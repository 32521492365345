import { FC, useEffect, useRef, useState } from 'react';
import { useQuery as uQ } from 'react-query';
import { CSVLink } from 'react-csv';
import { Button, useQueryString } from '../../reusable';
import { Absent, Group, Schedule, StudentGroup } from '../../types';
import { differenceWithSchedules, makeDateToString } from '../../utils';
import { fetcher } from '../../plugins';

interface AttendeeInfoDownloadViewProps {
  startDate: string;
  endDate: string;
  absents: Absent[];
  groups?: Group[];
  studentGroups: StudentGroup[];
  selectedGroup?: Group;
  userRole: string;
  setAtdData: (b: boolean) => void;
  isAtdData: boolean;
}

export const AttendeeInfoDownloadView: FC<AttendeeInfoDownloadViewProps> = ({
  startDate,
  endDate,
  absents,
  groups,
  studentGroups,
  selectedGroup,
  userRole,
  setAtdData,
  isAtdData,
}) => {
  const ref = useRef<any>();
  const [clicked, setClicked] = useState(false);
  const [updated, setUpdated] = useState(false);

  const _absents = absents
    ?.slice()
    ?.filter((el) => el.absentStatus === 'PROCESSED')
    ?.map((abs: Absent) => {
      const { studentId, student, reportType, description, startAt, endAt } =
        abs;
      const _abs = {
        studentId,
        student,
        reportType,
        description,
        startAt,
        endAt,
      };
      const _startAt = new Date(_abs?.startAt || '');
      const _endAt = new Date(_abs?.endAt || '');
      const _startDate = new Date(startDate);
      _startDate.setHours(0);
      _startDate.setMinutes(0);
      _startDate.setSeconds(0);
      const _endDate = new Date(endDate);

      if (_startAt < _startDate) {
        _abs.startAt = _startDate.toISOString();
      }
      if (_endAt > _endDate) {
        _abs.endAt = endDate;
      }
      return _abs;
    });
  const queryString = useQueryString({
    filter: {
      startDate: startDate,
      endDate: endDate,
    },
  });

  const { data: schedules } = uQ<Schedule[]>(
    `/schedules${queryString}`,
    fetcher,
    {
      keepPreviousData: true,
      enabled: isAtdData,
    },
  );

  const { data: attendances } = uQ(`/attendance${queryString}`, fetcher, {
    keepPreviousData: true,
    enabled: isAtdData,
  });

  const { data: dataField } = uQ(
    `/fieldtrips/processed${queryString}`,
    fetcher,
    {
      keepPreviousData: true,
      enabled: isAtdData,
    },
  );

  const csvData: any[] = [];
  csvData.push([
    '학급별출결현황',
    '기간:',
    makeDateToString(new Date(startDate)),
    ' ~ ',
    makeDateToString(new Date(endDate)),
  ]);
  csvData.push([
    '반',
    '번호',
    '성명',
    '수업일수',
    '결석',
    '',
    '',
    '',
    '지각',
    '',
    '',
    '',
    '조퇴',
    '',
    '',
    '',
    '결과',
    '',
    '',
    '',
    '결석총계',
    '지각총계',
    '조퇴총계',
    '결과총계',
  ]);
  csvData.push([
    '',
    '',
    '',
    '',
    '질병',
    '미인정',
    '기타',
    '인정',
    '질병',
    '미인정',
    '기타',
    '인정',
    '질병',
    '미인정',
    '기타',
    '인정',
    '질병',
    '미인정',
    '기타',
    '인정',
  ]);

  const attendeeInfo: any = {};

  if (userRole === 'PRINCIPAL' || userRole === 'SECURITY') {
    if (selectedGroup) {
      selectedGroup?.studentGroups?.map((studentGroup: StudentGroup) => {
        if (studentGroup.user?.id && !attendeeInfo[studentGroup.user.id]) {
          attendeeInfo[studentGroup.user.id] = {
            groupId: selectedGroup.id,
            studentNumber: studentGroup.studentNumber,
            studentGradeKlass: selectedGroup?.name,
            studentName: studentGroup.user.name,
          };
        }
      });
    } else {
      groups?.map((g: Group) =>
        g.studentGroups?.map((sg: StudentGroup) => {
          if (sg.user?.id && !attendeeInfo[sg.user.id]) {
            attendeeInfo[sg.user.id] = {
              groupId: g.id,
              studentNumber: sg.studentNumber,
              studentGradeKlass: g?.name,
              studentName: sg.user.name,
            };
          }
        }),
      );
    }
  } else {
    groups?.map((g: Group) =>
      g.studentGroups?.map((sg: StudentGroup) => {
        if (sg.user?.id && !attendeeInfo[sg.user.id]) {
          attendeeInfo[sg.user.id] = {
            groupId: g.id,
            studentNumber: sg.studentNumber,
            studentGradeKlass: g?.name,
            studentName: sg.user.name,
          };
        }
      }),
    );
    studentGroups?.map((studentGroup: StudentGroup) => {
      if (studentGroup.user?.id && !attendeeInfo[studentGroup.user.id]) {
        attendeeInfo[studentGroup.user.id] = {
          groupId: studentGroup.group?.id,
          studentNumber: studentGroup.studentNumber,
          studentGradeKlass: studentGroup.group?.name,
          studentName: studentGroup.user.name,
        };
      }
    });
  }

  _absents?.map((el: Absent) => {
    if (
      el.studentId &&
      el.student &&
      el.reportType &&
      el.description &&
      el.startAt &&
      el.endAt
    ) {
      if(el.description !== '미인정'){

        if (attendeeInfo[el.studentId]) {
          if (!attendeeInfo[el.studentId][el.reportType]) {
            attendeeInfo[el.studentId][el.reportType] = {};
          }

          if (!attendeeInfo[el.studentId][el.reportType][el.description]) {
            attendeeInfo[el.studentId][el.reportType][el.description] = 0;
          }

          attendeeInfo[el.studentId][el.reportType][el.description] +=
            differenceWithSchedules(el.startAt, el.endAt, schedules);
        }
      }
    }
  });

  attendances?.map((item: any) => {
    const studentId = item?.userId;

    if (attendeeInfo[studentId]) {
      const content = JSON.parse(item.content);

      let find = false;

      content.attendance?.reverse().map((cel: any) => {
        if (find === false && cel?.type2 === '미인정') {
          if (!attendeeInfo[studentId][cel?.type1]) {
            attendeeInfo[studentId][cel?.type1] = {};
          }

          if (!attendeeInfo[studentId][cel?.type1][cel?.type2]) {
            attendeeInfo[studentId][cel?.type1][cel?.type2] = 0;
          }

          attendeeInfo[studentId][cel?.type1][cel?.type2] += 1;
          find = true;
        }
      });
    }
  });

  dataField?.map((item: any) => {
    const studentId = item?.studentId;

    const _startAt = new Date(item?.startAt || '');
    const _endAt = new Date(item?.endAt || '');
    const _startDate = new Date(startDate);
    _startDate.setHours(0);
    _startDate.setMinutes(0);
    _startDate.setSeconds(0);
    const _endDate = new Date(endDate);

    if (_startAt < _startDate) {
      item.startAt = startDate;
    }
    if (_endAt > _endDate) {
      item.endAt = endDate;
    }

    if (attendeeInfo[studentId]) {
      if (!attendeeInfo[studentId]['결석']) {
        attendeeInfo[studentId]['결석'] = {};
      }

      if (!attendeeInfo[studentId]['결석']['인정']) {
        attendeeInfo[studentId]['결석']['인정'] = 0;
      }

      attendeeInfo[studentId]['결석']['인정'] += differenceWithSchedules(
        item.startAt,
        item.endAt,
        schedules,
      );
    }
  });

  const businessDays = differenceWithSchedules(startDate, endDate, schedules);

  Object.values(attendeeInfo)
    .slice()
    .sort((a: any, b: any) => {
      if (a.groupId === b.groupId) {
        return a.studentNumber - b.studentNumber;
      } else {
        return a.groupId - b.groupId;
      }
    })
    .map((info: any) => {
      const { studentNumber, studentGradeKlass, studentName } = info;
      csvData.push([
        studentGradeKlass,
        studentNumber,
        studentName,
        businessDays,
        info?.결석?.질병 || 0,
        info?.결석?.미인정 || 0,
        info?.결석?.기타 || 0,
        info?.결석?.인정 || 0,
        info?.지각?.질병 || 0,
        info?.지각?.미인정 || 0,
        info?.지각?.기타 || 0,
        info?.지각?.인정 || 0,
        info?.조퇴?.질병 || 0,
        info?.조퇴?.미인정 || 0,
        info?.조퇴?.기타 || 0,
        info?.조퇴?.인정 || 0,
        info?.결과?.질병 || 0,
        info?.결과?.미인정 || 0,
        info?.결과?.기타 || 0,
        info?.결과?.인정 || 0,
        (info?.결석?.질병 || 0) +
          (info?.결석?.기타 || 0) +
          (info?.결석?.미인정 || 0) +
          (info?.결석?.인정 || 0),
        (info?.지각?.질병 || 0) +
          (info?.지각?.기타 || 0) +
          (info?.지각?.미인정 || 0) +
          (info?.지각?.인정 || 0),
        (info?.조퇴?.질병 || 0) +
          (info?.조퇴?.기타 || 0) +
          (info?.조퇴?.미인정 || 0) +
          (info?.조퇴?.인정 || 0),
        (info?.결과?.질병 || 0) +
          (info?.결과?.기타 || 0) +
          (info?.결과?.미인정 || 0) +
          (info?.결과?.인정 || 0),
      ]);
    });

  csvData.push([]);
  csvData.push(['* 결재가 완료된 출결신고서를 기준으로 작성됩니다.']);
  csvData.push(['* 결재가 완료된 체험학습 신청건은 인정결석으로 추가됩니다.']);
  csvData.push([
    '* 미인정은 출석부를 기준으로 작성됩니다. (하루에 미인정건이 많으면 마지막 1건만 적용됨)',
  ]);
  csvData.push([
    '* 수업일수가 맞지 않을 경우, 조회 시작일, 종료일을 확인하세요.',
  ]);
  csvData.push([
    '  공휴일은 캘린더에서 공휴일 설정을 해야 수업일수에서 제외됩니다.',
  ]);

  useEffect(() => {
    if (absents && clicked) {
      setUpdated(true);
    }
  }, [clicked, csvData]);

  useEffect(() => {
    if (clicked && absents?.length && csvData && updated) {
      ref?.current?.link?.click();
      setClicked(false);
      setUpdated(false);
    }
  }, [updated]);

  return (
    <>
      <Button
        text="학급별출결현황"
        tw={{
          backgroundColor: 'bg-green-400',
          minWidth: 'min-w-max',
          paddingY: 'py-2.5',
          height: 'h-auto',
          paddingX: 'px-4',
          width: 'w-full',
        }}
        onClick={() => {
          !isAtdData && setAtdData(true);
          setClicked(true);
        }}
      />
      <CSVLink
        data={csvData}
        filename={`학급별출결현황 (${makeDateToString(new Date())}).csv`}
        // style={{ width: '100%' }}
        ref={ref}
      ></CSVLink>
    </>
  );
};
