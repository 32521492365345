import React, { FC, useState } from 'react';
import { Blank, Button, TextField, useMutation } from '../../../reusable';
import { CREATE_TIMETABLE, UPDATE_TIMETABLE } from '../../../query';
import { ErrorBlank } from '../../../components';

interface TimetableSubmitPageProps {
  timetableContent?: string;
  year: number;
  semester: number;
  groupId: number;
  setReadState: () => void;
  refetch: () => void;
}

export const TimetableSubmitPage: FC<TimetableSubmitPageProps> = ({
  timetableContent,
  year,
  semester,
  groupId,
  setReadState,
  refetch,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [content, setContent] = useState(
    timetableContent || '[{},{},{},{},{}]',
  );

  let parsedContent = [{}, {}, {}, {}, {}];

  try {
    parsedContent = JSON.parse(content);
  } catch (err) {
    console.error(err);
  }

  const [createTimetable] = useMutation(CREATE_TIMETABLE, {
    variables: {
      data: JSON.stringify({
        content,
        year,
        semester,
        groupId,
      }),
    },
    onError: () => setError(true),
    onCompleted: async () => {
      await refetch();
      await setLoading(false);
      await setReadState();
    },
  });

  const [updateTimetable] = useMutation(UPDATE_TIMETABLE, {
    variables: {
      data: JSON.stringify({
        content,
        year,
        semester,
        groupId,
      }),
    },
    onError: () => setError(true),
    onCompleted: async () => {
      await refetch();
      await setLoading(false);
      await setReadState();
    },
  });

  return (
    <div className="px-6 mx-6 max-w-256">
      {isLoading && <Blank reversed />}
      {isError && <ErrorBlank />}
      <table className="text-center mx-auto">
        <thead className="border-b border-grey-7">
          <tr>
            <td className="pb-2" />
            <td className="text-brand-1 pb-2">월</td>
            <td className="text-brand-1 pb-2">화</td>
            <td className="text-brand-1 pb-2">수</td>
            <td className="text-brand-1 pb-2">목</td>
            <td className="text-brand-1 pb-2">금</td>
          </tr>
        </thead>
        <tbody>
          {parsedContent?.map((classtime: any, i: number) => (
            <tr>
              <td className="pt-4 px-3">{i + 1}</td>
              <td className="pt-4 px-1">
                <TextField
                  value={classtime.monday}
                  onChange={(e) => {
                    const newContent = JSON.parse(
                      JSON.stringify(parsedContent),
                    );
                    newContent[i].monday = e.target.value;
                    setContent(JSON.stringify(newContent));
                  }}
                />
              </td>
              <td className="pt-4 px-1">
                <TextField
                  value={classtime.tuesday}
                  onChange={(e) => {
                    const newContent = JSON.parse(
                      JSON.stringify(parsedContent),
                    );
                    newContent[i].tuesday = e.target.value;
                    setContent(JSON.stringify(newContent));
                  }}
                />
              </td>
              <td className="pt-4 px-1">
                <TextField
                  value={classtime.wednesday}
                  onChange={(e) => {
                    const newContent = JSON.parse(
                      JSON.stringify(parsedContent),
                    );
                    newContent[i].wednesday = e.target.value;
                    setContent(JSON.stringify(newContent));
                  }}
                />
              </td>
              <td className="pt-4 px-1">
                <TextField
                  value={classtime.thursday}
                  onChange={(e) => {
                    const newContent = JSON.parse(
                      JSON.stringify(parsedContent),
                    );
                    newContent[i].thursday = e.target.value;
                    setContent(JSON.stringify(newContent));
                  }}
                />
              </td>
              <td className="pt-4 px-1">
                <TextField
                  value={classtime.friday}
                  onChange={(e) => {
                    const newContent = JSON.parse(
                      JSON.stringify(parsedContent),
                    );
                    newContent[i].friday = e.target.value;
                    setContent(JSON.stringify(newContent));
                  }}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={5} className="pt-4">
              <div
                className="w-full flex justify-center items-center py-3 hover:bg-brand-5 cursor-pointer"
                onClick={() => {
                  const newContent = JSON.parse(JSON.stringify(parsedContent));
                  newContent.push({});
                  setContent(JSON.stringify(newContent));
                }}
              >
                <div className="text-2xl flex items-center justify-center text-brand-1 rounded-full border-2 border-brand-1 w-6 h-6 pb-0.5">
                  +
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="w-full flex items-center justify-center">
        <Button
          disabled={!content}
          tw={{
            marginTop: 'mt-2',
            width: 'w-3/4',
            backgroundColor: !content ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={() => {
            setLoading(true);
            timetableContent ? updateTimetable() : createTimetable();
          }}
        >
          제출하기
        </Button>
      </div>
    </div>
  );
};
