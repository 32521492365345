import React, { FC, useEffect, useState } from 'react';
import {
  BackButton,
  Blank,
  Button,
  PhoneNumberField,
  Section,
  TextField,
  TopNavbar,
  useMutation,
  useQuery,
} from '../../../reusable';
import { ErrorBlank } from '../../../components';
import { GET_SIGNUP_GROUPS, UPDATE_ME } from '../../../query';
import { StudentGroup, User } from '../../../types';
import { api } from '../../../plugins';
import { passwordCheck } from '../../../utils';

interface MyInfoUpdatePageProps {
  me: User;
  refetch: () => void;
  gotoRead: () => void;
}

interface GroupUpdate {
  studentNumber: number;
  groupId: number;
}

export const MyInfoUpdatePage: FC<MyInfoUpdatePageProps> = ({
  me,
  refetch,
  gotoRead,
}) => {
  const { loading, error, data } = useQuery(GET_SIGNUP_GROUPS);

  const studentGroups = me?.studentGroups?.filter(
    (sg: StudentGroup) => sg.group?.type === 'KLASS',
  );

  const [name, setName] = useState(me?.name);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState(me?.phone);
  const [nokName, setNokName] = useState(me?.nokName || '');
  const [nokPhone, setNokPhone] = useState(me?.nokPhone);
  const [isLoading, setLoading] = useState(false);
  const [birthDate, setBirthDate] = useState<any>('');
  const [hopePath, setHopePath] = useState('');
  const [hopeMajor, setHopeMajor] = useState('');

  const SGgroups: any = studentGroups?.reduce((acc, cur: StudentGroup) => {
    if (!cur.id) {
      return acc;
    }
    return {
      ...acc,
      [cur.id]: {
        id: Number(cur.id),
        studentNumber: cur.studentNumber,
        groupId: cur.group?.id,
      },
    };
  }, {});

  useEffect(() => {
    if (!name) {
      setName(me?.name || '');
    }
    if (!nokName && me?.nokName) {
      setNokName(me?.nokName);
    }
    if (me?.nokPhone) {
      setNokPhone(me?.nokPhone);
    }
    if (!birthDate) {
      setBirthDate(me?.birthDate || '');
    }
    if (!hopePath) {
      setHopePath(me?.hopePath || '');
    }
    if (!hopeMajor) {
      setHopeMajor(me?.hopeMajor || '');
    }
  }, [data]);

  const [SGgroupIds, setSGgroupIds] =
    useState<Record<number, GroupUpdate>>(SGgroups);

  const [updateMe] = useMutation(UPDATE_ME, {
    variables: {
      data: JSON.stringify({
        name,
        password,
        phone,
        nokName,
        nokPhone,
        studentGroups: studentGroups && Object.values(SGgroupIds),
        birthDate,
        hopePath,
        hopeMajor,
      }),
    },
    onCompleted: async () => {
      await refetch();
      await gotoRead();
      await setLoading(false);
    },
  });

  const parentUpdateMe = () =>
    api({
      method: 'post',
      url: 'users/me',
      data: {
        name,
        password,
        phone,
        nokName,
        nokPhone,
        birthDate,
      },
    })
      .then(() => {
        setLoading(false);
        refetch();
        gotoRead();
      })
      .catch((err) => {
        console.log('err', err.message);
        setLoading(false);
      });

  const spacere = / /g;

  if (error) {
    return <ErrorBlank />;
  }

  const buttonDisabled =
    me && me?.role === 'PARENT'
      ? !name || !phone || password !== password2
      : !name || !phone || !nokName || !nokPhone || password !== password2;

  return (
    <div>
      <TopNavbar
        title="내 정보 수정"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      {loading && <Blank />}
      {isLoading && <Blank />}
      <Section>
        <div className="w-full">
          <TextField
            label="이름"
            id="name"
            placeholder="이름을 입력해주세요."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="w-full">
          <TextField label="이메일" value={me?.email} disabled />
        </div>
        <div className="w-full">
          <TextField
            type="password"
            label="비밀번호"
            placeholder="비밀번호 (문자,숫자,특수문자를 포함한 8자 이상)"
            value={password}
            onChange={(e) =>
              setPassword(String(e.target.value).replace(spacere, ''))
            }
          />
          {password && !passwordCheck(password) && (
            <div className="text-red-600">안전한 비밀번호를 입력하세요.</div>
          )}
          <TextField
            type="password"
            placeholder="동일한 비밀번호를 한번 더 입력해주세요."
            value={password2}
            style={{ marginTop: '0.5rem' }}
            onChange={(e) =>
              setPassword2(String(e.target.value).replace(spacere, ''))
            }
          />
          {password2 && password !== password2 && (
            <div className="text-red-600">
              비밀번호 확인이 일치하지 않습니다.
            </div>
          )}
          <div className="text-grey-3">
            &nbsp; 안전한 개인정보의 보호를 위해 문자,숫자,특수문자가 포함된 8자
            이상의 비밀번호를 입력하세요.
            <br />
            &nbsp; 사용 가능한 특수문자는 ! @ # $ % & * ? 입니다.
            <br />
            &nbsp; 비밀번호를 입력하지 않으면 기존 비밀번호가 유지 됩니다.
          </div>
        </div>
        <div className="w-full">
          <PhoneNumberField
            label="전화번호"
            value = {phone || ''}
            onChange={(e) => setPhone(e.target.value)}
            style={{
              borderColor: !phone ? 'rgba(185, 28, 28)' : '',
            }}
            // disabled={me?.role !== 'PARENT' && phone ? true : false}
          />
        </div>
        {studentGroups?.map((sg: StudentGroup) => {
          const sgId = sg?.id;
          const groupId = sg?.group?.id;
          if (!sgId || !groupId || !SGgroupIds[sgId]) {
            return '';
          }
          return (
            <div className="w-full flex items-center space-x-2" key={sgId}>
              <div className="w-3/4">
                <TextField
                  label="학년 / 학교 / 학년 / 반"
                  readOnly
                  value={`${sg?.group?.year} ${me?.school?.name} ${sg?.group?.name}`}
                />
                {/* <Select
                  label="학년 / 학교 / 학년 / 반"
                  placeholder={`${sg?.group?.year} ${sg?.group?.school?.name} ${sg?.group?.name}`}
                  value={SGgroupIds[sgId].groupId}
                  onChange={(e) => {
                    const newSGgroupId: GroupUpdate = {
                      ...SGgroupIds[sgId],
                      groupId: Number(e.target.value),
                    };
                    setSGgroupIds({
                      ...SGgroupIds,
                      [sgId]: newSGgroupId,
                    });
                  }}
                >
                  {groups?.map((group: Group) => (
                    <option value={group?.id}>
                      {group?.year} {group?.school?.name} {group?.name}
                    </option>
                  ))}
                </Select> */}
              </div>
              <div className="w-1/4">
                <TextField
                  label="번호"
                  value={Number(SGgroupIds[sgId].studentNumber)}
                  type="number"
                  onChange={(e) => {
                    const newSGgroupId: GroupUpdate = {
                      ...SGgroupIds[sgId],
                      studentNumber:
                        Number(e.target.value) >= 100
                          ? 99
                          : Number(e.target.value) <= 0
                          ? 1
                          : Number(e.target.value),
                    };
                    setSGgroupIds({
                      ...SGgroupIds,
                      [sgId]: newSGgroupId,
                    });
                  }}
                  readOnly
                />
              </div>
            </div>
          );
        })}

        {me?.role !== 'PARENT' && (
          <>
            {/* <div className="w-full">
              <TextField
                type="date"
                label="*생년월일"
                onChange={(e) => setBirthDate(e.target.value)}
                value={birthDate}
                tw={{
                  borderColor: birthDate ? 'border-gray-300' : 'border-red-700',
                }}
              />
            </div> */}
            <div className="w-full">
              <TextField
                label="희망진로"
                value={hopePath}
                onChange={(e) => setHopePath(e.target.value)}
              />
            </div>
            <div className="w-full">
              <TextField
                label="희망학과"
                value={hopeMajor}
                onChange={(e) => setHopeMajor(e.target.value)}
              />
            </div>

            <div className="w-full">
              <TextField
                disabled
                label="보호자 이름"
                value={nokName}
                onChange={(e) => setNokName(e.target.value)}
                tw={{
                  borderColor: nokName ? 'border-gray-300' : 'border-red-700',
                }}
              />
            </div>
            <div className="w-full">
              <PhoneNumberField
                disabled = {me?.nokPhone!=='' && me?.nokPhone!=='010' &&  (me?.nokPhone !== undefined && me?.nokPhone.length >= 10)}
                label="보호자 연락처"
                value={nokPhone || '010'}
                onChange={(e) => setNokPhone(e.target.value)}
                style={{
                  borderColor: !nokPhone ? 'rgba(185, 28, 28)' : '',
                }}
              />
              <div className="text-grey-3">
                &nbsp; * 보호자 정보 수정은 담임선생님의 학생카드 페이지에서
                가능합니다.
              </div>
            </div>
          </>
        )}

        <Button
          disabled={buttonDisabled}
          onClick={() => {
            setLoading(true);
            const regExp = /^010(?:\d{4})\d{4}$/;
            if (phone && !regExp.test(phone.replace(/-/g, ''))) {
              alert('전화번호를 확인해 주세요.');
              setLoading(false);
              return;
            }
            if (nokPhone && !regExp.test(nokPhone.replace(/-/g, ''))) {
              alert('보호자 연락처를 확인해 주세요.');
              setLoading(false);
              return;
            }
            if (password.length === 0) {
              alert(
                '비밀번호를 공백으로 기입할 시 기존 비밀번호가 유지됩니다.',
              );
              me?.role === 'PARENT' ? parentUpdateMe() : updateMe();
            } else {
              if (!passwordCheck(password)) {
                alert('안전한 비밀번호를 입력하세요.');
                setLoading(false);
              } else if (password !== password2) {
                alert('비밀번호와 비밀번호 확인이 다릅니다.');
              } else {
                me?.role === 'PARENT' ? parentUpdateMe() : updateMe();
              }
            }
          }}
        >
          내 정보 수정하기
        </Button>
      </Section>
    </div>
  );
};
