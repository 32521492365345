import React, { FC, useEffect, useState } from 'react';
import { UPDATE_FEEDBACK } from '../../query';
import {
  Blank,
  Button,
  Checkbox,
  Section,
  TextArea,
  useMutation,
} from '../../reusable';
import { StudentActivity } from '../../types';
import { makeDateToString } from '../../utils';
import { ErrorBlank } from '../ErrorBlank';

interface RecordCardProps {
  record: StudentActivity;
  refetch: () => void;
  selectedSAIds: number[];
  setSelectedSAIds: (ids: number[]) => void;
}

export const RecordCard: FC<RecordCardProps> = ({
  record,
  refetch,
  selectedSAIds,
  setSelectedSAIds,
}) => {
  const activity = record?.activity;
  const [feedback, setFeedback] = useState(record.feedback);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (record?.feedback) {
      setFeedback(record.feedback);
    }
  }, [record]);

  const [updateFeedback] = useMutation(UPDATE_FEEDBACK, {
    variables: {
      id: record?.id,
      feedback,
    },
    onError: (err) => {
      setLoading(false);
      setErrorText(err?.message);
    },
    onCompleted: () => {
      setLoading(false);
      refetch();
    },
  });

  return (
    <div className="border-gray-200 border-2 rounded-lg overflow-hidden">
      {loading && <Blank />}
      {errorText && <ErrorBlank text={errorText} />}
      <div className="px-6 py-3 bg-gray-50 flex items-center justify-between">
        <div>
          <div className="flex items-center space-x-2 font-semibold">
            {record.isSubmitted ? (
              <div>제출</div>
            ) : (
              <div className="text-red-500">미제출</div>
            )}

            <a
              className=" text-blue-600"
              href={`/teacher/activity/${activity?.id}`}
            >
              [{activity?.subject}] {activity?.title}
            </a>
          </div>

          <h3 className="text-sm text-grey-3 mt-2">
            {makeDateToString(new Date(activity?.createdAt || ''), '.')}
          </h3>
        </div>
        <Checkbox
          className="w-6 h-6"
          checked={selectedSAIds.includes(record?.id || 0)}
          onChange={() => {
            if (record?.id) {
              if (selectedSAIds.includes(record.id)) {
                setSelectedSAIds(
                  selectedSAIds.filter((id) => id !== record.id),
                );
              } else {
                setSelectedSAIds(selectedSAIds.concat(record.id));
              }
            }
          }}
        />
      </div>
      <Section>
        <div>
          <h2 className="font-semibold">공통문구</h2>
          <h2 className="text-sm mt-2">{activity?.commonText}</h2>
        </div>

        <div>
          <h2 className="font-semibold">활동요약</h2>
          {record.summary && (
            <TextArea
              tw={{ height: 'h-30' }}
              readOnly
              value={record.summary}
            ></TextArea>
          )}
        </div>

        <div>
          <TextArea
            label="피드백"
            placeholder="선생님이 직접 입력하는 섹션입니다."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          ></TextArea>
        </div>
        <div className="text-center mt-6">
          <Button
            text="등록하기"
            tw={{
              height: 'h-11',
            }}
            style={{ width: '70%' }}
            onClick={() => {
              setLoading(true);
              updateFeedback();
            }}
          />
        </div>
      </Section>
    </div>
  );
};
