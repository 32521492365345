import { useQuery } from '@apollo/client';
import { GET_TIMETABLE_PAGE } from '../../../query';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import SignaturePad from 'signature_pad';
import {
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  SearchField,
  Section,
  TextField,
  TopNavbar,
  useMutation,
  useQueryString,
  Select,
  Tabs,
  Tab,
  useRecoilValue,
} from '../../../reusable';
import {
  StudentItem,
  ErrorBlank,
  SelectMenus,
  FrontPagination,
  SuperModal,
} from '../../../components';
import { Group, TabType } from '../../../types';
import { fetcher } from '../../../plugins';
import { StudentCardDetailPage } from './StudentCardDetailPage';
import { ReactComponent as UploadIcon } from '../../../assets/svg/icon-upload.svg';
import { GET_KlASS_WITH_MYKLASSID } from '../../../query';
import { getThisYear, getSemester } from '../../../utils/time-util';
import { meState } from '../../../store';

export const StudentCardPage: React.FC = () => {
  const me = useRecoilValue(meState);

  const [tabType, setTabType] = useState<TabType>(TabType.COULSELING);
  const [isDetail, setIsDetail] = useState(false);

  const [groupId, setGroupId] = useState<number | undefined>(0);

  const pathname = useLocation().pathname;
  const selectedUserId = pathname
    .replace('/teacher/studentcard', '')
    .replace('/', '');

  console.log('selectedUserId', selectedUserId);

  const [studentName, setStudentName] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [_studentName, set_studentName] = useState('');

  //const { loading, error, data } = useQuery(GET_TIMETABLE_PAGE);

  const { error, data } = uQ(`/users/teacherHomeKlass`, fetcher);

  const year = Number(getThisYear());
  const semester = getSemester();

  const groups = data?.klass
    ?.slice()
    .sort((a: any, b: any) => {
      const aData = a?.name?.match(
        `([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`,
      );
      const bData = b?.name?.match(
        `([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`,
      );

      if (!aData || !bData) {
        return 0;
      }

      if (aData[1] && bData[1]) {
        if (aData[1] === bData[1]) {
          return Number(aData[2]) - Number(bData[2]);
        } else {
          return Number(aData[1]) - Number(bData[1]);
        }
      } else {
        return 0;
      }
    })
    .filter((item: any) => {
      if (data) {
        if (
          data?.user.role === 'PREPRINCIPAL' ||
          data?.user.role === 'PRINCIPAL' ||
          data?.user.role === 'VICEPRINCIPAL' ||
          data?.user.role === 'HEADPRINCIPAL' ||
          data?.user.role === 'ADMIN'
        ) {
          return true;
        } else if (data?.user.role === 'HEAD' || data?.user.role === 'PREHEAD') {
          return item.name.startsWith(data?.user.headNumber);
        } else if (data?.user.role === 'TEACHER') {
          return item.id === data?.user.teacherGroups[0]?.group?.id;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

  useEffect(() => {
    if (data && (!groupId || groupId === 0)) {
      if (data?.user.teacherGroups[0]?.group?.id !== 0) {
        setGroupId(data?.user.teacherGroups[0]?.group?.id);
      } else if (groups) {
        setGroupId(groups[0]?.id);
      }
    }
  }, [data]);

  const {
    error: studentsError,
    data: studentsData,
    refetch,
  } = uQ(`/groups?ids=${groupId}`, fetcher);

  let klassName = studentsData ? studentsData?.groupsByIds[0]?.name : '';
  let studentsList = studentsData
    ? studentsData?.groupsByIds[0]?.studentGroups
        .sort((a: any, b: any) => a.studentNumber - b.studentNumber)
        .filter(
          (item: any) =>
            _studentName === '' || item?.user?.name.includes(_studentName),
        )
    : [];

  return (
    <>
      {error && <ErrorBlank />}
      {isLoading && <Blank reversed />}

      {/* 모바일, 데스크탑 통합 */}
      <div
        className={`col-span-2 h-screen overflow-y-auto ${
          !selectedUserId || selectedUserId === '' ? '' : 'hidden md:block'
        }`}
      >
        <div className="md:hidden">
          <div className="md:hidden block">
            <TopNavbar title="학생카드" left={<BackButton />} />
          </div>
        </div>
        <div>
          <div className="px-6 py-6">
            <h1 className="text-2xl font-semibold mb-5 hidden md:block">
              학생카드
            </h1>

            <div className="flex space-x-2 items-center">
              <div className=" px-2 py-2 min-w-max cursor-pointer">
                <Select
                  value={groupId}
                  onChange={(e) => {
                    setGroupId(Number(e.target.value));
                  }}
                  placeholder="반 선택"
                >
                  {groups?.map((group: Group) => (
                    <option value={group.id}>{group.name}</option>
                  ))}
                </Select>
              </div>
              <div className="flex items-center space-x-2 w-full">
                <form className="items-center border-gray-200 rounded-full border flex justify-between h-10 px-4 w-full">
                  <input
                    className="w-full"
                    placeholder="학생이름을 입력해주세요."
                    value={_studentName}
                    onChange={(e) => {
                      set_studentName(e.target.value);
                      if (e.target.value === '') {
                        setStudentName('');
                      }
                    }}
                    // onKeyPress={(e) => {
                    //   if (e.key === 'Enter') {
                    //     if (_studentName) {
                    //       setStudentName(_studentName);
                    //     }
                    //   }
                    // }}
                  />
                </form>
                <UploadIcon
                // onClick={() => {
                //   if (_studentName) {
                //     setStudentName(_studentName);
                //   }
                // }}
                />
              </div>
            </div>
          </div>
          <div className="h-0.5 bg-gray-100"></div>
          <div className="h-screen-15 overflow-y-auto scroll-box pb-20">
            {!groups && <div className="text-center">권한이 없습니다.</div>}
            {studentsList?.map((student: any) => (
              <StudentItem
                studentid={student?.user?.id}
                studentname={student?.user?.name}
                klass={klassName}
                klassnum={student?.studentNumber + '번'}
              />
            ))}
          </div>
        </div>
      </div>

      {selectedUserId && selectedUserId !== '' && (
        <div
          className={`bg-gray-50 col-span-4 p-0 md:p-6 h-screen overflow-y-scroll  scroll-box`}
        >
          <StudentCardDetailPage
            id={selectedUserId}
            groupId={groupId?.toString()}
          />
          {/* <Switch>
          <Route
            path="/teacher/studentcard/:id"
            component={() => (
              <StudentCardDetailPage refetch={() => refetch()} />
            )}
          />
        </Switch> */}
        </div>
      )}
    </>
  );
};
