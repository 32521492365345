import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SuperSurveyComponent } from '../../../components';
import { GET_STUDENT_NEWSLETTER } from '../../../query';
import {
  Blank,
  useQuery,
  utcToLocalDatetime,
  utcToLocalFromNow,
} from '../../../reusable';
import { Newsletter } from '../../../types';

interface MatchParams {
  snid: string;
}

interface NewsletterSubmitDetailPageProps
  extends RouteComponentProps<MatchParams> {
  newsletterData?: Newsletter;
}

const NewsletterSubmitDetailPage: React.FC<NewsletterSubmitDetailPageProps> = ({
  match,
  newsletterData,
}) => {
  let { snid } = match.params;
  const [isLoading, setLoading] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_STUDENT_NEWSLETTER, {
    variables: { id: Number(snid) },
    skip: snid === 'undefined',
  });

  const studentNewsletter = data?.studentNewsletter;
  const newsletter = newsletterData;

  return (
    <>
      {loading && <Blank reversed />}
      {isLoading && <Blank reversed />}
      <div className="border border-gray-100 h-screen-4.5 overflow-y-scroll overflow-x-hidden relative">
        <div className="bg-gray-50 p-4">
          <div className="space-y-0.5">
            <div className="text-lg font-semibold mt-2">
              {newsletter?.title}
            </div>
            <div className="text-sm text-gray-500 font-light">
              {newsletter?.createdAt &&
                utcToLocalDatetime(newsletter.createdAt)}
            </div>
          </div>
        </div>
        {newsletter?.surveyContent && studentNewsletter?.content && (
          <SuperSurveyComponent
            surveyContent={newsletter?.surveyContent || ''}
            setContent={(c: any) => {}}
            content={JSON.parse(studentNewsletter?.content || '{}')}
          />
        )}

        {newsletter?.updatedAt &&
          studentNewsletter?.updatedAt &&
          studentNewsletter?.isSubmitted &&
          studentNewsletter.updatedAt !== newsletter?.updatedAt && (
            <div className="mt-3 text-brandblue-1">
              제출 완료 일시 :{' '}
              {utcToLocalDatetime(
                new Date(studentNewsletter.updatedAt).toISOString(),
              )}{' '}
              (
              {utcToLocalFromNow(
                new Date(studentNewsletter.updatedAt).toISOString(),
              )}
              )
            </div>
          )}
      </div>
    </>
  );
};

export default withRouter(NewsletterSubmitDetailPage);
