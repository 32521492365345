import React, { useEffect, useState } from 'react';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import { Chip, HorizontalScrollView, useQuery, Blank } from '../../../reusable';
import { ActivitiesView, SelectMenus, ErrorBlank } from '../../../components';
import { GET_TEACHER_GROUPS } from '../../../query';
import { Group, TeacherGroup } from '../../../types';
import ActivityAddPage from './ActivityAdd';
import ActivityDetailPage from './ActivityDetailPage';
import ActivitySubmitPage from './ActivitySubmitPage';
import ActivityDownloadPage from './ActivityDownloadPage';

const ActivityPage: React.FC = () => {
  const selectedGroupId = Number(localStorage.getItem('selectedGroupId'));

  const { loading, error, data, refetch } = useQuery(GET_TEACHER_GROUPS, {
    fetchPolicy: 'cache-and-network',
  });

  // const { error, data, refetch } = uQ(`/teacher-groups`, fetcher);

  const activityGroups = data?.me?.teacherGroups
    .map((el: TeacherGroup) => el.group)
    .filter((el: any) => !!el)
    .sort((a: any, b: any) => {
      const aData = a?.name?.match(
        `([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`,
      );
      const bData = b?.name?.match(
        `([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`,
      );

      if (!aData || !bData || !aData?.length || !bData?.length) {
        if (!aData && bData) {
          return 1;
        } else if (aData && !bData) {
          return -1;
        } else if (a?.name && b?.name) {
          return a?.name < b?.name ? -1 : 1;
        }
        return 0;
      }

      if (aData[1] && bData[1]) {
        if (aData[1] === bData[1]) {
          return Number(aData[2]) - Number(bData[2]);
        } else {
          return Number(aData[1]) - Number(bData[1]);
        }
      } else {
        return 0;
      }
    });

  const groups = activityGroups?.filter(
    (group: Group, i: number) =>
      i === activityGroups.findIndex((g: Group) => g?.name === group?.name),
  );

  let subjects: string[] = [];

  data?.me?.teacherGroups.map((tg: TeacherGroup) =>
    tg.subject && !subjects.includes(tg.subject)
      ? subjects.push(tg.subject)
      : tg,
  );

  const [subject, setSubject] = useState<string>();

  const [selectedGroup, setSelectedGroup] = useState<Group>(
    groups
      ? groups?.filter((g: Group) => g.id === Number(selectedGroupId))[0] ||
          groups[0]
      : null,
  );
  const [loadActivitiesView, setLoadActivitiesView] = useState(false);
  const [isUpdateState, setUpdateState] = useState<boolean>(false);

  useEffect(() => {
    if (groups && !selectedGroup) {
      setSelectedGroup(
        selectedGroup ||
          groups?.filter((g: Group) => g.id === Number(selectedGroupId))[0] ||
          groups[0],
      );
    }
  }, [groups, selectedGroup, selectedGroupId]);

  // if (loading) return <LoadingScreen />;
  if (error) return <ErrorBlank />;

  return (
    <div className="col-span-6 grid grid-cols-6">
      {loading && <Blank />}
      {/* 활동기록부 리스트 */}
      <div className="col-span-2 h-screen hidden md:block">
        <div className="px-6 py-6">
          <div className="flex justify-between mb-5">
            <h1 className="text-2xl font-semibold">활동기록부</h1>
            <Link to="/teacher/activity/add">
              <button className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-4 py-2 focus:outline-none">
                추가하기
              </button>
            </Link>
          </div>
          <div className="w-36 mb-5 cursor-pointer">
            <SelectMenus
              items={groups}
              value={groups ? selectedGroup : null}
              allText="선택하기"
              onChange={(group: Group) => {
                if (group?.id) {
                  localStorage.setItem('selectedGroupId', group.id.toString());
                  setSelectedGroup(group);
                }
              }}
            />
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        {/* 활동기록부 게시판 */}
        <HorizontalScrollView twInner={{ marginY: 'my-4' }}>
          <Chip
            text={'전체'}
            onClick={() => setSubject(undefined)}
            active={!subject}
            tw={{ paddingY: 'py-1.5', minWidth: 'min-w-max' }}
          />
          {subjects.map((el: string) => (
            <Chip
              key={el}
              text={el}
              onClick={() => setSubject(el)}
              tw={{ paddingY: 'py-1.5', minWidth: 'min-w-max' }}
              active={el === subject}
            />
          ))}
        </HorizontalScrollView>
        <div className="h-0.5 bg-gray-100"></div>
        <div className="h-screen-10 pb-20 overflow-y-auto scroll-box">
          {selectedGroup ? (
            <ActivitiesView
              group={selectedGroup}
              subject={subject}
              loadActivitiesView={loadActivitiesView}
              setUpdateState={() => setUpdateState(false)}
              subjects={subjects}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="col-span-4 bg-gray-50 scroll-box">
        <Switch>
          <Route
            path="/teacher/activity/add"
            component={() => (
              <ActivityAddPage
                refetch={() => {
                  setLoadActivitiesView(!loadActivitiesView);
                  refetch();
                }}
              />
            )}
          />
          <Route
            path="/teacher/activity/submit/:id"
            component={() => <ActivitySubmitPage groupId={selectedGroup?.id} />}
          />

          <Route
            path="/teacher/activity/download/:id"
            component={() => (
              <ActivityDownloadPage groupId={selectedGroup?.id} />
            )}
          />

          <Route
            path="/teacher/activity/:id"
            component={() => (
              <ActivityDetailPage
                isUpdateState={isUpdateState}
                setUpdateState={(b: boolean) => setUpdateState(b)}
                refetch={() => {
                  setLoadActivitiesView(!loadActivitiesView);
                  refetch();
                }}
              />
            )}
          />
          <Route
            path="/teacher/activity"
            component={() => (
              <div className="w-full h-full flex items-center justify-center"></div>
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(ActivityPage);
