import React, { useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { Blank, Chip, HorizontalScrollView, useQuery } from '../../../reusable';
import { GET_NEWSLETTERS } from '../../../query';
import { Newsletter } from '../../../types';
import { ErrorBlank, NewsletterCard } from '../../../components';
import { NewsletterAddPage } from './NewsletterAddPage';
import NewsletterSubmitPage from './NewsletterSubmitPage';
import NewsletterDownloadPage from './NewsletterDownloadPage';
import NewsletterDetailPage from './NewsletterDetailPage';

const categories = [
  '기타',
  '교무',
  '연구',
  '학생',
  '학년',
  '정보',
  '행정',
  '교육과정',
  '진로진학',
];

const NewsletterPage = () => {
  let { loading, error, data, refetch }: any = useQuery(GET_NEWSLETTERS, {});

  const [isUpdateState, setUpdateState] = useState<boolean>(false);
  const [category, setCategory] = useState('');

  const newsletters = category
    ? data?.newsletters.filter((el: Newsletter) => el.category === category)
    : data?.newsletters;

  return (
    <>
      {/* Desktop V */}
      {loading && <Blank reversed />}
      {error && <ErrorBlank />}
      <div className="col-span-2 h-screen hidden md:block">
        <div className="flex justify-between px-6 py-6">
          <h1 className="text-2xl font-semibold">가정통신문</h1>
          {data?.me?.canEditNewsletter && (
            <Link to="/teacher/newsletter/add">
              <button className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-4 py-2 focus:outline-none">
                추가하기
              </button>
            </Link>
          )}
        </div>
        <div className="h-0.5 bg-gray-100 scroll-box"></div>
        <HorizontalScrollView twInner={{ marginY: 'my-4' }}>
          <Chip
            text={'전체'}
            onClick={() => setCategory('')}
            active={!category}
            tw={{ paddingY: 'py-1.5', minWidth: 'min-w-max' }}
          />
          {categories.map((el: string) => (
            <Chip
              key={el}
              text={el}
              onClick={() => setCategory(el)}
              tw={{ paddingY: 'py-1.5', minWidth: 'min-w-max' }}
              active={el === category}
            />
          ))}
        </HorizontalScrollView>
        <div className="h-screen-5 pb-20 overflow-y-auto scroll-box">
          {newsletters?.map((newsletter: Newsletter) => (
            <NewsletterCard
              newsletter={newsletter}
              onClick={() => setUpdateState(false)}
            />
          ))}
        </div>
      </div>

      {/* Desktop V */}
      <div className="col-span-4 bg-white p-6 overflow-y-auto hidden md:block">
        <Switch>
          <Route
            path="/teacher/newsletter/add"
            component={() => <NewsletterAddPage refetch={() => refetch()} />}
          />
          <Route
            path="/teacher/newsletter/submit/:id"
            component={NewsletterSubmitPage}
          />
          <Route
            path="/teacher/newsletter/download/:id"
            component={NewsletterDownloadPage}
          />
          <Route
            path="/teacher/newsletter/:id"
            component={() => (
              <NewsletterDetailPage
                isUpdateState={isUpdateState}
                setUpdateState={(b: boolean) => setUpdateState(b)}
                refetch={() => refetch()}
              />
            )}
          />
        </Switch>
      </div>

      {/* Mobile V */}
      <div className="block md:hidden">
        <Switch>
          <Route
            path="/teacher/newsletter/add"
            component={() => <NewsletterAddPage refetch={() => refetch()} />}
          />
          <Route
            path="/teacher/newsletter/submit/:id"
            component={NewsletterSubmitPage}
          />
          <Route
            path="/teacher/newsletter/download/:id"
            component={NewsletterDownloadPage}
          />
          <Route
            path="/teacher/newsletter/:id"
            component={() => (
              <NewsletterDetailPage
                isUpdateState={isUpdateState}
                setUpdateState={(b: boolean) => setUpdateState(b)}
                refetch={() => refetch()}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
};

export default NewsletterPage;
