import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import {
  Blank,
  Chip,
  HorizontalScrollView,
  List,
  ListItem,
  Section,
  Tab,
  Tabs,
  TopNavbar,
  useRecoilValue,
} from '../../../reusable';
import { ActivityItem, ErrorBlank } from '../../../components';
import { Activity, GroupActivity, StudentGroup } from '../../../types';
import { fetcher } from '../../../plugins';
import { meState } from '../../../store';

export const ActivityPage = () => {
  const history = useHistory();
  // let { loading, error, data }: any = useQuery(GET_ACTIVITIES);
  const { error, data } = uQ('/student_groups', fetcher);
  const me = useRecoilValue(meState);

  useEffect(() => {
    if (me?.role === 'PARENT') history.replace('/student');
  }, [me]);

  const [subject, setSubject] = useState<string>();
  const [blankOpen, setBlankOpen] = useState(false);
  const [filter, setFilter] = useState<
    'all' | 'notice' | 'post' | 'survey' | 'before'
  >('all');
  // const [filter, setFilter] = useState<'new' | 'deadline' | 'done'>('new');
  // const [isOurKlass, setIsOurKlass] = useState(false);

  let studentGroups: StudentGroup[] = data?.me;

  let activities: Activity[] = [];

  let subjects: string[] = [];

  studentGroups = studentGroups?.map((sg: StudentGroup) => {
    if (sg?.group?.groupActivities) {
      const activitiesData = sg.group.groupActivities.map(
        (ga: GroupActivity) => ga?.activity,
      );
      for (let activity of activitiesData) {
        if (activity && !activities.map((el) => el.id).includes(activity.id)) {
          activities.push(activity);
        }
        if (activity?.subject && !subjects.includes(activity?.subject)) {
          subjects.push(activity.subject);
        }
      }
    }
    return sg;
  });

  if (subject) {
    activities = activities.filter((a: Activity) => a?.subject === subject);
  }

  switch (filter) {
    case 'all':
      activities = activities.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );
      break;
    case 'notice':
      activities = activities
        .filter((a: Activity) => a.type === 'NOTICE')
        .sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );
      break;
    case 'post':
      activities = activities
        .filter((a: Activity) => a.type === 'POST')
        .sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );
      break;
    case 'survey':
      activities = activities
        .filter((a: Activity) => a.type === 'SURVEY')
        .sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );
      break;
    case 'before':
      activities = activities
        .filter((a: Activity) =>
          a.endDate
            ? new Date(a.endDate).getTime() - new Date().getTime() >= 0
            : false,
        )
        .sort(
          (a: any, b: any) =>
            new Date(b.endDate).getTime() - new Date(a.endDate).getTime(),
        );
  }

  // if (loading) return <LoadingScreen />;

  if (error) {
    return <ErrorBlank />;
  }
  // console.log(activities);

  return (
    <>
      <TopNavbar
        title="활동"
        left={<div className="w-10 h-15" />}
        right={
          <div
            className="text-brand-1"
            onClick={() => {
              setBlankOpen(true);
              window?.location?.reload();
            }}
          >
            새로고침
          </div>
        }
      />

      {blankOpen && <Blank />}
      <Section tw={{ paddingX: 'px-0' }}>
        <HorizontalScrollView>
          <Chip
            text={'전체'}
            onClick={() => setSubject(undefined)}
            active={!subject}
            tw={{
              paddingY: 'py-2',
              minWidth: 'min-w-max',
              borderRadius: 'rounded-md',
            }}
          />
          {subjects.map((el: string) => (
            <Chip
              key={el}
              text={el}
              onClick={() => setSubject(el)}
              tw={{
                paddingY: 'py-3',
                minWidth: 'min-w-max',
                borderRadius: 'rounded-md',
              }}
              active={el === subject}
            />
          ))}
        </HorizontalScrollView>
      </Section>
      <Tabs>
        <Tab
          full
          text="전체"
          active={filter === 'all'}
          onClick={() => setFilter('all')}
        />
        <Tab
          full
          text="공지"
          active={filter === 'notice'}
          onClick={() => setFilter('notice')}
        />
        <Tab
          full
          text="과제"
          active={filter === 'post'}
          onClick={() => setFilter('post')}
        />
        <Tab
          full
          text="설문"
          active={filter === 'survey'}
          onClick={() => setFilter('survey')}
        />
        <Tab
          full
          text="마감전"
          active={filter === 'before'}
          onClick={() => setFilter('before')}
        />
      </Tabs>
      <div className="h-screen-15 overflow-y-auto scroll-box pb-20">
        <List style={{ marginTop: '0.5rem' }}>
          {activities.map(
            (a: Activity) =>
              a.id && (
                <ActivityItem
                  id={a.id}
                  activity={a}
                  subject={a.subject}
                  isSubmitted={
                    a?.studentActivities
                      ? !!a.studentActivities[0]?.isSubmitted
                      : false
                  }
                />
              ),
          )}
          {!activities.length && <ListItem>과제가 없습니다!</ListItem>}
        </List>
      </div>
    </>
  );
};
