import { forwardRef } from 'react';
import { Absent } from '../../types';
import { makeStartEndToString, makeTimeToString } from '../../utils';
import { Td } from '../Td';

interface ParentConfirmPaperProps {
  absent: any;
}

export const ParentConfirmPaper = forwardRef(
  (props: ParentConfirmPaperProps, ref: any) => {
    const { absent } = props;
    return (
      <div
        ref={ref}
        style={{ width: '560px', height: '792px' }}
        className="bg-white"
      >
        <div
          className="flex flex-col space-y-6"
          style={{
            width: '560px',
            height: '792px',
            paddingLeft: '80px',
            paddingRight: '80px',
            paddingTop: '67px',
            paddingBottom: '40px',
          }}
        >
          <div className="w-full text-center text-3xl font-bold min-w-max">
            학부모 확인서
          </div>
          <div className="flex justify-between">
            <div className="w-full">
              {[
                {
                  label: '학생이름',
                  title: absent?.student?.name,
                },
                {
                  label: '학번',
                  title:
                    absent?.studentGradeKlass +
                    ' ' +
                    absent?.studentNumber +
                    '번',
                },
                {
                  label: '신고일',
                  title: absent?.reportedAt,
                },
              ].map((item) => (
                <div className="flex items-center">
                  <div className="w-1/3 min-w-max text-sm text-gray-500">
                    {item.label}
                  </div>
                  <span className="w-2/3 min-w-max font-semibold text-lg">
                    {item.title}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div>
            <table className="w-full text-center font-bold">
              <tr>
                <Td innerClassName="">신고 유형</Td>
                <Td>{absent?.reportType}</Td>
              </tr>
              <tr>
                <Td innerClassName="">상세 내용</Td>
                <Td>{absent?.description}</Td>
              </tr>
              <tr>
                <Td innerClassName="">기간</Td>
                {absent?.reportType === "결석" ?
                <Td>
                  {absent?.startAt &&
                    absent?.endAt &&
                    absent?.reportType &&
                    makeStartEndToString(
                      absent.startAt,
                      absent.endAt,
                      absent.reportType,
                    )}
                </Td>
                :
                <Td>
                  {absent?.startAt &&
                    absent?.endAt &&
                    absent?.reportType &&
                    makeStartEndToString(
                      absent.startAt,
                      absent.endAt,
                      absent.reportType,
                    )}
                    {' '}
                    { (makeTimeToString(absent?.startAt) === '00:00') && (makeTimeToString(absent?.endAt) === '00:00')
                    ? ' '
                    :
                    makeTimeToString(absent?.startAt || '') + ' ~ ' + makeTimeToString(absent?.endAt || '')
                    }
                </Td>
                }
              </tr>
              <tr>
                <Td innerClassName="">신고사유</Td>
                <Td>{absent?.reason}</Td>
              </tr>
              <tr>
                <Td innerClassName="min-w-max">보호자 코멘트</Td>
                <Td> {absent?.parentComment}</Td>
              </tr>
            </table>
            <div className="whitespace-pre-line text-brand-1 pt-1">
              *{absent?.student?.name} 학생이{' '}
              {absent?.startAt &&
                absent?.endAt &&
                absent?.reportType &&
                makeStartEndToString(
                  absent.startAt,
                  absent.endAt,
                  absent.reportType,
                )}{' '}
              동안 학교에 미출석함을 확인하였습니다.
            </div>
          </div>
          <div>
            <div className="w-full flex items-center space-x-4">
              <div className="text-gray-600 w-full text-right min-w-max">
                신고자 학생본인
              </div>
              <div className="text-gray-800 w-1/4 text-right min-w-max text-lg font-bold">
                {absent?.student?.name}
              </div>
              {absent?.studentSignature ? (
                <img
                  style={{ width: '50px' }}
                  src={absent.studentSignature}
                  alt=""
                />
              ) : (
                <div
                  style={{
                    minWidth: '50px',
                    height: '5px',
                  }}
                />
              )}
            </div>
            <div className="w-full flex items-center space-x-4">
              <div className="text-gray-600 w-full text-right min-w-max">
                보호자
              </div>
              <div className="text-gray-800 w-1/4 text-right min-w-max text-lg font-bold">
                {absent?.student?.nokName}
              </div>
              {absent?.parentSignature ? (
                <img
                  style={{ width: '50px' }}
                  src={absent.parentSignature}
                  alt=""
                />
              ) : (
                <div
                  style={{
                    minWidth: '50px',
                    height: '5px',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
);
