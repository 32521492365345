import { forwardRef } from 'react';
import { Absent } from '../../types';
import { makeStartEndToString, makeTimeToString } from '../../utils';
import { Td } from '../Td';
import { Td2 } from '../Td2';

interface AbsentPaperProps {
  absent: any;
  approvalLine?: string[];
}

export const AbsentPaper = forwardRef((props: AbsentPaperProps, ref: any) => {
  const {
    absent,
    approvalLine = [
      'TEACHER',
      'PREHEAD',
      'HEAD',
      'PREPRINCIPAL',
      'PRINCIPAL',
      'VICEPRINCIPAL',
      'HEADPRINCIPAL',
    ],
  } = props;
  return (
    <div
      ref={ref}
      style={{ width: '560px', height: '792px' }}
      className="bg-white"
    >
      <div
        className="flex flex-col space-y-6"
        style={{
          width: '560px',
          height: '792px',
          paddingLeft: '80px',
          paddingRight: '80px',
          paddingTop: '67px',
          paddingBottom: '40px',
        }}
      >
        <div className=" w-full flex items-end justify-end">
          <table className="min-w-max">
            <tr>
              {approvalLine?.map((role: string) => {
                switch (role) {
                  case 'TEACHER':
                    return <Td2 style={{ height: '16px' }}>담임</Td2>;
                  case 'PREHEAD':
                      return <Td2 style={{ height: '16px' }}>학년계</Td2>;
                  case 'HEAD':
                    return <Td2 style={{ height: '16px' }}>학년부장</Td2>;
                  case 'PREPRINCIPAL':
                      return <Td2 style={{ height: '16px' }}>교무계</Td2>;
                  case 'PRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교무부장</Td2>;
                  case 'VICEPRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교감</Td2>;
                  case 'HEADPRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교장</Td2>;
                }
              })}
            </tr>
            <tr>
              {approvalLine?.map((role: string) => {
                switch (role) {
                  case 'TEACHER':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {absent?.teacherSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={absent?.teacherSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'PREHEAD':
                      return (
                        <Td2 style={{ height: '40px' }}>
                          {absent?.preHeadSignature && (
                            <img
                              style={{ width: '50px', margin: 'auto' }}
                              src={absent?.preHeadSignature}
                              crossOrigin="anonymous"
                              alt=""
                            />
                          )}
                        </Td2>
                      );
                  case 'HEAD':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {absent?.headSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={absent?.headSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'PREPRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {absent?.prePrincipalSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={absent?.prePrincipalSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'PRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {absent?.principalSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={absent?.principalSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'VICEPRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {absent?.viceprincipalSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={absent?.viceprincipalSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'HEADPRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {absent?.headprincipalSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={absent?.headprincipalSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                }
              })}
            </tr>
          </table>
        </div>
        <div className="w-full text-center text-3xl font-bold min-w-max">
          출결신고서
        </div>
        <div className="flex justify-between">
          <div className="w-full"></div>
          <div className="w-full flex items-center space-x-4">
            <div className="text-gray-600 w-full text-right min-w-max">
              학번:{'  '}
              {absent?.studentGradeKlass} {absent?.studentNumber}번 {'  '}
              이름:{'  '}
              {absent?.student?.name}
            </div>
          </div>
        </div>
        <div>
          <table className="w-full text-center font-bold">
            <tr>
              <Td innerClassName="min-w-max">출결 구분</Td>
              <Td>
                {absent?.description}
                {absent?.reportType}
              </Td>
            </tr>
            <tr>
              <Td innerClassName="min-w-max">해당 기간</Td>
              {(absent?.reportType === "결석") ?
                <Td>
                  {absent?.startAt &&
                    absent?.endAt &&
                    absent?.reportType &&
                    makeStartEndToString(
                      absent.startAt,
                      absent.endAt,
                      absent.reportType,
                    )}
                </Td>
                :
                <Td>
                  {absent?.startAt &&
                    absent?.endAt &&
                    absent?.reportType &&
                    makeStartEndToString(
                      absent.startAt,
                      absent.endAt,
                      absent.reportType,
                    )}{' '}
                    { (makeTimeToString(absent?.startAt) === '00:00') && (makeTimeToString(absent?.endAt) === '00:00')
                    ? ' '
                    :
                    makeTimeToString(absent?.startAt || '') + ' ~ ' + makeTimeToString(absent?.endAt || '')
                    }
                </Td>
                }
            </tr>
            <tr>
              <Td innerClassName="min-w-max">신고 사유</Td>
              <Td>{absent?.reason}</Td>
            </tr>
            <tr>
              <Td innerClassName="min-w-max">증빙 서류</Td>
              <Td>{absent?.evidenceType}</Td>
            </tr>
          </table>
          <div className="w-full mt-2">
            *위와 같이 본인의 출결 상황에 대하여 보호자 연서로 신고합니다.
          </div>
        </div>
        <div>
          <div className="w-full flex items-center space-x-4">
            <div className="text-gray-600 w-full text-right min-w-max">
              신고일:
            </div>
            <div className="text-gray-800 w-2/5 text-right min-w-max text-lg font-bold">
              {absent?.reportedAt}
            </div>
            <div style={{ minWidth: '50px' }} />
          </div>

          <div className="w-full flex items-center space-x-4">
            <div className="text-gray-600 w-full text-right min-w-max">
              신고자 학생본인:
            </div>
            <div className="text-gray-800 w-2/5 text-right min-w-max text-lg font-bold">
              {absent?.student?.name}
            </div>
            {absent?.studentSignature ? (
              <img
                style={{ width: '50px' }}
                src={absent.studentSignature}
                alt=""
              />
            ) : (
              <div style={{ minWidth: '50px', height: '5px' }} />
            )}
          </div>
          <div className="w-full flex items-center space-x-4">
            <div className="text-gray-600 w-full text-right min-w-max">
              보호자:
            </div>
            <div className="text-gray-800 w-2/5 text-right min-w-max text-lg font-bold">
              {absent?.student?.nokName}
            </div>
            {absent?.parentSignature ? (
              <img
                style={{ width: '50px' }}
                src={absent.parentSignature}
                alt=""
              />
            ) : (
              <div style={{ minWidth: '50px', height: '5px' }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
