import React, { useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import Viewer from 'react-viewer';
import Linkify from 'react-linkify';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import {
  BackButton,
  Blank,
  Button,
  Section,
  TopNavbar,
  useMutation,
  useQuery,
  utcToLocalDatetime,
} from '../../../reusable';
import { DELETE_NOTICE, GET_NOTICE } from '../../../query';
import { ErrorBlank, FileItem, SuperModal } from '../../../components';
import { NoticeAddPage } from './NoticeAddPage';

interface MatchParams {
  id: string;
}

interface NoticeDetailPageProps extends RouteComponentProps<MatchParams> {
  isUpdateState: boolean;
  setUpdateState: (b: boolean) => void;
}

const NoticeDetailPage: React.FC<NoticeDetailPageProps> = ({
  match,
  isUpdateState,
  setUpdateState,
}) => {
  const history = useHistory();
  let { id } = match.params;
  id = id.split('/')[0];
  let { loading, error, data, refetch }: any = useQuery(GET_NOTICE, {
    variables: { id: Number(id) },
  });

  const notice = data?.notice;
  const canEditNotice = data?.me?.canEditNotice;

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const [deleteNotice] = useMutation(DELETE_NOTICE, {
    variables: { id: Number(id) },
    onCompleted: () => {
      setDeleteModalOpen(false);
      history.push('/teacher/notice');
    },
    onError: async (err) => {
      console.error(err);
    },
  });

  let images: any[] = [];
  try {
    images = notice?.images
      ? notice?.images.map((image: any) => JSON.parse(image).url)
      : [];
  } catch (err) {
    console.error(err);
  }

  let viewerImages: any[] = [];

  for (let image of images) {
    viewerImages.push({
      src: image,
      title: '',
    });
  }

  let files: any[] = [];

  if (notice?.files) {
    try {
      notice?.files.map((f: any) => files.push(JSON.parse(f)));
    } catch (err) {
      console.error(err);
    }
  }

  if (isUpdateState) {
    return (
      <NoticeAddPage
        noticeData={notice}
        refetch={() => {
          refetch();
          setUpdateState(false);
        }}
      />
    );
  }

  return (
    <>
      {/* Mobile V */}
      <div className="block md:hidden bg-white">
        <div>
          {loading && <Blank />}
          {error && <ErrorBlank />}
          <TopNavbar left={<BackButton />} title="공지" />

          <Section>
            <div className="rounded-3xl px-3 py-1 bg-red-50 text-sm font-bold text-red-500 w-max">
              학교 공지
            </div>
            <div>
              <div className="text-lg font-bold text-grey-1 ">
                {data?.notice?.title}
              </div>
              <div className="text-grey-5 text-sm mb-2">
                {utcToLocalDatetime(data?.notice?.createdAt?.toString() || '')}
              </div>
            </div>
            <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
              {images?.map((image: string, i: number) => (
                <>
                  <div
                    className="w-full"
                    onClick={() => {
                      setActiveIndex(i);
                      setImagesModalOpen(true);
                    }}
                  >
                    <div className={`relative pb-3/5 rounded bg-gray-50`}>
                      <ExifOrientationImg
                        className={`absolute w-full h-full rounded object-cover`}
                        src={image}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </>
              ))}
            </div>
            {files?.length ? (
              <div>
                {files?.map((f: any) => (
                  <FileItem file={f} />
                ))}
              </div>
            ) : (
              <></>
            )}
            <div
              className={`text-grey-2 break-words whitespace-pre-line text-sm feedback_space`}
            >
              <Linkify>{data?.notice?.content}</Linkify>
            </div>
          </Section>
        </div>
      </div>

      {/* Desktop V */}
      <div className="bg-white border rounded-lg p-5">
        {loading && <Blank reversed />}
        {error && <ErrorBlank />}
        <SuperModal
          modalOpen={isDeleteModalOpen}
          setModalClose={() => setDeleteModalOpen(false)}
          width="w-max"
        >
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              정말 해당 과제를 삭제하시겠습니까?
            </div>
            <Button onClick={() => deleteNotice()}>삭제하기</Button>
          </Section>
        </SuperModal>

        <div className="hidden md:block">
          <Section>
            <div className="flex justify-between">
              <div className="rounded-md px-3 py-1 bg-red-50 text-sm font-bold text-red-500 w-max">
                학교 공지
              </div>
              {canEditNotice && (
                <div className="flex space-x-4 font-base text-gray-500 cursor-pointer">
                  <div
                    className="text-gray-700"
                    onClick={() => setUpdateState(true)}
                  >
                    수정
                  </div>
                  <div
                    className="text-red-400 cursor-pointer"
                    onClick={() => setDeleteModalOpen(true)}
                  >
                    삭제
                  </div>
                </div>
              )}
            </div>
            <div className="text-lg font-bold text-grey-1">
              {data?.notice?.title}
            </div>
            <div className="text-grey-3 text-sm">
              {utcToLocalDatetime(data?.notice?.createdAt?.toString() || '')}
            </div>
            <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
              {images?.map((image: string, i: number) => (
                <>
                  <div
                    className="w-full"
                    onClick={() => {
                      setActiveIndex(i);
                      setImagesModalOpen(true);
                    }}
                  >
                    <div className={`relative pb-3/5 rounded bg-gray-50`}>
                      <ExifOrientationImg
                        className={`absolute w-full h-full rounded object-cover`}
                        src={image}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </>
              ))}
            </div>
            {files?.length ? (
              <div>
                {files?.map((f: any) => (
                  <FileItem file={f} />
                ))}
              </div>
            ) : (
              <></>
            )}
            <div className={`text-grey-1 whitespace-pre-line feedback_space`}>
              <Linkify>{data?.notice?.content}</Linkify>
            </div>
          </Section>
        </div>
      </div>

      <div className="absolute">
        <Viewer
          visible={hasImagesModalOpen}
          rotatable={true}
          noImgDetails={true}
          //drag={false}
          scalable={false}
          images={viewerImages}
          onClose={() => {
            setImagesModalOpen(false);
          }}
          activeIndex={activeIndex}
        />
      </div>
    </>
  );
};

export default withRouter(NoticeDetailPage);
