import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SignaturePad from 'signature_pad';
import Linkify from 'react-linkify';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import {
  TopNavbar,
  BackButton,
  Section,
  Blank,
  useQuery,
  PhoneNumberField,
  Button,
  useMutation,
  TextField,
  BottomFixedView,
  CloseButton,
} from '../../../reusable';
import {
  CREATE_OR_UPDATE_STUDENT_NEWSLETTER,
  GET_NEWSLETTER_ADD_PAGE,
} from '../../../query';
import {
  ErrorBlank,
  FileItem,
  SuperSurveyComponent,
} from '../../../components';
import { Newsletter, StudentNewsletter } from '../../../types';
import { makeDateToString } from '../../../utils';

import Viewer from 'react-viewer';

interface NewsletterAddPageProps {
  newsletterData?: Newsletter;
  studentNewsletterData?: StudentNewsletter;
  setUpdateState?: (b: boolean) => void;
  refetch?: () => void;
}

let sigPad: any = null;

export const NewsletterAddPage: React.FC<NewsletterAddPageProps> = ({
  studentNewsletterData,
  newsletterData,
  setUpdateState,
  refetch,
}) => {
  const history = useHistory();

  const { loading, error, data } = useQuery(GET_NEWSLETTER_ADD_PAGE, {
    fetchPolicy: 'cache-and-network',
  });

  const [content, setContent] = useState<any>({});
  const [nokName, setNokName] = useState(data?.me?.nokName || '');
  const [nokPhone, setNokPhone] = useState(data?.me?.nokPhone);
  const [isLoading, setLoading] = useState(false);
  const [openSignModal, setOpenSignModal] = useState(false);
  const [sigPadData, setSigPadData] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (!nokName && data?.me?.nokName) {
      setNokName(data.me.nokName);
    }
    if (data?.me?.nokPhone) {
      setNokPhone(data.me.nokPhone);
    }
  }, [data]);

  useEffect(() => {
    if (!Object.values(content).length && studentNewsletterData) {
      try {
        setContent(JSON.parse(studentNewsletterData.content || '{}'));
      } catch (err) {}
    }
  }, [studentNewsletterData]);

  useEffect(() => {
    if (document.querySelector('canvas')) {
      sigPad =
        //@ts-ignore
        new SignaturePad(document.querySelector('canvas'), {
          onEnd: () => {
            setSigPadData(sigPad.toDataURL());
          },
        });
    }
  }, []);

  const [updateSN] = useMutation(CREATE_OR_UPDATE_STUDENT_NEWSLETTER, {
    variables: {
      data: JSON.stringify({
        nokName: nokName || data?.me?.nokName,
        nokPhone: nokPhone || data?.me?.nokPhone,
        newsletterId: newsletterData?.id,
        content: JSON.stringify(content),
        signature: sigPadData,
      }),
    },
    onCompleted: async ({ createOrUpdateStudentNewsletter }) => {
      setLoading(false);
      setOpenSignModal(false);
      if (createOrUpdateStudentNewsletter?.id) {
        refetch && refetch();
        setUpdateState && setUpdateState(false);
      }
    },
    onError: (error) => {
      setLoading(false);
      setOpenSignModal(false);
      setErrorMessage(error.message);
    },
  });

  const clearSignature = () => {
    sigPad && sigPad.clear();
    setSigPadData('');
    const canvas = document.querySelector('canvas');
    const ctx = canvas?.getContext('2d');
    ctx && ctx.clearRect(0, 0, canvas?.width || 0, canvas?.height || 0);
    ctx && ctx.beginPath();
  };

  const files: any[] = [];

  if (newsletterData?.files) {
    try {
      newsletterData?.files.map((f: any) => files.push(JSON.parse(f)));
    } catch (err) {
      console.error(err);
    }
  }

  let images: any[] = [];
  try {
    images = newsletterData?.images
      ? newsletterData?.images.map((el: any) => JSON.parse(el).url)
      : [];
  } catch (err) {
    console.error(err);
  }

  let viewerImages: any[] = [];

  for (let image of images) {
    viewerImages.push({
      src: image,
      title: '',
    });
  }

  const buttonDisabled =
    !nokPhone ||
    (newsletterData?.surveyContent
      ? Object.keys(content).length !==
        JSON.parse(newsletterData.surveyContent).length
      : false);

  return (
    <>
      {loading && <Blank />}
      {isLoading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar
        title="가정통신문"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <div>
          <h1 className="text-2xl font-semibold">{newsletterData?.title}</h1>
          <div className="text-gray-500 text-sm">
            {newsletterData?.createdAt &&
              makeDateToString(new Date(newsletterData.createdAt), '.')}
          </div>
        </div>
      </Section>
      <div className="h-0.5 w-full bg-gray-100" />
      <Section>
        {images?.map((image: string, i: number) => (
          <>
            <div
              className="w-full"
              onClick={() => {
                setActiveIndex(i);
                setImagesModalOpen(true);
              }}
            >
              <div className={`relative pb-3/5 rounded bg-gray-50`}>
                <ExifOrientationImg
                  loading="lazy"
                  className={`absolute w-full h-full rounded object-cover`}
                  src={image}
                  alt=""
                />
              </div>
            </div>
          </>
        ))}
        {files?.length ? (
          <div className="bg-gray-50">
            {files?.map((f: any) => (
              <FileItem file={f} />
            ))}
          </div>
        ) : (
          <></>
        )}
        <div
          className={`text-grey-2 break-words whitespace-pre-line text-sm feedback_space`}
        >
          <Linkify>{newsletterData?.content}</Linkify>
        </div>

        {newsletterData?.type === 'STUDENT_PARENTS_SURVEY' && (
          <>
            <div className="w-full">
              <TextField
                disabled = {data?.me?.nokName}
                label="보호자 이름"
                value={nokName}
                onChange={(e) => setNokName(e.target.value)}
                tw={{
                  borderColor: nokName ? 'border-gray-300' : 'border-red-700',
                }}
              />
            </div>
            <div className="w-full">
              <PhoneNumberField
                disabled = {data?.me?.nokPhone!=='' && data?.me?.nokPhone!=='010' &&  data?.me?.nokPhone.length >= 10}
                label="보호자 연락처"
                value={nokPhone || '010'}
                onChange={(e) => setNokPhone(e.target.value)}
                style={{
                  borderColor: !nokPhone ? 'rgba(185, 28, 28)' : '',
                }}
              />
            </div>
            <SuperSurveyComponent
              surveyContent={newsletterData?.surveyContent || ''}
              setContent={(c: any) => setContent(c)}
              content={content}
            />
            <Button
              disabled={buttonDisabled}
              tw={{
                backgroundColor: buttonDisabled ? 'bg-gray-400' : 'bg-brand-1',
              }}
              onClick={() => {
                const regExp=/^010(?:\d{4})\d{4}$/;
                if(nokPhone && !regExp.test(nokPhone.replace(/-/g, ''))){
                  alert('보호자 연락처를 확인해 주세요.');
                  setLoading(false);
                  return;
                }
                if (studentNewsletterData) {
                  setLoading(true);
                  updateSN();
                } else {
                  setOpenSignModal(true);
                }
              }}
            >
              제출하기
            </Button>
            {errorMessage && <div className="text-red-600">{errorMessage}</div>}
          </>
        )}
      </Section>
      <div className={openSignModal ? '' : 'hidden'}>
        <Blank text="" />
        <BottomFixedView
          tw={{
            borderRadius: 'rounded-xl',
            backgroundColor: 'bg-white',
            zIndex: 'z-100',
            bottom: '-bottom-4',
          }}
        >
          <div
            className="absolute top-2 right-3"
            onClick={() => setOpenSignModal(false)}
          >
            <CloseButton
              onClick={() => {
                setOpenSignModal(false);
                clearSignature();
              }}
            />
          </div>
          <Section>
            <div>
              <div className="text-gray-700 text-xl font-bold">학생 서명란</div>
              <div className="text-gray-500">
                아래 네모칸에 서명을 해주세요.
              </div>
            </div>
            <canvas
              width={
                window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6
              }
              height={
                window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4
              }
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            <div className="flex items-center justify-between space-x-2">
              <Button
                tw={{
                  backgroundColor: 'bg-white',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  color: 'text-current',
                  width: 'w-full',
                }}
                onClick={() => clearSignature()}
              >
                다시하기
              </Button>
              <Button
                tw={{ width: 'w-full' }}
                onClick={() => {
                  setLoading(true);
                  updateSN();
                }}
              >
                서명 제출하기
              </Button>
            </div>
          </Section>
          {isLoading && <Blank />}
        </BottomFixedView>
      </div>

      <div className="absolute">
        <Viewer
          visible={hasImagesModalOpen}
          rotatable={true}
          noImgDetails={true}
          //drag={false}
          scalable={false}
          images={viewerImages}
          onClose={() => {
            setImagesModalOpen(false);
          }}
          activeIndex={activeIndex}
        />
      </div>
    </>
  );
};
