import { FC, useState, useEffect } from 'react';
import { useQuery as uQ } from 'react-query';
import { fetcher } from '../../plugins';

interface GroupInfoCard {
  studentId: string;
}

export const GroupInfoCard: FC<GroupInfoCard> = ({ studentId }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const { error, data, refetch } = uQ(
    `/users/studentinfo?studentId=${studentId}`,
    fetcher,
  );

  let groups = new Map<number, string[]>();

  data?.studentGroups?.map((item: any) => {
    if (!groups.has(item.group?.year)) {
      groups.set(item.group?.year, []);
    }
    groups.get(item.group?.year)?.push(item.group?.name);
  });

  return (
    <div className="mb-5">
      <div className="text-xl font-semibold flex justify-between">
        <p>그룹정보카드</p>
      </div>
      <div className="bg-white border rounded-lg p-3 relative h-full">
        <table className="w-full">
          {Array.from(groups?.keys())
            .sort()
            .reverse()
            .map((year) => (
              <>
                <tr>
                  <td className="font-semibold w-32 border-b-2">{year}년</td>
                  <td className="border-b-2"></td>
                </tr>
                <tr>
                  <td className="w-full">
                    <div className=" flex flex-row flex-wrap mb-2">
                      {groups.get(year)?.map((gr) => (
                        <div className="flex  items-center space-x-2 w-max rounded-full px-2.5 py-0.5 m-1s mr-2 mt-1 bg-white border-2 border-brand-1 text-brand-1 whitespace-nowrap text-2sm font-bold">
                          <div className="whitespace-pre">{gr}</div>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              </>
            ))}
        </table>
      </div>
    </div>
  );
};
