import React, { Component } from 'react';

import * as Survey from 'survey-knockout';
import * as SurveyCreator from 'survey-creator';

import 'survey-knockout/modern.css';
import 'survey-creator/survey-creator.css';
import { creatorKoStrings } from './surveyKoStrings';

SurveyCreator.StylesManager.applyTheme('modern');

interface SurveyCreatorComponentProps {
  content: string;
  setContent: (content: string) => void;
}

export class SurveyCreatorComponent extends Component<SurveyCreatorComponentProps> {
  constructor(props: any) {
    super(props);
  }
  componentDidUpdate() {
    let svdaQuestionActions: any = document.getElementsByClassName(
      'svda_question_action svda_question_action--separated svd-main-color',
    );

    if (svdaQuestionActions && svdaQuestionActions.length) {
      for (let svdaItem of svdaQuestionActions) {
        svdaItem.children[1].innerText = '설정';
      }
    }

    let actionAddToTollBoxIcons: any = document.getElementsByClassName(
      'svd-primary-icon icon-actionaddtotoolbox',
    );
    if (actionAddToTollBoxIcons && actionAddToTollBoxIcons.length) {
      for (let icon of actionAddToTollBoxIcons) {
        icon.style.display = 'none';
      }
    }

    let questionActions: any =
      document.getElementsByTagName('question-actions');

    if (questionActions && questionActions.length) {
      for (let questionItem of questionActions) {
        questionItem.style.minWidth = 'max-content';
      }
    }
  }
  componentDidMount() {
    let options = {
      showLogicTab: true,
      questionTypes: [
        'text',
        'radiogroup',
        'checkbox',
        'comment',
        'image',
        'file',
      ],
    };

    const SurveyC: any = SurveyCreator;
    const { content, setContent } = this.props;

    SurveyC.localization.currentLocale = 'ko';

    SurveyC.localization.locales['ko'] = creatorKoStrings;

    // Survey.Serializer.addProperty('survey', {
    //   goNextPageAutomatic: true,
    //   showProgressBar: 'top',
    //   progressBarType: 'questions',
    // });

    Survey.Serializer.findProperty('survey', 'locale').visible = false;
    Survey.Serializer.findProperty('survey', 'logo').visible = false;
    Survey.Serializer.findProperty('survey', 'logoFit').visible = false;
    Survey.Serializer.findProperty('survey', 'logoHeight').visible = false;
    Survey.Serializer.findProperty('survey', 'logoPosition').visible = false;
    Survey.Serializer.findProperty('survey', 'logoWidth').visible = false;
    Survey.Serializer.findProperty('survey', 'cookieName').visible = false;
    Survey.Serializer.findProperty(
      'survey',
      'showPreviewBeforeComplete',
    ).visible = false;
    Survey.Serializer.findProperty('survey', 'pageNextText').visible = false;
    Survey.Serializer.findProperty('survey', 'pagePrevText').visible = false;
    Survey.Serializer.findProperty('survey', 'previewText').visible = false;
    Survey.Serializer.findProperty('survey', 'completeText').visible = false;
    Survey.Serializer.findProperty('survey', 'editText').visible = false;
    Survey.Serializer.findProperty('survey', 'startSurveyText').visible = false;
    Survey.Serializer.findProperty('survey', 'showNavigationButtons').visible =
      false;
    Survey.Serializer.findProperty('survey', 'showPrevButton').visible = false;
    Survey.Serializer.findProperty('survey', 'goNextPageAutomatic').visible =
      false;
    Survey.Serializer.findProperty('survey', 'firstPageIsStarted').visible =
      false;
    Survey.Serializer.findProperty('survey', 'showProgressBar').visible = false;
    Survey.Serializer.findProperty('survey', 'progressBarType').visible = false;
    Survey.Serializer.findProperty('survey', 'questionsOnPageMode').visible =
      false;
    Survey.Serializer.findProperty('survey', 'questionTitleLocation').visible =
      false;
    Survey.Serializer.findProperty(
      'survey',
      'questionDescriptionLocation',
    ).visible = false;
    Survey.Serializer.findProperty('survey', 'showQuestionNumbers').visible =
      false;
    Survey.Serializer.findProperty('survey', 'questionTitlePattern').visible =
      false;
    Survey.Serializer.findProperty('survey', 'questionStartIndex').visible =
      false;
    Survey.Serializer.findProperty('survey', 'questionErrorLocation').visible =
      false;
    Survey.Serializer.findProperty(
      'survey',
      'focusFirstQuestionAutomatic',
    ).visible = false;
    Survey.Serializer.findProperty('survey', 'questionsOrder').visible = false;
    Survey.Serializer.findProperty('survey', 'requiredText').visible = false;
    Survey.Serializer.findProperty('survey', 'maxTextLength').visible = false;
    Survey.Serializer.findProperty('survey', 'maxOthersLength').visible = false;
    Survey.Serializer.findProperty('survey', 'showPageTitles').visible = false;
    Survey.Serializer.findProperty('survey', 'showPageNumbers').visible = false;
    Survey.Serializer.findProperty('survey', 'calculatedValues').visible =
      false;
    Survey.Serializer.findProperty('survey', 'triggers').visible = false;
    Survey.Serializer.findProperty('survey', 'clearInvisibleValues').visible =
      false;
    Survey.Serializer.findProperty('survey', 'textUpdateMode').visible = false;
    Survey.Serializer.findProperty('survey', 'sendResultOnPageNext').visible =
      false;
    Survey.Serializer.findProperty('survey', 'storeOthersAsComment').visible =
      false;
    Survey.Serializer.findProperty('survey', 'focusOnFirstError').visible =
      false;
    Survey.Serializer.findProperty('survey', 'checkErrorsMode').visible = false;
    Survey.Serializer.findProperty('survey', 'navigateToUrl').visible = false;
    Survey.Serializer.findProperty(
      'survey',
      'navigateToUrlOnCondition',
    ).visible = false;
    Survey.Serializer.findProperty('survey', 'showCompletedPage').visible =
      false;
    Survey.Serializer.findProperty('survey', 'completedHtml').visible = false;
    Survey.Serializer.findProperty(
      'survey',
      'completedHtmlOnCondition',
    ).visible = false;
    Survey.Serializer.findProperty('survey', 'loadingHtml').visible = false;
    Survey.Serializer.findProperty('survey', 'completedBeforeHtml').visible =
      false;
    Survey.Serializer.findProperty('survey', 'maxTimeToFinish').visible = false;
    Survey.Serializer.findProperty('survey', 'title').visible = false;
    Survey.Serializer.findProperty('survey', 'description').visible = false;
    Survey.Serializer.findProperty('survey', 'pages').visible = false;
    Survey.Serializer.findProperty('survey', 'mode').visible = false;
    Survey.Serializer.findProperty('survey', 'maxTimeToFinishPage').visible =
      false;
    Survey.Serializer.findProperty('survey', 'showTimerPanel').visible = false;
    Survey.Serializer.findProperty('survey', 'showTitle').visible = false;
    Survey.Serializer.findProperty('survey', 'showTimerPanelMode').visible =
      false;
    Survey.Serializer.findProperty('page', 'title').visible = false;
    Survey.Serializer.findProperty('page', 'description').visible = false;
    Survey.Serializer.findProperty('page', 'name').visible = false;
    Survey.Serializer.findProperty('page', 'visibleIf').visible = false;
    Survey.Serializer.findProperty('page', 'enableIf').visible = false;
    Survey.Serializer.findProperty('page', 'requiredIf').visible = false;
    Survey.Serializer.findProperty('page', 'questionTitleLocation').visible =
      false;
    Survey.Serializer.findProperty('page', 'questionsOrder').visible = false;
    Survey.Serializer.findProperty(
      'page',
      'navigationButtonsVisibility',
    ).visible = false;
    Survey.Serializer.findProperty('page', 'visible').visible = false;
    Survey.Serializer.findProperty('page', 'maxTimeToFinish').visible = false;
    Survey.Serializer.findProperty('page', 'readOnly').visible = false;
    Survey.Serializer.findProperty('text', 'name').visible = false;
    Survey.Serializer.findProperty('text', 'visibleIf').visible = false;
    Survey.Serializer.findProperty('text', 'enableIf').visible = false;
    Survey.Serializer.findProperty('text', 'requiredIf').visible = false;
    Survey.Serializer.findProperty('text', 'defaultValueExpression').visible =
      false;
    Survey.Serializer.findProperty('text', 'inputType').visible = false;
    Survey.Serializer.findProperty('text', 'placeHolder').visible = false;
    Survey.Serializer.findProperty('text', 'autoComplete').visible = false;
    Survey.Serializer.findProperty('text', 'dataList').visible = false;
    Survey.Serializer.findProperty('text', 'size').visible = false;
    Survey.Serializer.findProperty('text', 'defaultValue').visible = false;
    Survey.Serializer.findProperty('text', 'correctAnswer').visible = false;
    Survey.Serializer.findProperty('text', 'textUpdateMode').visible = false;
    Survey.Serializer.findProperty('text', 'validators').visible = false;
    Survey.Serializer.findProperty('text', 'maxLength').visible = false;
    Survey.Serializer.findProperty('checkbox', 'choicesFromQuestion').visible =
      false;
    Survey.Serializer.findProperty('checkbox', 'choicesOrder').visible = false;
    Survey.Serializer.findProperty('checkbox', 'hideIfChoicesEmpty').visible =
      false;
    Survey.Serializer.findProperty('checkbox', 'choices').visible = false;
    Survey.Serializer.findProperty('checkbox', 'hasNone').visible = false;
    Survey.Serializer.findProperty('checkbox', 'noneText').visible = false;
    Survey.Serializer.findProperty('checkbox', 'hasSelectAll').visible = false;
    Survey.Serializer.findProperty('checkbox', 'hasSelectAll').visible = false;
    Survey.Serializer.findProperty('checkbox', 'hasOther').visible = false;
    Survey.Serializer.findProperty('checkbox', 'choicesVisibleIf').visible =
      false;
    Survey.Serializer.findProperty('checkbox', 'choicesEnableIf').visible =
      false;
    Survey.Serializer.findProperty('checkbox', 'colCount').visible = false;
    Survey.Serializer.findProperty('checkbox', 'selectAllText').visible = false;
    Survey.Serializer.findProperty('checkbox', 'maxSelectedChoices').visible =
      false;
    Survey.Serializer.findProperty('checkbox', 'choicesByUrl').visible = false;
    Survey.Serializer.findProperty('checkbox', 'hasComment').visible = false;
    Survey.Serializer.findProperty('file', 'hasComment').visible = false;
    Survey.Serializer.findProperty('file', 'acceptedTypes').visible = false;
    Survey.Serializer.findProperty('file', 'showPreview').visible = false;
    Survey.Serializer.findProperty('file', 'imageHeight').visible = false;
    Survey.Serializer.findProperty('file', 'imageWidth').visible = false;
    Survey.Serializer.findProperty('file', 'waitForUpload').visible = false;
    Survey.Serializer.findProperty('file', 'startWithNewLine').visible = false;
    Survey.Serializer.findProperty('file', 'hideNumber').visible = false;
    Survey.Serializer.findProperty('file', 'state').visible = false;
    Survey.Serializer.findProperty('file', 'titleLocation').visible = false;
    Survey.Serializer.findProperty('file', 'descriptionLocation').visible =
      false;
    Survey.Serializer.findProperty('file', 'indent').visible = false;
    Survey.Serializer.findProperty('file', 'width').visible = false;
    Survey.Serializer.findProperty('file', 'minWidth').visible = false;
    Survey.Serializer.findProperty('file', 'maxWidth').visible = false;
    Survey.Serializer.findProperty('file', 'valueName').visible = false;
    Survey.Serializer.findProperty('file', 'indent').visible = false;
    Survey.Serializer.findProperty('file', 'useDisplayValuesInTitle').visible =
      false;
    Survey.Serializer.findProperty('file', 'requiredErrorText').visible = false;
    Survey.Serializer.findProperty('file', 'needConfirmRemoveFile').visible =
      false;
    Survey.Serializer.findProperty('file', 'allowImagesPreview').visible =
      false;
    Survey.Serializer.findProperty('file', 'storeDataAsText').visible = false;
    Survey.Serializer.findProperty('radioGroup', 'hasComment').visible = false;
    Survey.Serializer.findProperty('radioGroup', 'showClearButton').visible =
      false;
    Survey.Serializer.findProperty('comment', 'rows').visible = false;
    Survey.Serializer.findProperty('comment', 'cols').visible = false;
    Survey.Serializer.findProperty('comment', 'textUpdateMode').visible = false;
    Survey.Serializer.findProperty('comment', 'maxLength').visible = false;
    Survey.Serializer.findProperty('image', 'text').visible = false;

    let creator = new SurveyC.SurveyCreator('creatorElement', options);

    creator.showToolbox = 'right';
    creator.showPropertyGrid = 'right';
    creator.rightContainerActiveItem('toolbox');
    creator.isAutoSave = true;
    creator.showState = true;

    creator.saveSurveyFunc = function (saveNo: any, callback: any) {
      try {
        let newText = JSON.parse(creator.text);
        newText.pages[0].elements = newText.pages[0].elements.map((el: any) => {
          if (el.type === 'file') {
            return {
              ...el,
              storeDataAsText: false,
              showPreview: true,
              maxSize: 1024 * 1024 * 20,
            };
          }
          return el;
        });
        setContent(JSON.stringify(newText));
      } catch (err) {
        console.error(err);
        setContent(creator.text);
      }

      callback(saveNo, true);
    };

    let defaultJSON = {
      pages: [{ name: '페이지 1' }],
    };

    creator.text = content || JSON.stringify(defaultJSON);

    let nav2: any = document.getElementsByClassName('nav-item svd-tab')[2];
    let nav3: any = document.getElementsByClassName('nav-item svd-tab')[3];

    if (nav2?.style) nav2.style.display = 'none';
    if (nav3?.style) nav3.style.display = 'none';

    let svdCommercialContainer: any = document.getElementsByClassName(
      'svd_commercial_container',
    )[0];

    if (svdCommercialContainer?.style)
      svdCommercialContainer.style.display = 'none';

    let svdPagesEditor: any =
      document.getElementsByTagName('svd-pages-editor')[0];

    if (svdPagesEditor?.style) svdPagesEditor.style.display = 'none';

    let svdDesinerTabbed: any = document.getElementsByClassName(
      'svd-designer-tabbed-container__tab-header',
    )[1];

    if (svdDesinerTabbed) svdDesinerTabbed.innerText = '설정';

    let svdaTitleEditorPlaceholder: any = document.getElementsByClassName(
      'svda-title-editor-placeholder',
    );
    if (svdaTitleEditorPlaceholder) {
      svdaTitleEditorPlaceholder[2].innerText = '제목을 입력해주세요.';
      svdaTitleEditorPlaceholder[3].innerText = '설명을 입력해주세요.';
    }

    let svdToolbar: any = document.getElementsByClassName('svd_toolbar')[1];

    if (svdToolbar && svdToolbar.style) svdToolbar.style.display = 'none';

    let body: any = document.getElementsByTagName('body')[0];

    if (body.style) {
      body.style =
        '--primary-color:#EE853A; --secondary-color:#EE853A; --primary-text-color:#676a6c; --secondary-text-color:#a7a7a7; --inverted-text-color:#ffffff; --primary-hover-color:#EC7623; --selection-border-color:#EE853A; --primary-icon-color:#3d4d5d; --primary-bg-color:#fff; --secondary-bg-color:#f4f4f4; --primary-border-color:#e7eaec; --secondary-border-color:#ddd; --error-color:#ed5565;';
    }

    let messageContainer: any = document.getElementsByClassName(
      'svd-empty-message-container',
    )[0];
    messageContainer?.remove();
  }
  render() {
    return <div id="creatorElement" />;
  }
}
