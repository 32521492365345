import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { BackButton, Section, TopNavbar } from '../../../reusable';
import { ReactComponent as RightArrow } from '../../../assets/svg/mypage-right-arrow.svg';

export const TeacherApplyPage: FC = () => {
  const history = useHistory();
  return (
    <>
      <div className="block md:hidden">
        <TopNavbar title="출결" left={<BackButton />} />

        <Section>
          <div
            className="flex justify-between items-center py-3 border-b-2 border-gray-6 cursor-pointer"
            onClick={() => history.push('/teacher/outing')}
          >
            <div>
              <div className="text-gray-800 font-sfpro font-bold">확인증</div>
              <div className="text-sm text-grey-5">
                조퇴증,외출증,확인증 / 조퇴,외출,확인 전 작성 서류
              </div>
            </div>
            <RightArrow />
          </div>
          <div
            className="flex justify-between items-center py-3 border-b-2 border-gray-6 cursor-pointer"
            onClick={() => history.push('/teacher/absent')}
          >
            <div>
              <div className="text-gray-800 font-sfpro font-bold">
                출결신고서
              </div>
              <div className="text-sm text-grey-5">
                구,결석계 / 조퇴,외출,결과,지각,결석 후 작성 서류
              </div>
            </div>
            <RightArrow />
          </div>
        </Section>
      </div>
    </>
  );
};
