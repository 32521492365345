import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from '../../../plugins';

export const SetChildrenPage = () => {
  const history = useHistory();

  useEffect(() => {
    api
      .get(`/users/children/login`)
      .then(({ data: { token } }: any) => {
        localStorage.setItem('childToken', token);
        history.replace('/student');
      })
      .catch(() => {
        localStorage.setItem('childError', 'true');
        alert('접속하신 보호자 계정의 자녀를 찾을 수 없습니다.');
        history.replace('/student');
      });
  }, []);

  return <div></div>;
};
