import { useQuery } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { Blank, Select } from '../../../reusable';
import { ErrorBlank } from '../../../components';
import { GET_TIMETABLE_PAGE } from '../../../query';
import { Group } from '../../../types';
import { TimetableDetailPage } from './TimetableDetailPage';

export const TimetablePage: FC = () => {
  const date = new Date();

  const [year, setYear] = useState(date.getFullYear());
  const [semester, setSemester] = useState(date.getMonth() + 1 > 6 ? 2 : 1);
  const [groupId, setGroupId] = useState<number | undefined>(0);

  const { loading, error, data } = useQuery(GET_TIMETABLE_PAGE);

  const groups = data?.klassGroups?.slice().sort((a: any, b: any) => {
    const aData = a?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);
    const bData = b?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);

    if (!aData || !bData) {
      return 0;
    }

    if (aData[1] && bData[1]) {
      if (aData[1] === bData[1]) {
        return Number(aData[2]) - Number(bData[2]);
      } else {
        return Number(aData[1]) - Number(bData[1]);
      }
    } else {
      return 0;
    }
  });

  useEffect(() => {
    if (!groupId && groups) {
      setGroupId(groups[0]?.id || 0);
    }
  }, [data]);

  return (
    <div className="col-span-6 ">
      {loading && <Blank reversed />}
      {error && <ErrorBlank />}
      <div className="w-full h-screen flex justify-center overflow-y-scroll scroll-box">
        <div className="w-full my-8 hidden md:block">
          <div className="w-full flex items-center justify-center space-x-2 mb-12">
            <Select
              value={year}
              onChange={(e) => setYear(Number(e.target.value))}
            >
              <option value={2021}>2021년</option>
              <option value={2022}>2022년</option>
              <option value={2023}>2023년</option>
            </Select>
            <Select
              value={groupId}
              onChange={(e) => setGroupId(Number(e.target.value))}
              placeholder="반 선택"
            >
              {groups?.map((group: Group) => (
                <option value={group.id}>{group.name}</option>
              ))}
            </Select>
            <Select
              value={semester}
              onChange={(e) => setSemester(Number(e.target.value))}
            >
              <option value={1}>1학기</option>
              <option value={2}>2학기</option>
            </Select>
          </div>
          <TimetableDetailPage
            year={year}
            semester={semester}
            groupId={groupId || 0}
          />
        </div>
      </div>
    </div>
  );
};
