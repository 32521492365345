import { FC, useRef, useState } from 'react';
import { jsPDF } from 'jspdf';
import { Button } from '../../reusable';
import { Absent } from '../../types';
import AbsentPdf from './AbsentPdf';
import { toJpeg } from 'html-to-image';
import { filter, find, groupBy, map } from 'lodash';
import { AbsentEvidenceType, AbsentPaperType } from '../types';
import * as loadImage from 'blueimp-load-image';
import imageCompression from 'browser-image-compression';
import {
  _getArrayBufferByFile,
  _getBlobByCanvas,
  _getImageMeta,
  _getPdfImageSize,
  padLeft,
} from '../../utils';

interface AbsentsDownloadViewProps {
  absents: Absent[];
  nowDate: string;
  setCsvData: (b: boolean) => void;
  isCsvData: boolean;
  approvalLine?: string[];
}

interface ReactPdfData {
  id: number;
  groupKey: string;
  data: string | Uint8Array;
  type: AbsentPaperType;
  width?: number;
  height?: number;
  evidenceType: AbsentEvidenceType;
  orientation?: number;
}

export const AbsentsDownloadView: FC<AbsentsDownloadViewProps> = ({
  absents = [],
  nowDate,
  setCsvData,
  isCsvData,
  approvalLine,
}) => {
  const [open, setOpen] = useState(false);
  const docRef = useRef<jsPDF>();
  const [isExtractData, setIsExtractData] = useState(false);
  const [extractDataCount, setExtractDataCount] = useState(-1);
  const [isProcessPdf, setIsProcessPdf] = useState(false);
  const [prodcessPdfCount, setIsProcessPdfCount] = useState(0);
  const reactPdfDatas = useRef<ReactPdfData[]>([]);

  const _initState = () => {
    setIsExtractData(false);
    setExtractDataCount(-1);
    setIsProcessPdf(false);
    setIsProcessPdfCount(0);
  };

  const _addImageToPdf = async (imageData: ReactPdfData) => {
    if (!docRef.current || !imageData.width || !imageData.height) {
      return null;
    }
    const { width: imageWidth, height: imageHeight, data } = imageData;
    const [width, height] = _getPdfImageSize(imageWidth, imageHeight);
    console.log('_addImageToPdf', 'width', width, 'height', height);
    try {
      docRef.current.addImage(
        data,
        'JPEG',
        0,
        0,
        width,
        height,
        undefined,
        'FAST',
      );
      docRef.current.addPage();
    } catch (e) {
      console.log('_addImageToPdf error  : ', e);
      console.log('_addImageToPdf data  : ', data);
      console.log('_addImageToPdf imageData  : ', imageData);
    }
  };

  const addReactToPdf = async (reactData: ReactPdfData) => {
    if (!docRef.current) {
      return null;
    }
    try {
      docRef.current.addImage(
        reactData.data,
        'JPEG',
        0,
        0,
        210,
        297,
        undefined,
        'FAST',
      );
      docRef.current.addPage();
    } catch (e) {
      console.log('addReactToPdf error  : ', e);
      console.log('addReactToPdf error reactData : ', reactData);
    }
  };

  const extractReactData = async (
    ref: any,
    type: AbsentPaperType,
    absent: Absent,
  ) => {
    if (!ref) {
      return null;
    }
    let imgData;
    try {
      imgData = await toJpeg(ref, { quality: 0.65, fontEmbedCSS: '' });
      await _getImageMeta(imgData);
      reactPdfDatas.current.push({
        id: absent.id as number,
        groupKey:
          (absent.startAt as string) + padLeft(absent.id as number, 10, '0'),
        data: imgData,
        type,
        evidenceType: absent.evidenceType as AbsentEvidenceType,
      });
    } catch (e) {
      console.log('extractReactData error  : ', e);
      console.log('extractReactData error type : ', type);
      console.log('extractReactData error absent : ', absent);
      console.log('extractReactData error imgData : ', imgData);
    } finally {
      if (type === AbsentPaperType.PARENT) {
        _nextExtractPdfData();
      }
    }
  };

  const extractImageData = async (absent: Absent, type: AbsentPaperType) => {
    const { id, evidenceFiles } = absent;

    if (!evidenceFiles?.length || !id) {
      return null;
    }

    for (let ef of evidenceFiles) {
      try {
        const evidenceFile =
          process.env.REACT_APP_API_URL + '/images?url=' + ef;
        //@ts-ignore
        const result = await loadImage(evidenceFile, {
          meta: true,
          orientation: true,
          canvas: true,
        });
        console.log('evidenceFile : ', evidenceFile);
        console.log('result : ', result);
        const blob = await _getBlobByCanvas(result.image);
        const file = new File([blob], 'temp_file.jpeg', { type: blob.type });
        const compressedFile = await imageCompression(file, {
          initialQuality: 0.6,
        });
        const arrayBuffer = await _getArrayBufferByFile(compressedFile);
        const unit8Array = new Uint8Array(arrayBuffer);
        const orientation = result.exif?.get('Orientation') || 1;
        const isChangeWidthHeight =
          orientation === 5 ||
          orientation === 6 ||
          orientation === 7 ||
          orientation === 8;
        console.log('orientation : ', orientation);
        console.log('isChangeWidthHeight : ', isChangeWidthHeight);
        reactPdfDatas.current.push({
          id,
          groupKey:
            (absent.startAt as string) + padLeft(absent.id as number, 10, '0'),
          data: unit8Array,
          type,
          width: isChangeWidthHeight
            ? result.originalHeight
            : result.originalWidth,
          height: isChangeWidthHeight
            ? result.originalWidth
            : result.originalHeight,
          evidenceType: absent.evidenceType as AbsentEvidenceType,
          orientation,
        });
      } catch (e) {
        console.log('extractImageData error  : ', e);
        console.log('extractImageData error evidenceFile : ', ef);
      } finally {
        _nextExtractPdfData();
      }
    }
  };

  const _finishDownloadPdf = () => {
    setIsExtractData(false);
    setIsProcessPdf(true);
    docRef.current = new jsPDF('p', 'mm', 'a4');
    const groupbyPdfDatas = groupBy(reactPdfDatas.current, 'groupKey');
    map(groupbyPdfDatas, (pdfDatas) => {
      const absentData = find(
        pdfDatas,
        (pdfData) => pdfData.type === AbsentPaperType.ABSENT,
      );
      const parentData = find(
        pdfDatas,
        (pdfData) => pdfData.type === AbsentPaperType.PARENT,
      );
      const imageDatas = filter(
        pdfDatas,
        (pdfData) => pdfData.type === AbsentPaperType.IMAGE,
      );
      if (absentData && (parentData || imageDatas)) {
        addReactToPdf(absentData);
        absentData.evidenceType === AbsentEvidenceType.PARENT &&
          parentData &&
          addReactToPdf(parentData);
        absentData.evidenceType !== AbsentEvidenceType.PARENT &&
          imageDatas?.length &&
          map(imageDatas, (image) => _addImageToPdf(image));
      }
      setIsProcessPdfCount((prev) => prev + 1);
    });
    docRef.current.deletePage(docRef.current.getNumberOfPages());
    docRef.current.save(`출결신고서 ${nowDate}.pdf`);
    setIsProcessPdf(false);
  };

  const _nextExtractPdfData = () => {
    if (!absents) {
      return null;
    }
    if (extractDataCount === absents.length - 1) {
      setExtractDataCount((prev) => prev + 1);
      _finishDownloadPdf();
    }
    if (extractDataCount >= absents.length - 1) {
      return;
    }
    setExtractDataCount((prev) => prev + 1);
  };

  const _getProgress = () => {
    if (!absents || !reactPdfDatas.current) {
      return 0;
    }

    if (isExtractData) {
      return (extractDataCount / absents.length) * 100;
    }

    if (isProcessPdf) {
      return (prodcessPdfCount / reactPdfDatas.current.length) * 100;
    }
    return 0;
  };

  return (
    <>
      {open && (
        <div
          className="fixed inset-0 w-full overflow-y-scroll h-screen z-100 bg-littleblack"
          style={{ margin: 0 }}
        >
          <div className="w-full h-full flex items-start">
            <div className="py-6">
              <div className="w-screen mb-2 flex items-center justify-center">
                <div
                  className="fixed right-16 top-7 text-3xl font-bold text-white cursor-pointer"
                  onClick={() => {
                    setOpen(false);
                    _initState();
                  }}
                >
                  닫기
                </div>
                <div className="min-w-max">
                  {(isExtractData || isProcessPdf) && (
                    <>
                      <div
                        id="progressOuterMax"
                        className="min-w-100 relative p-0"
                        style={{
                          background: '#eceeef',
                          marginBottom: '0.5rem',
                        }}
                      >
                        <div
                          id="barStatus"
                          style={{
                            height: '15px',
                            backgroundColor: '#2d7bb7',
                            width: `${_getProgress()}%`,
                          }}
                        />
                      </div>

                      {isExtractData && (
                        <div className="py-2 text-brand-1 text-center font-bold">
                          {`${extractDataCount < 0 ? 0 : extractDataCount} / ${
                            absents.length
                          } 데이터 추출중입니다.`}
                        </div>
                      )}
                      {isProcessPdf && (
                        <div className="py-2 text-brand-1 text-center font-bold">
                          {`${
                            prodcessPdfCount < 0
                              ? 0
                              : reactPdfDatas.current.length
                          } / ${absents.length} 데이터 처리중입니다.`}
                        </div>
                      )}
                    </>
                  )}
                  <div className="w-full flex items-center justify-center">
                    <Button
                      text={'PDF로 내보내기'}
                      disabled={isExtractData || isProcessPdf}
                      style={{ zIndex: 1000 }}
                      tw={{
                        backgroundColor: isExtractData
                          ? 'bg-gray-400'
                          : 'bg-brand-1',
                      }}
                      onClick={() => {
                        reactPdfDatas.current = [];
                        setIsExtractData(true);
                        setExtractDataCount(0);
                      }}
                    />
                  </div>
                  <div className="text-center text-white text-lg py-2">
                    *{absents.length} 장의 결석계 서류를 다운로드합니다.
                    <br />
                    서류양이 많을 경우 다운로드가 최대 수 분까지 지연될 수
                    있습니다. <br />
                    출결 신고서 화면 상단에서 날짜 범위를 좁혀 원하는 서류만
                    선택하면 다운로드 속도가 빨라집니다. <br />
                    현재 화면에서는 서류의 일부만 보여집니다. 모든 서류 확인을 위해서는 내보내기를 하시기 바랍니다.
                  </div>
                </div>
              </div>
              <div className="flex space-x-2 items-center justify-center">
                {map(
                  absents,
                  (absent, index) =>
                    extractDataCount - 5 <= index &&
                    index <= extractDataCount + 5 && (
                      <AbsentPdf
                        key={absent.id}
                        absent={absent}
                        extractReactData={extractReactData}
                        extractImageData={extractImageData}
                        nextExtractPdfData={_nextExtractPdfData}
                        isDownload={index === extractDataCount}
                        approvalLine={approvalLine}
                      />
                    ),
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Button
        onClick={() => {
          alert('승인 완료된 결석계만 다운로드됩니다. 계속 진행하시겠습니까?');
          !isCsvData && setCsvData(true);
          setOpen(true);
        }}
        tw={{
          minWidth: 'min-w-max',
          paddingY: 'py-2.5',
          height: 'h-auto',
          paddingX: 'px-4',
          backgroundColor: 'bg-blue-500',
          width: 'w-full',
        }}
        text="PDF"
      />
    </>
  );
};
