import { FC, useState, useEffect } from 'react';
import { useQuery as uQ } from 'react-query';
import { fetcher } from '../../plugins';
import { getThisYear } from '../../utils/time-util';
import { Link } from 'react-router-dom';
import { TextField, Select, useMutation } from '../../reusable';
import { api } from '../../plugins';
import svgUserIcon from '../../assets/svg/user_icon.svg';
import { SEND_PARENT_SIGNUP_WITH_STUDENT } from '../../query';

interface StudentInfoCardProps {
  studentId: string;
}

export const StudentInfoCard: FC<StudentInfoCardProps> = ({ studentId }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const [studTel, setStudTel] = useState('');
  const [studBarcode, setStudBarcode] = useState('');
  const [studMyRole, setStudMyRole] = useState('');
  const [studHopePath, setStudHopePath] = useState('');
  const [studHopeMajor, setStudHopeMajor] = useState('');
  const [studExpiredObj, setStudExpiredObj] = useState({
    key: 0,
    name: '학적변동없음',
    etc1: 'false',
  });
  const [customProfile, setCustomProfile] = useState<
    File | string | null | undefined
  >('');

  const [parentName, setParentName] = useState('');
  const [parentTel, setParentTel] = useState('');

  const { data: studentStates } = uQ(`/codes?category=studentstates`, fetcher);

  const { data, refetch } = uQ(
    `/users/studentinfo?studentId=${studentId}`,
    fetcher,
  );

  const { data: parentData, refetch: parentRefetch } = uQ(
    `/users/parentinfo?studentId=${studentId}`,
    fetcher,
  );

  const hasParent = (tel: string) => {
    let rst = false;
    parentData?.parents.map((item: any) => {
      if (parentData.nokPhone === item.phone) {
        rst = true;
      }
    });

    return rst;
  };

  const [sendParentSignup] = useMutation(SEND_PARENT_SIGNUP_WITH_STUDENT, {
    variables: {
      studentId: Number(studentId),
    },
    onCompleted: () => {
      alert('보호자 회원가입 메시지 발송이 완료되었습니다.');
    },
    onError: (err) => {
      alert(err.message || '메시지 발송 중 오류가 발생하였습니다.');
    },
  });

  const studentUpdate = () =>
    api({
      method: 'post',
      url: 'users/studentUpdate',
      data: {
        studentId,
        studTel,
        studBarcode,
        studHopePath,
        studHopeMajor,
        studExpired: studExpiredObj?.etc1 === 'true' ? true : false,
        studExpiredReason: studExpiredObj?.name
          ? studExpiredObj?.name
          : '학적변동없음',
        parentName,
        parentTel,
      },
    })
      .then(() => {
        refetch();
        setIsEditMode(false);
        parentRefetch();
      })
      .catch((err) => {
        console.log('err', err.message);
      });

  const klass = data?.studentGroups?.filter(
    (item: any) =>
      item?.group?.type === 'KLASS' && item?.group?.year === getThisYear(),
  );

  useEffect(() => {
    setStudTel(data?.phone);
    setStudBarcode(data?.barcode);
    setStudMyRole(data?.myRole);
    setStudHopePath(data?.hopePath);
    setStudHopeMajor(data?.hopeMajor);
    setParentName(data?.nokName);
    setParentTel(data?.nokPhone);
  }, [data]);

  useEffect(() => {
    if (data && studentStates) {
      const selItem = studentStates?.filter(
        (item: any) => item.name === data?.expiredReason,
      );
      if (selItem) {
        setStudExpiredObj(selItem[0]);
      }
    }
  }, [data, studentStates]);

  const klassName = klass
    ? klass[0]?.group?.name + ' ' + klass[0]?.studentNumber + '번'
    : '';
  console.log(klass);

  return (
    <div className="mb-5">
      <div className="text-xl font-semibold flex justify-between">
        <p>학생정보카드</p>
        <p> {data?.firstVisit && <div className="text-red-500">미가입</div>}</p>
      </div>
      <div className="bg-white border rounded-lg p-3 relative h-full">
        <div className="px-30 py-3 w-full border md:hidden">
          <img src={data?.profile} alt="" loading="lazy" />
        </div>
        <table className="w-full">
          <tr>
            <td className="font-semibold w-32 border-b-2">학번</td>
            <td className="border-b-2">
              {klassName} ({studMyRole})
            </td>
            <td className="p-4 w-60 border hidden md:table-cell" rowSpan={10}>
              <img
                src={data?.profile}
                alt=""
                loading="lazy"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = svgUserIcon;
                  currentTarget.className = 'w-full';
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="font-semibold border-b-2">이름</td>
            <td className="border-b-2">{data?.name}</td>
          </tr>
          <tr>
            <td className="font-semibold border-b-2">이메일</td>
            <td className="border-b-2">{data?.email}</td>
          </tr>
          <tr>
            <td className="font-semibold border-b-2">전화번호</td>
            <td className="border-b-2">
              {isEditMode ? (
                <TextField
                  placeholder="전화번호 입력"
                  value={studTel}
                  tw={{
                    width: 'w-48',
                    height: 'h-5',
                    borderColor: 'border-brand-1',
                  }}
                  onChange={(e) => {
                    setStudTel(e.target.value);
                  }}
                />
              ) : (
                studTel
              )}
            </td>
          </tr>
          <tr>
            <td className="font-semibold border-b-2">바코드</td>
            <td className="border-b-2">
              {isEditMode ? (
                <TextField
                  placeholder="바코드 입력"
                  value={studBarcode}
                  tw={{
                    width: 'w-48',
                    height: 'h-5',
                    borderColor: 'border-brand-1',
                  }}
                  onChange={(e) => {
                    setStudBarcode(e.target.value);
                  }}
                />
              ) : (
                studBarcode
              )}
            </td>
          </tr>
          {/* <tr>
            <td className="font-semibold border-b-2">일인일역</td>
            <td className="border-b-2">
              {isEditMode ? (
                <TextField
                  placeholder="일인일역 입력"
                  value={studMyRole}
                  tw={{
                    width: 'w-96',
                    height: 'h-5',
                    borderColor: 'border-brand-1',
                  }}
                  onChange={(e) => {
                    setStudMyRole(e.target.value);
                  }}
                />
              ) : (
                studMyRole
              )}
            </td>
          </tr> */}
          <tr>
            <td className="font-semibold border-b-2">희망진로</td>
            <td className="border-b-2">
              {isEditMode ? (
                <TextField
                  placeholder="희망진로 입력"
                  value={studHopePath}
                  tw={{
                    width: 'w-48',
                    height: 'h-5',
                    borderColor: 'border-brand-1',
                  }}
                  onChange={(e) => {
                    setStudHopePath(e.target.value);
                  }}
                />
              ) : (
                studHopePath
              )}
            </td>
          </tr>
          <tr>
            <td className="font-semibold border-b-2">희망학과</td>
            <td className="border-b-2">
              {isEditMode ? (
                <TextField
                  placeholder="희망학과 입력"
                  value={studHopeMajor}
                  tw={{
                    width: 'w-48',
                    height: 'h-5',
                    borderColor: 'border-brand-1',
                  }}
                  onChange={(e) => {
                    setStudHopeMajor(e.target.value);
                  }}
                />
              ) : (
                studHopeMajor
              )}
            </td>
          </tr>
          <tr>
            <td className="font-semibold border-b-2">학적상태</td>
            <td className="border-b-2">
              {isEditMode ? (
                <Select
                  value={studExpiredObj?.key}
                  tw={{
                    width: 'w-48',
                    height: 'h-6',
                    borderColor: 'border-brand-1',
                  }}
                  onChange={(e) => {
                    const selItem = studentStates.filter(
                      (item: any) => item.key === Number(e.target.value),
                    );
                    selItem
                      ? setStudExpiredObj(selItem[0])
                      : setStudExpiredObj({
                          key: 0,
                          name: '학적변동없음',
                          etc1: 'false',
                        });
                  }}
                  placeholder="학적 선택"
                >
                  {studentStates.map((item: any) => (
                    <option
                      className={` ${
                        item?.etc1 === 'true' && 'text-red-500'
                      }            
                  `}
                      value={item.key}
                    >
                      {item.name}
                    </option>
                  ))}
                </Select>
              ) : (
                <div
                  className={` ${
                    studExpiredObj?.etc1 === 'true' && 'text-red-500'
                  }            
              `}
                >
                  {studExpiredObj?.name}
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td className="font-semibold w-32 border-b-2">보호자이름</td>
            <td className="border-b-2">
              {isEditMode ? (
                <TextField
                  placeholder="이름 입력"
                  value={parentName}
                  tw={{
                    width: 'w-48',
                    height: 'h-5',
                    borderColor: 'border-brand-1',
                  }}
                  onChange={(e) => {
                    setParentName(e.target.value);
                  }}
                />
              ) : (
                parentName
              )}
            </td>
          </tr>
          <tr>
            <td className="font-semibold border-b-2">보호자전화번호</td>
            <td className="border-b-2">
              {isEditMode ? (
                <TextField
                  placeholder="전화번호 입력"
                  value={parentTel}
                  tw={{
                    width: 'w-48',
                    height: 'h-5',
                    borderColor: 'border-brand-1',
                  }}
                  onChange={(e) => {
                    setParentTel(e.target.value);
                  }}
                />
              ) : (
                <div className="flex">
                  {parentTel}
                  {hasParent(parentTel) === false && (
                    <button
                      className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-2 focus:outline-none"
                      onClick={() => {
                        sendParentSignup();
                      }}
                    >
                      가입요청알림톡 보내기
                    </button>
                  )}
                </div>
              )}
            </td>
          </tr>
        </table>
        <div className="flex justify-end">
          {isEditMode && (
            <>
              {/* <input
                id="profileupload"
                type="file"
                accept=".pdf, .png, .jpeg, .jpg"
                className="hidden"
                onChange={(e) =>
                  e.target.validity.valid &&
                  setCustomProfile(e.target.files?.item(0))
                }
              />
              <label htmlFor="profileupload">
                <div className="w-20 px-2 py-1 mr-5 hover:bg-blue-700 hover:text-light_orange bg-blue-300 text-light_orange text-sm cursor-pointer  rounded-md">
                  사진등록
                </div>
              </label> */}
              <button
                className="hover:bg-red-500 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-2 py-1 focus:outline-none"
                onClick={() => {
                  setIsEditMode(false);
                }}
              >
                취소
              </button>
            </>
          )}
          <button
            className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-2 py-1 focus:outline-none"
            onClick={() => {
              if (isEditMode) {
                studentUpdate();
              }
              setIsEditMode(true);
            }}
          >
            {isEditMode ? '저장하기' : '수정하기'}
          </button>
        </div>
      </div>
    </div>
  );
};
