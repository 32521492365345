import {
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  Section,
  TextField,
  TopNavbar,
  useMutation,
} from '../../../reusable';
import React, { FC, useState } from 'react';
import {
  RouteComponentProps,
  useHistory,
  useParams,
  withRouter,
} from 'react-router-dom';
import { RESET_NEW_PASSWORD } from '../../../query';
import { passwordCheck } from '../../../utils';

interface MatchParams {
  id: string;
}

const ResetPasswordPage: FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const history = useHistory();
  // const { id } = match.params;
  const { id } = useParams<MatchParams>();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errM, setErrM] = useState('');

  const [resetNewPassword] = useMutation(RESET_NEW_PASSWORD, {
    variables: {
      token: id,
      newPassword,
    },
    onCompleted: (result) => {
      alert('비밀번호 변경이 완료되었습니다. 다시 로그인해주세요.');
      history.push('/login');
    },
    onError: (err) => {
      setLoading(false);
      setErrM(err.message);
    },
  });

  return (
    <>
      <TopNavbar
        title="비밀번호 변경"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      {loading && <Blank />}
      {/* <div className="w-full h-screen flex flex-col items-center justify-center text-center"> */}
      <Section>
        <h1 className="text-2xl font-bold">새로운 비밀번호 입력</h1>
        <TextField
          type="password"
          id="password"
          label="새 비밀번호"
          placeholder="새로운 비밀번호를 입력해주세요."
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        {newPassword && !passwordCheck(newPassword) && (
          <div className="text-red-600">안전한 비밀번호를 입력하세요.</div>
        )}
        <TextField
          type="password"
          id="password"
          label="비밀번호 확인"
          placeholder="동일한 비밀번호를 한번 더 입력해주세요."
          value={confirmPassword}
          style={{ marginTop: '0.5rem' }}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {confirmPassword && confirmPassword !== newPassword && (
          <div className="text-red-600">비밀번호 확인이 일치하지 않습니다.</div>
        )}
        <div className="text-grey-3">
          &nbsp; 안전한 개인정보의 보호를 위해 문자,숫자,특수문자가 포함된 8자
          이상의 비밀번호를 입력하세요.
          <br />
          &nbsp; 사용 가능한 특수문자는 ! @ # $ % & * ? 입니다. 
        </div>
        {errM && <div className="text-red-600">{errM}</div>}
      </Section>
      <BottomFixedView>
        <Section>
          <Button
            disabled={
              !passwordCheck(newPassword) || confirmPassword !== newPassword
            }
            tw={{
              backgroundColor: !newPassword ? 'bg-gray-300' : 'bg-brand-1',
            }}
            onClick={async () => {
              await setLoading(true);
              await resetNewPassword();
            }}
          >
            비밀번호 재설정하기
          </Button>
        </Section>
      </BottomFixedView>
      {/* </div> */}
    </>
  );
};

export default withRouter(ResetPasswordPage);
