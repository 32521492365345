import React, { FC } from 'react';
import { Fieldtrip } from '../../types';
import { makeDateToString2 } from '../../utils';

interface FieldtripSeparatePaperProps {
  studentName?: string;
  studentGradeKlass?: string;
  index: number;
  content: any;
  fieldtrip: Fieldtrip;
  type: '신청서' | '결과보고서';
}

export const FieldtripSeparatePaper: FC<FieldtripSeparatePaperProps> = ({
  studentName,
  studentGradeKlass,
  index,
  content,
  fieldtrip,
  type = '신청서',
}) => {
  if (type === '신청서') {
    return (
      <div className="px-12 pt-10">
        <div className="flex justify-center w-full">
          <h5 className="font-bold text-2xl">
            「학교장허가 가정학습」 {type}(별지)
          </h5>
        </div>
        <div className="flex justify-center -mt-0.5 pt-10">
          <table className="table-fixed border-black border-2 w-full ">
            <thead>
              <tr className="h-8">
                <th className="border-black border bg-gray-200" colSpan={3}>
                  성명
                </th>
                <td className="border-black border text-center" colSpan={6}>
                  {studentName}
                </td>
                <th className="border-black border bg-gray-200" colSpan={7}>
                  {studentGradeKlass}
                </th>
                <th className="border-black border" colSpan={7}>
                  {index + 1}일차
                </th>
              </tr>
              <tr className="h-8">
                <th className="border-black border bg-gray-200" colSpan={9}>
                  교외체험학습 기간
                </th>
                <td className="border-black border text-center" colSpan={14}>
                  {makeDateToString2(fieldtrip?.startAt || '')} ~{' '}
                  {makeDateToString2(fieldtrip?.endAt || '')} (
                  {fieldtrip?.usedDays}) 일간
                </td>
              </tr>
            </thead>
            <tbody>
              {/* 왼쪽 교외체험 학습결과 || rowSpan 총 8개인데 10개여야 정상 작동 하는 버그 있습니다.*/}
              <td
                className="border-black border h-full text-center font-bold"
                colSpan={3}
                rowSpan={9}
              >
                교외체험 <br /> 학습계획
              </td>

              {/* 첫째 줄 start */}
              <tr>
                <td className="border-black border h-6 text-center" colSpan={2}>
                  교시
                </td>
                <td className="border-black border h-6 text-center" colSpan={2}>
                  교과
                </td>
                <th
                  className="border-black border h-6 text-center"
                  colSpan={16}
                >
                  ※ 학습할 내용을 기록합니다.
                </th>
              </tr>
              {/* 첫째 줄 end */}

              {new Array(7).fill('').map((dlldl: any, i: number) => (
                <>
                  <tr>
                    <td
                      className="border-black border h-20 text-center text-blue-700"
                      colSpan={2}
                    >
                      {i + 1}
                    </td>
                    <td
                      className="border-black border h-20 text-center"
                      colSpan={2}
                    >
                      {content['subject' + (i + 1)] &&
                        content['subject' + (i + 1)]}
                    </td>
                    <td
                      className="border-black border h-20 text-center"
                      colSpan={16}
                    >
                      {content['content' + (i + 1)] &&
                        content['content' + (i + 1)]}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else if (type === '결과보고서') {
    return (
      <div className="px-12 pt-10">
        <div className="flex justify-center w-full">
          <h5 className="font-bold text-2xl">
            「학교장허가 가정학습」 {type}(별지)
          </h5>
        </div>
        <div className="flex justify-center mb-20 mt-10">
          <table className="table-fixed border-black border-2 w-full ">
            <thead>
              <tr className="h-8">
                <th className="border-black border bg-gray-200" colSpan={3}>
                  성명
                </th>
                <td className="border-black border text-center" colSpan={6}>
                  {studentName}
                </td>
                <th
                  className="border-black border bg-gray-200 text-center"
                  colSpan={7}
                >
                  {studentGradeKlass}
                </th>
                <th className="border-black border text-center" colSpan={7}>
                  {index + 1}일차
                </th>
              </tr>
              <tr className="h-8">
                <th
                  className="border-black border bg-gray-200 text-center"
                  colSpan={9}
                >
                  교외체험학습 기간
                </th>
                <td className="border-black border text-center" colSpan={14}>
                  {makeDateToString2(fieldtrip?.startAt || '')} ~{' '}
                  {makeDateToString2(fieldtrip?.endAt || '')} (
                  {fieldtrip?.usedDays}) 일간
                </td>
              </tr>
            </thead>
            <tbody>
              {/* 왼쪽 교외체험 학습결과 || rowSpan 총 8개인데 10개여야 정상 작동 하는 버그 있습니다.*/}
              <td
                className="border-black border text-center h-full font-bold"
                colSpan={3}
                rowSpan={10}
              >
                교외체험 <br />
                학습결과
              </td>

              {/* 첫째 줄 start */}
              <tr>
                <td
                  className="border-black border text-center h-6"
                  colSpan={2}
                  rowSpan={2}
                >
                  교시
                </td>
                <td
                  className="border-black border text-center h-6"
                  colSpan={4}
                  rowSpan={2}
                >
                  교과
                </td>
                <th
                  className="border-black border text-center h-6"
                  colSpan={10}
                  rowSpan={2}
                >
                  학습내용
                </th>
                <th className="border-black border text-center h-3" colSpan={4}>
                  학습확인
                </th>
              </tr>
              <tr>
                <th className="border-black border text-center h-3" colSpan={2}>
                  학생
                </th>
                <th className="border-black border text-center h-3" colSpan={2}>
                  교사
                </th>
              </tr>
              {/* 첫째 줄 end */}

              {new Array(7).fill('').map((dlldl: any, i: number) => (
                <>
                  <tr>
                    <td
                      className="border-black border h-20 px-4 text-center text-blue-700"
                      colSpan={2}
                    >
                      {i + 1}
                    </td>
                    <td
                      className="border-black border h-20 px-4 text-center"
                      colSpan={4}
                    >
                      {content['subject' + (i + 1)] &&
                        content['subject' + (i + 1)]}
                    </td>
                    <td
                      className="border-black border h-20 px-4 text-center"
                      colSpan={10}
                    >
                      {content['content' + (i + 1)] &&
                        content['content' + (i + 1)]}
                    </td>

                    <td
                      className="border-black border h-20 px-4 text-center"
                      colSpan={2}
                    >
                      O
                    </td>
                    <td
                      className="border-black border h-20 px-4 text-center"
                      colSpan={2}
                    >
                      O
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
