import { FC, useState, useEffect } from 'react';
import { useQuery as uQ } from 'react-query';
import { fetcher, api } from '../../plugins';
import { useMutation } from '../../reusable';
import { SEND_PARENT_SIGNUP_WITH_STUDENT } from '../../query';

interface ParentInfoCard {
  studentId: string;
}

export const ParentInfoCard: FC<ParentInfoCard> = ({ studentId }) => {
  const { error, data, refetch } = uQ(
    `/users/parentinfo?studentId=${studentId}`,
    fetcher,
  );

  const [sendParentSignup] = useMutation(SEND_PARENT_SIGNUP_WITH_STUDENT, {
    variables: {
      studentId: Number(studentId),
    },
    onCompleted: () => {
      alert('보호자 회원가입 메시지 발송이 완료되었습니다.');
    },
    onError: (err) => {
      alert(err.message || '메시지 발송 중 오류가 발생하였습니다.');
    },
  });

  const parentUpdate = (parentName: string, parentTel: string) =>
    api({
      method: 'post',
      url: 'users/parentUpdate',
      data: {
        studentId,
        parentName,
        parentTel,
      },
    })
      .then(() => {
        refetch();
      })
      .catch((err) => {
        console.log('err', err.message);
      });

  return (
    <div className="mb-5">
      <div className="text-xl font-semibold flex justify-between">
        <p>보호자정보카드</p>
        {/* <p>
          {' '}
          {!data?.parents[0] ? (
            <div className="flex">
              <button
                className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-2 py-1 focus:outline-none"
                onClick={() => {
                  data?.nokPhone === ''
                    ? alert('학생정보의 보호자 전화번호를 입력해주세요.')
                    : sendParentSignup();
                }}
              >
                가입요청알림톡 보내기
              </button>
              <div className="text-red-500">미가입</div>
            </div>
          ) : (
            '가입완료'
          )}
        </p> */}
      </div>
      <div className="bg-white border rounded-lg pt-3 px-3 relative h-full">
        {data?.parents.map((item: any) => (
          <div className="bg-white border rounded-lg pb-3 pt-1 px-3 mb-3 relative h-full">
            {data.nokPhone === item.phone ? (
              <div className="text-red-500">주 보호자</div>
            ) : (
              <div className="flex justify-end">
                <button
                  className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-2 py-1 focus:outline-none"
                  onClick={() => {
                    alert(
                      `학생정보의 보호자 전화번호가 ${item?.name} 님의 전화번호로 변경되며, 학생의 결재요청도 ${item?.name}님 에게 보내집니다.`,
                    );

                    parentUpdate(item?.name, item?.phone);
                  }}
                >
                  주 보호자 지정
                </button>
              </div>
            )}

            <table className="w-full ">
              <tr>
                <td className="font-semibold w-32 border-b-2">이름</td>
                <td className="border-b-2">{item?.name}</td>
              </tr>
              <tr>
                <td className="font-semibold border-b-2">전화번호</td>
                <td className="border-b-2">{item?.phone}</td>
              </tr>
              <tr>
                <td className="font-semibold border-b-2">이메일</td>
                <td className="border-b-2">{item?.email}</td>
              </tr>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};
