import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import SignaturePad from 'signature_pad';
import {
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  Checkbox,
  CloseButton,
  PhoneNumberField,
  Section,
  TextField,
  TopNavbar,
  useMutation,
} from '../../../reusable';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import { ErrorBlank, FieldtripDatePicker, SelectValues } from '../../../components';
import { CREATE_ABSENT, UPDATE_ABSENT } from '../../../query';
import {
  isValidDate,
  makeDateToString,
  makeTimeToString,
} from '../../../utils';
import { fetcher } from '../../../plugins';
import { StudentGroup, User } from '../../../types';
import undoArrow from '../../../assets/images/undo-arrow.png';

const reportType = ['결석', '지각', '조퇴', '결과'];
const descriptionType = ['인정', '질병', '기타', '미인정'];
const reasonType = [
  '상고',
  '코로나 19 관련',
  '법정전염병',
  '생리',
  '학교장 출석인정',
  '병원진료',
  '가정에서의 안정',
  '보건실 방문',
  '기타',
  '개인사',
  '신고사유 없음',
];

const desType: any = {
  인정: {
    reasonType: ['상고', '코로나 19 관련', '법정전염병', '생리', '학교장 출석인정'],
    evidenceFileType: ['학부모 확인서', '기타'],
  },
  질병: {
    reasonType: ['병원진료', '가정에서의 안정', '보건실 방문'],
    evidenceFileType: ['진료확인서(진단서, 의사소견서 등)', '처방전(약봉투 등)', '학부모 확인서', '담임교사 확인서','보건교사 확인서','기타'],
  },
  기타: {
    reasonType: ['기타'],
    evidenceFileType: ['학부모 확인서', '기타'],
  },
  미인정: {
    reasonType: ['개인사', '신고사유 없음', '기타'],
    evidenceFileType: ['학부모 확인서','증빙서류 없음', '기타'],
  },
};

interface AbsentAddPageProps {
  absentData?: any;
  me?: User;
}

let sigPad: any = null;

const getMeridiem = (date: string | undefined) => {
  if (!date) return '오전';
  if (new Date(date).getHours() < 12) {
    return '오전';
  } else {
    return '오후';
  }
};

const getMeridiemHours = (date: string | undefined) => {
  if (!date) return 0;
  return new Date(date).getHours();
};

export const AbsentAddPage: React.FC<AbsentAddPageProps> = ({
  absentData,
  me,
}) => {
  const history = useHistory();
  const { error, data } = uQ('/absents', fetcher);

  const {
    reason: _reasonText = '',
    reportType: _reportType = '',
    startAt: _startAt = new Date(),
    endAt: _endAt = new Date(),
    description: _description = '',
  } = absentData || {};

  const [dateOff, setDateOff] = useState<boolean>(absentData === undefined ? true 
    : (makeTimeToString(_startAt) === '00:00' && makeTimeToString(_endAt) === '00:00') ? true : false);

  const _reason = _reasonText
    ? reasonType.includes(_reasonText)
      ? _reasonText
      : '기타'
    : '';
  const [reason, setReason] = useState(_reason);
  const [reasonText, setReasonText] = useState(_reasonText);
  const [report, setReport] = useState(_reportType);
  const [evidenceType, setEvidenceType] = useState(
    absentData?.evidenceType || '',
  );
  const [evidenceTypeText, setEvidenceTypeText] = useState('');
  const [parentsName, setParentsName] = useState(data?.me?.nokName || '');
  const [parentsPhone, setParentsPhone] = useState(data?.me?.nokPhone);
  const [startAt, setStartAt] = useState(
    _startAt? makeDateToString(_startAt) : '',
  );
  const [endAt, setEndAt] = useState(
    _endAt ? makeDateToString(_endAt) : '');
  const [description, setDescription] = useState(_description || '');

  const [startHour, setStartHour] = useState(
    _startAt ? getMeridiemHours(dateOff ? 0 :_startAt) : 9,
  );
  const [startMinute, setStartMinute] = useState(
    _startAt ? new Date(dateOff ? 0 :_startAt).getMinutes() : 0,
  );
  const [endHour, setEndHour] = useState(
    _endAt ? getMeridiemHours(dateOff ? 0 :_endAt) : 16,
  );
  const [endMinute, setEndMinute] = useState(
    _endAt ? new Date(dateOff ? 0 :_endAt).getMinutes() : 40,
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [openSignModal, setSignModal] = useState(false);
  const [sigPadData, setSigPadData] = useState('');
  const [agree, setAgree] = useState(false);
  const [images, setImages] = useState<(File | null | undefined)[]>([null]);
  const [willRemoveImages, setWillRemoveImages] = useState<string[]>([]);

  useEffect(() => {
    if (!parentsName && data?.me?.nokName) {
      setParentsName(data.me.nokName);
    }
    if (data?.me?.nokPhone) {
      setParentsPhone(data.me.nokPhone);
    }
  }, [data]);

  useEffect(() => {
    sigPad =
      //@ts-ignore
      new SignaturePad(document.querySelector('canvas'), {
        onEnd: () => {
          setSigPadData(sigPad.toDataURL());
        },
      });
  }, []);

  const clearSignature = () => {
    sigPad && sigPad.clear();
    setSigPadData('');
    const canvas = document.querySelector('canvas');
    const ctx = canvas?.getContext('2d');
    ctx && ctx.clearRect(0, 0, canvas?.width || 0, canvas?.height || 0);
    ctx && ctx.beginPath();
  };

  const makeStartAt = () => {
    let date = new Date();
    if (startAt) {
      date = new Date(startAt);
    }
    let hour = dateOff ? 0 : Number(startHour);
    date.setHours(hour, dateOff ? 0 : Number(startMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };
  const makeEndAt = () => {
    let date = new Date();
    if (report !== '결석') {
      startAt && (date = new Date(startAt));
    } else {
      endAt && (date = new Date(endAt));
    }
    let hour = dateOff ? 0 : Number(endHour);
    date.setHours(hour, dateOff ? 0 : Number(endMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };

  const [createAbsent] = useMutation(CREATE_ABSENT, {
    variables: {
      data: JSON.stringify({
        reportType: report,
        startAt: report !== '결석' ? makeStartAt() : startAt,
        endAt: report !== '결석' ? makeEndAt() : endAt,
        reason:
          reason === '학교장 출석인정' || reason === '기타'
            ? reasonText
            : reason,
        evidenceType: evidenceType === '기타' ? evidenceTypeText : evidenceType,
        description: description,
        parentsName,
        parentsPhone,
        reportedAt: makeDateToString(new Date()),
      }),
      images: evidenceType !== '학부모 확인서' ? images : [],
      signature: sigPadData,
    },
    onError: (e) => {
      setLoading(false);
      setSignModal(false);
      clearSignature();
      setErrorMessage(e.message);
    },
    onCompleted: ({ createAbsent }) => {
      setLoading(false);
      setSignModal(false);
      alert(
        '출결신고서 제출이 완료되어 학부모님께 출결신고서 승인을 위한 메세지가 전달되었습니다.',
      );
      clearSignature();
      history.push(`/student/absent/${createAbsent?.id}`);
    },
  });
  const [updateAbsent] = useMutation(UPDATE_ABSENT, {
    variables: {
      data: JSON.stringify({
        id: absentData?.id,
        reportType: report,
        startAt: report !== '결석' ? makeStartAt() : startAt,
        endAt: report !== '결석' ? makeEndAt() : endAt,
        reason: reason === '학교장 출석인정' ? reasonText : reason,
        evidenceType: evidenceType === '기타' ? evidenceTypeText : evidenceType,
        description: description,
        parentsName,
        parentsPhone,
        deleteImages: willRemoveImages,
      }),
      images: evidenceType !== '학부모 확인서' ? images : [],
    },
    onError: (e) => {
      setLoading(false);
      setErrorMessage(e.message);
    },
    onCompleted: ({ updateAbsent }) => {
      absentData?.absentStatus === 'RETURNED' &&
        alert('수정이 완료되어 선생님께 전송되었습니다.');
      setLoading(false);
      setSignModal(false);
      clearSignature();
      history.push(`/student/absent/${updateAbsent?.id}`);
    },
  });

  const studentGroup = data?.me?.studentGroups?.filter(
    (sg: StudentGroup) => !!sg && !!sg.group,
  )[0];

  const aImages = absentData?.evidenceFiles;

  const buttonDisabled =
    !agree ||
    (reason === '학교장 출석인정' || reason === '기타'
      ? !reasonText
      : !(reasonType.includes(reason))) ||
    !(descriptionType.includes(description)) ||
    (evidenceType === '기타' ? !evidenceTypeText : !desType[description]?.evidenceFileType.includes(evidenceType)) ||
    !(reportType.includes(report)) ||
    !parentsName ||
    !(parentsPhone.replace(/-/g, '').length === 11) ||
    !startAt ||
      (report !== '결석' ? !startAt && !endAt : !endAt) ||
    (!absentData
      ? evidenceType === '기타'
        ? !images[0]
        : false
      : false);


  return (
    <>
      {/* {loading && <Blank />} */}
      {isLoading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar
        title="출결신고서 작성"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <div className="w-full">
          <TextField
            label="학생 이름(본인)"
            id="studentName"
            value={data?.me?.name}
            tw={{ backgroundColor: 'bg-gray-100' }}
            readOnly
          />
        </div>
        <div className="w-full">
          <TextField
            label="학생 학년/반/번호"
            value={
              studentGroup?.group?.name +
              ' / ' +
              studentGroup?.studentNumber +
              '번'
            }
            tw={{ backgroundColor: 'bg-gray-100' }}
            readOnly
          />
        </div>
        <div className="w-full">
          <SelectValues
            label="*신고유형"
            placeholder="선택"
            selectValues={reportType}
            value={report}
            onChange={(group) => setReport(group)}
            border = {reportType.includes(report) ? 'border' : 'border-2'} 
            borderColor={reportType.includes(report) ? 'border-gray-300' : 'border-red-700'}
          />
          <SelectValues
            placeholder="선택"
            selectValues={descriptionType}
            value={description}
            onChange={(group) => {
              setDescription(group);
              if (!desType[group]?.reasonType?.includes(reason)) {
                setReason('');
              }
              if (!desType[group]?.evidenceFileType?.includes(evidenceType)) {
                setEvidenceType('');
              }
            }}
            border = {descriptionType.includes(description) ? 'border' : 'border-2'}
            borderColor={descriptionType.includes(description) ? 'border-gray-300' : 'border-red-700'}
          />
          {/* {description === '기타' && (
            <TextField
              placeholder="신고 유형을 입력해주세요."
              value={descriptionText}
              onChange={(e) => setDescriptionText(e.target.value)}
              tw={{
                marginTop: 'mt-1',
                borderColor: descriptionText
                  ? 'border-gray-300'
                  : 'border-red-700',
              }}
            />
          )} */}
        </div>
        {report === '결석' && (
          <div className="w-full pb-6">
            <label className="mb-1 text-sm text-gray-800">*발생일</label>
            <div className="flex items-center mb-3">
              <FieldtripDatePicker
                selectedDate={new Date(startAt)}
                excludeDates= {[new Date(1970,1,1)]}
                placeholderText="시작 날짜"
                onChange={(selectedDate) => {
                  if (!selectedDate) {
                    return;
                  }

                  if (endAt && selectedDate > new Date(endAt)) {
                    setEndAt(makeDateToString(selectedDate));
                  }
                  setStartAt(makeDateToString(selectedDate));
                }}
              />
              <span className="flex-shrink-0 ml-3">일 부터</span>
            </div>
            <div className="flex items-center">
            <FieldtripDatePicker
                selectedDate={new Date(endAt)}
                excludeDates= {[new Date(1970,1,1)]}
                placeholderText="종료 날짜"
                onChange={(selectedDate) => {
                  if (!selectedDate) {
                    return;
                  }

                  if (startAt && selectedDate < new Date(startAt)) {
                    setStartAt(makeDateToString(selectedDate));
                  }
                  setEndAt(makeDateToString(selectedDate));
                }}
              />
              <span className="flex-shrink-0 ml-3">일 까지</span>
            </div>
          </div>
        )}
        {report !== '결석' && (
          <div className="pb-6">
            <label className="mb-1 text-sm text-gray-800">*발생일</label>
            <FieldtripDatePicker
                selectedDate={new Date(startAt)}
                excludeDates= {[new Date(1970,1,1)]}
                placeholderText="시작 날짜"
                onChange={(selectedDate) => {
                  if (!selectedDate) {
                    return;
                  }

                  if (endAt && selectedDate > new Date(endAt)) {
                    setEndAt(makeDateToString(selectedDate));
                  }
                  setStartAt(makeDateToString(selectedDate));
                }}
              />
              <div className="min-w-max flex space-x-2 items-center mt-2">
                <label className="mb-1 text-sm text-gray-800">발생시간</label> 
                <Checkbox
                  checked={dateOff}
                  label={dateOff ? 'off' : 'on'}
                  onChange={() => setDateOff(!dateOff)}
                />
              </div>
            <div className="flex items-center space-x-2" >
                <span>
                <TextField 
                  type="text" 
                  min="0" 
                  max="24"
                  maxLength={2} 
                  className="px-4 w-14 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400"
                  value={dateOff ? '00' : startHour}
                  onChange={(e) =>{
                    if (/^\d*$/.test(e.target.value)) {
                      if (!isNaN(Number(e.target.value))) {
                        const _startHour = Number(e.target.value);
                        if (Number(e.target.value) >= 0 && _startHour < 24) {
                          setStartHour(_startHour);
                        }
                      }
                    }
                  }}
                  onBlur={(e) => {
                    const _startHour = Number(e.target.value);
                    if (startHour > endHour) {
                      setEndHour(startHour+1);
                    }
                    if (_startHour === endHour && startMinute > endMinute) {
                      setEndMinute(startMinute);
                    }

                  }}
                  disabled={dateOff}
                  />
                  <span className='text-sm'> 시 </span>
                  <TextField type="text" 
                  min="0" 
                  max="59" 
                  maxLength={2} 
                  className="px-4 w-14 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400"
                  value={dateOff ? '00' : startMinute }
                  onChange={(e) =>{
                    if (/^\d*$/.test(e.target.value)) {
                      if (!isNaN(Number(e.target.value))) {
                        const _startMinute = Number(e.target.value);
                        if (_startMinute >= 0 && _startMinute < 60) {
                          setStartMinute(_startMinute);
                        } 
                      }
                    }
                  }}
                  onBlur={(e) => {
                    const _startMinute = Number(e.target.value);
                    // if (startMinute > endMinute) {
                    //   setEndMinute(startMinute);
                    // }
                    if (startHour === endHour && _startMinute > endMinute) {
                      setEndMinute(_startMinute);
                    }
                  }}
                  disabled={dateOff}
                  />
                  <span className='text-sm'> 분 부터 </span><br />
                  <TextField type="text" 
                  min="0" 
                  max="24" 
                  maxLength={2}
                  className="px-4 w-14 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400"
                  value={dateOff ? '00' : endHour}
                  onChange={(e) =>{
                    if (/^\d*$/.test(e.target.value)) {
                      if (!isNaN(Number(e.target.value))) {
                        const _endHour = Number(e.target.value);
                        if (_endHour >= 0 && _endHour < 24) {
                          setEndHour(_endHour);
                        } 
                      }
                    }
                  }}
                  onBlur={(e) => {
                    const _endHour = Number(e.target.value);
                    if (startHour > _endHour) {
                      setStartHour(_endHour);
                    }
                    if (startHour === _endHour && startMinute > endMinute) {
                      setEndMinute(startMinute);
                    }
                  }}
                  disabled={dateOff}
                  />
                  <span className='text-sm'> 시 </span>
                  <TextField type="text" 
                  min="0" 
                  max="59" 
                  maxLength={2}
                  className="px-4 w-14 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400"
                  value={dateOff ? '00' : endMinute}
                  onChange={(e) =>{
                    if (/^\d*$/.test(e.target.value)) {
                      if (!isNaN(Number(e.target.value))) {
                        const _endMinute = Number(e.target.value);
                        if (_endMinute >= 0 && _endMinute < 60) {
                          setEndMinute(_endMinute);
                        }
                      }
                    }
                  }}
                  onBlur={(e) => {
                    const _endMinute = Number(e.target.value);
                    if (startHour === endHour && startMinute > _endMinute) {
                      setStartMinute(_endMinute);
                    }
                  }}
                  disabled={dateOff}
                  />
                  <span className='text-sm'> 분 까지 </span>
                  </span>
            </div>
          </div>
        )}
        <div className="w-full">
          <SelectValues
            label="*신고사유 선택"
            placeholder="선택"
            selectValues={desType[description]?.reasonType || []}
            value={reason}
            onChange={(group) => setReason(group)}
            border = {reasonType.includes(reason) ? 'border' : 'border-2'}
            borderColor={reasonType.includes(reason) ? 'border-gray-300' : 'border-red-700'}
            // onChange={(e) => setReason(e.target.value)}
          />
          {(reason === '학교장 출석인정' || reason === '기타') && (
            <TextField
              tw={{
                marginTop: 'mt-1',
                borderWidth: reasonText ? 'border' : 'border-2',
                borderColor: reasonText ? 'border-gray-300' : 'border-red-700',
              }}
              placeholder="신고 사유를 입력해주세요."
              value={reasonText}
              onChange={(e) => setReasonText(e.target.value)}
            />
          )}
        </div>
        <div className="w-full"></div>
        <div className="w-full">
          <SelectValues
            label="*증빙서류 선택"
            placeholder="증빙서류를 선택해주세요."
            selectValues={desType[description]?.evidenceFileType || []}
            value={evidenceType}
            onChange={(group) => setEvidenceType(group)}
            border = {desType[description]?.evidenceFileType.includes(evidenceType) ? 'border' : 'border-2'}
            borderColor={desType[description]?.evidenceFileType.includes(evidenceType) ? 'border-gray-300' : 'border-red-700'}
          />
          {evidenceType === '기타' && (
            <TextField
              placeholder="서류 종류를 입력해주세요."
              value={evidenceTypeText}
              onChange={(e) => setEvidenceTypeText(e.target.value)}
              tw={{
                borderWidth: evidenceTypeText ? 'border' : 'border-2',
                borderColor: evidenceTypeText
                  ? 'border-gray-300'
                  : 'border-red-700',
              }}
            />
          )}
        </div>

        {(evidenceType !== '학부모 확인서' && evidenceType !== '증빙서류 없음') && (
          <>
            <div className="text-red-600 text-sm">
              *증빙서류 원본은 담임선생님께 <b>반드시</b> 제출해 주세요.
            </div>
            {aImages?.map((el: string) => (
              <div
                className={`relative pb-3/5 rounded border-2 border-dashed border-grey-5 ${
                  willRemoveImages.includes(el) ? 'opacity-50' : ''
                }`}
              >
                <span className="absolute -top-3 -right-3 block w-6 h-6 rounded-full ring-2 ring-white bg-red-700 z-40">
                  <div
                    className="w-full h-full flex items-center justify-center text-white cursor-pointer"
                    onClick={() =>
                      willRemoveImages.includes(el)
                        ? setWillRemoveImages(
                            willRemoveImages.filter((imgUrl) => imgUrl !== el),
                          )
                        : setWillRemoveImages(willRemoveImages.concat(el))
                    }
                    style={{ transform: 'translate(0.1px, 0.1px)' }}
                  >
                    {willRemoveImages.includes(el) ? (
                      <img src={undoArrow} alt="" className="w-4 h-4" />
                    ) : (
                      'X'
                    )}
                  </div>
                </span>
                <img
                  src={process.env.REACT_APP_API_URL + '/images?url=' + el}
                  alt=""
                  className="absolute w-full h-full rounded object-cover"
                  loading="lazy"
                />
                {willRemoveImages.includes(el) && (
                  <div className="absolute w-full h-full z-20">
                    <div className="flex w-full h-full items-center justify-center">
                      <div className="text-4xl text-gray-800 opacity-100">
                        삭제
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
            {images?.map((el: File | null | undefined, i: number) => (
              <>
                <label htmlFor={`imageupload${i}`}>
                  <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
                    {el ? (
                      <img
                        className="absolute w-full h-full rounded object-cover"
                        src={URL.createObjectURL(el)}
                        alt=""
                      />
                    ) : (
                      <div
                        className="absolute w-full h-full rounded object-cover bg-white"
                        onClick={() =>
                          alert(
                            `진료확인서 및 진단서 내 주민등록번호 정보는 개인정보이므로 꼭 가린 후 업로드 해주세요. (가리지 않을 경우 반려 될 수 있음)
진료확인서 및 처방전 원본은 반드시 담임선생님께 제출하여 주시기 바랍니다.
                          `,
                          )
                        }
                      >
                        <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                          <FileUploadImg />
                          <div className="text-brand-1">
                            이미지를 업로드해주세요.
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  id={`imageupload${i}`}
                  className="hidden"
                  accept=".png, .jpeg, .jpg"
                  onChange={async (e) => {
                    let size = 0;

                    images.map((el: File | string | null | undefined) => {
                      if (el instanceof File) {
                        size += el.size || 0;
                      }
                    });

                    if (size >= 20 * 1024 * 1024) {
                      alert(
                        '한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요.',
                      );
                    } else {
                      const newImages = images.slice();
                      const alreadyImages = !!newImages[i];
                      if (e.target.validity.valid) {
                        newImages[i] = e.target.files?.item(0);
                      }
                      if (!alreadyImages) {
                        newImages.push(null);
                      }
                      setImages(newImages);
                    }
                  }}
                />
              </>
            ))}
          </>
        )}
        <div>
          <div className="text-red-600 text-sm whitespace-pre-line mb-2">
            *민감정보(진료확인서 및 진단서)의 수집/이용/제3자 제공에 동의
          </div>
          <div className="border border-gray-300 rounded-lg px-4 py-3 whitespace-pre-line">
            진료 확인서 등 건강 관련 민감 정보는 소속 학교에 제공되어 출결 관리
            목적으로만 사용됩니다.
          </div>
          <div className="flex items-center space-x-2 mb-1">
            <Checkbox
              id="agree"
              onChange={() => setAgree(!agree)}
              checked={agree}
            />
            <label htmlFor="agree">
              <span className="font-semibold text-lg cursor-pointer">
                동의하기
                <span className="text-red-600 text-sm whitespace-pre-line mb-2">
                  &nbsp; (체크하지 않으면 다음단계로 넘어가지 않습니다.)
                </span>
              </span>
            </label>
          </div>
        </div>
        {absentData?.absentStatus !== 'RETURNED' && (
          <>
            <div className="w-full">
              <TextField
                disabled = {data?.me?.nokName}
                label="보호자 이름"
                value={parentsName}
                onChange={(e) => setParentsName(e.target.value)}
                tw={{
                  borderWidth: parentsName ? 'border' : 'border-2',
                  borderColor: parentsName
                    ? 'border-gray-300'
                    : 'border-red-700',
                }}
              />
            </div>

            <div className="w-full">
              <PhoneNumberField 
                disabled = {data?.me?.nokPhone!=='' && data?.me?.nokPhone!=='010' &&  data?.me?.nokPhone.length >= 10}
                label="보호자 연락처"
                value={parentsPhone || '010'}
                onChange={(e) => setParentsPhone(e.target.value)}
                style={{
                  borderWidth: parentsName ? 'border' : 'border-2',
                  borderColor: !parentsPhone ? 'rgba(185, 28, 28)' : '',
                }}
              />
              <div className="px-4 ">
                * 보호자 정보 수정은 담임선생님의 학생카드 페이지에서
                가능합니다.
              </div>
            </div>
          </>
        )}

        {errorMessage && <div className="text-red-600">{errorMessage}</div>}

        <Button
          disabled={buttonDisabled}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-400' : 'bg-brand-1',
          }}
          onClick={() => {
            const regExp = /^010(?:\d{4})\d{4}$/;
            if (parentsPhone && !regExp.test(parentsPhone.replace(/-/g, ''))) {
              alert('보호자 연락처를 확인해 주세요.');
              setLoading(false);
              return;
            }
            if (absentData) {
              setLoading(true);
              updateAbsent().then(
                (res) =>
                  res?.data?.updateAbsent?.id && window.location.reload(),
              );
            } else {
              setSignModal(true);
            }
          }}
        >
          제출하기
        </Button>
      </Section>
      <div className={openSignModal ? '' : 'hidden'}>
        <Blank text="" />
        <BottomFixedView
          tw={{
            borderRadius: 'rounded-xl',
            backgroundColor: 'bg-white',
            zIndex: 'z-100',
            bottom: '-bottom-4',
          }}
        >
          <div
            className="absolute top-2 right-3"
            onClick={() => setSignModal(false)}
          >
            <CloseButton
              onClick={() => {
                setSignModal(false);
                clearSignature();
              }}
            />
          </div>
          <Section>
            <div>
              <div className="text-gray-700 text-xl font-bold">학생 서명란</div>
              <div className="text-gray-500">
                아래 네모칸에 서명을 해주세요.
              </div>
            </div>
            <canvas
              width={
                window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6
              }
              height={
                window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4
              }
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            <div className="flex items-center justify-between space-x-2">
              <Button
                tw={{
                  backgroundColor: 'bg-white',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  color: 'text-current',
                  width: 'w-full',
                }}
                onClick={() => clearSignature()}
              >
                다시하기
              </Button>
              <Button
                tw={{ width: 'w-full' }}
                onClick={() => {
                  if(!sigPadData){
                    alert('서명이 없습니다. 아래 네모칸에 다시 서명을 해주세요.');
                    return;
                  }
                  if (parentsPhone === data?.me?.phone) {
                    alert('학생 전화번호와 같은 번호로 신청할 수 없습니다.');
                  } else {
                    setLoading(true);
                    createAbsent().then(
                      (res) =>
                        res?.data?.createAbsent?.id &&
                        history.push(
                          `/student/absent/${res.data.createAbsent.id}`,
                        ),
                    );
                  }
                }}
              >
                서명 제출하기
              </Button>
            </div>
          </Section>
          {isLoading && <Blank />}
        </BottomFixedView>
      </div>
    </>
  );
};
