import React, { FC, useState } from 'react';
import { forbiddenWords } from '../../pages/teacher/ForbiddenWords';
import {
  DELETE_RECORD_SUMMARY,
  UPDATE_EXIST_RECORD_SUMMARY,
} from '../../query';
import { Blank, Button, TextArea, useMutation } from '../../reusable';
import { Summary } from '../../types';
import { SuperModal } from '../SuperModal';

interface SummaryItemProps {
  summary: Summary;
  isUserOwn: boolean;
  refetch: () => void;
}

export const SummaryItem: FC<SummaryItemProps> = ({
  summary,
  isUserOwn = false,
  refetch,
}) => {
  const [recordSummary, setRecordSummary] = useState<string>(
    summary?.content || '',
  );
  const [updateState, setUpdateState] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [forbiddenText, setForbiddenText] = useState('');

  const [updateExistRecordSummary] = useMutation(UPDATE_EXIST_RECORD_SUMMARY, {
    variables: {
      id: summary?.id,
      recordSummary,
    },
    onCompleted: () => {
      setLoading(false);
      setErrorText('');
      refetch();
    },
    onError: (err) => {
      setLoading(false);
      setErrorText(err.message);
    },
  });

  const [deleteRecordSummary] = useMutation(DELETE_RECORD_SUMMARY, {
    variables: {
      id: summary.id,
    },
    onCompleted: () => {
      setLoading(false);
      setErrorText('');
      refetch();
    },
    onError: (err) => {
      setLoading(false);
      setErrorText(err.message);
    },
  });

  if (!isUserOwn) {
    return (
      <>
        <div>
          <div className="flex items-center justify-between px-4 pb-2">
            <div className="font-bold text-lg ">{summary.subject}</div>
            <div className="text-gray-500">작성자 : {summary.teacherName}</div>
          </div>
          <TextArea
            tw={{ height: 'h-40', backgroundColor: 'bg-gray-50' }}
            value={summary.content}
            disabled
          />
        </div>

        <div className="w-full h-0 border-gray-50 border" />
      </>
    );
  }

  return (
    <>
      {isLoading && <Blank />}
      <div>
        <div className="flex items-center justify-between px-4 pb-2">
          <div className="font-bold text-lg ">{summary.subject}</div>
          <div className="text-gray-500">작성자 : {summary.teacherName}</div>
        </div>
        {updateState ? (
          <>
            <TextArea
              tw={{ height: 'h-40' }}
              value={recordSummary}
              onChange={(e) => setRecordSummary(e.target.value)}
            />
            <div className="flex items-center justify-end space-x-2 pt-2">
              <Button
                tw={{ height: 'h-12' }}
                onClick={() => {
                  const match = recordSummary.match(
                    new RegExp(forbiddenWords.join('|'), 'g'),
                  );

                  if (match?.length) {
                    setModalOpen(true);
                    setForbiddenText(match.join(', '));
                  } else {
                    setLoading(true);
                    updateExistRecordSummary();
                    setForbiddenText('');
                  }
                }}
              >
                수정 완료
              </Button>
              <Button
                tw={{
                  backgroundColor: 'bg-gray-500',
                  height: 'h-12',
                }}
                onClick={() => {
                  setUpdateState(false);
                  setRecordSummary(summary?.content || '');
                }}
              >
                취소
              </Button>
            </div>
          </>
        ) : (
          <>
            <TextArea
              tw={{ height: 'h-40' }}
              value={summary.content}
              disabled
            />
            <div className="flex items-center justify-end space-x-2 pt-2">
              <Button
                tw={{ height: 'h-12' }}
                onClick={() => setUpdateState(true)}
              >
                수정하기
              </Button>
              <Button
                tw={{
                  backgroundColor: 'bg-red-500',
                  height: 'h-12',
                }}
                onClick={() => setDeleteModalOpen(true)}
              >
                삭제
              </Button>
            </div>
          </>
        )}

        {errorText && <div className="text-red-500">{errorText}</div>}
      </div>

      <div className="w-full h-0 border-gray-50 border" />
      <SuperModal
        modalOpen={deleteModalOpen}
        setModalClose={() => setDeleteModalOpen(false)}
        width="w-max"
      >
        <div className="px-12 py-6">
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            해당 총정리 내용을 삭제하시겠습니까?
          </div>
          <Button
            tw={{ width: 'w-full' }}
            onClick={async () => {
              await deleteRecordSummary();
              await setDeleteModalOpen(false);
            }}
          >
            삭제하기
          </Button>
        </div>
      </SuperModal>
      <SuperModal
        modalOpen={modalOpen}
        setModalClose={() => setModalOpen(false)}
        width="w-max"
      >
        <div className="px-12 py-6">
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            학생부 기재 유의어가 포함되어있습니다. <br />
            {forbiddenText} <br />
            <br />
            기재 내용을 다시 한번 확인해 주시기 바랍니다. 유의어를 포함하여
            저장하시겠습니까?
          </div>
          <Button
            tw={{ width: 'w-full' }}
            onClick={() => {
              setLoading(true);
              updateExistRecordSummary();
              setForbiddenText('');
              setRecordSummary('');
            }}
          >
            저장하기
          </Button>
        </div>
      </SuperModal>
    </>
  );
};
