import { FC } from 'react';
import { makeDateToString, makeTimeToString } from '../../utils';
import { useQuery as uQ } from 'react-query';
import { fetcher } from '../../plugins';
// import { LastChat } from '../../types';

interface MessageBoxProps {
  name: string | undefined;
  lastmsgtime: string | undefined;
  msgcnt: number | undefined;
  content: string | undefined;
}

export const MessageBox: FC<MessageBoxProps> = ({
  name,
  lastmsgtime,
  msgcnt,
  content,
}) => {
  // const { error, data: chatDetailData } = uQ(`/chatroom/${chatRoomId}`, fetcher);

  return (
    <div className="flex w-full items-center cursor-pointer">
      <div className="flex-1 px-1 py-2 text-left">
        <h4 className="text-sm font-semibold text-gray-900">{name}</h4>
        <div className="text-sm">{content}</div>
      </div>
      <div className="flex-2 text-right">
        <div>
          <small className="text-gray-500">
            {lastmsgtime &&
              makeDateToString(lastmsgtime, '.') +
                ' ' +
                makeTimeToString(lastmsgtime)}{' '}
          </small>
        </div>

        {/* // -1: 상대방이 읽지 않은것, 0: 다 읽은것, n: 안 읽은 갯수 */}
        <div>
          {Number(msgcnt) > 0 ? (
            <small className="text-xs bg-red-500 text-white rounded-full h-6 w-6 leading-6 text-center inline-block">
              {Number(msgcnt)}
            </small>
          ) : Number(msgcnt) < 0 ? (
            <small className="text-xs bg-blue-600 text-white rounded-full h-6 w-6 leading-6 text-center inline-block">
              S
            </small>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
