import { Avatar, Button, ListItem, utcToLocalDatetime } from '../reusable';
import React, { FC, useState } from 'react';
import { Comment } from '../types';
import { SuperModal } from './SuperModal';
import { SuperSearchField } from './SuperSearchField';

interface CommentItemProps {
  comment: Comment;
  userId: number;
  userRole?: string;
  updateComment: any;
  deleteComment: any;
  // setText: (text: string) => void;
}

export const CommentItem: FC<CommentItemProps> = ({
  comment,
  userId,
  userRole,
  updateComment,
  deleteComment,
  // setText,
}) => {
  const [text, setText] = useState(comment.content);
  const [updateState, setUpdateState] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  if (!comment) {
    return <></>;
  }
  return (
    <ListItem key={comment.id || 0}>
      <SuperModal
        modalOpen={modalOpen}
        setModalClose={() => setModalOpen(false)}
        width="w-max"
      >
        <div className="px-12 py-6">
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            해당 댓글을 삭제하시겠습니까?
          </div>
          <Button
            tw={{ width: 'w-full' }}
            onClick={async () => {
              await deleteComment({ variables: { id: comment?.id } });
              await setModalOpen(false);
            }}
          >
            삭제하기
          </Button>
        </div>
      </SuperModal>
      <div className="flex space-x-2">
        <Avatar
          size={10}
          src={comment.user?.customProfile || comment.user?.profile}
        />
        <div style={{ width: 'calc(100% - 4rem)' }}>
          <div className="flex justify-between align-center">
            <div className="flex space-x-2 overflow-hidden">
              <div className="text-grey-1 font-bold min-w-max">
                {comment.user?.name}
              </div>
              <div className="text-grey-3 min-w-max">
                {comment.updatedAt ? utcToLocalDatetime(comment.updatedAt) : ''}
              </div>
            </div>
            {!updateState ? (
              userId === comment.user?.id ? (
                <div className="ml-1 flex space-x-2 items-center">
                  <span
                    className="text-gray-500 cursor-pointer min-w-max"
                    onClick={() => setUpdateState(!updateState)}
                  >
                    수정
                  </span>
                  <span
                    className="text-red-400 cursor-pointer min-w-max"
                    onClick={() => setModalOpen(true)}
                  >
                    삭제
                  </span>
                </div>
              ) : userRole === 'TEACHER' ? (
                <span
                  className="text-red-400 cursor-pointer min-w-max"
                  onClick={() => setModalOpen(true)}
                >
                  삭제
                </span>
              ) : (
                <div />
              )
            ) : (
              <></>
            )}
          </div>
          {updateState ? (
            <>
              <SuperSearchField
                value={text}
                outerStyle={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                onChange={(e) => setText(e.target.value)}
                onSearch={async () => {
                  if (text === '') {
                    alert('텍스트 내용을 입력해주세요.');
                  } else {
                    await updateComment({
                      variables: { content: text, id: comment.id },
                    });
                    await setUpdateState(false);
                  }
                }}
              />
              <div className="flex space-x-2 ml-2">
                <div
                  className="text-gray-400 min-w-max"
                  onClick={() => {
                    setUpdateState(false);
                    setText(comment.content);
                  }}
                >
                  취소
                </div>
                <div
                  className="text-brandblue-1 min-w-max"
                  onClick={async () => {
                    if (text === '') {
                      alert('텍스트 내용을 입력해주세요.');
                    } else {
                      await updateComment({
                        variables: { content: text, id: comment.id },
                      });
                      await setUpdateState(false);
                    }
                  }}
                >
                  수정 완료
                </div>
              </div>
            </>
          ) : (
            <div className="text-grey-2 whitespace-normal text-left mt-1">
              {comment.content}
            </div>
          )}
        </div>
      </div>
      {/* {updateState && (
        <div className="flex items-center space-x-2 mt-2">
          <div className="w-full">
            <TextArea
              style={{ height: '2.5rem', borderWidth: '1px' }}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <div
            className="text-brandblue-1 px-2 py-4 min-w-max"
            onClick={() =>
              text === ''
                ? alert('텍스트 내용을 입력해주세요.')
                : updateComment({
                    variables: { content: text, id: comment.id },
                  })
            }
          >
            수정하기
          </div>
        </div>
      )} */}
    </ListItem>
  );
};
