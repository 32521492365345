import { Blank, newMsgCntState, Section, TopNavbar, useRecoilValue, useSetRecoilState } from '../../../reusable';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import Viewer from 'react-viewer';
import Calendar from 'react-calendar';
import { ErrorBlank } from '../../../components';
import { StudentGroup } from '../../../types';
import { makeDateToString, makeMonthDayToString } from '../../../utils';
import { TimetableDetailPage } from '../timetable/TimetableDetailPage';
import { ReactComponent as ClockIcon } from '../../../assets/svg/clock.svg';
import { api, fetcher } from '../../../plugins';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import { ReactComponent as ChatIcon } from '../../../assets/svg/chat.svg';
import { checkNewVersion } from '../../../utils/status-util';

export const CanteenPage: FC = () => {
  const [showTimetablePage, setShowTimetablePage] = useState(false);

  const currentDate = new Date();

  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [blankOpen, setBlankOpen] = useState(false);

  const [isImageModalOpen, setImageModalOpen] = useState(false);

  const year = selectedDate.getFullYear();
  const month = selectedDate.getMonth() + 1;
  const day = selectedDate.getDate();

  const newMsgCnt = useRecoilValue(newMsgCntState);
  const setNewMsgCnt = useSetRecoilState(newMsgCntState);

  checkNewVersion();

  useEffect(() => {
    api
      .get('/chatroom/newmsg')
      .then((res) => {
        setNewMsgCnt(res.data.newMsgCnt);
        //console.log(newMsgCnt);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const { error, data } = useQuery(
    `/canteens?year=${year}&month=${month}`,
    fetcher,
  );
  const canteens = data?.canteens;
  const schedules = data?.schedules;

  const selectedCanteen = canteens?.filter(
    (el: any) => el.date === makeDateToString(selectedDate),
  )[0];

  let groupId: any = 0;

  data?.me?.studentGroups?.map((sg: StudentGroup) => {
    if (sg.group?.type === 'KLASS') {
      groupId = sg.group.id;
    }
  });

  const daysWithSchedule: string[] = [];
  const schedulesOrderbyDay: any = {};

  schedules?.map((s: any) => {
    const current = new Date(s.start);
    const end = new Date(s.end);
    while (current < end) {
      if (!daysWithSchedule.includes(makeDateToString(current))) {
        daysWithSchedule.push(makeDateToString(current));
      }

      if (schedulesOrderbyDay[makeDateToString(current)]) {
        schedulesOrderbyDay[makeDateToString(current)].push(s);
      } else {
        schedulesOrderbyDay[makeDateToString(current)] = [s];
      }

      current.setDate(current.getDate() + 1);
    }
  });
  if (!data) {
    return <Blank />;
  }
  return (
    <>
      {error && <ErrorBlank />}
      {blankOpen && <Blank />}
      <TopNavbar
        title={data?.me?.school?.name ? data?.me?.school?.name : '슈퍼스쿨'}
        left={<div className="h-15 w-10" />}
        right={
          <div
            className="text-brand-1"
            onClick={() => {
              setBlankOpen(true);
              window?.location?.reload();
            }}
          >
            새로고침
          </div>
        }
      />
      <div className="px-4 py-4">
        <Calendar
          onChange={(e: any) => {
            setSelectedDate(new Date(e));
          }}
          calendarType="US"
          locale="kr"
          prev2Label={null}
          next2Label={null}
          value={selectedDate}
          className="text-center"
          onActiveStartDateChange={(e) => setSelectedDate(e.activeStartDate)}
          //@ts-ignore
          formatDay={(locale, date) => date.getDate()}
          tileContent={({ activeStartDate, date, view }) => (
            <div
              className="absolute left-1/2 min-w-max flex justify-center space-x-0.5"
              style={{ transform: 'translateX(-51%)' }}
            >
              {daysWithSchedule.includes(makeDateToString(date)) && (
                <div className="rounded-full w-1 h-1 bg-red-500 bg-opacity-100" />
              )}
            </div>
          )}
        />
      </div>
      <div className="bg-gray-50 h-0.5 w-full" />
      <div className="py-4 px-6">
        <div className="text-sm text-grey-5 pb-3">
          {makeMonthDayToString(selectedDate)}
        </div>
        <div className="flex flex-col space-y-3">
          {schedulesOrderbyDay[makeDateToString(selectedDate)]?.map(
            (schedule: any) => (
              <div className="w-full flex space-x-2 items-center">
                <div className="w-2 h-2 rounded-full bg-brand-1" />
                <div className="font-bold">{schedule.title}</div>
              </div>
            ),
          )}
        </div>
      </div>
      <Section>
        {selectedCanteen?.image && (
          <div onClick={() => setImageModalOpen(true)}>
            <div className={`relative pb-3/5 rounded bg-gray-50`}>
              <ExifOrientationImg
                className={`absolute w-full h-full rounded-lg object-cover`}
                src={selectedCanteen.image}
                alt=""
              />
            </div>

            <div className="text-gray-400 text-sm ml-4">
              이미지를 클릭해서 크게 볼 수 있습니다.
            </div>
          </div>
        )}
        <div className="absolute">
          {selectedCanteen?.image && (
            <Viewer
              visible={isImageModalOpen}
              rotatable={true}
              noImgDetails={true}
              //drag={false}
              scalable={false}
              //changeable={false}
              //loop={false}
              images={[
                {
                  src: selectedCanteen.image,
                  alt: '',
                },
              ]}
              onClose={() => setImageModalOpen(false)}
            />
          )}
        </div>
        <div className="bg-brand-1 w-screen -ml-5 bg-opacity-20 font-semibold text-brand-1 px-5 py-5">
          오늘의 급식표
        </div>
        {selectedCanteen && (
          <div className="flex items-start space-x-2">
            <div className="w-full flex-col space-y-2">
              <div className="text-lg font-bold">중식</div>
              <div className="whitespace-pre-line">
                {selectedCanteen?.lunch}
              </div>
            </div>
            <div className="w-full flex-col space-y-2">
              <div className="text-lg font-bold">{/* 석식 */}</div>
              <div className="whitespace-pre-line">
                {selectedCanteen?.dinner}
              </div>
            </div>
          </div>
        )}
      </Section>

      <div className="fixed bottom-16 right-4">
        <div className={
          newMsgCnt > 0
          ? "bg-brand-1 rounded-full w-16 h-16 opacity-100 mb-2"
          : "bg-brand-1 rounded-full w-16 h-16 opacity-50 mb-2"}>
          <Link
            className="w-full h-full flex flex-col justify-center items-center"
            to={'/student/chat'}
          >
            <ChatIcon />
            <div className="text-white text-sm">메시지</div>
          </Link>
        </div>

        <div className="bg-grey-8 rounded-full w-16 h-16">
          <div
            className="w-full h-full flex flex-col justify-center items-center"
            onClick={(e) => setShowTimetablePage(true)}
          >
            <ClockIcon />
            <div className="text-white text-sm">시간표</div>
          </div>
        </div>
      </div>

      {showTimetablePage && (
        <TimetableDetailPage
          date={selectedDate}
          groupId={groupId || 0}
          setShowTimetablePage={(b: boolean) => setShowTimetablePage(b)}
        />
      )}
    </>
  );
};
