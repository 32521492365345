import React, { FC, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import { CSVLink } from 'react-csv';
import {
  Blank,
  Button,
  Checkbox,
  Section,
  utcToLocalDatetimestamp,
} from '../../../reusable';
import { ErrorBlank, Td } from '../../../components';
import { Activity, Group, StudentGroup } from '../../../types';
import { fetcher } from '../../../plugins';

interface MatchParams {
  id: string;
}

interface ActivityDownloadPageProps extends RouteComponentProps<MatchParams> {
  groupId?: number;
}

const ActivityDownloadPage: FC<ActivityDownloadPageProps> = ({
  match,
  groupId,
}) => {
  let { id } = match.params;

  const [_groupId, set_groupId] = useState(groupId);

  const { isLoading, isFetching, error, data } = uQ(
    `/groups/download/${id}?groupId=${_groupId}`,
    fetcher,
    { enabled: !!groupId },
  );

  const activity: Activity = data?.activity;
  const groupName = data?.group?.name;
  const userDatas: any[] = [];

  data?.group?.studentGroups
    ?.slice()
    ?.sort(
      (a: StudentGroup, b: StudentGroup) =>
        (a?.studentNumber || 0) - (b?.studentNumber || 0),
    )
    .map((sg: StudentGroup) => {
      userDatas.push({
        ...sg.user,
        studentNumber: sg.user?.studentGroups?.length
          ? sg.user?.studentGroups[0]?.studentNumber
          : sg.studentNumber,
        studentActivity:
          sg.user?.studentActivities && sg.user?.studentActivities[0],
      });
    });

  data?.groups?.map((g: Group) => {
    g.studentGroups
      ?.slice()
      ?.sort(
        (a: StudentGroup, b: StudentGroup) =>
          (a?.studentNumber || 0) - (b?.studentNumber || 0),
      )
      .map((sg: StudentGroup) => {
        userDatas.push({
          ...sg.user,
          studentNumber: sg.user?.studentGroups?.length
            ? sg.user?.studentGroups[0]?.studentNumber
            : sg.studentNumber,
          studentActivity:
            sg.user?.studentActivities && sg.user?.studentActivities[0],
        });
      });
  });

  let questions: any = [];
  let content: any;

  try {
    if (activity?.content) {
      content = JSON.parse(activity.content);
      questions = content.pages[0].elements
        ?.slice()
        ?.filter((q: any) => !q?.type?.includes('image'));
    }
  } catch (error) {
    console.error(error);
  }

  const userContents: any = {};

  try {
    userDatas?.map((user: any) => {
      if (user?.studentActivities[0]?.content && user?.id) {
        userContents[user.id] = JSON.parse(user.studentActivities[0].content);
      }
    });
  } catch (error) {
    console.error(error);
  }

  const csvData: any[] = [];

  if (activity?.type === 'SURVEY') {
    const questionOrAnswer =
      questions?.map((el: any) => el.title || el.name) || [];
    csvData.push([
      '학년',
      '반',
      '번호',
      '이름',
      '활동제목',
      '활동요약',
      ...questionOrAnswer,
      '제출여부',
      '제출시간',
    ]);
  } else if (activity?.type === 'POST') {
    csvData.push([
      '학년',
      '반',
      '번호',
      '이름',
      // '제출여부',
      // '제출시간',
      '활동제목',
      '활동요약',
      '내용',
      '제출여부',
      '제출시간',
    ]);
  }

  userDatas?.map((user: any) => {
    if (user?.id) {
      let grade = 0;
      let klass = 0;

      if (user.studentGroups[0]?.group?.name) {
        grade = user.studentGroups[0]?.group?.name?.split('학년')[0];
        klass = user.studentGroups[0]?.group?.name
          ?.split('학년')[1]
          .split('반')[0];
      }

      if (activity?.type === 'SURVEY') {
        const question_data = questions?.slice()?.map((q: any) => {
          try {
            const answer = userContents[user.id][q.name];

            if (!answer) {
              return '';
            }
            if (q?.type === 'image') {
              return '이미지 파일은 CSV에서 확인할 수 없습니다.';
            }
            if (q?.type === 'file') {
              return '파일은 CSV에서 확인할 수 없습니다.';
            }
            if (q.choices?.length) {
              if (q.type === 'radiogroup') {
                return q.choices.filter((el: any) => el.value === answer)[0]
                  .text;
              } else if (q.type === 'checkbox') {
                if (answer instanceof Array) {
                  return q.choices
                    .filter((el: any) => answer?.includes(el.value))
                    .map((el: any) => el.text)
                    .join(',');
                } else {
                  return q.choices
                    .filter((el: any) => answer === el.value)
                    .map((el: any) => el.text)
                    .join(',');
                }
              } else {
                if (Array.isArray(answer)) {
                  return answer.join(',');
                } else {
                  return String(answer);
                }
              }
            }

            return answer || '';
          } catch (err) {
            return '';
          }
        });

        csvData.push([
          grade,
          klass,
          user.studentNumber,
          user.name,
          activity.title,
          user.studentActivity?.summary || '',
          ...question_data,
          user.studentActivity?.isSubmitted ? '제출' : '미제출',
          user.studentActivity?.isSubmitted && user.studentActivity?.updatedAt
            ? utcToLocalDatetimestamp(user.studentActivity.updatedAt)
            : '',
        ]);
      } else {
        csvData.push([
          grade,
          klass,
          user.studentNumber,
          user.name,
          activity.title,
          user.studentActivity?.summary || '',
          user.studentActivity?.content || '',
          user.studentActivity?.isSubmitted ? '제출' : '미제출',
          user.studentActivity?.isSubmitted && user.studentActivity?.updatedAt
            ? utcToLocalDatetimestamp(user.studentActivity.updatedAt)
            : '',
        ]);
      }
    }
  });

  if (error) {
    return <ErrorBlank />;
  }

  return (
    <div className="bg-white ml-0.5 grid grid-cols-7 h-screen overflow-auto w-full">
      {isLoading && <Blank reversed />}
      {isFetching && <Blank reversed />}
      <div className="fixed top-0 py-3 px-5 pt-6 bg-white flex items-center space-x-2 w-full border-b border-gray-500">
        <CSVLink
          data={csvData}
          filename={`${activity?.title}-${groupName}.csv`}
        >
          <div className="w-full text-center mt-6">
            <Button
              text="Excel"
              tw={{
                height: 'h-11',
                minWidth: 'min-w-max',
                backgroundColor: 'bg-green-500',
              }}
              style={{ width: '70%' }}
            />
          </div>
        </CSVLink>
        <Checkbox
          checked={_groupId === 0}
          onChange={() =>
            _groupId === 0 ? set_groupId(groupId) : set_groupId(0)
          }
          label="모두"
        />
      </div>

      <Section tw={{ marginTop: 'mt-24' }}>
        <table className="pt-24">
          <tbody>
            {csvData?.map((row: any) => (
              <tr>
                {row.map((cell: any) => (
                  <Td innerClassName="min-w-max">{String(cell)}</Td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Section>
    </div>
  );
};

export default withRouter(ActivityDownloadPage);
