import { atom } from './reusable';
import { User } from './types';

export const meState = atom<User | undefined>({
  key: 'meState',
  default: undefined,
});

export const selectedGroupIdState = atom<number | undefined>({
  key: 'selectedGroupIdState',
  default: 0,
});
