import { ListItem, utcToLocalDatetime } from '../../reusable';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Notice } from '../../types';

interface NoticeItemProps {
  notice: Notice;
}

export const NoticeItem: FC<NoticeItemProps> = ({ notice }) => {
  const history = useHistory();
  return (
    <ListItem onClick={() => history.push(`/student/notice/${notice.id}`)}>
      <div className="text-left flex flex-col space-y-1">
        <div className="flex items-center justify-between">
          <div className="flex space-x-2 items-center">
            <div className="rounded-md px-3 py-1 bg-red-50 text-sm text-red-500 w-max">
              {notice.category || '공지사항'}
            </div>
          </div>
          <div className="text-sm text-gray-500">{notice?.user?.name}</div>
        </div>
        <div className="text-lg font-bold text-grey-1">{notice.title}</div>
        <div className="text-grey-3 text-sm">
          {utcToLocalDatetime(notice?.createdAt?.toString() || '')}
        </div>
      </div>
    </ListItem>
  );
};
