import { Badge, Blank, useQuery, utcToLocalDatetime } from '../../reusable';
import React, { FC, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GET_GROUP_ACTIVITIES } from '../../query';
import { Activity, Group, GroupActivity } from '../../types';

interface ActivitiesViewProps {
  group: Group;
  loadActivitiesView: boolean;
  subjects: any;
  setUpdateState: () => void;
  subject?: string;
}

export const ActivitiesView: FC<ActivitiesViewProps> = ({
  group,
  loadActivitiesView,
  setUpdateState,
  subjects,
  subject,
}) => {
  // console.log('group', group);

  const { pathname } = useLocation();

  const { loading, error, data, refetch } = useQuery(GET_GROUP_ACTIVITIES, {
    variables: {
      groupId: group.id,
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch, loadActivitiesView]);

  if (error) return <div>그룹 데이터를 불러오는 중 오류 발생</div>;

  let activities = data?.groupActivitiesByGroupId
    .map((el: GroupActivity) => el.activity)
    .filter((el: Activity) => !!el && subjects.includes(el.subject))
    .sort((a: any, b: any) => b.id - a.id);

  if (subject) {
    activities = activities?.filter((a: Activity) => a?.subject === subject);
  }

  return (
    <>
      {loading && <Blank reversed />}
      {activities?.map((activity: Activity) => {
        return (
          <div key={activity.id || 0}>
            <div
              className={
                pathname.startsWith(`/teacher/activity/${activity.id}`) ||
                pathname.startsWith(`/teacher/activity/submit/${activity.id}`)
                  ? 'px-6 py-4 bg-gray-50'
                  : 'px-6 py-4'
              }
            >
              <div className="flex justify-between">
                <Badge
                  tw={{
                    backgroundColor: 'bg-light_orange' as any,
                    color: 'text-brand-1',
                  }}
                >
                  {activity.subject}
                </Badge>
                <div className="text-sm text-gray-500 font-light">
                  {activity.createdAt && utcToLocalDatetime(activity.createdAt)}
                </div>
              </div>
              <div className="text-lg font-semibold mt-2">{activity.title}</div>

              {activity?.endDate && (
                <div className="text-sm font-normal text-red-400 space-x-2">
                  <span className="font-semibold">마감기한</span>
                  <span>
                    {utcToLocalDatetime(activity.endDate)}
                    까지
                  </span>
                </div>
              )}
              <div className="mt-5 space-x-3">
                <Link to={`/teacher/activity/${activity.id}`}>
                  <button
                    onClick={() => setUpdateState()}
                    className={
                      pathname.startsWith(`/teacher/activity/${activity.id}`)
                        ? 'bg-darkgray text-white rounded-md border border-darkgray text-sm px-4 py-2 focus:outline-none'
                        : 'hover:bg-darkgray hover:text-white rounded-md border border-darkgray bg-white text-darkgray text-sm px-4 py-2 focus:outline-none'
                    }
                  >
                    상세보기
                  </button>
                </Link>
                {activity.type !== 'NOTICE' && (
                  <Link to={`/teacher/activity/submit/${activity.id}`}>
                    <button
                      className={
                        pathname.startsWith(
                          `/teacher/activity/submit/${activity.id}`,
                        )
                          ? 'bg-darkgray text-white rounded-md border border-darkgray text-sm px-4 py-2 focus:outline-none'
                          : 'hover:bg-darkgray hover:text-white rounded-md border border-darkgray bg-white text-darkgray text-sm px-4 py-2 focus:outline-none'
                      }
                    >
                      제출자 보기
                    </button>
                  </Link>
                )}

                {activity.type !== 'NOTICE' && (
                  <Link to={`/teacher/activity/download/${activity.id}`}>
                    <button
                      className={
                        pathname.startsWith(
                          `/teacher/activity/download/${activity.id}`,
                        )
                          ? 'bg-darkgray text-white rounded-md border border-darkgray text-sm px-4 py-2 focus:outline-none'
                          : 'hover:bg-darkgray hover:text-white rounded-md border border-darkgray bg-white text-darkgray text-sm px-4 py-2 focus:outline-none'
                      }
                    >
                      다운로드
                    </button>
                  </Link>
                )}
              </div>
            </div>
            <div className="h-0.5 bg-gray-100 w-full" />
          </div>
        );
      })}
    </>
  );
};
