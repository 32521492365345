export const getImgUrl = (url: string) => {
  if (url.startsWith('data:image/png;base64')) {
    return url;
  } else if (url.includes(process.env.REACT_APP_API_URL + '/images?url=')) {
    return url;
  } else {
    return process.env.REACT_APP_API_URL + '/images?url=' + url;
  }
};

export const _getPdfImageSize = (width: number, height: number) => {
  if (width === 0 || height === 0) return [0, 0];
  if (width >= height) {
    return [210, (210 * height) / width];
  }
  return [(297 * width) / height, 297];
};
