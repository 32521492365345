import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import Linkify from 'react-linkify';
import Viewer from 'react-viewer';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import {
  TopNavbar,
  BackButton,
  Section,
  Blank,
  useQuery,
  Button,
} from '../../../reusable';
import { GET_NEWSLETTER } from '../../../query';
import {
  ErrorBlank,
  FileItem,
  SuperSurveyComponent,
} from '../../../components';
import { Newsletter, User } from '../../../types';
import { makeDateToString } from '../../../utils';
import { NewsletterAddPage } from './NewsletterAddPage';
import { api } from '../../../plugins';

interface MatchParams {
  id: string;
}

interface NewsletterDetailPageProps extends RouteComponentProps<MatchParams> {}

const NewsletterDetailPage: React.FC<NewsletterDetailPageProps> = ({
  match,
}) => {
  const history = useHistory();
  let { id } = match.params;
  id = id.split('/')[0];
  console.log(id);

  const { loading, error, data, refetch } = useQuery(GET_NEWSLETTER, {
    variables: { id: Number(id) },
    skip: isNaN(Number(id)),
  });

  const [isUpdateState, setUpdateState] = useState(false);
  const [me, setMe] = useState<User>();

  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (!me) {
      api
        .get('/users/me')
        .then((res) => setMe(res.data))
        .catch((err) => {
          if (err.message.includes('Unauthorized')) {
            history.replace('/login');
          } else {
            alert(err.message);
          }
        });
    }
  }, []);

  const isNotParent = me?.role !== 'PARENT';

  const newsletter: Newsletter = data?.newsletter;
  const studentNewsletter: any = newsletter?.studentNewsletter;

  const files: any[] = [];
  if (newsletter?.files) {
    try {
      newsletter?.files.map((f: any) => files.push(JSON.parse(f)));
    } catch (err) {
      console.error(err);
    }
  }

  let images: any[] = [];
  if (newsletter?.images) {
    try {
      images = newsletter?.images
        ? newsletter?.images.map((el: any) => JSON.parse(el).url)
        : [];
    } catch (err) {
      console.error(err);
    }
  }

  let viewerImages: any[] = [];

  for (let image of images) {
    viewerImages.push({
      src: image,
      title: '',
    });
  }

  useEffect(() => {
    if (newsletter) {
      if (!studentNewsletter || !studentNewsletter?.isSubmitted) {
        setUpdateState(true);
      }
    }
  }, [data]);

  if (isUpdateState) {
    return (
      <NewsletterAddPage
        studentNewsletterData={studentNewsletter}
        newsletterData={newsletter}
        setUpdateState={(b: boolean) => setUpdateState(b)}
        refetch={() => refetch()}
      />
    );
  }

  return (
    <>
      {loading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar
        title="가정통신문"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <div>
          <h1 className="text-2xl font-semibold">{newsletter?.title}</h1>
          <div className="text-gray-500 text-sm">
            {newsletter?.createdAt &&
              makeDateToString(new Date(newsletter.createdAt), '.')}
          </div>
        </div>
      </Section>
      <div className="h-0.5 w-full bg-gray-100" />
      <Section>
        {images?.map((image: string, i: number) => (
          <>
            <div
              className="w-full"
              onClick={() => {
                setActiveIndex(i);
                setImagesModalOpen(true);
              }}
            >
              <div className={`relative pb-3/5 rounded bg-gray-50`}>
                <ExifOrientationImg
                  loading="lazy"
                  className={`absolute w-full h-full rounded object-cover`}
                  src={image}
                  alt=""
                />
              </div>
            </div>
          </>
        ))}
        {files?.length ? (
          <div className="bg-gray-50">
            {files?.map((f: any) => (
              <FileItem file={f} />
            ))}
          </div>
        ) : (
          <></>
        )}
        <div
          className={`text-grey-2 break-words whitespace-pre-line feedback_space`}
        >
          <Linkify>{newsletter?.content}</Linkify>
        </div>

        {newsletter?.type === 'STUDENT_PARENTS_SURVEY' && (
          <>
            <SuperSurveyComponent
              surveyContent={newsletter?.surveyContent || ''}
              setContent={(c: any) => {}}
              content={JSON.parse(studentNewsletter?.content || '{}')}
              readOnly
            />
            {studentNewsletter?.parentSignature && (
              <>
                <div className="rounded-lg bg-light_orange whitespace-pre-line mb-4 p-4">
                  {`이 가정통신문의 답변 내용이 부모님의 서명을 받았습니다.`}
                </div>
                <div className="w-full">
                  <img
                    src={studentNewsletter?.parentSignature}
                    alt=""
                    style={{ width: '100px', marginTop: '0.5rem' }}
                  />
                </div>
              </>
            )}
            {isNotParent && (
              <Button onClick={() => setUpdateState(true)}>수정하기</Button>
            )}
          </>
        )}

        <div className="absolute">
          <Viewer
            visible={hasImagesModalOpen}
            rotatable={true}
            noImgDetails={true}
            //drag={false}
            scalable={false}
            images={viewerImages}
            onClose={() => {
              setImagesModalOpen(false);
            }}
            activeIndex={activeIndex}
          />
        </div>
      </Section>
    </>
  );
};

export default withRouter(NewsletterDetailPage);
