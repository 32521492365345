import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { forEach, includes, set } from 'lodash';
//@ts-ignore
import preval from 'preval.macro';
import {
  BackButton,
  Blank,
  Button,
  Section,
  TextField,
  tokenState,
  TopNavbar,
  useSetRecoilState,
} from '../../../reusable';
import { version } from '../../../../package.json';
import { LOGIN } from '../../../query';
import { Bridge, api } from '../../../plugins';
import { checkNewVersion } from '../../../utils/status-util';

export const Login: React.FC = () => {
  const setToken = useSetRecoilState(tokenState);
  const history = useHistory();

  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [errorMessage, setErrorMessage] = useState('');
  let [blankOpen, setBlankOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [login] = useMutation(LOGIN, {
    variables: {
      email,
      password,
    },
    onCompleted: async ({ login }) => {
      if (login?.token) {
        await setToken(login?.token);
        localStorage.setItem('token', login?.token);
        const data = await api.get('/users/me/grades').then(({ data }) => data);
        const grades = data?.grades || [];
        const schoolId = data?.user?.schoolId || 0;
        const tagValue = {
          schoolId,
        };
        forEach(['firstYear', 'secondYear', 'thirdYear'], (key, index) => {
          const value = includes(grades, String(index + 1)) ? 'true' : 'false';
          set(tagValue, key, value);
        });
        if (data?.user?.role !== 'USER' && data?.user?.role !== 'PARENT') {
          set(tagValue, 'teacher', 'true');
        } else {
          set(tagValue, 'teacher', 'false');
        }
        Bridge.flareLane('setUserId', data?.user?.email);
        Bridge.flareLane('setTags', tagValue);
        history.push('/student');
      }
      await setLoading(false);
    },
    onError: async (error) => {
      await setLoading(false);
      if (error?.message === 'Unauthorized') {
        await setErrorMessage('아이디(이메일) 혹은 비밀번호가 일치하지 않습니다!');
      } else {
        await setErrorMessage(error?.message || '');
      }
    },
  });

  const spacere = / /g;

  checkNewVersion();

  return (
    <div className="flex items-center justify-center w-full box-border">
      <div className="max-w-xl w-full">
        {isLoading && <Blank />}
        {blankOpen && <Blank />}
        <TopNavbar
          left={
            <div className="h-15">
              <BackButton className="h-15" />
            </div>
          }
          title="로그인"
          right={
            <div
              className="text-brand-1 text-sm"
              onClick={() => {
                setBlankOpen(true);
                window?.location?.reload();
              }}
            >
              새로고침
            </div>
          }
        />
        <Section>
          <div className="w-full">
            <TextField
              id="email"
              label="아이디"
              placeholder="아이디(이메일주소)를 입력해주세요."
              value={email}
              onChange={(e) =>
                setEmail(
                  String(e.target.value)
                    .replace(/ /g, '')
                    .replace(/•/g, '')
                    .replace(/\t/g, ''),
                )
              }
            />
          </div>

          <div className="w-full mt-6">
            <TextField
              label="비밀번호"
              type="password"
              id="password"
              placeholder="비밀번호를 입력해주세요."
              value={password}
              onChange={(e) =>
                setPassword(
                  String(e.target.value)
                    .replace(/ /g, '')
                    .replace(/•/g, '')
                    .replace(/\t/g, ''),
                )
              }
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setLoading(true);
                  login();
                }
              }}
            />
          </div>

          <div className="w-full mt-6">
            <Link to="/find-id">
              <div className="text-gray-4 mt-1">아이디 찾기</div>
            </Link>
            <Link to="/find-password">
              <div className="text-gray-4 mt-1">비밀번호 찾기</div>
            </Link>
          </div>

          <div className="text-pink-600">{errorMessage}</div>

          <Button
            disabled={!email || !password}
            tw={{
              backgroundColor:
                !email || !password ? 'bg-gray-300' : 'bg-brand-1',
            }}
            onClick={async () => {
              await setLoading(true);
              await login();
            }}
          >
            로그인
          </Button>
          {/* <Button
            // className="mt-4 bg-gray-7 text-gray-2"
            tw={{
              backgroundColor: 'bg-brand-1',
              color: 'text-white',
            }}
            onClick={() => history.push('/signup/student')}
          >
            학생 회원가입
          </Button> */}
          {/* <Button
            className="mt-4 bg-gray-7 text-gray-2"
            onClick={() => history.push('/signup/teacher')}
            variant="outlined"
          >
            선생님 회원가입
          </Button> */}

          <a href="https://superstudy.channel.io/lounge" target="blank">
            <div className="text-brand-1 bg-white rounded-lg border-brand-1 p-5 text-center ">
              문의하기
            </div>
          </a>
        </Section>

        <div className="fixed bottom-3 w-full text-center text-gray-600 left-0 right-0">
          슈퍼스쿨
          {/* v{version} <br />
          build at{' '}
          {preval`module.exports = new Date().toLocaleString().split("├")[0]`} */}
        </div>
      </div>
    </div>
  );
};
