import {
  addDays,
  differenceInBusinessDays,
  differenceInDays,
  getDay,
} from 'date-fns';
import { some } from 'lodash';
import { TZDate } from 'tui-calendar';
import { Schedule } from '../types';

export const makeDateToString = (
  date: Date | string,
  connector: string = '-',
) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  const year = date.getFullYear();
  let month: any = date.getMonth() + 1;
  if (String(month).length === 1) month = '0' + String(month);
  let day: any = date.getDate();
  if (String(day).length === 1) day = '0' + String(day);
  return `${year}${connector}${month}${connector}${day}`;
};

export const makeMonthDayToString = (date: Date) => {
  let month: any = date.getMonth() + 1;
  let day: any = date.getDate();
  return `${month}월 ${day}일`;
};

export const makeDateToStringByFormat = (date: Date) => {
  const year = date.getFullYear();
  let month: any = date.getMonth() + 1;
  if (String(month).length === 1) month = '0' + String(month);
  let day: any = date.getDate();
  if (String(day).length === 1) day = '0' + String(day);

  let week = ['일', '월', '화', '수', '목', '금', '토'];
  let dayOfWeek = week[date.getDay()];

  return `${year}. ${month}. ${day} ${dayOfWeek}`;
};

export const makeTimeToString = (date: Date | string) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  let hour: any = date.getHours();
  if (String(hour).length === 1) hour = '0' + hour;
  let min: any = date.getMinutes();
  if (String(min).length === 1) min = '0' + min;
  let sec: any = date.getSeconds();
  if (String(sec).length === 1) sec = '0' + sec;
  return `${hour}:${min}`;
};

export const MonthAgo = (date: Date) => {
  const newDate = new Date(date.getTime());
  newDate.setMonth(date.getMonth() - 1);
  return newDate;
};

export const twoMonthAgo = (date: Date) => {
  const newDate = new Date(date.getTime());
  newDate.setMonth(date.getMonth() - 2);
  return newDate;
};

export const weekAgo = (date: Date) => {
  const newDate = new Date(date.getTime());
  newDate.setDate(date.getDate() - 7);
  return newDate;
};

export const twoMonthAfter = (date: Date) => {
  const newDate = new Date(date.getTime());
  newDate.setMonth(date.getMonth() + 2);
  return newDate;
};

export const DayAfter = (date: Date) => {
  const newDate = new Date(date.getTime());
  newDate.setDate(date.getDate() + 1);
  return newDate;
};

export const makeStartEndToString = (
  startAt: string,
  endAt: string,
  reportType = '결석',
  connector = '-',
) => {
  const startDate = new Date(startAt);
  const endDate = new Date(endAt);

  if (
    reportType === '확인' &&
    makeDateToString(startDate) !== makeDateToString(endDate)
  ) {
    return `${makeDateToString(startDate, connector)} ${makeTimeToString(
      startDate,
    )} ~
${makeDateToString(endDate, connector)} ${makeTimeToString(endDate)}`;
  }

  if (
    reportType === '결석' &&
    makeDateToString(startDate) !== makeDateToString(endDate)
  ) {
    return `${makeDateToString(startDate, connector)} ~ ${makeDateToString(
      endDate,
      connector,
    )}`;
  }
  return `${makeDateToString(startDate, connector)}`;
};

export const makeDateToStringType2 = (date: Date) => {
  let month: any = date.getMonth() + 1;
  let day: any = date.getDate();
  return `${month}월 ${day}일`;
};

export const makeDateToString2 = (date: Date | string) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  const year = date.getFullYear();
  let month: any = date.getMonth() + 1;
  let day: any = date.getDate();
  return `${year}년 ${month}월 ${day}일`;
};

export const makeStartEndToStringType2 = (startAt: string, endAt: string) => {
  const startDate = new Date(startAt);
  const endDate = new Date(endAt);
  const connector = '-';
  if (makeDateToString(startDate) !== makeDateToString(endDate)) {
    return `${makeDateToString(startDate, connector)} ${makeTimeToString(
      startDate,
    )}~${makeTimeToString(endDate)}`;
  }
  return `${makeDateToString(startDate, connector)} ${makeTimeToString(
    startDate,
  )} ~
${makeDateToString(endDate, connector)} ${makeTimeToString(endDate)}`;
};

export const makeTZDateToString = (_date: TZDate, view: string = 'month') => {
  const date = _date.toDate();
  const year = date.getFullYear();
  let month: any = date.getMonth() + 1;
  if (String(month).length === 1) month = '0' + String(month);
  let day: any = date.getDate();
  if (String(day).length === 1) day = '0' + String(day);

  switch (view) {
    case 'month':
      return `${year}년 ${month}월`;
    case 'week':
      return `${year}년 ${month}월`;
    case 'day':
      return `${year}년 ${month}월 ${day}일`;
  }
};

export const calcBusinessDays = (
  startDate: Date | string,
  endDate: Date | string,
) => {
  if (!(startDate instanceof Date)) {
    startDate = new Date(startDate);
  }
  if (!(endDate instanceof Date)) {
    endDate = new Date(endDate);
  }
  if (startDate >= endDate) {
    return 0;
  }
  let count = 0;
  const curDate = new Date(startDate.getTime());
  while (curDate <= endDate) {
    const dayOfWeek = curDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
    curDate.setDate(curDate.getDate() + 1);
  }
  return count;
};

export const calcBusinessDaysWithSchedules = (
  startDate: Date | string,
  endDate: Date | string,
  schedules: Schedule[],
) => {
  if (!(startDate instanceof Date)) {
    startDate = new Date(startDate);
  }
  if (!(endDate instanceof Date)) {
    endDate = new Date(endDate);
  }
  if (startDate >= endDate) {
    return 0;
  }
  let count = 0;
  const curDate = new Date(startDate.getTime());
  while (curDate <= endDate) {
    const dayOfWeek = curDate.getDay();
    if (
      dayOfWeek !== 0 &&
      dayOfWeek !== 6 &&
      !isInSchedule(curDate, schedules)
    ) {
      count++;
    }
    curDate.setDate(curDate.getDate() + 1);
  }
  return count;
};

export const isInSchedule = (date: Date | string, schedules: Schedule[]) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  let result = false;
  schedules?.map((schedule: Schedule) => {
    const start = new Date(schedule.start || '');
    if (schedule.isAllDay) {
      const end = start;
      end.setDate(end.getDate() + 1);
      end.setHours(0);
      end.setMinutes(0);
      end.setSeconds(0);
      if (start <= date && date <= end) result = true;
    }
    const end = new Date(schedule.end || '');
    if (start <= date && date <= end) result = true;
  });
  return result;
};

const isContainSchedule = (startDate: Date, schedule: Schedule) => {
  let { start, end } = schedule;
  if (!(start instanceof Date)) {
    start = new Date(start);
  }
  start.setHours(0);
  start.setMinutes(0);
  start.setSeconds(0);
  if (!(end instanceof Date)) {
    end = new Date(end);
  }
  end.setHours(23);
  end.setMinutes(59);
  end.setSeconds(59);
  return +start <= +startDate && +startDate < +end;
};

export const differenceWithSchedules = (
  startDate: Date | string,
  endDate: Date | string,
  schedules: Schedule[] | undefined,
) => {
  const _startDate: Date = new Date(startDate);
  _startDate.setHours(0);
  _startDate.setMinutes(0);
  _startDate.setSeconds(0);
  const _endDate: Date = new Date(endDate);
  if (_startDate > _endDate) {
    return 0;
  }
  if (
    makeDateToString(_startDate) === makeDateToString(_endDate) &&
    !some(schedules, (schedule) => isContainSchedule(_startDate, schedule))
  ) {
    return 1;
  }
  if (!schedules || schedules.length === 0) {
    if (isWeekendDay(_endDate)) {
      return differenceInBusinessDays(_endDate, _startDate);
    }
    return differenceInBusinessDays(_endDate, _startDate) + 1;
  }
  let result = 0;
  const diffDay = differenceInDays(_endDate, _startDate);
  for (let i = 0; i <= diffDay; i++) {
    const tempDate = addDays(_startDate, i);
    const day = getDay(tempDate);
    if (
      day !== 0 &&
      day !== 6 &&
      !some(schedules, (schedule) => isContainSchedule(tempDate, schedule))
    ) {
      result++;
    }
  }
  return result;
};

export const isWeekendDay = (date: Date) => {
  const day = getDay(date);
  return day === 0 || day === 6;
};

export const isWeekday = (date: Date) => {
  const day = getDay(date);
  return day !== 0 && day !== 6;
};

export const isValidDate = (date: Date | string) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  return date.getTime() === date.getTime();
};

export const getStartDate = (startDate: string) => {
  const _start = new Date(startDate);
  _start.setHours(0);
  _start.setMinutes(0);
  _start.setSeconds(0);
  return _start.toISOString();
};

export const getEndDate = (endDate: string) => {
  const _end = new Date(endDate);
  _end.setHours(23);
  _end.setMinutes(59);
  _end.setSeconds(59);
  return _end.toISOString();
};

// 로컬시간 형식 yyyy-MM-dd 로 변경
export const toLocaleDateFormatString = (utc: Date) => {
  return utc.toLocaleString('fr-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

/// input : day:Date - 선택한 날짜
/// output : 선택한 날짜가 몇째주 인지, 해당 주에 월~금요일의 날짜 리턴
export const weekCount = (
  day: Date,
): [
  week: number,
  monday: Date,
  tuesday: Date,
  wednesday: Date,
  thursday: Date,
  friday: Date,
] => {
  let year = day.getFullYear();
  let countDay = new Date(year, 0, 1);
  let weekday = countDay.getDay();
  let week = 0;
  if (weekday <= 5) {
    countDay.setDate(countDay.getDate() - weekday);
  } else {
    countDay.setDate(countDay.getDate() - weekday + 7);
  }

  //let week = weekday <= 5 ? 1 : 0;  // 토요일 (6) 부터 시작하는 년은 1월2일(차주 일요일)부터 1주차
  while (day > countDay) {
    countDay.setDate(countDay.getDate() + 7);
    week++;
  } //while
  let monday = new Date(day);
  monday.setDate(day.getDate() - day.getDay() + 1);
  let tuesday = new Date(monday);
  tuesday.setDate(tuesday.getDate() + 1);
  let wednesday = new Date(tuesday);
  wednesday.setDate(wednesday.getDate() + 1);
  let thursday = new Date(wednesday);
  thursday.setDate(thursday.getDate() + 1);
  let friday = new Date(thursday);
  friday.setDate(friday.getDate() + 1);

  //return [day, week, ((monday.getMonth() + 1) + '월' + monday.getDate() + '일'), friday];
  return [week, monday, tuesday, wednesday, thursday, friday];
};

export const getThisYear = () => {
  const toDay = new Date();

  const year =
    toDay.getMonth() + 1 >= 3 ? toDay.getFullYear() : toDay.getFullYear() - 1;

  return String(year);
};

export const getSemester = () => {
  const date = new Date();
  return date.getMonth() + 1 >= 3 && date.getMonth() + 1 <= 7 ? 1 : 2;
};
