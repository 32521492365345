import {
  AuthRoute,
  Blank,
  BottomNavbar,
  BottomNavbarItem,
  newMsgCntState,
  useAuth,
  useLogout,
  useRecoilValue,
  useSetRecoilState,
} from '../../reusable';
import React, { useEffect, useState } from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
//@ts-ignore
import preval from 'preval.macro';
import { version } from '../../../package.json';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { meState } from '../../store';
import ActivityPage from './activity/ActivityPage';
import { GroupPage } from './group/GroupPage';
import LoginPage from './login/LoginPage';
import { CanteenPage } from './canteen/CanteenPage';
import NoticePage from './notice/NoticePage';
import { TimetablePage } from './timetable/TimetablePage';
import { AbsentPage } from './absent/AbsentPage';
import { OutingPage } from './outing/OutingPage';
import { StudentCardPage } from './studentcard/StudentCardPage';
import { FieldtripMainPage } from './fieldtrip/FieldtripMainPage';
import { ManagerScheduleMainPage } from './schedule/ManagerScheduleMainPage';
import { FieldtripNoticePage } from './fieldtrip/FieldtripNoticePage';
import { FieldtripResultPage } from './fieldtrip/FieldtripResultPage';
import { CalendarPage } from './calendar/CalendarPage';
import NewsletterPage from './newsletter/NewsletterPage';
import RecordPage from './record/RecordPage';
import { TeacherInfoPage } from './mypage/TeacherInfoPage';
import { TeacherFirstLoginPage } from './login/TeacherFirstLoginPage';
import { ReactComponent as NoticeIcon } from '../../assets/svg/bottomview-notice.svg';
import { ReactComponent as NoticeIconSelected } from '../../assets/svg/bottomview-notice-selected.svg';
import { ReactComponent as CanteenIcon } from '../../assets/svg/bottomview-canteen.svg';
import { ReactComponent as CanteenIconSelected } from '../../assets/svg/bottomview-canteen-selected.svg';
import { ReactComponent as PlannerIcon } from '../../assets/svg/bottomview-planner.svg';
import { ReactComponent as PlannerIconSelected } from '../../assets/svg/bottomview-planner-selected.svg';
import { ReactComponent as MypageIcon } from '../../assets/svg/bottomview-more.svg';
import { ReactComponent as MypageIconSelected } from '../../assets/svg/bottomview-more-selected.svg';
import { ReactComponent as ManageIcon } from '../../assets/svg/bottomview-manage.svg';
import { ReactComponent as ManageIconSelected } from '../../assets/svg/bottomview-manage-selected.svg';
import { ReactComponent as HomeIcon } from '../../assets/svg/bottomview-home.svg';
import { ReactComponent as HomeIconSelected } from '../../assets/svg/bottomview-home-selected.svg';
import { TeacherApplyPage } from './absent/TeacherApplyPage';
import { TeacherMypage } from './mypage/TeacherMypage';
import { BoardsPage } from './board/BoardPage';
import ChatListPage from './chat/ChatListPage';
import { TimetablePageV2 } from './timetable_v2/TimetablePageV2';
import { AttendancePage } from './schedule/AttendancePage';
import { api, Bridge } from '../../plugins';
import { checkNewVersion } from '../../utils/status-util';

const MainPage = () => {
  const history = useHistory();
  const me = useRecoilValue(meState);
  const logout = useLogout();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [openFieldTrip, setOpenFieldTrip] = useState(false);
  const [blankOpen, setBlankOpen] = useState(false);
  const { authenticated } = useAuth();

  const schoolName = localStorage.getItem('schoolName');

  const d = new Date();
  if (me?.role === 'USER') {
    history.replace('/student');
  }
  if (
    me?.role !== 'USER' &&
    me?.firstVisit &&
    !pathname.startsWith('/teacher/first-login')
  ) {
    history.replace('/teacher/first-login');
  }

  // if (!pathname.startsWith('/teacher/field') && openFieldTrip) {
  //   setOpenFieldTrip(false);
  // }

  checkNewVersion();

  const newMsgCnt = useRecoilValue(newMsgCntState);
  const setNewMsgCnt = useSetRecoilState(newMsgCntState);

  useEffect(() => {
    api
      .get('/chatroom/newmsg')
      .then((res) => {
        setNewMsgCnt(res.data.newMsgCnt);
        //console.log(newMsgCnt);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="flex">
      {blankOpen && <Blank />}
      <div className="w-60 flex-shrink-0 hidden md:block">
        <div className="flex flex-col flex-grow border-r border-gray-200 pt-1 pb-4 bg-gray-50 overflow-y-auto h-screen">
          <div className="px-4 flex items-center justify-between">
            <Link to="/teacher/activity">
              <Logo className="w-20" />
            </Link>
            <div
              className="text-brand-1 text-sm cursor-pointer"
              onClick={() => {
                setBlankOpen(true);
                window?.location?.reload();
              }}
            >
              새로고침
            </div>
          </div>
          {/* 선생님 프로필 */}
          <div className="py-4 px-6 mx-4 bg-white rounded-lg space-y-3">
            <div>
              <div className="font-semibold text-lg">{me?.name} 선생님</div>
              <div className="font-light text-base text-gray-500">
                {me?.email}
              </div>
            </div>
          </div>
          {/* 메뉴바 */}
          <div className="mt-5 flex-grow flex flex-col tracking-tighter">
            <nav className="flex-1 px-4 space-y-8  " aria-label="Sidebar">
              <div className="space-y-1">
                <div className="flex">
                  <div className="w-12 text-center self-center font-bold text-darkgray">
                    출결
                  </div>

                  <div
                    className=" border border-grey-6 mr-2 ml-3"
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />

                  <div className="w-48">
                    <Link
                      to="/teacher/outing"
                      className={
                        pathname.startsWith('/teacher/outing')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-bold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      확인증
                    </Link>
                    <Link
                      to="/teacher/absent"
                      className={
                        pathname.startsWith('/teacher/absent')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-bold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      출결신고서
                    </Link>
                    <Link
                      to="/teacher/attendance"
                      className={
                        pathname.startsWith('/teacher/attendance')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-bold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      출석부
                    </Link>
                    <Link
                      to="/teacher/v2timetable"
                      className={
                        pathname.startsWith('/teacher/v2timetable')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-semibold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      시간표/출석체크
                    </Link>
                    <div
                      className={
                        openFieldTrip
                          ? 'text-darkgray bg-gray-200 group flex items-center px-4 pt-3 pb-1 text-sm font-base rounded-t-md cursor-pointer select-none'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-t-md cursor-pointer select-none'
                      }
                      onClick={() => setOpenFieldTrip(!openFieldTrip)}
                    >
                      체험학습
                    </div>
                    {openFieldTrip && (
                      <div className="bg-gray-200 rounded-b-md">
                        <Link
                          to="/teacher/fieldtrip"
                          className={
                            pathname.startsWith('/teacher/fieldtrip') &&
                            !pathname.includes('notice') &&
                            !pathname.includes('result')
                              ? 'bg-darkgray text-white group flex items-center px-6 py-2 text-sm font-bold rounded-md'
                              : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-6 py-2 text-sm font-base rounded-md cursor-pointer'
                          }
                        >
                          - 신청서
                        </Link>
                        <Link
                          to="/teacher/fieldtrip/notice"
                          className={
                            pathname.startsWith('/teacher/fieldtrip/notice')
                              ? 'bg-darkgray text-white group flex items-center px-6 py-2 text-sm font-bold rounded-md'
                              : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-6 py-2 text-sm font-base rounded-md cursor-pointer'
                          }
                        >
                          - 통보서
                        </Link>
                        <Link
                          to="/teacher/fieldtrip/result"
                          className={
                            pathname.startsWith('/teacher/fieldtrip/result')
                              ? 'bg-darkgray text-white group flex items-center px-6 py-2 text-sm font-bold rounded-md'
                              : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-6 py-2 text-sm font-base rounded-md cursor-pointer'
                          }
                        >
                          - 결과보고서
                        </Link>
                      </div>
                    )}
                  </div>
                </div>

                {/* <div
                  className="w-full border border-grey-6"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                /> */}

                {/* <div className="flex">
                  <div className="w-12 text-center self-center font-bold text-darkgray">
                    체험
                    <br />
                    학습
                  </div>

                  <div
                    className=" border border-grey-6 mr-2 ml-3"
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />

                  <div className="w-48">
                    <Link
                      to="/teacher/fieldtrip"
                      className={
                        pathname.startsWith('/teacher/fieldtrip') &&
                        !pathname.includes('notice') &&
                        !pathname.includes('result')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-bold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      신청서
                    </Link>
                    <Link
                      to="/teacher/fieldtrip/notice"
                      className={
                        pathname.startsWith('/teacher/fieldtrip/notice')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-bold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      통보서
                    </Link>
                    <Link
                      to="/teacher/fieldtrip/result"
                      className={
                        pathname.startsWith('/teacher/fieldtrip/result')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-bold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      결과보고서
                    </Link>
                  </div>
                </div> */}

                <div
                  className="w-full border border-grey-6"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                />

                <div className="flex">
                  <div className="w-12 text-center self-center font-bold text-darkgray">
                    활동
                  </div>

                  <div
                    className=" border border-grey-6 mr-2 ml-3"
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />

                  <div className="w-48">
                    <Link
                      to="/teacher/activity"
                      className={
                        pathname.startsWith('/teacher/activity')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-bold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      활동 기록부
                    </Link>
                    <Link
                      to="/teacher/record"
                      className={
                        pathname.startsWith('/teacher/record')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-bold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      생활 기록부
                    </Link>

                    <Link
                      to="/teacher/groups"
                      className={
                        pathname.startsWith('/teacher/groups')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-bold rounded-md '
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      그룹 만들기
                    </Link>
                  </div>
                </div>

                {/*{process.env.REACT_APP_MENU_TYPE !== '2' && (
                  <>
                    <div
                      className="w-full border border-grey-6"
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                    />
                    <div className="text-darkgray group flex items-center px-4 py-3 text-sm font-base rounded-md">
                      체험학습
                    </div>

                    <div
                      className="w-full border border-grey-6"
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                    />
                    {/* <Link
                      to="/teacher/manager"
                      className={
                        pathname.startsWith('/teacher/manerger')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-bold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-bold rounded-md cursor-pointer'
                      }
                    >
                      학사일정 관리
                    </Link> }
                  </>
                )}*/}

                <div
                  className="w-full border border-grey-6"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                />

                <div className="flex">
                  <div className="w-12 text-center self-center font-bold text-darkgray">
                    일정
                  </div>

                  <div
                    className=" border border-grey-6 mr-2 ml-3"
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />

                  <div className="w-48">
                    <Link
                      to="/teacher/calendar"
                      className={
                        pathname.startsWith('/teacher/calendar')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-bold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      캘린더
                    </Link>
                    {/* <Link
                      to="/teacher/timetable"
                      className={
                        pathname.startsWith('/teacher/timetable')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-semibold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      시간표
                    </Link> */}
                    <Link
                      to={`/teacher/canteen/${d.getFullYear()}-${
                        d.getMonth() + 1
                      }-${d.getDate()}`}
                      className={
                        pathname.startsWith('/teacher/canteen')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-semibold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      급식표
                    </Link>
                    {/* <Link
                      to="/teacher/manager"
                      className={
                        pathname.startsWith('/teacher/manerger')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-bold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      학사일정
                    </Link> */}
                  </div>
                </div>

                {/* <Link
                  to="/teacher/chat"
                  className={
                    pathname.startsWith('/teacher/chat')
                      ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-semibold rounded-md'
                      : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                  }
                >
                  채팅
                </Link> */}

                <div
                  className="w-full border border-grey-6"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                />

                <div className="flex">
                  <div className="w-12 text-center self-center font-bold text-darkgray">
                    공지
                  </div>

                  <div
                    className=" border border-grey-6 mr-2 ml-3"
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />

                  <div className="w-48">
                    <Link
                      to="/teacher/notice"
                      className={
                        pathname.startsWith('/teacher/notice')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-semibold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      공지사항
                    </Link>
                    <Link
                      to="/teacher/board"
                      className={
                        pathname.startsWith('/teacher/board')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-semibold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      학급 게시판
                    </Link>
                    <Link
                      to="/teacher/newsletter"
                      className={
                        pathname.startsWith('/teacher/newsletter')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-semibold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      가정통신문
                    </Link>
                    <Link
                      to="/teacher/chat"
                      className={
                        pathname.startsWith('/teacher/chat')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-semibold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      <div className="flex-1 text-left">채팅</div>
                      <div className="text-right">
                        {Number(newMsgCnt) > 0 ? (
                          <small className="text-xs bg-red-500 text-white rounded-full h-6 w-6 leading-6 text-center inline-block">
                            N {/* {Number(newMsgCnt)} */}
                          </small>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Link>
                  </div>
                </div>

                <div
                  className="w-full border border-grey-6"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                />

                <div className="flex">
                  <div className="w-12 text-center self-center font-bold text-darkgray">
                    서비스
                  </div>

                  <div
                    className=" border border-grey-6 mr-2 ml-3"
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />

                  <div className="w-48">
                    <Link
                      to="/teacher/update"
                      className={
                        pathname.startsWith('/teacher/update')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-semibold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      내 정보
                    </Link>
                    <Link
                      to="/teacher/studentcard"
                      className={
                        pathname.startsWith('/teacher/studentcard')
                          ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-bold rounded-md'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                      }
                    >
                      학생카드
                    </Link>
                    <a
                      href="http://superstudy.channel.io/"
                      target="blank"
                      className="text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer"
                    >
                      고객센터
                    </a>
                    <div
                      className="text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer"
                      onClick={() => {
                        Bridge.flareLane('setUserId', '');
                        Bridge.flareLane('setTags', {});
                        localStorage.removeItem('token');
                        logout();
                      }}
                    >
                      로그아웃
                    </div>
                    <div
                      className={
                        open
                          ? 'text-white bg-darkgray group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer select-none'
                          : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer select-none'
                      }
                      onClick={() => setOpen(!open)}
                    >
                      슈퍼스쿨
                      <br />
                      사용법
                    </div>
                    {open && (
                      <>
                        <a
                          href={`https://www.notion.so/signed/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fb072a08c-df63-4eb5-84fa-2e084026f390%2Fstarting.pdf?table=block&id=9873b81d-5059-4043-9471-ad187bfd8f46&spaceId=8612a4a1-fd50-4ddd-9f79-e0df30e67f9e&name=starting.pdf&userId=625b3019-b1d4-48ee-a73f-fc5f93cb5b0a&cache=v2`}
                          target="_blank"
                          rel="noreferrer"
                          download
                        >
                          <div className="text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer select-none">
                            시작하기
                          </div>
                        </a>
                        <a
                          href={`https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%ED%8A%9C%ED%86%A0%EB%A6%AC%EC%96%BC%20%EC%B6%9C%EA%B2%B0%281%29_2022.11.01.pdf`}
                          target="_blank"
                          rel="noreferrer"
                          download
                        >
                          <div className="text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer select-none">
                            출결관리
                          </div>
                        </a>
                        <a
                          href={`https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%EC%B6%9C%EA%B2%B0%282%29_%EC%98%A8%EB%9D%BC%EC%9D%B8%EC%B6%9C%EC%84%9D%EB%B6%80_2022.11.01.pdf`}
                          target="_blank"
                          rel="noreferrer"
                          download
                        >
                          <div className="text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer select-none">
                            출석부
                          </div>
                        </a>
                        <a
                          href={`https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%EC%B2%B4%ED%97%98%ED%95%99%EC%8A%B5_2022.11.01.pdf`}
                          target="_blank"
                          rel="noreferrer"
                          download
                        >
                          <div className="text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer select-none">
                            체험학습
                          </div>
                        </a>
                        <a
                          href={`https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%ED%8A%9C%ED%86%A0%EB%A6%AC%EC%96%BC%20%ED%99%9C%EB%8F%99%281%29_2022.11.01.pdf`}
                          target="_blank"
                          rel="noreferrer"
                          download
                        >
                          <div className="text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer select-none">
                            활동기록부
                          </div>
                        </a>
                        <a
                          href={`https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%ED%8A%9C%ED%86%A0%EB%A6%AC%EC%96%BC%20%ED%95%99%EB%B6%80%EB%AA%A8%EC%9A%A9_2022.10.31.pdf`}
                          target="_blank"
                          rel="noreferrer"
                          download
                        >
                          <div className="text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer select-none">
                            보호자용
                          </div>
                        </a>
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="w-full border border-grey-6"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                />
                {/* <div className="text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer">
                  전체 일정 (준비중)
                </div>
                <div className="text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer">
                  채팅 (준비중)
                </div> */}
                {/* <Link
                  to="/teacher/attend"
                  className={
                    pathname.startsWith('/teacher/attend')
                      ? 'bg-darkgray text-white group flex items-center px-4 py-3 text-sm font-semibold rounded-md'
                      : 'text-darkgray hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-base rounded-md cursor-pointer'
                  }
                >
                  출결 관리
                </Link> */}

                <div className="text-gray-400 text-sm py-2">
                  <div className="text-white">
                    v{version} build at <br />
                    {preval`module.exports = new Date().toLocaleString().split("├")[0]`}
                  </div>
                  {schoolName} <br />
                  Copyright 2022. SUPERSCHOOL <br />
                  all right reserved.
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {/* Mobile V */}
      <div className="block md:hidden">
        <BottomNavbar
          style={{
            paddingTop: '0.25rem',
            paddingBottom: '0.25rem',
            zIndex: 100,
          }}
        >
          <BottomNavbarItem
            icon={
              pathname.includes('/teacher/studentcard')
                ? ManageIconSelected
                : ManageIcon
            }
            label="학생카드"
            style={{ fontSize: '11px' }}
            twLabel={{
              color: pathname.includes('/teacher/studentcard')
                ? 'text-gray-800'
                : 'text-gray-300',
            }}
            twLabelActive={{
              color: pathname.includes('/teacher/studentcard')
                ? 'text-gray-800'
                : 'text-gray-300',
            }}
            active={true}
            onClick={() => history.push('/teacher/studentcard')}
          />
          <BottomNavbarItem
            icon={
              pathname.includes('/teacher/apply') ||
              pathname.includes('/teacher/absent') ||
              pathname.includes('/teacher/outing')
                ? PlannerIconSelected
                : PlannerIcon
            }
            label="출결"
            twLabel={{
              color:
                pathname.includes('/teacher/apply') ||
                pathname.includes('/teacher/absent') ||
                pathname.includes('/teacher/outing')
                  ? 'text-gray-800'
                  : 'text-gray-300',
            }}
            twLabelActive={{
              color:
                pathname.includes('/teacher/apply') ||
                pathname.includes('/teacher/absent') ||
                pathname.includes('/teacher/outing')
                  ? 'text-gray-800'
                  : 'text-gray-300',
            }}
            style={{ fontSize: '11px' }}
            active={true}
            onClick={() => history.push('/teacher/apply')}
          />
          <BottomNavbarItem
            icon={
              pathname.includes('/teacher/v2timetable') ||
              pathname.includes(`/teacher/canteen`)
                ? HomeIconSelected
                : HomeIcon
            }
            label="홈"
            style={{ fontSize: '11px' }}
            twLabel={{
              color:
                pathname.includes('/teacher/v2timetable') ||
                pathname.includes(`/teacher/canteen`)
                  ? 'text-gray-800'
                  : 'text-gray-300',
            }}
            twLabelActive={{
              color:
                pathname.includes('/teacher/v2timetable') ||
                pathname.includes(`/teacher/canteen`)
                  ? 'text-gray-800'
                  : 'text-gray-300',
            }}
            active={true}
            onClick={() =>
              history.push(
                `/teacher/canteen/${d.getFullYear()}-${
                  d.getMonth() + 1
                }-${d.getDate()}`,
              )
            }
          />
          <BottomNavbarItem
            icon={
              pathname.includes('/teacher/notice') ||
              pathname.includes('/teacher/newsletter') ||
              pathname.includes('/teacher/board')
                ? NoticeIconSelected
                : NoticeIcon
            }
            label="공지"
            style={{ fontSize: '11px' }}
            twLabel={{
              color:
                pathname.includes('/teacher/notice') ||
                pathname.includes('/teacher/newsletter') ||
                pathname.includes('/teacher/board')
                  ? 'text-gray-800'
                  : 'text-gray-300',
            }}
            twLabelActive={{
              color:
                pathname.includes('/teacher/notice') ||
                pathname.includes('/teacher/newsletter') ||
                pathname.includes('/teacher/board')
                  ? 'text-gray-800'
                  : 'text-gray-300',
            }}
            active={true}
            onClick={() => history.push('/teacher/notice')}
          />

          <BottomNavbarItem
            icon={
              pathname.includes('/teacher/mypage') ||
              pathname.includes('/teacher/update')
                ? MypageIconSelected
                : MypageIcon
            }
            label={authenticated ? '더보기' : '로그인'}
            style={{ fontSize: '11px' }}
            twLabel={{
              color: pathname.includes('/teacher/mypage')
                ? 'text-gray-800'
                : 'text-gray-300',
            }}
            twLabelActive={{
              color:
                pathname.includes('/teacher/mypage') ||
                pathname.includes('/teacher/update')
                  ? 'text-gray-800'
                  : 'text-gray-300',
            }}
            active={true}
            onClick={() =>
              history.push(authenticated ? '/teacher/mypage' : '/')
            }
          />
        </BottomNavbar>
      </div>

      <div className="md:grid grid-cols-6 h-screen w-full overflow-y-scroll scroll-box md:overflow-y-hidden overflow-x-hidden">
        <Switch>
          <Route path="/teacher/canteen" component={CanteenPage} />
          <Route path="/teacher/timetable" component={TimetablePage} />
          <Route path="/teacher/v2timetable" component={TimetablePageV2} />
          <Route path="/teacher/attendance" component={AttendancePage} />
          <Route path="/teacher/absent" component={AbsentPage} />
          <Route path="/teacher/update" component={TeacherInfoPage} />
          <AuthRoute
            path="/teacher/first-login"
            component={TeacherFirstLoginPage}
          />
          <Route
            path="/teacher/fieldtrip/notice"
            component={FieldtripNoticePage}
          />
          <Route
            path="/teacher/fieldtrip/result"
            component={FieldtripResultPage}
          />
          <Route path="/teacher/board" component={BoardsPage} />
          <Route path="/teacher/chat" component={ChatListPage} />
          <Route path="/teacher/fieldtrip" component={FieldtripMainPage} />
          <Route path="/teacher/calendar" component={CalendarPage} />
          {/* <Route path="/teacher/attend" component={AttendPage} /> */}
          <Route path="/teacher/activity/:id" component={ActivityPage} />
          <Route path="/teacher/activity" component={ActivityPage} />
          {/* <Route path="/teacher/record/add" component={RecordAddPage} /> */}
          {/* <Route path="/teacher/record/:id" component={RecordPage} /> */}
          <Route path="/teacher/record" component={RecordPage} />
          <Route path="/teacher/outing" component={OutingPage} />
          <Route path="/teacher/studentcard" component={StudentCardPage} />
          <Route path="/teacher/manager" component={ManagerScheduleMainPage} />
          <Route path="/teacher/groups" component={GroupPage} />
          <Route path="/teacher/notice" component={NoticePage} />
          <Route path="/teacher/newsletter" component={NewsletterPage} />
          <Route path="/teacher/apply" component={TeacherApplyPage} />
          <Route path="/teacher/mypage" component={TeacherMypage} />
          <Route path="/teacher/login" component={LoginPage} />
          <Route path="/teacher">
            {process.env.REACT_APP_MENU_TYPE === '2' ? (
              <Redirect to="/teacher/absent" />
            ) : (
              <Redirect
                to={`/teacher/canteen/${d.getFullYear()}-${
                  d.getMonth() + 1
                }-${d.getDate()}`}
              />
            )}
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default MainPage;
