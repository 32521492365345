import {
  Alert,
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  Section,
  TopNavbar,
  useMutation,
} from '../../../reusable';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FIND_PASSWORD } from '../../../query';

interface FindPasswordSuccessPageProps {
  email: string;
  phone: string;
}

export const FindPasswordSuccessPage: FC<FindPasswordSuccessPageProps> = ({
  email,
  phone,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errM, setErrM] = useState('');
  const [mphone, setMphone] = useState(phone);

  const [findPassword] = useMutation(FIND_PASSWORD, {
    variables: { email, isMail: true },
    onCompleted: (result) => {
      setLoading(false);
      setMphone(result?.findPassword?.phone);
      alert('이메일이 발송되었습니다.');
    },
    onError: (err) => {
      setLoading(false);
      setErrM(err.message);
    },
  });
  return (
    <>
      <TopNavbar
        title="비밀번호 찾기"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      {loading && <Blank />}
      {errM && (
        <Alert severity="error" onClose={() => setErrM('')}>
          {errM}
        </Alert>
      )}

      {mphone ? (
        <Section>
          <h1 className="text-2xl font-bold">
            비밀번호 재설정 <br /> 알림톡을 보냈습니다.
          </h1>
          <div className="text-gray-400">
            카카오톡을 확인해 주세요. <br />
            카카오톡이 오지 않으면, 아래 버튼을 눌러 비밀번호 재설정 이메일을
            보내주세요.
          </div>
        </Section>
      ) : (
        <Section>
          <h1 className="text-2xl font-bold">
            비밀번호 재설정 <br /> 이메일을 보냈습니다.
          </h1>
          <div className="text-gray-400">
            받은편지함을 확인해 주세요. <br />
            이메일이 오지 않으면, 스팸메일함을 확인해 주시거나 아래 버튼을 눌러
            이메일을 다시 보내주세요.
          </div>
        </Section>
      )}

      <BottomFixedView tw={{ paddingBottom: 'pb-20' }}>
        <Section>
          <Button
            tw={{
              backgroundColor: 'bg-white',
              color: 'text-brand-1',
              borderColor: 'border-brand-1',
            }}
            onClick={async () => {
              await setLoading(true);
              await findPassword();
            }}
          >
            {mphone ? '이메일 전송' : '이메일 재전송'}
          </Button>
          <Button onClick={() => history.push('/')}>홈으로 가기</Button>
        </Section>
      </BottomFixedView>
    </>
  );
};
