import React, { useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import Viewer from 'react-viewer';
import Linkify from 'react-linkify';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import {
  BackButton,
  Blank,
  Button,
  Section,
  TopNavbar,
  useMutation,
  useQuery,
  utcToLocalDatetime,
} from '../../../reusable';
import {
  DELETE_NEWSLETTER,
  GET_NEWSLETTER_BY_TEACHER,
  PUBLISH_NEWSLETTER,
} from '../../../query';
import {
  ErrorBlank,
  FileItem,
  SuperSurveyComponent,
  SuperModal,
} from '../../../components';
import { NewsletterAddPage } from './NewsletterAddPage';

interface MatchParams {
  id: string;
}

interface NewsletterDetailPageProps extends RouteComponentProps<MatchParams> {
  isUpdateState: boolean;
  setUpdateState: (b: boolean) => void;
  refetch: () => void;
}

const NewsletterDetailPage: React.FC<NewsletterDetailPageProps> = ({
  match,
  isUpdateState,
  setUpdateState,
  refetch,
}) => {
  const history = useHistory();
  let { id } = match.params;
  let {
    loading,
    error,
    data,
    refetch: _refetch,
  }: any = useQuery(GET_NEWSLETTER_BY_TEACHER, {
    variables: { id: Number(id) },
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const newsletter = data?.newsletter;

  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const [content, setContent] = useState<Record<string, string>>({});

  const onCompleted = () => {
    setLoading(false);
    refetch();
    _refetch();
    setModalOpen(false);
    setDeleteModalOpen(false);
  };

  const onError = (err: any) => {
    setLoading(false);
    setModalOpen(false);
    refetch();
    _refetch();
    setDeleteModalOpen(false);
    setErrorText(err.message);
  };

  const [publishNewsletter] = useMutation(PUBLISH_NEWSLETTER, {
    variables: { id: Number(id) },
    onCompleted,
    onError,
  });

  const [deleteNewsletter] = useMutation(DELETE_NEWSLETTER, {
    variables: { id: Number(id) },
    onCompleted: () => {
      onCompleted();
      history.push('/teacher/newsletter');
    },
    onError,
  });

  const files: any[] = [];

  if (newsletter?.files) {
    try {
      newsletter?.files.map((f: any) => files.push(JSON.parse(f)));
    } catch (err) {
      console.error(err);
    }
  }

  let images: any[] = [];
  try {
    images = newsletter?.images
      ? newsletter?.images.map((el: any) => JSON.parse(el).url)
      : [];
  } catch (err) {
    console.error(err);
  }

  let viewerImages: any[] = [];

  for (let image of images) {
    viewerImages.push({
      src: image,
      title: '',
    });
  }

  if (isUpdateState) {
    return (
      <NewsletterAddPage
        newsletterData={newsletter}
        refetch={() => {
          refetch();
          _refetch();
          setUpdateState(false);
        }}
      />
    );
  }

  return (
    <>
      {/* Mobile V */}
      <div className="bg-white block md:hidden">
        {loading && <Blank />}
        {error && <ErrorBlank />}
        <TopNavbar title="가정통신문" left={<BackButton />} />

        <Section>
          <div className="w-full flex justify-between space-x-2 ">
            <div className="rounded-3xl px-3 py-1 bg-purple-100 text-sm font-bold text-purple-700 w-max">
              가정통신문
            </div>
          </div>
          <div className="text-lg font-bold text-grey-1">
            {data?.newsletter?.title}
          </div>
          <div className="text-grey-3 text-sm">
            {utcToLocalDatetime(data?.newsletter?.createdAt?.toString() || '')}
          </div>
          <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
            {images?.map((image: string, i: number) => (
              <>
                <div
                  className="w-full"
                  onClick={() => {
                    setActiveIndex(i);
                    setImagesModalOpen(true);
                  }}
                >
                  <div className={`relative pb-3/5 rounded bg-gray-50`}>
                    <ExifOrientationImg
                      className={`absolute w-full h-full rounded object-cover`}
                      src={image}
                      loading="lazy"
                      alt=""
                    />
                  </div>
                </div>
              </>
            ))}
          </div>
          {files?.length ? (
            <div className="bg-gray-50">
              {files?.map((f: any) => (
                <FileItem file={f} />
              ))}
            </div>
          ) : (
            <></>
          )}
          <div className={`text-grey-1 whitespace-pre-line feedback_space`}>
            <Linkify>{newsletter?.content}</Linkify>
          </div>
          {newsletter?.type === 'STUDENT_PARENTS_SURVEY' && (
            <>
              <SuperSurveyComponent
                surveyContent={newsletter.surveyContent}
                setContent={(c: any) => setContent(c)}
                content={content}
              />
            </>
          )}
        </Section>
      </div>

      {/* Desktop V */}
      <div className="hidden md:block">
        <div className="bg-white border rounded-lg p-5">
          {loading && <Blank reversed />}
          {error && <ErrorBlank />}
          {errorText && <ErrorBlank text={errorText} />}
          {isLoading && <Blank reversed />}
          <Section>
            <div className="w-full flex justify-between space-x-2">
              <div className="rounded-md px-3 py-1 bg-purple-100 text-sm font-bold text-purple-700 w-max">
                가정통신문
              </div>
              <div className="flex space-x-4 font-base text-gray-500 cursor-pointer">
                {!newsletter?.isPublished && (
                  <div
                    className="text-gray-700"
                    onClick={() => setUpdateState(true)}
                  >
                    수정
                  </div>
                )}

                <div
                  className="text-red-400 cursor-pointer"
                  onClick={() => setDeleteModalOpen(true)}
                >
                  삭제
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mt-3">
              {data?.newsletter?.klasses?.map((klass: number) => (
                <span className="rounded-full py-2 px-3 border border-gray-400 font-semibold text-gray-500 text-sm mr-2 mb-2">
                  {klass}학년
                </span>
              ))}
            </div>
            <div className="text-lg font-bold text-grey-1">
              {data?.newsletter?.title}
            </div>
            <div className="text-grey-3 text-sm">
              {utcToLocalDatetime(
                data?.newsletter?.createdAt?.toString() || '',
              )}
            </div>
            <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
              {images?.map((image: string, i: number) => (
                <>
                  <div
                    className="w-full"
                    onClick={() => {
                      setActiveIndex(i);
                      setImagesModalOpen(true);
                    }}
                  >
                    <div className={`relative pb-3/5 rounded bg-gray-50`}>
                      <ExifOrientationImg
                        className={`absolute w-full h-full rounded object-cover`}
                        src={image}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </>
              ))}
            </div>
            {files?.length ? (
              <div className="bg-gray-50">
                {files?.map((f: any) => (
                  <FileItem file={f} />
                ))}
              </div>
            ) : (
              <></>
            )}
            <div className={`text-grey-1 whitespace-pre-line feedback_space`}>
              <Linkify>{newsletter?.content}</Linkify>
            </div>
            {newsletter?.type === 'STUDENT_PARENTS_SURVEY' && (
              <>
                <SuperSurveyComponent
                  surveyContent={newsletter.surveyContent}
                  setContent={(c: any) => setContent(c)}
                  content={content}
                />
                {!newsletter.isPublished && (
                  <Button
                    tw={{ width: 'w-full' }}
                    text="발행하기"
                    onClick={() => setModalOpen(true)}
                  />
                )}
              </>
            )}
            <SuperModal
              modalOpen={modalOpen}
              setModalClose={() => setModalOpen(false)}
              width="w-100"
            >
              <div className="px-12 py-6">
                <div className="w-full text-lg font-bold text-center text-gray-900 mb-4">
                  해당 가정통신문을 발행하시겠습니까?
                </div>
                <div className="w-full text-sm text-center mb-6">
                  가정통신문을 발행하면 학생이 열람하고 답변할 수 있으며 <br />
                  수정이 불가능해집니다.
                </div>
                <Button
                  tw={{ width: 'w-full' }}
                  onClick={() => {
                    setLoading(true);
                    publishNewsletter();
                  }}
                >
                  발행하기
                </Button>
              </div>
            </SuperModal>
            <SuperModal
              modalOpen={isDeleteModalOpen}
              setModalClose={() => setDeleteModalOpen(false)}
              width="w-max"
            >
              <Section tw={{ marginTop: 'mt-7' }}>
                <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
                  정말 해당 가정통신문을 삭제하시겠습니까?
                </div>
                <Button onClick={() => deleteNewsletter()}>삭제하기</Button>
              </Section>
            </SuperModal>
          </Section>
        </div>
      </div>

      <div className="absolute">
        <Viewer
          visible={hasImagesModalOpen}
          rotatable={true}
          noImgDetails={true}
          //drag={false}
          scalable={false}
          images={viewerImages}
          onClose={() => {
            setImagesModalOpen(false);
          }}
          activeIndex={activeIndex}
        />
      </div>
    </>
  );
};

export default withRouter(NewsletterDetailPage);
