import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  TopNavbar,
  BackButton,
  Section,
  useQuery,
  Blank,
} from '../../../reusable';
import { ErrorBlank, Td } from '../../../components';
import { GET_FIELDTRIP_NOTICE } from '../../../query';
import { makeStartEndToString } from '../../../utils';

interface MatchParams {
  id: string;
}

const FieldtripNoticePage: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { id } = match.params;
  const { loading, error, data } = useQuery(GET_FIELDTRIP_NOTICE, {
    variables: { fieldtripId: Number(id) },
  });

  const me = data?.me;
  const fieldtrip = data?.fieldtripById;
  const school = me?.school;

  return (
    <>
      {loading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar
        title="통보서"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <h1 className="text-xl font-bold text-center underline">
          학교장허가 교외체험 학습통보서
        </h1>
        <label className="mb-1 text-sm text-center font-semibold text-gray-800">
          {fieldtrip?.student?.name} {fieldtrip?.studentGradeKlass}{' '}
          {fieldtrip?.studentNumber}번
        </label>
        <table className="w-full text-center font-bold">
          <tr>
            <Td innerClassName="min-w-max">신청기간</Td>
            <Td>
              {fieldtrip?.startAt &&
                fieldtrip?.endAt &&
                makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)}{' '}
              ({fieldtrip?.usedDays}
              일간)
            </Td>
          </tr>
          <tr>
            <Td innerClassName="min-w-max">허가기간</Td>
            <Td>
              {fieldtrip?.startAt &&
                fieldtrip?.endAt &&
                makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)}{' '}
              ({fieldtrip?.usedDays}
              일간)
            </Td>
          </tr>
        </table>
        <div className="text-sm">
          본교 교외체험학습{' '}
          <span className="font-bold">
            출석인정 기간 연간 {school?.fieldtripDays}일 중{' '}
          </span>{' '}
          금회까지
          <span className="font-bold">
            {' '}
            누적 사용기간은{' '}
            {school?.fieldtripDays &&
              fieldtrip?.currentRemainDays &&
              fieldtrip?.usedDays &&
              school.fieldtripDays -
                fieldtrip.currentRemainDays +
                fieldtrip.usedDays}
            일
          </span>
          입니다.
        </div>
        <label className="mb-1 text-sm text-center text-gray-800">
          위와 같이 처리되었음을 알려드립니다.
        </label>
        <label className="mb-1 text-sm text-center text-gray-800">
          {fieldtrip?.noticedAt}
        </label>
        <div className="w-full flex items-center space-x-4">
          <div className="text-gray-600 w-full text-right min-w-max font-bold mr-6">
            {me?.school?.name} {fieldtrip?.studentGradeKlass}
          </div>
        </div>
        <div className="w-full flex flex-col items-end">
          {
            <div
              className="bg-no-repeat bg-right bg-contain h-20"
              style={{
                backgroundImage: `url(${fieldtrip?.teacherSignature})`,
              }}
            >
              <div className="min-w-max font-bold mt-6 text-right mr-6">
                담임교사: {fieldtrip?.teacher?.name} &nbsp;&nbsp;&nbsp;(인)
              </div>
            </div>
          }
        </div>

        <div className="w-full flex items-center space-x-4">
          <div className="text-gray-600 w-full text-right min-w-max">
            {me?.nokName} 귀하
          </div>
        </div>
        <label className="mb-1 text-sm text-center font-semibold text-gray-800">
          *교외체험학습 실시 중에는 보호자와 담당교사 간 연락체계를 유지하고
          사안(사고) 발생 시 보호자는 담당교사에게 연락을 하도록 합니다.
        </label>
        <label className="mb-1 text-sm text-center font-semibold text-gray-800">
          *결과보고서 제출 기한은 체험학습 종료 후 5일 이내입니다.
        </label>
      </Section>
    </>
  );
};

export default withRouter(FieldtripNoticePage);
