import {
  BottomFixedView,
  Divider,
  Label,
  List,
  Section,
  TextArea,
  useMutation,
  utcToLocalDatetime,
  utcToLocalFromNow,
} from '../../../reusable';
import React, { useState } from 'react';
import {
  CommentItem,
  FileItem,
  SuperSearchField,
  SurveyComponent,
} from '../../../components';
import { Activity, StudentActivity } from '../../../types';
import { CREATE_COMMENT, DELETE_COMMENT, UPDATE_COMMENT } from '../../../query';
import { ReactComponent as SendIcon } from '../../../assets/svg/icon-send.svg';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';

interface ActivityDetailReadPageProps {
  studentActivity: StudentActivity;
  activity: Activity;
  type?: string;
  refetch: () => void;
  setSubmitState: () => void;
  setLoading: (state: boolean) => void;
  userId: number;
}

export const ActivityDetailReadPage: React.FC<ActivityDetailReadPageProps> = ({
  studentActivity,
  activity,
  type,
  refetch,
  setSubmitState,
  setLoading,
  userId,
}) => {
  const [text, setText] = useState('');
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [hasImagesModalOpen, setImagesModalOpen] = useState<boolean[]>(
    studentActivity?.images?.map((el) => false) || [],
  );

  const [createComment] = useMutation(CREATE_COMMENT, {
    variables: {
      content: text,
      said: studentActivity?.id,
    },
    errorPolicy: 'ignore',
    onCompleted: async () => {
      await setLoading(false);
      await refetch();
      setText('');
    },
  });

  const [updateComment] = useMutation(UPDATE_COMMENT, {
    errorPolicy: 'ignore',
    onCompleted: () => {
      refetch();
    },
  });

  const [deleteComment] = useMutation(DELETE_COMMENT, {
    onError: async (err) => {
      console.error(err);
      if (refetch) {
        await refetch();
      }
    },
    onCompleted: async () => {
      if (refetch) {
        await refetch();
      }
    },
  });

  // const comments = studentActivity?.comments?.sort(
  //   (a: any, b: any) => a.id - b.id,
  // );]

  let files: any[] = [];

  if (studentActivity?.files) {
    try {
      studentActivity?.files.map((f: any) => files.push(JSON.parse(f)));
    } catch (err) {
      files = studentActivity?.files || [];
    }
  }

  let images: any[] = [];
  try {
    images = studentActivity?.images
      ? studentActivity?.images.map((el) => JSON.parse(el).url)
      : [];
  } catch (err) {
    images = studentActivity?.images || [];
  }

  return (
    <>
      <Section tw={{ backgroundColor: 'bg-gray-50' }}>
        {images?.map((image: string, i: number) => (
          <>
            <div
              className="w-full"
              onClick={() => {
                const newOpens = hasImagesModalOpen.slice();
                newOpens[i] = true;
                setImagesModalOpen(newOpens);
              }}
            >
              {/* <a href={image} target="_blank" rel="noreferrer"> */}
              <div className={`relative pb-3/5 rounded bg-gray-50`}>
                <img
                  className={`absolute w-full h-full rounded object-cover`}
                  src={image}
                  loading="lazy"
                  alt=""
                />
              </div>
              {/* </a> */}
            </div>
            <div className="absolute">
              {hasImagesModalOpen[i] ? (
                <div
                  className={`fixed inset-0 w-full h-screen z-100 bg-black flex items-center justify-center`}
                  onClick={() => {
                    const newOpens = hasImagesModalOpen.slice();
                    newOpens[i] = false;
                    setImagesModalOpen(newOpens);
                  }}
                >
                  <ExifOrientationImg
                    src={image}
                    alt=""
                    className="w-full"
                    loading="lazy"
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        ))}
        {files?.length ? (
          <div>
            {files?.map((f: any) => (
              <FileItem file={f} />
            ))}
          </div>
        ) : (
          <></>
        )}

        {activity.isRecord && (
          <>
            <Label text="활동요약" />
            <div className="text-base font-semibold text-gray-600">
              {activity.explainText}
            </div>
            <TextArea value={studentActivity.summary} readOnly />
          </>
        )}
        {type === 'SURVEY' ? (
          <>
            <SurveyComponent
              content={activity?.content || '{}'}
              setContent={(c: string) => {}}
              display
              value={studentActivity?.content}
            />
          </>
        ) : (
          <div className="text-grey-3 whitespace-pre-line">
            {studentActivity?.content}
          </div>
        )}
        <Divider />
        {studentActivity?.updatedAt &&
          activity?.updatedAt &&
          studentActivity.updatedAt !== activity.updatedAt && (
            <div className="mt-3 text-brandblue-1">
              제출 완료 일시 :{' '}
              {utcToLocalDatetime(
                new Date(studentActivity.updatedAt).toISOString(),
              )}{' '}
              (
              {utcToLocalFromNow(
                new Date(studentActivity.updatedAt).toISOString(),
              )}
              )
            </div>
          )}
      </Section>
      <List>
        {studentActivity?.comments
          ?.slice()
          .sort((a: any, b: any) => a.id - b.id)
          .map((comment: any) => (
            <CommentItem
              comment={comment}
              userId={userId}
              updateComment={updateComment}
              deleteComment={deleteComment}
            />
          ))}
        <br />
        <br />
      </List>

      {isImageModalOpen ? (
        <div
          className={`fixed inset-0 w-full h-screen z-100 bg-black flex items-center justify-center`}
          onClick={() => setImageModalOpen(false)}
        >
          <img src={studentActivity?.image} alt="" className="w-full" />
        </div>
      ) : (
        <></>
      )}
      <BottomFixedView>
        <div className="flex items-center space-x-2 px-5 py-2">
          <SuperSearchField
            placeholder="댓글은 담당 선생님께만 노출됩니다."
            value={text}
            onChange={(e) => setText(e.target.value)}
            onSearch={() => {
              if (text === '') {
                alert('텍스트 내용을 입력해주세요.');
              } else {
                setLoading(true);
                createComment();
              }
            }}
          />

          <SendIcon
            onClick={() => {
              if (text === '') {
                alert('텍스트 내용을 입력해주세요.');
              } else {
                setLoading(true);
                createComment();
              }
            }}
          />
        </div>
      </BottomFixedView>
    </>
  );
};
