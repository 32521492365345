import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import { Group, TeacherGroup } from '../../../types';
import { ErrorBlank, SelectMenus } from '../../../components';
import RecordDetailPage from './RecordDetailPage';
import { fetcher, api } from '../../../plugins';
import { Button } from '../../../reusable';

const RecordPage: React.FC = () => {
  const history = useHistory();
  const selectedGroupId = Number(localStorage.getItem('selectedGroupId'));
  const [selectedSGId, setSelectedSGId] = useState(0);

  const { error, data } = uQ('/teacher-groups?isStudent=true', fetcher);

  const teacherGroups = data?.teacherGroups;

  const activityGroups = teacherGroups
    ?.map((el: TeacherGroup) => el.group)
    ?.filter((el: any) => !!el)
    ?.sort((a: any, b: any) => {
      const aData = a.name.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);
      const bData = b.name.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);

      if (!aData || !bData) {
        return 0;
      }

      if (aData[1] && bData[1]) {
        if (aData[1] === bData[1]) {
          return Number(aData[2]) - Number(bData[2]);
        } else {
          return Number(aData[1]) - Number(bData[1]);
        }
      } else {
        return 0;
      }
    });

  const groups = activityGroups?.filter(
    (group: Group, i: number) =>
      i === activityGroups.findIndex((g: Group) => g?.name === group?.name),
  );

  const [selectedGroup, setSelectedGroup] = useState<Group>(
    groups
      ? groups?.filter((g: Group) => g.id === Number(selectedGroupId))[0] ||
          groups[0]
      : null,
  );

  const subject = teacherGroups?.filter(
    (tg: TeacherGroup) => (tg.groupId = selectedGroup?.id),
  )[0]?.subject;

  useEffect(() => {
    if (groups && !selectedGroup) {
      setSelectedGroup(
        selectedGroup ||
          groups?.filter((g: Group) => g.id === Number(selectedGroupId))[0] ||
          groups[0],
      );
    }
  }, [groups]);

  const { data: recordDetail, refetch } = uQ(
    `/life_record/${selectedGroup?.id}`,
    fetcher,
    { enabled: !!selectedGroup?.id, keepPreviousData: true },
  );

  // if (loading) return <LoadingScreen />;
  if (error) return <ErrorBlank />;

  return (
    <div className="col-span-6 grid grid-cols-6">
      <div className="col-span-2 h-screen border-r border-gray-200 hidden md:block">
        <div className="px-6 py-6">
          <div className="flex justify-between mb-5">
            <h1 className="text-2xl font-semibold">생활기록부</h1>
          </div>
          <div className="w-max">
            <div className="mb-5 ml-3 mr-3 flex space-x-2 items-center justify-between">
              <SelectMenus
                items={groups}
                value={selectedGroup}
                allText="{학년/반}"
                onChange={(group: Group) => {
                  if (group?.id) {
                    localStorage.setItem(
                      'selectedGroupId',
                      group.id.toString(),
                    );
                    setSelectedGroup(group);
                  }
                }}
              />
            </div>
            <div>
              <div className="text-center text-gray-500">일괄 다운로드</div>
              <div className="flex space-x-2 items-center">
                <Button
                  onClick={() =>
                    api
                      .get(
                        `/life_record/download/summary/${selectedGroup.id}`,
                        {
                          responseType: 'blob',
                          headers: {
                            'Content-Type':
                              'application/x-www-form-urlencoded; charset=UTF-8',
                          },
                        },
                      )
                      .then((res) => {
                        const url = URL.createObjectURL(res.data);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `생활기록부_총정리_${selectedGroup.name}`;
                        link.click();
                        URL.revokeObjectURL(url);
                      })
                  }
                >
                  총정리
                </Button>
                <Button
                  onClick={() =>
                    api
                      .get(
                        `/life_record/download/activity/${selectedGroup.id}`,
                        {
                          responseType: 'blob',
                          headers: {
                            'Content-Type':
                              'application/x-www-form-urlencoded; charset=UTF-8',
                          },
                        },
                      )
                      .then((res) => {
                        const url = URL.createObjectURL(res.data);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `생활기록부_활동내역_${selectedGroup.name}}`;
                        link.click();
                        URL.revokeObjectURL(url);
                      })
                  }
                >
                  활동내역
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        {/* 생기부 이름 리스트 */}
        <div className="w-full h-screen-12 overflow-y-auto scroll-box">
          <div className="w-full grid grid-flow-row grid-cols-2 gap-2 px-5 mb-20">
            {recordDetail?.studentGroups?.map((sg: any) => (
              <div
                className={`rounded-lg border-2 text-center py-2 cursor-pointer ${
                  sg.id === selectedSGId && 'bg-light_orange border-brand-1'
                }`}
                onClick={() => {
                  localStorage.setItem('recordSummary', ' ');
                  setSelectedSGId(sg.id);
                  history.push(`/teacher/record/${sg?.user?.id}`);
                }}
              >
                {sg?.user?.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-span-4 h-screen scroll-box overflow-y-scroll">
        <Switch>
          <Route
            path="/teacher/record/:id"
            component={() => (
              <RecordDetailPage
                selectedGroup={selectedGroup}
                studentGroups={recordDetail?.studentGroups}
                refetch={() => refetch()}
                me={data}
                subject={subject}
              />
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(RecordPage);
