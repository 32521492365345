import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Viewer from 'react-viewer';
import Linkify from 'react-linkify';
import {
  BackButton,
  Blank,
  Section,
  TopNavbar,
  useQuery,
  utcToLocalDatetime,
} from '../../../reusable';
import { ErrorBlank, BoardFileItem } from '../../../components';
import { GET_BOARD_DETAIL } from '../../../query';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';

interface MatchParams {
  id: string;
}

interface BoardImageType {
  name: string;
  url: string;
  key: string;
}

export const BoardDetailPage: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { id } = match.params;

  const { loading, error, data } = useQuery(GET_BOARD_DETAIL, {
    variables: { id: Number(id) },
  });

  const board = data?.board;

  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  let boardImages: BoardImageType[] = [];

  try {
    board?.images.map((im: any) => boardImages.push(JSON.parse(im)));
  } catch (err) {}

  const viewerImages = boardImages?.map((el: BoardImageType) => ({
    src: el.url,
    title: el.name,
  }));

  return (
    <div>
      {loading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar
        title="학급 게시판"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />

      <Section>
        <div className="flex items-center justify-between">
          <div className="rounded-md px-3 py-1 bg-red-50 text-sm font-bold text-red-500 w-max">
            {board?.category || '학급게시판'}
          </div>
          <div className="text-sm text-gray-500">{board?.writer?.name}</div>
        </div>
        <div>
          <div className="text-xl font-bold text-grey-1">{board?.title}</div>
          <div className="text-grey-5 text-sm mb-2">
            {utcToLocalDatetime(board?.createdAt?.toString() || '')}
          </div>
        </div>
        <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
          {boardImages?.map((image: BoardImageType, i: number) => (
            <>
              <div
                className="w-full"
                onClick={() => {
                  setActiveIndex(i);
                  setImagesModalOpen(true);
                }}
              >
                <div className={`relative pb-3/5 rounded bg-gray-50`}>
                  <ExifOrientationImg
                    className={`absolute w-full h-full rounded object-cover`}
                    src={image.url}
                    loading="lazy"
                    alt=""
                  />
                </div>
              </div>
            </>
          ))}
        </div>
        <div>
          {board?.files?.map((file: string) => (
            <BoardFileItem file={file} />
          ))}
        </div>
        <div
          className={`text-grey-2 break-words whitespace-pre-line feedback_space`}
        >
          <Linkify>{board?.content}</Linkify>
        </div>

        <div className="absolute">
          <Viewer
            visible={hasImagesModalOpen}
            rotatable={true}
            noImgDetails={true}
            //drag={false}
            scalable={false}
            images={viewerImages}
            onClose={() => {
              setImagesModalOpen(false);
            }}
            activeIndex={activeIndex}
          />
        </div>
      </Section>
    </div>
  );
};
