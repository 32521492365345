import React from 'react';
import ReactDOM from 'react-dom';
import './tailwind.generated.css';
import './calendar.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ReusableProvider } from './reusable';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from 'react-query';

const client = new QueryClient();
Sentry.init({
  dsn: 'https://9221713b290944b9a0b90d3b8f6de86f@sentry.martian.link/8',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <ReusableProvider>
        <App />
      </ReusableProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
