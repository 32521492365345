import { useState } from 'react';
import { useQuery as uQ } from 'react-query';
import { fetcher } from '../../../plugins';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import {
  Blank,
  Button,
  Label,
  Section,
  Select,
  TextArea,
  TextField,
  useMutation,
} from '../../../reusable';
import { FileItem, ErrorBlank } from '../../../components';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import { CREATE_NOTICE, UPDATE_NOTICE } from '../../../query';
import undoArrow from '../../../assets/images/undo-arrow.png';

interface NoticeAddProps {
  refetch?: () => void;
  noticeData?: any;
}

//const categories = ['공지', '일정', '자료', '신청', '평가'];

export const NoticeAddPage: React.FC<NoticeAddProps> = ({
  refetch,
  noticeData,
}) => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [title, setTitle] = useState(noticeData?.title || '');
  const [content, setContent] = useState(noticeData?.content || '');
  const [category, setCategory] = useState(noticeData?.category || '');
  const [categories, setCategories] = useState<any[]>([]);
  const [images, setImages] = useState<(File | null | undefined)[]>([null]);
  const [files, setFiles] = useState<(File | null | undefined)[]>([]);
  const [willRemoveImages, setWillRemoveImages] = useState<any[]>([]);
  const [willRemoveFiles, setWillRemoveFiles] = useState<any[]>([]);

  const [createNotice] = useMutation(CREATE_NOTICE, {
    variables: {
      data: JSON.stringify({ title, content, category }),
      images: images,
      files: files,
    },
    onCompleted: ({ createNotice }) => {
      setLoading(false);
      refetch && refetch();
      history.push(`/teacher/notice/${createNotice.id}`);
    },
    onError: (err) => {
      setErrorText(err.message);
      setLoading(false);
    },
  });
  const [updateNotice] = useMutation(UPDATE_NOTICE, {
    variables: {
      data: JSON.stringify({
        id: noticeData?.id,
        title,
        content,
        category,
        willRemoveImages,
        willRemoveFiles,
      }),
      images: images,
      files: files,
    },
    onCompleted: () => {
      setLoading(false);
      refetch && refetch();
    },
    onError: (err) => {
      setErrorText(err.message);
      setLoading(false);
    },
  });

  const { data: categoriesData } = uQ(`/codes?category=notice`, fetcher, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });

  if (categories?.length === 0) {
    setCategories(
      categoriesData?.map((item: any) => {
        return item.name;
      }),
    );
  }

  // console.log('images : ', images);
  // console.log('files : ', files);

  let nImages: any[] = [];
  try {
    nImages = noticeData?.images
      ? noticeData?.images.map((image: any) => JSON.parse(image))
      : [];
  } catch (err) {
    console.error(err);
  }

  let nFiles: any[] = [];

  if (noticeData?.files) {
    try {
      noticeData?.files.map((f: any) => nFiles.push(JSON.parse(f)));
    } catch (err) {
      console.error(err);
    }
  }

  const buttonDisabled = title.length === 0 || content.length === 0;
  return (
    <div>
      {isLoading && <Blank />}
      {errorText && <ErrorBlank text={errorText} />}
      <div>
        <TextField
          label="제목"
          id="title"
          placeholder="제목을 입력해주세요"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
      </div>
      <div>
        <Select
          label="구분"
          id="category"
          onChange={(e) => setCategory(e.target.value)}
          value={category}
        >
          <option selected hidden>
            구분을 선택해주세요
          </option>
          {categories?.map((el: any) => (
            <option id={el} value={el} key={el}>
              {el}
            </option>
          ))}
        </Select>
      </div>
      <div>
        <TextArea
          label="내용"
          placeholder="내용을 입력해주세요."
          value={content}
          onChange={(e) => setContent(e.target.value)}
          style={{ borderWidth: '1px' }}
        />
      </div>
      <Section>
        <div>
          <Label>이미지</Label>
          <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
            {nImages?.map((el: any) => (
              <div>
                <div
                  className={`relative pb-3/5 rounded border-2 border-dashed border-grey-5 ${
                    willRemoveImages.includes(el.data) ? 'opacity-50' : ''
                  }`}
                >
                  <span className="absolute -top-3 -right-3 block w-6 h-6 rounded-full ring-2 ring-white bg-red-700 z-40">
                    <div
                      className="w-full h-full flex items-center justify-center text-white cursor-pointer"
                      onClick={() =>
                        willRemoveImages.includes(el.data)
                          ? setWillRemoveImages(
                              willRemoveImages.filter(
                                (imgUrl) => imgUrl !== el.data,
                              ),
                            )
                          : setWillRemoveImages(
                              willRemoveImages.concat(el.data),
                            )
                      }
                      style={{ transform: 'translate(0.1px, 0.1px)' }}
                    >
                      {willRemoveImages.includes(el.data) ? (
                        <img src={undoArrow} alt="" className="w-4 h-4" />
                      ) : (
                        'X'
                      )}
                    </div>
                  </span>
                  <ExifOrientationImg
                    className="absolute w-full h-full rounded object-cover"
                    src={el.url}
                    alt=""
                  />
                  {willRemoveImages.includes(el.data) && (
                    <div className="absolute w-full h-full z-20">
                      <div className="flex w-full h-full items-center justify-center">
                        <div className="text-4xl text-gray-800 opacity-100">
                          삭제
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
            {images?.map((el: File | null | undefined, i: number) => (
              <div className="">
                <label htmlFor={`imageupload${i}`}>
                  <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
                    {el ? (
                      <img
                        className="absolute w-full h-full rounded object-cover"
                        src={URL.createObjectURL(el)}
                        alt=""
                      />
                    ) : (
                      <div className="absolute w-full h-full rounded object-cover bg-white">
                        <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                          <FileUploadImg />
                          <div className="text-brand-1">
                            이미지를 업로드해주세요!
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  id={`imageupload${i}`}
                  className="hidden"
                  accept=".png, .jpeg, .jpg"
                  onChange={(e) => {
                    let size = 0;

                    images.map((el: File | string | null | undefined) => {
                      if (el instanceof File) {
                        size += el.size || 0;
                      }
                    });

                    files.map((el: File | null | undefined) => {
                      size += el?.size || 0;
                    });

                    if (size >= 20 * 1024 * 1024) {
                      alert(
                        '한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요!',
                      );
                    } else {
                      const newImages = images.slice();
                      if (e.target.files && e.target.files?.item(0)) {
                        newImages[i] = e.target.files?.item(0);
                        newImages.push(null);
                      }
                      e.target.validity.valid && setImages(newImages);
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div>
          <Label>파일</Label>
          <div className="bg-white rounded border-2 border-dashed border-grey-5">
            {nFiles?.map((file: any) => (
              <FileItem
                file={file}
                canClose
                willRemoveFiles={willRemoveFiles}
                setWillRemoveFiles={() =>
                  willRemoveFiles.includes(file.data)
                    ? setWillRemoveFiles(
                        willRemoveFiles.filter(
                          (fileName) => fileName !== file.data,
                        ),
                      )
                    : setWillRemoveFiles(willRemoveFiles.concat(file.data))
                }
              />
            ))}
            {files.map((file: any) => (
              <FileItem file={file} />
            ))}
            <label htmlFor="fileupload">
              <div className="w-full flex space-x-1 justify-center items-center pt-0.5 pb-2.5 cursor-pointer">
                <span className="text-2xl text-grey-3 mb-1">+</span>
                <div className="text-brand-1">
                  파일을 업로드해주세요 (다중 업로드 가능)
                </div>
              </div>
            </label>
          </div>
          <input
            type="file"
            id="fileupload"
            className="hidden"
            multiple
            onChange={(e) => {
              const newFiles = e.target.files
                ? files.concat(Array.from(e.target.files))
                : files;

              let size = 0;

              images.map((el: File | string | null | undefined) => {
                if (el instanceof File) {
                  size += el?.size || 0;
                }
              });

              newFiles.map((el: File | null | undefined) => {
                size += el?.size || 0;
              });

              if (size >= 20 * 1024 * 1024) {
                alert(
                  '한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요!',
                );
              } else {
                e.target.validity.valid && setFiles(newFiles);
              }
            }}
          />
        </div>
      </Section>
      <div className="text-center pb-8 mt-6">
        <Button
          text="등록하기"
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
            height: 'h-11',
          }}
          style={{ width: '70%' }}
          disabled={buttonDisabled}
          onClick={() => {
            setLoading(true);
            noticeData ? updateNotice() : createNotice();
          }}
        />
      </div>
    </div>
  );
};
