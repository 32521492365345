import {
  BackButton,
  Blank,
  Button,
  LoadingScreen,
  Section,
  TopNavbar,
  useMutation,
} from '../../../reusable';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StudentGroup, User } from '../../../types';
import { MyInfoUpdatePage } from './MyInfoUpdatePage';
import { api } from '../../../plugins';
import { SEND_PARENT_SIGNUP } from '../../../query';

export const MyInfoPage = () => {
  const history = useHistory();

  const [me, setMe] = useState<User>();
  const [isLoading, setLoading] = useState(false);

  const [sendParentSignup] = useMutation(SEND_PARENT_SIGNUP, {
    onCompleted: () => {
      alert('보호자 회원가입 메시지 발송이 완료되었습니다.');
      setLoading(false);
    },
    onError: (err) => {
      alert(err.message || '메시지 발송 중 오류가 발생하였습니다.');
      setLoading(false);
    },
  });

  useEffect(() => {
    if (!me) {
      api
        .get('/users/me/detail')
        .then((data) => setMe(data.data))
        .catch((err) => {
          if (err.message.includes('Unauthorized')) {
            history.replace('/login');
          } else {
            alert(err.message);
          }
        });
    }
  }, [me]);

  const isNotParent = me?.role !== 'PARENT';

  const [isUpdateState, setUpdateState] = useState(false);

  if (!me) return <LoadingScreen />;

  const studentGroups = me?.studentGroups?.filter(
    (sg: StudentGroup) => sg.group?.type === 'KLASS',
  );

  if (isUpdateState) {
    return (
      <MyInfoUpdatePage
        me={me}
        refetch={() => setMe(undefined)}
        gotoRead={() => setUpdateState(false)}
      />
    );
  }

  return (
    <div>
      {isLoading && <Blank />}
      <TopNavbar
        title="내 정보"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <div>
          <div className="text-lg font-bold text-gray-800">이름</div>
          <div className="text-grey-2">{me?.name}</div>
        </div>
        <div>
          <div className="text-lg font-bold text-gray-800">이메일</div>
          <div className="text-grey-2">{me?.email}</div>
        </div>
        <div>
          <div className="text-lg font-bold text-gray-800">전화번호</div>
          <div className="text-grey-2">{me?.phone}</div>
        </div>
        <div>
          <div className="text-lg font-bold text-gray-800">학교</div>
          <div className="text-grey-2">{me?.school?.name}</div>
        </div>
        {isNotParent && (
          <div>
            <div className="text-lg font-bold text-gray-800">반/번호</div>
            <div className="text-grey-2">
              {studentGroups?.map((sg: StudentGroup) => (
                <>
                  {sg?.group?.year} {me?.school?.name} {sg?.group?.name}{' '}
                  {sg?.studentNumber}번<br />
                </>
              ))}
              {}
            </div>
          </div>
        )}
        {isNotParent && (
          <>
            {/* <div>
              <div className="text-lg font-bold text-gray-800">생년월일</div>
              <div className="text-grey-2">{me?.birthDate}</div>
            </div> */}
            <div>
              <div className="text-lg font-bold text-gray-800">희망진로</div>
              <div className="text-grey-2">{me?.hopePath}</div>
            </div>
            <div>
              <div className="text-lg font-bold text-gray-800">희망학과</div>
              <div className="text-grey-2">{me?.hopeMajor}</div>
            </div>
            <div>
              <div className="text-lg font-bold text-gray-800">보호자 이름</div>
              <div className="text-grey-2">{me?.nokName}</div>
            </div>
            <div>
              <div className="text-lg font-bold text-gray-800">
                보호자 연락처
              </div>
              <div className="text-grey-2">{me?.nokPhone}</div>
            </div>
            <Button
              tw={{
                borderColor: 'border-brand-1',
                backgroundColor: 'white',
                color: 'text-brand-1',
              }}
              onClick={() => {
                setLoading(true);
                sendParentSignup();
              }}
            >
              보호자 가입 알림톡 발송하기
            </Button>
          </>
        )}
        <br />
        <Button onClick={() => setUpdateState(true)}>수정하기</Button>
        <div className="w-full h-24" />
      </Section>
    </div>
  );
};
