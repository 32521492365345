import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  Button,
  gql,
  LoadingScreen,
  Section,
  TextField,
  TopNavbar,
  useQuery,
} from '../../../reusable';
import { ErrorBlank } from '../../../components';
import { TeacherGroup } from '../../../types';
import { TeacherInfoUpdatePage } from './TeacherInfoUpdatePage';

const MeQuery = gql`
  query Me {
    me {
      id
      name
      email
      role
      phone
      profile
      school {
        id
        name
      }
      teacherGroups {
        id
        subject
        group {
          id
          name
          type
          year
        }
      }
    }
  }
`;

export const TeacherInfoPage = () => {
  const history = useHistory();

  const { loading, error, data, refetch } = useQuery(MeQuery, {
    onError: ({ message }) => {
      if (message === 'Unauthorized') {
        history.push('/login');
      }
    },
  });
  const me = data?.me;

  const [isUpdateState, setUpdateState] = useState(false);

  if (loading) return <LoadingScreen />;

  if (error) {
    return <ErrorBlank />;
  }

  if (isUpdateState) {
    return (
      <TeacherInfoUpdatePage
        me={me}
        refetch={refetch}
        gotoRead={() => setUpdateState(false)}
      />
    );
  }

  return (
    <>
      {/* Mobile V */}
      <div className="block md:hidden">
        <TopNavbar title="내 정보" left={<BackButton />} />
      </div>
      <div className="md:col-span-6 md:max-w-lg h-screen overflow-auto px-6 py-6">
        <Section>
          <h1 className="text-2xl font-semibold">내 정보</h1>
          <div />
          <div>
            <div className="text-lg font-bold text-gray-800">이름</div>
            <div className="text-grey-2">{me?.name}</div>
          </div>
          <div>
            <div className="text-lg font-bold text-gray-800">이메일</div>
            <div className="text-grey-2">{me?.email}</div>
          </div>
          <div>
            <div className="text-lg font-bold text-gray-800">전화번호</div>
            <div className="text-grey-2">{me?.phone}</div>
          </div>
          <div>
            <div className="text-lg font-bold text-gray-800">학교</div>
            <div className="text-grey-2">{me?.school?.name}</div>
          </div>
          <div className="text-lg font-bold text-gray-800">과목/담당 학년</div>

          {data?.me?.teacherGroups?.map((tg: TeacherGroup) => (
            <div className="w-full flex justify-between space-x-2 items-center">
              <TextField readOnly value={tg.subject} />
              <TextField readOnly value={tg.group?.name} />
            </div>
          ))}
          {/* <div>
          <div className="text-lg font-bold text-gray-800">전화번호</div>
          <div className="text-grey-2"></div>
        </div> */}
          <br />
          <Button onClick={() => setUpdateState(true)}>수정하기</Button>
        </Section>
      </div>
    </>
  );
};
