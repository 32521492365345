import React, { useEffect, useState } from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  withRouter,
} from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import { ErrorBlank, NewsletterSubmitterItem, Tab } from '../../../components';
import { Group, StudentGroup, StudentNewsletter } from '../../../types';
import NewsletterSubmitDetailPage from './NewsletterSubmitDetailPage';
import { fetcher } from '../../../plugins';

interface MatchParams {
  id: string;
}

const NewsletterSubmitPage: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const history = useHistory();
  let { id } = match.params;

  const selectedFilter = Number(localStorage.getItem('selectedFilter'));

  const [filter, setFilter] = useState(selectedFilter || 0);
  const [openedGroup, setOpenedGroup] = useState<string[]>([]);

  const [studentNewsletters, setStudentNewsletters] = useState<
    StudentNewsletter[]
  >([]);

  const { error, data, refetch } = uQ(`/newsletter/${id}`, fetcher);

  useEffect(() => {
    if (data?.newsletter?.studentNewsletters) {
      if (filter === 1) {
        setStudentNewsletters(
          data.newsletter.studentNewsletters
            ?.slice()
            ?.filter((sn: StudentNewsletter) => sn.isSubmitted === true),
        );
      } else if (filter === 2) {
        setStudentNewsletters(
          data.newsletter.studentNewsletters
            ?.slice()
            ?.filter((sn: StudentNewsletter) => sn.isSubmitted === false),
        );
      } else {
        setStudentNewsletters(data.newsletter.studentNewsletters);
      }
    }
  }, [data]);

  if (error) {
    return <ErrorBlank />;
  }

  const groups = data?.groups;

  const studentNewslettersData: any = {};

  let studentsCount = 0;
  let submitStudentsCount = 0;
  let unSubmitStudentsCount = 0;

  groups?.map((g: Group) => {
    let _submitStudentsCount = 0;
    let _unSubmitStudentsCount = 0;
    if (g.name) {
      studentNewslettersData[g.name] = g.studentGroups?.map(
        (sg: StudentGroup) => {
          studentsCount++;
          const studentNewsletter: StudentNewsletter =
            studentNewsletters.filter(
              (sn: StudentNewsletter) => sn.student?.id === sg.user?.id,
            )[0];
          if (studentNewsletter?.isSubmitted) {
            submitStudentsCount++;
            _submitStudentsCount++;
          } else {
            unSubmitStudentsCount++;
            _unSubmitStudentsCount++;
          }
          return {
            ...sg,
            studentNewsletter,
          };
        },
      );
      studentNewslettersData[g.name]._ssc = _submitStudentsCount;
      studentNewslettersData[g.name]._ussc = _unSubmitStudentsCount;
    }
  });

  return (
    <div className="bg-white ml-0.5 grid grid-cols-7 h-screen">
      {/* {loading && <Blank reversed />} */}
      <div className="col-span-4">
        <div className="w-full flex items-center justify-between p-4">
          <div className="space-x-2 flex items-center">
            <h3 className="text-lg">제출자 리스트</h3>
            <div className="text-gray-500 text-sm">
              {filter === 2
                ? `미제출 ${unSubmitStudentsCount}명`
                : `제출 ${submitStudentsCount}명`}{' '}
              / 총 {studentsCount}명
            </div>
          </div>
          <div
            className="text-brand-1 cursor-pointer"
            onClick={() => refetch()}
          >
            새로고침
          </div>
        </div>
        <div className="h-0.5 bg-gray-100 "></div>
        <Tab
          filter={filter}
          setFilter={(n: number) => {
            localStorage.setItem('selectedFilter', n.toString());
            setFilter(n);
          }}
        />
        <div className="p-4 overflow-y-scroll h-screen-8">
          {Object.keys(studentNewslettersData)
            ?.slice()
            ?.sort((a: any, b: any) => {
              const aData = a.match(
                `([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`,
              );
              const bData = b.match(
                `([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`,
              );

              if (aData[1] && bData[1]) {
                if (aData[1] === bData[1]) {
                  return Number(aData[2]) - Number(bData[2]);
                } else {
                  return Number(aData[1]) - Number(bData[1]);
                }
              } else {
                return 0;
              }
            })
            ?.map((sgk: string) => (
              <div className="my-5">
                <div
                  className="w-full border-t border-gray-600 cursor-pointer flex justify-between items-center"
                  onClick={() =>
                    openedGroup.includes(sgk)
                      ? setOpenedGroup(openedGroup.filter((el) => el !== sgk))
                      : setOpenedGroup(openedGroup.concat(sgk))
                  }
                >
                  <div className="font-bold text-lg">{sgk}</div>
                  <div className="text-gray-500 text-sm">
                    {filter === 2
                      ? `미제출 ${studentNewslettersData[sgk]?._ussc || 0}명`
                      : `제출 ${studentNewslettersData[sgk]?._ssc || 0}명`}{' '}
                    / 총 {studentNewslettersData[sgk]?.length || 0}명
                  </div>
                </div>
                {openedGroup.includes(sgk) &&
                  studentNewslettersData[sgk]?.map((studentGroup: any) => (
                    <NewsletterSubmitterItem
                      filter={filter}
                      studentGroup={studentGroup}
                      studentNewsletter={studentGroup.studentNewsletter}
                      onClick={() =>
                        history.push(
                          `/teacher/newsletter/submit/${id}/${studentGroup.studentNewsletter?.id}`,
                        )
                      }
                      id={id}
                    />
                  ))}
              </div>
            ))}
        </div>
      </div>
      <div className="col-span-3">
        <Switch>
          <Route
            path={`/teacher/newsletter/submit/:id/:snid`}
            component={() => (
              <NewsletterSubmitDetailPage newsletterData={data?.newsletter} />
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(NewsletterSubmitPage);
