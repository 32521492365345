import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { StudentGroup, StudentNewsletter } from '../../types';

interface NewsletterSubmitterItemProps {
  filter: number;
  studentGroup: StudentGroup;
  studentNewsletter: StudentNewsletter;
  onClick: () => void;
  id: string;
}

export const NewsletterSubmitterItem: FC<NewsletterSubmitterItemProps> = ({
  filter,
  studentGroup,
  studentNewsletter,
  onClick,
  id,
}) => {
  const { pathname } = useLocation();
  if (filter === 1 && (!studentNewsletter || !studentNewsletter?.isSubmitted)) {
    return <></>;
  }
  if (filter === 2 && studentNewsletter && studentNewsletter.isSubmitted) {
    return <></>;
  }
  return (
    <div
      className="min-w-1/2-2 p-1 text-center inline-block cursor-pointer"
      onClick={onClick}
    >
      <div
        className={
          studentNewsletter?.id
            ? pathname.includes(
                `/teacher/activity/submit/${id}/${studentNewsletter?.id}`,
              )
              ? 'border border-brand-1 bg-light_orange rounded-md p-2 flex items-center justify-between space-x-2'
              : 'border rounded-md p-2 flex items-center justify-between space-x-2'
            : 'border rounded-md p-2 flex items-center justify-between space-x-2'
        }
      >
        {studentNewsletter?.isSubmitted ? (
          <>
            <div className="flex items-center">
              <span className="bg-brand-1 px-2 py-1 text-white rounded-md text-sm">
                제출
              </span>
              <div className="ml-2 flex space-x-2">
                <span className="font-semibold">
                  {studentGroup?.studentNumber}
                </span>
                <span>{studentGroup?.user?.name}</span>
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center cursor-pointer">
            <span
              className={
                pathname.startsWith(
                  `/teacher/activity/submit/${id}/${studentNewsletter?.id}`,
                )
                  ? 'bg-gray-100 px-2 py-1 border border-gray-200 text-gray-500 rounded-md text-sm'
                  : 'bg-gray-100 px-2 py-1 text-gray-500 rounded-md text-sm'
              }
            >
              미제출
            </span>
            <div className="ml-2 flex space-x-2">
              <span className="font-semibold">
                {studentGroup?.studentNumber}
              </span>
              <span>{studentGroup?.user?.name}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
