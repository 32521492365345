import React, { useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useQuery as uQ } from 'react-query';
import { withRouter } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { School } from '../../../types';
import { makeStartEndToString } from '../../../utils';
import { FieldtripPaper } from '../../../components';
import { fetcher } from '../../../plugins';
import { Blank, Button } from '../../../reusable';

interface MatchParams {
  id: string;
}
interface FieldtripNoticeDetailPageProps
  extends RouteComponentProps<MatchParams> {
  school: School;
  approvalLine?: string[];
}

const FieldtripNoticeDetailPage: React.FC<FieldtripNoticeDetailPageProps> = ({
  school,
  match,
  approvalLine,
}) => {
  const history = useHistory();
  const { id } = match.params;
  const ref = useRef(null);
  const [clicked, setClicked] = useState(false);

  const { isLoading, data: fieldtrip } = uQ(`/fieldtrips/${id}`, fetcher);

  if (fieldtrip?.fieldtripStatus !== 'PROCESSED') {
    return (
      <div className="relative bg-white border rounded-lg py-5 h-screen-7 flex items-center justify-center text-center">
        <div className="absolute top-5 left-0">
          <div className="w-full flex items-center justify-start space-x-2 px-5">
            <div
              className="text-brand-1 underline cursor-pointer"
              onClick={() => history.push(`/teacher/fieldtrip/${fieldtrip.id}`)}
            >
              신청서
            </div>
            <div className="text-brand-1 underline cursor-pointer">통보서</div>
            <div
              className="text-brand-1 underline cursor-pointer"
              onClick={() =>
                history.push(`/teacher/fieldtrip/result/${fieldtrip.id}`)
              }
            >
              결과보고서
            </div>
          </div>
        </div>
        <div>
          신청서가 아직 승인되지 않았습니다. <br />
          승인절차가 완료되면 통보서가 보호자 연락처로 발송됩니다.
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white border rounded-lg py-5 h-screen-7">
      {isLoading && <Blank reversed />}
      <div className="w-auto h-full overflow-scroll relative">
        <div className="w-full flex items-center justify-start space-x-2 px-5">
          <div
            className="text-brand-1 underline cursor-pointer"
            onClick={() => history.push(`/teacher/fieldtrip/${fieldtrip.id}`)}
          >
            신청서
          </div>
          <div className="text-brand-1 underline cursor-pointer">통보서</div>
          <div
            className="text-brand-1 underline cursor-pointer"
            onClick={() =>
              history.push(`/teacher/fieldtrip/result/${fieldtrip.id}`)
            }
          >
            결과보고서
          </div>
        </div>
        <div
          ref={ref}
          className=" bg-white mt-20"
          style={{ width: '760px', height: '1058px', padding: '20px' }}
        >
          <FieldtripPaper
            school={school}
            fieldtrip={fieldtrip}
            type="통보서"
            approvalLine={approvalLine}
          />
        </div>
      </div>

      <div className="flex items-center w-full overflow-x-auto space-x-2 pt-8">
        <Button
          disabled={clicked}
          tw={{
            width: 'w-full',
            backgroundColor: clicked ? 'bg-gray-400' : 'bg-blue-500',
            minWidth: 'min-w-max',
          }}
          onClick={() => {
            if (ref?.current) {
              setClicked(true);
              let doc: any = new jsPDF('p', 'mm', 'a4');
              //@ts-ignore
              html2canvas(ref.current, {
                logging: true,
                useCORS: true,
                allowTaint: false,
              }).then(async function (canvas) {
                let imgData = canvas.toDataURL('image/png');
                await doc.addImage(imgData, 'PNG', 0, 0, 210, 297);

                await doc.save(
                  `체험학습 통보서(${fieldtrip?.student?.name}, ${
                    fieldtrip?.startAt &&
                    fieldtrip?.endAt &&
                    makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)
                  }).pdf`,
                );
                setClicked(false);
              });
            }
          }}
        >
          다운로드
        </Button>
      </div>
    </div>
  );
};

export default withRouter(FieldtripNoticeDetailPage);
