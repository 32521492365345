import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import {
  Blank,
  List,
  Tab,
  Tabs,
  TopNavbar,
  useQuery,
  useQueryString,
} from '../../../reusable';
import {
  NoticeItem,
  ErrorBlank,
  NewsletterItem,
  BoardItem,
} from '../../../components';
import { GET_KLASSGROUP_NAME } from '../../../query';
import {
  Board,
  GetPaginatedBoards,
  Newsletter,
  Notice,
  TabType,
} from '../../../types';
import { fetcher } from '../../../plugins';

export const NoticePage = () => {
  const search = useLocation().search;
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [tabType, setTabType] = useState<TabType>(TabType.NOTICE);
  const [blankOpen, setBlankOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  const [page, setPage] = useState<number>(Number(params.get('page') ?? '1'));
  const limit = Number(params.get('limit') ?? '500');

  let {
    loading,
    error,
    data: groupData,
  }: any = useQuery(GET_KLASSGROUP_NAME, {});

  const userGroup = groupData?.me?.klassGroup?.group;

  const userKlass = userGroup?.name?.split('학년')[0];
  const groupId = userGroup?.id;

  const queryString = useQueryString({
    page: page,
    limit,
    defaultLimit: limit,
    groupId: groupId,
    category: selectedCategory,
  });

  const { data } = uQ(`/newsletters/${userKlass}`, fetcher);

  const { data: boards } = uQ<GetPaginatedBoards>(
    `/boards${queryString}`,
    fetcher,
  );

  const newsletters: Newsletter[] = data?.newsletters
    ?.slice()
    ?.sort(
      (a: Newsletter, b: Newsletter) =>
        new Date(b?.createdAt || '').getTime() -
        new Date(a?.createdAt || '').getTime(),
    );

  useEffect(() => {
    const getLocal = localStorage.getItem('tabType');
    if (!getLocal) {
      return;
    } else if (getLocal === 'BOARD') {
      setTabType(TabType.BOARD);
    } else if (getLocal === 'NOTICE') {
      setTabType(TabType.NOTICE);
    } else if (getLocal === 'NEWSLETTER') {
      setTabType(TabType.NEWSLETTER);
    }
  }, [tabType]);

  return (
    <>
      {loading && <Blank />}
      {error && <ErrorBlank />}
      {blankOpen && <Blank />}

      <TopNavbar
        title="공지"
        left={<div className="w-10 h-15" />}
        right={
          <div
            className="text-brand-1"
            onClick={() => {
              setBlankOpen(true);
              window?.location?.reload();
            }}
          >
            새로고침
          </div>
        }
      />

      <Tabs>
        <Tab
          active={tabType === TabType.NOTICE}
          text="공지사항"
          full
          onClick={() => {
            setTabType(TabType.NOTICE);
            localStorage.setItem('tabType', TabType.NOTICE);
          }}
        />
        <Tab
          active={tabType === TabType.BOARD}
          text="학급 게시판"
          full
          onClick={() => {
            setTabType(TabType.BOARD);
            localStorage.setItem('tabType', TabType.BOARD);
          }}
        />
        <Tab
          active={tabType === TabType.NEWSLETTER}
          text="가정통신문"
          full
          onClick={() => {
            setTabType(TabType.NEWSLETTER);
            localStorage.setItem('tabType', TabType.NEWSLETTER);
          }}
        />
      </Tabs>

      {tabType === TabType.NOTICE && (
        <div className="w-full flex-col space-y-2">
          <div className="whitespace-pre-line">
            <List>
              {data?.notices?.map((notice: Notice) => (
                <NoticeItem notice={notice} />
              ))}
            </List>
          </div>
        </div>
      )}

      {tabType === TabType.NEWSLETTER && (
        <div className="w-full flex-col space-y-2">
          <div className="whitespace-pre-line">
            <List>
              {newsletters?.map((newsletter: Newsletter) => (
                <NewsletterItem newsletter={newsletter} />
              ))}
            </List>
          </div>
        </div>
      )}

      {tabType === TabType.BOARD && (
        <div className="w-full flex-col space-y-2">
          <div className="whitespace-pre-line">
            <List>
              {boards?.items?.map((board: Board) => (
                <BoardItem board={board} />
              ))}
            </List>
          </div>
        </div>
      )}
    </>
  );
};
