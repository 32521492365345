import React, { useState } from 'react';
import { ReactComponent as Close } from '../../../assets/svg/Close_Icon.svg';
import { useHistory } from 'react-router';
import {
  Blank,
  Button,
  Checkbox,
  Label,
  Section,
  Select,
  TextArea,
  TextField,
  useMutation,
  useQuery,
} from '../../../reusable';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import { CREATE_ACTIVITY, GET_GROUPS, UPDATE_ACTIVITY } from '../../../query';
import { Group, TeacherGroup } from '../../../types';
import {
  AllSelectCheckbox,
  ErrorBlank,
  FileItem,
  SurveyCreatorComponent,
} from '../../../components';

import undoArrow from '../../../assets/images/undo-arrow.png';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';

interface ActivityAddProps {
  refetch: () => void;
  activityData?: any;
}

const isValidDate = (date: Date | string) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  return date.getTime() === date.getTime();
};

const ActivityAddPage: React.FC<ActivityAddProps> = ({
  refetch,
  activityData,
}) => {
  const history = useHistory();

  const { loading, error, data } = useQuery(GET_GROUPS, {
    fetchPolicy: 'cache-and-network',
  });

  // const { error, data } = uQ('/teacher-groups?isSchool=true', fetcher);

  const [isError, setError] = useState('');

  let subjects: string[] = [];

  data?.me?.teacherGroups.map((tg: TeacherGroup) =>
    tg.subject && !subjects.includes(tg.subject)
      ? subjects.push(tg.subject)
      : tg,
  );

  const [title, setTitle] = useState(activityData ? activityData.title : '');
  const [content, setContent] = useState(
    activityData ? activityData.content : '',
  );
  const [subject, setSubject] = useState(
    activityData ? activityData.subject : subjects ? subjects[0] : '',
  );
  const [type, setType] = useState(activityData ? activityData.type : 'POST');
  const [endDate, setEndDate] = useState<any>(
    activityData
      ? activityData.endDate
      : new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString(),
  );
  const [endDateOff, setEndDateOff] = useState<boolean>(
    activityData ? activityData.endDate === null : true,
  );
  const [isPhrase, setIsPhrase] = useState(
    activityData ? activityData.isRecord : false,
  );
  const [explainText, setExplainText] = useState(
    activityData ? activityData.explainText : '',
  );
  const [phrase, setPhrase] = useState(
    activityData ? activityData.commonText : '',
  );
  const [images, setImages] = useState<(File | null | undefined)[]>([null]);
  const [files, setFiles] = useState<(File | null | undefined)[]>([]);
  const [willRemoveImages, setWillRemoveImages] = useState<any[]>([]);
  const [willRemoveFiles, setWillRemoveFiles] = useState<any[]>([]);
  // const [showPreview, setShowPreview] = useState<boolean>(false);
  const [isImage, setIsImage] = useState(
    activityData ? activityData.isImage : false,
  );
  const [isFile, setIsFile] = useState(
    activityData ? activityData.isFile : false,
  );
  const [isContent, setIsContent] = useState(
    activityData ? activityData.isContent : true,
  );

  let aFiles: any[] = [];
  try {
    aFiles = activityData?.files
      ? activityData?.files.map((el: string) => JSON.parse(el))
      : [];
  } catch (err) {
    aFiles = activityData?.files?.map((el: string) => ({
      url: process.env.REACT_APP_API_URL + '/images?url=' + el,
      data: el,
      name: el,
    }));
  }

  let aImages: any[] = [];
  try {
    aImages = activityData?.images
      ? activityData?.images.map((el: string) => JSON.parse(el))
      : [];
  } catch (err) {
    aImages = activityData?.images?.map((el: string) => ({
      url: process.env.REACT_APP_API_URL + '/images?url=' + el,
      data: el,
      name: el,
    }));
  }

  const groups = data?.me?.teacherGroups
    .filter((tg: TeacherGroup) => tg?.subject === subject)
    .map((tg: TeacherGroup) => tg?.group)
    .slice()
    .filter(
      (g: Group, i: number, groups: Group[]) =>
        !!g &&
        groups.filter((gp: Group) => !!gp && gp?.name === g?.name)[0].id ===
          g.id,
    )
    .filter(
      (g: Group, i: number, groups: Group[]) =>
        !!g && groups.map((el: Group) => el.id).indexOf(g.id) === i,
    )
    .sort((a: any, b: any) => a.id - b.id);

  const [selectedGroups, setSelectedGroups] = useState<Group[]>(
    activityData ? activityData.groups : [],
  );
  const [isLoading, setLoading] = useState(false);

  const [createActivity] = useMutation(CREATE_ACTIVITY, {
    variables: {
      data: JSON.stringify({
        title,
        content,
        subject,
        type,
        endDate: endDateOff
          ? null
          : endDate
          ? isValidDate(endDate)
            ? new Date(endDate).toISOString()
            : null
          : activityData?.endDate ||
            new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString(),
        groupIds: selectedGroups.map((el) => el.id),
        isRecord: isPhrase,
        commonText: phrase,
        isImage,
        isFile,
        isContent,
        explainText,
      }),
      images: images.filter((el) => {
        if (typeof el === 'string') {
          return false;
        } else if (!el) {
          return false;
        }
        return true;
      }),
      files: files.length ? files : undefined,
    },
    onError: (error) => {
      setError(JSON.stringify(error));
    },
    onCompleted: async (response) => {
      await refetch();
      await setLoading(false);
      await setSelectedGroups([]);
      await setTitle('');
      await setContent('');
      await setSubject('');
      if (response?.createActivity?.id) {
        history.push(`/teacher/activity/${response.createActivity.id}`);
      } else {
        alert(
          '과제가 추가되었습니다. 확인하려면 해당 반을 선택 후 확인해주세요!',
        );
      }
    },
  });
  const [updateActivity] = useMutation(UPDATE_ACTIVITY, {
    variables: {
      id: activityData?.id,
      data: JSON.stringify({
        title,
        content,
        subject,
        type,
        endDate: endDateOff
          ? null
          : endDate
          ? isValidDate(endDate)
            ? new Date(endDate).toISOString()
            : null
          : activityData?.endDate ||
            new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString(),
        groupIds: selectedGroups.map((el) => el.id),
        deleteImages: willRemoveImages,
        deleteFiles: willRemoveFiles,
        isRecord: isPhrase,
        commonText: phrase,
        isImage,
        isFile,
        isContent,
        explainText,
      }),
      images: images.filter((el) => {
        if (typeof el === 'string') {
          return false;
        } else if (!el) {
          return false;
        }
        return true;
      }),
      files: files.length ? files : undefined,
    },
    onCompleted: async (response) => {
      await refetch();
      await setLoading(false);
      await setSelectedGroups([]);
      await setTitle('');
      await setContent('');
      await setSubject('');
      if (response?.updateActivity?.id) {
        history.push(`/teacher/activity/${response.updateActivity.id}`);
      } else {
        alert(
          '과제가 추가되었습니다. 확인하려면 해당 반을 선택 후 확인해주세요!',
        );
      }
    },
  });

  let firstGroup: Group[] = [];
  let secondGroup: Group[] = [];
  let thirdGroup: Group[] = [];
  let restGroup: Group[] = [];

  groups
    ?.filter((el: any) => !!el)
    ?.map((el: Group) => {
      if (el.name?.includes('1학년')) {
        firstGroup.push(el);
      } else if (el.name?.includes('2학년')) {
        secondGroup.push(el);
      } else if (el.name?.includes('3학년')) {
        thirdGroup.push(el);
      } else {
        restGroup.push(el);
      }
      return el;
    });

  if (error) {
    return <ErrorBlank />;
  }

  const buttonDisabled =
    !title || !content || !subject || !type || !selectedGroups.length;

  return (
    <div className="p-4 h-screen overflow-auto">
      {isLoading && <Blank reversed />}
      {isError && <ErrorBlank />}
      <div>
        <h3 className="text-xl font-semibold">활동기록부 추가하기</h3>
        <div className="bg-white p-4 border rounded-md mt-3">
          <Select
            label="과목"
            placeholder="과목을 선택해주세요"
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
              setSelectedGroups([]);
            }}
          >
            {subjects?.map((subject: string) => (
              <option value={subject} key={subject}>
                {subject}
              </option>
            ))}
          </Select>
        </div>
        <div className="bg-white p-4 border rounded-md mt-3">
          <span>전달 대상 선택</span>
          <div className="flex w-full justify-between mt-1">
            <div>
              <div className="font-bold">
                {!!firstGroup.length && (
                  <AllSelectCheckbox
                    groups={firstGroup}
                    selectedGroups={selectedGroups}
                    setSelectedGroups={(groups: Group[]) =>
                      setSelectedGroups(groups)
                    }
                    label="1학년 해당 반 전체"
                  />
                )}
              </div>
              {firstGroup.map((el) => (
                <Checkbox
                  label={el.name}
                  checked={selectedGroups.map((el) => el.id).includes(el.id)}
                  onChange={() =>
                    setSelectedGroups(
                      selectedGroups.map((el) => el.id).includes(el.id)
                        ? selectedGroups.filter((g) => g.id !== el.id)
                        : selectedGroups.concat(el),
                    )
                  }
                />
              ))}
            </div>
            <div>
              <div className="font-bold">
                {!!secondGroup.length && (
                  <AllSelectCheckbox
                    groups={secondGroup}
                    selectedGroups={selectedGroups}
                    setSelectedGroups={(groups: Group[]) =>
                      setSelectedGroups(groups)
                    }
                    label="2학년 해당 반 전체"
                  />
                )}
              </div>
              {secondGroup.map((el) => (
                <Checkbox
                  label={el.name}
                  checked={selectedGroups.map((el) => el.id).includes(el.id)}
                  onChange={() =>
                    setSelectedGroups(
                      selectedGroups.map((el) => el.id).includes(el.id)
                        ? selectedGroups.filter((g) => g.id !== el.id)
                        : selectedGroups.concat(el),
                    )
                  }
                />
              ))}
            </div>
            <div>
              <div className="font-bold">
                {!!thirdGroup.length && (
                  <AllSelectCheckbox
                    groups={thirdGroup}
                    selectedGroups={selectedGroups}
                    setSelectedGroups={(groups: Group[]) =>
                      setSelectedGroups(groups)
                    }
                    label="3학년 해당 반 전체"
                  />
                )}
              </div>
              {thirdGroup.map((el) => (
                <Checkbox
                  label={el.name}
                  checked={selectedGroups.map((el) => el.id).includes(el.id)}
                  onChange={() =>
                    setSelectedGroups(
                      selectedGroups.map((el) => el.id).includes(el.id)
                        ? selectedGroups.filter((g) => g.id !== el.id)
                        : selectedGroups.concat(el),
                    )
                  }
                />
              ))}
            </div>
          </div>
          <div>
            {restGroup.map((el) => (
              <Checkbox
                label={el.name}
                value={el.id}
                checked={selectedGroups.map((el) => el.id).includes(el.id)}
                onChange={() =>
                  setSelectedGroups(
                    selectedGroups.map((el) => el.id).includes(el.id)
                      ? selectedGroups.filter((g) => g.id !== el.id)
                      : selectedGroups.concat(el),
                  )
                }
              />
            ))}
          </div>
          <div className="flex flex-wrap mt-2">
            {selectedGroups
              ?.slice()
              .sort((a: any, b: any) => {
                const aData = a.name.match(
                  `([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`,
                );
                const bData = b.name.match(
                  `([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`,
                );
                if (!aData || !bData) {
                  return 0;
                }

                if (aData[1] && bData[1]) {
                  if (aData[1] === bData[1]) {
                    return Number(aData[2]) - Number(bData[2]);
                  } else {
                    return Number(aData[1]) - Number(bData[1]);
                  }
                } else {
                  return 0;
                }
              })
              .map((group: Group) => (
                <div
                  className="flex items-center w-max rounded-full border border-brand-1 text-brand-1 px-2.5 py-1.5 m-1s mr-2 mt-2 cursor-pointer"
                  onClick={() =>
                    setSelectedGroups(
                      selectedGroups.filter((el) => el.id !== group.id),
                    )
                  }
                >
                  {group.name}
                  <Close className="ml-2" />
                </div>
              ))}
          </div>
        </div>

        <div>
          <Section tw={{ paddingX: 'px-0' }}>
            {/* <div className="mt-1">
            <Label>활동기록부 유형</Label>
            <SelectMenus item={PostTypeProps} AllText="게시글 타입" />
          </div> */}
            <TextField
              label="제목"
              id="title"
              placeholder="제목을 입력해주세요"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Select
              label="타입"
              placeholder="타입을 선택해주세요"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="POST">과제</option>
              <option value="NOTICE">공지</option>
              <option value="SURVEY">설문</option>
            </Select>

            {type !== 'NOTICE' && (
              <Checkbox
                checked={isPhrase}
                label="생활 기록부 상태 추가"
                onChange={() => setIsPhrase(!isPhrase)}
              />
            )}

            <div>
              <Label htmlFor="end_date">마감기한</Label>
              <div className="flex space-x-2">
                <Checkbox
                  checked={endDateOff}
                  label={endDateOff ? 'off' : 'on'}
                  onChange={() => setEndDateOff(!endDateOff)}
                />
                <div className="outline-none flex items-center px-4 w-full h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 appearance-none rounded-none focus:appearance-none focus:no-underline focus:outline-none">
                  <input
                    id="end_date"
                    type="datetime-local"
                    className="ring-0 focus:ring-0 outline-none focus:outline-none"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    pattern="\d{4}-\d{2}-\d{2}"
                    disabled={endDateOff}
                  />
                </div>
                {/* <SelectMenus item={YearProps} AllText="년도" />
            <SelectMenus item={MonthProps} AllText="월" />
            <SelectMenus item={DayProps} AllText="일" />
            <SelectMenus item={TimeProps} AllText="00시" />
            <SelectMenus item={MinuteProps} AllText="00분" /> */}
              </div>
            </div>
            {isPhrase && type !== 'NOTICE' && (
              <TextField
                label="공통문구"
                placeholder="내용을 입력해주세요."
                style={{ borderWidth: '1px' }}
                value={phrase}
                onChange={(e) => setPhrase(e.target.value)}
              />
            )}
            {isPhrase && type !== 'NOTICE' && (
              <TextField
                label="활동요약 설명"
                placeholder="활동요약에 대한 설명/예시 등을 입력합니다."
                style={{ borderWidth: '1px' }}
                value={explainText}
                onChange={(e) => setExplainText(e.target.value)}
              />
            )}

            {type === 'SURVEY' ? (
              <SurveyCreatorComponent
                content={content}
                setContent={(c: string) => setContent(c)}
              />
            ) : (
              <>
                <div>
                  <Label>이미지</Label>
                  <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
                    {aImages?.map((el: any) => (
                      <div>
                        <div
                          className={`relative pb-3/5 rounded border-2 border-dashed border-grey-5 ${
                            willRemoveImages.includes(el.data)
                              ? 'opacity-50'
                              : ''
                          }`}
                        >
                          <span className="absolute -top-3 -right-3 block w-6 h-6 rounded-full ring-2 ring-white bg-red-700 z-40">
                            <div
                              className="w-full h-full flex items-center justify-center text-white cursor-pointer"
                              onClick={() =>
                                willRemoveImages.includes(el.data)
                                  ? setWillRemoveImages(
                                      willRemoveImages.filter(
                                        (imgUrl) => imgUrl !== el.data,
                                      ),
                                    )
                                  : setWillRemoveImages(
                                      willRemoveImages.concat(el.data),
                                    )
                              }
                              style={{ transform: 'translate(0.1px, 0.1px)' }}
                            >
                              {willRemoveImages.includes(el.data) ? (
                                <img
                                  src={undoArrow}
                                  alt=""
                                  className="w-4 h-4"
                                />
                              ) : (
                                'X'
                              )}
                            </div>
                          </span>
                          <ExifOrientationImg
                            className="absolute w-full h-full rounded object-cover"
                            src={el.url}
                            alt=""
                          />
                          {willRemoveImages.includes(el.data) && (
                            <div className="absolute w-full h-full z-20">
                              <div className="flex w-full h-full items-center justify-center">
                                <div className="text-4xl text-gray-800 opacity-100">
                                  삭제
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                    {images?.map((el: File | null | undefined, i: number) => (
                      <div className="">
                        <label htmlFor={`imageupload${i}`}>
                          <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
                            {el ? (
                              <img
                                className="absolute w-full h-full rounded object-cover"
                                src={URL.createObjectURL(el)}
                                alt=""
                              />
                            ) : (
                              <div className="absolute w-full h-full rounded object-cover bg-white">
                                <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                                  <FileUploadImg />
                                  <div className="text-brand-1">
                                    이미지를 업로드해주세요.
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </label>
                        <input
                          type="file"
                          id={`imageupload${i}`}
                          className="hidden"
                          accept=".png, .jpeg, .jpg"
                          onChange={(e) => {
                            let size = 0;

                            images.map(
                              (el: File | string | null | undefined) => {
                                if (el instanceof File) {
                                  size += el.size || 0;
                                }
                              },
                            );

                            files.map((el: File | null | undefined) => {
                              size += el?.size || 0;
                            });

                            if (size >= 20 * 1024 * 1024) {
                              alert(
                                '한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요.',
                              );
                            } else {
                              const newImages = images.slice();
                              if (e.target.files && e.target.files?.item(0)) {
                                newImages[i] = e.target.files?.item(0);
                                newImages.push(null);
                              }
                              e.target.validity.valid && setImages(newImages);
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <Label>파일</Label>
                  <div className="bg-white rounded border-2 border-dashed border-grey-5">
                    {aFiles?.map((file: any) => (
                      <FileItem
                        file={file}
                        canClose
                        willRemoveFiles={willRemoveFiles}
                        setWillRemoveFiles={() =>
                          willRemoveFiles.includes(file.data)
                            ? setWillRemoveFiles(
                                willRemoveFiles.filter(
                                  (fileName) => fileName !== file.data,
                                ),
                              )
                            : setWillRemoveFiles(
                                willRemoveFiles.concat(file.data),
                              )
                        }
                      />
                    ))}
                    {files.map((file: any) => (
                      <FileItem file={file} />
                    ))}
                    <label htmlFor="fileupload">
                      <div className="w-full flex space-x-1 justify-center items-center pt-0.5 pb-2.5 cursor-pointer">
                        <span className="text-2xl text-grey-3 mb-1">+</span>
                        <div className="text-brand-1">
                          파일을 업로드해주세요. (다중 업로드 가능)
                        </div>
                      </div>
                    </label>
                  </div>
                  <input
                    type="file"
                    id="fileupload"
                    className="hidden"
                    multiple
                    onChange={(e) => {
                      const newFiles = e.target.files
                        ? files.concat(Array.from(e.target.files))
                        : files;

                      let size = 0;

                      images.map((el: File | string | null | undefined) => {
                        if (el instanceof File) {
                          size += el?.size || 0;
                        }
                      });

                      newFiles.map((el: File | null | undefined) => {
                        size += el?.size || 0;
                      });

                      if (size >= 20 * 1024 * 1024) {
                        alert(
                          '한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요.',
                        );
                      } else {
                        e.target.validity.valid && setFiles(newFiles);
                      }
                    }}
                  />
                </div>
                <TextArea
                  label="내용"
                  placeholder="내용을 입력해주세요."
                  style={{ borderWidth: '1px' }}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </>
            )}
            {type === 'POST' && (
              <div className="w-full">
                <div className="pb-4 font-bold text-lg">과제제출방식</div>
                <div className="flex w-full justify-between mt-1">
                  <Checkbox
                    label="이미지 업로드"
                    checked={isImage}
                    onChange={() => setIsImage(!isImage)}
                  />
                  <Checkbox
                    label="파일 업로드"
                    checked={isFile}
                    onChange={() => setIsFile(!isFile)}
                  />
                  <Checkbox
                    label="내용 입력"
                    checked={isContent}
                    onChange={() => setIsContent(!isContent)}
                  />
                </div>
              </div>
            )}
          </Section>
        </div>
        <div className="text-center mt-6 flex flex-row space-x-10 justify-center">
          {/* <Button
            text="미리보기"
            tw={{ height: 'h-11' }}
            style={{ width: '45%' }}
            onClick={() => setShowPreview(true)}
          /> */}
          <Button
            text="등록하기"
            tw={{
              backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
              height: 'h-11',
            }}
            style={{ width: '45%' }}
            disabled={buttonDisabled}
            onClick={() => {
              setLoading(true);
              activityData ? updateActivity() : createActivity();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityAddPage;
