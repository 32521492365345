import React, { FC, useState } from 'react';
import { UPDATE_CUSTOM_PROFILE, UPDATE_PROFILE } from '../query';
import {
  Blank,
  Button,
  CloseButton,
  IconButton,
  Section,
  useMutation,
} from '../reusable';
import { ReactComponent as Edit } from '../assets/svg/edit.svg';
import { User } from '../types';
import { makeDateToString } from '../utils';
import { Swiper, SwiperSlide } from 'swiper/react';

interface StudentIDCardProps {
  me: User;
  refetch: () => void;
}

export const StudentIDCard: FC<StudentIDCardProps> = ({ me, refetch }) => {
  const school = me?.school;
  const schoolName = me?.school?.name;
  const klassGroup = me?.klassGroup;

  const [isLoading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [profile, setProfile] = useState<File | null | undefined>();
  const [editMode, setEditMode] = useState(false);
  const [customProfile, setCustomProfile] = useState<
    File | string | null | undefined
  >(me?.customProfile);
  const [background, setBackground] = useState<
    File | string | null | undefined
  >(me?.customBackground);
  const [message, setMessage] = useState<string>(me?.customMessage || '');

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    variables: {
      profile,
    },
    onCompleted: async () => {
      await refetch();
      await setLoading(false);
      await setModalOpen(false);
    },
  });

  const [updateCustomProfile] = useMutation(UPDATE_CUSTOM_PROFILE, {
    variables: {
      profile,
      customProfile,
      customBackground: background,
      customMessage: message,
    },
    onError: async () => {
      setEditMode(false);
      setLoading(false);
    },
    onCompleted: async () => {
      await setEditMode(false);
      await refetch();
      await setLoading(false);
      await setModalOpen(false);
    },
  });

  return (
    <>
      {isLoading && <Blank />}
      <Swiper
        slidesPerView={1.05}
        spaceBetween={10}
        className="mySwiper px-6 pb-5 pt-6"
      >
        <SwiperSlide>
          <div className="card_shadow overflow-hidden relative">
            <div className="absolute left-0 right-0 z-10">
              <div className="flex items-center justify-end space-x-2 rounded-xl">
                <input
                  type="file"
                  id="bgupload"
                  className="hidden"
                  accept=".png, .jpeg, .jpg"
                  onChange={(e) =>
                    e.target.validity.valid &&
                    setBackground(e.target.files?.item(0))
                  }
                />
              </div>
            </div>
            <div className="w-full flex flex-col relative bg-transparent bg-gradient-to-t from-littleblack h-80">
              {/* 뒷배경 */}
              {background ? (
                typeof background === 'string' ? (
                  <img
                    className="absolute -z-10 w-full h-full object-cover"
                    src={background}
                    alt=""
                    loading="lazy"
                  />
                ) : (
                  <img
                    className="absolute -z-10 w-full h-full object-cover"
                    src={URL.createObjectURL(background)}
                    alt=""
                    loading="lazy"
                  />
                )
              ) : (
                <div
                  className="absolute -z-10 w-full h-full bg-center bg-no-repeat bg-cover"
                  style={{
                    backgroundColor: '#333D4B',
                    backgroundImage: `url(${me?.customBackground})`,
                  }}
                />
              )}

              {/* 프로필 이미지 */}
              <div className="grid place-content-center my-12">
                <label htmlFor="profileupload" className="z-20">
                  {customProfile ? (
                    <img
                      className="w-32 h-32 rounded-full object-cover cursor-pointer"
                      src={
                        typeof customProfile === 'string'
                          ? customProfile
                          : URL.createObjectURL(customProfile)
                      }
                      alt=""
                      loading="lazy"
                    />
                  ) : (
                    <div
                      className="w-32 h-32 rounded-full bg-white bg-center bg-no-repeat bg-cover cursor-pointer z-10"
                      style={{ backgroundImage: `url(${me?.customProfile})` }}
                    />
                  )}
                </label>
                {editMode && (
                  <input
                    id="profileupload"
                    type="file"
                    accept=".png, .jpeg, .jpg"
                    className="hidden"
                    onChange={(e) =>
                      e.target.validity.valid &&
                      setCustomProfile(e.target.files?.item(0))
                    }
                  />
                )}
              </div>

              {/* 히딘 프로필 이름 edit */}
              <div className="flex w-full justify-between items-end p-6">
                <div>
                  <p className="text-sm text-white mb-1">
                    {klassGroup &&
                      `${klassGroup.group?.name} ${klassGroup.studentNumber}번`}
                  </p>
                  <h1 className="text-md font-semibold text-white text-overflow-">
                    {me?.name}
                  </h1>
                </div>
                <div className="flex items-center space-x-2 pb-0.5">
                  <input
                    type="file"
                    id="bgupload"
                    className="hidden"
                    accept=".png, .jpeg, .jpg"
                    onChange={(e) =>
                      e.target.validity.valid &&
                      setBackground(e.target.files?.item(0))
                    }
                  />
                  {editMode ? (
                    <>
                      <label htmlFor="bgupload">
                        <div className="p-2 text-white text-sm cursor-pointer bg-littleblack rounded-xl">
                          배경 업로드
                        </div>
                      </label>

                      <div
                        className="p-2 text-white text-sm cursor-pointer bg-littleblack rounded-xl"
                        onClick={() => {
                          setLoading(true);
                          updateCustomProfile();
                        }}
                      >
                        완료
                      </div>
                    </>
                  ) : (
                    <Edit className="p-0.5" onClick={() => setEditMode(true)} />
                  )}
                </div>
              </div>
            </div>
            {/* 학교 마크 */}
            <div className="px-6 h-30 flex justify-center">
              <div className="flex space-x-2 items-center justify-center font-semibold">
                {school?.mark && (
                  <IconButton
                    className="w-8 h-8"
                    icon={<img src={school?.mark} alt="" loading="lazy" />}
                  />
                )}

                <p className="text-black">{schoolName}</p>
                {school?.stamp && (
                  <IconButton
                    className="w-8 h-8"
                    icon={<img src={school?.stamp} alt="" loading="lazy" />}
                  />
                )}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card_shadow overflow-hidden w-full">
            <div>
              <div
                style={{
                  backgroundColor: '#FAFAFA',
                }}
                className="w-full flex flex-col items-center h-80"
              >
                <div
                  className="mt-12 mb-2 w-32 h-32 rounded-md bg-white bg-center bg-no-repeat bg-cover cursor-pointer z-10"
                  style={{ backgroundImage: `url(${me?.profile})` }}
                />

                <table>
                  <tbody>
                    <tr>
                      <td className="text-xs text-grey-4 pr-3 flex justify-between py-1">
                        <p>성</p>
                        <p>명</p>
                      </td>
                      <td className="font-bold text-black tracking-widest text-sm">
                        {me?.name}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-xs text-grey-4 pr-3 flex justify-between py-1">
                        <p>학</p>
                        <p>번</p>
                      </td>
                      <td className="font-bold text-black text-sm">
                        {klassGroup &&
                          `${klassGroup.group?.name} ${klassGroup.studentNumber}번`}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="text-xs text-grey-4 pr-3">생년월일</td>
                      <td className="text-black text-sm">
                        {me?.birthDate && makeDateToString(me.birthDate, '.')}
                      </td>
                    </tr> */}
                    <tr>
                      <td className="text-xs text-grey-4 pr-3">유효기간</td>
                      <td className="text-gray-500 text-sm">
                        {klassGroup && Number(klassGroup.group?.year) + 1}.02.28
                      </td>
                    </tr>
                    <tr>
                      <td className="text-xs text-grey-4 pr-3 flex justify-between py-1">
                        <p>발</p>
                        <p>급</p>
                        <p>일</p>
                      </td>
                      <td className="text-gray-500 text-sm">
                        {klassGroup && klassGroup.group?.year}.03.02
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="px-6 h-30 flex flex-col justify-center">
                <div>
                  <div className="text-xs text-gray-700 mb-4">
                    <p>* 위 학생은 본교 학생임을 증명합니다.</p>
                    <p>* 본 학생증을 타인에게 대여 및 양도할 수 없습니다.</p>
                  </div>

                  <div className="flex space-x-2 items-center justify-center font-semibold">
                    {school?.mark && (
                      <IconButton
                        className="w-8 h-8"
                        icon={<img src={school?.mark} alt="" loading="lazy" />}
                      />
                    )}
                    <p className="text-black">{schoolName}</p>
                    {school?.stamp && (
                      <IconButton
                        className="w-8 h-8"
                        icon={<img src={school?.stamp} alt="" loading="lazy" />}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div
        className={`fixed inset-0 w-full h-screen z-60 bg-littleblack flex items-center justify-center ${
          !modalOpen && 'hidden'
        }`}
      >
        <div className={`w-80 bg-white opacity-100 rounded-lg relative`}>
          <div className="absolute top-3 right-3">
            <CloseButton onClick={() => setModalOpen(false)} />
          </div>
          <Section>
            <div className="w-full text-lg font-bold text-center text-gray-900">
              프로필 이미지 업데이트
            </div>

            <label htmlFor="imageupload">
              <div className="h-24 w-24 rounded-full cursor-pointer overflow-hidden relative mx-auto">
                {profile ? (
                  <img
                    className="absolute w-full h-full rounded object-cover"
                    src={URL.createObjectURL(profile)}
                    alt=""
                    crossOrigin="anonymous"
                    loading="lazy"
                  />
                ) : (
                  <div
                    className="h-24 w-24 rounded-full bg-center bg-no-repeat bg-cover bg-gray-400 cursor-pointer"
                    style={{ backgroundImage: `url(${me?.profile})` }}
                  />
                )}
              </div>
            </label>
            <div className="w-full text-center">이미지를 업로드해주세요.</div>
            <input
              type="file"
              id="imageupload"
              className="hidden"
              accept=".png, .jpeg, .jpg"
              onChange={(e) => {
                e.target.validity.valid && setProfile(e.target.files?.item(0));
              }}
            />
            {profile && (
              <Button
                tw={{ width: 'w-full' }}
                onClick={() => {
                  setLoading(true);
                  updateProfile();
                }}
              >
                프로필 변경하기
              </Button>
            )}
          </Section>
        </div>
      </div>
    </>
  );
};
