import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { use100vh } from 'react-div-100vh';
import {
  Alert,
  Blank,
  Button,
  Checkbox,
  Select,
  PhoneNumberField,
  Section,
  TextField,
  tokenState,
  TopNavbar,
  useMutation,
  useSetRecoilState,
  useResetRecoilState,
} from '../../../reusable';
import { PARENT_SIGNUP } from '../../../query';
import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';
import { ErrorBlank } from '../../../components';
import { fetcher, api } from '../../../plugins';
import { StudentGroup, User } from '../../../types';
import { passwordCheck } from '../../../utils';

export const ParentSignupPage: React.FC = () => {
  let vh = use100vh();
  const height = vh ? `${vh}px` : '100vh';

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const uuid = searchParams.get('uuid');
  const name = searchParams.get('name');
  const nokName = searchParams.get('nokName');
  const phone = searchParams.get('phone')?.replaceAll('-', '');
  const history = useHistory();

  const setToken = useSetRecoilState(tokenState);
  const resetToken = useResetRecoilState(tokenState);

  const { error, data: user } = useQuery(`/users/${uuid}`, fetcher, {
    enabled: !!uuid,
  });
  const studentName = user?.name || '';
  const validStudentGroup =
    user?.studentGroups &&
    user.studentGroups?.filter((sg: StudentGroup) => !!sg?.group)[0];
  const studentNumber = validStudentGroup?.studentNumber || 0;
  const studentGradeKlass = validStudentGroup?.group?.name || '';

  const [email, setEmail] = useState('');
  const [emailId, setEmailId] = useState('');
  const [emailUrl, setEmailUrl] = useState('');
  const [emailUrlType, setEmailUrlType] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [parentName, setParentName] = useState(nokName || '');
  const [parentPhone, setParentPhone] = useState(phone);
  const [studentPhone, setStudentPhone] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isChannel, setChannel] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [privacy, setPrivacy] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [privacy3, setPrivacy3] = useState(false);
  const [marketing, setMarketing] = useState(false);

  const [me, setMe] = useState<User>();
  const [isWarn, setIsWarn] = useState(true);

  useEffect(() => {
    if (!me) {
      api
        .get('/users/me')
        .then((res) => setMe(res.data))
        .catch((err) => {});
    }
  }, []);

  const [signup] = useMutation(PARENT_SIGNUP, {
    variables: {
      email,
      name: parentName,
      password: password1,
      phone: parentPhone,
      schoolId: user?.school?.id,
    },
    onCompleted: async ({ parentSignup: { token } }) => {
      alert('회원가입이 완료되었습니다!');
      await setToken(token);
      await setSuccess(true);
    },
  });

  const emailre = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const spacere = / /g;

  const buttonDisabled =
    !emailId ||
    !emailUrl ||
    !password1 ||
    !password2 ||
    !parentName ||
    !privacy3 ||
    !privacy ||
    !policy ||
    !parentPhone ||
    !studentPhone;

  if (isSuccess) {
    return (
      <>
        <Alert
          onClose={() => history.push(`/add-kids/${uuid}`)}
          text="보호자 회원가입이 완료되었습니다."
        />
      </>
    );
  }

  if (error) {
    return <ErrorBlank />;
  }

  return (
    <div className="w-full box-border">
      {isLoading && <Blank />}
      {isWarn && (
        <div
          className={`fixed inset-0 w-full h-screen z-100 bg-littleblack flex items-center justify-center`}
          style={{ height, margin: 0 }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="bg-white opacity-100 rounded-lg shadow-sm relative p-3">
            {me ? (
              <Section>
                {me.role === 'PARENT' ? (
                  <>
                    <div className="text-2xl font-bold text-center">
                      자녀 추가 안내
                    </div>
                    <div className="text-lg">
                      <span className="font-bold">{me?.name}</span> 계정에 이미
                      로그인되어 있습니다. <br />이 계정에{' '}
                      <span className="font-bold">{name}</span> 자녀를
                      추가하시겠습니까?
                    </div>
                    <div className="h-4"></div>
                    <Button
                      tw={{ backgroundColor: 'bg-blue-500' }}
                      onClick={() => history.push(`/add-child/${uuid}`)}
                    >
                      추가하기
                    </Button>
                    <Button
                      tw={{ backgroundColor: 'bg-red-500' }}
                      onClick={() => {
                        setMe(undefined);
                        setIsWarn(false);
                      }}
                    >
                      새 계정으로 회원가입하기
                    </Button>
                  </>
                ) : (
                  <>
                    <div className="text-2xl font-bold text-center">경고</div>
                    <div className="text-lg">
                      <span className="font-bold">{me?.name}</span> 계정에 이미
                      로그인되어 있지만, <br />이 계정은 부모 계정이 아니기
                      때문에 <span className="font-bold">{name}</span> 자녀를
                      추가할 수 없습니다.
                      <br />새 계정으로 회원가입해 주시기 바랍니다.
                    </div>
                    <div className="h-4"></div>
                    <Button
                      tw={{ backgroundColor: 'bg-red-500' }}
                      onClick={() => {
                        setMe(undefined);
                      }}
                    >
                      새 계정으로 회원가입하기
                    </Button>
                  </>
                )}
              </Section>
            ) : (
              <Section>
                <div className="text-2xl font-bold text-center">
                  이미 슈퍼스쿨 회원이신가요?
                </div>
                <div className="h-4"></div>
                <div className="text-lg">
                  <span className="font-bold"> </span> 슈퍼스쿨 회원이신
                  보호자께서는 <br />
                  로그인하기 버튼을 눌러 로그인 후, <br />
                  카카오 알림톡에 수신된 보호자 가입하기 <br />
                  버튼을 다시 눌러주세요.
                </div>
                <div className="h-4"></div>

                <Button
                  tw={{ backgroundColor: 'bg-blue-500' }}
                  onClick={() => {
                    resetToken();
                    localStorage.removeItem('childToken');
                    localStorage.removeItem('token');
                    localStorage.removeItem('childError');
                    history.push('/login');
                  }}
                >
                  로그인하기
                </Button>

                <Button
                  tw={{ backgroundColor: 'bg-red-500' }}
                  onClick={() => {
                    setMe(undefined);
                    setIsWarn(false);
                  }}
                >
                  회원가입하기
                </Button>
              </Section>
            )}
          </div>
        </div>
      )}

      <TopNavbar
        title="보호자 회원가입"
        right={<div className="h-15 w-15" />}
      />
      <div className="mt-6 mb-3">
        <Logo className="mx-auto mb-4" />
        <div className="text-center flex flex-col items-center justify-center text-sm sm:text-base">
          <div className="font-bold text-lg">
            학생명 : {name}
            <br />
            {/* 학번 : {studentGradeKlass} {studentNumber}번 */}
          </div>
          <div className="text-gray-500 px-4 mt-2">
            해당 학생의 학부모가 맞는지 꼭 체크해주시고, 학생정보가 상이하다면{' '}
            <a
              href="http://superstudy.channel.io/"
              target="_blank"
              rel="noreferrer"
            >
              고객센터
            </a>
            로 문의주세요.
          </div>
        </div>
      </div>
      <Section>
        <div className="w-full">
          <TextField
            label="*이름"
            id="name"
            placeholder="이름을 입력해주세요."
            value={parentName}
            onChange={(e) =>
              setParentName(String(e.target.value).replace(spacere, ''))
            }
          />
        </div>

        <div className="w-full flex items-end">
          <TextField
            label="*이메일"
            tw={{
              width: 'w-40',
            }}
            placeholder="이메일"
            value={emailId}
            onChange={(e) => {
              setEmailId(String(e.target.value).replace(spacere, ''));
              setEmail(emailId + '@' + emailUrl);
            }}
          />
          <div className="text-xl pb-2">@</div>
          <TextField
            placeholder="직접입력"
            value={emailUrl}
            onChange={(e) => {
              let v = String(e.target.value).replace(spacere, '');
              setEmailUrl(v);
              setEmail(emailId + '@' + v);
            }}
          />
          <Select
            twOuter={{ width: 'w-full' }}
            value={emailUrlType}
            onChange={(e) => {
              setEmailUrlType(e.target.value);
              if (e.target.value === '직접입력') {
                setEmailUrl('');
              } else {
                setEmailUrl(e.target.value);
              }
              setEmail(emailId + '@' + e.target.value);
            }}
          >
            <option selected hidden>
              직접입력
            </option>
            {['직접입력', 'naver.com', 'gmail.com', 'daum.net', 'nate.com'].map(
              (subject: string) => (
                <option value={subject} key={subject}>
                  {subject}
                </option>
              ),
            )}
          </Select>
        </div>
        <div className="w-full">
          <TextField
            label="*비밀번호"
            type="password"
            placeholder="비밀번호 (문자,숫자,특수문자를 포함한 8자 이상)"
            value={password1}
            onChange={(e) =>
              setPassword1(String(e.target.value).replace(spacere, ''))
            }
          />
          {password1 && !passwordCheck(password1) && (
            <div className="text-red-600">안전한 비밀번호를 입력하세요.</div>
          )}
          <TextField
            type="password"
            placeholder="동일한 비밀번호를 한번 더 입력해주세요."
            value={password2}
            style={{ marginTop: '0.5rem' }}
            onChange={(e) =>
              setPassword2(String(e.target.value).replace(spacere, ''))
            }
          />
          {password2 && password1 !== password2 && (
            <div className="text-red-600">
              비밀번호 확인이 일치하지 않습니다.
            </div>
          )}
          <div className="text-grey-3">
            &nbsp; 안전한 개인정보의 보호를 위해 문자,숫자,특수문자가 포함된 8자
            이상의 비밀번호를 입력하세요.
            <br />
            &nbsp; 사용 가능한 특수문자는 ! @ # $ % & * ? 입니다.
          </div>
        </div>
        <div className="w-full">
          <PhoneNumberField
            label="*보호자전화번호"
            value={parentPhone || '010'}
            onChange={(e) => setParentPhone(e.target.value)}
          />
        </div>
        <div className="w-full">
          <PhoneNumberField
            label="*학생전화번호"
            value={studentPhone}
            onChange={(e) => setStudentPhone(e.target.value)}
          />
        </div>
        <div className="space-y-2">
          <div className="font-bold">
            <Checkbox
              label="전체 동의"
              onChange={() => {
                const allChecked = privacy3 && privacy && policy && marketing;
                setPrivacy3(!allChecked);
                setPrivacy(!allChecked);
                setMarketing(!allChecked);
                setPolicy(!allChecked);
              }}
              checked={privacy3 && privacy && policy && marketing}
            />
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="privacy"
              onChange={() => setPrivacy(!privacy)}
              checked={privacy}
            />
            <a
              href="https://prairie-porcupine-6b9.notion.site/e98e538db2384490afe99b5b422aebc9"
              target="blank"
            >
              <span className="font-semibold text-base cursor-pointer">
                개인정보/민감정보 수집 및 이용에 관한 동의(필수)
              </span>
            </a>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="policy"
              onChange={() => setPolicy(!policy)}
              checked={policy}
            />
            <a href={user?.student?.school?.privacyPolicyLink} target="blank">
              <span className="font-semibold text-base cursor-pointer">
                개인정보처리방침 (필수)
              </span>
            </a>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="privacy3"
              onChange={() => setPrivacy3(!privacy3)}
              checked={privacy3}
            />
            <a
              href="https://prairie-porcupine-6b9.notion.site/3-8429a0cbf48441e39e49c26122fde81f"
              target="blank"
            >
              <span className="font-semibold text-base cursor-pointer">
                개인정보/민감정보 제3자 제공에 관한 동의(필수)
              </span>
            </a>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="marketing"
              onChange={() => setMarketing(!marketing)}
              checked={marketing}
            />
            <a
              href="https://prairie-porcupine-6b9.notion.site/4e22f7de710f4a60a48900ed6e853199"
              target="blank"
            >
              <span className="font-semibold text-base cursor-pointer">
                홍보 및 마케팅을 위한 개인정보 제공 동의(선택)
              </span>
            </a>
          </div>
        </div>
        <div className="text-pink-600">{errorMessage}</div>
        {isChannel && (
          <a
            target="_blank"
            rel="noreferrer"
            href="http://superstudy.channel.io/"
          >
            <Button
              tw={{
                backgroundColor: 'bg-white',
                color: 'text-black',
                width: 'w-full',
              }}
            >
              문의하기
            </Button>
          </a>
        )}
        <Button
          // className="mt-20 bg-orange-2 text-white"
          disabled={buttonDisabled}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={() => {
            const regExp = /^010(?:\d{4})\d{4}$/;
            if (studentPhone && !regExp.test(studentPhone.replace(/-/g, ''))) {
              alert('학생 전화번호를 확인해 주세요.');
              setLoading(false);
              return;
            }
            if (parentPhone && !regExp.test(parentPhone.replace(/-/g, ''))) {
              alert('보호자 전화번호를 확인해 주세요.');
              setLoading(false);
              return;
            }
            if (user?.phone.replace(/-/g, '') !== studentPhone.replace(/-/g, '')) {
              setErrorMessage('학생전화번호가 맞지 않습니다!');
              alert('학생전화번호가 맞지 않습니다!');
            } else if (!emailre.test(String(email))) {
              setErrorMessage('이메일이 형식에 맞지 않습니다!');
              alert('이메일이 형식에 맞지 않습니다!');
            } else if (!passwordCheck(password1)) {
              setErrorMessage('안전한 비밀번호를 입력하세요.');
              alert('안전한 비밀번호를 입력하세요.');
            } else if (password1 !== password2) {
              setErrorMessage('비밀번호와 비밀번호 확인이 다릅니다!');
              alert('비밀번호와 비밀번호 확인이 다릅니다!');
            } else {
              setLoading(true);
              signup()
                .then((res) => {
                  console.log('res', res);
                  if (res) {
                    const { data, errors } = res;
                    if (data?.parentSignup?.token) {
                      history.push(`/add-child/${uuid}`);
                    } else {
                      setErrorMessage(
                        errors?.map((el: any) => el.message).join(', ') || '',
                      );
                      alert(
                        errors?.map((el: any) => el.message).join(', ') || '',
                      );
                    }
                  } else {
                    setLoading(false);
                    setErrorMessage(
                      '회원가입 정보가 정확하지 않거나 이미 가입된 이메일입니다. 정보를 다시 확인해주세요.',
                    );
                    alert(
                      '회원가입 정보가 정확하지 않거나 이미 가입된 이메일입니다. 정보를 다시 확인해주세요.',
                    );
                  }
                })
                .catch((err) => {
                  console.error('err', err);
                  setLoading(false);
                  if (
                    err?.message.includes(
                      'duplicate key value violates unique constraint',
                    )
                  ) {
                    setErrorMessage(
                      '이미 가입된 이메일입니다. 로그인을 시도해주세요.',
                    );
                    alert('이미 가입된 이메일입니다. 로그인을 시도해주세요.');
                  } else if (
                    err?.message.includes('Received status code 400')
                  ) {
                    setErrorMessage('필수 정보를 입력해주세요.');
                    alert('필수 정보를 입력해주세요.');
                  } else {
                    setErrorMessage(err?.message || '');
                    alert(err?.message || '');
                    if (
                      err?.message?.includes(
                        '입력된 반과 번호에 해당하는 계정이 이미 존재합니다.',
                      )
                    ) {
                      setChannel(true);
                    }
                  }
                });
            }
          }}
        >
          회원가입
        </Button>
      </Section>
    </div>
  );
};
