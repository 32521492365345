import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { Group, StudentGroup, User } from '../../types';

interface SubmitterItemProps {
  students: User[];
  studentGroup: StudentGroup;
  onClick: () => void;
  id: string;
}

export const SubmitterItem: FC<SubmitterItemProps> = ({
  students,
  studentGroup,
  onClick,
  id,
}) => {
  const user = studentGroup?.user;

  const _studentGroup = students
    ?.filter((el) => el.id === user?.id)[0]
    ?.studentGroups?.filter(
      (sg: StudentGroup) => sg?.group?.type === 'KLASS',
    )[0];
  const group: Group | undefined = _studentGroup?.group;

  const grade = group?.name?.split('학년')[0].trim();
  const klass = group?.name?.split('학년')[1]?.split('반')[0].trim();

  let num = grade || '0';

  if (String(klass).length === 1) {
    num += '0' + klass;
  } else if (String(klass).length === 2) {
    num += String(klass);
  }

  if (_studentGroup?.studentNumber) {
    if (String(_studentGroup.studentNumber).length === 1) {
      num += '0' + _studentGroup.studentNumber;
    } else if (String(_studentGroup.studentNumber).length === 2) {
      num += String(_studentGroup.studentNumber);
    }
  }

  const studentActivity =
    studentGroup?.user?.studentActivities &&
    studentGroup?.user?.studentActivities[0];
  const { pathname } = useLocation();
  return (
    <div
      className="min-w-1/2-2 p-1 text-center inline-block cursor-pointer"
      onClick={onClick}
    >
      <div
        className={
          studentActivity?.id
            ? pathname.includes(
                `/teacher/activity/submit/${id}/${studentActivity?.id}`,
              )
              ? 'border border-brand-1 bg-light_orange rounded-md p-2 flex items-center justify-between space-x-2'
              : 'border rounded-md p-2 flex items-center justify-between space-x-2'
            : 'border rounded-md p-2 flex items-center justify-between space-x-2'
        }
      >
        {studentActivity?.isSubmitted ? (
          <>
            <div className="flex items-center">
              <span className="bg-brand-1 px-2 py-1 text-white rounded-md text-sm">
                제출
              </span>
              <div className="ml-2 flex space-x-2">
                <span className="font-semibold">{num}</span>
                <span>{user?.name}</span>
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center cursor-pointer">
            <span
              className={
                pathname.startsWith(
                  `/teacher/activity/submit/${id}/${studentActivity?.id}`,
                )
                  ? 'bg-gray-100 px-2 py-1 border border-gray-200 text-gray-500 rounded-md text-sm'
                  : 'bg-gray-100 px-2 py-1 text-gray-500 rounded-md text-sm'
              }
            >
              미제출
            </span>
            <div className="ml-2 flex space-x-2">
              <span className="font-semibold">{num}</span>
              <span>{user?.name}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
