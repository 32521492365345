import { FC, useState, useEffect, useRef, useCallback } from 'react';
import { useQuery as uQ, useMutation } from 'react-query';
import { fetcher } from '../../plugins';
import {
  getThisYear,
  makeDateToString,
  makeTimeToString,
} from '../../utils/time-util';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { TextField, Select, Divider, Section, useRecoilValue, newMsgCntState, useSetRecoilState } from '../../reusable';
import { api } from '../../plugins';
import { ChatRoom } from '../../types';
import { MessageBox } from '../../components/chat/MessageBox';
import svgUserIcon from '../../assets/svg/user_icon.svg';
import { makeStudNum5 } from '../../utils';
import { ReactComponent as UploadIcon } from '../../assets/svg/icon-upload.svg';
import { LnbCalendarsItem } from '../calendar';

interface ChatRoomListProps {
  refetch: () => void;
}

export const ChatRoomList: FC<ChatRoomListProps> = ({ refetch }) => {
  const history = useHistory();

  const setNewMsgCnt = useSetRecoilState(newMsgCntState);

  const [chatRoomListData, setChatRoomListData] = useState<any[]>([]);
  const getChatRoomListData = useCallback(async () => {
    const res = await api.get(`/chatroom/list`).then(({ data }) => data);
    setChatRoomListData(res);
    api
      .get('/chatroom/newmsg')
      .then((res) => {
        setNewMsgCnt(res.data.newMsgCnt);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [refetch]);

  useEffect(() => {
    getChatRoomListData();
  }, [getChatRoomListData]);

  
  const newMsgCnt = useRecoilValue(newMsgCntState);
  useEffect(() => {
    getChatRoomListData();
  }, [getChatRoomListData, newMsgCnt])
  
  const [_name, set_Name] = useState('');
  const [, setChatRoomName] = useState('');

  let userType = '';
  //let chatRoomInfo: ChatRoom[] = chatRoomListData;
  const { pathname } = useLocation();

  if (pathname?.split('/').length > 2) {
    userType = pathname?.split('/')[1];
  }
  const roomListRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    roomListRef.current?.scrollTo(0, 0);
  }, [chatRoomListData]);

  const msgColor = [
    // {
    //     id: '0',
    //     name: '내가 읽지 않은 메시지 갯수',
    //     bgColor: '#ff0000',
    //     borderColor: '#ff0000',
    // },
    {
      id: '1',
      name: '상대방이 읽지 않은 메시지',
      bgColor: '#0054FF',
      borderColor: '#0054FF',
    },
  ];

  //console.log(chatRoomListData);
  return (
    <div className="h-screen-16" ref={roomListRef}>
      <Section>
        <div className="flex space-x-3 items-center">
          <div className="min-w-max cursor-pointer mt-1">
            <Select
              tw={{
                height: 'h-11',
                fontSize: 'text-sm',
              }}
              onChange={(e) => {}}
              placeholder="대화상대"
            >
              <option value={-1}>{'대화상대'}</option>
            </Select>
          </div>
          <div className="w-full cursor-pointer text-sm">
            <div className="flex pt-3 pb-2 items-center space-x-2">
              <form
                className="items-center border-gray-200 rounded-md border flex justify-between h-11 px-2 w-full"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  className="w-full"
                  placeholder="상대방 이름을 입력해 주세요."
                  value={_name}
                  onChange={(e) => {
                    set_Name(e.target.value);
                    if (e.target.value === '') {
                      setChatRoomName('');
                    }
                  }}
                />
              </form>
              <UploadIcon />
            </div>
          </div>
        </div>
      </Section>
      <div className="text-xs text-right">
        {msgColor.map((el: any) => (
          <LnbCalendarsItem
            value={el.id}
            checked={false}
            color={el.bgColor}
            text={el.name}
          />
        ))}
      </div>
      <Divider />
      {chatRoomListData &&
        chatRoomListData
          ?.filter((item: any) => _name === '' || item?.name?.includes(_name))
          ?.map((cr: any) => (
            <div
              className={
                pathname === `/${userType}/chat/${cr.id}`
                  ? 'px-6 py-2 cursor-pointer bg-gray-50'
                  : 'px-6 py-2 cursor-pointer'
              }
            >
              <div>
                <button className="w-full py-4 focus:outline-none focus-visible:bg-indigo-50">
                  <Link to={`/${userType}/chat/${cr.id}`}>
                    <div className="flex items-center cursor-pointer">
                      <div className="relative">
                        <img
                          className="flex-2 rounded-full items-start flex-shrink-0 mr-1 w-10 h-10 mx-auto"
                          src={cr.profile}
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = svgUserIcon;
                            //currentTarget.className = 'w-full ';
                          }}
                        />
                      </div>
                      {/* 대화정보 (마지막 메시지, 시간, 안 읽은 갯수) */}
                      <MessageBox
                        name={
                          cr.role === 'USER'
                            ? cr.name +
                              ' (' +
                              makeStudNum5(
                                (cr?.studentinfo[0]?.klass
                                  ? cr?.studentinfo[0]?.klass
                                  : '') +
                                  (cr?.studentinfo[0]?.student_number
                                    ? cr?.studentinfo[0]?.student_number
                                    : ''),
                              ) +
                              ')'
                            : cr.role === 'PARENT'
                            ? cr?.studentinfo[0]?.student_name +
                              '보호자' +
                              ' (' +
                              makeStudNum5(
                                (cr?.studentinfo[0]?.klass
                                  ? cr?.studentinfo[0]?.klass
                                  : '') +
                                  (cr?.studentinfo[0]?.student_number
                                    ? cr?.studentinfo[0]?.student_number
                                    : ''),
                              ) +
                              ')'
                            : //: cr.role === 'TEACHER'
                            cr.role !== 'USER' && cr.role !== 'PARENT'
                            ? cr.name + '선생님'
                            : ''
                        }
                        lastmsgtime={cr.lastmsgtime}
                        msgcnt={cr.msgcnt}
                        content={cr.content}
                      />
                    </div>
                  </Link>
                </button>
              </div>
            </div>
          ))}
    </div>
  );
};
