import React from 'react';
import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from '../assets/svg/icon-arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../assets/svg/icon-arrow-right.svg';

export interface FrontPaginationProps {
  basePath: string;
  total: number;
  limit: number;
  maxPageSetLength?: number;
  studentName?: string;
  page: number;
  setPage: (b: number) => void;
}

export const FrontPagination: FC<FrontPaginationProps> = ({
  basePath,
  total,
  limit = 25,
  maxPageSetLength = 10,
  studentName = '',
  page,
  setPage,
}) => {
  const { push } = useHistory();
  const pageCount = Math.ceil(total / limit);
  const pageSet = Math.ceil(page / maxPageSetLength);
  const pageSetLength = Math.min(
    pageCount - (pageSet - 1) * maxPageSetLength,
    maxPageSetLength,
  );
  const prevPage = (pageSet - 2) * pageSetLength + 1;
  const nextPage = pageSet * maxPageSetLength + 1;

  function range(length: number, start = 0) {
    return Array.from({ length }, (_, i) => i + start);
  }

  return (
    <div className="flex border-t p-1">
      <div className="max-w-container flex">
        <button
          className=" space-x-4 items-center h-10 "
          disabled={prevPage < 1}
          onClick={() => push(`${basePath}?page=${prevPage}&limit=${limit}`)}
        >
          <ArrowLeftIcon />
        </button>

        <div className="flex">
          {range(pageSetLength, (pageSet - 1) * maxPageSetLength + 1).map(
            (i) => (
              <Link
                key={i}
                to={`${basePath}?page=${i}&limit=${limit}&username=${studentName}`}
                className={`grid place-items-center w-8 h-10 text-gray-500 ${
                  i === page ? 'border-t-2 border-gray-900 text-gray-900' : ''
                }`}
                onClick={() => setPage(i)}
              >
                {i}
              </Link>
            ),
          )}
        </div>

        <button
          className=" space-x-4 items-center h-10"
          disabled={nextPage > pageCount}
          onClick={() => push(`${basePath}?page=${nextPage}&limit=${limit}`)}
        >
          <ArrowRightIcon />
        </button>
      </div>
    </div>
  );
};
