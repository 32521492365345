import { FC } from 'react';
import { makeDateToString, makeTimeToString } from '../../utils/time-util';
import svgUserIcon from '../../assets/svg/user_icon.svg';
interface ReceiveMessageProps {
  MessageData?: any;
  name?: string;
  profile?: string;
  KlassNameNum?: string;
}

export const ReceiveMessage: FC<ReceiveMessageProps> = ({
  MessageData,
  name,
  profile,
  KlassNameNum,
}) => {
  return (
    <div className="message mb-4 flex px-3">
      <div className="flex-2">
        <div className="relative">
          <img
            className="flex-2 rounded-full items-start flex-shrink-0 mr-1 w-10 h-10 mx-auto"
            src={profile}
            width="35"
            height="35"
            alt=""
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = svgUserIcon;
              currentTarget.className = 'flex-2 rounded-full items-start flex-shrink-0 mr-1 w-10 h-10 mx-auto';
            }}
          />
        </div>
      </div>
      <div className="flex-1 px-2">
        <h4 className="text-sm text-gray-900">
          {KlassNameNum} {name}
        </h4>
        <div className="inline-block bg-gray-300 rounded-xl p-1 px-6 text-gray-700">
          <p className="whitespace-pre-wrap text-left text-sm">
            {MessageData.content}
          </p>
        </div>
        <div className="pl-4">
          <small className="text-gray-500">
            {MessageData.createdAt &&
              makeDateToString(MessageData.createdAt, '.') +
                ' ' +
                makeTimeToString(MessageData.createdAt)}
          </small>
        </div>
      </div>
    </div>
  );
};
