import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  Blank,
  Button,
  Checkbox,
  PhoneNumberField,
  Section,
  TextField,
  TopNavbar,
  useLogout,
  useMutation,
  useQuery,
} from '../../../reusable';
import {
  FIRST_LOGIN,
  GET_SIGNUP_GROUPS,
  SEND_PARENT_SIGNUP,
} from '../../../query';
import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';
import { ErrorBlank } from '../../../components';
import { passwordCheck } from '../../../utils';

export const FirstLoginPage: React.FC = () => {
  const { loading, error, data } = useQuery(GET_SIGNUP_GROUPS);
  const logout = useLogout();
  const history = useHistory();
  const [name, setName] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState('');
  const [nokName, setNokName] = useState('');
  const [nokPhone, setNokPhone] = useState('');
  const [birthDate, setBirthDate] = useState('2006-01-01');

  const spacere = / /g;

  const [isLoading, setLoading] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [isChannel, setChannel] = useState(false);
  const [hopePath, setHopePath] = useState('');
  const [hopeMajor, setHopeMajor] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  // const signup = useSignup({ email, password: password1, name });

  const [sendParentSignup] = useMutation(SEND_PARENT_SIGNUP, {
    onCompleted: () => {
      alert('보호자 회원가입 메시지 발송이 완료되었습니다.');
      setLoading(false);
    },
    onError: (err) => {
      alert(err.message || '메시지 발송 중 오류가 발생하였습니다.');
      setLoading(false);
    },
  });

  const [firstLogin] = useMutation(FIRST_LOGIN, {
    variables: {
      data: JSON.stringify({
        name,
        password: password1,
        phone,
        nokPhone,
        nokName,
        birthDate,
        hopePath,
        hopeMajor,
      }),
    },
    onCompleted: async () => {
      sendParentSignup();
    },
  });

  useEffect(() => {
    setName(data?.me?.name || '');
    setNokName(data?.me?.nokName || '');
    setNokPhone(data?.me?.nokPhone || '');
    if (data && !data?.me?.firstVisit) {
      history.push('/student');
    }
  }, [data, history]);

  if (error) {
    return <ErrorBlank />;
  }

  const buttonDisabled =
    !password1 ||
    !password2 ||
    !name ||
    !privacy ||
    !policy ||
    !phone ||
    !nokName ||
    !nokPhone ||
    !birthDate ||
    password1 !== password2;

  return (
    <div className="flex items-center justify-center w-full box-border">
      <div className="max-w-xl w-full box-border">
        {loading && <Blank />}
        {isLoading && <Blank />}

        <TopNavbar
          title="첫 로그인"
          left={
            <div className="h-15">
              <BackButton
                className="h-15"
                onClick={() => {
                  logout();
                  history.push('/');
                }}
              />
            </div>
          }
        />
        <div className="mt-6 mb-3">
          <Logo className="mx-auto mb-4" />
          <span className="text-center flex justify-center text-sm sm:text-base">
            슈퍼스쿨에 오신 것을 환영합니다!
            <br />
            처음 로그인하시나요? 아래 정보를 입력해주세요.
          </span>
        </div>
        <Section>
          <div className="w-full">
            <TextField
              label="*이름"
              id="name"
              placeholder="이름을 입력해주세요."
              value={name}
              onChange={(e) =>
                setName(String(e.target.value).replace(spacere, ''))
              }
              tw={{
                borderColor: name ? 'border-gray-300' : 'border-red-700',
              }}
            />
          </div>

          <div className="w-full">
            <TextField label="이메일" value={data?.me?.email} readOnly />
          </div>
          <div className="w-full">
            <TextField label="학교" value={data?.me?.school?.name} readOnly />
          </div>
          <div className="w-full">
            <TextField
              label="학년 / 반"
              value={data?.me?.klassGroup?.group?.name}
              readOnly
            />
          </div>

          <div className="w-full">
            <TextField
              label="출석번호"
              value={data?.me?.klassGroup?.studentNumber}
              readOnly
            />
          </div>
          <div className="w-full">
            <TextField
              label="*비밀번호"
              type="password"
              placeholder="비밀번호 (문자,숫자,특수문자를 포함한 8자 이상)"
              value={password1}
              onChange={(e) =>
                setPassword1(String(e.target.value).replace(spacere, ''))
              }
              tw={{
                borderColor:
                  !password1 || password1 !== password2
                    ? 'border-red-700'
                    : 'border-gray-300',
              }}
            />
            {password1 && !passwordCheck(password1) && (
              <div className="text-red-600">안전한 비밀번호를 입력하세요.</div>
            )}
            <TextField
              type="password"
              placeholder="동일한 비밀번호를 한번 더 입력해주세요."
              value={password2}
              onChange={(e) =>
                setPassword2(String(e.target.value).replace(spacere, ''))
              }
              style={{ marginTop: '0.5rem' }}
              tw={{
                borderColor:
                  !password2 || password1 !== password2
                    ? 'border-red-700'
                    : 'border-gray-300',
              }}
            />
            {password2 && password1 !== password2 && (
              <div className="text-red-600">
                비밀번호 확인이 일치하지 않습니다.
              </div>
            )}
            <div className="text-grey-3">
              &nbsp; 안전한 개인정보의 보호를 위해 문자,숫자,특수문자가 포함된
              8자 이상의 비밀번호를 입력하세요.
              <br />
              &nbsp; 사용 가능한 특수문자는 ! @ # $ % & * ? 입니다.
            </div>
          </div>
          <div className="w-full">
            <PhoneNumberField
              label="*전화번호"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              tw={{
                borderColor: !phone ? 'border-red-700' : 'border-gray-300',
              }}
            />
          </div>

          {/* <div className="w-full">
          <TextField
            type="date"
            label="*생년월일"
            onChange={(e) => setBirthDate(e.target.value)}
            value={birthDate}
            tw={{
              borderColor: birthDate ? 'border-gray-300' : 'border-red-700',
            }}
          />
        </div> */}
          <div className="w-full">
            <TextField
              label="희망진로"
              value={hopePath}
              onChange={(e) => setHopePath(e.target.value)}
            />
          </div>
          <div className="w-full">
            <TextField
              label="희망학과"
              value={hopeMajor}
              onChange={(e) => setHopeMajor(e.target.value)}
            />
          </div>

          <div className="w-full">
            <TextField
              label="*보호자 이름"
              value={nokName}
              onChange={(e) => setNokName(e.target.value)}
              tw={{
                borderColor: nokName ? 'border-gray-300' : 'border-red-700',
              }}
            />
          </div>

          <div className="w-full">
            <PhoneNumberField
              label="*보호자 전화번호"
              value={nokPhone}
              onChange={(e) => setNokPhone(e.target.value)}
              tw={{
                borderColor: !nokPhone ? 'border-red-700' : 'border-gray-300',
              }}
            />
            <div className="text-grey-3">
              &nbsp; 작성하신 보호자 연락처로 보호자 가입요청 메시지가
              전달됩니다. 학교에 제출한 보호자의 정보와 상이할 경우 추후 학교
              제출 연락처로 변경되니, 꼭 학교에 제출한 보호자의 정보로
              입력하시기 바랍니다.
            </div>
          </div>
          <div className="space-y-2">
            <div className="font-bold">
              <Checkbox
                label="전체 동의"
                onChange={() => {
                  const allChecked = privacy && policy;
                  setPrivacy(!allChecked);
                  setPolicy(!allChecked);
                }}
                checked={privacy && policy}
              />
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="policy"
                onChange={() => setPolicy(!policy)}
                checked={policy}
              />
              <a
                href="https://brook-eggplant-53c.notion.site/8b9b51e5800c43e6bf17f55a13095e06"
                target="blank"
              >
                <span className="font-semibold text-base cursor-pointer">
                  슈퍼스쿨이용약관 (필수)
                </span>
              </a>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="privacy"
                onChange={() => setPrivacy(!privacy)}
                checked={privacy}
              />
              <a href={data?.me?.school?.privacyPolicyLink} target="blank">
                <span className="font-semibold text-base cursor-pointer">
                  개인정보처리방침 (필수)
                </span>
              </a>
            </div>
          </div>
          <div className="text-pink-600">{errorMessage}</div>
          {isChannel && (
            <a
              target="_blank"
              rel="noreferrer"
              href="http://superstudy.channel.io/"
            >
              <Button
                tw={{
                  backgroundColor: 'bg-white',
                  color: 'text-black',
                  width: 'w-full',
                }}
              >
                문의하기
              </Button>
            </a>
          )}
          <Button
            // className="mt-20 bg-orange-2 text-white"
            disabled={buttonDisabled}
            tw={{
              backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
            }}
            onClick={() => {
              const regExp = /^010(?:\d{4})\d{4}$/;
              if (phone && !regExp.test(phone)) {
                alert('전화번호를 확인해 주세요.');
                setLoading(false);
                return;
              }
              if (nokPhone && !regExp.test(nokPhone.replace(/-/g, ''))) {
                alert('보호자 전화번호를 확인해 주세요.');
                setLoading(false);
                return;
              }
              if (!passwordCheck(password1)) {
                setErrorMessage('안전한 비밀번호를 입력하세요.');
              } else if (password1 !== password2) {
                setErrorMessage('비밀번호와 비밀번호 확인이 다릅니다!');
              } else {
                setLoading(true);
                firstLogin()
                  .then((res) => {
                    if (res) {
                      const { data, errors } = res;
                      if (data?.firstLogin?.id) {
                        alert('정보 설정이 완료되었습니다!');
                        window.location.reload();
                      } else {
                        setErrorMessage(
                          errors?.map((el: any) => el.message).join(', ') || '',
                        );
                        alert(
                          errors?.map((el: any) => el.message).join(', ') || '',
                        );
                      }
                    } else {
                      setLoading(false);
                      setErrorMessage(
                        '회원가입 정보가 정확하지 않거나 이미 가입된 이메일입니다. 정보를 다시 확인해주세요.',
                      );
                    }
                  })
                  .catch((err) => {
                    console.error('err', err);
                    setLoading(false);
                    if (
                      err?.message.includes(
                        'duplicate key value violates unique constraint',
                      )
                    ) {
                      setErrorMessage(
                        '이미 가입된 이메일입니다. 로그인을 시도해주세요.',
                      );
                      alert('이미 가입된 이메일입니다. 로그인을 시도해주세요.');
                    } else if (
                      err?.message.includes('Received status code 400')
                    ) {
                      setErrorMessage('필수 정보를 입력해주세요.');
                      alert('필수 정보를 입력해주세요.');
                    } else {
                      setErrorMessage(err?.message || '');
                      alert(err?.message || '');
                      if (
                        err?.message?.includes(
                          '입력된 반과 번호에 해당하는 계정이 이미 존재합니다.',
                        )
                      ) {
                        setChannel(true);
                      }
                    }
                  });
              }
            }}
          >
            정보 입력하고 시작하기
          </Button>
        </Section>
      </div>
    </div>
  );
};
