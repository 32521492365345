import React, { FC, useState } from 'react';
import { UPDATE_TEACHER } from '../../../query';
import {
  BackButton,
  Blank,
  Button,
  PhoneNumberField,
  Section,
  TextField,
  TopNavbar,
  useMutation,
} from '../../../reusable';
import { User } from '../../../types';
import { passwordCheck } from '../../../utils';

interface TeacherInfoUpdatePageProps {
  me: User;
  refetch: () => void;
  gotoRead: () => void;
}

export const TeacherInfoUpdatePage: FC<TeacherInfoUpdatePageProps> = ({
  me,
  refetch,
  gotoRead,
}) => {
  const [name, setName] = useState(me?.name);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState(me?.phone);
  const [isLoading, setLoading] = useState(false);

  const [updateTeacher] = useMutation(UPDATE_TEACHER, {
    variables: {
      data: JSON.stringify({
        name,
        password,
        phone,
      }),
    },
    onCompleted: async () => {
      await refetch();
      await gotoRead();
      await setLoading(false);
    },
  });

  const spacere = / /g;

  const buttonDisabled = !name || !phone || password !== password2;

  const firstLogin = false;

  return (
    <>
      {/* Mobile V */}
      <div className="block md:hidden">
        <TopNavbar title="내 정보" left={<BackButton />} />
      </div>
      <div className="col-span-6 max-w-lg h-screen  px-6 py-6">
        {isLoading && <Blank />}
        <Section>
          <div className="w-full">
            <TextField
              label="이름"
              id="name"
              placeholder="이름을 입력해주세요"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="w-full">
            <TextField label="이메일" value={me?.email} readOnly />
          </div>
          <div className="w-full">
            <TextField
              type="password"
              label="비밀번호"
              placeholder="비밀번호 (문자,숫자,특수문자를 포함한 8자 이상)"
              value={password}
              onChange={(e) =>
                setPassword(String(e.target.value).replace(spacere, ''))
              }
            />
            {password && !passwordCheck(password) && (
              <div className="text-red-600">안전한 비밀번호를 입력하세요.</div>
            )}
            <TextField
              type="password"
              placeholder="동일한 비밀번호를 한번 더 입력해주세요."
              value={password2}
              style={{ marginTop: '0.5rem' }}
              onChange={(e) =>
                setPassword2(String(e.target.value).replace(spacere, ''))
              }
            />
            {password2 && password !== password2 && (
              <div className="text-red-600">
                비밀번호 확인이 일치하지 않습니다.
              </div>
            )}
            <div className="text-grey-3">
              &nbsp; 안전한 개인정보의 보호를 위해 문자,숫자,특수문자가 포함된
              8자 이상의 비밀번호를 입력하세요.
              <br />
              &nbsp; 사용 가능한 특수문자는 ! @ # $ % & * ? 입니다. 
              <br />
              &nbsp; 비밀번호를 입력하지 않으면 기존 비밀번호가 유지 됩니다.
            </div>
          </div>
          <div className="w-full">
            <PhoneNumberField
              label="전화번호"
              value={phone || '010'}
              onChange={(e) => setPhone(e.target.value)}
              style={{
                borderColor: !phone ? 'rgba(185, 28, 28)' : '',
              }}
            />
          </div>
          <Button
            disabled={buttonDisabled}
            onClick={() => {
              setLoading(true);
              const regExp=/^010(?:\d{4})\d{4}$/;
              if(phone && !regExp.test(phone)){
                alert('전화번호를 확인해 주세요.');
                setLoading(false);
                return;
              }
              if (password.length === 0) {
                alert(
                  '비밀번호를 공백으로 기입할 시 기존 비밀번호가 유지됩니다.',
                );
                updateTeacher();
              } else {
                if (!passwordCheck(password)) {
                  alert('안전한 비밀번호를 입력하세요.');
                  setLoading(false);
                } else if (password !== password2) {
                  alert('비밀번호와 비밀번호 확인이 다릅니다.');
                } else {
                  updateTeacher();
                }
              }
            }}
          >
            내 정보 수정하기
          </Button>
        </Section>
      </div>
    </>
  );
};
