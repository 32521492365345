import {
  Alert,
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  Section,
  TextField,
  TopNavbar,
  useQuery,
  useQueryBasic,
  useMutation, PhoneNumberField,
} from '../../../reusable';
import React, { FC, useEffect, useState } from 'react';
import { FIND_ID_PAGE, FIND_ID } from '../../../query';
import { FindIdSuccessPage } from './FindIdSuccessPage';
import { api } from '../../../plugins';


export const FindIdPage: FC = () => {
  const { loading, error, data } = useQuery(FIND_ID_PAGE);
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [errM, setErrM] = useState('');
  const [userId, setUserId] = useState(-1);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isFindResult, setIsFindResult] = useState(false);


  const queryString = useQueryBasic({
    name,
    phone
  });

  const [findIdQL] = useMutation(FIND_ID, {
    variables: {
      userId,
    },
    onCompleted: (result) => {},
    onError: (err) => {
      setLoading(false);
      //setErrM(err.message);
      setErrM('입력된 정보에 해당하는 계정을 찾을 수 없습니다.');
    },
  });

  const findId = () => {
      if (!name || phone.length != 11) {
        alert('이름, 전화번호를 입력해주세요.');
        return;
      }
    setLoading(true);

    api
      .get(`/users/find-id${queryString}`)
      .then(({ data }) => {
        const { userId, result, phone } = data;
        setUserId(userId);
        setIsFindResult(result);
        setPhone(phone);

        result && findIdQL();

        setSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setErrM('입력된 정보에 해당하는 계정을 찾을 수 없습니다.');
      });
  };


  if (isSuccess) {
    return (
      <FindIdSuccessPage
        phone={phone}
       />
    );
  }

  const buttonDisabled = phone.length!=11 || !name;

  return (
    <>
      <TopNavbar
        title="아이디 찾기"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      {loading && <Blank />}
      {isLoading && <Blank />}
      {errM && (
        <Alert severity="error" onClose={() => setErrM('')}>
          {errM}
        </Alert>
      )}

      {
        <Section>
          <div> 이름과 전화번호를 입력해 주세요. </div>

          <TextField
            label="*이름"
            placeholder="이름을 입력해주세요."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <PhoneNumberField
          label="*전화번호"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}>
          </PhoneNumberField>
        </Section>
      }
        <Section>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://superstudy.channel.io/"
          >
            <Button
              tw={{
                backgroundColor: 'bg-white',
                color: 'text-black',
                width: 'w-full',
              }}
            >
              문의하기
            </Button>
          </a>
          <Button
            disabled={buttonDisabled}
            tw={{
              backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
            }}
            onClick={() => findId()}
          >
            아이디 찾기
          </Button>
        </Section>
    </>
  );
};
