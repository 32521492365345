import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  Blank,
  gql,
  Section,
  TopNavbar,
  useQuery,
} from '../../../reusable';
import { ErrorBlank } from '../../../components';
import { ReactComponent as RightArrow } from '../../../assets/svg/mypage-right-arrow.svg';
import { makeDateToString } from '../../../utils';
import { User } from '../../../types';
import { api } from '../../../plugins';

const GET_OUTINGS = gql`
  query {
    outingsByStudent {
      id
      type
      reportedAt
      startAt
      outingStatus
      endAt
      reason
      teacherSignature
    }
  }
`;
function getDate(stromg: any) {
  let week = ['일', '월', '화', '수', '목', '금', '토'];
  let dayOfWeek = week[new Date(stromg).getDay()];
  return dayOfWeek + '요일';
}

export const OutingPage: React.FC = () => {
  const history = useHistory();

  const { loading, error, data } = useQuery(GET_OUTINGS, {
    onError: ({ message }) => {
      if (message === 'Unauthorized') {
        history.push('/login');
      }
    },
  });

  const [me, setMe] = useState<User>();

  useEffect(() => {
    if (!me) {
      api
        .get('/users/me')
        .then((res) => setMe(res.data))
        .catch((err) => {
          if (err.message.includes('Unauthorized')) {
            history.replace('/login');
          } else {
            alert(err.message);
          }
        });
    }
  }, []);

  const isNotParent = me?.role !== 'PARENT';

  const outings = data?.outingsByStudent
    ?.slice()
    .sort(
      (a: any, b: any) =>
        (a.outingStatus === 'DELETE_APPEAL' ? -1 : 0) -
        (b.outingStatus === 'DELETE_APPEAL' ? -1 : 0),
    )
    .sort(
      (a: any, b: any) =>
        (a.outingStatus === 'RETURNED' ? -1 : 0) -
        (b.outingStatus === 'RETURNED' ? -1 : 0),
    );

  if (error) {
    return <ErrorBlank />;
  }

  return (
    <div>
      <TopNavbar
        title="확인증"
        left={
          <div className="h-15">
            <BackButton
              className="h-15"
              onClick={() => history.push('/student/apply')}
            />
          </div>
        }
      />
      {loading && <Blank />}
      {error && <ErrorBlank />}

      <Section>
        <div className="pb-12 space-y-4">
          {outings?.map((outing: any) => {
            return (
              <div
                className="flex justify-between items-center pb-4 border-b border-grey-9 cursor-pointer "
                onClick={() => history.push(`/student/outing/${outing?.id}`)}
              >
                <div>
                  <div className="text-gray-800 font-bold">
                    [{outing?.type}]{' '}
                    {outing?.startAt &&
                      makeDateToString(new Date(outing.startAt))}{' '}
                    {getDate(outing?.startAt)}
                  </div>
                  <div className="text-gray-600 text-sm">
                    {outing?.reportedAt}{' '}
                    {outing?.outingStatus === 'PROCESSED' ? (
                      '선생님 승인 완료'
                    ) : outing?.outingStatus === 'BEFORE_TEACHER_APPROVAL' ? (
                      '선생님 승인 전'
                    ) : outing?.outingStatus === 'DELETE_APPEAL' ? (
                      <span className="text-red-500 font-bold">삭제 요청</span>
                    ) : (
                      <span className="text-brand-1 font-bold">반려됨</span>
                    )}
                  </div>
                </div>
                <RightArrow />
              </div>
            );
          })}
          {!outings?.length && (
            <div className="w-full h-screen-10 text-center flex items-center justify-center">
              <div className="text-gray-600">
                아직 확인증 리스트가 없습니다. <br />
                {isNotParent && <div>아래 버튼을 눌러 신청해주세요.</div>}
              </div>
            </div>
          )}
        </div>
      </Section>
      {isNotParent && (
        <div className="bottom-16 fixed z-10 w-full px-4">
          <button
            className="bg-brand-1 text-white rounded-lg h-14 px-4 w-full"
            onClick={() => history.push('/student/outing/add')}
          >
            확인증 신청하기
          </button>
        </div>
      )}
    </div>
  );
};
