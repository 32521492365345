import React, { FC, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Button } from '../../reusable';
import { Absent } from '../../types';
import { makeDateToString, makeStartEndToString } from '../../utils';

interface AbsentsExcelDownloadViewProps {
  startDate: string;
  endDate: string;
  absents: Absent[];
  setCsvData: (b: boolean) => void;
  isCsvData: boolean;
}

export const AbsentsExcelDownloadView: FC<AbsentsExcelDownloadViewProps> = ({
  startDate,
  endDate,
  absents,
  setCsvData,
  isCsvData,
}) => {
  const csvRef = useRef<any>();
  const [clicked, setClicked] = useState(false);
  const [updated, setUpdated] = useState(false);

  const csvData: any[] = [];

  csvData.push([
    '일자',
    '학년',
    '반',
    '번호',
    '성명',
    '출결구분',
    '사유',
    '나이스',
    '출결신고서',
    '증빙서류',
    '원본',
  ]);

  absents
    ?.filter((a: Absent) => a?.absentStatus === 'PROCESSED')
    .map((a: Absent) => {
      if (a?.startAt && a?.endAt) {
        let grade: any = 0;
        let klass: any = 0;
        if (typeof a.studentGradeKlass === 'string') {
          grade = a.studentGradeKlass.split('학년')[0];
          klass = a.studentGradeKlass.split('학년')[1].split('반')[0];
        }

        csvData.push([
          makeStartEndToString(a.startAt, a.endAt, a.reportType),
          grade,
          klass,
          a.studentNumber,
          a.student?.name,
          String(a.description) + a.reportType,
          a.reason,
          a.niceSubmitted ? 'O' : 'X',
          'O',
          a.evidenceType,
          a.submitted || a.evidenceType === '학부모 확인서' ? 'O' : 'X',
        ]);
      }
    });

  useEffect(() => {
    if (absents && clicked) {
      setUpdated(true);
    }
  }, [clicked, csvData]);

  useEffect(() => {
    if (clicked && absents?.length && csvData && updated) {
      csvRef?.current?.link?.click();
      setClicked(false);
      setUpdated(false);
    }
  }, [updated]);

  return (
    <>
      <Button
        text="월별출결현황"
        tw={{
          backgroundColor: 'bg-green-500',
          minWidth: 'min-w-max',
          paddingY: 'py-2.5',
          height: 'h-auto',
          paddingX: 'px-4',
          width: 'w-full',
        }}
        onClick={() => {
          !isCsvData && setCsvData(true);
          setClicked(true);
        }}
      />
      <CSVLink
        data={csvData}
        filename={`월출결현황(${makeDateToString(
          new Date(startDate),
        )}~${makeDateToString(new Date(endDate))}).csv`}
        ref={csvRef}
      ></CSVLink>
    </>
  );
};
