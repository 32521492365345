import {
  Blank,
  Button,
  Section,
  TextArea,
  useMutation,
} from '../../../reusable';
import React, { FC, useState } from 'react';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import { CREATE_CANTEEN, UPDATE_CANTEEN } from '../../../query';
import { makeDateToString } from '../../../utils';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import { Canteen } from '../../../types';

interface CanteenSubmitPageProps {
  selectedDate: Date;
  canteenData?: Canteen;
  refetch: () => void;
}

export const CanteenSubmitPage: FC<CanteenSubmitPageProps> = ({
  selectedDate,
  canteenData,
  refetch,
}) => {
  const [image, setImage] = useState<File | string | null | undefined>(
    canteenData?.image,
  );
  const [lunch, setLunch] = useState(canteenData?.lunch || '');
  const [dinner, setDinner] = useState(canteenData?.dinner || '');
  const [loading, setLoading] = useState(false);

  const [createCanteen] = useMutation(CREATE_CANTEEN, {
    variables: {
      data: JSON.stringify({
        lunch,
        dinner,
        date: makeDateToString(selectedDate),
      }),
      image,
    },
    onCompleted: () => {
      setLoading(false);
      refetch();
    },
  });
  const [updateCanteen] = useMutation(UPDATE_CANTEEN, {
    variables:
      typeof image === 'string'
        ? {
            data: JSON.stringify({
              lunch,
              dinner,
              date: makeDateToString(selectedDate),
            }),
          }
        : {
            data: JSON.stringify({
              lunch,
              dinner,
              date: makeDateToString(selectedDate),
            }),
            image,
          },
    onCompleted: () => {
      setLoading(false);
      refetch();
    },
  });
  return (
    <div className="w-full h-screen relative bg-white border-l border-grey-6">
      {loading && <Blank reversed />}
      <div className="w-full py-4 px-6 border-b border-grey-6 flex items-center justify-between">
        <div className="font-bold text-xl">
          {selectedDate?.getFullYear()}년 {selectedDate?.getMonth() + 1}월{' '}
          {selectedDate?.getDate()}일
        </div>
        <div className="invisible border border-grey-6 px-4 rounded py-2 text-gray-700 cursor-pointer">
          수정
        </div>
      </div>
      <Section>
        <label htmlFor="imageupload">
          <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
            {typeof image === 'string' ? (
              <ExifOrientationImg
                className="absolute w-full h-full rounded object-cover"
                src={image}
                alt=""
              />
            ) : image ? (
              <img
                className="absolute w-full h-full rounded object-cover"
                src={URL.createObjectURL(image)}
                alt=""
              />
            ) : (
              <div className="absolute w-full h-full rounded object-cover bg-white">
                <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                  <FileUploadImg />
                  <div className="text-brand-1">이미지를 업로드해주세요!</div>
                </div>
              </div>
            )}
          </div>
        </label>
        <input
          type="file"
          id="imageupload"
          className="hidden"
          accept=".png, .jpeg, .jpg"
          onChange={(e) => {
            if (e.target.files?.item(0)) {
              const size: any = e.target.files.item(0)?.size || 0;
              if (size >= 20 * 1024 * 1024) {
                alert(
                  '한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요!',
                );
              } else {
                e.target.validity.valid && setImage(e.target.files?.item(0));
              }
            }
          }}
        />

        <div className="w-full flex items-start space-x-2">
          <div className="w-full">
            <TextArea
              label="중식"
              placeholder="내용을 입력해주세요"
              style={{ borderWidth: '1px' }}
              value={lunch}
              onChange={(e) => setLunch(e.target.value)}
            />
          </div>
          <div className="w-full">
            <TextArea
              label="전달사항"
              placeholder="내용을 입력해주세요"
              style={{ borderWidth: '1px' }}
              value={dinner}
              onChange={(e) => setDinner(e.target.value)}
            />
          </div>
        </div>
      </Section>
      <div className="text-center mt-6">
        <Button
          text="저장하기"
          tw={{
            backgroundColor: 'bg-brand-1',
            height: 'h-11',
          }}
          style={{ width: '70%' }}
          onClick={() => {
            setLoading(true);
            canteenData ? updateCanteen() : createCanteen();
          }}
        />
      </div>
    </div>
  );
};
