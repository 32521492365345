import React, { FC, useState } from 'react';
import { useQuery as uQ } from 'react-query';
import {
  Blank,
  Button,
  Checkbox,
  Divider,
  Label,
  Section,
  TextField,
  useMutation,
} from '../../../reusable';
import { ReactComponent as Close } from '../../../assets/svg/Close_Icon.svg';
import { CREATE_GROUP, UPDATE_GROUP } from '../../../query';
import { Group, StudentGroup } from '../../../types';
import { ErrorBlank, SelectMenus } from '../../../components';
import { fetcher } from '../../../plugins';

interface GroupAddPageProps {
  groupIds: number[];
  refetch: () => void;
  groupData?: Group;
}

export const GroupAddPage: FC<GroupAddPageProps> = ({
  groupIds,
  refetch,
  groupData,
}) => {
  const { error, data } = uQ(`/groups?ids=${groupIds}`, fetcher);

  const groups = data?.groupsByIds?.slice().sort((a: any, b: any) => {
    const aData = a?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);
    const bData = b?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);

    if (!aData || !bData) {
      return 0;
    }

    if (aData[1] && bData[1]) {
      if (aData[1] === bData[1]) {
        return Number(aData[2]) - Number(bData[2]);
      } else {
        return Number(aData[1]) - Number(bData[1]);
      }
    } else {
      return 0;
    }
  });

  const subjectData =
    groupData?.teacherGroups &&
    groupData?.teacherGroups
      ?.slice()
      ?.sort((a: any, b: any) => (a?.id && b?.id ? b.id - a.id : 0))[0]
      ?.subject;
  const klassGroups = data?.groupsByIds
    ?.filter((el: Group) => el.type === 'KLASS')
    ?.slice()
    .sort((a: any, b: any) => {
      const aData = a?.name?.match(
        `([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`,
      );
      const bData = b?.name?.match(
        `([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`,
      );

      if (!aData || !bData) {
        return 0;
      }

      if (aData[1] && bData[1]) {
        if (aData[1] === bData[1]) {
          return Number(aData[2]) - Number(bData[2]);
        } else {
          return Number(aData[1]) - Number(bData[1]);
        }
      } else {
        return 0;
      }
    });

  const [selectedGroup, setSelectedGroup] = useState<Group>(
    groups ? groups[0] : null,
  );

  const [selectedYear] = useState<any>(new Date().getFullYear().toString());

  const [subject, setSubject] = useState<any>(subjectData);

  const [selectedUsers, setSelectedUsers] = useState<any[]>(
    groupData?.studentGroups
      ? groupData.studentGroups.map((sg: StudentGroup) => sg.user)
      : [],
  );

  const [name, setName] = useState(groupData ? groupData.name : '');
  const [isLoading, setLoading] = useState(false);

  const [createGroup] = useMutation(CREATE_GROUP, {
    variables: {
      name,
      data: JSON.stringify({
        users: selectedUsers,
        year: selectedYear,
        subject,
      }),
    },
    onCompleted: async () => {
      await refetch();
      await setLoading(false);
    },
  });

  const [updateGroup] = useMutation(UPDATE_GROUP, {
    variables: {
      id: groupData?.id,
      name,
      data: JSON.stringify({
        users: selectedUsers,
        year: selectedYear,
        subject,
      }),
    },
    onCompleted: async () => {
      await refetch();
      await setLoading(false);
    },
  });

  const userIds = selectedUsers.map((el) => el.id);

  // if (loading) return <LoadingScreen />;

  if (error) return <ErrorBlank />;

  return (
    <div className="p-4 h-screen overflow-auto bg-gray-50">
      {isLoading && <Blank />}
      <Section>
        <TextField
          label="그룹명"
          placeholder="그룹명을 입력해주세요."
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <TextField label="년도" value={selectedYear} disabled />
        <TextField
          label="과목"
          placeholder="현재 접속한 사용자가 이 과목으로 해당 그룹에 소속되게 됩니다."
          onChange={(e) => setSubject(e.target.value)}
          value={subject}
        />
      </Section>

      <Divider />
      <Section>
        <div className="w-36">
          <SelectMenus
            items={klassGroups}
            value={klassGroups ? selectedGroup : null}
            allText="선택하기"
            onChange={(group: Group) => {
              setSelectedGroup(group);
            }}
          />
        </div>
      </Section>
      <div className="w-full px-3 py-2">
        {!!selectedGroup?.studentGroups?.length && (
          <Checkbox
            label="전체 선택"
            checked={
              !selectedGroup?.studentGroups?.filter(
                (el) => !userIds.includes(el.user?.id),
              ).length
            }
            onChange={() =>
              !selectedGroup?.studentGroups?.filter(
                (el) => !userIds.includes(el.user?.id),
              ).length
                ? setSelectedUsers(
                    selectedUsers.filter(
                      (el) =>
                        !selectedGroup?.studentGroups
                          ?.map((sg) => sg.user?.id)
                          .includes(el.id),
                    ),
                  )
                : setSelectedUsers(
                    selectedUsers.concat(
                      selectedGroup?.studentGroups
                        ?.filter(
                          (el) =>
                            !selectedUsers
                              .map((u) => u.id)
                              .includes(el.user?.id),
                        )
                        .map((el) => el.user) || [],
                    ),
                  )
            }
          />
        )}
      </div>
      <div className="w-full grid grid-flow-row grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 pr-4 pb-4">
        {selectedGroup?.studentGroups
          ?.slice()
          ?.sort((a: any, b: any) => a.studentNumber - b.studentNumber)
          ?.map((el: StudentGroup, i: number) => (
            <div
              className={`w-full border-2 py-3 px-3 m-2 rounded-lg cursor-pointer flex items-center justify-between ${
                userIds.includes(el.user?.id)
                  ? 'border-brand-1 bg-light_orange'
                  : ' border-grey-6'
              }`}
              key={el.id}
              onClick={() => {
                if (el?.user) {
                  if (userIds.includes(el.user.id)) {
                    setSelectedUsers(
                      selectedUsers.filter((u) => u.id !== el.user?.id),
                    );
                  } else {
                    setSelectedUsers(selectedUsers.concat(el.user));
                  }
                }
              }}
            >
              {selectedGroup.type === 'KLASS' && (
                <div className="font-bold">{el.studentNumber}</div>
              )}
              <div className="font-base whitespace-pre overflow-hidden">
                {el.user?.name}
              </div>
            </div>
          ))}
      </div>
      <Divider />
      <Section>
        <div className="">
          <Label text="선택된 사용자들" />
          <div className="flex flex-wrap mt-2">
            {selectedUsers.map((el) => (
              <div
                className="flex items-center space-x-2 w-max rounded-full px-2.5 py-1.5 m-1s mr-2 mt-2 bg-white border-2 border-brand-1 text-brand-1 whitespace-nowrap text-2sm font-bold cursor-pointer"
                onClick={() => {
                  setSelectedUsers(selectedUsers.filter((u) => u.id !== el.id));
                }}
              >
                <div className="whitespace-pre">{el.name}</div>
                <Close />
              </div>
            ))}
          </div>
        </div>
        {/* <div className="">
          <Label text="1학년 1반" />
          <HorizontalScrollView>
            {['김가은', '황시안', '길주하', '설해윤'].map((el) => (
              <div className="flex items-center space-x-2 px-3 py-2 rounded-full bg-white border-2 border-brand-1 whitespace-nowrap text-2sm text-brand-1 font-bold cursor-pointer">
                <div className="whitespace-pre">{el}</div>
                <Close />
              </div>
            ))}
          </HorizontalScrollView>
        </div>
        <div className="">
          <Label text="1학년 1반" />
          <HorizontalScrollView>
            {['김가은', '황시안', '길주하', '설해윤'].map((el) => (
              <div className="flex items-center space-x-2 px-3 py-2 rounded-full bg-white border-2 border-brand-1 whitespace-nowrap text-2sm text-brand-1 font-bold cursor-pointer">
                <div className="whitespace-pre">{el}</div>
                <Close />
              </div>
            ))}
          </HorizontalScrollView>
        </div> */}
      </Section>
      <div className="text-center mt-6">
        <Button
          text="등록하기"
          tw={{
            height: 'h-11',
            backgroundColor:
              !name || !selectedUsers.length ? 'bg-gray-300' : 'bg-brand-1',
          }}
          disabled={!name || !selectedUsers.length}
          style={{ width: '70%' }}
          onClick={() => {
            setLoading(true);
            groupData ? updateGroup() : createGroup();
          }}
        />
      </div>
    </div>
  );
};
