/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { useQuery as uQ } from 'react-query';
import SignaturePad from 'signature_pad';
import {
  BackButton,
  Blank,
  Button,
  Section,
  TextField,
  TopNavbar,
  useMutation,
  useQueryString,
  useQueryStringForCSV,
} from '../../../reusable';
import {
  APPROVE_ABSENT,
  APPROVE_MULTIPLE_ABSENTS,
  SUBMIT_ABSENT_BY_TEACHER,
  SUBMIT_NICE_ABSENT_BY_TEACHER,
  UPDATE_STAMP,
} from '../../../query';
import { Absent } from '../../../types';
import {
  AbsentCard,
  SelectMenus,
  FrontPagination,
  AttendeeInfoDownloadView,
  AbsentsExcelDownloadView,
  ErrorBlank,
  SuperModal,
  AbsentsDownloadView,
} from '../../../components';
import AbsentDetailPage from './AbsentDetailPage';
import {
  getEndDate,
  getStartDate,
  isValidDate,
  makeDateToString,
  MonthAgo,
} from '../../../utils';
import { fetcher } from '../../../plugins';
import { ReactComponent as DescImg } from '../../../assets/svg/icon-chevron-up.svg';
import { ReactComponent as AscImg } from '../../../assets/svg/icon-chevron-down.svg';
import { ReactComponent as UploadIcon } from '../../../assets/svg/icon-upload.svg';
import { useHistory } from 'react-router-dom';

const filters = [
  { id: 1, name: '모두', value: 'ALL' },
  { id: 2, name: '승인 전', value: 'BEFORE_APPROVAL' },
  { id: 3, name: '승인 완료', value: 'PROCESSED' },
  { id: 4, name: '반려됨', value: 'RETURNED' },
  { id: 5, name: '외부서류 미제출', value: 'UNSUBMITTED' },
  { id: 6, name: '외부서류 제출', value: 'SUBMITTED' },
];

let sigPad: any = null;

export const AbsentPage: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [startDate, setStartDate] = useState(
    makeDateToString(MonthAgo(new Date())),
  );
  const [endDate, setEndDate] = useState(makeDateToString(new Date()));
  const [sigPadData, setSigPadData] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [agreeAll, setAgreeAll] = useState(false);
  const [absentId, setAbsentId] = useState(0);
  const [_studentName, set_studentName] = useState('');
  const [filter, setFilter] = useState<any>(filters[0]);
  const [sorts, setSorts] = useState<any>();
  const [stampMode, setStampMode] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<any>();
  const [isAtdData, setAtdData] = useState<boolean>(false);
  const [isCsvData, setCsvData] = useState<boolean>(false);

  const [page, setPage] = useState(Number(params.get('page') ?? '1'));
  const limit = Number(params.get('limit') ?? '25') || 25;
  const studentName = params.get('username') || '';

  useEffect(() => {
    setPage(Number(params.get('page') ?? '1'));
  }, [params]);

  const queryString = useQueryString({
    filter: {
      startDate: getStartDate(startDate),
      endDate: getEndDate(endDate),
      absentStatus: filter?.value === 'ALL' ? undefined : filter.value,
      selectedGroupId: selectedGroup?.id,
      ...(studentName && { username: studentName }),
    },
    sort: sorts,
    page: page,
    limit,
    defaultLimit: limit,
  });

  const { error, data, refetch } = uQ(
    `/absents/teacher${queryString}`,
    fetcher,
    { refetchOnWindowFocus: false },
  );

  const approvalLine = data?.school?.approvalLine;

  const atdQueryString = useQueryString({
    filter: {
      startDate: getStartDate(startDate),
      endDate: getEndDate(endDate),
      absentStatus: filter?.value === 'ALL' ? undefined : filter.value,
      username: studentName,
      selectedGroupId: selectedGroup?.id,
    },
    sort: sorts,
    page: page,
    limit,
    defaultLimit: limit,
  });

  const { isLoading: isAtdDataLoading, data: atdDatas } = uQ(
    `/absents/teacher/atd${atdQueryString}`,
    fetcher,
    { refetchOnWindowFocus: false, enabled: isAtdData },
  );

  const csvQueryString = useQueryStringForCSV({
    filter: {
      startDate: getStartDate(startDate),
      endDate: getEndDate(endDate),
      absentStatus: filter?.value === 'ALL' ? undefined : filter.value,
      username: studentName,
      selectedGroupId: selectedGroup?.id,
    },
    sort: sorts,
  });

  const { isLoading: isCsvDataLoading, data: csvDatas } = uQ(
    `/absents/teacher/csv${csvQueryString}`,
    fetcher,
    { refetchOnWindowFocus: false, enabled: isCsvData },
  );

  const studentGroups = data?.studentGroups;
  const groups =
    data?.me.headNumber !== 0
      ? data?.groups?.filter((item: any) =>
          item.name.toString().startsWith(data?.me.headNumber),
        )
      : data?.groups?.sort(
          (a: any, b: any) => a.name.substr(0, 1) - b.name.substr(0, 1),
        );

  const clearSignature = () => {
    sigPad && sigPad.clear();
    setSigPadData('');
    const canvas = document.querySelector('canvas');
    const ctx = canvas?.getContext('2d');
    ctx && ctx.clearRect(0, 0, canvas?.width || 0, canvas?.height || 0);
    ctx && ctx.beginPath();
  };

  const absents = data?.absents;
  const me = data?.me;

  const { data: teacherData } = uQ(`/users/teacherHomeKlass`, fetcher);

  // 조회 권한 여부
  let isViewAuth = false;
  if (teacherData?.user?.role === 'SECURITY') {
    isViewAuth = false;
  } else {
    if (teacherData?.user?.role === 'TEACHER') {
      isViewAuth = teacherData?.user.teacherGroups.length !== 0 ? true : false;
    } else {
      isViewAuth = true;
    }
  }
  // 승인 권한 여부
  let isApprovalAuth = false;
  for (const index in approvalLine) {
    if (teacherData?.user?.role === approvalLine[index]) {
      if (teacherData?.user?.role === 'TEACHER') {
        isApprovalAuth =
          teacherData?.user.teacherGroups.length !== 0 ? true : false;
      } else {
        isApprovalAuth = true;
      }
    }
  }

  useEffect(() => {
    if (document.querySelector('canvas')) {
      sigPad =
        //@ts-ignore
        new SignaturePad(document.querySelector('canvas'), {
          onEnd: () => {
            setSigPadData(sigPad.toDataURL());
          },
        });
    }
  }, []);

  const stamp = me?.stamp;
  const preSignedStamp =
    me?.stamp && process.env.REACT_APP_API_URL + '/images?url=' + me?.stamp;

  const AtdnceCsvData = [['학급별출결현황']];

  AtdnceCsvData.push([
    '기간',
    makeDateToString(new Date(startDate)),
    '~',
    makeDateToString(new Date(endDate)),
  ]);

  const [approveAbsents] = useMutation(APPROVE_MULTIPLE_ABSENTS, {
    variables: {
      data: JSON.stringify({
        absentIds: absents?.items.map((a: Absent) => a.id),
        signature: stampMode ? stamp : sigPadData,
      }),
    },
    onCompleted: () => {
      setOpen(false);
      clearSignature();
      setLoading(false);
      refetch();
    },
  });

  const [approveAbsent] = useMutation(APPROVE_ABSENT, {
    variables: { absentId, signature: stampMode ? stamp : sigPadData },
    onCompleted: () => {
      setOpen(false);
      clearSignature();
      setLoading(false);
      refetch();
    },
  });

  const [updateStamp] = useMutation(UPDATE_STAMP, {
    onCompleted: async () => {
      await refetch();
      await setLoading(false);
    },
    onError: () => {
      setLoading(false);
      alert(
        '스탬프 이미지를 업로드하던 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
      );
    },
  });

  const [submitAbsent] = useMutation(SUBMIT_ABSENT_BY_TEACHER, {
    onCompleted: () => {
      refetch();
    },
  });

  const [submitNiceAbsent] = useMutation(SUBMIT_NICE_ABSENT_BY_TEACHER, {
    onCompleted: () => {
      refetch();
    },
  });

  const { pathname } = useLocation();
  const isDetail = !pathname.endsWith('/teacher/absent');

  const [isStampImageLoaded, setIsStampImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setIsStampImageLoaded(true);
    img.onerror = () => setIsStampImageLoaded(false);
    img.src = preSignedStamp;
  }, [preSignedStamp]);

  const searchAlert = () => {
    const confirmed = window.confirm(
      '승인 전 상태의 내용만 일괄 승인이 가능합니다. \n승인 전 상태인 건들을 조회하시겠습니까?',
    );
    if (confirmed) {
      setFilter(filters[1]);
    }
  };

  return (
    <>
      {error && <ErrorBlank />}
      {isLoading && <Blank reversed />}
      {isCsvDataLoading && <Blank reversed />}
      {isAtdDataLoading && <Blank reversed />}

      {/* Mobile V */}
      <div className={`md:hidden ${isDetail ? 'hidden' : 'block'}`}>
        <TopNavbar title="출결신고서" left={<BackButton />} />
        <div className="px-6 py-6 overflow-x-scroll scroll-box flex flex-col space-y-2">
          <div className="flex items-center justify-between">
            <div
              className="text-lg text-brand-1 cursor-pointer"
              onClick={async () => {
                await setLoading(true);
                await refetch();
                await setLoading(false);
              }}
            >
              새로고침
            </div>
          </div>
          <div className="flex space-x-3 mb-4 items-center">
            <TextField
              className="cursor-pointer"
              type="date"
              value={makeDateToString(new Date(startDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (endDate && selectedDate > new Date(endDate)) {
                  setEndDate(e.target.value);
                }
                setStartDate(e.target.value);
                setPage(1);
              }}
            />
            <div className="font-bold text-xs">~</div>
            <TextField
              className="cursor-pointer"
              type="date"
              value={makeDateToString(new Date(endDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (startDate && selectedDate < new Date(startDate)) {
                  setStartDate(e.target.value);
                }
                setEndDate(e.target.value);
                setPage(1);
              }}
            />
          </div>

          {data &&
            (me?.role === 'PREHEAD' ||
              me?.role === 'HEAD' ||
              me?.role === 'PREPRINCIPAL' ||
              me?.role === 'PRINCIPAL' ||
              me?.role === 'VICEPRINCIPAL' ||
              me?.role === 'HEADPRINCIPAL' ||
              me?.role === 'SECURITY') && (
              <>
                <div className="flex space-x-3 items-center">
                  <div className="w-full cursor-pointer">
                    <SelectMenus
                      items={filters}
                      value={filters ? filter : null}
                      onChange={(group: any) => setFilter(group)}
                    />
                  </div>

                  <div className="w-full cursor-pointer">
                    <SelectMenus
                      allText="반"
                      items={groups}
                      value={selectedGroup}
                      onChange={(group: any) => setSelectedGroup(group)}
                    />
                  </div>
                </div>
              </>
            )}
          <div className="flex space-x-3 items-center">
            {data &&
              me?.role === 'ADMIN' &&
              me?.role === 'TEACHER' &&
              me?.role === 'USER' &&
              me?.role === 'PARENT' && (
                <div className="min-w-min cursor-pointer">
                  <SelectMenus
                    items={filters}
                    value={filters ? filter : null}
                    onChange={(group: any) => setFilter(group)}
                  />
                </div>
              )}

            <div className="flex items-center space-x-2 w-full">
              <form className="items-center border-gray-200 rounded-full border flex justify-between h-10 px-4 w-full">
                <input
                  className="w-full"
                  placeholder="학생이름을 입력해주세요."
                  value={_studentName}
                  onChange={(e) => {
                    set_studentName(e.target.value);
                    if (e.target.value === '') {
                      history.replace(`/teacher/absent`);
                    }
                    setPage(1);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (_studentName) {
                        history.replace(
                          `/teacher/absent?username=${_studentName}`,
                        );
                      }
                    }
                  }}
                />
              </form>
              <UploadIcon
                onClick={() => {
                  if (_studentName === '') {
                    alert('텍스트 내용을 입력해주세요.');
                  } else {
                    history.replace(`/teacher/absent?username=${_studentName}`);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        <table className="w-full  table-fixed">
          <tr className="text-center bg-gray-100 ">
            <td colSpan={8}>세부 내용</td>
            <td colSpan={3}>승인 절차</td>
            <td colSpan={2} align="center">
              <button
                className="min-w-max flex flex-row justify-center cursor-pointer"
                onClick={() =>
                  setSorts(
                    sorts?.NICE
                      ? sorts?.NICE === 'DESC'
                        ? { NICE: 'ASC' }
                        : ''
                      : { NICE: 'DESC' },
                  )
                }
              >
                나이스
                {sorts?.NICE ? (
                  sorts?.NICE === 'DESC' ? (
                    <DescImg />
                  ) : (
                    <AscImg />
                  )
                ) : (
                  <></>
                )}
              </button>
            </td>
            <td colSpan={3} align="center">
              <button
                className="min-w-max  flex flex-row justify-center cursor-pointer"
                onClick={() =>
                  setSorts(
                    sorts?.SUBMIT
                      ? sorts?.SUBMIT === 'DESC'
                        ? { SUBMIT: 'ASC' }
                        : ''
                      : { SUBMIT: 'DESC' },
                  )
                }
              >
                증빙 서류
                {sorts?.SUBMIT ? (
                  sorts?.SUBMIT === 'DESC' ? (
                    <DescImg />
                  ) : (
                    <AscImg />
                  )
                ) : (
                  <></>
                )}
              </button>
            </td>
          </tr>
        </table>

        <div className="h-screen-22 overflow-y-auto scroll-box">
          {absents?.items.map((absent: Absent) => (
            <AbsentCard
              userRole={me?.role}
              absent={absent}
              submitAbsent={submitAbsent}
              submitNiceAbsent={submitNiceAbsent}
              page={page}
              limit={limit}
            />
          ))}
        </div>
        {absents?.total > limit && (
          <div className="grid place-items-center min-w-max">
            <FrontPagination
              basePath="/teacher/absent"
              total={absents?.total}
              limit={limit}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
      </div>
      <div
        className={`bg-gray-50 block md:hidden ${
          isDetail ? 'h-full' : 'col-span-3 p-6'
        } scroll-box`}
      >
        <Switch>
          <Route
            path={`/teacher/absent/:id`}
            component={() => (
              <AbsentDetailPage
                userRole={me?.role}
                refetch={() => refetch()}
                setOpen={(b: boolean) => setOpen(b)}
                setAbsentId={(n: number) => setAbsentId(n)}
                setAgreeAll={(b: boolean) => setAgreeAll(b)}
                approvalLine={approvalLine}
              />
            )}
          />
        </Switch>
      </div>

      {/* Desktop V */}
      <div className="col-span-3 h-screen overflow-y-auto hidden md:block">
        <div className="px-6 py-6 overflow-x-scroll scroll-box flex flex-col">
          <div className="flex items-center justify-between ">
            <h1 className="text-2xl font-semibold">출결신고서</h1>
            <div
              className="text-lg text-brand-1 cursor-pointer"
              onClick={async () => {
                await setLoading(true);
                await refetch();
                await setLoading(false);
              }}
            >
              새로고침
            </div>
          </div>
          <div className="text-sm mb-5 text-grey-5">
            ※ 구,결석계 / 조퇴,외출,결과,지각,결석 후 작성 서류
          </div>
          <div className="flex space-x-3 mb-2 items-center">
            <TextField
              type="date"
              value={makeDateToString(new Date(startDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (endDate && selectedDate > new Date(endDate)) {
                  setEndDate(e.target.value);
                }
                setStartDate(e.target.value);
              }}
            />
            <div className="px-4 font-bold text-xl">~</div>
            <TextField
              type="date"
              value={makeDateToString(new Date(endDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (startDate && selectedDate < new Date(startDate)) {
                  setStartDate(e.target.value);
                }
                setEndDate(e.target.value);
              }}
            />
          </div>
          <div className="flex space-x-3 items-center mb-2">
            <div className="min-w-min cursor-pointer">
              <SelectMenus
                allText="모두"
                items={filters}
                value={filters ? filter : null}
                onChange={(group: any) => setFilter(group)}
              />
            </div>

            {data &&
              (me?.role === 'PREHEAD' ||
                me?.role === 'HEAD' ||
                me?.role === 'PREPRINCIPAL' ||
                me?.role === 'PRINCIPAL' ||
                me?.role === 'VICEPRINCIPAL' ||
                me?.role === 'HEADPRINCIPAL' ||
                me?.role === 'SECURITY') && (
                <>
                  <div className="min-w-max cursor-pointer">
                    <SelectMenus
                      allText="반"
                      items={groups}
                      value={selectedGroup}
                      onChange={(group: any) => setSelectedGroup(group)}
                    />
                  </div>
                </>
              )}
            <div className="flex items-center space-x-2 w-full cursor-pointer">
              <form
                className="items-center border-gray-200 rounded-full border flex justify-between h-10 px-4 w-full"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  className="w-full"
                  placeholder="학생이름을 입력해주세요."
                  value={_studentName}
                  onChange={(e) => {
                    set_studentName(e.target.value);
                    if (e.target.value === '') {
                      history.replace(`/teacher/absent`);
                    }
                    setPage(1);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (_studentName) {
                        history.replace(
                          `/teacher/absent?username=${_studentName}`,
                        );
                      }
                    }
                  }}
                />
              </form>
              <UploadIcon
                onClick={() => {
                  if (_studentName === '') {
                    alert('텍스트 내용을 입력해주세요.');
                  } else {
                    history.replace(`/teacher/absent?username=${_studentName}`);
                  }
                }}
              />
            </div>
          </div>

          {
            <div className="flex space-x-3 items-end w-full">
              <div className="w-full">
                {/* <div className="w-full text-center text-gray-500">
                결석계 일괄 다운로드
              </div> */}
                <div className="flex space-x-3 items-center w-full">
                  <AbsentsDownloadView
                    nowDate={`${makeDateToString(
                      new Date(startDate),
                    )}~${makeDateToString(new Date(endDate))}`}
                    absents={csvDatas
                      ?.slice()
                      .filter((absent: Absent) =>
                        selectedGroup
                          ? absent?.studentGradeKlass === selectedGroup?.name &&
                            absent?.absentStatus === 'PROCESSED' &&
                            isViewAuth
                          : absent?.absentStatus === 'PROCESSED' && isViewAuth,
                      )
                      .sort(
                        (a: Absent, b: Absent) =>
                          new Date(a?.startAt || '').getTime() -
                          new Date(b?.startAt || '').getTime(),
                      )}
                    setCsvData={(b: boolean) => setCsvData(b)}
                    isCsvData={isCsvData}
                    approvalLine={approvalLine}
                  />
                  <AbsentsExcelDownloadView
                    startDate={startDate}
                    endDate={endDate}
                    absents={csvDatas}
                    setCsvData={(b: boolean) => setCsvData(b)}
                    isCsvData={isCsvData}
                  />
                </div>
              </div>

              <div className="flex space-x-3 items-center w-full">
                <AttendeeInfoDownloadView
                  startDate={startDate}
                  endDate={endDate}
                  absents={atdDatas}
                  groups={groups}
                  selectedGroup={selectedGroup}
                  studentGroups={studentGroups}
                  userRole={me?.role || ''}
                  setAtdData={(b: boolean) => setAtdData(b)}
                  isAtdData={isAtdData}
                />
                <Button
                  text="일괄 승인하기"
                  tw={{
                    minWidth: 'min-w-max',
                    paddingY: 'py-2.5',
                    height: 'h-auto',
                    paddingX: 'px-4',
                    width: 'w-full',
                  }}
                  onClick={() => {
                    if (isApprovalAuth) {
                      if (filter.value === 'BEFORE_APPROVAL') {
                        setOpen(true);
                        setAgreeAll(true);
                      } else {
                        searchAlert();
                      }
                    } else {
                      alert('권한이 없습니다.');
                    }
                  }}
                />
              </div>
            </div>
          }
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        {!isViewAuth && !isApprovalAuth && (
          <div className="text-center">권한이 없습니다.</div>
        )}
        {isViewAuth && (
          <table className="w-full  table-fixed">
            <tr className="text-center bg-gray-100">
              <td colSpan={8}>세부 내용</td>
              <td colSpan={3}>승인 절차</td>
              <td colSpan={2} align="center">
                <button
                  className="min-w-max flex flex-row justify-center"
                  onClick={() =>
                    setSorts(
                      sorts?.NICE
                        ? sorts?.NICE === 'DESC'
                          ? { NICE: 'ASC' }
                          : ''
                        : { NICE: 'DESC' },
                    )
                  }
                >
                  나이스
                  {sorts?.NICE ? (
                    sorts?.NICE === 'DESC' ? (
                      <DescImg />
                    ) : (
                      <AscImg />
                    )
                  ) : (
                    <></>
                  )}
                </button>
              </td>
              <td colSpan={3} align="center">
                <button
                  className="min-w-max  flex flex-row justify-center"
                  onClick={() =>
                    setSorts(
                      sorts?.SUBMIT
                        ? sorts?.SUBMIT === 'DESC'
                          ? { SUBMIT: 'ASC' }
                          : ''
                        : { SUBMIT: 'DESC' },
                    )
                  }
                >
                  증빙 서류
                  {sorts?.SUBMIT ? (
                    sorts?.SUBMIT === 'DESC' ? (
                      <DescImg />
                    ) : (
                      <AscImg />
                    )
                  ) : (
                    <></>
                  )}
                </button>
              </td>
            </tr>
          </table>
        )}
        {isViewAuth && (
          <div className="h-screen-20 overflow-y-auto scroll-box">
            {absents?.items.map((absent: Absent) => (
              <AbsentCard
                userRole={me?.role}
                absent={absent}
                submitAbsent={submitAbsent}
                submitNiceAbsent={submitNiceAbsent}
                page={page}
                limit={limit}
              />
            ))}
          </div>
        )}
        {absents?.total > limit && (
          <div className="grid place-items-center">
            <FrontPagination
              basePath="/teacher/absent"
              total={absents?.total}
              limit={limit}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
      </div>
      <div className="hidden md:block col-span-3 bg-gray-50 p-6 overflow-y-auto scroll-box">
        <Switch>
          <Route
            path={`/teacher/absent/:id`}
            component={() => (
              <AbsentDetailPage
                userRole={me?.role}
                refetch={() => refetch()}
                setOpen={(b: boolean) => setOpen(b)}
                setAbsentId={(n: number) => setAbsentId(n)}
                setAgreeAll={(b: boolean) => setAgreeAll(b)}
                approvalLine={approvalLine}
              />
            )}
          />
        </Switch>
      </div>
      <SuperModal
        modalOpen={open}
        setModalClose={() => {
          clearSignature();
          setOpen(false);
        }}
        width="w-max"
        ablePropragation
      >
        <Section tw={{ marginTop: 'mt-7' }}>
          <div>
            <div className="text-gray-700 text-xl font-bold">서명란</div>
            <div className="text-gray-500">아래 네모칸에 서명을 해주세요.</div>
          </div>
          <div className="relative">
            <canvas
              width={
                window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6
              }
              height={
                window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4
              }
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            {stampMode ? (
              isStampImageLoaded ? (
                <div
                  className="absolute inset-0 z-10 rounded overflow-hidden bg-center bg-no-repeat bg-contain "
                  style={{
                    backgroundImage: `url("${preSignedStamp}")`,
                  }}
                ></div>
              ) : (
                <div className="absolute inset-0 z-10 rounded overflow-hidden bg-grey-4">
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="min-w-max text-center">
                      도장을 등록해주세요.
                    </div>
                  </div>
                </div>
              )
            ) : (
              ''
            )}
          </div>
          <div className="flex items-center justify-center space-x-2">
            <label htmlFor="stamp-upload" className="w-1/2">
              <div className="w-full rounded-lg font-bold h-13 px-6 justify-center flex items-center bg-white border border-brandblue-1 text-current cursor-pointer">
                도장등록
              </div>
            </label>
            <input
              id="stamp-upload"
              name="stamp-upload"
              type="file"
              className="sr-only"
              accept=".png, .jpeg, .jpg"
              onChange={(e) => {
                if (e.target.validity.valid && e.target.files?.length != 0) {
                  setLoading(true);
                  updateStamp({
                    variables: { image: e.target.files?.item(0) },
                  });
                  setStampMode(true);
                }
              }}
            />
            {!stampMode ? (
              <Button
                tw={{
                  width: 'w-1/2',
                  backgroundColor: 'bg-brandblue-1',
                }}
                onClick={async () => {
                  setStampMode(true);
                  clearSignature();
                }}
              >
                도장 사용하기
              </Button>
            ) : (
              <Button
                tw={{
                  width: 'w-1/2',
                  backgroundColor: 'bg-brandblue-1',
                  borderColor: 'border-red-500',
                  borderWidth: 'border-4',
                }}
                onClick={async () => {
                  await setLoading(true);
                  if (!isStampImageLoaded) {
                    alert('도장이 등록되어 있지 않습니다.');
                    await setLoading(false);
                  } else {
                    if (agreeAll) {
                      await approveAbsents();
                    } else {
                      await approveAbsent();
                    }
                    await setStampMode(false);
                  }
                }}
              >
                도장으로 승인
              </Button>
            )}
          </div>
          <div className="flex items-center justify-center space-x-2">
            <Button
              tw={{
                backgroundColor: 'bg-white',
                borderWidth: 'border',
                borderColor: 'border-brand-1',
                color: 'text-current',
                width: 'w-full',
                minWidth: 'min-w-max',
              }}
              onClick={() => {
                clearSignature();
                setStampMode(false);
              }}
            >
              서명 다시하기
            </Button>
            {stampMode ? (
              <Button
                tw={{
                  width: 'w-full',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  backgroundColor: 'bg-brand-1',
                  minWidth: 'min-w-max',
                }}
                onClick={() => setStampMode(false)}
              >
                서명 사용하기
              </Button>
            ) : (
              <Button
                tw={{
                  width: 'w-full',
                  minWidth: 'min-w-max',
                  borderWidth: sigPadData ? 'border-4' : '',
                  borderColor: 'border-green-500',
                  backgroundColor: sigPadData ? 'bg-brand-1' : 'bg-brand-5',
                }}
                onClick={() => {
                  if (!sigPadData) {
                    alert('서명 후 승인해 주세요.');
                  } else {
                    setLoading(true);
                    agreeAll ? approveAbsents() : approveAbsent();
                  }
                }}
              >
                서명으로 승인
              </Button>
            )}
          </div>
        </Section>
      </SuperModal>
    </>
  );
};
