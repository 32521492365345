import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import { jsPDF } from 'jspdf';
import {
  Blank,
  Section,
  Button,
  TextArea,
  useMutation,
} from '../../../reusable';
import { DENY_FIELDTRIP, DELETE_APPREAL_FIELDTRIP } from '../../../query';
import { School, User } from '../../../types';
import {
  makeDateToString,
  makeStartEndToString,
  makeTimeToString,
  extractReactData,
} from '../../../utils';
import {
  FieldtripPaper,
  SuperModal,
  FieldtripSeparatePaper,
  ErrorBlank,
} from '../../../components';
import FieldtripUpdatePage from './FieldtripUpdatePage';
import { fetcher } from '../../../plugins';

interface MatchParams {
  id: string;
}
interface FieldtripDetailPageProps extends RouteComponentProps<MatchParams> {
  school: School;
  refetch: () => void;
  setOpen: (b: boolean) => void;
  setFieldtripId: (n: number) => void;
  setAgreeAll: (b: boolean) => void;
  me: User;
  approvalLine?: string[];
}

const calculateStatus = (role: string, order: 'before' | 'after' | 'show') => {
  const roles = ['TEACHER', 'PREHEAD', 'HEAD', 'PREPRINCIPAL','PRINCIPAL','VICEPRINCIPAL','HEADPRINCIPAL'];
  const _fieldtripStatus = [
    'BEFORE_TEACHER_APPROVAL',
    'TEACHER',
    'BEFORE_PREHEAD_APPROVAL',
    'PREHEAD',
    'BEFORE_HEAD_APPROVAL',
    'HEAD',
    'BEFORE_PREPRINCIPAL_APPROVAL',
    'PREPRINCIPAL',
    'BEFORE_PRINCIPAL_APPROVAL',
    'PRINCIPAL',
    'BEFORE_VICEPRINCIPAL_APPROVAL',
    'VICEPRINCIPAL',
    'BEFORE_HEADPRINCIPAL_APPROVAL',
    'HEADPRINCIPAL',
    'PROCESSED',
  ];

  if (role === 'SECURITY') return ['PROCESSED'];

  const index = _fieldtripStatus.findIndex((el) => el === role);

  if (index === -1) return [];

  if (order === 'before') {
    return _fieldtripStatus
      .slice(index - 1, index)
      .filter((el) => !roles.includes(el));
  } else if (order === 'after') {
    return _fieldtripStatus.slice(index).filter((el) => !roles.includes(el));
  } else if (order === 'show') {
    return _fieldtripStatus
      .slice(index - 1)
      .filter((el) => !roles.includes(el));
  }
};

const FieldtripDetailPage: React.FC<FieldtripDetailPageProps> = ({
  school,
  refetch: _refetch,
  match,
  setOpen,
  setFieldtripId,
  setAgreeAll,
  me,
  approvalLine,
}) => {
  const history = useHistory();
  const { id } = match.params;
  const ref = useRef(null);
  const separatePaperRefs = useRef<any[]>([]);
  const planRef = useRef(null);

  const [deny, setDeny] = useState(false);
  const [deleteAppeal, setDeleteAppeal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notApprovedReason, setNotApprovedReason] = useState('');
  const [deleteReason, setDeleteReason] = useState('');
  const [clicked, setClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [readState, setReadState] = useState(true);

  const {
    isLoading,
    error,
    data: fieldtrip,
    refetch: __refetch,
  } = uQ(`/fieldtrips/${id}`, fetcher);

  const refetch = () => {
    _refetch();
    __refetch();
  };

  const userRole = me?.role || '';

  useEffect(() => {
    setFieldtripId(Number(id));
  }, [id, setFieldtripId]);

  const [denyFieldtrip] = useMutation(DENY_FIELDTRIP, {
    variables: { fieldtripId: Number(id), notApprovedReason },
    onCompleted: () => {
      setDeny(false);
      setLoading(false);
      refetch();
    },
    onError: (error: any) => {
      setLoading(false);
      setErrorMessage(error?.message);
      refetch();
    },
  });

  const [deleteAppealFieldtrip] = useMutation(DELETE_APPREAL_FIELDTRIP, {
    variables: { fieldtripId: Number(id), deleteReason },
    onCompleted: () => {
      setDeleteAppeal(false);
      setLoading(false);
      refetch();
    },
    onError: (error: any) => {
      setLoading(false);
      setErrorMessage(error?.message);
      refetch();
    },
  });
  let isConfirmed =
    calculateStatus(userRole, 'after')?.includes(
      fieldtrip?.fieldtripStatus || '',
    ) || false;

  let homeplans: any = [];

  try {
    if (fieldtrip?.type === 'HOME') {
      const content = JSON.parse(fieldtrip?.content || '[]');
      homeplans = content?.slice(1);
    }
  } catch (err) {
    console.log(err);
  }

  const buttonDisabled = !calculateStatus(userRole, 'before')
    //?.slice(1)
    ?.includes(fieldtrip?.fieldtripStatus || '');

  const isNotApproved = !calculateStatus(userRole, 'after')?.includes(
    fieldtrip?.fieldtripStatus || '',
  );
  

  if (!readState) {
    return (
      <FieldtripUpdatePage
        school={school}
        fieldtrip={fieldtrip}
        refetch={() => refetch()}
        me={me}
        setReadState={() => setReadState(true)}
        isConfirmed={isConfirmed}
      />
    );
  }

  return (
    <div className="bg-white border rounded-lg py-5 h-screen-10">
      {loading && <Blank reversed />}
      {isLoading && <Blank reversed />}
      {errorMessage && <ErrorBlank text={errorMessage} />}

      <div className="w-auto h-full overflow-scroll relative">
        <div className="w-full flex items-center justify-start space-x-2 px-5">
          <div className="text-brand-1 underline cursor-pointer">신청서</div>
          <div
            className="text-brand-1 underline cursor-pointer"
            onClick={() =>
              history.push(`/teacher/fieldtrip/notice/${fieldtrip.id}`)
            }
          >
            통보서
          </div>
          <div
            className="text-brand-1 underline cursor-pointer"
            onClick={() =>
              history.push(`/teacher/fieldtrip/result/${fieldtrip.id}`)
            }
          >
            결과보고서
          </div>
        </div>

        {fieldtrip?.fieldtripStatus === 'RETURNED' &&
          fieldtrip?.notApprovedReason &&
          fieldtrip?.updatedAt && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 mx-5 py-2">
              <div className="text-brand-1">{fieldtrip?.notApprovedReason}</div>
              <div className="text-gray-500 text-sm">
                {makeDateToString(fieldtrip?.updatedAt)}{' '}
                {makeTimeToString(fieldtrip?.updatedAt)}에 마지막으로 수정
              </div>
            </div>
          )}
        {fieldtrip?.updateReason && fieldtrip?.updatedAt && (
          <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2">
            <div className="text-brand-1">{fieldtrip?.updateReason}</div>
            <div className="text-gray-500 text-sm">
              {makeDateToString(fieldtrip?.updatedAt)}{' '}
              {makeTimeToString(fieldtrip?.updatedAt)}에 마지막으로 수정
            </div>
          </div>
        )}

        <div
          ref={ref}
          className="bg-white"
          style={{
            width: '778px',
            height: '1100px',
            padding: '20px',
          }}
        >
          <FieldtripPaper
            school={school}
            fieldtrip={fieldtrip}
            type="신청서"
            approvalLine={approvalLine}
          />
        </div>
        {fieldtrip?.type === 'HOME' && (
          <>
            {homeplans?.map((content: any, i: number) => (
              <div
                ref={(el) => (separatePaperRefs.current[i] = el)}
                className="bg-white"
                style={{
                  width: '760px',
                  height: '1058px',
                  padding: '20px',
                }}
              >
                <FieldtripSeparatePaper
                  studentName={fieldtrip?.student?.name}
                  studentGradeKlass={
                    fieldtrip?.studentGradeKlass +
                    ' ' +
                    fieldtrip?.studentNumber +
                    '번'
                  }
                  fieldtrip={fieldtrip}
                  index={i + 1}
                  content={content}
                  type="신청서"
                />
              </div>
            ))}
          </>
        )}
      </div>
      <div className="flex items-center w-full overflow-x-auto space-x-2 pt-8">
        <Button
          disabled={clicked}
          tw={{
            width: 'w-full',
            backgroundColor: clicked ? 'bg-gray-400' : 'bg-blue-500',
            minWidth: 'min-w-max',
          }}
          onClick={async () => {
            if (ref?.current) {
              setClicked(true);
              let doc: any = new jsPDF('p', 'mm', 'a4');
              //@ts-ignore
              const imgData = await extractReactData(ref.current);

              await doc.addImage(
                imgData,
                'JPEG',
                0,
                0,
                210,
                297,
                undefined,
                'FAST',
              );

              if (planRef?.current) {
                const planImgData = await extractReactData(planRef.current);
                await doc.addPage();
                await doc.addImage(
                  planImgData,
                  'PNG',
                  0,
                  0,
                  210,
                  297,
                  undefined,
                  'FAST',
                );
              }

              for (let ref of separatePaperRefs.current) {
                if (ref) {
                  const paperImgData = await extractReactData(ref);
                  await doc.addPage();
                  await doc.addImage(
                    paperImgData,
                    'PNG',
                    0,
                    0,
                    210,
                    297,
                    undefined,
                    'FAST',
                  );
                }
              }

              await doc.save(
                `체험학습 신청서(${fieldtrip?.student?.name}, ${
                  fieldtrip?.startAt &&
                  fieldtrip?.endAt &&
                  makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)
                }).pdf`,
              );

              setClicked(false);
            }
          }}
        >
          다운로드
        </Button>
        <Button
          tw={{
            width: 'w-full',
            minWidth: 'min-w-max',
            backgroundColor: 'bg-red-500',
          }}
          text="삭제요청"
          onClick={() => {
            setDeleteAppeal(true);
          }}
        />
        <Button
          tw={{
            width: 'w-full',
            minWidth: 'min-w-max',
            backgroundColor: buttonDisabled
              ? 'bg-gray-500'
              : 'bg-lightpurple-4',
          }}
          text="반려"
          disabled={buttonDisabled}
          onClick={() => {
            setDeny(true);
          }}
        />
        {fieldtrip?.fieldtripStatus === 'PROCESSED' || !isNotApproved ?
        <Button
          tw={{ width: 'w-full', minWidth: 'min-w-max' }}
          text={isConfirmed ? '승인 후 수정' : '수정'}
          onClick={() => {
            setReadState(false);
          }}
        />
        :
        <Button
          tw={{ width: 'w-full', minWidth: 'min-w-max' }}
          text={!isNotApproved ? '승인 후 수정' : '수정'}

          onClick={() => {
              setReadState(false);
          }}
          disabled={buttonDisabled}
        />
        }
        <Button
          tw={{
            width: 'w-full',
            minWidth: 'min-w-max',
            backgroundColor: buttonDisabled ? 'bg-gray-500' : 'bg-brand-1',
          }}
          text={buttonDisabled ? 
            (isNotApproved ? '승인 대기' : '승인 완료') 
            : '승인'}
          disabled={buttonDisabled}
          onClick={() => {
            setOpen(true);
            setAgreeAll(false);
          }}
        />
      </div>
      <SuperModal
        modalOpen={deny}
        setModalClose={() => setDeny(false)}
        width="w-max"
      >
        <Section tw={{ marginTop: 'mt-7' }}>
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            이 학생의 체험학습 신청서를 반려하시겠습니까?
          </div>
          <TextArea
            placeholder="반려 이유"
            onChange={(e) => setNotApprovedReason(e.target.value)}
            value={notApprovedReason}
          />
          <Button
            onClick={() => {
              setLoading(true);
              denyFieldtrip();
            }}
          >
            반려하기
          </Button>
        </Section>
      </SuperModal>
      <SuperModal
        modalOpen={deleteAppeal}
        setModalClose={() => setDeleteAppeal(false)}
        width="w-max"
      >
        <Section tw={{ marginTop: 'mt-7' }}>
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            이 체험학습 신청서를 삭제하도록 요청하시겠습니까?
          </div>
          <TextArea
            placeholder="삭제 이유"
            onChange={(e) => setDeleteReason(e.target.value)}
            value={deleteReason}
          />
          <Button
            tw={{ backgroundColor: 'bg-red-500' }}
            onClick={() => {
              setLoading(true);
              deleteAppealFieldtrip();
            }}
          >
            삭제 요청하기
          </Button>
        </Section>
      </SuperModal>
    </div>
  );
};

export default withRouter(FieldtripDetailPage);
