import {
  BackButton,
  Blank,
  Section,
  TopNavbar,
  utcToLocalDatetime,
} from '../../../reusable';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ActivityDetailSubmitPage } from './ActivityDetailSubmitPage';
import { ActivityDetailReadPage } from './ActivityDetailReadPage';
import { ErrorBlank, FileItem } from '../../../components';
import { useQuery as uQ } from 'react-query';
import { fetcher } from '../../../plugins';
import Viewer from 'react-viewer';
import Linkify from 'react-linkify';

interface MatchParams {
  id: string;
}

export const ActivityDetailPage: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  let { id } = match.params;
  const { error, data, refetch } = uQ(`/activities/${id}/detail`, fetcher, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });
  const activity = data?.activity;
  const studentActivity = data?.activity?.studentActivities[0];

  const [readState, setReadState] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const activityEnded =
    activity?.endDate &&
    new Date(activity.endDate).getTime() - new Date().getTime() < 0;

  let defaultReadState = false;

  if (activityEnded) {
    defaultReadState = true;
  } else if (studentActivity?.isSubmitted) {
    defaultReadState = true;
  }

  if (error) return <ErrorBlank />;

  const files: any[] = [];

  if (activity?.files) {
    try {
      activity?.files.map((f: any) => files.push(JSON.parse(f)));
    } catch (err) {
      console.error(err);
    }
  }

  let images: any[] = [];
  try {
    images = activity?.images
      ? activity?.images.map((el: any) => JSON.parse(el).url)
      : [];
  } catch (err) {
    console.error(err);
  }

  const viewerImages = activity?.images?.map((el: any) => ({
    src: JSON.parse(el).url,
    title: JSON.parse(el).name,
  }));

  if (data?.activity?.type === 'NOTICE') {
    return (
      <div>
        {!data && <Blank text="해당하는 데이터가 없습니다." />}
        {isLoading && <Blank />}
        <TopNavbar
          left={
            <div className="h-20">
              <BackButton className="h-15" />
            </div>
          }
          title="활동기록부"
        />

        <Section tw={{ spaceY: 'space-y-2', backgroundColor: 'bg-white' }}>
          <div className="rounded-3xl px-3 py-1 bg-brand-5 text-sm font-bold text-brand-1 w-max">
            {activity?.subject || ''}
          </div>
          <div className="py-2">
            <div className="text-xl font-bold text-grey-1">
              {activity?.title}
            </div>
            <div className="text-grey-5 text-sm">
              {utcToLocalDatetime(activity?.createdAt?.toString() || '')}
            </div>
          </div>
          {images?.map((image: string, i: number) => (
            <>
              <div
                className="w-full"
                onClick={() => {
                  setActiveIndex(i);
                  setImagesModalOpen(true);
                }}
              >
                <div className={`relative pb-3/5 rounded bg-gray-50`}>
                  <img
                    loading="lazy"
                    className={`absolute w-full h-full rounded object-cover`}
                    src={image}
                    alt=""
                  />
                </div>
              </div>
            </>
          ))}
          <div className="absolute">
            <Viewer
              visible={hasImagesModalOpen}
              rotatable={true}
              noImgDetails={true}
              //drag={false}
              scalable={false}
              images={viewerImages}
              onClose={() => {
                setImagesModalOpen(false);
              }}
              activeIndex={activeIndex}
            />
          </div>
          {files?.length ? (
            <div>
              {files?.map((f: any) => (
                <FileItem file={f} />
              ))}
            </div>
          ) : (
            <></>
          )}
          <div className={`whitespace-pre-line feedback_space`}>
            <Linkify>{activity?.content}</Linkify>
          </div>
          <div className="h-0.5 bg-gray-100"></div>
        </Section>
      </div>
    );
  }

  const calculatedReadState = readState && defaultReadState;

  return (
    <div>
      {!data && <Blank text="해당하는 데이터가 없습니다." />}
      {isLoading && <Blank />}
      <TopNavbar
        title="활동"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
        right={
          readState ? (
            activity?.endDate &&
            new Date(activity?.endDate).getTime() - new Date().getTime() < 0 ? (
              <span className="text-red-600">마감</span>
            ) : (
              <span
                onClick={() => setReadState(false)}
                className="text-brand-1 cursor-pointer"
              >
                수정
              </span>
            )
          ) : (
            <></>
          )
        }
      />

      <Section tw={{ spaceY: 'space-y-2', backgroundColor: 'bg-white' }}>
        <div className="rounded-3xl px-3 py-1 bg-brand-5 text-sm font-bold text-brand-1 w-max">
          {activity?.subject || ''}
        </div>
        <div className="py-2">
          <div className="text-xl font-bold text-grey-1">{activity?.title}</div>
          <div className="text-grey-5 text-sm">
            {utcToLocalDatetime(activity?.createdAt?.toString() || '')}
          </div>
        </div>
        {activity?.type !== 'SURVEY' && (
          <div>
            <Section tw={{ paddingX: 'px-0' }}>
              {images?.map((image: string, i: number) => (
                <>
                  <div
                    className="w-full"
                    onClick={() => {
                      setActiveIndex(i);
                      setImagesModalOpen(true);
                    }}
                  >
                    <div className={`relative pb-3/5 rounded bg-gray-50`}>
                      <img
                        loading="lazy"
                        className={`absolute w-full h-full rounded object-cover`}
                        src={image}
                        alt=""
                      />
                    </div>
                  </div>
                </>
              ))}
              {files?.length ? (
                <div>
                  {files?.map((f: any) => (
                    <FileItem file={f} />
                  ))}
                </div>
              ) : (
                <></>
              )}
              <div className={`whitespace-pre-line feedback_space`}>
                <Linkify>{activity?.content}</Linkify>
              </div>
            </Section>
            <div className="absolute">
              <Viewer
                visible={hasImagesModalOpen}
                rotatable={true}
                noImgDetails={true}
                //drag={false}
                scalable={false}
                images={viewerImages}
                onClose={() => {
                  setImagesModalOpen(false);
                }}
                activeIndex={activeIndex}
              />
            </div>
          </div>
        )}

        <div className="h-0.5 bg-gray-100"></div>

        {activity?.endDate && (
          <div className="text-red-500">
            <span className="">마감 기한 </span>{' '}
            {utcToLocalDatetime(activity.endDate)}
            까지
          </div>
        )}
      </Section>

      {calculatedReadState ? (
        <ActivityDetailReadPage
          studentActivity={studentActivity}
          activity={activity}
          type={activity?.type}
          refetch={() => refetch()}
          setSubmitState={() => setReadState(false)}
          setLoading={(state: boolean) => setLoading(state)}
          userId={data?.me?.id}
        />
      ) : (
        <ActivityDetailSubmitPage
          studentActivity={studentActivity}
          activity={activity}
          setReadState={() => setReadState(true)}
          refetch={() => refetch()}
          setLoading={(state: boolean) => setLoading(state)}
        />
      )}
    </div>
  );
};
