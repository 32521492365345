import React, { useEffect, useMemo, useState } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import SignaturePad from 'signature_pad';
import {
  Blank,
  Button,
  Section,
  TextField,
  useMutation,
  useQueryString,
} from '../../../reusable';
import {
  ErrorBlank,
  FieldtripCard,
  SelectMenus,
  SuperModal,
  FrontPagination,
} from '../../../components';
import {
  APPROVE_FIELDTRIP,
  APPROVE_MULTIPLE_FIELDTRIPS,
  UPDATE_STAMP,
} from '../../../query';
import { Fieldtrip } from '../../../types';
import {
  getEndDate,
  getStartDate,
  isValidDate,
  makeDateToString,
  MonthAgo,
} from '../../../utils';
import FieldtripDetailPage from './FieldtripDetailPage';
import { fetcher } from '../../../plugins';
import { ReactComponent as UploadIcon } from '../../../assets/svg/icon-upload.svg';

const groups = [
  { id: 1, name: '모두', value: 'ALL' },
  { id: 2, name: '승인 전', value: 'BEFORE_APPROVAL' },
  { id: 3, name: '승인 완료', value: 'PROCESSED' },
  { id: 4, name: '반려됨', value: 'RETURNED' },
];

let sigPad: any = null;

export const FieldtripMainPage: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [startDate, setStartDate] = useState(
    makeDateToString(MonthAgo(new Date())),
  );
  const [endDate, setEndDate] = useState(makeDateToString(new Date()));
  const [sigPadData, setSigPadData] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [agreeAll, setAgreeAll] = useState(false);
  const [fieldtripId, setFieldtripId] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [filter, setFilter] = useState<any>({
    id: 1,
    name: '모두',
    value: 'ALL',
  });
  const [stampMode, setStampMode] = useState<boolean>(false);
  const [page, setPage] = useState(Number(params.get('page') ?? '1'));
  const [_studentName, set_studentName] = useState('');
  const limit = Number(params.get('limit') ?? '25') || 25;
  const studentName = params.get('username') || '';

  useEffect(() => {
    setPage(Number(params.get('page') ?? '1'));
  }, [params]);

  const queryString = useQueryString({
    filter: {
      startDate: getStartDate(startDate),
      endDate: getEndDate(endDate),
      fieldtripStatus: filter?.value === 'ALL' ? undefined : filter.value,
      ...(studentName && { username: studentName }),
    },
    page: page,
    limit,
    defaultLimit: limit,
  });

  const { error, data, refetch } = uQ(`/fieldtrips${queryString}`, fetcher);

  const school = data?.me?.school;
  const approvalLine = school?.approvalLineF;
  const fieldtrips = data?.items;
  const me = data?.me;
  const userRole = data?.me?.role;

  const clearSignature = () => {
    sigPad && sigPad.clear();
    setSigPadData('');
    const canvas = document.querySelector('canvas');
    const ctx = canvas?.getContext('2d');
    ctx && ctx.clearRect(0, 0, canvas?.width || 0, canvas?.height || 0);
    ctx && ctx.beginPath();
  };

  useEffect(() => {
    if (document.querySelector('canvas')) {
      sigPad =
        //@ts-ignore
        new SignaturePad(document.querySelector('canvas'), {
          onEnd: () => {
            setSigPadData(sigPad.toDataURL());
          },
        });
    }
  }, []);

  let stampData = { preSignedStamp: '', stamp: '' };

  try {
    if (me?.stamp) {
      stampData = JSON.parse(me.stamp);
    }
  } catch (err) {
    console.error(err);
  }

  const { preSignedStamp, stamp } = stampData;

  const onCompleted = () => {
    setOpen(false);
    clearSignature();
    setLoading(false);
    setErrorMessage('');
    refetch();
  };

  const onError = (error: any) => {
    setOpen(false);
    clearSignature();
    setLoading(false);
    setErrorMessage(error?.message);
    refetch();
  };

  const [approveFieldtrips] = useMutation(APPROVE_MULTIPLE_FIELDTRIPS, {
    variables: {
      data: JSON.stringify({
        fieldtripIds: fieldtrips?.map((f: Fieldtrip) => f.id),
        signature: stampMode ? stamp : sigPadData,
      }),
    },
    onCompleted,
    onError,
  });

  const [approveFieldtrip] = useMutation(APPROVE_FIELDTRIP, {
    variables: { fieldtripId, signature: stampMode ? stamp : sigPadData },
    onCompleted,
    onError,
  });

  const [updateStamp] = useMutation(UPDATE_STAMP, {
    onCompleted: async () => {
      await refetch();
      await setLoading(false);
    },
    onError: () => {
      setLoading(false);
      alert(
        '스탬프 이미지를 업로드하던 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요!',
      );
    },
  });

  //조회 권한 여부
  const { data: teacherData } = uQ(`/users/teacherHomeKlass`, fetcher);

  let isViewAuth = false;
  if(teacherData?.user?.role === 'SECURITY') {
    isViewAuth = false;
  } else {
    if(teacherData?.user?.role === 'TEACHER'){
      isViewAuth = teacherData?.user.teacherGroups.length !== 0 ? true : false;        
    }else {
      isViewAuth = true;
    }
  }
  // 승인 권한 여부
  let isApprovalAuth = false;
  for (const index in approvalLine) {
    if (teacherData?.user?.role === approvalLine[index]) {
      if(teacherData?.user?.role === 'TEACHER'){
        isApprovalAuth = teacherData?.user.teacherGroups.length !== 0 ? true : false;        
      }else {
        isApprovalAuth = true;
      }
    }
  }
  const [isStampImageLoaded, setIsStampImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setIsStampImageLoaded(true);
    img.onerror = () => setIsStampImageLoaded(false);
    img.src = preSignedStamp;
  }, [preSignedStamp]);

  const searchAlert = () => {
    const confirmed = window.confirm("승인 전 상태의 내용만 일괄 승인이 가능합니다. \n승인 전 상태인 건들을 조회하시겠습니까?");
    if (confirmed) {
      setFilter(groups[1]);
    }
  };

  return (
    <>
      {error && <ErrorBlank />}
      {errorMessage && <ErrorBlank text={errorMessage} />}
      {isLoading && <Blank reversed />}
      <div className="col-span-3 h-screen hidden md:block">
        <div className=" px-6 pb-4 pt-6 overflow-y-scroll scroll-box">
          <div className="flex justify-between">
            <h1 className="text-2xl font-semibold">체험학습 신청서</h1>
          </div>
          <div className="flex space-x-3 my-3 items-center">
            <TextField
              type="date"
              value={makeDateToString(new Date(startDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (endDate && selectedDate > new Date(endDate)) {
                  setEndDate(e.target.value);
                }
                setStartDate(e.target.value);
                setPage(1);
              }}
            />
            <div className="px-4 font-bold text-xl">~</div>
            <TextField
              type="date"
              value={makeDateToString(new Date(endDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (startDate && selectedDate < new Date(startDate)) {
                  setStartDate(e.target.value);
                }
                setEndDate(e.target.value);
                setPage(1);
              }}
            />
          </div>
          <div className="flex space-x-2 items-center">
            <div className=" px-2 py-2 min-w-max cursor-pointer">
              <SelectMenus
                allText="모두"
                items={groups}
                onChange={(e) => setFilter(e)}
                value={filter}
              ></SelectMenus>
            </div>

            <div className="flex items-center space-x-2 w-full">
              <div className="items-center border-gray-200 rounded-full border flex justify-between h-10 px-4 w-full">
                <input
                  className="w-full"
                  placeholder="학생이름을 입력해주세요."
                  value={_studentName}
                  onChange={(e) => {
                    set_studentName(e.target.value);
                    if (e.target.value === '') {
                      history.replace(`/teacher/fieldtrip`);
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      history.replace(
                        `/teacher/fieldtrip?username=${_studentName}`,
                      );
                    }
                  }}
                />
              </div>
              <UploadIcon
                className="cursor-pointer"
                onClick={() => {
                  if (_studentName === '') {
                    alert('텍스트 내용을 입력해주세요!');
                  } else {
                    history.replace(
                      `/teacher/fieldtrip?username=${_studentName}`,
                    );
                  }
                }}
              />
            </div>
            {isApprovalAuth && ( 
            <Button
              text="일괄 승인하기"
              tw={{
                minWidth: 'min-w-max',
                paddingY: 'py-2.5',
                height: 'h-auto',
                paddingX: 'px-4',
              }}
              onClick={() => {
                if(filter.value === 'BEFORE_APPROVAL'){
                  setOpen(true);
                  setAgreeAll(true);
                }else{
                  searchAlert();
                }
              }}
            />
            )}
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        {(!isViewAuth && !isApprovalAuth) && <div className="text-center">권한이 없습니다.</div> }
        {isViewAuth &&
        <div className="h-screen-15 overflow-y-auto scroll-box">
          {fieldtrips?.map((fieldtrip: any) => (
            <FieldtripCard userRole={userRole} fieldtrip={fieldtrip} />
          ))}
        </div>
        }
        {data?.total > limit && (
          <div className="grid place-items-center min-w-max">
            <FrontPagination
              basePath="/teacher/fieldtrip"
              total={data?.total}
              limit={limit}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
      </div>
      <div className="col-span-3 bg-gray-50 p-6">
        <Switch>
          <Route
            path="/teacher/fieldtrip/:id"
            component={() => (
              <FieldtripDetailPage
                school={school}
                refetch={() => refetch()}
                setOpen={(b: boolean) => setOpen(b)}
                setFieldtripId={(n: number) => setFieldtripId(n)}
                setAgreeAll={(b: boolean) => setAgreeAll(b)}
                me={me}
                approvalLine={approvalLine}
              />
            )}
          />
        </Switch>
      </div>
      <SuperModal
        modalOpen={open}
        setModalClose={() => {
          clearSignature();
          setOpen(false);
        }}
        width="w-max"
        ablePropragation
      >
        <Section tw={{ marginTop: 'mt-7' }}>
          <div>
            <div className="text-gray-700 text-xl font-bold">서명란</div>
            <div className="text-gray-500">아래 네모칸에 서명을 해주세요.</div>
          </div>
          <div className="relative">
            <canvas
              width={
                window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6
              }
              height={
                window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4
              }
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            {stampMode ? (
                isStampImageLoaded ? (
                <div
                  className="absolute inset-0 z-10 rounded overflow-hidden bg-center bg-no-repeat bg-contain"
                  style={{ backgroundImage: `url(${preSignedStamp})` }}
                ></div>
              ) : (
                <div className="absolute inset-0 z-10 rounded overflow-hidden bg-grey-4">
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="min-w-max text-center">
                      도장을 등록해주세요.
                    </div>
                  </div>
                </div>
              )
            ) : (
              ''
            )}
          </div>
          <div className="flex items-center justify-center space-x-2">
            <label htmlFor="stamp-upload" className="w-1/2">
              <div className="w-full rounded-lg font-bold h-13 px-6 justify-center flex items-center bg-white border border-brandblue-1 text-current cursor-pointer">
                도장등록
              </div>
            </label>
            <input
              id="stamp-upload"
              name="stamp-upload"
              type="file"
              className="sr-only"
              accept=".png, .jpeg, .jpg"
              onChange={(e) => {
                if(e.target.validity.valid && e.target.files?.length != 0) {
                  setLoading(true);
                  updateStamp({
                    variables: {image: e.target.files?.item(0)},
                  });
                  setStampMode(true);
                }
              }}
            />
            {!stampMode ? (
              <Button
                tw={{
                  width: 'w-1/2',
                  backgroundColor: 'bg-brandblue-1',
                }}
                onClick={async () => {
                  setStampMode(true);
                  clearSignature();
                }}
              >
                도장 사용하기
              </Button>
            ) : (
              <Button
                tw={{
                  width: 'w-1/2',
                  backgroundColor: 'bg-brandblue-1',
                  borderColor: 'border-red-500',
                  borderWidth: 'border-4',
                }}
                onClick={async () => {
                  await setLoading(true);
                  if (!isStampImageLoaded) {
                    alert('도장이 등록되어 있지 않습니다.');
                    await setLoading(false);
                  } else {
                    if (agreeAll) {
                      await approveFieldtrips();
                    } else {
                      await approveFieldtrip();
                    }
                    await setStampMode(false);
                  }
                }}
              >
                도장으로 승인
              </Button>
            )}
          </div>
          <div className="flex items-center justify-center space-x-2">
            <Button
              tw={{
                backgroundColor: 'bg-white',
                borderWidth: 'border',
                borderColor: 'border-brand-1',
                color: 'text-current',
                width: 'w-full',
              }}
              onClick={() => {
                clearSignature()
                setStampMode(false)
              }}
            >
              서명 다시하기
            </Button>
            {stampMode ? (
              <Button
                tw={{
                  width: 'w-full',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  backgroundColor: 'bg-brand-1',
                }}
                onClick={() => setStampMode(false)}
              >
                서명 사용하기
              </Button>
            ) : (
              <Button
                tw={{
                  width: 'w-full',
                  backgroundColor: sigPadData ? 'bg-brand-1' : 'bg-brand-5',
                  borderWidth: sigPadData ? 'border-4' : '',
                  borderColor: 'border-green-500',
                }}
                onClick={() => {
                  if (!sigPadData) {
                    alert('서명 후 승인해 주세요.');
                  } else {
                    setLoading(true);
                    agreeAll ? approveFieldtrips() : approveFieldtrip();
                  }
                }}
              >
                서명으로 승인
              </Button>
            )}
          </div>
        </Section>
      </SuperModal>
    </>
  );
};
