import React from 'react';

interface Item {
  id?: number;
  name: string;
}

interface SelectMenusProps {
  label?: string;
  items: (Item | string)[];
  allText?: string;
  value?: any;
  onChange?: (item: any) => void;
  allTextVisible?: boolean;
}

export const SelectMenus: React.FC<SelectMenusProps> = ({
  label,
  items,
  allText,
  value,
  onChange,
  allTextVisible,
}) => {
  const handleChange = (e: any) => {
    if (onChange) {
      onChange(
        items.filter((el) =>
          typeof el === 'string'
            ? el === e.target.value
            : el.id === Number(e.target.value),
        )[0],
      );
    }
  };
  return (
    <div>
      <label
        htmlFor="location"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <select
        className="mt-1 block w-full pl-1 pr-1 py-3 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-black sm:text-sm rounded-md"
        onChange={handleChange}
        value={value?.id}
      >
        {allText && (
          <option selected hidden={!allTextVisible} value={undefined}>
            {allText}
          </option>
        )}
        {items?.map((item, i: number) => {
          if (typeof item === 'string') {
            return <option value={item || ''}>{item}</option>;
          } else {
            return <option value={item?.id || 0}>{item.name}</option>;
          }
        })}
      </select>
    </div>
  );
};
