import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  BackButton,
  Blank,
  Button,
  Section,
  TextArea,
  TopNavbar,
  useMutation,
} from '../../../reusable';
import {
  SuperModal,
  AbsentPaper,
  ParentConfirmPaper,
} from '../../../components';
import { DELETE_APPREAL_ABSENT, DENY_ABSENT } from '../../../query';
import {
  makeDateToString,
  makeTimeToString,
  extractReactData,
  extractImageData,
  _getPdfImageSize,
} from '../../../utils';
import { jsPDF } from 'jspdf';
import { AbsentUpdatePage } from './AbsentUpdatePage';
import { fetcher } from '../../../plugins';
import { AbsentEvidenceType } from '../../../components/types';

interface MatchParams {
  id: string;
}

interface AbsentDetailPageProps extends RouteComponentProps<MatchParams> {
  refetch: () => void;
  setOpen: (b: boolean) => void;
  setAbsentId: (n: number) => void;
  setAgreeAll: (b: boolean) => void;
  userRole: string;
  approvalLine?: string[];
}

const calculateStatus = (role: string, order: 'before' | 'after' | 'show') => {
  const roles = ['TEACHER', 'PREHEAD', 'HEAD', 'PREPRINCIPAL', 'PRINCIPAL', 'VICEPRINCIPAL', 'HEADPRINCIPAL'];
  const _absentStatus = [
    'BEFORE_TEACHER_APPROVAL',
    'TEACHER',
    'BEFORE_PREHEAD_APPROVAL',
    'PREHEAD',
    'BEFORE_HEAD_APPROVAL',
    'HEAD',
    'BEFORE_PREPRINCIPAL_APPROVAL',
    'PREPRINCIPAL',
    'BEFORE_PRINCIPAL_APPROVAL',
    'PRINCIPAL',
    'BEFORE_VICEPRINCIPAL_APPROVAL',
    'VICEPRINCIPAL',
    'BEFORE_HEADPRINCIPAL_APPROVAL',
    'HEADPRINCIPAL',
    'PROCESSED',
  ];

  if (role === 'SECURITY') return ['PROCESSED'];

  const index = _absentStatus.findIndex((el) => el === role);

  if (index === -1) return [];

  if (order === 'before') {
    return _absentStatus
      .slice(index - 1, index)
      .filter((el) => !roles.includes(el));
  } else if (order === 'after') {
    return _absentStatus.slice(index).filter((el) => !roles.includes(el));
  } else if (order === 'show') {
    return _absentStatus.slice(index - 1).filter((el) => !roles.includes(el));
  }
};

const AbsentDetailPage: React.FC<AbsentDetailPageProps> = ({
  refetch,
  match,
  setOpen,
  setAbsentId,
  setAgreeAll,
  userRole,
  approvalLine,
}) => {
  const { id } = match.params;
  const ref = useRef(null);
  const parentRef = useRef(null);

  const [deny, setDeny] = useState(false);
  const [deleteAppeal, setDeleteAppeal] = useState(false);
  const [changeMode, setChangeMode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [notApprovedReason, setNotApprovedReason] = useState('');
  const [deleteReason, setDeleteReason] = useState('');
  const [clicked, setClicked] = useState(false);

  const { data: absent } = useQuery(`/absents/teacher/${id}`, fetcher, {
    enabled: !!id,
  });

  // useEffect(() => {
    // 2023년 이전의 결재선은 결재상태와 사인(도장) 여부에 따라 판단
    if(absent?.reportedAt <= '2023-03-01'){
      if(absent?.absentStatus === 'PROCESSED')
      {
        approvalLine = [];
        if(absent?.teacherSignature) approvalLine?.push('TEACHER');
        if(absent?.preHeadSignature) approvalLine?.push('PREHEAD');
        if(absent?.headSignature) approvalLine?.push('HEAD');
        if(absent?.prePrincipalSignature) approvalLine?.push('PREPRINCIPAL');
        if(absent?.principalSignature) approvalLine?.push('PRINCIPAL');
        if(absent?.viceprincipalSignature) approvalLine?.push('VICEPRINCIPAL');
        if(absent?.headprincipalSignature) approvalLine?.push('HEADPRINCIPAL');
      }
    }
  // }, []);
  
  useEffect(() => {
    setAbsentId(Number(id));
  }, [id, setAbsentId]);

  const [denyAbsent] = useMutation(DENY_ABSENT, {
    variables: { absentId: Number(id), notApprovedReason },
    onCompleted: () => {
      setDeny(false);
      setLoading(false);
      refetch();
    },
  });

  const [deleteAppealAbsent] = useMutation(DELETE_APPREAL_ABSENT, {
    variables: { absentId: Number(id), deleteReason },
    onCompleted: () => {
      setDeleteAppeal(false);
      setLoading(false);
      refetch();
    },
  });

  if (changeMode) {
    return (
      <AbsentUpdatePage
        absentData={absent}
        isConfirmed={absent?.absentStatus === 'PROCESSED'}
        refetch={() => refetch()}
        setChangeMode={(b: boolean) => setChangeMode(b)}
      />
    );
  }

  
  const buttonDisabled = !calculateStatus(userRole, 'before')?.includes(
    absent?.absentStatus || '',
  );

  const isNotApproved = !calculateStatus(userRole, 'after')?.includes(
    absent?.absentStatus || '',
  );

  return (
    <>
      <div className="bg-white border rounded-lg py-5 relative h-full">
        {isLoading && <Blank reversed />}
        {/* Desktop V */}
        <div className="w-auto mb-24 md:mb-0 md:h-full md:overflow-scroll md:relative">
          <div className="block md:hidden">
            <TopNavbar title="상세보기" left={<BackButton />} />
          </div>
          {absent?.updateReason && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2 m-2">
              <div className="text-brand-1">{absent?.updateReason}</div>
              <div className="text-gray-500 text-sm">
                {makeDateToString(absent?.updatedAt)}{' '}
                {makeTimeToString(absent?.updatedAt)}에 마지막으로 수정
              </div>
            </div>
          )}
          {absent?.absentStatus === 'RETURNED' && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2 m-2">
              <div className="text-brand-1 text-sm">
                {absent?.notApprovedReason}
              </div>
              <div className="text-red-500">반려 이유</div>
            </div>
          )}
          <div className="w-full md:w-auto overflow-x-scroll md:overflow-auto">
            <AbsentPaper
              ref={ref}
              absent={absent}
              approvalLine={approvalLine}
            />
            {absent?.evidenceType === '학부모 확인서' && (
              <ParentConfirmPaper ref={parentRef} absent={absent} />
            )}
          </div>
          {absent?.evidenceFiles &&
            absent.evidenceFiles.map((evidenceFile: string, i: number) => (
              <div
                style={{ width: '560px', height: '792px' }}
                className="bg-white overflow-hidden"
              >
                <img
                  className="object-cover"
                  src={
                    process.env.REACT_APP_API_URL +
                    '/images?url=' +
                    evidenceFile
                  }
                  crossOrigin="anonymous"
                  alt=""
                />
              </div>
            ))}
        </div>
        <div className="fixed md:absolute w-full bottom-16 md:bottom-0 bg-gray-50">
          <div
            className="block md:sticky bottom-0 -ml-1 md:ml-0"
            style={{ width: 'inherit', maxWidth: 'inherit' }}
          >
            <div className="px-3 my-3 h-16 overflow-y-scroll scroll-box">
              {absent?.evidenceType !== '학부모 확인서' &&
                absent?.evidenceFiles?.map((evidenceFile: string) => (
                  <>
                    <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <a
                        href={
                          process.env.REACT_APP_API_URL +
                          '/images?url=' +
                          evidenceFile
                        }
                        target="_blank"
                        rel="noreferrer"
                        download={true}
                      >
                        <ul className="border border-gray-200 rounded-md bg-white">
                          <li className="px-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <svg
                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                              >
                                <path d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" />
                              </svg>
                              <span className="ml-2 flex-1 w-0 truncate">
                                {absent?.evidenceType}
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <span className="font-medium text-indigo-600 hover:text-indigo-500">
                                Download
                              </span>
                            </div>
                          </li>
                        </ul>
                      </a>
                    </div>
                    <span className="font-semibold text-lg"></span>
                  </>
                ))}
            </div>
            <div className="flex relative items-center w-full overflow-x-auto space-x-2 mt-3 ">
              <div className="hidden md:block w-full min-w-max">
                <Button
                  disabled={clicked}
                  tw={{
                    width: 'w-full',
                    backgroundColor: clicked ? 'bg-gray-400' : 'bg-blue-500',
                    minWidth: 'min-w-max',
                  }}
                  onClick={async () => {
                    setClicked(true);
                    let doc: any = new jsPDF('p', 'mm', 'a4');

                    if (ref?.current) {
                      let absentPdfData = await extractReactData(ref.current);
                      await doc.addImage(absentPdfData, 'PNG', 0, 0, 210, 297);
                    }
                    if (
                      absent.evidenceType === AbsentEvidenceType.PARENT &&
                      parentRef?.current
                    ) {
                      let parentPdfData = await extractReactData(
                        parentRef.current,
                      );
                      await doc.addPage();
                      await doc.addImage(parentPdfData, 'PNG', 0, 0, 210, 297);
                    }
                    if (
                      absent.evidenceType !== AbsentEvidenceType.PARENT &&
                      absent?.evidenceFiles?.length
                    ) {
                      for (const ef of absent.evidenceFiles) {
                        const evidenceFile =
                          process.env.REACT_APP_API_URL + '/images?url=' + ef;
                        const imgData = await extractImageData(evidenceFile);
                        if (!imgData) continue;
                        const {
                          width: imageWidth = 0,
                          height: imageHeight = 0,
                          data,
                        } = imgData;
                        const [width, height] = _getPdfImageSize(
                          imageWidth,
                          imageHeight,
                        );
                        await doc.addPage();
                        await doc.addImage(
                          data,
                          'JPEG',
                          0,
                          0,
                          width,
                          height,
                          undefined,
                          'FAST',
                        );
                      }
                    }

                    await doc.save(
                      `${absent?.reportedAt}-${absent?.student?.name}.pdf`,
                    );
                    setClicked(false);
                  }}
                >
                  다운로드
                </Button>
              </div>
              <Button
                tw={{
                  width: 'w-full',
                  minWidth: 'min-w-max',
                  backgroundColor: 'bg-red-500',
                }}
                text="삭제요청"
                onClick={() => {
                  setDeleteAppeal(true);
                }}
              />
              <Button
                tw={{
                  width: 'w-full',
                  minWidth: 'min-w-max',
                  backgroundColor: 'bg-gray-600',
                }}
                text="반려"
                disabled={buttonDisabled}
                onClick={() => {
                    setDeny(true);
                }}
              />
              <div>
              {absent?.absentStatus === 'PROCESSED' || !isNotApproved ? (
                <Button
                  tw={{ width: 'w-full', minWidth: 'min-w-max' }}
                  text="승인 후 수정"
                  onClick={() => {
                    setChangeMode(true);
                  }}
                />
              ) : (
                <div className="w-full min-w-max">
                  <Button
                    tw={{ width: 'w-full', minWidth: 'min-w-max' }}
                    text={!isNotApproved ? '승인 후 수정' : '수정'}
                    onClick={() => {
                      setChangeMode(true);
                    }}
                    disabled={buttonDisabled}
                  />
                </div>
              )}
              </div>
              <Button
                tw={{
                  width: 'w-full',
                  minWidth: 'min-w-max',
                  backgroundColor: buttonDisabled
                    ? 'bg-gray-500'
                    : 'bg-brand-1',
                }}
                text={buttonDisabled ? 
                  (isNotApproved ? '승인 대기' : '승인 완료') 
                  : '승인'}
                disabled={buttonDisabled}
                onClick={() => {
                  setOpen(true);
                  setAgreeAll(false);
                }}
              />  
            </div> 
          </div>
        </div>
        <div className="h-24" />
        <SuperModal
          modalOpen={deny}
          setModalClose={() => setDeny(false)}
          width="w-max"
        >
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              이 학생의 출결신고서를 반려하시겠습니까?
            </div>
            <TextArea
              placeholder="반려 이유"
              onChange={(e) => setNotApprovedReason(e.target.value)}
              value={notApprovedReason}
            />
            <Button
              onClick={() => {
                setLoading(true);
                denyAbsent();
              }}
            >
              반려하기
            </Button>
          </Section>
        </SuperModal>
        <SuperModal
          modalOpen={deleteAppeal}
          setModalClose={() => setDeleteAppeal(false)}
          width="w-max"
        >
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              이 출결신고서를 삭제하도록 요청하시겠습니까?
            </div>
            <TextArea
              placeholder="삭제 이유"
              onChange={(e) => setDeleteReason(e.target.value)}
              value={deleteReason}
            />
            <Button
              tw={{ backgroundColor: 'bg-red-500' }}
              onClick={() => {
                setLoading(true);
                deleteAppealAbsent();
              }}
            >
              삭제 요청하기
            </Button>
          </Section>
        </SuperModal>
      </div>
    </>
  );
};

export default withRouter(AbsentDetailPage);
