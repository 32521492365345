import React, { useState } from 'react';
import {
  Blank,
  Section,
  Button,
  TextArea,
  useMutation,
  TextField,
} from '../../../reusable';
import { UPDATE_FIELD_TRIP_RESULT_BY_TEACHER } from '../../../query';
import { Fieldtrip, School } from '../../../types';
import { makeDateToString } from '../../../utils';
import { SuperModal } from '../../../components';

interface FieldtripResultUpdatePageProps {
  school: School;
  fieldtrip: Fieldtrip;
  refetch: () => void;
  setReadState: () => void;
  isConfirmed: boolean;
}

export const FieldtripResultUpdatePage: React.FC<
  FieldtripResultUpdatePageProps
> = ({ fieldtrip, refetch, setReadState, isConfirmed }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [updateReason, setUpdateReason] = useState('');
  const [destination, setDestination] = useState(fieldtrip?.destination);
  const [resultText, setResultText] = useState(fieldtrip?.resultText || '');
  const [resultTitle, setResultTitle] = useState(fieldtrip?.resultTitle || '');
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [updateFieldtripResultByTeacher] = useMutation(
    UPDATE_FIELD_TRIP_RESULT_BY_TEACHER,
    {
      variables: {
        data: JSON.stringify({
          fieldtripId: fieldtrip?.id,
          destination,
          resultText,
          resultTitle,
          updateReason,
        }),
      },
      onError: (error) => {
        setLoading(false);
        setErrorMessage(error.message);
      },
      onCompleted: (data) => {
        setLoading(false);
        alert('체험학습 결과보고서를 수정하였습니다.');
        refetch();
        setReadState();
      },
    },
  );
  const buttonDisabled = !destination || !resultText || !resultTitle;

  return (
    <div className="bg-white border rounded-lg py-5 h-screen-10 overflow-y-scroll scroll-box">
      {isLoading && <Blank reversed />}
      <Section>
        <Section style={{ backgroundColor: '#F7F7F7' }}>
          <h1 className="text-xl font-semibold">교외 체험학습 결과 보고서</h1>
          <div className="text-xs whitespace-pre-line mb-2 text-red-600">
            제출기한 : {makeDateToString(new Date(fieldtrip?.endAt || ''), ' ')}
            까지
          </div>
          <label className="mb-1 text-sm text-gray-800">*기간</label>
          <div className="space-y-3 pb-6">
            <div>
              <div className="flex space-x-4 items-center">
                <div className="text-lg">
                  {fieldtrip?.startAt &&
                    makeDateToString(new Date(fieldtrip?.startAt || ''), '. ')}
                </div>
                <span className="">부터</span>
              </div>
              <div className="pt-2 flex space-x-4 items-center">
                <div className="text-lg">
                  {fieldtrip?.endAt &&
                    makeDateToString(new Date(fieldtrip?.endAt || ''), '. ')}
                </div>
                <span className="">까지</span>
              </div>
            </div>
            <div className="  text-lg whitespace-pre-line mb-2">
              남은 일수
              <span className="text-brand-1 underline">
                {fieldtrip?.currentRemainDays}일 중 {fieldtrip?.usedDays}일 신청
              </span>
              합니다.
            </div>
            <div className=" text-gray-600 text-xs whitespace-pre-line mb-2">
              ※
              <span className="font-bold">
                토,일, 개교기념일 등 학교 휴업일
              </span>
              은 체험학습 신청 일수에 넣지 않음. <br /> ※ 체험학습 신청은
              신청일보다 <span className="font-bold">3일 이전에 신청</span>
              해야함.
            </div>
            <div className="w-full">
              <TextField
                label="*체험학습 형태"
                placeholder={fieldtrip?.form}
                readOnly
              />
            </div>
            <div className="w-full">
              <TextField
                label="*목적지"
                id="destination"
                placeholder="목적지를 입력해주세요."
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
                tw={{
                  borderColor: destination
                    ? 'border-gray-300'
                    : 'border-red-700',
                }}
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="font-bold text-gray-800 text-lg">
              *현장학습 결과 보고서 작성
            </div>
          </div>
          <div className="w-full">
            <TextField
              label="*제목"
              placeholder="내용을 입력해주세요."
              value={resultTitle}
              onChange={(e) => setResultTitle(e.target.value)}
              tw={{
                borderColor: resultTitle ? 'border-gray-300' : 'border-red-700',
              }}
            />
          </div>
          <TextArea
            placeholder="내용을 입력해주세요."
            rows={5}
            style={{ height: 'auto', borderWidth: '1px' }}
            value={resultText}
            onChange={(e) => setResultText(e.target.value)}
          />

          {errorMessage && <div className="text-red-600">{errorMessage}</div>}
        </Section>
        <Button
          disabled={buttonDisabled}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={() => {
            if (isConfirmed) {
              setModalOpen(true);
            } else {
              setLoading(true);
              updateFieldtripResultByTeacher();
            }
          }}
        >
          수정하기
        </Button>

        {errorMessage && <div className="text-red-600">{errorMessage}</div>}
      </Section>
      <SuperModal
        modalOpen={modalOpen}
        setModalClose={() => setModalOpen(false)}
        width="w-max"
      >
        <Section tw={{ marginTop: 'mt-7' }}>
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            이 체험학습 결과보고서를 승인 후 수정하시는 이유를 적어주세요.
          </div>
          <TextArea
            placeholder="수정 이유"
            onChange={(e) => setUpdateReason(e.target.value)}
            value={updateReason}
          />
          <Button
            tw={{ backgroundColor: 'bg-red-500' }}
            onClick={() => {
              setLoading(true);
              updateFieldtripResultByTeacher();
            }}
          >
            승인 후 수정하기
          </Button>
        </Section>
      </SuperModal>
    </div>
  );
};
