import { ListItem, utcToLocalDatetime } from '../../reusable';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Notice } from '../../types';

interface NoticeTeacherItemProps {
  notice: Notice;
}

export const NoticeTeacherItem: FC<NoticeTeacherItemProps> = ({ notice }) => {
  const history = useHistory();
  return (
    <ListItem onClick={() => history.push(`/teacher/notice/${notice.id}`)}>
      <div className="text-left flex flex-col space-y-1">
        <div className="flex justify-between">
          <div className="rounded-md px-3 py-1 bg-red-50 text-sm text-red-500 w-max">
            {notice.category || '공지사항'}
          </div>
          <div className="text-sm text-gray-500 font-light">
            {notice.createdAt && utcToLocalDatetime(notice.createdAt)}
          </div>
        </div>
        <div className="flex items-center justify-between space-x-2">
          <div className="text-lg font-semibold mt-2 break-all whitespace-pre-line">
            {notice.title}
          </div>
          <div className="text-sm text-gray-500 min-w-max">
            {notice.user?.name}
          </div>
        </div>

      </div>
    </ListItem>
  );
};
