import React, { FC, useEffect, useState } from 'react';
import { Blank, Button, useQuery } from '../../../reusable';
import { GET_TIMETABLE_BY_YEAR_SEMESTER_GROUP } from '../../../query';
import { ErrorBlank } from '../../../components';
import { TimetableSubmitPage } from './TimetableSubmitPage';

interface TimetableDetailPageProps {
  year: number;
  semester: number;
  groupId: number | undefined;
}

export const TimetableDetailPage: FC<TimetableDetailPageProps> = ({
  year,
  semester,
  groupId,
}) => {
  const [isModifyMode, setModifyMode] = useState(false);
  const { loading, error, data, refetch } = useQuery(
    GET_TIMETABLE_BY_YEAR_SEMESTER_GROUP,
    {
      variables: { year, semester, groupId },
      errorPolicy: 'ignore',
      onError: (e) => {
        if (e.message === 'not found') {
          setModifyMode(true);
        }
      },
      skip:
        typeof year !== 'number' ||
        typeof semester !== 'number' ||
        typeof groupId !== 'number',
    },
  );
  const canEditTimetable = data?.me?.canEditTimetable;

  useEffect(() => {
    refetch();
    setModifyMode(false);
  }, [year, semester, groupId, refetch]);

  const timetable = data?.timetableByYearSemesterGroup;

  let content = null;
  let contentRow: number;

  try {
    content = JSON.parse(timetable?.content);
    contentRow = content.length;
  } catch (err) {}

  if (groupId === undefined) {
    return <div></div>;
  }
  if (isModifyMode && canEditTimetable) {
    return (
      <TimetableSubmitPage
        timetableContent={timetable?.content}
        year={year}
        semester={semester}
        groupId={groupId}
        setReadState={() => setModifyMode(false)}
        refetch={() => refetch()}
      />
    );
  }

  const day = new Date().getDay();

  return (
    <div className="px-6 mx-6 max-w-256">
      {loading && <Blank reversed />}
      {error && <ErrorBlank />}
      <table className="text-center min-w-3/4 mx-auto">
        <thead>
          <tr>
            <td className="" />
            <td className="" />
            <td className="min-w-max  bg-grey-9 rounded-l-xl py-4">월</td>
            <td className="min-w-max  bg-grey-9">화</td>
            <td className="min-w-max  bg-grey-9">수</td>
            <td className="min-w-max  bg-grey-9">목</td>
            <td className="min-w-max bg-grey-9 rounded-r-xl">금</td>
          </tr>
          <td className="pb-4"></td>
        </thead>
        <tbody>
          {content &&
            content?.map((classtime: any, i: number) => (
              <tr>
                <td
                  className={`min-w-max py-2 min-w-9.5 px-1 min-h-10.5 bg-grey-9
                ${i === 0 ? 'rounded-t-xl' : ''} ${
                    i === contentRow - 1 ? 'rounded-b-xl' : ''
                  }`}
                >
                  {i + 1} {}
                </td>
                <div className="px-1"></div>
                <td
                  className={`bg-brand-1 bg-opacity-20 min-w-max py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 1 ? 'bg-brand-1 bg-opacity-20' : ''
                  }${i === 0 ? 'rounded-tl-2xl' : ''} ${
                    i === contentRow - 1 ? 'rounded-bl-2xl' : ''
                  }`}
                >
                  {classtime.monday}
                </td>
                <td
                  className={`bg-brand-1 bg-opacity-20 min-w-max py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 2 ? 'bg-brand-1 bg-opacity-20' : ''
                  }`}
                >
                  {classtime.tuesday}
                </td>
                <td
                  className={`bg-brand-1 bg-opacity-20 min-w-max py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 3 ? 'bg-brand-1 bg-opacity-20' : ''
                  }`}
                >
                  {classtime.wednesday}
                </td>
                <td
                  className={`bg-brand-1 bg-opacity-20 min-w-max py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 4 ? 'bg-brand-1 bg-opacity-20' : ''
                  }`}
                >
                  {classtime.thursday}
                </td>
                <td
                  className={`bg-brand-1 bg-opacity-20 min-w-max py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 5 ? 'bg-brand-1 bg-opacity-20' : ''
                  }${i === 0 ? 'rounded-tr-2xl' : ''} ${
                    i === contentRow - 1 ? 'rounded-br-2xl' : ''
                  }`}
                >
                  {classtime.friday}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {canEditTimetable && (
        <div className="w-full flex items-center justify-center">
          <Button
            tw={{
              marginTop: 'mt-2',
              width: 'w-3/4',
              backgroundColor: 'bg-brand-1',
            }}
            onClick={() => {
              setModifyMode(true);
            }}
          >
            수정하기
          </Button>
        </div>
      )}
    </div>
  );
};
