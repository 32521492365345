import React, { FC } from 'react';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import superstudyLight from '../assets/images/superstudy-light.png';
import { BottomFixedView, Button, Screen, Section, useAuth } from '../reusable';
import { Redirect, useHistory } from 'react-router-dom';
import { version } from '../../package.json';
//@ts-ignore
import preval from 'preval.macro';

export const HomePage: FC = () => {
  const { authenticated } = useAuth();
  const history = useHistory();

  if (authenticated) {
    return <Redirect to="/student"></Redirect>;
  }
  return (
    <div className="w-full h-screen box-border font-spoqa font-normal text-md min-w-full min-h-screen flex items-center justify-center ">
      {/* <div className="max-w-xl w-full"> */}
      <Screen>
        <div className="mt-16 flex items-center justify-center">
          <div>
            <Logo className="mx-auto" />
            <img className="mx-auto" src={superstudyLight} alt="" />
          </div>
        </div>
        <BottomFixedView>
          <div className="w-full text-center mx-auto">
            <Section>
              {/* <Button
                variant="outlined"
                height="h-13"
                style={{ width: '100%' }}
              >
                구글 로그인 (개발중)
              </Button> */}
              <div className="w-full text-center text-gray-600">
                <div className="text-white">
                  v{version} build at{' '}
                  {preval`module.exports = new Date().toLocaleString().split("├")[0]`}
                </div>
                슈퍼스쿨 <br />
                Copyright 2022. SUPERSCHOOL all right reserved.
              </div>
              <Button onClick={() => history.push('/login')}>시작하기</Button>
            </Section>
          </div>
        </BottomFixedView>
      </Screen>
      {/* </div> */}
    </div>
  );
};
