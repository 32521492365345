import { Blank, Button } from '../reusable';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

interface ErrorBlankProps {
  text?: string;
}

export const ErrorBlank: FC<ErrorBlankProps> = ({
  text = '에러가 발생했습니다. 다시 접속해주세요.',
}) => {
  const history = useHistory();
  return (
    <Blank>
      <div className="text-white mb-8 bg-transblack">{text}</div>
      <div className="w-full flex space-x-4 items-center justify-center">
        <Button
          tw={{ backgroundColor: 'bg-gray-800' }}
          onClick={() => window.location.reload()}
        >
          새로고침하기
        </Button>
        <Button
          tw={{ backgroundColor: 'bg-gray-800' }}
          onClick={() => history.push('/')}
        >
          홈으로 돌아가기
        </Button>
      </div>
    </Blank>
  );
};
