import { Badge, utcToLocalDatetime } from '../../reusable';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Newsletter } from '../../types';

interface NewsletterCardProps {
  newsletter: Newsletter;
  onClick?: () => void;
}

export const NewsletterCard: React.FC<NewsletterCardProps> = ({
  newsletter,
  onClick,
}) => {
  const { pathname } = useLocation();

  const { id } = newsletter;

  return (
    <div key={newsletter.id || 0}>
      <div
        className={
          pathname === `/teacher/newsletter/${newsletter.id}`
            ? 'px-6 py-4 bg-gray-50'
            : 'px-6 py-4'
        }
      >
        <div className="flex justify-between">
          <Badge
            tw={{
              backgroundColor: 'bg-purple-100',
              color: 'text-purple-700',
              borderRadius: 'rounded-md',
            }}
          >
            {newsletter.category || '가정통신문'}
          </Badge>
          <div className="text-sm text-gray-500 font-light">
            {newsletter.createdAt && utcToLocalDatetime(newsletter.createdAt)}
          </div>
        </div>
        <div className="text-lg font-semibold mt-2">{newsletter.title}</div>

        <div className="mt-5 space-x-3">
          <Link to={`/teacher/newsletter/${newsletter.id}`}>
            <button
              className={
                pathname.startsWith(`/teacher/newsletter/${newsletter.id}`)
                  ? 'bg-darkgray text-white rounded-md border border-darkgray text-xs px-4 py-2 focus:outline-none'
                  : 'hover:bg-darkgray hover:text-white rounded-md border border-darkgray bg-white text-darkgray text-xs px-4 py-2 focus:outline-none'
              }
              onClick={onClick}
            >
              상세보기
            </button>
          </Link>
          {newsletter.isPublished && (
            <>
              {newsletter.type !== 'NOTICE' && (
                <Link to={`/teacher/newsletter/submit/${id}`}>
                  <button
                    className={
                      pathname === `/teacher/newsletter/submit/${id}`
                        ? 'bg-darkgray text-white rounded-md border border-darkgray text-xs px-4 py-2 focus:outline-none'
                        : 'hover:bg-darkgray hover:text-white rounded-md border border-darkgray bg-white text-darkgray text-xs px-4 py-2 focus:outline-none'
                    }
                  >
                    제출자 보기
                  </button>
                </Link>
              )}
              {newsletter.type !== 'NOTICE' && (
                <Link to={`/teacher/newsletter/download/${id}`}>
                  <button
                    className={
                      pathname === `/teacher/newsletter/download/${id}`
                        ? 'bg-darkgray text-white rounded-md border border-darkgray text-xs px-4 py-2 focus:outline-none'
                        : 'hover:bg-darkgray hover:text-white rounded-md border border-darkgray bg-white text-darkgray text-xs px-4 py-2 focus:outline-none'
                    }
                  >
                    엑셀 다운로드
                  </button>
                </Link>
              )}
            </>
          )}
        </div>
      </div>
      <div className="h-0.5 bg-gray-100 w-full"></div>
    </div>
  );
};
