import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Blank,
  Button,
  Checkbox,
  gql,
  LoadingScreen,
  PhoneNumberField,
  Section,
  TextField,
  useMutation,
  useQuery,
} from '../../../reusable';
import { ErrorBlank } from '../../../components';
import { TeacherGroup } from '../../../types';
import { TEACHER_FIRST_LOGIN } from '../../../query';
import { passwordCheck } from '../../../utils';

const MeQuery = gql`
  query Me {
    schools {
      id
      name
    }
    me {
      name
      email
      firstVisit
      phone
      school {
        id
        name
        privacyPolicyLink
      }
      teacherGroups {
        id
        subject
        group {
          id
          name
          type
          year
        }
      }
    }
  }
`;

export const TeacherFirstLoginPage = () => {
  const history = useHistory();

  const { loading, error, data } = useQuery(MeQuery, {
    onError: ({ message }) => {
      if (message === 'Unauthorized') {
        history.push('/login');
      }
    },
  });

  const [name, setName] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [showTeacherGroups, setShowTeacherGroups] = useState(true);

  const [errorMessage, setErrorMessage] = useState('');

  const [teacherFirstLogin] = useMutation(TEACHER_FIRST_LOGIN, {
    variables: {
      data: JSON.stringify({
        name,
        password: password1,
        phone,
      }),
    },
  });

  useEffect(() => {
    setName(data?.me?.name || '');
    setPhone(data?.me?.phone || '');
    if (data && !data?.me?.firstVisit) {
      history.push('/teacher');
    }
  }, [data, history]);

  const spacere = / /g;

  if (loading) return <LoadingScreen />;

  if (error) {
    return <ErrorBlank />;
  }

  const buttonDisabled =
    !password1 ||
    !password2 ||
    !name ||
    !privacy ||
    !policy ||
    !phone ||
    password1 !== password2;

  return (
    <div className="md:col-span-6 md:max-w-lg h-screen overflow-auto px-6 py-6">
      {isLoading && <Blank reversed />}
      <Section>
        <h1 className="text-2xl font-semibold">첫 로그인</h1>
        <span className="text-center flex justify-center text-sm sm:text-base">
          슈퍼스쿨에 오신 것을 환영합니다!
          <br />
          처음 로그인하시나요? 아래 정보를 입력해주세요.
        </span>
        <div />
        <div className="w-full">
          <TextField
            label="*이름"
            id="name"
            placeholder="이름을 입력해주세요"
            value={name}
            onChange={(e) =>
              setName(String(e.target.value).replace(spacere, ''))
            }
            tw={{
              borderColor: name ? 'border-gray-300' : 'border-red-700',
            }}
          />
        </div>
        <div className="w-full">
          <TextField label="이메일" value={data?.me?.email} readOnly />
        </div>
        <div className="w-full">
          <TextField label="학교" value={data?.me?.school?.name} readOnly />
        </div>
        <div className="w-full">
          <TextField
            label="*비밀번호"
            type="password"
            placeholder="비밀번호 (문자,숫자,특수문자를 포함한 8자 이상)"
            value={password1}
            onChange={(e) =>
              setPassword1(String(e.target.value).replace(spacere, ''))
            }
            tw={{
              borderColor:
                !password1 || password1 !== password2
                  ? 'border-red-700'
                  : 'border-gray-300',
            }}
          />
          {password1 && !passwordCheck(password1) && (
            <div className="text-red-600">안전한 비밀번호를 입력하세요.</div>
          )}
          <TextField
            type="password"
            placeholder="동일한 비밀번호를 한번 더 입력해주세요."
            value={password2}
            onChange={(e) =>
              setPassword2(String(e.target.value).replace(spacere, ''))
            }
            style={{ marginTop: '0.5rem' }}
            tw={{
              borderColor:
                !password2 || password1 !== password2
                  ? 'border-red-700'
                  : 'border-gray-300',
            }}
          />
          {password2 && password1 !== password2 && (
            <div className="text-red-600">
              비밀번호 확인이 일치하지 않습니다.
            </div>
          )}
          <div className="text-grey-3">
            &nbsp; 안전한 개인정보의 보호를 위해 문자,숫자,특수문자가 포함된 8자
            이상의 비밀번호를 입력하세요.
            <br />
            &nbsp; 사용 가능한 특수문자는 ! @ # $ % & * ? 입니다. 
          </div>
        </div>
        <div className="w-full">
          <PhoneNumberField
            label="*전화번호"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            tw={{
              borderColor: !phone ? 'border-red-700' : 'border-gray-300',
            }}
          />
        </div>
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => setShowTeacherGroups(!showTeacherGroups)}
        >
          <div className="text-4xl -mt-6 -mb-5">
            {showTeacherGroups ? <>&#x025BE;</> : <>&#x025B4;</>}
          </div>
          <div className="text-lg font-bold text-gray-800">과목/담당 학년</div>
        </div>
        {showTeacherGroups &&
          data?.me?.teacherGroups?.map((tg: TeacherGroup) => (
            <div className="w-full flex justify-between space-x-2 items-center">
              <TextField readOnly value={tg.subject} />
              <TextField readOnly value={tg.group?.name} />
            </div>
          ))}
        <div className="space-y-2">
          <div className="font-bold">
            <Checkbox
              label="전체 동의"
              onChange={() => {
                const allChecked = privacy && policy;
                setPrivacy(!allChecked);
                setPolicy(!allChecked);
              }}
              checked={privacy && policy}
            />
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="policy"
              onChange={() => setPolicy(!policy)}
              checked={policy}
            />
            <a
              href="https://brook-eggplant-53c.notion.site/8b9b51e5800c43e6bf17f55a13095e06"
              target="blank"
            >
              <span className="font-semibold text-base cursor-pointer">
                슈퍼스쿨이용약관 (필수)
              </span>
            </a>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="privacy"
              onChange={() => setPrivacy(!privacy)}
              checked={privacy}
            />
            <a href={data?.me?.school?.privacyPolicyLink} target="blank">
              <span className="font-semibold text-base cursor-pointer">
                개인정보처리방침 (필수)
              </span>
            </a>
          </div>
        </div>
        <div className="text-pink-600">{errorMessage}</div>

        <Button
          // className="mt-20 bg-orange-2 text-white"
          disabled={buttonDisabled}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={() => {
            const regExp=/^010(?:\d{4})\d{4}$/;
            if(phone && !regExp.test(phone)){
              alert('전화번호를 확인해 주세요.');
              setLoading(false);
              return;
            }
            if (!passwordCheck(password1)) {
              setErrorMessage('안전한 비밀번호를 입력하세요.');
            } else if (password1 !== password2) {
              setErrorMessage('비밀번호와 비밀번호 확인이 다릅니다!');
            } else {
              setLoading(true);
              teacherFirstLogin()
                .then((res) => {
                  if (res) {
                    const { data, errors } = res;
                    if (data?.teacherFirstLogin?.id) {
                      alert('정보 설정이 완료되었습니다!');
                      window.location.reload();
                    } else {
                      setErrorMessage(
                        errors?.map((el: any) => el.message).join(', ') || '',
                      );
                      alert(
                        errors?.map((el: any) => el.message).join(', ') || '',
                      );
                    }
                  } else {
                    setLoading(false);
                    setErrorMessage(
                      '회원가입 정보가 정확하지 않거나 이미 가입된 이메일입니다. 정보를 다시 확인해주세요!',
                    );
                  }
                })
                .catch((err) => {
                  console.error('err', err);
                  setLoading(false);
                  if (
                    err?.message.includes(
                      'duplicate key value violates unique constraint',
                    )
                  ) {
                    setErrorMessage(
                      '이미 가입된 이메일입니다. 로그인을 시도해주세요!',
                    );
                    alert('이미 가입된 이메일입니다. 로그인을 시도해주세요!');
                  } else if (
                    err?.message.includes('Received status code 400')
                  ) {
                    setErrorMessage('필수 정보를 입력해주세요!');
                    alert('필수 정보를 입력해주세요!');
                  } else {
                    setErrorMessage(err?.message || '');
                    alert(err?.message || '');
                  }
                });
            }
          }}
        >
          정보 입력하고 시작하기
        </Button>
      </Section>
    </div>
  );
};
