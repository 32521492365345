import { Button, Label, TextArea, useMutation } from '../../../reusable';
import React, { useEffect, useState } from 'react';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import { FileItem, SurveyComponent } from '../../../components';
import {
  CREATE_OR_UPDATE_STUDENT_ACTIVITY,
  SURVEY_FILES_UPLOAD,
} from '../../../query';
import { Activity, StudentActivity } from '../../../types';
import undoArrow from '../../../assets/images/undo-arrow.png';
import imageCompression from 'browser-image-compression';

interface ActivityDetailSubmitPageProps {
  studentActivity: StudentActivity;
  activity?: Activity;
  setReadState: () => void;
  refetch: () => void;
  setLoading: (state: boolean) => void;
}

export const ActivityDetailSubmitPage: React.FC<
  ActivityDetailSubmitPageProps
> = ({ studentActivity, activity, setReadState, refetch, setLoading }) => {
  const [images, setImages] = useState<(File | null | undefined)[]>([null]);
  const [files, setFiles] = useState<(File | null | undefined)[]>([]);
  const [content, setContent] = useState(studentActivity?.content || '');
  const [willRemoveImages, setWillRemoveImages] = useState<any[]>([]);
  const [willRemoveFiles, setWillRemoveFiles] = useState<any[]>([]);
  const [summary, setSummary] = useState(studentActivity?.summary || '');

  useEffect(() => {
    if (studentActivity?.content) {
      setContent(studentActivity?.content);
    } else if (!!activity) {
      const activityContent = localStorage.getItem('activityContent');
      const activityId = localStorage.getItem('activityId');
      if (activityContent && String(activity.id) === activityId) {
        setContent(activityContent);
      }
    }
  }, []);

  useEffect(() => {
    if (activity && content) {
      localStorage.setItem('activityId', String(activity.id));
      localStorage.setItem('activityContent', content);
    }
  }, [activity, content]);

  let saFiles: any[] = [];

  if (studentActivity?.files) {
    try {
      studentActivity?.files?.map((f: any) => saFiles.push(JSON.parse(f)));
    } catch (err) {
      saFiles = studentActivity?.files || [];
    }
  }

  let saImages: any[] = [];
  try {
    saImages = studentActivity?.images
      ? studentActivity?.images.map((el) => JSON.parse(el))
      : [];
  } catch (err) {
    saImages = studentActivity?.images || [];
  }

  const [updateSA] = useMutation(CREATE_OR_UPDATE_STUDENT_ACTIVITY, {
    variables: {
      data: JSON.stringify(
        !studentActivity
          ? {
              activityId: activity?.id,
              content: content.length ? content : ' ',
              isSubmitted: true,
              deleteImages: willRemoveImages,
              deleteFiles: willRemoveFiles,
              summary,
            }
          : {
              id: studentActivity.id,
              activityId: activity?.id,
              content: content.length ? content : ' ',
              isSubmitted: true,
              deleteImages: willRemoveImages,
              deleteFiles: willRemoveFiles,
              summary,
            },
      ),
      images: images.filter((el) => {
        if (typeof el === 'string') {
          return false;
        } else if (!el) {
          return false;
        }
        return true;
      }),
      files: files.length ? files : undefined,
    },
    errorPolicy: 'ignore',
    onCompleted: async () => {
      await refetch();
      await setLoading(false);
      await setReadState();
    },
  });

  const [uploadFiles] = useMutation(SURVEY_FILES_UPLOAD);

  if (activity?.type === 'SURVEY') {
    return (
      <div className="flex flex-col items-stretch space-y-2 px-5 py-4 bg-gray-50">
        {activity?.isRecord && (
          <div className="w-full px-4">
            <Label text="활동요약" htmlFor="textarea1" />
            <div className="text-base font-semibold text-gray-600">
              {activity.explainText}
            </div>
            <textarea
              id="textarea1"
              className="border-gray-200 rounded-md border block h-48 px-4 py-2 placeholder-gray-400 w-full disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0"
              value={summary}
              onChange={(e) => {
                setSummary(e.target.value);
              }}
            />
          </div>
        )}
        <SurveyComponent
          content={activity?.content || '{}'}
          updateSA={() => updateSA()}
          setContent={(c: string) => setContent(JSON.stringify(c))}
          uploadFiles={(files: any) => uploadFiles({ variables: { files } })}
          value={content || '[]'}
        />
        {/* <Button
          onClick={() => {
            setLoading(true);
            updateSA();
          }}
        >
          제출하기
        </Button> */}
      </div>
    );
  }

  const buttonDisabled = !files.length && !images.length && !content;

  return (
    <>
      <div className="flex flex-col items-stretch space-y-4 px-5 py-4 bg-gray-50">
        {activity?.isRecord && (
          <div className="w-full px-4">
            <Label text="활동요약" htmlFor="textarea1" />
            <div className="text-base font-semibold text-gray-600">
              {activity.explainText}
            </div>
            <textarea
              id="textarea1"
              className="border-gray-200 rounded-md border block h-48 px-4 py-2 placeholder-gray-400 w-full disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0"
              value={summary}
              onChange={(e) => {
                setSummary(e.target.value);
              }}
            />
          </div>
        )}
        {activity?.isImage && (
          <>
            <div className="font-bold text-gray-800 text-lg">이미지 업로드</div>
            {saImages?.map((el: any) => (
              <div
                className={`relative pb-3/5 rounded border-2 border-dashed border-grey-5 ${
                  willRemoveImages.includes(el.data) ? 'opacity-50' : ''
                }`}
              >
                <span className="absolute -top-3 -right-3 block w-6 h-6 rounded-full ring-2 ring-white bg-red-700 z-40">
                  <div
                    className="w-full h-full flex items-center justify-center text-white cursor-pointer"
                    onClick={() =>
                      willRemoveImages.includes(el.data)
                        ? setWillRemoveImages(
                            willRemoveImages.filter(
                              (imgUrl) => imgUrl !== el.data,
                            ),
                          )
                        : setWillRemoveImages(willRemoveImages.concat(el.data))
                    }
                    style={{ transform: 'translate(0.1px, 0.1px)' }}
                  >
                    {willRemoveImages.includes(el.data) ? (
                      <img src={undoArrow} alt="" className="w-4 h-4" />
                    ) : (
                      'X'
                    )}
                  </div>
                </span>
                <img
                  src={el.url}
                  alt=""
                  className="absolute w-full h-full rounded object-cover"
                  loading="lazy"
                />
                {willRemoveImages.includes(el.data) && (
                  <div className="absolute w-full h-full z-20">
                    <div className="flex w-full h-full items-center justify-center">
                      <div className="text-4xl text-gray-800 opacity-100">
                        삭제
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
            {images?.map((el: File | null | undefined, i: number) => (
              <>
                <label htmlFor={`imageupload${i}`}>
                  <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
                    {el ? (
                      <img
                        className="absolute w-full h-full rounded object-cover"
                        src={URL.createObjectURL(el)}
                        alt=""
                      />
                    ) : (
                      <div className="absolute w-full h-full rounded object-cover bg-white">
                        <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                          <FileUploadImg />
                          <div className="text-brand-1">
                            이미지를 업로드해주세요.
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  id={`imageupload${i}`}
                  className="hidden"
                  accept=".png, .jpeg, .jpg"
                  onChange={async (e) => {
                    let size = 0;

                    images.map((el: File | string | null | undefined) => {
                      if (el instanceof File) {
                        size += el.size || 0;
                      }
                    });

                    files.map((el: File | null | undefined) => {
                      size += el?.size || 0;
                    });

                    if (size >= 20 * 1024 * 1024) {
                      alert(
                        '한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요.',
                      );
                    } else {
                      const newImages = images.slice();
                      const alreadyImages = !!newImages[i];
                      newImages[i] = e.target.files?.item(0);
                      if (!alreadyImages) {
                        newImages.push(null);
                      }

                      if (newImages[i]) {
                        const compressFile = await imageCompression(
                          newImages[i] as File,
                          { initialQuality: 0.8 },
                        );
                        newImages[i] = compressFile;
                        e.target.validity.valid && setImages(newImages);
                      }
                    }
                  }}
                />
              </>
            ))}
          </>
        )}
        {activity?.isFile && (
          <>
            <div className="font-bold text-gray-800 text-lg">파일 업로드</div>
            <div className="bg-white rounded border-2 border-dashed border-grey-5">
              {saFiles?.map((file: any) => (
                <FileItem
                  file={file}
                  canClose
                  willRemoveFiles={willRemoveFiles}
                  setWillRemoveFiles={() =>
                    willRemoveFiles.includes(file.data)
                      ? setWillRemoveFiles(
                          willRemoveFiles.filter(
                            (fileName) => fileName !== file.data,
                          ),
                        )
                      : setWillRemoveFiles(willRemoveFiles.concat(file.data))
                  }
                />
              ))}
              {files.map((file: any) => (
                <FileItem file={file} />
              ))}
              <label htmlFor="fileupload">
                <div className="w-full flex space-x-2 justify-center items-center pt-0.5 pb-2.5 cursor-pointer">
                  <span className="text-2xl text-grey-3 mb-1">+</span>
                  <div className="text-brand-1">
                    파일을 업로드해주세요 (다중 업로드 가능)
                  </div>
                </div>
              </label>
            </div>
            <input
              type="file"
              id="fileupload"
              className="hidden"
              multiple
              onChange={(e) => {
                const newFiles = e.target.files
                  ? files.concat(Array.from(e.target.files))
                  : files;

                let size = 0;

                images.map((el: File | string | null | undefined) => {
                  if (el instanceof File) {
                    size += el?.size || 0;
                  }
                });

                newFiles.map((el: File | null | undefined) => {
                  size += el?.size || 0;
                });

                if (size >= 20 * 1024 * 1024) {
                  alert(
                    '한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요.',
                  );
                } else {
                  e.target.validity.valid && setFiles(newFiles);
                }
              }}
            />
          </>
        )}
        {activity?.isContent && (
          <>
            <div className="font-bold text-gray-800 text-lg">내용 입력</div>
            <TextArea
              placeholder="내용을 입력해주세요. 작성된 활동기록부는 담당 선생님께만 노출됩니다."
              rows={5}
              style={{ height: 'auto', borderWidth: '1px' }}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </>
        )}
        <Button
          disabled={buttonDisabled}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={() => {
            setLoading(true);
            updateSA();
          }}
        >
          제출하기
        </Button>
        <br />
      </div>
    </>
  );
};
