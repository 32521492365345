import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useQuery as uQ } from 'react-query';
import { Blank, Button, Section } from '../../../reusable';
import { ErrorBlank, Td } from '../../../components';
import { fetcher } from '../../../plugins';
import { Group, StudentGroup, StudentNewsletter } from '../../../types';
import { makeDateToString, makeTimeToString } from '../../../utils';

interface MatchParams {
  id: string;
}

const NewsletterDownloadPage: FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  let { id } = match.params;

  const { error, data } = uQ(`/newsletter/${id}`, fetcher);

  const newsletter = data?.newsletter;
  const studentNewsletters = newsletter?.studentNewsletters;
  const groups = data?.groups;

  let surveyContent: any[] = [];

  try {
    surveyContent = JSON.parse(newsletter?.surveyContent);
  } catch (err) {
    console.error(err);
  }

  const csvData: any = [];

  csvData.push([
    '학년',
    '반',
    '번호',
    '이름',
    '제출여부',
    '제출시간',
    ...surveyContent.map((q: any) => q.title),
    '학생 승인',
    '보호자 승인',
  ]);

  groups?.map((g: Group) => {
    let grade = g.name?.split('학년')[0].trim();
    let klass = g.name?.split('학년')[1].split('반')[0].trim();
    g.studentGroups?.forEach((sg: StudentGroup) => {
      const studentNewsletter = studentNewsletters?.filter(
        (sn: StudentNewsletter) => sn.student?.id === sg.user?.id,
      )[0];
      if (studentNewsletter && studentNewsletter.isSubmitted) {
        let content: any = {};
        try {
          content = JSON.parse(studentNewsletter?.content);
        } catch (err) {
          console.error(err);
          return;
        }

        csvData.push([
          grade,
          klass,
          sg.studentNumber,
          sg.user?.name,
          '제출',
          studentNewsletter?.updatedAt
            ? makeDateToString(studentNewsletter.updatedAt) +
              ' ' +
              makeTimeToString(studentNewsletter.updatedAt)
            : '',
          ...surveyContent.map((q: any) => content[q.id]),
          'O',
          studentNewsletter?.parentSignature ? 'O' : 'X',
        ]);
      } else {
        csvData.push([
          grade,
          klass,
          sg.studentNumber,
          sg.user?.name,
          '미제출',
          '',
          ...surveyContent.map((q: any) => ''),
          'X',
          'X',
        ]);
      }
    });
  });

  studentNewsletters?.map((sn: any) => {
    let content: any = {};
    try {
      content = JSON.parse(sn?.content);
    } catch (err) {
      console.error(err);
    }
  });

  if (error) {
    return <ErrorBlank />;
  }
  if (!data) {
    return <Blank reversed />;
  }
  return (
    <div className="bg-white border rounded-lg p-5">
      <div className="fixed top-0 py-3 px-5 pt-6 bg-white flex items-center space-x-2 w-full border-b border-gray-500">
        <CSVLink data={csvData} filename={`${newsletter?.title}.csv`}>
          <Button
            text="다운로드"
            tw={{ height: 'h-11', minWidth: 'min-w-max' }}
            style={{ width: '70%' }}
          />
        </CSVLink>
      </div>
      <Section tw={{ marginTop: 'mt-12' }}>
        <div className="text-xl font-bold">미리보기</div>
        <table>
          <tbody>
            {csvData.slice(0, 10).map((row: any) => (
              <tr>
                {row.map((cell: any) => (
                  <Td innerClassName="min-w-max">{String(cell)}</Td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Section>
    </div>
  );
};

export default withRouter(NewsletterDownloadPage);
