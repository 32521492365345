import React, { FC, useEffect, useState } from 'react';
import { useQuery as uQ, useMutation } from 'react-query';
import _ from 'lodash';
import {
  useQueryBasic,
  Button,
  TextField,
  Section,
  Tabs,
  Tab,
  Select,
  useRecoilValue,
} from '../../../reusable';
import { fetcher, api } from '../../../plugins';
import {
  ErrorBlank,
  SelectMenus,
  TimetableAtdCard,
  TimetableStudentRole,
  SuperModal,
} from '../../../components';
import { KlassInfo, AttendanceType, AbsentSave } from '../../../types';
import { toLocaleDateFormatString, weekCount } from '../../../utils';
import { meState } from '../../../store';
import svgUserIcon from '../../../assets/svg/user_icon.svg';

const groups = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
  { id: 7, name: '7' },
  { id: 8, name: '8' },
  { id: 9, name: '9' },
];

enum contentType {
  list = 1,
  seat,
  role,
}

enum listType {
  total = 1,
  in,
  out,
  del,
}

interface TimetableAttendancePageProps {
  year: number;
  semester: number;
  groupId: number | undefined;
  klassInfo: KlassInfo;
}

interface AbsentUser {
  id: number;
  profile: string;
  klassname: string;
  student_number: string;
  name: string;
  hope: string;
  major: string;
  absent: boolean;
  content: string;
  comment: string;
  type1: string;
  type2: string;
  role: string;
}

interface SeatType {
  id: number;
  seatPosition: string;
}

const lastPeriod = 8; // 동적으로 바꿔야 함.

export const TimetableAttendancePageV2: FC<TimetableAttendancePageProps> = ({
  year,
  semester,
  groupId,
  klassInfo,
}) => {
  const me = useRecoilValue(meState);

  const klassName = klassInfo?.name;

  let _date = new Date();
  let _year = _date.getFullYear();
  // let _month = ("0" + (1 + _date.getMonth())).slice(-2);
  // let _day = ("0" + _date.getDate()).slice(-2);

  // const toDay = _year + "-" + _month + "-" + _day;

  let [tselweek, selmonday, seltuesday, selwednesday, selthursday, selfriday] =
    weekCount(_date);

  let targetDay = '';

  // klassInfo.timeCode = 요일_몇교시
  let selectedPeriod = Number(klassInfo.timeCode.split('_')[1]);

  if (klassInfo.timeCode.startsWith('mon')) {
    targetDay = toLocaleDateFormatString(selmonday);
  } else if (klassInfo.timeCode.startsWith('tue')) {
    targetDay = toLocaleDateFormatString(seltuesday);
  } else if (klassInfo.timeCode.startsWith('wed')) {
    targetDay = toLocaleDateFormatString(selwednesday);
  } else if (klassInfo.timeCode.startsWith('thu')) {
    targetDay = toLocaleDateFormatString(selthursday);
  } else if (klassInfo.timeCode.startsWith('fri')) {
    targetDay = toLocaleDateFormatString(selfriday);
  }

  const makeStringKlassName = (kn: string) => {
    const Rx = /^[0-9]-[0-9]{1,2}$/;

    //return kn;
    return Rx.test(kn) ? kn.replace('-', '학년 ') + '반' : kn;
  };

  const queryString = useQueryBasic({
    year: year,
    klassname: makeStringKlassName(klassName),
    day: targetDay,
    subject: klassInfo.subject,
    teachername: klassInfo.teacherName,
  });
  //const { error, data } = uQ(`/attendance/students${queryString}`, fetcher);
  const { error, data, refetch } = uQ(
    `/users/attendance${queryString}`,
    fetcher,
    { refetchOnWindowFocus: false },
  );

  // const {  mutate, isLoading, isError, error:aa, isSuccess } = useMutation( (absent:AttendanceType) => {
  //   return api.post<AttendanceType>(process.env.REACT_APP_API_URL +'/attendance/absent', absent);
  // });

  const { mutate } = useMutation(
    (absent: AttendanceType) =>
      api.post<AttendanceType>(
        process.env.REACT_APP_API_URL + '/attendance/absent',
        absent,
      ),
    {
      // onCompleted:() =>{
      //   console.log("onCompleted");
      //   //refetch();
      // },
      onSuccess: () => {
        //console.log('출석변경성공');
        refetch();
      },
      onError: (error) => {
        // 요청에 에러가 발생된 경우
        console.log('onError');
      },
      onSettled: () => {
        // 요청이 성공하든, 에러가 발생되든 실행하고 싶은 경우
        //console.log('onSettled');
      },
    },
  );

  const { mutate: mutateSeat } = useMutation(
    (seat: SeatType) =>
      api.post<SeatType>(
        process.env.REACT_APP_API_URL + '/timetable/seat',
        seat,
      ),
    {
      onSuccess: () => {
        console.log('출석변경성공');
        refetch();
      },
      onError: (error) => {
        // 요청에 에러가 발생된 경우
        console.log('onError');
      },
      onSettled: () => {
        // 요청이 성공하든, 에러가 발생되든 실행하고 싶은 경우
        console.log('onSettled');
      },
    },
  );

  const { mutate: mutateRole } = useMutation(
    (role: { role: string }) =>
      api.post<{ role: string }>(
        process.env.REACT_APP_API_URL + '/studentrole/role',
        role,
      ),
    {
      onSuccess: () => {
        console.log('일인일역 저장 성공');
        refetch();
      },
      onError: (error) => {
        // 요청에 에러가 발생된 경우
        console.log('onError');
      },
      onSettled: () => {
        // 요청이 성공하든, 에러가 발생되든 실행하고 싶은 경우
        //console.log('onSettled');
      },
    },
  );
  const [showSubject, setShowSubject] = useState(false);

  const [showAbsent, setShowAbsent] = useState(listType.total);

  const [showSeat, setShowSeat] = useState(contentType.list);
  const [selectedUserId, setSelectedUserId] = useState<AbsentUser>({
    id: -1,
    profile: '',
    klassname: '',
    student_number: '',
    name: '',
    hope: '',
    major: '',
    absent: false,
    content: '',
    comment: '',
    type1: '',
    type2: '',
    role: '',
  });
  const [seatSizeRows, setSeatSizeRows] = useState<any>({ id: 0, name: '0' });
  const [seatSizeCols, setSeatSizeCols] = useState<any>({ id: 6, name: '6' });

  const [roleInfo, setRoleInfo] = useState<any[]>([]);

  const [seatEditMode, setSeatEditMode] = useState(false);
  const [roleEditMode, setRoleEditMode] = useState(false);

  let studentsAbsent: Array<number> = [];
  const students = data?.attendance?.userAttendance;
  const teacher = data?.attendance?.teacher[0]?.name;
  const seatPosition = data?.attendance?.seatPosition[0]?.seat_position;
  const seatPositionId = data?.attendance?.seatPosition[0]?.id;

  const removeStudents = new Map<number, boolean>();
  const absentOfSelectedPeriod = new Map<number, boolean>();
  const absentCommentOfSelectedPeriod = new Map<number, string>();

  //if (klassInfo.myClass && students) {
  if (students) {
    studentsAbsent = [];

    students.map((student: any) => {
      if (student.expired) {
        removeStudents.set(student.id, true);
        absentCommentOfSelectedPeriod.set(student.id, '');
      } else {
        if (student.content) {
          const contentJson = JSON.parse(student.content);

          absentOfSelectedPeriod.set(
            student.id,
            contentJson.attendance[selectedPeriod].absent,
          );
          absentCommentOfSelectedPeriod.set(
            student.id,
            contentJson.attendance[selectedPeriod].comment,
          );

          if (contentJson.attendance[selectedPeriod].absent) {
            studentsAbsent.push(student.student_number);
          }

          if (
            contentJson.attendance[selectedPeriod].type1 !== '' &&
            contentJson.attendance[selectedPeriod].type2 !== ''
          ) {
            student.type1 = contentJson.attendance[selectedPeriod].type1;
            student.type2 = contentJson.attendance[selectedPeriod].type2;
          }
        } else {
          absentOfSelectedPeriod.set(student.id, false);
          absentCommentOfSelectedPeriod.set(student.id, '');
        }
      }
    });
  }

  let tempRoleInfo: any[] = [];
  _.sortBy(students, 'display_order')?.map((student: any, i: number) => {
    if (student.expired === false) {
      tempRoleInfo.push({
        id: student.id,
        klassname: student.klassname,
        student_number: student.student_number,
        name: student.name,
        role: student.role ? student.role : '',
        job: student.job ? student.job : '',
        //displayOrder: student.displayOrder,
        displayOrder: i,
      });
    }
  });
  useEffect(() => {
    setRoleEditMode(false);
    setRoleInfo(tempRoleInfo?.map((el) => el));
  }, [students]);

  /// userId : 결석처리할 학색,
  /// absent : true : 미출석, false : 출석
  /// content : 이전시간 출석 기록
  const setAbsentUserId = (submit: boolean) => {
    if (submit && selectedUserId.id > 0) {
      let absentInfo: AbsentSave[] = [];
      let contentJson = { attendance: absentInfo };

      let type1 = '';
      let type2 = '';

      if (selectedUserId.absent === true) {
        type1 =
          !selectedUserId.type1 || selectedUserId.type1 === ''
            ? '기타'
            : selectedUserId.type1;
        type2 =
          !selectedUserId.type2 || selectedUserId.type2 === ''
            ? '기타'
            : selectedUserId.type2;
      }

      // 기초데이터 생성
      if (selectedUserId.content) {
        contentJson = JSON.parse(selectedUserId.content);
      } else {
        for (let i = 0; i <= lastPeriod; i++) {
          const t = {
            subject: i === 0 ? '조회' : '',
            period: i,
            creator: me?.name ? me?.name : '',
            createtime: new Date().toLocaleString(),
            editor: '',
            edittime: '',
            comment: '',
            absent: i < selectedPeriod ? false : selectedUserId.absent, // 교과반에서 수정시에는 이후시간에 출결만 반영
            type1: '',
            type2: '',
          };

          contentJson.attendance.push(t);
        }
      }

      // 선택한 다음시간부터  동일한 상태로 만든다.
      for (let i = selectedPeriod + 1; i <= lastPeriod; i++) {
        // if (contentJson.attendance[i].editor === '') {
        contentJson.attendance[i].type1 = type1;
        contentJson.attendance[i].type2 = type2;
        contentJson.attendance[i].absent = selectedUserId.absent;
        // } else {
        //   break;
        // }
      }

      contentJson.attendance[selectedPeriod].subject = klassInfo.subject;
      contentJson.attendance[selectedPeriod].comment = selectedUserId.comment;
      contentJson.attendance[selectedPeriod].type1 = type1;
      contentJson.attendance[selectedPeriod].type2 = type2;
      contentJson.attendance[selectedPeriod].editor = me?.name ? me?.name : '';
      contentJson.attendance[selectedPeriod].edittime =
        new Date().toLocaleString();
      contentJson.attendance[selectedPeriod].absent = selectedUserId.absent;

      mutate({
        attendanceday: targetDay,
        absent: selectedUserId.absent,
        comment: selectedUserId.comment,
        type1: type1,
        type2: type2,
        content: JSON.stringify(contentJson),
        year: year,
        semester: semester,
        userid: selectedUserId.id,
      });
    }

    //팝업창 닫기
    setSelectedUserId({
      id: -1,
      profile: '',
      klassname: '',
      student_number: '',
      name: '',
      hope: '',
      major: '',
      absent: false,
      content: '',
      comment: '',
      type1: '',
      type2: '',
      role: '',
    });
  };

  let moveKlass = false;

  students?.map((student: any) => {
    if (student.klassname !== klassInfo.name) {
      moveKlass = true;
    }
  });

  let seatPositionMap = JSON.parse(
    seatPosition && !moveKlass ? seatPosition : '[]',
  ); // key : studentId, value : row col

  let maxCol = 6;
  let maxRow = 0;

  // 설정된 자리 크기를 가져온다. to-do
  if (seatPositionMap.length > 0) {
    let tmpRow = 1;
    let tmpCol = 1;
    seatPositionMap.map((seatInfo: any) => {
      let row = Math.floor(Number(seatInfo?.seat) / 10);
      let col = Number(seatInfo?.seat) % 10;

      tmpRow = row > tmpRow ? row : tmpRow;
      tmpCol = col > tmpCol ? col : tmpCol;
    });

    maxCol = tmpCol + 1;
    maxRow = tmpRow;
  }

  let newSeatCount = 0;
  // 누락된 학생 찾아서 넣기 start
  const getNewSeat = () => {
    let newRow = maxRow + Math.floor(newSeatCount / maxCol);
    let newCol = newSeatCount % maxCol;

    newSeatCount++;
    return newRow.toString() + newCol.toString();
  };

  students?.map((student: any) => {
    if (student.expired === false) {
      const rst = seatPositionMap?.find(
        (seatinfo: any) => seatinfo.studentid === student.id,
      );
      if (rst === undefined) {
        seatPositionMap.push({
          studentid: student.id,
          seat: `${getNewSeat()}`,
        });
      }
    }
  });
  maxRow = maxRow + Math.floor(newSeatCount / maxCol);

  useEffect(() => {
    if (maxRow >= 0) {
      setSeatSizeRows(groups[maxRow]);
      setSeatSizeCols(groups[maxCol - 1]); // 인덱스가 0부터 시작하므로,
    }
  }, [maxRow]);

  // 누락된 학생 찾아서 넣기 end

  // dom 에서 자리의 학생 가져오기
  let seatSudentMap = new Map<string, any>();
  const getStudentSeat = (row: number, col: number) => {
    let seat = row.toString() + col.toString();

    let rststudent: any;

    if (!seatSudentMap.has(seat)) {
      const rst = seatPositionMap?.find(
        (seatinfo: any) => seatinfo.seat === seat,
      );
      let studentId = rst?.studentid;

      rststudent = students?.find((student: any) => student.id === studentId);

      if (rststudent?.expired) {
        rststudent = undefined;
      }

      if (rststudent) {
        seatSudentMap.set(seat, rststudent);
      } else {
        // 자리설정은 되었지만 삭제된 학생
        seatPositionMap = seatPositionMap.filter((obj: any) => {
          return obj !== rst;
        });
      }

      //console.log(seat);
    } else {
      rststudent = seatSudentMap.get(seat);
      //console.log('hit');
    }

    return rststudent;
  };

  //let selSeat: string = '';

  const [selSeat, setSelSeat] = useState('');

  const swapSeat = (row: number, col: number) => {
    let seat = row.toString() + col.toString();

    if (selSeat === '') {
      //selSeat = seat;
      setSelSeat(seat);
    } else {
      // selSeat 와 seat 변경
      const selInfo = seatPositionMap?.find(
        (seatinfo: any) => seatinfo.seat === selSeat,
      );
      const nowInfo = seatPositionMap?.find(
        (seatinfo: any) => seatinfo.seat === seat,
      );

      if (selInfo !== undefined) selInfo.seat = seat;
      if (nowInfo !== undefined) nowInfo.seat = selSeat;

      saveStudentSeat();
      setSelSeat('');
    }
  };

  const swapSeatDrop = (selSeat: string, nowSeat: string) => {
    // selSeat 와 seat 변경
    const selInfo = seatPositionMap?.find(
      (seatinfo: any) => seatinfo.seat === selSeat,
    );
    const nowInfo = seatPositionMap?.find(
      (seatinfo: any) => seatinfo.seat === nowSeat,
    );

    if (selInfo !== undefined) selInfo.seat = nowSeat;
    if (nowInfo !== undefined) nowInfo.seat = selSeat;

    saveStudentSeat();
  };

  const saveStudentSeat = () => {
    if (seatEditMode) {
      let seatPosition = JSON.stringify(seatPositionMap);

      mutateSeat({
        id: seatPositionId,
        seatPosition: seatPosition,
      });
    }
  };

  const checkSeatSize = (isCol: boolean, size: Number) => {
    if ((isCol && maxCol > size) || (!isCol && maxRow + 1 > size)) {
      //alert(maxCol.toString() + ' / ' + maxRow.toString());
      alert('학생이 설정되어 있는 자리는 삭제할 수 없습니다.');
      return false;
    } else {
      return true;
    }
  };

  const saveRole = () => {
    if (roleEditMode) {
      let roleInfos: any[] = [];

      // tempRoleInfo?.map((student: any) => {
      roleInfo?.map((student: any, i: number) => {
        if (
          (student.role && student.role !== '') ||
          (student.job && student.job !== '')
        ) {
          roleInfos.push({
            id: student.id,
            // role: student.rolen ? student.rolen : student.role,
            // job: student.jobn ? student.jobn : student.job,
            role: student.role,
            job: student.job,
            displayOrder: i,
            year: year,
          });
        }
      });

      let roleInfosStr = JSON.stringify(roleInfos);

      mutateRole({ role: roleInfosStr });
    }
  };

  let from = -1;

  return (
    <div className="w-full">
      {/* {loading && <Blank reversed />} */}
      {error && <ErrorBlank />}
      <div>
        <div>{klassInfo.name}</div>
        <div className="md:text-xl font-semibold my-3 flex justify-between">
          <p>
            <div className="flex">
              {klassInfo.time} |{' '}
              {klassInfo.type === 'move' ? (
                <>
                  <div className="ml-2">분반</div>
                  <div
                    className="text-sm md:text-base bg-blue-100 text-blue-600 px-2.5 mx-2 rounded"
                    onClick={() => setShowSubject(!showSubject)}
                  >
                    과목보기
                  </div>
                </>
              ) : (
                klassInfo.subject
              )}
              {/* {klassInfo.subject} */}
            </div>
          </p>
          <p> {teacher ? `담임 : ${teacher} 선생님` : ''}</p>
        </div>
        {showSubject && (
          <div className="bg-grey-100 border rounded-lg px-5 mb-3 flex justify-between items-center">
            {klassInfo.subject}
          </div>
        )}
        <div className="bg-grey-100 border rounded-lg p-5 flex justify-between items-center">
          <p
            className={`cursor-pointer ${
              showAbsent === listType.total && 'font-extrabold text-red-500'
            }`}
            onClick={() => setShowAbsent(listType.total)}
          >
            총원 : {students?.length - removeStudents.size}명
          </p>
          <p
            className={`cursor-pointer ${
              showAbsent === listType.in && 'font-extrabold text-red-500'
            }`}
            onClick={() => setShowAbsent(listType.in)}
          >
            출석 :{' '}
            {students?.length - removeStudents.size - studentsAbsent?.length}명
          </p>
          <p
            className={`cursor-pointer ${
              showAbsent === listType.out && 'font-extrabold text-red-500'
            }`}
            onClick={() => setShowAbsent(listType.out)}
          >
            미출석 : {studentsAbsent?.length}명
          </p>
          <p
            className={`hidden md:inline cursor-pointer ${
              showAbsent === listType.del && 'font-extrabold text-red-500'
            }`}
            onClick={() => setShowAbsent(listType.del)}
          >
            제적
          </p>
        </div>
      </div>
      <Tabs>
        <Tab
          text="출석부"
          active={showSeat === contentType.list}
          onClick={() => {
            setSeatEditMode(false);
            setRoleEditMode(false);
            setShowSeat(contentType.list);
          }}
        />
        <Tab
          text="자리"
          active={showSeat === contentType.seat}
          onClick={() => {
            setSeatEditMode(false);
            setRoleEditMode(false);
            setShowSeat(contentType.seat);
          }}
        />
        <Tab
          text="일인일역"
          active={showSeat === contentType.role}
          onClick={() => {
            setSeatEditMode(false);
            setRoleEditMode(false);
            setShowSeat(contentType.role);
          }}
        />
      </Tabs>

      {/* 자리표시 */}
      {showSeat === contentType.seat && (
        <div className="-mx-5 overflow-x-auto scroll-box">
          <table
            className="text-center py-4 mx-auto border-separate"
            style={{ borderSpacing: '0.5rem 0.5rem' }}
          >
            <tbody>
              {Array.from(Array(seatSizeRows.id).keys())
                .reverse()
                .map((r: number) => (
                  <tr>
                    {Array(seatSizeCols.id)
                      .fill(0)
                      .map((_: any, c: number) => (
                        <td
                          className={`border border-grey-7 ${
                            selSeat === r.toString() + c.toString()
                              ? 'bg-blue-300'
                              : getStudentSeat(r, c)?.profile
                              ? absentOfSelectedPeriod.get(
                                  getStudentSeat(r, c)?.id,
                                )
                                ? 'bg-red-300'
                                : 'bg-white'
                              : 'bg-grey-6'
                          } cursor-pointer w-14 h-24 min-h-24 rounded-md`}
                          draggable
                          onClick={() => {
                            if (seatEditMode) {
                              swapSeat(r, c);
                            } else {
                              let student = getStudentSeat(r, c);
                              setSelectedUserId({
                                id: student?.id,
                                profile: student?.profile,
                                klassname: student?.klassname,
                                student_number: student?.student_number,
                                name: student?.name,
                                hope: student?.hopepath,
                                major: student?.hopemajor,
                                absent: absentOfSelectedPeriod.get(student?.id)
                                  ? true
                                  : false,
                                content: student?.content,
                                comment: absentCommentOfSelectedPeriod.get(
                                  student?.id,
                                ) as string,
                                type1: student?.type1,
                                type2: student?.type2,
                                role: student?.role,
                              });
                            }
                          }}
                          onDragStart={(ev) => {
                            if (seatEditMode) {
                              ev.dataTransfer.setData(
                                'location',
                                r.toString() + c.toString(),
                              );
                            }
                          }}
                          onDrop={(ev) => {
                            if (seatEditMode) {
                              ev.preventDefault();
                              swapSeatDrop(
                                ev.dataTransfer.getData('location'),
                                r.toString() + c.toString(),
                              );
                            }
                          }}
                          onDragOver={(ev) => {
                            if (seatEditMode) {
                              ev.preventDefault();
                            }
                          }}
                        >
                          {getStudentSeat(r, c)?.profile && (
                            <>
                              {/* <div
                                className=" w-16 h-16 rounded-md bg-white bg-top bg-no-repeat bg-cover z-10 "
                                style={{
                                  backgroundImage: `url(${
                                    getStudentSeat(r, c).profile
                                  })`,
                                }}
                              ></div> */}
                              <div
                                className=" w-14 h-16 rounded-md bg-white z-10 overflow-hidden"
                                //style={{ backgroundImage: `url(${selectedUserId.profile})` }}
                              >
                                <img
                                  src={getStudentSeat(r, c).profile}
                                  alt=""
                                  loading="lazy"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = svgUserIcon;
                                    currentTarget.className = 'w-full ';
                                  }}
                                />
                              </div>
                              <div>
                                {getStudentSeat(r, c)?.name}
                                {/* {r}
                                {c} */}
                              </div>
                            </>
                          )}
                          {!getStudentSeat(r, c)?.profile && (
                            <div className="w-14"></div>
                          )}
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>

          {moveKlass && (
            <div className="w-full flex items-center justify-center space-x-2 mb-6 text-red-500">
              이동수업은 학번 순으로 자리가 표시됩니다.
            </div>
          )}
          <br />
          <div className="w-full flex items-center justify-center space-x-2 mb-6">
            <div className="border border-grey-7 bg-grey-6 px-3 py-2 rounded-md">
              교탁
            </div>
          </div>

          {klassInfo.myClass && (
            <div className="w-full flex items-center justify-center flex-col">
              {seatEditMode && (
                <div className="flex items-center space-x-2">
                  <div className=" min-w-max cursor-pointer flex items-center space-x-2">
                    <SelectMenus
                      allText="열 선택"
                      items={groups}
                      onChange={(e) =>
                        checkSeatSize(true, e.id) && setSeatSizeCols(e)
                      }
                      value={seatSizeCols}
                    ></SelectMenus>
                    <div>열</div>
                  </div>

                  <div className=" min-w-max cursor-pointer flex items-center space-x-2">
                    <SelectMenus
                      allText="줄 선택"
                      items={groups}
                      onChange={(e) =>
                        checkSeatSize(false, e.id) && setSeatSizeRows(e)
                      }
                      value={seatSizeRows}
                    ></SelectMenus>
                    <div>줄</div>
                  </div>
                </div>
              )}

              {me?.name === teacher && (
                <div className=" cursor-pointer mt-6">
                  <Button
                    onClick={() => {
                      setSelSeat('');
                      setSeatEditMode(!seatEditMode);
                      //saveStudentSeat();
                    }}
                  >
                    {seatEditMode ? '변경완료' : '자리변경'}
                  </Button>
                </div>
              )}
              {me?.name !== teacher && '* 담임선생님만 수정이 가능합니다. '}
            </div>
          )}
          <br />
          <br />
        </div>
      )}

      {/* 목록표시 */}
      {showSeat === contentType.list && (
        <div className="mb-10 -mx-4">
          {students
            ?.sort((a: any, b: any) => {
              const aData = a.klassname.match(
                `([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`,
              );
              const bData = b.klassname.match(
                `([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`,
              );

              if (aData[1] && bData[1]) {
                if (aData[1] === bData[1]) {
                  return Number(aData[2]) - Number(bData[2]);
                } else {
                  return Number(aData[1]) - Number(bData[1]);
                }
              } else {
                return 0;
              }
            })
            ?.filter((student: any) =>
              showAbsent === listType.in
                ? removeStudents.get(student.id) === false ||
                  absentOfSelectedPeriod.get(student.id) === false
                : showAbsent === listType.out
                ? absentOfSelectedPeriod.get(student.id)
                : showAbsent === listType.del
                ? removeStudents.get(student.id)
                : true,
            )
            .map((student: any, i: number) => (
              <TimetableAtdCard
                student={student}
                comment={
                  absentCommentOfSelectedPeriod.get(student.id) as string
                }
                attendance={!absentOfSelectedPeriod.get(student.id)}
                setModalOpen={() =>
                  setSelectedUserId({
                    id: student.id,
                    profile: student.profile,
                    klassname: student.klassname,
                    student_number: student.student_number,
                    name: student.name,
                    hope: student.hopepath,
                    major: student.hopemajor,
                    absent: absentOfSelectedPeriod.get(student.id)
                      ? true
                      : false,
                    content: student.content,
                    comment: absentCommentOfSelectedPeriod.get(
                      student?.id,
                    ) as string,
                    type1: student.type1,
                    type2: student.type2,
                    role: student?.role,
                  })
                }
              />
            ))}
        </div>
      )}

      {/* 일인일역 */}
      {showSeat === contentType.role && (
        <>
          <div className="mb-10 -mx-4">
            {/* {tempRoleInfo?.map((student: any, i: number) => ( */}
            {roleInfo?.map((student: any, i: number) => (
              <div
                className={`cursor-pointer w-full`}
                draggable={roleEditMode}
                data-id={i}
                key={i}
                onDragStart={(e) => {
                  if (roleEditMode) {
                    let item = e.currentTarget;
                    from = Number(item.dataset.id);
                  }
                }}
                onDrop={(e) => {
                  if (roleEditMode) {
                    if (from >= 0) {
                      let item = e.currentTarget;
                      let to = Number(item.dataset.id);

                      let tempList = roleInfo;

                      tempList.splice(to, 0, tempList.splice(from, 1)[0]);
                      setRoleInfo(tempList?.map((el) => el));
                    }
                  }
                }}
                onDragOver={(ev) => {
                  if (roleEditMode) {
                    ev.preventDefault();
                  }
                }}
              >
                <TimetableStudentRole
                  student={student}
                  editmode={roleEditMode}
                  order={i}
                  setOrder={(order: number, isUpDir: boolean) => {
                    let from = order;
                    let to = isUpDir ? from - 1 : from + 1;

                    if (to >= 0) {
                      let tempList = roleInfo;

                      tempList.splice(to, 0, tempList.splice(from, 1)[0]);
                      setRoleInfo(tempList?.map((el) => el));
                    }
                  }}
                />
              </div>
            ))}
          </div>
          <div className="w-full flex items-center justify-center flex-col">
            {me?.name === teacher && (
              <div className=" cursor-pointer mt-6">
                <Button
                  onClick={() => {
                    if (roleEditMode) saveRole();
                    setRoleEditMode(!roleEditMode);
                  }}
                >
                  {roleEditMode ? '저장하기' : '수정하기'}
                </Button>
              </div>
            )}
            {me?.name !== teacher && '* 담임선생님만 수정이 가능합니다. '}
          </div>
          <br />
          <br />
        </>
      )}

      {/* 상태변경 */}

      <SuperModal
        modalOpen={selectedUserId.id >= 0}
        setModalClose={() => setAbsentUserId(false)}
        ablePropragation
      >
        <Section tw={{ spaceY: 'space-y-2' }}>
          <div className="font-semibold text-lg">출결관리</div>
          <div className="flex justify-between">
            {/* <div
              className=" w-40 h-40 rounded-md bg-white bg-top-1 bg-no-repeat bg-cover z-10 "
              style={{ backgroundImage: `url(${selectedUserId.profile})` }}
            /> */}
            <div
              className=" w-40 h-40 rounded-md bg-white z-10 overflow-hidden"
              //style={{ backgroundImage: `url(${selectedUserId.profile})` }}
            >
              <img
                src={selectedUserId.profile}
                alt=""
                loading="lazy"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = svgUserIcon;
                  currentTarget.className = 'w-full ';
                }}
              />
            </div>

            <div className="w-25 h-40 text-lg font-semibold align-text-bottom text-ellipsis">
              {selectedUserId.role}
            </div>
          </div>
          <div>
            <div className=" text-lg font-bold">
              {selectedUserId.klassname} {selectedUserId.student_number}번{' '}
              {selectedUserId.name}
            </div>
            <div className="text-lg font-bold"></div>

            <div className="text-sm text-grey-2">
              희망학과 | {selectedUserId.major}
            </div>
            <div className="text-sm text-grey-2">
              장래희망 | {selectedUserId.hope}
            </div>
          </div>
          <div className="w-full border-b border-gray-100" />
          <div className="w-full flex items-center justify-between space-x-2">
            <Button
              tw={{
                backgroundColor: selectedUserId.absent
                  ? 'bg-gray-100'
                  : 'bg-blue-500',
                color: selectedUserId.absent ? 'text-gray-500' : 'text-white',
                width: 'w-full',
              }}
              onClick={() =>
                setSelectedUserId((prevState) => ({
                  ...prevState,
                  absent: false,
                }))
              }
            >
              출석
            </Button>
            <Button
              tw={{
                backgroundColor: selectedUserId.absent
                  ? 'bg-red-50'
                  : 'bg-gray-100',
                color: selectedUserId.absent ? 'text-red-500' : 'text-gray-500',
                width: 'w-full',
              }}
              onClick={() =>
                setSelectedUserId((prevState) => ({
                  ...prevState,
                  absent: true,
                }))
              }
            >
              미출석
            </Button>
          </div>
          <TextField
            label="사유"
            placeholder="특기사항를 입력해주세요."
            onChange={(e) =>
              setSelectedUserId({ ...selectedUserId, comment: e.target.value })
            }
            value={selectedUserId.comment}
          />
          <div className="w-full flex items-end justify-between space-x-2">
            <Select
              label="신고유형"
              disabled={!selectedUserId.absent}
              twOuter={{ width: 'w-full' }}
              value={selectedUserId.type2}
              onChange={(e) =>
                setSelectedUserId({ ...selectedUserId, type2: e.target.value })
              }
            >
              <option selected hidden>
                선택
              </option>
              {['인정', '질병', '기타', '미인정'].map((subject: string) => (
                <option value={subject} key={subject}>
                  {subject}
                </option>
              ))}
            </Select>
            <Select
              label=" "
              disabled={!selectedUserId.absent}
              twOuter={{ width: 'w-full' }}
              value={selectedUserId.type1}
              onChange={(e) =>
                setSelectedUserId({ ...selectedUserId, type1: e.target.value })
              }
            >
              <option selected hidden>
                선택
              </option>
              {['결석', '지각', '조퇴', '결과', '기타'].map(
                (subject: string) => (
                  <option value={subject} key={subject}>
                    {subject}
                  </option>
                ),
              )}
            </Select>
          </div>
          <Button
            tw={{ width: 'w-full' }}
            onClick={() => setAbsentUserId(true)}
          >
            저장하기
          </Button>
        </Section>
      </SuperModal>
    </div>
  );
};
