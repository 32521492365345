import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Absent } from '../../types';
import { makeStartEndToString } from '../../utils';

interface AbsentCardProps {
  absent: Absent;
  userRole: string;
  submitAbsent: any;
  submitNiceAbsent: any;
  page: number;
  limit: number;
}

export const AbsentCard: React.FC<AbsentCardProps> = ({
  absent,
  userRole,
  submitAbsent,
  submitNiceAbsent,
  page,
  limit,
}) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const [clicked, setClicked] = useState(false);
  let role = '선생님';

  if (userRole === 'PREHEAD') {
    role = '학년계';
  }
  if (userRole === 'HEAD') {
    role = '학년부장';
  }
  if (userRole === 'PREPRINCIPAL') {
    role = '교무계';
  }
  if (userRole === 'PRINCIPAL') {
    role = '교무부장';
  }
  if (userRole === 'VICEPRINCIPAL') {
    role = '교감';
  }
  if (userRole === 'HEADPRINCIPAL') {
    role = '교장';
  }

  let text = <div className="text-sm text-red-500">{role} 승인 전</div>;

  switch (absent?.absentStatus) {
    case 'BEFORE_PARENT_CONFIRM':
      text = (
        <div className="md:text-sm text-xs text-red-500">보호자 승인 전</div>
      );
      break;
    case 'BEFORE_TEACHER_APPROVAL':
      text = (
        <div className="md:text-sm text-xs text-red-500">선생님 승인 전</div>
      );
      break;
    case 'BEFORE_PREHEAD_APPROVAL':
      text = (
        <div className="md:text-sm text-xs text-red-500">학년계 승인 전</div>
      );
      break;
    case 'BEFORE_HEAD_APPROVAL':
      text = (
        <div className="md:text-sm text-xs text-red-500">학년부장 승인 전</div>
      );
      break;
    case 'BEFORE_PREPRINCIPAL_APPROVAL':
        text = (
          <div className="md:text-sm text-xs text-red-500">교무계 승인 전</div>
        );
        break;
    case 'BEFORE_PRINCIPAL_APPROVAL':
      text = (
        <div className="md:text-sm text-xs text-red-500">교무부장 승인 전</div>
      );
      break;
    case 'BEFORE_VICEPRINCIPAL_APPROVAL':
      text = (
        <div className="md:text-sm text-xs text-red-500">교감 승인 전</div>
      );
      break;
    case 'BEFORE_HEADPRINCIPAL_APPROVAL':
      text = (
        <div className="md:text-sm text-xs text-red-500">교장 승인 전</div>
      );
      break;
    case 'PROCESSED':
      text = <div className="md:text-sm text-xs text-gray-600">처리 완료</div>;
      break;
    case 'RETURNED':
      text = <div className="md:text-sm text-xs text-brand-1">반려됨</div>;
      break;
    case 'DELETE_APPEAL':
      text = <div className="md:text-sm text-xs text-red-800">삭제 요청</div>;
      break;
  }

  return (
    <div
      className={
        pathname.startsWith(`/teacher/absent/${absent.id}`)
          ? 'w-full py-4 cursor-pointer bg-gray-100'
          : 'w-full py-4 cursor-pointer border-b border-gray-100'
      }
      onClick={() => history.push(`/teacher/absent/${absent.id}${search}`)}
    >
      <table className="w-full table-fixed">
        <tr>
          <td className="text-lg font-semibold pl-4" colSpan={8}>
            [{absent.description} {absent?.reportType}] {absent.student?.name}
          </td>
          <td
            colSpan={3}
            rowSpan={3}
            className="text-center"
            align="center"
            onClick={() =>
              history.push(
                `/teacher/absent/${absent.id}?page=${page}&limit=${limit}`,
              )
            }
          >
            {text}
          </td>
          <td colSpan={2} rowSpan={3}>
            <div className="flex items-center justify-center h-full">
              <input
                type="checkbox"
                className="md:w-6 w-5 md:h-6 h-5"
                onChange={() => {
                  setClicked(true);
                  submitNiceAbsent({
                    variables: {
                      absentId: absent.id,
                      niceSubmitted: !absent.niceSubmitted,
                    },
                  }).then(() => setClicked(false));
                }}
                checked={absent.niceSubmitted}
                disabled={clicked}
              />
            </div>
          </td>
          <td
            colSpan={3}
            rowSpan={3}
            className="md:text-sm text-xs text-center"
          >
            <div className="flex items-center justify-center h-full">
              <div>{absent.evidenceType}</div>
              <input
                type="checkbox"
                className="md:w-6 w-5 md:h-6 h-5 ml-2"
                onChange={() => {
                  setClicked(true);
                  submitAbsent({
                    variables: {
                      absentId: absent.id,
                      submitted: !absent.submitted,
                    },
                  }).then(() => setClicked(false));
                }}
                checked={
                  absent.submitted || absent?.evidenceType === '학부모 확인서'
                }
                disabled={clicked || absent?.evidenceType === '학부모 확인서'}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td
            className="text-xs text-gray-500 pl-4 overflow-x-hidden"
            colSpan={8}
          >
            기간 :{' '}
            {absent.startAt &&
              absent.endAt &&
              absent.reportType &&
              makeStartEndToString(
                absent.startAt,
                absent.endAt,
                absent.reportType,
              )}
          </td>
        </tr>
        <tr>
          <td className="text-xs text-gray-500 pl-4" colSpan={8}>
            신청일 : {absent.reportedAt}
          </td>
        </tr>
      </table>
    </div>
  );
};
