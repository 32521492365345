import React, { useEffect, useMemo, useState } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import { TextField, useQueryString } from '../../../reusable';
import { Fieldtrip } from '../../../types';
import {
  getEndDate,
  getStartDate,
  isValidDate,
  makeDateToString,
  MonthAgo,
} from '../../../utils';
import FieldtripNoticeDetailPage from './FieldtripNoticeDetailPage';
import {
  FieldtripNoticeCard,
  FrontPagination,
  ErrorBlank,
} from '../../../components';
import { fetcher } from '../../../plugins';
import { ReactComponent as UploadIcon } from '../../../assets/svg/icon-upload.svg';

export const FieldtripNoticePage: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [startDate, setStartDate] = useState(
    makeDateToString(MonthAgo(new Date())),
  );
  const [endDate, setEndDate] = useState(makeDateToString(new Date()));
  const [page, setPage] = useState(Number(params.get('page') ?? '1'));
  const [_studentName, set_studentName] = useState('');
  const limit = Number(params.get('limit') ?? '25');
  const studentName = params.get('username') || '';

  useEffect(() => {
    setPage(Number(params.get('page') ?? '1'));
  }, [params]);

  const queryString = useQueryString({
    filter: {
      startDate: getStartDate(startDate),
      endDate: getEndDate(endDate),
      fieldtripStatus: 'PROCESSED',
      ...(studentName && { username: studentName }),
    },
    page: page,
    limit,
    defaultLimit: limit,
  });

  const { error, data } = uQ(`/fieldtrips${queryString}`, fetcher);
  const school = data?.me?.school;
  const approvalLine = school?.approvalLineF;

  let fieldtrips = data?.items
    ?.slice()
    .sort(
      (a: any, b: any) =>
        (a.fieldtripStatus === 'RETURNED' ? -1 : 0) -
        (b.fieldtripStatus === 'RETURNED' ? -1 : 0),
    );

  const me = data?.me;
  const userRole = data?.me?.role;

  if (studentName) {
    fieldtrips = fieldtrips?.filter((a: Fieldtrip) =>
      a.student?.name?.includes(studentName),
    );
  }

  //조회 권한 여부
  const { data: teacherData } = uQ(`/users/teacherHomeKlass`, fetcher);

  let isViewAuth = false;
  if(teacherData?.user?.role === 'SECURITY') {
    isViewAuth = false;
  } else {
    if(teacherData?.user?.role === 'TEACHER'){
      isViewAuth = teacherData?.user.teacherGroups.length !== 0 ? true : false;        
    }else {
      isViewAuth = true;
    }
  }
  // 승인 권한 여부
  let isApprovalAuth = false;
  for (const index in approvalLine) {
    if (teacherData?.user?.role === approvalLine[index]) {
      if(teacherData?.user?.role === 'TEACHER'){
        isApprovalAuth = teacherData?.user.teacherGroups.length !== 0 ? true : false;        
      }else {
        isApprovalAuth = true;
      }
    }
  }

  return (
    <>
      {error && <ErrorBlank />}
      <div className="col-span-3 h-screen hidden md:block">
        <div className=" px-6 pb-4 pt-6">
          <div className="flex justify-between">
            <h1 className="text-2xl font-semibold">체험학습 통보서</h1>
          </div>
          <div className="flex space-x-3 my-3 items-center">
            <TextField
              type="date"
              value={makeDateToString(new Date(startDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (endDate && selectedDate > new Date(endDate)) {
                  setEndDate(e.target.value);
                }
                setStartDate(e.target.value);
                setPage(1);
              }}
            />
            <div className="px-4 font-bold text-xl">~</div>
            <TextField
              type="date"
              value={makeDateToString(new Date(endDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (startDate && selectedDate < new Date(startDate)) {
                  setStartDate(e.target.value);
                }
                setEndDate(e.target.value);
                setPage(1);
              }}
            />
          </div>
          <div className="flex items-center space-x-2 w-full">
            <div className="items-center border-gray-200 rounded-full border flex justify-between h-10 px-4 w-full">
              <input
                className="w-full"
                placeholder="학생이름을 입력해주세요."
                value={_studentName}
                onChange={(e) => {
                  set_studentName(e.target.value);
                  if (e.target.value === '') {
                    history.replace(`/teacher/fieldtrip`);
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    history.replace(
                      `/teacher/fieldtrip/notice?username=${_studentName}`,
                    );
                  }
                }}
              />
            </div>
            <UploadIcon
              onClick={() => {
                if (_studentName === '') {
                  alert('텍스트 내용을 입력해주세요!');
                } else {
                  history.replace(
                    `/teacher/fieldtrip/notice?username=${_studentName}`,
                  );
                }
              }}
            />
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        {(!isViewAuth && !isApprovalAuth) && <div className="text-center">권한이 없습니다.</div> }
        {isViewAuth &&
        <div className="h-screen-15 overflow-y-auto scroll-box">
          {fieldtrips?.map((fieldtrip: any) => (
            <FieldtripNoticeCard userRole={userRole} fieldtrip={fieldtrip} />
          ))}
        </div>
        }
        {data?.total > limit && (
          <div className="grid place-items-center min-w-max">
            <FrontPagination
              basePath="/teacher/fieldtrip/notice"
              total={data?.total}
              limit={limit}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
      </div>
      <div className="col-span-3 bg-gray-50 p-6">
        <Switch>
          <Route
            path="/teacher/fieldtrip/notice/:id"
            component={() => (
              <FieldtripNoticeDetailPage
                school={school}
                approvalLine={approvalLine}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
};
