import { add } from 'date-fns';
import React from 'react';
import { Fieldtrip, School } from '../../types';
import { makeDateToString2 } from '../../utils';
import { Td2 } from '../Td2';

interface FieldtripPaperProps {
  school: School;
  fieldtrip: Fieldtrip | any;
  content?: any;
  type: '신청서' | '통보서' | '결과보고서';
  approvalLine?: string[];
}

const forms = ['가족동반여행', '친·인척 방문', '답사∙견학 활동', '체험활동'];

export const FieldtripPaper: React.FC<FieldtripPaperProps> = ({
  school,
  fieldtrip,
  content,
  type,
  approvalLine,
}) => {
  // 2023년 이전의 결재선은 결재상태와 사인(도장) 여부에 따라 판단
  if (fieldtrip?.reportedAt <= '2023-03-01') {
    if (fieldtrip?.fieldtripStatus === 'PROCESSED') {
      approvalLine = [];
      if (fieldtrip?.teacherSignature) approvalLine?.push('TEACHER');
      if (fieldtrip?.preHeadSignature) approvalLine?.push('PREHEAD');
      if (fieldtrip?.headSignature) approvalLine?.push('HEAD');
      if (fieldtrip?.prePrincipalSignature) approvalLine?.push('PREPRINCIPAL');
      if (fieldtrip?.principalSignature) approvalLine?.push('PRINCIPAL');
      if (fieldtrip?.viceprincipalSignature)
        approvalLine?.push('VICEPRINCIPAL');
      if (fieldtrip?.headprincipalSignature)
        approvalLine?.push('HEADPRINCIPAL');
    }
  }

  const schoolName = localStorage.getItem('schoolName');
  if (type === '신청서') {
    if (!content) {
      if (fieldtrip?.type === 'HOME') {
        try {
          content = JSON.parse(fieldtrip?.content || '')[0];
        } catch (err) {
          console.log(err);
        }
      } else if (fieldtrip?.type === 'SUBURBS') {
        content = fieldtrip?.content;
      }
    }
    return (
      <div className="px-12 pt-12">
        <div className="w-full flex items-end justify-end">
          <table className="min-w-max border-black border-2">
            <tr>
              {approvalLine?.map((role: string) => {
                switch (role) {
                  case 'TEACHER':
                    return <Td2 style={{ height: '16px' }}>담임</Td2>;
                  case 'PREHEAD':
                    return <Td2 style={{ height: '16px' }}>학년계</Td2>;
                  case 'HEAD':
                    return <Td2 style={{ height: '16px' }}>학년부장</Td2>;
                  case 'PREPRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교무계</Td2>;
                  case 'PRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교무부장</Td2>;
                  case 'VICEPRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교감</Td2>;
                  case 'HEADPRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교장</Td2>;
                }
              })}
            </tr>
            <tr>
              {approvalLine?.map((role: string) => {
                switch (role) {
                  case 'TEACHER':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.teacherSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.teacherSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'PREHEAD':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.preHeadSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.preHeadSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'HEAD':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.headSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.headSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'PREPRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.prePrincipalSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.prePrincipalSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'PRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.principalSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.principalSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'VICEPRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.viceprincipalSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.viceprincipalSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'HEADPRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.headprincipalSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.headprincipalSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                }
              })}
            </tr>
          </table>
        </div>
        <div className="flex justify-end w-full">
          <h5 className="font-bold text-xl mt-4 w-full text-center">
            「학교장허가{' '}
            {fieldtrip?.type === 'HOME' ? '가정학습' : '교외체험학습'}」 신청서
          </h5>
        </div>
        <div className="flex justify-center mt-4">
          <table className="table-fixed border-black border-2 w-full text-center">
            <tr>
              <th className="border-black border bg-gray-200" colSpan={3}>
                이름
              </th>
              <td className="border-black border" colSpan={5}>
                {fieldtrip?.student?.name}
              </td>
              <th className="border-black border bg-gray-200" colSpan={5}>
                학년 / 반 / 번호
              </th>
              <th className="border-black border" colSpan={5}>
                {fieldtrip?.studentGradeKlass} {fieldtrip?.studentNumber}번
              </th>
              <td className="border-black border" colSpan={5}>
                {fieldtrip?.student?.phone}
              </td>
            </tr>

            <tr className="h-0">
              <th
                className=" border-black border bg-gray-200 break-normal"
                colSpan={3}
                rowSpan={2}
              >
                본교 출석인정기간 연간({school?.fieldtripDays}
                )일
              </th>
              <th className=" border-black border bg-gray-200" colSpan={5}>
                신청 기간
              </th>
              <th className="  border-black border" colSpan={15}>
                {makeDateToString2(fieldtrip?.startAt || '')} ~{' '}
                {makeDateToString2(fieldtrip?.endAt || '')} (
                {fieldtrip?.usedDays}) 일간
              </th>
            </tr>

            <tr>
              <th className="border-black border text-sm" colSpan={16}>
                우리 학교 학교장허가{' '}
                {fieldtrip?.type === 'HOME' ? '가정학습' : '교외체험학습'} 세부
                규정 및 불허 기간 확인
                <p></p> ※ 담임교사와의 사전 협의 또는 문의
              </th>
              <td className="border-black border text-center" colSpan={4}>
                확인 ( O )
              </td>
            </tr>

            <tr>
              <th className="border-black border bg-gray-200" colSpan={3}>
                학습형태
              </th>
              <td
                className="border-black border text-center font-bold"
                colSpan={20}
              >
                <div className="flex justify-center items-center space-x-2 text-sm">
                  {fieldtrip?.type === 'SUBURBS' && (
                    <>
                      {forms.map((f: string) => (
                        <span
                          className={`${
                            fieldtrip?.form === f && 'text-blue-600'
                          }`}
                        >
                          ∘{f}( {fieldtrip?.form === f && '○'} )
                        </span>
                      ))}
                      <span>∘가정학습( )</span>
                    </>
                  )}
                  {fieldtrip?.type === 'HOME' && (
                    <>
                      {forms.map((f: string) => (
                        <span>∘{f}( )</span>
                      ))}
                      <span className="text-blue-600">∘가정학습( ○ )</span>
                    </>
                  )}
                </div>
              </td>
            </tr>

            <tr>
              <th className="border-black border bg-gray-200" colSpan={3}>
                목적지
              </th>
              <td className="border-black border text-center" colSpan={11}>
                {fieldtrip?.destination}
              </td>
              <th className="border-black border" colSpan={4}>
                <div className="">
                  <p className="font-normal text-sm">(숙박시)</p> 숙박장소
                </div>
              </th>
              <td className="border-black border" colSpan={5}>
                {fieldtrip?.accommodation}
              </td>
            </tr>

            <tr>
              <th className="border-black border bg-gray-200" colSpan={3}>
                보호자명
              </th>
              <td className="border-black border text-center" colSpan={6}>
                {fieldtrip?.student?.nokName}
              </td>
              <th className="border-black border" colSpan={2}>
                관계
              </th>
              <td className="border-black border" colSpan={3}>
                보호자
              </td>
              <th className="border-black border" colSpan={4}>
                휴대폰
              </th>
              <td className="border-black border" colSpan={5}>
                {fieldtrip?.student?.nokPhone}
              </td>
            </tr>

            <tr>
              <th className="border-black border bg-gray-200" colSpan={3}>
                인솔자명
              </th>
              <td className="border-black border text-center" colSpan={6}>
                {fieldtrip?.guideName}
              </td>
              <th className="border-black border" colSpan={2}>
                관계
              </th>
              <td className="border-black border" colSpan={3}>
                {fieldtrip?.relationship}
              </td>
              <th className="border-black border" colSpan={4}>
                휴대폰
              </th>
              <td className="border-black border" colSpan={5}>
                {fieldtrip?.guidePhone}
              </td>
            </tr>

            <tr>
              <th className="border-black border h-12 bg-gray-200" colSpan={3}>
                목적
              </th>
              <td className="border-black border text-center" colSpan={20}>
                {fieldtrip?.purpose}
              </td>
            </tr>

            {fieldtrip?.type === 'HOME' && (
              <>
                <tr className="h-12">
                  <th
                    className="border-black border bg-gray-200"
                    colSpan={3}
                    rowSpan={8}
                  >
                    가정학습 <p></p>계획
                  </th>
                  <th className="border-black border" colSpan={2}>
                    교시
                  </th>
                  <th className="border-black border min-w-max" colSpan={4}>
                    교과
                  </th>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    ※ 학습할 내용을 기록합니다.
                  </td>
                </tr>

                <tr className="h-12">
                  <th className="border-black border" colSpan={2}>
                    1
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject1}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content1}
                  </td>
                </tr>

                <tr className="h-12">
                  <th className="border-black border" colSpan={2}>
                    2
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject2}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content2}
                  </td>
                </tr>

                <tr className="h-12">
                  <th className="border-black border" colSpan={2}>
                    3
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject3}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content3}
                  </td>
                </tr>

                <tr className="h-12">
                  <th className="border-black border" colSpan={2}>
                    4
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject4}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content4}
                  </td>
                </tr>

                <tr className="h-12">
                  <th className="border-black border" colSpan={2}>
                    5
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject5}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content5}
                  </td>
                </tr>

                <tr className="h-12">
                  <th className="border-black border" colSpan={2}>
                    6
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject6}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content6}
                  </td>
                </tr>

                <tr className="h-12">
                  <th className="border-black border" colSpan={2}>
                    7
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject7}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content7}
                  </td>
                </tr>
              </>
            )}
            {fieldtrip?.type === 'SUBURBS' && (
              <>
                <tr>
                  <th
                    className="border-black border h-12 bg-gray-200 py-8"
                    colSpan={3}
                  >
                    교외<p></p>체험<p></p>학습<p></p>계획
                  </th>
                  <td
                    className="border-black border whitespace-pre-line h-96 overflow-hidden text-left px-4"
                    colSpan={20}
                  >
                    {content}
                  </td>
                </tr>
              </>
            )}
            <tr>
              <th className="bg-gray-200" colSpan={23}>
                위와 같이 「학교장허가{' '}
                {fieldtrip?.type === 'HOME' ? '가정학습' : '교외체험학습'}」을
                신청합니다.
              </th>
            </tr>
            <tr>
              <th colSpan={23} className="bg-gray-200">
                {fieldtrip?.reportedAt &&
                  makeDateToString2(fieldtrip.reportedAt)}
              </th>
            </tr>
            <tr>
              <th className=" pr-3 bg-gray-200" colSpan={23}>
                <div className="w-full -my-5">
                  <div className="w-full flex items-center justify-end">
                    <div>보호자 {fieldtrip?.student?.nokName} :</div>
                    <div
                      className="relative"
                      style={{ width: '75px', minHeight: '50px' }}
                    >
                      <div
                        className="absolute text-littleblack"
                        style={{ top: '13px', left: '37px' }}
                      >
                        (인)
                      </div>
                      {fieldtrip?.parentSignature && (
                        <img
                          style={{ width: '75px' }}
                          src={fieldtrip?.parentSignature}
                          crossOrigin="anonymous"
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-end -mt-5">
                    <div>학생 {fieldtrip?.student?.name} :</div>
                    <div
                      className="relative"
                      style={{ width: '75px', minHeight: '50px' }}
                    >
                      <div
                        className="absolute text-littleblack"
                        style={{ top: '13px', left: '37px' }}
                      >
                        (인)
                      </div>
                      {fieldtrip?.studentSignature && (
                        <img
                          style={{ width: '75px' }}
                          src={fieldtrip?.studentSignature}
                          crossOrigin="anonymous"
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th className="font-extrabold text-xl bg-gray-200" colSpan={23}>
                {schoolName}장 귀하
              </th>
            </tr>
          </table>
        </div>
        <div className="mx-5 mt-1 text-sm">
          <p>
            ※ 보호자가 신청서를 제출하였다 하여 체험학습이 허가된 것이 아니며
            담임교사로부터 반드시 최종 허가 여부 통보서(또는 문자)를 받은 후
            실시해야 함
          </p>
          <p>※ 신청서 제출 기한은 3일 이전, 보고서 제출 기한은 7일 이내</p>
          <p>
            ※ 교외체험학습 실시 중에는 보호자와 담당교사 간 연락체계를 유지하고
            사안(사고) 발생 시 보호자는 담당교사에게 연락을 하도록 합니다.
          </p>
        </div>
      </div>
    );
  } else if (type === '통보서') {
    return (
      <div className="col-span-3 col-start-2 px-12 pt-12">
        <div className="flex justify-center w-full mt-4 mb-2">
          <h5 className="font-bold text-2xl ">
            「학교장허가{' '}
            {fieldtrip?.type === 'HOME' ? '가정학습' : '교외체험학습'}」 통보서
          </h5>
        </div>
        <div className="flex justify-center -mt-0.5 text-center">
          <table className="table-fixed border-black border-2 w-full ">
            <tr>
              <th className="border-black border bg-gray-200" colSpan={3}>
                성명
              </th>
              <td className="border-black border" colSpan={7}>
                {fieldtrip?.student?.name}
              </td>
              <th className="border-black border bg-gray-200" colSpan={5}>
                학년 / 반 / 번호
              </th>
              <th className="border-black border" colSpan={10}>
                {fieldtrip?.studentGradeKlass} {fieldtrip?.studentNumber}번
              </th>
            </tr>
            <tr>
              <th
                className=" border-black border bg-gray-200"
                colSpan={3}
                rowSpan={5}
              >
                본교 <p></p> 출석인정기간 <p></p> 연간<p></p>(
                {school?.fieldtripDays}
                )일
              </th>
              <th className=" border-black border bg-gray-200" colSpan={7}>
                신청 기간
              </th>
              <th className="  border-black border" colSpan={15}>
                {makeDateToString2(fieldtrip?.startAt || '')} ~{' '}
                {makeDateToString2(fieldtrip?.endAt || '')} (
                {fieldtrip?.usedDays}) 일간
              </th>
            </tr>
            <tr>
              <th className=" border-black border bg-gray-200" colSpan={7}>
                허가 기간
              </th>
              <th className="  border-black border" colSpan={15}>
                {makeDateToString2(fieldtrip?.startAt || '')} ~{' '}
                {makeDateToString2(fieldtrip?.endAt || '')} (
                {fieldtrip?.usedDays}) 일간
              </th>
            </tr>
            <tr>
              <th
                className=" border-black border bg-gray-200"
                colSpan={3}
                rowSpan={3}
              >
                금회까지 <p></p>누적 <p></p>사용기간 <p></p>(
                {school?.fieldtripDays &&
                  fieldtrip?.currentRemainDays &&
                  fieldtrip?.usedDays &&
                  school.fieldtripDays -
                    fieldtrip.currentRemainDays +
                    fieldtrip.usedDays}
                )일
              </th>
              <th colSpan={19}>
                {fieldtrip?.endAt &&
                  makeDateToString2(
                    add(new Date(fieldtrip.endAt), { days: 1 }) || '',
                  )}
              </th>
            </tr>
            <tr>
              <th className="text-right" colSpan={19}>
                <div className="w-full flex items-center justify-end">
                  <div>
                    {schoolName} {fieldtrip?.studentGradeKlass} 담임교사 :
                  </div>{' '}
                  <div className="relative" style={{ width: '75px' }}>
                    <div
                      className="absolute text-littleblack"
                      style={{ top: '13px', left: '37px' }}
                    >
                      (인)
                    </div>
                    {fieldtrip?.teacherSignature && (
                      <img
                        style={{ width: '75px' }}
                        src={fieldtrip?.teacherSignature}
                        crossOrigin="anonymous"
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th className="font-extrabold text-xl text-right" colSpan={19}>
                보호자님 귀하
              </th>
            </tr>
          </table>
        </div>
        <div className="mx-5 mt-5">
          <p className="font-extrabold underline ">
            ※ 보호자가 신청서를 제출하였다 하여 체험학습이 허가된 것이 아니며
            담임교사로부터 반드시 최종 허가 여부 통보서(또는 문자)를 받은 후
            실시해야 함
          </p>
          <p className="text-blue-600">
            ※ 신청서 제출 기한은 3일 이전, 보고서 제출 기한은 7일 이내
          </p>
          <p className="text-blue-600">
            ※ 교외체험학습 실시 중에는 보호자와 담당교사 간 연락체계를 유지하고
            사안(사고) 발생 시 보호자는 담당교사에게 연락을 하도록 합니다.
          </p>
        </div>
      </div>
    );
  } else if (type === '결과보고서') {
    return (
      <div className="col-span-3 col-start-2 px-12 pt-12">
        <div className="w-full flex items-end justify-end">
          <table className="min-w-max border-black border-2">
            <tr>
              {approvalLine?.map((role: string) => {
                switch (role) {
                  case 'TEACHER':
                    return <Td2 style={{ height: '16px' }}>담임</Td2>;
                  case 'PREHEAD':
                    return <Td2 style={{ height: '16px' }}>학년계</Td2>;
                  case 'HEAD':
                    return <Td2 style={{ height: '16px' }}>학년부장</Td2>;
                  case 'PREPRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교무계</Td2>;
                  case 'PRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교무부장</Td2>;
                  case 'VICEPRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교감</Td2>;
                  case 'HEADPRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교장</Td2>;
                }
              })}
            </tr>
            <tr>
              {approvalLine?.map((role: string) => {
                switch (role) {
                  case 'TEACHER':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.teacherResultSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.teacherResultSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'PREHEAD':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.preHeadResultSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.preHeadResultSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'HEAD':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.headResultSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.headResultSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'PREPRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.prePrincipalResultSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.prePrincipalResultSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'PRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.principalResultSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.principalResultSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'VICEPRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.viceprincipalResultSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.viceprincipalResultSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'HEADPRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.headprincipalResultSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.headprincipalResultSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                }
              })}
            </tr>
          </table>
        </div>
        <div className="flex justify-end w-full">
          <h5 className="font-bold text-xl mt-4 w-full text-center">
            「학교장허가{' '}
            {fieldtrip?.type === 'HOME' ? '가정학습' : '교외체험학습'}」
            결과보고서
          </h5>
        </div>
        <div className="flex justify-center mt-4 text-center">
          <table className="table-fixed border-black border-2 w-full ">
            <tr>
              <th className="border-black border bg-gray-200" colSpan={3}>
                성명
              </th>
              <td className="border-black border" colSpan={6}>
                {fieldtrip?.student?.name}
              </td>
              <th className="border-black border bg-gray-200" colSpan={7}>
                학년 / 반 / 번호
              </th>
              <th className="border-black border" colSpan={7}>
                {fieldtrip?.studentGradeKlass} {fieldtrip?.studentNumber}번
              </th>
            </tr>

            <tr>
              <th className="border-black border bg-gray-200" colSpan={9}>
                {fieldtrip?.type === 'HOME' ? '가정학습' : '교외체험학습'} 기간
              </th>
              <th className="border-black border " colSpan={14}>
                {makeDateToString2(fieldtrip?.startAt || '')} ~{' '}
                {makeDateToString2(fieldtrip?.endAt || '')} (
                {fieldtrip?.usedDays}) 일간
              </th>
            </tr>

            <tr>
              <th className="border-black border bg-gray-200" colSpan={9}>
                {fieldtrip?.type === 'HOME' ? '가정학습' : '교외체험학습'} 장소
              </th>
              <th className="border-black border " colSpan={14}>
                {fieldtrip?.type === 'HOME' ? '가정' : fieldtrip?.destination}
              </th>
            </tr>

            <tr>
              <th className="border-black border bg-gray-200" colSpan={3}>
                학습형태
              </th>
              <td
                className="border-black border text-center font-bold"
                colSpan={20}
              >
                <div className="flex justify-center space-x-2 items-center text-sm">
                  {fieldtrip?.type === 'SUBURBS' && (
                    <>
                      {forms.map((f: string) => (
                        <span
                          className={`${
                            fieldtrip?.form === f && 'text-blue-600'
                          }`}
                        >
                          ∘{f}( {fieldtrip?.form === f && '○'} )
                        </span>
                      ))}
                      <span>∘가정학습( )</span>
                    </>
                  )}
                  {fieldtrip?.type === 'HOME' && (
                    <>
                      {forms.map((f: string) => (
                        <span>∘{f}( )</span>
                      ))}
                      <span className="text-blue-600">∘가정학습( ○ )</span>
                    </>
                  )}
                </div>
              </td>
            </tr>

            {fieldtrip?.type === 'HOME' && (
              <>
                <tr className="h-10">
                  <th
                    className="border-black border bg-gray-200"
                    colSpan={3}
                    rowSpan={8}
                  >
                    가정학습 <p></p>계획
                  </th>
                  <th className="border-black border" colSpan={2}>
                    교시
                  </th>
                  <th className="border-black border min-w-max" colSpan={4}>
                    교과
                  </th>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    ※ 학습할 내용을 기록합니다.
                  </td>
                </tr>

                <tr className="h-14">
                  <th className="border-black border" colSpan={2}>
                    1
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject1}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content1}
                  </td>
                </tr>

                <tr className="h-14">
                  <th className="border-black border" colSpan={2}>
                    2
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject2}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content2}
                  </td>
                </tr>

                <tr className="h-14">
                  <th className="border-black border" colSpan={2}>
                    3
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject3}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content3}
                  </td>
                </tr>

                <tr className="h-14">
                  <th className="border-black border" colSpan={2}>
                    4
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject4}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content4}
                  </td>
                </tr>

                <tr className="h-14">
                  <th className="border-black border" colSpan={2}>
                    5
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject5}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content5}
                  </td>
                </tr>

                <tr className="h-14">
                  <th className="border-black border" colSpan={2}>
                    6
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject6}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content6}
                  </td>
                </tr>

                <tr className="h-14">
                  <th className="border-black border" colSpan={2}>
                    7
                  </th>
                  <td className="border-black border min-w-max" colSpan={4}>
                    {content && content.subject7}
                  </td>
                  <td
                    className="border-black border pl-5 font-semibold text-lg"
                    colSpan={14}
                  >
                    {content && content.content7}
                  </td>
                </tr>
              </>
            )}
            {fieldtrip?.type === 'SUBURBS' && (
              <>
                <tr>
                  <th className="border-black border bg-gray-200" colSpan={3}>
                    제목
                  </th>
                  <td className="border-black border text-center" colSpan={20}>
                    {fieldtrip?.resultTitle}
                  </td>
                </tr>

                <tbody>
                  <tr>
                    <td
                      className="border-black border border-dashed"
                      colSpan={23}
                    >
                      * 각 일정별로 느낀 점, 배운 점 등을 글, 그림 등으로 학생이
                      직접 기록합니다.
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="border-black border border-dashed whitespace-pre-line h-96 text-left px-4"
                      colSpan={23}
                    >
                      {fieldtrip?.resultText}
                    </td>
                  </tr>
                </tbody>
              </>
            )}

            <tr>
              <th colSpan={23} className=" bg-gray-200">
                위와 같이 「학교장허가{' '}
                {fieldtrip?.type === 'HOME' ? '가정학습' : '교외체험학습'}」
                결과보고서를 제출합니다.
              </th>
            </tr>
            <tr>
              <th colSpan={23} className="bg-gray-200">
                {fieldtrip?.endAt &&
                  makeDateToString2(
                    add(new Date(fieldtrip.endAt), { days: 1 }) || '',
                  )}
              </th>
            </tr>
            <tr>
              <th className="bg-gray-200 pr-3" colSpan={23}>
                <div className="w-full -my-5">
                  <div className="w-full flex items-center justify-end">
                    <div>보호자 {fieldtrip?.student?.nokName}:</div>
                    <div
                      className="relative"
                      style={{ width: '75px', minHeight: '50px' }}
                    >
                      <div
                        className="absolute text-littleblack"
                        style={{ top: '13px', left: '37px' }}
                      >
                        (인)
                      </div>
                      {fieldtrip?.parentResultSignature && (
                        <img
                          style={{ width: '75px' }}
                          src={fieldtrip?.parentResultSignature}
                          crossOrigin="anonymous"
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-end -mt-5">
                    <div>학생 {fieldtrip?.student?.name}:</div>
                    <div
                      className="relative"
                      style={{ width: '75px', minHeight: '50px' }}
                    >
                      <div
                        className="absolute text-littleblack"
                        style={{ top: '13px', left: '37px' }}
                      >
                        (인)
                      </div>
                      {fieldtrip?.studentResultSignature && (
                        <img
                          style={{ width: '75px' }}
                          src={fieldtrip?.studentResultSignature}
                          crossOrigin="anonymous"
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th className="font-extrabold text-xl bg-gray-200" colSpan={23}>
                {schoolName}장 귀하
              </th>
            </tr>
          </table>
        </div>
        <p className="font-bold mt-5 mb-10">
          ※ 보고서 제출 기한: 체험학습 종료 후 5일 이내
        </p>
      </div>
    );
  }
  return <></>;
};
