import React, { Component } from 'react';
import * as Survey from 'survey-react';
import 'survey-react/modern.css';

Survey.StylesManager.applyTheme('modern');
Survey.Serializer.findProperty('survey', '');

interface SurveyComponentProps {
  display?: boolean;
  content: string;
  btnHide?: boolean;
  value?: any;
  uploadFiles?: (files: any) => any;
  updateSA?: () => void;
  setContent: (content: string) => void;
}

type SurveyComponentState = {
  survey: any; // like this
};

export class SurveyComponent extends Component<
  SurveyComponentProps,
  SurveyComponentState
> {
  constructor(props: any) {
    super(props);
    const json = JSON.parse(this.props.content);
    const survey = new Survey.Model(json);

    survey.focusFirstQuestionAutomatic = false;
    survey.focusOnFirstError = false;
    survey.locale = 'my';

    if (this.props.display) {
      survey.mode = 'display';
    }
    if (this.props.value) {
      try {
        for (const v of Object.entries(JSON.parse(this.props.value))) {
          const val: any = v;
          survey.setValue(val[0], val[1]);
        }
      } catch (err) {
        console.error(err);
      }
    }

    function updatePreview(newValue: any, container: any) {
      container.innerHTML = '';
      newValue?.map(function (fileItem: any) {
        let aTag = document.createElement('a');
        aTag.target = '_blank';
        aTag.rel = 'noreferrer';
        aTag.download = 'true';
        aTag.href = fileItem.content;

        let button = document.createElement('div');
        button.className = 'btn sv-btn sv-file__choose-btn';
        button.style.margin = '0.5rem';
        button.innerText = 'Download ' + fileItem.name;
        aTag.appendChild(button);
        container.appendChild(aTag);
      });
    }

    survey.onComplete.add(async (result) => {
      await this.props.setContent(survey.getAllValues());
      if (this.props.updateSA) await this.props.updateSA();
    });

    survey.onAfterRenderQuestion.add(function (sender, options) {
      if (options.question.getType() === 'file') {
        // console.log(options.question);
        let container = document.createElement('div');
        container.className = 'my-preview-container';
        let fileElement =
          options.htmlElement.getElementsByClassName('sv_q_file')[0];
        if (!fileElement) {
          fileElement =
            options.htmlElement.getElementsByClassName('sv-file__decorator')[0];
        }
        fileElement.appendChild(container);
        options.question.onPropertyChanged.add(function (
          question: any,
          options: any,
        ) {
          if (options.name === 'value') {
            updatePreview(options.newValue, container);
          }
        });
        updatePreview(options.question.value, container);
      }
      if (options.question.getType() === 'image') {
        let container = document.createElement('a');
        container.className = 'sv-image';
        container.href = options.question.imageLink;
        container.target = '_blank';
        container.rel = 'noreferrer';
        let imageDivElement =
          options.htmlElement.getElementsByClassName('sv-image')[0];
        if (imageDivElement) {
          let parent = imageDivElement.parentNode;
          parent.replaceChild(container, imageDivElement);
          container.appendChild(imageDivElement);
        }
      }
    });

    survey.onUploadFiles.add(async (survey, options) => {
      // console.log('options', options);
      if (this.props.uploadFiles) {
        await this.props.uploadFiles(options.files).then((res: any) => {
          // console.log('res', res);
          if (res?.data?.surveyFilesUpload?.length) {
            const newFiles = options.files.map(function (file: any, i: number) {
              return { file, content: res.data.surveyFilesUpload[i] };
            });
            options.callback('success', newFiles);

            let container = document.createElement('div');
            container.className = 'my-preview-container';
            let fileElement = document.getElementsByClassName('sv_q_file')[0];
            if (!fileElement) {
              fileElement =
                document.getElementsByClassName('sv-file__decorator')[0];
            }
            updatePreview(newFiles, container);
            fileElement.appendChild(container);
          }
        });
      }
      this.props.setContent(survey.getAllValues());
    });

    this.state = {
      survey,
    };
  }

  componentDidMount() {
    let btn: any = document.getElementsByClassName(
      'sv-footer__complete-btn',
    )[0];
    if (btn?.style) {
      btn.style.width = '100%';
      btn.style.borderRadius = '1rem';
      btn.style.height = '3rem';
      btn.style.textAlign = 'center';
      btn.style.fontSize = '1rem';
      btn.style.backgroundColor = '#EE853A';
      btn.value = '제출하기';
      if (this.props.btnHide) {
        btn.style.display = 'none';
      }
    }
    let body: any = document.getElementsByTagName('body')[0];

    if (body.style) {
      body.style =
        '--primary-color:#EE853A; --secondary-color:#EE853A; --primary-text-color:#676a6c; --secondary-text-color:#a7a7a7; --inverted-text-color:#ffffff; --primary-hover-color:#EC7623; --selection-border-color:#EE853A; --primary-icon-color:#3d4d5d; --primary-bg-color:#fff; --secondary-bg-color:#f4f4f4; --primary-border-color:#e7eaec; --secondary-border-color:#ddd; --error-color:#ed5565;';
    }

    let questionTitles: any = document.getElementsByClassName(
      'sv-title sv-question__title',
    );

    if (questionTitles && questionTitles.length) {
      for (let questionTitle of questionTitles) {
        questionTitle.style.backgroundColor = 'rgba(238, 133, 58, 0.2)';
      }
    }
  }

  render() {
    // survey.onDownloadFile.add(function (survey, options) {
    //   console.log(options.content);
    //   fetch(options.content)
    //     .then(function (response) {
    //       return response.blob();
    //     })
    //     .then(function (blob) {
    //       let reader = new FileReader();
    //       reader.onload = function (e: any) {
    //         options.callback('success', e.target.result);
    //       };
    //       reader.readAsDataURL(
    //         new File([blob], options.fileValue.name, {
    //           type: options.fileValue.type,
    //         }),
    //       );
    //       options.callback('success', URL.createObjectURL(blob));
    //     });
    // });

    return <Survey.Survey model={this.state.survey} />;
  }
}
