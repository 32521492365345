import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Linkify from 'react-linkify';
import Viewer from 'react-viewer';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import {
  BackButton,
  Blank,
  Section,
  TopNavbar,
  useQuery,
  utcToLocalDatetime,
} from '../../../reusable';
import { GET_NOTICE } from '../../../query';
import { ErrorBlank, FileItem } from '../../../components';

interface MatchParams {
  id: string;
}

export const NoticeDetailPage: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  let { id } = match.params;
  let { loading, error, data }: any = useQuery(GET_NOTICE, {
    variables: { id: Number(id) },
  });

  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const notice = data?.notice;

  let images: any[] = [];
  try {
    images = notice?.images
      ? notice?.images.map((image: any) => JSON.parse(image).url)
      : [];
  } catch (err) {
    console.error(err);
  }

  let viewerImages: any[] = [];

  for (let image of images) {
    viewerImages.push({
      src: image,
      title: '',
    });
  }

  let files: any[] = [];
  try {
    files = notice?.files
      ? notice?.files.map((file: any) => JSON.parse(file))
      : [];
  } catch (err) {
    console.error(err);
  }
  return (
    <div>
      {loading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar
        title="공지사항"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />

      <div className="h-15">
        <Section>
          <div className="rounded-md px-3 py-1 bg-red-50 text-sm font-bold text-red-500 w-max">
            학교 공지
          </div>
          <div>
            <div className="text-xl font-bold text-grey-1">
              {data?.notice?.title}
            </div>
            <div className="text-grey-5 text-sm mb-2">
              {utcToLocalDatetime(data?.notice?.createdAt?.toString() || '')}
            </div>
          </div>
          <Section>
            {images?.map((image: string, i: number) => (
              <>
                <div
                  className="w-full"
                  onClick={() => {
                    setActiveIndex(i);
                    setImagesModalOpen(true);
                  }}
                >
                  <div className={`relative pb-3/5 rounded bg-gray-50`}>
                    <ExifOrientationImg
                      loading="lazy"
                      className={`absolute w-full h-full rounded object-cover`}
                      src={image}
                      alt=""
                    />
                  </div>
                </div>
              </>
            ))}
          </Section>
          {files?.length ? (
            <div>
              {files?.map((f: any) => (
                <FileItem file={f} />
              ))}
            </div>
          ) : (
            <></>
          )}
          <div
            className={`text-grey-2 break-words whitespace-pre-line feedback_space`}
          >
            <Linkify>{data?.notice?.content}</Linkify>
          </div>

          <div className="absolute">
            <Viewer
              visible={hasImagesModalOpen}
              rotatable={true}
              noImgDetails={true}
              //drag={false}
              scalable={false}
              images={viewerImages}
              onClose={() => {
                setImagesModalOpen(false);
              }}
              activeIndex={activeIndex}
            />
          </div>
        </Section>
      </div>
    </div>
  );
};
