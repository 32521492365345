import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import SignaturePad from 'signature_pad';
import imageCompression from 'browser-image-compression';
import {
  TopNavbar,
  BackButton,
  Section,
  TextField,
  TextArea,
  Button,
  PhoneNumberField,
  Checkbox,
  useQuery,
  Blank,
  useMutation,
  BottomFixedView,
  CloseButton,
} from '../../../reusable';
import { ErrorBlank } from '../../../components';
import {
  GET_FIELDTRIP_REPORT_ADD_PAGE,
  UPDATE_FIELDTRIP_RESULT,
} from '../../../query';
import { makeDateToString } from '../../../utils';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';

interface MatchParams {
  id: string;
}

let sigPad: any = null;

const FieldtripSuburbsReportAddPage: React.FC<
  RouteComponentProps<MatchParams>
> = ({ match }) => {
  const { id } = match.params;
  const { loading, error, data } = useQuery(GET_FIELDTRIP_REPORT_ADD_PAGE, {
    variables: { fieldtripId: Number(id) },
  });

  const me = data?.me;
  const fieldtrip = data?.fieldtripById;

  const [destination, setDestination] = useState(fieldtrip?.destination || '');
  const [resultText, setResultText] = useState(fieldtrip?.resultText || '');
  const [agree, setAgree] = useState(false);
  const [sigPadData, setSigPadData] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [parentsName, setParentsName] = useState(me?.nokName || '');
  const [parentsPhone, setParentsPhone] = useState(me?.nokPhone);
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openSignModal, setSignModal] = useState(false);
  const [resultTitle, setResultTitle] = useState(fieldtrip?.resultTitle || '');
  const [images, setImages] = useState<(File | null | undefined)[]>([null]);
  const history = useHistory();

  const [updateFieldtripResult] = useMutation(UPDATE_FIELDTRIP_RESULT, {
    variables: {
      data: JSON.stringify({
        fieldtripId: fieldtrip?.id,
        destination,
        resultText,
        resultTitle,
      }),
      images,
      signature: sigPadData,
    },
    onCompleted: (data) => {
      setLoading(false);
      setSuccess(data?.updateFieldtripResult?.id);
    },
    onError: (error) => {
      setErrorMessage(error.message);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (document.querySelector('canvas')) {
      sigPad =
        //@ts-ignore
        new SignaturePad(document.querySelector('canvas'), {
          onEnd: () => {
            setSigPadData(sigPad.toDataURL());
          },
        });
    }
  }, []);

  const clearSignature = () => {
    sigPad && sigPad.clear();
    setSigPadData('');
    const canvas = document.querySelector('canvas');
    const ctx = canvas?.getContext('2d');
    ctx && ctx.clearRect(0, 0, canvas?.width || 0, canvas?.height || 0);
    ctx && ctx.beginPath();
  };

  useEffect(() => {
    if (!parentsName && data?.me?.nokName) {
      setParentsName(data.me.nokName);
    }
    if (data?.me?.nokPhone) {
      setParentsPhone(data.me.nokPhone);
    }
    if(!destination){
      setDestination(fieldtrip?.destination);
    }
  }, [fieldtrip]);


  if (success) {
    return (
      <>
        <TopNavbar
          title="체험학습 신청서"
          left={
            <div className="h-15">
              <BackButton className="h-15" />
            </div>
          }
        />
        <div className="w-full h-screen-15 flex flex-col items-center justify-center text-center">
          <div className="text-gray-600 font-bold text-xl">
            체험학습 결과보고서 제출 완료
          </div>
          <div className="text-gray-400 mt-4">
            결과보고서 제출이 완료되었습니다. 보호자, 담임, 학년부장, 교무부장의
            서명 후 결재 승인이 완료됩니다.
          </div>
          <BottomFixedView tw={{ bottom: 'bottom-16', paddingX: 'px-5' }}>
            <Button
              onClick={() =>
                history.push(`/student/fieldtrip/result/${success}`)
              }
              tw={{ width: 'w-full' }}
            >
              결과보고서 확인하기
            </Button>
            <button
              className="bg-white border border-brand-1 text-brand-1 w-full rounded-lg mt-4 px-6 h-12 font-bold"
              onClick={() => history.push(`/student/fieldtrip`)}
            >
              목록으로 가기
            </button>
          </BottomFixedView>
        </div>
      </>
    );
  }

  return (
    <>
      {loading && <Blank />}
      {isLoading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar title="교외 체험학습 결과 보고서" left={<BackButton />} />
      <Section style={{ backgroundColor: '#F7F7F7' }}>
        <h1 className="text-xl font-semibold">교외 체험학습 결과 보고서</h1>
        <div className="text-xs whitespace-pre-line mb-2 text-red-600">
          제출기한 : {makeDateToString(new Date(fieldtrip?.endAt || ''), ' ')}
          까지
        </div>
        <label className="mb-1 text-sm text-gray-800">*기간</label>
        <div className="space-y-3 pb-6">
          <div>
            <div className="flex space-x-4 items-center">
              <div className="text-lg">
                {fieldtrip?.startAt &&
                  makeDateToString(new Date(fieldtrip?.startAt || ''), '. ')}
              </div>
              <span className="">부터</span>
            </div>
            <div className="pt-2 flex space-x-4 items-center">
              <div className="text-lg">
                {fieldtrip?.endAt &&
                  makeDateToString(new Date(fieldtrip?.endAt || ''), '. ')}
              </div>
              <span className="">까지</span>
            </div>
          </div>
          <div className="  text-lg whitespace-pre-line mb-2">
            남은 일수
            <span className="text-brand-1 underline">
              {fieldtrip?.currentRemainDays}일 중 {fieldtrip?.usedDays}일 신청
            </span>
            합니다.
          </div>
          <div className=" text-gray-600 text-xs whitespace-pre-line mb-2">
            ※<span className="font-bold">토,일, 개교기념일 등 학교 휴업일</span>
            은 체험학습 신청 일수에 넣지 않음. <br /> ※ 체험학습 신청은
            신청일보다 <span className="font-bold">3일 이전에 신청</span>해야함.
          </div>
          <div className="w-full">
            <TextField
              label="*체험학습 형태"
              placeholder={fieldtrip?.form}
              readOnly
            />
          </div>
          <div className="w-full">
            <TextField
              label="*목적지"
              id="destination"
              placeholder="목적지를 입력해주세요."
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="font-bold text-gray-800 text-lg">
            *현장학습 결과 보고서 작성
          </div>
        </div>
        <div className="w-full">
          <TextField
            label="*제목"
            placeholder="내용을 입력해주세요."
            value={resultTitle}
            onChange={(e) => setResultTitle(e.target.value)}
          />
        </div>
        <TextArea
          placeholder="내용을 입력해주세요."
          rows={5}
          style={{ height: 'auto', borderWidth: '1px' }}
          value={resultText}
          onChange={(e) => setResultText(e.target.value)}
        />

        {images?.map((el: File | null | undefined, i: number) => (
          <>
            <label htmlFor={`imageupload${i}`}>
              <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
                {el ? (
                  <img
                    className="absolute w-full h-full rounded object-cover"
                    src={URL.createObjectURL(el)}
                    alt=""
                  />
                ) : (
                  <div className="absolute w-full h-full rounded object-cover bg-white">
                    <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                      <FileUploadImg />
                      <div className="text-brand-1">
                        이미지를 업로드해주세요!
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </label>
            <input
              type="file"
              id={`imageupload${i}`}
              className="hidden"
              accept=".png, .jpeg, .jpg"
              onChange={async (e) => {
                const newImages = images.slice();
                const alreadyImages = !!newImages[i];
                newImages[i] = e.target.files?.item(0);
                if (!alreadyImages) {
                  newImages.push(null);
                }

                if (newImages[i]) {
                  const compressFile = await imageCompression(
                    newImages[i] as File,
                    { initialQuality: 0.8 },
                  );
                  newImages[i] = compressFile;
                  e.target.validity.valid && setImages(newImages);
                }
              }}
            />
          </>
        ))}
        <div className="w-full">
          <TextField
            disabled = {me?.nokName}
            label="보호자 이름"
            placeholder="보호자 이름을 입력해주세요."
            value={parentsName}
            onChange={(e) => setParentsName(e.target.value)}
            tw={{
              borderColor: parentsName ? 'border-gray-300' : 'border-red-700',
            }}
          />
        </div>
        <div className="w-full">
          <PhoneNumberField
            disabled = {me?.nokPhone!=='' && me?.nokPhone!=='010' &&  me?.nokPhone.length >= 10}
            label="보호자 연락처"
            value={parentsPhone || '010'}
            onChange={(e) => setParentsPhone(e.target.value)}
            style={{
              borderColor: !parentsPhone ? 'rgba(185, 28, 28)' : '',
            }}
          />
          <div className=" text-gray-600 text-xs whitespace-pre-line mb-2 mt-2">
            * 보호자 정보 수정은 담임선생님의 학생카드 페이지에서 가능합니다.
          </div>
        </div>
        <div>
          <div className="flex items-center space-x-2 mb-1">
            <Checkbox
              id="agree"
              onChange={() => setAgree(!agree)}
              checked={agree}
            />
            <label htmlFor="agree">
              <span className="font-semibold text-lg cursor-pointer">
                아래 내용을 체크하셨습니까?
              </span>
            </label>
          </div>
          <div className="border border-gray-300 rounded-lg px-4 py-3 whitespace-pre-line">
            -신고자는 학생 본인입니다.
          </div>
        </div>
        <Button
          disabled={!resultTitle || !resultText || !agree}
          tw={{
            backgroundColor:
              !resultTitle || !resultText || !agree
                ? 'bg-gray-300'
                : 'bg-brand-1',
          }}
          onClick={() => {
            setSignModal(true);
            const regExp = /^010(?:\d{4})\d{4}$/;
            if (parentsPhone && !regExp.test(parentsPhone.replace(/-/g, ''))) {
              alert('보호자 연락처를 확인해 주세요.');
              setSignModal(false);
              return;
            }
          }}
        >
          동의하고 서명하기
        </Button>

        {errorMessage && <div className="text-red-600">{errorMessage}</div>}
      </Section>
      <div className={openSignModal ? '' : 'hidden'}>
        <Blank text="" />
        <BottomFixedView
          tw={{
            borderRadius: 'rounded-xl',
            backgroundColor: 'bg-white',
            zIndex: 'z-100',
            bottom: '-bottom-4',
          }}
        >
          <div
            className="absolute top-2 right-3"
            onClick={() => setSignModal(false)}
          >
            <CloseButton
              onClick={() => {
                setSignModal(false);
                clearSignature();
              }}
            />
          </div>
          <Section>
            <div>
              <div className="text-gray-700 text-xl font-bold">학생 서명란</div>
              <div className="text-gray-500">
                아래 네모칸에 서명을 해주세요.
              </div>
            </div>
            <canvas
              width={
                window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6
              }
              height={
                window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4
              }
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            <div className="flex items-center justify-between space-x-2">
              <Button
                tw={{
                  backgroundColor: 'bg-white',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  color: 'text-current',
                  width: 'w-full',
                }}
                onClick={() => clearSignature()}
              >
                다시하기
              </Button>
              <Button
                tw={{ width: 'w-full' }}
                onClick={() => {
                  setLoading(true);
                  updateFieldtripResult();
                }}
              >
                서명 제출하기
              </Button>
            </div>
          </Section>
          {isLoading && <Blank />}
        </BottomFixedView>
      </div>
    </>
  );
};

export default withRouter(FieldtripSuburbsReportAddPage);
