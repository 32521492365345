import React, { FC, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import { Button, Section, useMutation } from '../../../reusable';
import { ErrorBlank, SuperModal } from '../../../components';
import { DELETE_GROUP } from '../../../query';
import { StudentGroup } from '../../../types';
import { GroupAddPage } from './GroupAddPage';
import { fetcher } from '../../../plugins';

interface MatchParams {
  id: string;
}

interface GroupDetailPageProps extends RouteComponentProps<MatchParams> {
  groupIds: number[];
  groupsRefetch: () => void;
}

const GroupDetailPage: FC<GroupDetailPageProps> = ({
  match,
  groupIds,
  groupsRefetch,
}) => {
  let { id } = match.params;
  const { error, data, refetch } = uQ(`/groups/${id}`, fetcher);
  const [updateState, setUpdateState] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const students: any[] = data?.group?.studentGroups
    .map((el: StudentGroup) => ({
      ...el.user,
      studentNumber: el.studentNumber,
    }))
    .sort((a: any, b: any) => a.studentNumber - b.studentNumber);
  const group = data?.group;

  const [deleteGroup] = useMutation(DELETE_GROUP, {
    variables: {
      id: Number(id),
    },
  });

  // if (loading) return <LoadingScreen />;
  if (error) return <ErrorBlank />;

  if (updateState) {
    return (
      <GroupAddPage
        refetch={() => {
          refetch();
          groupsRefetch();
          setUpdateState(false);
        }}
        groupIds={groupIds}
        groupData={group}
      />
    );
  }

  return (
    <div className="p-4 h-screen overflow-auto bg-gray-50">
      <SuperModal
        modalOpen={isDeleteModalOpen}
        setModalClose={() => setDeleteModalOpen(false)}
        width="w-max"
      >
        <Section tw={{ marginTop: 'mt-7' }}>
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            이 그룹을 삭제하면 이 그룹에 올라온 {group?.groupActivities?.length}{' '}
            개의 활동기록도 같이 삭제됩니다. <br />
            정말 선택된 그룹을 삭제하시겠습니까?
          </div>
          <Button onClick={() => deleteGroup()}>삭제하기</Button>
        </Section>
      </SuperModal>
      <div className="py-6 px-3 flex items-center justify-between">
        <div className="text-xl font-semibold">{group?.name}</div>
        <div className="flex space-x-4 font-base text-gray-500 cursor-pointer">
          <div className="text-gray-700" onClick={() => setUpdateState(true)}>
            수정
          </div>
          {/* <div
            className="text-red-400 cursor-pointer"
            onClick={() => setDeleteModalOpen(true)}
          >
            삭제
          </div> */}
        </div>
      </div>
      <div className="w-full grid grid-flow-row grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 pr-4">
        {students?.map((student: any) => (
          <div className="flex items-center space-x-2 py-3 px-3 w-full border-2 border-grey-6 m-2 rounded-lg cursor-pointer ">
            {group?.type === 'KLASS' && (
              <div className="font-bold">{student.studentNumber}</div>
            )}
            <div className="font-base overflow-hidden whitespace-pre">
              {student.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(GroupDetailPage);
