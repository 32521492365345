import React, { useEffect, useState } from 'react';
import { useQuery as uQ } from 'react-query';
import { fetcher } from '../../../plugins';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import {
  BackButton,
  Blank,
  Chip,
  HorizontalScrollView,
  List,
  Tab,
  Tabs,
  TopNavbar,
  useQuery,
} from '../../../reusable';
import { GET_NOTICES } from '../../../query';
import { Notice, TabType } from '../../../types';
import NoticeDetailPage from './NoticeDetailPage';
import { ErrorBlank, NoticeCard, NoticeTeacherItem } from '../../../components';
import { NoticeAddPage } from './NoticeAddPage';
import { NewsletterMobilePage } from '../newsletter/NewsletterMobilePage';
import { BoardMobilePage } from '../board/BoardMobilePage';

//const categories = ['공지', '일정', '자료', '신청', '평가'];

const NoticePage = () => {
  const { pathname } = useLocation();
  let { loading, error, data, refetch }: any = useQuery(GET_NOTICES);

  const [tabType, setTabType] = useState<TabType>(TabType.NOTICE);
  const [isUpdateState, setUpdateState] = useState(false);
  const [category, setCategory] = useState('');

  const { data: categories } = uQ(`/codes?category=notice`, fetcher, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });

  useEffect(() => {
    const getLocal = localStorage.getItem('tabType');
    if (!getLocal) {
      return;
    } else if (getLocal === 'BOARD') {
      setTabType(TabType.BOARD);
    } else if (getLocal === 'NOTICE') {
      setTabType(TabType.NOTICE);
    } else if (getLocal === 'NEWSLETTER') {
      setTabType(TabType.NEWSLETTER);
    }
  }, [tabType]);

  const notices = category
    ? data?.notices.filter((el: Notice) => el.category === category)
    : data?.notices;
  const me = data?.me;

  const isDetail = !pathname.endsWith('/teacher/notice');

  return (
    <>
      {/* Mobile V */}
      <div className={`md:hidden ${isDetail ? 'hidden' : 'block'}`}>
        <div className="md:hidden block">
          <TopNavbar title="공지" left={<BackButton />} />
        </div>
        <Tabs>
          <Tab
            active={tabType === TabType.NOTICE}
            text="공지사항"
            full
            onClick={() => {
              setTabType(TabType.NOTICE);
              localStorage.setItem('tabType', TabType.NOTICE);
            }}
          />
          <Tab
            active={tabType === TabType.BOARD}
            text="학급 게시판"
            full
            onClick={() => {
              setTabType(TabType.BOARD);
              localStorage.setItem('tabType', TabType.BOARD);
            }}
          />
          <Tab
            active={tabType === TabType.NEWSLETTER}
            text="가정통신문"
            full
            onClick={() => {
              setTabType(TabType.NEWSLETTER);
              localStorage.setItem('tabType', TabType.NEWSLETTER);
            }}
          />
        </Tabs>
        {tabType === TabType.NOTICE && (
          <div className="w-full flex-col space-y-2 h-screen-9 overflow-y-auto scroll-box">
            <div className="whitespace-pre-line">
              <List>
                {notices?.map((notice: Notice) => (
                  <NoticeTeacherItem notice={notice} />
                ))}
              </List>
            </div>

          </div>
        )}
        {tabType === TabType.NEWSLETTER && <NewsletterMobilePage />}
        {tabType === TabType.BOARD && <BoardMobilePage />}
      </div>

      {/* Desktop V */}
      {loading && <Blank reversed />}
      {error && <ErrorBlank />}
      <div className="col-span-2 h-screen hidden md:block">
        <div className="flex justify-between px-6 py-6">
          <h1 className="text-2xl font-semibold">학교 공지사항</h1>
          {me?.canEditNotice && (
            <Link to="/teacher/notice/add">
              <button className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-4 py-2 focus:outline-none">
                추가하기
              </button>
            </Link>
          )}
        </div>
        <div className="w-full space-y-0h-0.5 bg-gray-100"></div>
        <HorizontalScrollView twInner={{ marginY: 'my-4' }}>
          <Chip
            text={'전체'}
            onClick={() => setCategory('')}
            active={!category}
            tw={{ paddingY: 'py-1.5', minWidth: 'min-w-max' }}
          />
          {categories?.map((item: any) => (
            <Chip
              key={item.name}
              text={item.name}
              onClick={() => setCategory(item.name)}
              tw={{ paddingY: 'py-1.5', minWidth: 'min-w-max' }}
              active={item.name === category}
            />
          ))}
        </HorizontalScrollView>
        <div className="h-screen-5 pb-20 overflow-y-auto scroll-box">
          {notices?.map((notice: Notice) => (
            <NoticeCard notice={notice} onClick={() => setUpdateState(false)} />
          ))}
        </div>
      </div>

      <div
        className={`bg-gray-50 overflow-y-auto col-span-4 p-0`}
      >
        <Switch>
          <Route
            path="/teacher/notice/add"
            component={() => <NoticeAddPage refetch={() => refetch()} />}
          />
          <Route
            path="/teacher/notice/:id"
            component={() => (
              <NoticeDetailPage
                setUpdateState={(b: boolean) => setUpdateState(b)}
                isUpdateState={isUpdateState}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
};

export default NoticePage;
