import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import {
  Button,
  Checkbox,
  Label,
  Section,
  Select,
  TextArea,
  TextField,
  useMutation,
} from '../../../reusable';
import { FileItem, SuperSurveyAddComponent } from '../../../components';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import { CREATE_NEWSLETTER, UPDATE_NEWSLETTER } from '../../../query';
import { Newsletter, TeacherGroup } from '../../../types';
import undoArrow from '../../../assets/images/undo-arrow.png';
import { fetcher } from '../../../plugins';

interface NewsletterAddPageProps {
  refetch: () => void;
  newsletterData?: Newsletter;
}
const categories = [
  '기타',
  '교무',
  '연구',
  '학생',
  '학년',
  '정보',
  '행정',
  '교육과정',
  '진로진학',
];

export const NewsletterAddPage: React.FC<NewsletterAddPageProps> = ({
  refetch,
  newsletterData,
}) => {
  const history = useHistory();
  const [title, setTitle] = useState(newsletterData?.title || '');
  const [content, setContent] = useState(newsletterData?.content || '');
  const [category, setCategory] = useState(newsletterData?.category || '');
  const [surveyContent, setSurveyContent] = useState<any[]>([]);
  const [images, setImages] = useState<(File | null | undefined)[]>([null]);
  const [files, setFiles] = useState<(File | null | undefined)[]>([]);
  const [isError, setError] = useState('');
  const [type, setType] = useState('NOTICE');
  const [selectedGrades, setSelectedGrades] = useState<number[]>(
    newsletterData?.klasses?.map((el) => Number(el)) || [],
  );
  const [willRemoveImages, setWillRemoveImages] = useState<any[]>([]);
  const [willRemoveFiles, setWillRemoveFiles] = useState<any[]>([]);

  // const { loading, error, data } = useQuery(GET_GROUPS, {
  //   fetchPolicy: 'cache-first',
  // });

  const { data } = uQ('/teacher-groups?isSchool=true', fetcher);

  useEffect(() => {
    if (newsletterData) {
      const { title, content, surveyContent, images, files, type, category } =
        newsletterData;
      setTitle(title || '');
      setContent(content || '');
      setType(type || 'NOTICE');
      setCategory(category || '');
      try {
        setSurveyContent(JSON.parse(surveyContent || '[]'));
      } catch (err) {
        console.error(err);
      }
    }
  }, [newsletterData]);

  let subjects: string[] = [];

  data?.me?.teacherGroups.map((tg: TeacherGroup) =>
    tg.subject && !subjects.includes(tg.subject)
      ? subjects.push(tg.subject)
      : tg,
  );

  const [createNewsletter] = useMutation(CREATE_NEWSLETTER, {
    variables: {
      data: JSON.stringify({
        grades: selectedGrades,
        title,
        content,
        subject: '',
        type,
        category,
        surveyContent: JSON.stringify(surveyContent),
      }),
      images: images,
      files: files,
    },
    onCompleted: (data) => {
      if (data?.createNewsletter?.id) {
        refetch();
        history.push(`/teacher/newsletter/${data?.createNewsletter?.id}`);
      }
    },
    onError: (error) => {
      setError(error?.message);
    },
  });

  const [updateNewsletter] = useMutation(UPDATE_NEWSLETTER, {
    variables: {
      id: newsletterData?.id,
      data: JSON.stringify({
        grades: selectedGrades,
        title,
        content,
        subject: '',
        type,
        category,
        surveyContent: JSON.stringify(surveyContent),
        deleteImages: willRemoveImages,
        deleteFiles: willRemoveFiles,
      }),
      images: images.filter((el) => {
        if (typeof el === 'string') {
          return false;
        } else if (!el) {
          return false;
        }
        return true;
      }),
      files,
    },
    onCompleted: (data) => {
      if (data?.updateNewsletter?.id) {
        refetch();
        history.push(`/teacher/newsletter/${data?.updateNewsletter?.id}`);
      }
    },
    onError: (error) => {
      setError(error?.message);
    },
  });

  let nFiles: any[] = [];
  try {
    nFiles = newsletterData?.files
      ? newsletterData?.files.map((el: string) => JSON.parse(el))
      : [];
  } catch (err) {
    console.error(err);
  }

  let nImages: any[] = [];
  try {
    nImages = newsletterData?.images
      ? newsletterData?.images.map((el: string) => JSON.parse(el))
      : [];
  } catch (err) {
    console.error(err);
  }

  const buttonDisabled = title.length === 0 || content.length === 0;
  return (
    <Section>
      <div className="bg-white p-4 border rounded-md mt-3">
        <span>전달 대상 선택</span>
        <div className="flex w-full justify-between mt-1">
          <Checkbox
            label="1학년"
            checked={selectedGrades.includes(1)}
            onChange={() =>
              selectedGrades.includes(1)
                ? setSelectedGrades(selectedGrades.filter((g) => g !== 1))
                : setSelectedGrades(selectedGrades.concat(1))
            }
          />
          <Checkbox
            label="2학년"
            checked={selectedGrades.includes(2)}
            onChange={() =>
              selectedGrades.includes(2)
                ? setSelectedGrades(selectedGrades.filter((g) => g !== 2))
                : setSelectedGrades(selectedGrades.concat(2))
            }
          />
          <Checkbox
            label="3학년"
            checked={selectedGrades.includes(3)}
            onChange={() =>
              selectedGrades.includes(3)
                ? setSelectedGrades(selectedGrades.filter((g) => g !== 3))
                : setSelectedGrades(selectedGrades.concat(3))
            }
          />
        </div>
      </div>

      <div>
        <label
          htmlFor="location"
          className="block text-sm font-medium text-gray-700"
        >
          타입 선택
        </label>
        <div></div>
        <select
          className={`mt-1 block w-full pl-1 pr-1 py-3 text-base border border-gray-800 focus:outline-none focus:ring-indigo-500 focus:border-black sm:text-sm rounded-md`}
          onChange={(e) => setType(e.target.value)}
          value={type}
          placeholder="타입을 선택해주세요"
        >
          <option value="NOTICE">공지 타입</option>
          <option value="STUDENT_PARENTS_SURVEY">{`설문 타입 - 학생&보호자(서명)`}</option>
        </select>
      </div>
      <Select
        label="구분"
        id="category"
        onChange={(e) => setCategory(e.target.value)}
        value={category}
      >
        <option selected hidden>
          구분을 선택해주세요
        </option>
        {categories.map((el) => (
          <option id={el} value={el} key={el}>
            {el}
          </option>
        ))}
      </Select>

      <div>
        <Label>이미지</Label>
        <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
          {nImages?.map((el: any) => (
            <div>
              <div
                className={`relative pb-3/5 rounded border-2 border-dashed border-grey-5 ${
                  willRemoveImages.includes(el.data) ? 'opacity-50' : ''
                }`}
              >
                <span className="absolute -top-3 -right-3 block w-6 h-6 rounded-full ring-2 ring-white bg-red-700 z-40">
                  <div
                    className="w-full h-full flex items-center justify-center text-white cursor-pointer"
                    onClick={() =>
                      willRemoveImages.includes(el.data)
                        ? setWillRemoveImages(
                            willRemoveImages.filter(
                              (imgUrl) => imgUrl !== el.data,
                            ),
                          )
                        : setWillRemoveImages(willRemoveImages.concat(el.data))
                    }
                    style={{ transform: 'translate(0.1px, 0.1px)' }}
                  >
                    {willRemoveImages.includes(el.data) ? (
                      <img src={undoArrow} alt="" className="w-4 h-4" />
                    ) : (
                      'X'
                    )}
                  </div>
                </span>
                <ExifOrientationImg
                  className="absolute w-full h-full rounded object-cover"
                  src={el.url}
                  alt=""
                />
                {willRemoveImages.includes(el.data) && (
                  <div className="absolute w-full h-full z-20">
                    <div className="flex w-full h-full items-center justify-center">
                      <div className="text-4xl text-gray-800 opacity-100">
                        삭제
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
          {images?.map((el: File | null | undefined, i: number) => (
            <div className="">
              <label htmlFor={`imageupload${i}`}>
                <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
                  {el ? (
                    <img
                      className="absolute w-full h-full rounded object-cover"
                      src={URL.createObjectURL(el)}
                      alt=""
                    />
                  ) : (
                    <div className="absolute w-full h-full rounded object-cover bg-white">
                      <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                        <FileUploadImg />
                        <div className="text-brand-1">
                          이미지를 업로드해주세요!
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </label>
              <input
                type="file"
                id={`imageupload${i}`}
                className="hidden"
                accept=".png, .jpeg, .jpg"
                onChange={(e) => {
                  let size = 0;

                  images.map((el: File | string | null | undefined) => {
                    if (el instanceof File) {
                      size += el.size || 0;
                    }
                  });

                  files.map((el: File | null | undefined) => {
                    size += el?.size || 0;
                  });

                  if (size >= 20 * 1024 * 1024) {
                    alert(
                      '한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요!',
                    );
                  } else {
                    const newImages = images.slice();
                    if (e.target.files && e.target.files?.item(0)) {
                      newImages[i] = e.target.files?.item(0);
                      newImages.push(null);
                    }
                    e.target.validity.valid && setImages(newImages);
                  }
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div>
        <Label>파일</Label>
        <div className="bg-white rounded border-2 border-dashed border-grey-5">
          {nFiles?.map((file: any) => (
            <FileItem
              file={file}
              canClose
              willRemoveFiles={willRemoveFiles}
              setWillRemoveFiles={() =>
                willRemoveFiles.includes(file.data)
                  ? setWillRemoveFiles(
                      willRemoveFiles.filter(
                        (fileName) => fileName !== file.data,
                      ),
                    )
                  : setWillRemoveFiles(willRemoveFiles.concat(file.data))
              }
            />
          ))}
          {files.map((file: any) => (
            <FileItem file={file} />
          ))}
          <label htmlFor="fileupload">
            <div className="w-full flex space-x-1 justify-center items-center pt-0.5 pb-2.5 cursor-pointer">
              <span className="text-2xl text-grey-3 mb-1">+</span>
              <div className="text-brand-1">
                파일을 업로드해주세요 (다중 업로드 가능)
              </div>
            </div>
          </label>
        </div>
        <input
          type="file"
          id="fileupload"
          className="hidden"
          multiple
          onChange={(e) => {
            const newFiles = e.target.files
              ? files.concat(Array.from(e.target.files))
              : files;

            let size = 0;

            images.map((el: File | string | null | undefined) => {
              if (el instanceof File) {
                size += el?.size || 0;
              }
            });

            newFiles.map((el: File | null | undefined) => {
              size += el?.size || 0;
            });

            if (size >= 20 * 1024 * 1024) {
              alert(
                '한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요!',
              );
            } else {
              e.target.validity.valid && setFiles(newFiles);
            }
          }}
        />
      </div>

      <div>
        <TextField
          label="제목"
          id="title"
          placeholder="제목을 입력해주세요"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
      </div>

      <div>
        <TextArea
          label="내용"
          placeholder="내용을 입력해주세요."
          value={content}
          onChange={(e) => setContent(e.target.value)}
          style={{ borderWidth: '1px' }}
        />
      </div>
      {type === 'STUDENT_PARENTS_SURVEY' && (
        <div>
          <SuperSurveyAddComponent
            setContent={(c: any) => setSurveyContent(c)}
            content={surveyContent}
          />
        </div>
      )}

      <div className="text-center mt-6">
        {isError && <div className="text-red-500">{isError}</div>}
        <Button
          text={newsletterData ? '수정하기' : '등록하기'}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
            height: 'h-11',
          }}
          style={{ width: '70%' }}
          disabled={buttonDisabled}
          onClick={() =>
            newsletterData ? updateNewsletter() : createNewsletter()
          }
        />
      </div>
    </Section>
  );
};
