import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { api } from '../../../plugins';

interface MatchParams {
  uuid: string;
}

export const AddChildrenPage = () => {
  const { uuid } = useParams<MatchParams>();
  const history = useHistory();

  useEffect(() => {
    if (uuid) {
      api
        .post(`/users/children/${uuid}`)
        .then(({ data: { token } }: any) => {
          localStorage.setItem('childToken', token);
          history.replace('/student');
        })
        .catch(() => {
          alert('자녀 추가 실패');
          localStorage.setItem('childError', 'true');
          history.replace('/student');
        });
    }
  }, [uuid]);

  return <div></div>;
};
