import {
  Alert,
  BackButton,
  Blank,
  BottomFixedView,
  Button, PhoneNumberField,
  Section,
  TextField,
  TopNavbar,
  useMutation,
} from '../../../reusable';
import React, { FC, useState } from 'react';
import { FIND_PASSWORD } from '../../../query';
import { FindPasswordSuccessPage } from './FindPasswordSuccessPage';

export const FindPasswordPage: FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [errM, setErrM] = useState('');

  const [findPassword] = useMutation(FIND_PASSWORD, {
    variables: { email, name, phone, isMail: false },
    onCompleted: (result) => {
      setLoading(false);
      // history.push('/');
      setPhone(result?.findPassword?.phone);
      setSuccess(true);
    },
    onError: (err) => {
      setLoading(false);
      setErrM(err.message);
    },
  });

  if (isSuccess) {
    return <FindPasswordSuccessPage email={email} phone={phone} />;
  }
  const buttonDisabled = !email || phone.length!=11 || !name;
  return (
    <>
      <TopNavbar
        title="비밀번호 찾기"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      {loading && <Blank />}
      {errM && (
        <Alert severity="error" onClose={() => setErrM('')}>
          {errM}
        </Alert>
      )}
      <Section>

        <div className="w-full">
          <TextField
            placeholder="you@example.com"
            label="*이메일"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

          <TextField
              label="*이름"
              placeholder="이름을 입력해주세요."
              value={name}
              onChange={(e) => setName(e.target.value)}
          />
          <PhoneNumberField
              label="*전화번호"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}>
          </PhoneNumberField>
        <div className="text-gray-400">
          *알림톡이 수신되지 않을 경우 고객센터로 문의 주시기 바랍니다.
        </div>
      </Section>
        <Section>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://superstudy.channel.io/"
          >
            <Button
              tw={{
                backgroundColor: 'bg-white',
                color: 'text-black',
                width: 'w-full',
              }}
            >
              문의하기
            </Button>
          </a>
          <Button
            disabled={buttonDisabled}
            tw={{ backgroundColor: !email ? 'bg-gray-300' : 'bg-brand-1' }}
            onClick={async () => {
              await setLoading(true);
              await findPassword();
            }}
          >
            비밀번호 찾기
          </Button>
        </Section>
    </>
  );
};
