import { FC } from 'react';
import { makeDateToString, makeTimeToString } from '../../utils';

interface SendMessageProps {
  MessageData: any;
}

export const SendMessage: FC<SendMessageProps> = ({ MessageData }) => {
  // console.log(MessageData);
  return (
    <div className="message me mb-4 flex text-right px-1">
      <div className="flex-1 px-2">
        <div className="inline-block bg-blue-600 rounded-xl p-1 px-6 text-white">
          <p className="whitespace-pre-wrap text-left text-sm">
            {MessageData.content}
          </p>
        </div>
        <div className="pr-4">
        {!MessageData.isread && <small className='text-yellow-400'>1</small>} &nbsp;
          <small className="text-gray-500">
            {MessageData.createdAt &&
              makeDateToString(MessageData.createdAt, '.') +
                ' ' +
                makeTimeToString(MessageData.createdAt)}
          </small>
        </div>
      </div>
    </div>
  );
};
