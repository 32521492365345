import React, { useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import Viewer from 'react-viewer';
import Linkify from 'react-linkify';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import {
  BackButton,
  Blank,
  Button,
  Section,
  TopNavbar,
  utcToLocalDatetime,
} from '../../../reusable';
import { ErrorBlank, BoardFileItem, SuperModal } from '../../../components';
import { Board, GroupBoard } from '../../../types';
import { fetcher, api } from '../../../plugins';

interface MatchParams {
  id: string;
}

interface BoardDetailPageProps extends RouteComponentProps<MatchParams> {
  page: number;
  limit: number;
  refetch: () => void;
}

const BoardDetailPage: React.FC<BoardDetailPageProps> = ({
  match,
  page,
  limit,
  refetch,
}) => {
  const { id } = match.params;

  const history = useHistory();

  const {
    data: board,
    error,
    isLoading,
  } = uQ<Board>(`/boards/${id}`, fetcher, { enabled: !!id });

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [hasImagesModalOpen, setImagesModalOpen] = useState<boolean[]>(
    board?.images?.map(() => false) || [],
  );

  const deleteBoard = () => {
    api.delete(`/boards/${board?.id}`).then(() => {
      refetch();
      history.push('/teacher/board');
    });
  };

  return (
    <>
      <div className="md:hidden block">
        <TopNavbar
          title="학급게시판"
          left={<BackButton onClick={() => history.push('/teacher/notice')} />}
        />
      </div>
      <div className="bg-white border rounded-lg p-5 h-full">
        {isLoading && <Blank reversed text="게시글을 불러오는 중입니다." />}
        {error && <ErrorBlank text="해당 게시글을 불러오지 못했습니다." />}
        <SuperModal
          modalOpen={isDeleteModalOpen}
          setModalClose={() => setDeleteModalOpen(false)}
          width="w-max"
        >
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              정말 해당 학급게시판 게시글을 삭제하시겠습니까?
            </div>
            <Button onClick={() => deleteBoard()}>삭제하기</Button>
          </Section>
        </SuperModal>

        <div>
          <Section>
            <div className="flex justify-between items-center">
              <div className="flex space-x-2 items-center">
                <div className="rounded-md px-3 py-1 bg-red-50 text-sm font-bold text-red-500 w-max">
                  {board?.category || '학급게시판'}
                </div>
              </div>

              <div className="flex space-x-4 font-base text-gray-500 cursor-pointer">
                <div
                  className="text-gray-700"
                  onClick={() =>
                    history.push(
                      `/teacher/board/${board?.id}/edit?page=${page}&limit=${limit}`,
                    )
                  }
                >
                  수정
                </div>
                <div
                  className="text-red-400 cursor-pointer"
                  onClick={() => setDeleteModalOpen(true)}
                >
                  삭제
                </div>
              </div>
            </div>
            <div className="text-lg font-bold text-grey-1">{board?.title}</div>
            <div className="text-grey-3 text-sm">
              {board?.createdAt && utcToLocalDatetime(board.createdAt)}
            </div>

            <div className="w-full overflow-x-scroll scroll-box">
              <div className="flex space-x-2 items-center min-w-max">
                {board?.groupBoards?.map((gb: GroupBoard) => (
                  <span className="rounded-full py-2 px-3 border border-gray-400 font-semibold text-gray-500 text-sm">
                    {gb?.group?.name}
                  </span>
                ))}
              </div>
            </div>
            <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
              {board?.images?.map((image: any, i: number) => {
                return (
                  <>
                    <div
                      className="w-full"
                      onClick={() => {
                        const newOpens = hasImagesModalOpen.slice();
                        newOpens[i] = true;
                        setImagesModalOpen(newOpens);
                      }}
                    >
                      <div className={`relative pb-3/5 rounded bg-gray-50`}>
                        <ExifOrientationImg
                          className={`absolute w-full h-full rounded object-cover`}
                          src={
                            process.env.REACT_APP_API_URL +
                            '/images?url=' +
                            image
                          }
                          loading="lazy"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="absolute">
                      <Viewer
                        visible={hasImagesModalOpen[i]}
                        rotatable={true}
                        noImgDetails={true}
                        //drag={false}
                        scalable={false}
                        images={[
                          {
                            src:
                              process.env.REACT_APP_API_URL +
                              '/images?url=' +
                              image,
                            alt: '',
                          },
                        ]}
                        onClose={() => {
                          const newOpens = hasImagesModalOpen.slice();
                          newOpens[i] = false;
                          setImagesModalOpen(newOpens);
                        }}
                      />
                    </div>
                  </>
                );
              })}
            </div>
            <div>
              {board?.files?.map((key: string) => {
                let data = {
                  name: key.split('/').pop()?.slice(14),
                  data: key,
                  url: process.env.REACT_APP_API_URL + '/images?url=' + key,
                };
                return <BoardFileItem file={data} />;
              })}
            </div>
            <div className={`text-grey-1 whitespace-pre-line feedback_space`}>
              <Linkify>{board?.content}</Linkify>
            </div>
          </Section>
        </div>
      </div>
    </>
  );
};

export default withRouter(BoardDetailPage);
