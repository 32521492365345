import { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery as uQ, useMutation } from 'react-query';
import { CSVLink } from 'react-csv';
import { GET_TIMETABLE_V2_MYKLASS } from '../../../query';
import {
  Button,
  Divider,
  useQueryBasic,
  useQueryString,
  useQuery,
  Blank,
  Select,
  useRecoilValue,
  Chip,
} from '../../../reusable';
import { fetcher, api } from '../../../plugins';
import {
  getEndDate,
  getStartDate,
  makeDateToStringType2,
  toLocaleDateFormatString,
  weekCount,
} from '../../../utils';
import { meState } from '../../../store';
import { Group, AttendanceType, AbsentSave } from '../../../types';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/svg/icon-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../../assets/svg/icon-chevron-right.svg';
import { getThisYear, getSemester } from '../../../utils/time-util';

export const AttendancePage: FC = () => {
  const me = useRecoilValue(meState);

  const lastPeriod = 8;
  const toDay = new Date();

  const year = Number(getThisYear());
  const semester = getSemester();

  const [groupId, setGroupId] = useState<number | undefined>(-1);

  const [editMode, setEditMode] = useState(false);
  const [editType, setEditType] = useState<any>({
    mark: '.',
    type1: '',
    type2: '',
    absent: false,
  });

  const [attendanceMap, setAttendanceMap] = useState(new Map<string, string>());

  const attendanceMapAdd = (key: string, value: string) => {
    if (attendanceMap.has(key) === false) {
      attendanceMapUpsert(key, value);
    }
  };
  const attendanceMapUpsert = (key: string, value: string) => {
    setAttendanceMap((prev) => new Map<string, string>(prev).set(key, value));
  };
  const [editMap, setEditMap] = useState(new Map<string, any>());
  const editMapUpsert = (key: string, value: string) => {
    setEditMap((prev) => new Map<string, string>(prev).set(key, value));
  };

  //let attendanceMap = new Map<string, string>(); // 출석체크 데이터
  let commentMap = new Map<string, any[]>(); // 특기사항 데이터
  let absentsMap = new Map<string, string>(); // 출결신고 데이터
  let studentsExpired: Array<number> = [];

  const {
    loading,
    error,
    data: dataTimetable,
    refetch,
  } = useQuery(GET_TIMETABLE_V2_MYKLASS, {
    variables: { year, semester, groupId },
    errorPolicy: 'ignore',
    onError: (e) => {},
    skip:
      typeof year !== 'number' ||
      typeof semester !== 'number' ||
      typeof groupId !== 'number',
  });

  let maxPeriod = 8;
  let periodMon = 8;
  let periodTue = 8;
  let periodWed = 8;
  let periodThu = 8;
  let periodFri = 8;

  const groups = dataTimetable?.klassGroups?.slice().sort((a: any, b: any) => {
    const aData = a?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);
    const bData = b?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);

    if (!aData || !bData) {
      return 0;
    }

    if (aData[1] && bData[1]) {
      if (aData[1] === bData[1]) {
        return Number(aData[2]) - Number(bData[2]);
      } else {
        return Number(aData[1]) - Number(bData[1]);
      }
    } else {
      return 0;
    }
  });

  const timetable = dataTimetable?.timetablev2ByYearSemesterGroup;

  if (timetable && timetable?.groupId !== groupId) {
    setGroupId(timetable?.groupId);
  }

  let timetableContent: any = null;
  try {
    timetableContent = JSON.parse(timetable?.content);
    maxPeriod = timetableContent?.length;
    periodMon = timetableContent?.length;
    periodTue = timetableContent?.length;
    periodWed = timetableContent?.length;
    periodThu = timetableContent?.length;
    periodFri = timetableContent?.length;

    timetableContent?.map((period: any, i: number) => {
      if (periodMon > i && period.monday.subject === '') periodMon = i;
      if (periodTue > i && period.tuesday.subject === '') periodTue = i;
      if (periodWed > i && period.wednesday.subject === '') periodWed = i;
      if (periodThu > i && period.thursday.subject === '') periodThu = i;
      if (periodFri > i && period.friday.subject === '') periodFri = i;
    });
  } catch (err) {}

  const history = useHistory();

  const [isLoading, setLoading] = useState(false);

  const [selday, setSelday] = useState(new Date());
  const [selweek, setSelweek] = useState(0);

  // CSV 지연 생성
  const csvRef = useRef<any>();
  const [clicked, setClicked] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [csvData, setCsvData] = useState<any[]>([]);

  const csvRefStudent = useRef<any>();
  const [clickedStudent, setClickedStudent] = useState(false);
  const [updatedStudent, setUpdatedStudent] = useState(false);
  const [csvDataStudent, setCsvDataStudent] = useState<any[]>([]);

  let klassName = '';

  let [tselweek, selmonday, seltuesday, selwednesday, selthursday, selfriday] =
    weekCount(selday);

  // 처음 한번 실행
  useEffect(() => {
    // if (klassName === 'NO') {
    //   alert('담임을 맡은 반이 없습니다.');
    //   history.goBack();
    // }
    setSelweek(tselweek);
  }, []);

  useEffect(() => {
    if (clicked) {
      setUpdated(true);
    }
  }, [clicked, csvData]);

  useEffect(() => {
    if (clicked && csvData && updated) {
      csvRef?.current?.link?.click();
      setClicked(false);
      setUpdated(false);
    }
  }, [updated]);

  useEffect(() => {
    if (clickedStudent) {
      setUpdatedStudent(true);
    }
  }, [clickedStudent, csvDataStudent]);

  useEffect(() => {
    if (clickedStudent && csvDataStudent && updatedStudent) {
      csvRefStudent?.current?.link?.click();
      setClickedStudent(false);
      setUpdatedStudent(false);
    }
  }, [updatedStudent]);

  const { mutate } = useMutation(
    (absent: AttendanceType) =>
      api.post<AttendanceType>(
        process.env.REACT_APP_API_URL + '/attendance/absent',
        absent,
      ),
    {
      // onCompleted:() =>{
      //   console.log("onCompleted");
      //   //refetch();
      // },
      onSuccess: () => {
        //console.log('출석변경성공');
        //refetch();
      },
      onError: (error) => {
        // 요청에 에러가 발생된 경우
        console.log('onError');
      },
      onSettled: () => {
        // 요청이 성공하든, 에러가 발생되든 실행하고 싶은 경우
        //console.log('onSettled');
      },
    },
  );

  // 학생명단, 출석체크 가져오기
  const queryString = useQueryBasic({
    year: year,
    groupId: groupId,
    semester: semester,
  });
  const { data, refetch: attendanceRefetch } = uQ(
    `/users/attendancebook${queryString}`,
    fetcher,
    {
      refetchOnWindowFocus: false,
    },
  );
  klassName = data?.klassname;

  if (data && !klassName) {
    klassName = 'NO';
  }
  const teacher = data?.attendance?.teacher[0].name;

  // 출결신청내역 가져오기
  const absentsQuery = useQueryString({
    filter: {
      startDate: getStartDate(year.toString() + '-03-01'),
      endDate: getEndDate((year + 1).toString() + '-02-28'),
      selectedGroupId: groupId,
      // absentStatus: 'PROCESSED',
    },
    limit: 9999,
  });
  const { data: dataAbsents } = uQ(`/absents/groupid${absentsQuery}`, fetcher, {
    refetchOnWindowFocus: false,
  });

  // 체험학습-가정학습 가져오기
  const fieldtripQuery = useQueryString({
    filter: {
      startDate: getStartDate(year.toString() + '-03-01'),
      endDate: getEndDate((year + 1).toString() + '-02-28'),
      selectedGroupId: groupId,
      queryType: 'attendance',
      // fieldtripStatus: undefined : filter.value,
      // username: studentName,
    },
    limit: 9999,
    // page: page,
    // limit,
    // defaultLimit: limit,
  });

  const { data: dataField } = uQ(`/fieldtrips${fieldtripQuery}`, fetcher, {
    refetchOnWindowFocus: false,
  });

  // 학생 정렬
  const students = data?.attendance?.userAttendance?.sort((a: any, b: any) => {
    const aData = a?.studentGroups[0]?.studentNumber;
    const bData = b?.studentGroups[0]?.studentNumber;

    if (!aData || !bData) {
      return 0;
    }
    return Number(aData) - Number(bData);
  });

  const getAttendentMark = (
    type2: string,
    type1: string,
    attendanceMarkBefore: string,
  ) => {
    let attendanceMark = '.';
    switch (type2 + type1) {
      case '질병결석':
        attendanceMark = '♡';
        break;
      case '미인정결석':
        attendanceMark = '♥';
        break;
      case '기타결석':
        attendanceMark = '▲';
        break;
      case '인정결석':
        attendanceMark = '△';
        break;

      case '질병지각':
        attendanceMark = '＃';
        break;
      case '미인정지각':
        attendanceMark = 'Ｘ';
        break;
      case '기타지각':
        attendanceMark = '≠';
        break;
      case '인정지각':
        attendanceMark = '◁';
        break;

      case '질병조퇴':
        attendanceMark = '＠';
        break;
      case '미인정조퇴':
        attendanceMark = '◎';
        break;
      case '기타조퇴':
        attendanceMark = '∞';
        break;
      case '인정조퇴':
        attendanceMark = '▷';
        break;

      case '질병결과':
        attendanceMark = '☆';
        break;
      case '미인정결과':
        attendanceMark = '◇';
        break;
      case '기타결과':
        attendanceMark = '＝';
        break;
      case '인정결과':
        attendanceMark = '▽';
        break;

      case '질병기타':
        attendanceMark = 'V';
        break;
      case '미인정기타':
        attendanceMark = 'V';
        break;
      case '기타기타':
        attendanceMark = 'V';
        break;
      case '인정기타':
        attendanceMark = 'V';
        break;
      default:
        attendanceMark = attendanceMarkBefore;
        break;
    }

    return attendanceMark;
  };

  // 출결신청 내역 확인
  dataAbsents?.absents?.items?.map((absent: any, i: number) => {
    let iDate = new Date(absent.startAt);
    let eDate = new Date(absent.endAt);
    eDate.setDate(eDate.getDate() + 1);
    eDate.setHours(0, 0, 0, 0);

    do {
      let keyDate = toLocaleDateFormatString(iDate);
      let preabsent = absentsMap.has(keyDate)
        ? absentsMap.get(keyDate) + '\n'
        : '';
      absentsMap.set(
        keyDate,
        preabsent +
          absent.student.name +
          getAttendentMark(absent.description, absent.reportType, '') +
          '[' +
          absent.description +
          absent.reportType +
          ']' +
          (absent.absentStatus !== 'PROCESSED' ? ' (결재중)' : ''),
      );

      iDate.setDate(iDate.getDate() + 1);
    } while (iDate < eDate); // 한번에 여러날 출결신고작성해서 각 날짜마다 동일한 사유 작성
  });

  // 체험활동 - 가정학습 내역 확인
  dataField?.items?.map((item: any, i: number) => {
    if (groupId) {
      if (
        item.student.studentGroups[0].group?.id === groupId &&
        item.fieldtripStatus !== 'DELETE_APPEAL' &&
        item.fieldtripStatus !== 'RETURNED'
      ) {
        let iDate = new Date(item.startAt);
        let eDate = new Date(item.endAt);
        eDate.setDate(eDate.getDate() + 1);
        eDate.setHours(0, 0, 0, 0);

        do {
          let keyDate = toLocaleDateFormatString(iDate);
          let preabsent = absentsMap.has(keyDate)
            ? absentsMap.get(keyDate) + '\n'
            : '';
          absentsMap.set(
            keyDate,
            preabsent +
              item.student.name +
              getAttendentMark('인정', '결석', '') +
              '[인정결석]:' +
              (item.type === 'HOME' ? '가정학습' : '체험학습') +
              (item.fieldtripStatus !== 'PROCESSED' ? ' (결재중)' : ''),
          );

          iDate.setDate(iDate.getDate() + 1);
        } while (iDate < eDate); // 한번에 여러날 출결신고작성해서 각 날짜마다 동일한 사유 작성
      }
    }
  });

  students?.map((student: any, i: number) => {
    if (student?.expired) {
      studentsExpired.push(student.id);
    }

    student.attendances?.map((attendance: any, i: number) => {
      let content = JSON.parse(attendance.content);

      let attendanceMarkBefore = '.';
      let attendanceMark = '.';
      //for (let i = 0; i <= content.attendance.length; i++) {
      for (let i = 0; i <= maxPeriod; i++) {
        if (content.attendance[i]?.absent) {
          attendanceMark = getAttendentMark(
            content.attendance[i]?.type2,
            content.attendance[i]?.type1,
            attendanceMarkBefore,
          );

          let attendanceKey =
            student.id +
            attendance.attendance_day +
            content.attendance[i]?.period;

          attendanceMapAdd(attendanceKey, attendanceMark);
        }

        if (
          attendanceMarkBefore !== attendanceMark ||
          (content.attendance[i]?.comment !== undefined &&
            content.attendance[i]?.comment !== '')
        ) {
          attendanceMarkBefore = attendanceMark;

          let reason = content.attendance[i]?.absent
            ? student.name +
              attendanceMark +
              '[' +
              content.attendance[i]?.type2 +
              content.attendance[i]?.type1 +
              ']'
            : student.name;

          let preComment = commentMap.get(attendance.attendance_day);

          if (!preComment) preComment = [];

          preComment?.push({
            confirm: !(
              absentsMap.get(attendance.attendance_day) === undefined ||
              absentsMap.get(attendance.attendance_day)?.indexOf(reason) === -1
            ),
            state: reason + ':' + content.attendance[i]?.comment,
          });

          // let preComment = commentMap.has(attendance.attendance_day)
          //   ? commentMap.get(attendance.attendance_day)
          //   : '';

          commentMap.set(attendance.attendance_day, preComment);
        }
      }
    });
  });

  const getComments = (seldate: string) => {
    let targetComment = commentMap.get(seldate);

    return (
      <>
        {targetComment?.map((el) => (
          <p className={` ${el.confirm ? 'text-black' : 'text-red-500'}  `}>
            {el.state}
          </p>
        ))}
      </>
    );
  };

  const getStringComments = (seldate: string) => {
    let targetComment = commentMap.get(seldate);

    let rst = '';

    targetComment?.map((el) => (rst = rst + el.state + '\n'));

    return rst;
  };

  const changeDate = (offset: number) => {
    let tmpday = selday;
    tmpday.setDate(tmpday.getDate() + offset);
    setSelday(tmpday);
    [tselweek, selmonday, seltuesday, selwednesday, selthursday, selfriday] =
      weekCount(selday);
    setSelweek(tselweek);
  };

  const getAbsent = (id: number, day: Date, period: number) => {
    if (studentsExpired.includes(id)) {
      return '-';
    } else {
      return period > maxPeriod //|| day > toDay
        ? ''
        : attendanceMap.has(id + toLocaleDateFormatString(day) + period)
        ? attendanceMap.get(id + toLocaleDateFormatString(day) + period)
        : '.';
    }
  };

  const editAbsent = (id: number, day: Date, period: number) => {
    if (
      editMode //&& day <= toDay
    ) {
      const strDay = toLocaleDateFormatString(day);

      let contentJson = editMap.get(id + strDay);

      if (!contentJson) {
        const selStudent = students.filter((student: any) => {
          return student.id === id;
        });

        const selAttendance = selStudent[0]?.attendances?.filter(
          (attendance: any) => {
            return attendance.attendance_day === strDay;
          },
        );

        let absentInfo: AbsentSave[] = [];
        contentJson = { attendance: absentInfo };

        if (selAttendance.length > 0) {
          contentJson = JSON.parse(selAttendance[0]?.content);
        } else {
          for (let i = 0; i <= lastPeriod; i++) {
            const t = {
              subject: i === 0 ? '조회' : '',
              period: i,
              creator: '',
              createtime: '',
              editor: '',
              edittime: '',
              comment: '',
              absent: false,
              type1: '',
              type2: '',
            };

            contentJson.attendance.push(t);
          }
        }
      }

      for (let i = 0; i <= maxPeriod; i++) {
        if (
          period === i ||
          (editType.type1 === '지각' && i < period) ||
          (editType.type1 === '조퇴' && i > period) ||
          editType.type1 === '결석' ||
          editType.absent === false
        ) {
          contentJson.attendance[i].comment = '';
          contentJson.attendance[i].type1 = editType.type1;
          contentJson.attendance[i].type2 = editType.type2;
          contentJson.attendance[i].absent = editType.absent;
          if (contentJson.attendance[i].creator === '') {
            contentJson.attendance[i].creator = me?.name || '';
            contentJson.attendance[i].createtime = new Date().toLocaleString();
          } else {
            contentJson.attendance[i].editor = me?.name || '';
            contentJson.attendance[i].edittime = new Date().toLocaleString();
          }

          attendanceMapUpsert(id + strDay + i, editType.mark);
        }
      }

      editMapUpsert(id + strDay, contentJson);

      mutate({
        attendanceday: strDay,
        absent: true,
        comment: '수정됨',
        type1: '',
        type2: '',
        content: JSON.stringify(contentJson),
        year: year,
        semester: semester,
        userid: id,
      });
    }
  };

  const exportData: any[] = [];

  // 출석부 excel 데이터 시작
  const makeCsvData = () => {
    console.log('엑셀데이터 생성 시작');
    const csvHeader = (iday: Date) => {
      let [tweek, tmonday, ttuesday, twednesday, tthursday, tfriday] =
        weekCount(iday);

      exportData.push([
        year + '년',
        tweek + '주차',
        '월',
        makeDateToStringType2(tmonday),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '화',
        makeDateToStringType2(ttuesday),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '수',
        makeDateToStringType2(twednesday),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '목',
        makeDateToStringType2(tthursday),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '금',
        makeDateToStringType2(tfriday),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ]);

      exportData.push([
        '',
        '과목',
        '조회',
        timetableContent && timetableContent[0]
          ? timetableContent[0].monday.subject
          : '',
        timetableContent && timetableContent[1]
          ? timetableContent[1].monday.subject
          : '',
        timetableContent && timetableContent[2]
          ? timetableContent[2].monday.subject
          : '',
        timetableContent && timetableContent[3]
          ? timetableContent[3].monday.subject
          : '',
        timetableContent && timetableContent[4]
          ? timetableContent[4].monday.subject
          : '',
        timetableContent && timetableContent[5]
          ? timetableContent[5].monday.subject
          : '',
        timetableContent && timetableContent[6]
          ? timetableContent[6].monday.subject
          : '',
        timetableContent && timetableContent[7]
          ? timetableContent[7].monday.subject
          : '',
        '조회',
        timetableContent && timetableContent[0]
          ? timetableContent[0].tuesday.subject
          : '',
        timetableContent && timetableContent[1]
          ? timetableContent[1].tuesday.subject
          : '',
        timetableContent && timetableContent[2]
          ? timetableContent[2].tuesday.subject
          : '',
        timetableContent && timetableContent[3]
          ? timetableContent[3].tuesday.subject
          : '',
        timetableContent && timetableContent[4]
          ? timetableContent[4].tuesday.subject
          : '',
        timetableContent && timetableContent[5]
          ? timetableContent[5].tuesday.subject
          : '',
        timetableContent && timetableContent[6]
          ? timetableContent[6].tuesday.subject
          : '',
        timetableContent && timetableContent[7]
          ? timetableContent[7].tuesday.subject
          : '',
        '조회',
        timetableContent && timetableContent[0]
          ? timetableContent[0].wednesday.subject
          : '',
        timetableContent && timetableContent[1]
          ? timetableContent[1].wednesday.subject
          : '',
        timetableContent && timetableContent[2]
          ? timetableContent[2].wednesday.subject
          : '',
        timetableContent && timetableContent[3]
          ? timetableContent[3].wednesday.subject
          : '',
        timetableContent && timetableContent[4]
          ? timetableContent[4].wednesday.subject
          : '',
        timetableContent && timetableContent[5]
          ? timetableContent[5].wednesday.subject
          : '',
        timetableContent && timetableContent[6]
          ? timetableContent[6].wednesday.subject
          : '',
        timetableContent && timetableContent[7]
          ? timetableContent[7].wednesday.subject
          : '',
        '조회',
        timetableContent && timetableContent[0]
          ? timetableContent[0].thursday.subject
          : '',
        timetableContent && timetableContent[1]
          ? timetableContent[1].thursday.subject
          : '',
        timetableContent && timetableContent[2]
          ? timetableContent[2].thursday.subject
          : '',
        timetableContent && timetableContent[3]
          ? timetableContent[3].thursday.subject
          : '',
        timetableContent && timetableContent[4]
          ? timetableContent[4].thursday.subject
          : '',
        timetableContent && timetableContent[5]
          ? timetableContent[5].thursday.subject
          : '',
        timetableContent && timetableContent[6]
          ? timetableContent[6].thursday.subject
          : '',
        timetableContent && timetableContent[7]
          ? timetableContent[7].thursday.subject
          : '',
        '조회',
        timetableContent && timetableContent[0]
          ? timetableContent[0].friday.subject
          : '',
        timetableContent && timetableContent[1]
          ? timetableContent[1].friday.subject
          : '',
        timetableContent && timetableContent[2]
          ? timetableContent[2].friday.subject
          : '',
        timetableContent && timetableContent[3]
          ? timetableContent[3].friday.subject
          : '',
        timetableContent && timetableContent[4]
          ? timetableContent[4].friday.subject
          : '',
        timetableContent && timetableContent[5]
          ? timetableContent[5].friday.subject
          : '',
        timetableContent && timetableContent[6]
          ? timetableContent[6].friday.subject
          : '',
        timetableContent && timetableContent[7]
          ? timetableContent[7].friday.subject
          : '',
      ]);

      exportData.push([
        '',
        '선생님',
        teacher,
        timetableContent && timetableContent[0]
          ? timetableContent[0].monday.teacher
          : '',
        timetableContent && timetableContent[1]
          ? timetableContent[1].monday.teacher
          : '',
        timetableContent && timetableContent[2]
          ? timetableContent[2].monday.teacher
          : '',
        timetableContent && timetableContent[3]
          ? timetableContent[3].monday.teacher
          : '',
        timetableContent && timetableContent[4]
          ? timetableContent[4].monday.teacher
          : '',
        timetableContent && timetableContent[5]
          ? timetableContent[5].monday.teacher
          : '',
        timetableContent && timetableContent[6]
          ? timetableContent[6].monday.teacher
          : '',
        timetableContent && timetableContent[7]
          ? timetableContent[7].monday.teacher
          : '',
        teacher,
        timetableContent && timetableContent[0]
          ? timetableContent[0].tuesday.teacher
          : '',
        timetableContent && timetableContent[1]
          ? timetableContent[1].tuesday.teacher
          : '',
        timetableContent && timetableContent[2]
          ? timetableContent[2].tuesday.teacher
          : '',
        timetableContent && timetableContent[3]
          ? timetableContent[3].tuesday.teacher
          : '',
        timetableContent && timetableContent[4]
          ? timetableContent[4].tuesday.teacher
          : '',
        timetableContent && timetableContent[5]
          ? timetableContent[5].tuesday.teacher
          : '',
        timetableContent && timetableContent[6]
          ? timetableContent[6].tuesday.teacher
          : '',
        timetableContent && timetableContent[7]
          ? timetableContent[7].tuesday.teacher
          : '',
        teacher,
        timetableContent && timetableContent[0]
          ? timetableContent[0].wednesday.teacher
          : '',
        timetableContent && timetableContent[1]
          ? timetableContent[1].wednesday.teacher
          : '',
        timetableContent && timetableContent[2]
          ? timetableContent[2].wednesday.teacher
          : '',
        timetableContent && timetableContent[3]
          ? timetableContent[3].wednesday.teacher
          : '',
        timetableContent && timetableContent[4]
          ? timetableContent[4].wednesday.teacher
          : '',
        timetableContent && timetableContent[5]
          ? timetableContent[5].wednesday.teacher
          : '',
        timetableContent && timetableContent[6]
          ? timetableContent[6].wednesday.teacher
          : '',
        timetableContent && timetableContent[7]
          ? timetableContent[7].wednesday.teacher
          : '',
        teacher,
        timetableContent && timetableContent[0]
          ? timetableContent[0].thursday.teacher
          : '',
        timetableContent && timetableContent[1]
          ? timetableContent[1].thursday.teacher
          : '',
        timetableContent && timetableContent[2]
          ? timetableContent[2].thursday.teacher
          : '',
        timetableContent && timetableContent[3]
          ? timetableContent[3].thursday.teacher
          : '',
        timetableContent && timetableContent[4]
          ? timetableContent[4].thursday.teacher
          : '',
        timetableContent && timetableContent[5]
          ? timetableContent[5].thursday.teacher
          : '',
        timetableContent && timetableContent[6]
          ? timetableContent[6].thursday.teacher
          : '',
        timetableContent && timetableContent[7]
          ? timetableContent[7].thursday.teacher
          : '',
        teacher,
        timetableContent && timetableContent[0]
          ? timetableContent[0].friday.teacher
          : '',
        timetableContent && timetableContent[1]
          ? timetableContent[1].friday.teacher
          : '',
        timetableContent && timetableContent[2]
          ? timetableContent[2].friday.teacher
          : '',
        timetableContent && timetableContent[3]
          ? timetableContent[3].friday.teacher
          : '',
        timetableContent && timetableContent[4]
          ? timetableContent[4].friday.teacher
          : '',
        timetableContent && timetableContent[5]
          ? timetableContent[5].friday.teacher
          : '',
        timetableContent && timetableContent[6]
          ? timetableContent[6].friday.teacher
          : '',
        timetableContent && timetableContent[7]
          ? timetableContent[7].friday.teacher
          : '',
      ]);

      exportData.push([
        '번호',
        '이름',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
      ]);

      students?.map((student: any, i: number) => {
        exportData.push([
          student.studentGroups[0].studentNumber,
          student.name,
          getAbsent(student.id, tmonday, 0),
          periodMon < 1 ? '' : getAbsent(student.id, tmonday, 1),
          periodMon < 2 ? '' : getAbsent(student.id, tmonday, 2),
          periodMon < 3 ? '' : getAbsent(student.id, tmonday, 3),
          periodMon < 4 ? '' : getAbsent(student.id, tmonday, 4),
          periodMon < 5 ? '' : getAbsent(student.id, tmonday, 5),
          periodMon < 6 ? '' : getAbsent(student.id, tmonday, 6),
          periodMon < 7 ? '' : getAbsent(student.id, tmonday, 7),
          periodMon < 8 ? '' : getAbsent(student.id, tmonday, 8),
          getAbsent(student.id, ttuesday, 0),
          periodTue < 1 ? '' : getAbsent(student.id, ttuesday, 1),
          periodTue < 2 ? '' : getAbsent(student.id, ttuesday, 2),
          periodTue < 3 ? '' : getAbsent(student.id, ttuesday, 3),
          periodTue < 4 ? '' : getAbsent(student.id, ttuesday, 4),
          periodTue < 5 ? '' : getAbsent(student.id, ttuesday, 5),
          periodTue < 6 ? '' : getAbsent(student.id, ttuesday, 6),
          periodTue < 7 ? '' : getAbsent(student.id, ttuesday, 7),
          periodTue < 8 ? '' : getAbsent(student.id, ttuesday, 8),
          getAbsent(student.id, twednesday, 0),
          periodWed < 1 ? '' : getAbsent(student.id, twednesday, 1),
          periodWed < 2 ? '' : getAbsent(student.id, twednesday, 2),
          periodWed < 3 ? '' : getAbsent(student.id, twednesday, 3),
          periodWed < 4 ? '' : getAbsent(student.id, twednesday, 4),
          periodWed < 5 ? '' : getAbsent(student.id, twednesday, 5),
          periodWed < 6 ? '' : getAbsent(student.id, twednesday, 6),
          periodWed < 7 ? '' : getAbsent(student.id, twednesday, 7),
          periodWed < 8 ? '' : getAbsent(student.id, twednesday, 8),
          getAbsent(student.id, tthursday, 0),
          periodThu < 1 ? '' : getAbsent(student.id, tthursday, 1),
          periodThu < 2 ? '' : getAbsent(student.id, tthursday, 2),
          periodThu < 3 ? '' : getAbsent(student.id, tthursday, 3),
          periodThu < 4 ? '' : getAbsent(student.id, tthursday, 4),
          periodThu < 5 ? '' : getAbsent(student.id, tthursday, 5),
          periodThu < 6 ? '' : getAbsent(student.id, tthursday, 6),
          periodThu < 7 ? '' : getAbsent(student.id, tthursday, 7),
          periodThu < 8 ? '' : getAbsent(student.id, tthursday, 8),
          getAbsent(student.id, tfriday, 0),
          periodFri < 1 ? '' : getAbsent(student.id, tfriday, 1),
          periodFri < 2 ? '' : getAbsent(student.id, tfriday, 2),
          periodFri < 3 ? '' : getAbsent(student.id, tfriday, 3),
          periodFri < 4 ? '' : getAbsent(student.id, tfriday, 4),
          periodFri < 5 ? '' : getAbsent(student.id, tfriday, 5),
          periodFri < 6 ? '' : getAbsent(student.id, tfriday, 6),
          periodFri < 7 ? '' : getAbsent(student.id, tfriday, 7),
          periodFri < 8 ? '' : getAbsent(student.id, tfriday, 8),
        ]);
      });

      exportData.push([
        '',
        '출결신청',
        absentsMap.get(toLocaleDateFormatString(tmonday)),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        absentsMap.get(toLocaleDateFormatString(ttuesday)),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        absentsMap.get(toLocaleDateFormatString(twednesday)),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        absentsMap.get(toLocaleDateFormatString(tthursday)),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        absentsMap.get(toLocaleDateFormatString(tfriday)),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ]);

      exportData.push([
        '',
        '특기사항',
        getStringComments(toLocaleDateFormatString(tmonday)),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        getStringComments(toLocaleDateFormatString(ttuesday)),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        getStringComments(toLocaleDateFormatString(twednesday)),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        getStringComments(toLocaleDateFormatString(tthursday)),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        getStringComments(toLocaleDateFormatString(tfriday)),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ]);

      exportData.push(['']);
      exportData.push(['']);
    };

    let startDate = new Date(year, 2, 2); // 3월2일부터 출력

    while (startDate < toDay) {
      csvHeader(startDate);
      startDate.setDate(startDate.getDate() + 7);
    }

    setCsvData(exportData);

    setLoading(false);
    // console.log(exportData);
    // console.log(csvData);
  };

  // 출석부 excel 데이터 끝

  const makeStudentListCsvData = () => {
    console.log('명렬표데이터 생성 시작');

    const exportStudentListData: any[] = [];
    exportStudentListData.push([]);
    exportStudentListData.push(['', '', '', '학급명렬표']);
    exportStudentListData.push([]);

    exportStudentListData.push([
      klassName + '  ' + data?.attendance?.teacher[0].name + '선생님',
    ]);
    exportStudentListData.push([]);
    exportStudentListData.push(['순서', '반', '번호', '이름', '비고']);

    students?.map((student: any, i: number) => {
      exportStudentListData.push([
        i + 1,
        klassName,
        student.studentGroups[0].studentNumber,
        student.name,
        student.expiredReason,
      ]);
    });

    setCsvDataStudent(exportStudentListData);
    setLoading(false);

    console.log('명렬표데이터 생성 종료');
  };

  return (
    <div className="col-span-6 ">
      {loading && <Blank />}
      {isLoading && <Blank />}
      <div className="w-full justify-center">
        <div className="px-6 py-6">
          <div className="flex justify-between">
            <div>
              <h1 className="text-2xl font-semibold">
                출석부{' '}
                {klassName &&
                  '(' + klassName + ' - 담임선생님 : ' + teacher + ')'}
              </h1>
              <div className="text-sm mb-5 text-grey-5">
                ※ 출석 체크 및 출결서류 제출 내역 비교 서류
              </div>
            </div>

            <Select
              value={groupId}
              onChange={(e) => setGroupId(Number(e.target.value))}
              placeholder="반 선택"
            >
              {groups?.map((group: Group) => (
                <option value={group.id}>{group.name}</option>
              ))}
            </Select>
          </div>
        </div>
        <Divider />
        <div className="py-3 px-6 bg-white flex items-center  w-full ">
          <div className="w-30 text-center">
            <Button
              text="Excel"
              tw={{
                backgroundColor: 'bg-green-500',
                minWidth: 'min-w-max',
                paddingY: 'py-2.5',
                height: 'h-11',
                paddingX: 'px-4',
                width: 'w-full',
              }}
              onClick={() => {
                setLoading(true); // to-do 엑셀출력 시작시 로딩화면 표시되도록 개선해야 함.
                makeCsvData();
                setClicked(true);
              }}
            />
            <CSVLink
              data={csvData}
              filename={`출석부-${klassName}.csv`}
              ref={csvRef}
            ></CSVLink>
          </div>

          <div className="w-full flex items-center justify-center space-x-4">
            <div
              className={`rounded-full w-10 h-10 flex items-center justify-center border-2 cursor-pointer`}
              onClick={() => changeDate(-7)}
            >
              <ChevronLeftIcon />
            </div>
            <div className="text-center">
              <p className="text-xl font-semibold">
                {selday.getFullYear()}년 {/* {selweek}주차 */}
              </p>
              <p className="text-sm">
                {selmonday.getMonth() + 1}월 {selmonday.getDate()}일 ~{' '}
                {selfriday.getMonth() + 1}월 {selfriday.getDate()}일
              </p>
            </div>
            <div
              className={`rounded-full w-10 h-10 flex items-center justify-center border-2 cursor-pointer`}
              onClick={() => changeDate(7)}
            >
              <ChevronRightIcon />
            </div>
          </div>

          <div className="w-30 text-center">
            <Button
              text="명렬표"
              tw={{
                backgroundColor: 'bg-green-500',
                minWidth: 'min-w-max',
                paddingY: 'py-2.5',
                height: 'h-11',
                paddingX: 'px-4',
                width: 'w-full',
              }}
              onClick={() => {
                setLoading(true); // to-do 엑셀출력 시작시 로딩화면 표시되도록 개선해야 함.
                makeStudentListCsvData();
                setClickedStudent(true);
              }}
            />
            <CSVLink
              data={csvDataStudent}
              filename={`명렬표-${klassName}.csv`}
              ref={csvRefStudent}
            ></CSVLink>
          </div>
        </div>
        <div className="h-screen-10 overflow-y-scroll pb-20 w-full px-6 py-6">
          {timetableContent?.length === 0 && (
            <div className="text-center text-3xl">반을 선택해주세요.</div>
          )}
          {timetableContent?.length > 0 && (
            <table className="table-fixed text-center w-full border-collapse rounded-lg mb-16">
              <thead>
                <tr className="h-2">
                  <td
                    colSpan={2}
                    className="w-30 border-grey-6 border py-2  bg-gray-50"
                  ></td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="w-1/5 border-grey-6 border py-2 bg-orange-2"
                  >
                    {makeDateToStringType2(selmonday)} 월
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="w-1/5 border-grey-6 border py-2 bg-pink-2"
                  >
                    {makeDateToStringType2(seltuesday)} 화
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="w-1/5 border-grey-6 border py-2 bg-lightblue-2"
                  >
                    {makeDateToStringType2(selwednesday)} 수
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="w-1/5 border-grey-6 border py-2 bg-lightgreen-2"
                  >
                    {makeDateToStringType2(selthursday)} 목
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="w-1/5 border-grey-6 border py-2 bg-lightpurple-2"
                  >
                    {makeDateToStringType2(selfriday)} 금
                  </td>
                </tr>
                <tr className="h-2">
                  <td colSpan={2} className="border-grey-6 border bg-gray-50">
                    과목
                  </td>
                  <td className="break-all border-grey-6 border text-sm bg-orange-1">
                    조회
                  </td>
                  {new Array(maxPeriod).fill(null).map((el, i: number) => (
                    <td className="break-all border-grey-6 border text-sm bg-orange-1">
                      {timetableContent && timetableContent[i]
                        ? timetableContent[i].monday.type === 'move'
                          ? '분반'
                          : timetableContent[i].monday.subject
                        : ''}
                    </td>
                  ))}

                  <td className="break-all border-grey-6 border text-sm bg-pink-1">
                    조회
                  </td>
                  {new Array(maxPeriod).fill(null).map((el, i: number) => (
                    <td className="break-all border-grey-6 border text-sm bg-pink-1">
                      {timetableContent && timetableContent[i]
                        ? timetableContent[i].tuesday.type === 'move'
                          ? '분반'
                          : timetableContent[i].tuesday.subject
                        : ''}
                    </td>
                  ))}
                  <td className="break-all border-grey-6 border text-sm bg-lightblue-1">
                    조회
                  </td>
                  {new Array(maxPeriod).fill(null).map((el, i: number) => (
                    <td className="break-all border-grey-6 border text-sm bg-lightblue-1">
                      {timetableContent && timetableContent[i]
                        ? timetableContent[i].wednesday.type === 'move'
                          ? '분반'
                          : timetableContent[i].wednesday.subject
                        : ''}
                    </td>
                  ))}
                  <td className="break-all border-grey-6 border text-sm bg-lightgreen-1">
                    조회
                  </td>
                  {new Array(maxPeriod).fill(null).map((el, i: number) => (
                    <td className="break-all border-grey-6 border text-sm bg-lightgreen-1">
                      {timetableContent && timetableContent[i]
                        ? timetableContent[i].thursday.type === 'move'
                          ? '분반'
                          : timetableContent[i].thursday.subject
                        : ''}
                    </td>
                  ))}
                  <td className="break-all border-grey-6 border text-sm bg-lightpurple-1">
                    조회
                  </td>
                  {new Array(maxPeriod).fill(null).map((el, i: number) => (
                    <td className="break-all border-grey-6 border text-sm bg-lightpurple-1">
                      {timetableContent && timetableContent[i]
                        ? timetableContent[i].friday.type === 'move'
                          ? '분반'
                          : timetableContent[i].friday.subject
                        : ''}
                    </td>
                  ))}
                </tr>
                <tr className="h-2">
                  <td
                    colSpan={2}
                    className="whitespace-pre border-grey-6 border bg-gray-50"
                  >
                    선생님
                  </td>

                  <td className="break-all border-grey-6 border text-sm bg-orange-1">
                    {teacher}
                  </td>
                  {new Array(maxPeriod).fill(null).map((el, i: number) => (
                    <td className="break-all border-grey-6 border text-sm bg-orange-1">
                      {timetableContent && timetableContent[i]
                        ? timetableContent[i].monday.type === 'move'
                          ? '분반'
                          : timetableContent[i].monday.teacher
                        : ''}
                    </td>
                  ))}
                  <td className="break-all border-grey-6 border text-sm bg-pink-1">
                    {teacher}
                  </td>
                  {new Array(maxPeriod).fill(null).map((el, i: number) => (
                    <td className="break-all border-grey-6 border text-sm bg-pink-1">
                      {timetableContent && timetableContent[i]
                        ? timetableContent[i].tuesday.type === 'move'
                          ? '분반'
                          : timetableContent[i].tuesday.teacher
                        : ''}
                    </td>
                  ))}
                  <td className="break-all border-grey-6 border text-sm bg-lightblue-1">
                    {teacher}
                  </td>
                  {new Array(maxPeriod).fill(null).map((el, i: number) => (
                    <td className="break-all border-grey-6 border text-sm bg-lightblue-1">
                      {timetableContent && timetableContent[i]
                        ? timetableContent[i].wednesday.type === 'move'
                          ? '분반'
                          : timetableContent[i].wednesday.teacher
                        : ''}
                    </td>
                  ))}
                  <td className="break-all border-grey-6 border text-sm bg-lightgreen-1">
                    {teacher}
                  </td>
                  {new Array(maxPeriod).fill(null).map((el, i: number) => (
                    <td className="break-all border-grey-6 border text-sm bg-lightgreen-1">
                      {timetableContent && timetableContent[i]
                        ? timetableContent[i].thursday.type === 'move'
                          ? '분반'
                          : timetableContent[i].thursday.teacher
                        : ''}
                    </td>
                  ))}
                  <td className="break-all border-grey-6 border text-sm bg-lightpurple-1">
                    {teacher}
                  </td>
                  {new Array(maxPeriod).fill(null).map((el, i: number) => (
                    <td className="break-all border-grey-6 border text-sm bg-lightpurple-1">
                      {timetableContent && timetableContent[i]
                        ? timetableContent[i].friday.type === 'move'
                          ? '분반'
                          : timetableContent[i].friday.teacher
                        : ''}
                    </td>
                  ))}
                </tr>
                <tr className="h-2">
                  {/* <td colSpan={2} className="border-grey-6 border">교시</td> */}
                  <td className="whitespace-pre border-grey-6 border bg-gray-50">
                    번호
                  </td>
                  <td className="whitespace-pre border-grey-6 border bg-gray-50">
                    이름
                  </td>

                  {new Array(maxPeriod + 1).fill(null).map((el, i: number) => (
                    <td className="border-grey-6 border text-sm bg-orange-1">
                      {i}
                    </td>
                  ))}

                  {new Array(maxPeriod + 1).fill(null).map((el, i: number) => (
                    <td className="border-grey-6 border text-sm bg-pink-1">
                      {i}
                    </td>
                  ))}
                  {new Array(maxPeriod + 1).fill(null).map((el, i: number) => (
                    <td className="border-grey-6 border text-sm bg-lightblue-1">
                      {i}
                    </td>
                  ))}
                  {new Array(maxPeriod + 1).fill(null).map((el, i: number) => (
                    <td className="border-grey-6 border text-sm bg-lightgreen-1">
                      {i}
                    </td>
                  ))}
                  {new Array(maxPeriod + 1).fill(null).map((el, i: number) => (
                    <td className="border-grey-6 border text-sm bg-lightpurple-1">
                      {i}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {students &&
                  students?.map((student: any, i: number) => (
                    <tr
                      key={student.id}
                      className={`h-2 ${
                        i % 5 === 4 && 'border-gray-800 border-b-2'
                      }`}
                    >
                      <td className="border-grey-6 border w-10">
                        {student.studentGroups[0].studentNumber}
                      </td>
                      <td
                        className={`border-grey-6 border truncate w-12 text-sm 
                      ${studentsExpired.includes(student.id) && 'text-red-600'}
                      `}
                      >
                        {student.name}
                      </td>
                      {new Array(maxPeriod + 1)
                        .fill(null)
                        .map((el, i: number) => (
                          <td
                            className={`border-grey-6 border bg-orange-3 ${
                              getAbsent(student.id, selmonday, i) === '.'
                                ? ''
                                : 'text-red-500'
                            } 
                          ${
                            editMode && 'cursor-pointer'
                          }                          
                          `}
                            onClick={() => editAbsent(student.id, selmonday, i)}
                          >
                            {periodMon >= i &&
                              getAbsent(student.id, selmonday, i)}
                          </td>
                        ))}
                      {new Array(maxPeriod + 1)
                        .fill(null)
                        .map((el, i: number) => (
                          <td
                            className={`border-grey-6 border bg-pink-3 ${
                              getAbsent(student.id, seltuesday, i) === '.'
                                ? ''
                                : 'text-red-500'
                            }
                          ${
                            editMode && 'cursor-pointer'
                          }                          
                          `}
                            onClick={() =>
                              editAbsent(student.id, seltuesday, i)
                            }
                          >
                            {periodTue >= i &&
                              getAbsent(student.id, seltuesday, i)}
                          </td>
                        ))}
                      {new Array(maxPeriod + 1)
                        .fill(null)
                        .map((el, i: number) => (
                          <td
                            className={`border-grey-6 border bg-lightblue-3 ${
                              getAbsent(student.id, selwednesday, i) === '.'
                                ? ''
                                : 'text-red-500'
                            }
                          ${
                            editMode && 'cursor-pointer'
                          }                          
                          `}
                            onClick={() =>
                              editAbsent(student.id, selwednesday, i)
                            }
                          >
                            {periodWed >= i &&
                              getAbsent(student.id, selwednesday, i)}
                          </td>
                        ))}
                      {new Array(maxPeriod + 1)
                        .fill(null)
                        .map((el, i: number) => (
                          <td
                            className={`border-grey-6 border bg-lightgreen-3 ${
                              getAbsent(student.id, selthursday, i) === '.'
                                ? ''
                                : 'text-red-500'
                            }
                          ${
                            editMode && 'cursor-pointer'
                          }                          
                          `}
                            onClick={() =>
                              editAbsent(student.id, selthursday, i)
                            }
                          >
                            {periodThu >= i &&
                              getAbsent(student.id, selthursday, i)}
                          </td>
                        ))}
                      {new Array(maxPeriod + 1)
                        .fill(null)
                        .map((el, i: number) => (
                          <td
                            className={`border-grey-6 border bg-lightpurple-3 ${
                              getAbsent(student.id, selfriday, i) === '.'
                                ? ''
                                : 'text-red-500'
                            }
                          ${
                            editMode && 'cursor-pointer'
                          }                          
                          `}
                            onClick={() => editAbsent(student.id, selfriday, i)}
                          >
                            {periodFri >= i &&
                              getAbsent(student.id, selfriday, i)}
                          </td>
                        ))}
                    </tr>
                  ))}
                <tr className="h-2">
                  <td colSpan={2} className="border-grey-6 border bg-gray-50">
                    출결신청
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="border-grey-6 text-xs border text-left whitespace-pre-line"
                  >
                    {absentsMap.get(toLocaleDateFormatString(selmonday))}
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="border-grey-6 text-xs border text-left whitespace-pre-line"
                  >
                    {absentsMap.get(toLocaleDateFormatString(seltuesday))}
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="border-grey-6 text-xs border text-left whitespace-pre-line"
                  >
                    {absentsMap.get(toLocaleDateFormatString(selwednesday))}
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="border-grey-6 text-xs border text-left whitespace-pre-line"
                  >
                    {absentsMap.get(toLocaleDateFormatString(selthursday))}
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="border-grey-6 text-xs border text-left whitespace-pre-line"
                  >
                    {absentsMap.get(toLocaleDateFormatString(selfriday))}
                  </td>
                </tr>
                <tr className="h-2">
                  <td colSpan={2} className="border-grey-6 border bg-gray-50">
                    특기사항
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="border-grey-6 text-xs border text-left whitespace-pre-line"
                  >
                    {getComments(toLocaleDateFormatString(selmonday))}
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="border-grey-6 text-xs border text-left whitespace-pre-line"
                  >
                    {getComments(toLocaleDateFormatString(seltuesday))}
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="border-grey-6 text-xs border text-left whitespace-pre-line"
                  >
                    {getComments(toLocaleDateFormatString(selwednesday))}
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="border-grey-6 text-xs border text-left whitespace-pre-line"
                  >
                    {getComments(toLocaleDateFormatString(selthursday))}
                  </td>
                  <td
                    colSpan={maxPeriod + 1}
                    className="border-grey-6 text-xs border text-left whitespace-pre-line"
                  >
                    {getComments(toLocaleDateFormatString(selfriday))}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {me?.name === teacher && editMode === false ? (
            <div className="mt-6 w-full flex items-center justify-center flex-col">
              <Button
                onClick={() => {
                  setEditMode(true);
                }}
              >
                수정하기
              </Button>
            </div>
          ) : (
            <div className="mt-32"></div>
          )}

          {editMode && (
            <div className=" flex absolute bottom-4 bg-gray-600 rounded-lg p-5 mr-10 space-x-2 items-stretch">
              <div
                className={`grid place-items-center rounded-lg w-30 text-center cursor-pointer align-middle mt-2 ${
                  '.' === editType.mark
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 text-black'
                }
            `}
                onClick={() =>
                  setEditType({
                    mark: '.',
                    type1: '',
                    type2: '',
                    absent: false,
                  })
                }
              >
                출석
              </div>
              <div className="space-y-2 space-x-2">
                <Chip
                  text={'♡질병결석'}
                  onClick={() =>
                    setEditType({
                      mark: '♡',
                      type1: '결석',
                      type2: '질병',
                      absent: true,
                    })
                  }
                  active={'♡' === editType.mark}
                  tw={{
                    paddingY: 'py-0.5',
                    minWidth: 'min-w-27',
                  }}
                />
                <Chip
                  text={'♥미인정결석'}
                  onClick={() =>
                    setEditType({
                      mark: '♥',
                      type1: '결석',
                      type2: '미인정',
                      absent: true,
                    })
                  }
                  active={'♥' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'▲기타결석'}
                  onClick={() =>
                    setEditType({
                      mark: '▲',
                      type1: '결석',
                      type2: '기타',
                      absent: true,
                    })
                  }
                  active={'▲' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'△인정결석'}
                  onClick={() =>
                    setEditType({
                      mark: '△',
                      type1: '결석',
                      type2: '인정',
                      absent: true,
                    })
                  }
                  active={'△' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />

                <Chip
                  text={'＃질병지각'}
                  onClick={() =>
                    setEditType({
                      mark: '＃',
                      type1: '지각',
                      type2: '질병',
                      absent: true,
                    })
                  }
                  active={'＃' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'Ｘ미인정지각'}
                  onClick={() =>
                    setEditType({
                      mark: 'Ｘ',
                      type1: '지각',
                      type2: '미인정',
                      absent: true,
                    })
                  }
                  active={'Ｘ' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'≠기타지각'}
                  onClick={() =>
                    setEditType({
                      mark: '≠',
                      type1: '지각',
                      type2: '기타',
                      absent: true,
                    })
                  }
                  active={'≠' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'◁인정지각'}
                  onClick={() =>
                    setEditType({
                      mark: '◁',
                      type1: '지각',
                      type2: '인정',
                      absent: true,
                    })
                  }
                  active={'◁' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />

                <Chip
                  text={'＠질병조퇴'}
                  onClick={() =>
                    setEditType({
                      mark: '＠',
                      type1: '조퇴',
                      type2: '질병',
                      absent: true,
                    })
                  }
                  active={'＠' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'◎미인정조퇴'}
                  onClick={() =>
                    setEditType({
                      mark: '◎',
                      type1: '조퇴',
                      type2: '미인정',
                      absent: true,
                    })
                  }
                  active={'◎' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'∞기타조퇴'}
                  onClick={() =>
                    setEditType({
                      mark: '∞',
                      type1: '조퇴',
                      type2: '기타',
                      absent: true,
                    })
                  }
                  active={'∞' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'▷인정조퇴'}
                  onClick={() =>
                    setEditType({
                      mark: '▷',
                      type1: '조퇴',
                      type2: '인정',
                      absent: true,
                    })
                  }
                  active={'▷' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'☆질병결과'}
                  onClick={() =>
                    setEditType({
                      mark: '☆',
                      type1: '결과',
                      type2: '질병',
                      absent: true,
                    })
                  }
                  active={'☆' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'◇미인정결과'}
                  onClick={() =>
                    setEditType({
                      mark: '◇',
                      type1: '결과',
                      type2: '미인정',
                      absent: true,
                    })
                  }
                  active={'◇' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'＝기타결과'}
                  onClick={() =>
                    setEditType({
                      mark: '＝',
                      type1: '결과',
                      type2: '기타',
                      absent: true,
                    })
                  }
                  active={'＝' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'▽인정결과'}
                  onClick={() =>
                    setEditType({
                      mark: '▽',
                      type1: '결과',
                      type2: '인정',
                      absent: true,
                    })
                  }
                  active={'▽' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />

                <Chip
                  text={'v질병기타'}
                  onClick={() =>
                    setEditType({
                      mark: 'v1',
                      type1: '기타',
                      type2: '질병',
                      absent: true,
                    })
                  }
                  active={'v1' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'v미인정기타'}
                  onClick={() =>
                    setEditType({
                      mark: 'v2',
                      type1: '기타',
                      type2: '미인정',
                      absent: true,
                    })
                  }
                  active={'v2' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'v기타기타'}
                  onClick={() =>
                    setEditType({
                      mark: 'v3',
                      type1: '기타',
                      type2: '기타',
                      absent: true,
                    })
                  }
                  active={'v3' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'v인정기타'}
                  onClick={() =>
                    setEditType({
                      mark: 'v4',
                      type1: '기타',
                      type2: '인정',
                      absent: true,
                    })
                  }
                  active={'v4' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
              </div>
              <div
                className={`grid place-items-center rounded-lg w-30 text-center cursor-pointer align-middle mt-2 bg-brand-1 text-white
            `}
                onClick={() => {
                  setEditMode(false);
                  window?.location?.reload();
                }}
              >
                닫기
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
