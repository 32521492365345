import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  Checkbox,
  gql,
  Section,
  TextField,
  TopNavbar,
  useMutation,
  useQuery,
} from '../../../reusable';
import { ErrorBlank, FieldtripDatePicker, SelectValues } from '../../../components';
import { UPDATE_OUTING } from '../../../query';
import { makeDateToString, makeTimeToString } from '../../../utils';

const reportType = ['조퇴', '외출', '확인'];

const GET_OUTING_DETAIL = gql`
  query Me {
    me {
      id
      name
      email
      role
      nokName
      nokPhone
      klassGroup {
        id
        studentNumber
        group {
          id
          name
          type
        }
      }
    }
  }
`;

export const CREATE_OUTING = gql`
  mutation ($data: String!) {
    createOuting(data: $data) {
      id
    }
  }
`;

const getMeridiemHours = (date: string | undefined) => {
  if (!date) return 0;
  return new Date(date).getHours();
};

interface OutingAddPageProps {
  outingData?: any;
  goDetail: () => void;
}

export const OutingAddPage: React.FC<OutingAddPageProps> = ({
  outingData,
  goDetail,
}) => {
  const history = useHistory();

  const { loading, error, data } = useQuery(GET_OUTING_DETAIL);
  const studentName = data?.me?.name;

  const [startAt, setStartAt] = useState(
    outingData ? makeDateToString(new Date(outingData.startAt)) : makeDateToString(new Date()),
  );
  const [startHour, setStartHour] = useState(
    outingData ? getMeridiemHours(outingData.startAt) : 9,
  );
  const [startMinute, setStartMinute] = useState(
    outingData?.startAt ? new Date(outingData.startAt).getMinutes() : 0,
  );
  const [endAt, setEndAt] = useState(
    outingData ? makeDateToString(new Date(outingData.endAt)) : makeDateToString(new Date()),
  );
  const [endHour, setEndHour] = useState(
    outingData ? getMeridiemHours(outingData.endAt) : 16,
  );
  const [endMinute, setEndMinute] = useState(
    outingData?.endAt ? new Date(outingData.endAt).getMinutes() : 40,
  );
  const [report, setReport] = useState(outingData ? outingData.type : '');
  const [reason, setReason] = useState(outingData ? outingData.reason : '');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState<any>(false);
  const [agree, setAgree] = useState(outingData ? true : false);

  const makeStartAt = () => {
    let date = new Date();
    if (startAt) {
      date = new Date(startAt);
    }
    let hour = Number(startHour);
    date.setHours(hour, Number(startMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };
  const makeEndAt = () => {
    let date = new Date();
    if (startAt) {
      date = new Date(startAt);
    }
    if (report === '확인') {
      date = new Date(endAt);
    }
    let hour = Number(endHour);
    date.setHours(hour, Number(endMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };

  const [createOuting] = useMutation(CREATE_OUTING, {
    variables: {
      data: JSON.stringify({
        type: report,
        startAt: makeStartAt(),
        endAt: makeEndAt(),
        reason,
        reportedAt: makeDateToString(new Date()),
      }),
    },
    onError: (e) => {
      setLoading(false);
      let msg = e.message
      if(e.message.includes("teacher_id"))
        msg = "시스템에서 담임선생님이 지정되어 있지 않습니다."
      setErrorMessage(msg);
    },
    onCompleted: (res) => {
      if (res?.createOuting?.id) {
        setSuccess(res?.createOuting?.id);
      } else {
        history.push('/student/outing');
      }
      setLoading(false);
    },
  });

  const [updateOuting] = useMutation(UPDATE_OUTING, {
    variables: {
      data: JSON.stringify({
        type: report,
        startAt: makeStartAt(),
        endAt: makeEndAt(),
        reason,
      }),
      outingId: outingData?.id,
    },
    onError: (e) => {
      setLoading(false);
      setErrorMessage(e.message);
    },
    onCompleted: () => {
      setLoading(false);
      goDetail();
    },
  });

  const studentGroup = data?.me?.klassGroup;
  const startDateTime = new Date();
  startDateTime.setHours(startHour);
  startDateTime.setMinutes(startMinute);
  startDateTime.setSeconds(0);

  const endDateTime = new Date();
  endDateTime.setHours(endHour);
  endDateTime.setMinutes(endMinute);
  endDateTime.setSeconds(0);

  const buttonDisabled =
  !reason ||
  !report ||
  !studentName ||
  !startAt ||
  !agree ||
  startDateTime > endDateTime

  if (success) {
    return (
      <>
        <TopNavbar title="확인증 작성" left={<BackButton />} />
        <div className="w-full h-screen flex flex-col items-center justify-center text-center">
          <div className="text-gray-600 font-bold text-xl">
            확인증 제출 완료
          </div>
          <div className="text-gray-400 mt-4">
            확인증 제출이 완료되었습니다. <br />
            담임 선생님의 서명 후 승인이 완료됩니다.
          </div>
          <BottomFixedView tw={{ bottom: 'bottom-16', paddingX: 'px-5' }}>
            <Button
              onClick={() => history.push(`/student/outing/${success}`)}
              tw={{ width: 'w-full' }}
            >
              확인하기
            </Button>
          </BottomFixedView>
        </div>
      </>
    );
  }

  return (
    <>
      {loading && <Blank />}
      {isLoading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar
        title="확인증 신청"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <div className="w-full">
          <TextField
            label="학생 이름(본인)"
            id="studentName"
            value={data?.me?.name}
            tw={{ backgroundColor: 'bg-gray-100' }}
            readOnly
          />
        </div>
        <div className="w-full">
          <TextField
            label="학생 학년/반/번호"
            value={
              studentGroup?.group?.name +
              ' / ' +
              studentGroup?.studentNumber +
              '번'
            }
            tw={{ backgroundColor: 'bg-gray-100' }}
            readOnly
          />
        </div>
        <div className="w-full">
          <SelectValues
            label="*유형"
            placeholder="신청 유형을 선택해주세요."
            selectValues={reportType}
            value={report}
            onChange={(group: any) => {
              setReport(group);
              if (group === '외출') {
                setStartHour(12);
                setStartMinute(50);
                setEndHour(13);
                setEndMinute(50);
              }
            }}
          />
        </div>
        <div className="w-full pb-6">
          <label className="mb-1 text-sm text-gray-800">*기간</label>
          <div className="space-y-3 pb-6">
            <div>
            <FieldtripDatePicker
                selectedDate={new Date(startAt)}
                excludeDates= {[new Date(1970,1,1)]}
                placeholderText="시작 날짜"
                onChange={(selectedDate) => {
                  if (!selectedDate) {
                    return;
                  }
                  if (selectedDate > new Date(endAt)) {
                    setEndAt(makeDateToString(selectedDate));
                  }
                  if (selectedDate.getDate === new Date(endAt).getDate) {
                    if (startHour > endHour) {
                      setEndHour(startHour);
                      if (startMinute > endMinute) {
                        setEndMinute(startMinute);
                      }
                    }
                    if (startHour === endHour && startMinute > endMinute) {
                      setEndMinute(startMinute);
                    }
                  }
                  setStartAt(makeDateToString(selectedDate))
                }}
              />
            </div>
            <div className="flex items-center space-x-2">
              <select
                value={startHour}
                onChange={(e) => {
                  const _startHour = Number(e.target.value);
                  if(report !== "확인" || startAt === endAt){
                    if (_startHour > endHour) {
                      setEndHour(_startHour);
                    }
                    if (_startHour === endHour && startMinute > endMinute) {
                      setEndMinute(startMinute);
                    }
                  }
                  setStartHour(_startHour);

                }}
                className="px-4 w-16 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
              >
                {new Array(24).fill(null).map((item: any, num: number) => (
                  <option value={num}>{num}</option>
                ))}
              </select>
              <span>시</span>
              <select
                className="px-4 w-16 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                onChange={(e) => {
                  const _startMinute = Number(e.target.value);
                  if(report !== "확인" || startAt === endAt){
                    if (startHour === endHour && _startMinute > endMinute) {
                      setEndMinute(_startMinute);
                    }
                  }
                  setStartMinute(_startMinute);
                }}
                value={startMinute}
              >
                <option value={0}>0</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
              </select>
              <span>분 부터</span>
            </div>
            {report === '확인' && (
              <div>
                <FieldtripDatePicker
                selectedDate={new Date(endAt)}
                excludeDates= {[new Date(1970,1,1)]}
                placeholderText="종료 날짜"
                onChange={(selectedDate) => {
                  if (!selectedDate) {
                    return;
                  }
                  if (new Date(startAt) > selectedDate) {
                    setStartAt(makeDateToString(selectedDate));
                  }
                  if (selectedDate.getDate === new Date(startAt).getDate) {
                    if (startHour > endHour) {
                      setStartHour(endHour);
                      if (startMinute > endMinute) {
                        setStartMinute(endMinute);
                      }
                    }
                    if (startHour === endHour && startMinute > endMinute) {
                      setStartMinute(endMinute);
                    }
                  }
                  setEndAt(makeDateToString(selectedDate))
                }}
              />
              </div>
            )}
            <div className="flex items-center space-x-2">
              <select
                value={endHour}
                onChange={(e) => {
                  const _endHour = Number(e.target.value);
                  if(report !== "확인" || startAt === endAt){
                    if (startHour > _endHour) {
                      setStartHour(_endHour);
                    }
                    if (startHour === _endHour && startMinute > endMinute) {
                      setEndMinute(startMinute);
                    }
                  }
                  setEndHour(_endHour);
                }}
                className="px-4 w-16 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
              >
                {new Array(24).fill(null).map((item: any, num: number) => (
                  <option value={num}>{num}</option>
                ))}
              </select>
              <span>시</span>
              <select
                className="px-4 w-16 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                onChange={(e) => {
                  const _endMinute = Number(e.target.value);
                  if(report !== "확인" || startAt === endAt){
                    if (startHour === endHour && startMinute > _endMinute) {
                      setStartMinute(_endMinute);
                    }
                  }
                  setEndMinute(_endMinute);
                }}
                value={endMinute}
              >
                <option value={0}>0</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
              </select>
              <span>분 까지</span>
            </div>
          </div>
          <div className="w-full">
            <TextField
              label="*사유"
              id="reason"
              placeholder="조퇴/외출/확인 사유를 자세하게 입력해주세요."
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div className="text-red-600 text-sm whitespace-pre-line mb-2">
            *민감정보의 수집/이용/제3자 제공에 동의
          </div>
          <div className="border border-gray-300 rounded-lg px-4 py-3 whitespace-pre-line">
            진료 확인서 등 건강 관련 민감 정보는 소속 학교에 제공되어 출결 관리
            목적으로만 사용됩니다.
          </div>
        </div>
        <div className="flex items-center space-x-2 mb-1">
          <Checkbox
            id="agree"
            onChange={() => setAgree(!agree)}
            checked={agree}
          />
          <label htmlFor="agree">
            <span className="font-semibold text-lg cursor-pointer">
              동의하기
              <span className="text-red-600 text-sm whitespace-pre-line mb-2">
                &nbsp; (체크하지 않으면 다음단계로 넘어가지 않습니다.)
              </span>
            </span>
          </label>
        </div>
        <Button
          disabled={buttonDisabled}
          tw={{
            backgroundColor:
              !reason || !report || !studentName || !startAt || !agree
                ? 'bg-gray-300'
                : 'bg-brand-1',
          }}
          onClick={() => {
            setLoading(true);
            outingData ? updateOuting() : createOuting();
          }}
        >
          제출하기
        </Button>

        {errorMessage && <div className="text-red-600">{errorMessage}</div>}
      </Section>
    </>
  );
};
