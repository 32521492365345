import {
  BackButton,
  BottomFixedView,
  Button,
  Section,
  TopNavbar,
} from '../../../reusable';
import React, { FC, useRef } from 'react';
import { useHistory } from 'react-router-dom';


interface FindIdSuccessPageProps {
  phone: string;
}

export const FindIdSuccessPage: FC<FindIdSuccessPageProps> = ({
  phone,
}) => {
  const history = useHistory();
  const ref = useRef<HTMLInputElement>(null);

  return (
    <>
      <TopNavbar
        title="아이디 찾기"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      {
          <Section>
            <h1 className="text-2xl font-bold">아이디 전송 성공</h1>

            <div className="w-full text-xl">
              &nbsp;등록된 휴대전화로({phone}) 아이디를 보냈습니다.
              <br />
              &nbsp;카카오톡을 확인해주세요.
            </div>
            <div className="w-full text-red-500">
              * 카카오톡으로 아이디를 받지 못 한 경우 슈퍼스쿨에 문의해주세요.
            </div>
          </Section>
      }

      <BottomFixedView>
        <Section>
          <Button onClick={() => history.push('/login')}>로그인하기</Button>
        </Section>
      </BottomFixedView>
    </>
  );
};
