import { ListItem, utcToLocalDatetime } from '../../reusable';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Activity } from '../../types';

interface ActivityItemProps {
  id: number;
  activity: Activity;
  subject?: string;
  isSubmitted: Boolean;
}

export const ActivityItem: FC<ActivityItemProps> = ({
  id,
  activity,
  subject,
  isSubmitted,
}) => {
  // console.log(activity);
  const history = useHistory();
  return (
    <ListItem onClick={() => history.push(`/student/activity/${id}`)}>
      <div className="flex justify-between items-center">
        <div className="text-left flex flex-col space-y-1">
          {subject && (
            <div className="rounded-md px-3 py-1 bg-brand-5 text-sm text-brand-1 w-max">
              {subject}
            </div>
          )}
          <div className="text-lg font-semibold text-grey-1">
            {activity.title}
          </div>
          {/* <div className="text-grey-3 text-sm">
            {utcToLocalDatetime(activity?.createdAt?.toString() || '')}
          </div> */}
          {activity?.endDate && (
            <div className="text-grey-3 text-sm">
              {utcToLocalDatetime(activity.endDate)}
              까지
            </div>
          )}
        </div>
        {activity?.type !== 'NOTICE' && (
          <div
            className={
              isSubmitted
                ? 'ml-2 px-3 py-2 bg-brand-1 text-white text-center rounded-md min-w-max text-sm'
                : 'ml-2 px-3 py-2 bg-grey-4 text-white text-center rounded-md min-w-max text-sm'
            }
          >
            {isSubmitted ? '제출 완료' : '미제출'}
          </div>
        )}

        {/* <Button
          height="h-11"
          disabled={true}
          theme={isSubmitted ? 'brand-1' : 'gray-800'}
          style={{ opacity: isSubmitted ? '1' : '0.5' }}
        >
          {isSubmitted ? '제출 완료' : '미제출'}
        </Button> */}
      </div>
    </ListItem>
  );
};
