import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery as uQ } from 'react-query';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import {
  Blank,
  Button,
  Checkbox,
  Label,
  Section,
  Select,
  TextArea,
  TextField,
  useQuery,
} from '../../../reusable';
import {
  ErrorBlank,
  FileItem,
  BoardFileItem,
  AllSelectCheckbox,
} from '../../../components';
import {
  Board,
  Group,
  GroupBoard,
  TeacherGroup,
  UpdateBoardType,
} from '../../../types';
import { fetcher, api } from '../../../plugins';
import { GET_GROUPS } from '../../../query';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import undoArrow from '../../../assets/images/undo-arrow.png';

const categories = ['조종례', '알림', '오늘의 한마디', '자료', '기타'];

interface BoardEditProps {
  refetch: () => void;
}

export const BoardEditPage: React.FC<BoardEditProps> = ({ refetch }) => {
  const { id } = useParams<{ id?: string }>();

  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [images, setImages] = useState<(File | null | undefined)[]>([null]);
  const [files, setFiles] = useState<(File | null | undefined)[]>([]);
  const [willRemoveImages, setWillRemoveImages] = useState<any[]>([]);
  const [willRemoveFiles, setWillRemoveFiles] = useState<any[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);

  const { data } = useQuery(GET_GROUPS, {
    fetchPolicy: 'cache-and-network',
  });

  const groups = data?.me?.teacherGroups
    .map((tg: TeacherGroup) => tg?.group)
    .slice()
    .filter(
      (g: Group, i: number, groups: Group[]) =>
        !!g &&
        g?.type === 'KLASS' &&
        groups.filter((gp: Group) => !!gp && gp?.name === g?.name)[0].id ===
          g.id,
    )
    .filter(
      (g: Group, i: number, groups: Group[]) =>
        !!g && groups.map((el: Group) => el.id).indexOf(g.id) === i,
    )
    .sort((a: any, b: any) => a.id - b.id);

  const {
    data: board,
    error,
    isLoading: loading,
  } = uQ(`/boards/${id}`, fetcher, { enabled: !!id });

  const buttonDisabled =
    title.length === 0 || content.length === 0 || !category;

  useEffect(() => {
    if (board) {
      if (!title) setTitle(board.title || '');
      if (!content) setContent(board.content || '');
      if (!category) setCategory(board.category || '');
      if (!selectedGroups.length) {
        setSelectedGroups(board.groupBoards?.map((el: GroupBoard) => el.group));
      }
    }
  }, [board]);

  let firstGroup: Group[] = [];
  let secondGroup: Group[] = [];
  let thirdGroup: Group[] = [];
  let restGroup: Group[] = [];

  groups
    ?.filter((el: any) => !!el)
    ?.map((el: Group) => {
      if (el.name?.includes('1학년')) {
        firstGroup.push(el);
      } else if (el.name?.includes('2학년')) {
        secondGroup.push(el);
      } else if (el.name?.includes('3학년')) {
        thirdGroup.push(el);
      } else {
        restGroup.push(el);
      }
      return el;
    });

  const selectedGroupIds = selectedGroups?.map((el) => el.id) || [];

  const { mutate } = useMutation(
    (board: UpdateBoardType) => api.patch<Board>(`boards/${id}`, board),
    {
      onSuccess: (res) => {
        setLoading(false);
        refetch && refetch();
        history.push(`/teacher/board/${res.data.id}`);
      },
      onError: (err) => {
        setErrorText(String(err));
        setLoading(false);
      },
    },
  );

  const updateBoard = async () => {
    const _images: string[] = [];
    const _files: string[] = [];

    for (let image of images) {
      if (image instanceof File) {
        const formData = new FormData();
        formData.append('file', image);
        await api
          .post('files', formData)
          .then((res) => {
            _images.push(res.data);
          })
          .catch((err) => console.error(err));
      }
    }

    for (let file of files) {
      if (file instanceof File) {
        const formData = new FormData();
        formData.append('file', file);
        await api
          .post('files', formData)
          .then((res) => {
            _files.push(res.data);
          })
          .catch((err) => console.error(err));
      }
    }

    const targetGroupIds: any[] = selectedGroupIds.filter((el) => !!el);

    mutate({
      title: title,
      content: content,
      category,
      targetGroupIds,
      files: _files,
      images: _images,
      willRemoveImages,
      willRemoveFiles,
    });
  };

  console.log('board', board);

  return (
    <div className="md:p-6">
      {isLoading && <Blank />}
      {errorText && <ErrorBlank text={errorText} />}
      {loading && <Blank reversed text="게시글을 불러오는 중입니다." />}
      {error && <ErrorBlank text="해당 게시글을 불러오지 못했습니다." />}

      <Section>
        <div className="bg-white p-4 border rounded-md mt-3">
          <span>전달 대상 선택</span>
          <div className="flex w-full justify-between mt-1">
            <div>
              <div className="font-bold">
                {firstGroup.length && (
                  <AllSelectCheckbox
                    groups={firstGroup}
                    selectedGroups={selectedGroups}
                    setSelectedGroups={(groups: Group[]) =>
                      setSelectedGroups(groups)
                    }
                    label="1학년 전체"
                  />
                )}
              </div>
              {firstGroup.map((el: Group) => (
                <Checkbox
                  label={el.name}
                  checked={selectedGroupIds.includes(el.id)}
                  onChange={() =>
                    selectedGroupIds.includes(el.id)
                      ? setSelectedGroups(
                          selectedGroups.filter((g) => g.id !== el.id),
                        )
                      : setSelectedGroups(selectedGroups.concat(el))
                  }
                />
              ))}
            </div>
            <div>
              <div className="font-bold">
                {secondGroup.length && (
                  <AllSelectCheckbox
                    groups={secondGroup}
                    selectedGroups={selectedGroups}
                    setSelectedGroups={(groups: Group[]) =>
                      setSelectedGroups(groups)
                    }
                    label="2학년 전체"
                  />
                )}
              </div>
              {secondGroup.map((el) => (
                <Checkbox
                  label={el.name}
                  checked={selectedGroupIds.includes(el.id)}
                  onChange={() =>
                    selectedGroupIds.includes(el.id)
                      ? setSelectedGroups(
                          selectedGroups.filter((g) => g.id !== el.id),
                        )
                      : setSelectedGroups(selectedGroups.concat(el))
                  }
                />
              ))}
            </div>
            <div>
              <div className="font-bold">
                {thirdGroup.length && (
                  <AllSelectCheckbox
                    groups={thirdGroup}
                    selectedGroups={selectedGroups}
                    setSelectedGroups={(groups: Group[]) =>
                      setSelectedGroups(groups)
                    }
                    label="3학년 전체"
                  />
                )}
              </div>
              {thirdGroup.map((el) => (
                <Checkbox
                  label={el.name}
                  checked={selectedGroupIds.includes(el.id)}
                  onChange={() =>
                    selectedGroupIds.includes(el.id)
                      ? setSelectedGroups(
                          selectedGroups.filter((g) => g.id !== el.id),
                        )
                      : setSelectedGroups(selectedGroups.concat(el))
                  }
                />
              ))}
            </div>
          </div>
          <div>
            {restGroup.map((el) => (
              <Checkbox
                label={el.name}
                checked={selectedGroupIds.includes(el.id)}
                onChange={() =>
                  selectedGroupIds.includes(el.id)
                    ? setSelectedGroups(
                        selectedGroups.filter((g) => g.id !== el.id),
                      )
                    : setSelectedGroups(selectedGroups.concat(el))
                }
              />
            ))}
          </div>
        </div>
        <div>
          <TextField
            label="제목"
            id="title"
            placeholder="제목을 입력해주세요"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </div>

        <div>
          <TextArea
            label="내용"
            placeholder="내용을 입력해주세요."
            value={content}
            onChange={(e) => setContent(e.target.value)}
            style={{ borderWidth: '1px' }}
          />
        </div>

        <div>
          <Select
            label="구분"
            id="category"
            onChange={(e) => setCategory(e.target.value)}
            value={category}
          >
            <option selected hidden>
              구분을 선택해주세요
            </option>
            {categories.map((el) => (
              <option id={el} value={el} key={el}>
                {el}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <Label>이미지</Label>
          <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
            {board?.images?.map((image: any) => {
              return (
                <div>
                  <div
                    className={`relative pb-3/5 rounded border-2 border-dashed border-grey-5 ${
                      willRemoveImages.includes(image) ? 'opacity-50' : ''
                    }`}
                  >
                    <span className="absolute -top-3 -right-3 block w-6 h-6 rounded-full ring-2 ring-white bg-red-700 z-40">
                      <div
                        className="w-full h-full flex items-center justify-center text-white cursor-pointer"
                        onClick={() =>
                          willRemoveImages.includes(image)
                            ? setWillRemoveImages(
                                willRemoveImages.filter(
                                  (imgUrl) => imgUrl !== image,
                                ),
                              )
                            : setWillRemoveImages(
                                willRemoveImages.concat(image),
                              )
                        }
                        style={{ transform: 'translate(0.1px, 0.1px)' }}
                      >
                        {willRemoveImages.includes(image) ? (
                          <img src={undoArrow} alt="" className="w-4 h-4" />
                        ) : (
                          'X'
                        )}
                      </div>
                    </span>
                    <ExifOrientationImg
                      className="absolute w-full h-full rounded object-cover"
                      src={
                        process.env.REACT_APP_API_URL + '/images?url=' + image
                      }
                      alt=""
                    />
                    {willRemoveImages.includes(image) && (
                      <div className="absolute w-full h-full z-20">
                        <div className="flex w-full h-full items-center justify-center">
                          <div className="text-4xl text-gray-800 opacity-100">
                            삭제
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            {images?.map((el: File | null | undefined, i: number) => (
              <div className="">
                <label htmlFor={`imageupload${i}`}>
                  <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
                    {el ? (
                      <img
                        className="absolute w-full h-full rounded object-cover"
                        src={URL.createObjectURL(el)}
                        alt=""
                      />
                    ) : (
                      <div className="absolute w-full h-full rounded object-cover bg-white">
                        <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                          <FileUploadImg />
                          <div className="text-brand-1">
                            이미지를 업로드해주세요!
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  id={`imageupload${i}`}
                  className="hidden"
                  accept=".png, .jpeg, .jpg"
                  onChange={(e) => {
                    let size = 0;

                    images.map((el: File | string | null | undefined) => {
                      if (el instanceof File) {
                        size += el.size || 0;
                      }
                    });

                    files.map((el: File | null | undefined) => {
                      size += el?.size || 0;
                    });

                    if (size >= 20 * 1024 * 1024) {
                      alert(
                        '한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요!',
                      );
                    } else {
                      const newImages = images.slice();
                      if (e.target.files && e.target.files?.item(0)) {
                        newImages[i] = e.target.files?.item(0);
                        if (newImages[-1] !== null && !!el) {
                          newImages.push(null);
                        }
                      }
                      e.target.validity.valid && setImages(newImages);
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div>
          <Label>파일</Label>
          <div className="bg-white rounded border-2 border-dashed border-grey-5">
            {board?.files?.map((key: string) => {
              let data = {
                name: key.split('/').pop()?.slice(14),
                data: key,
                url: process.env.REACT_APP_API_URL + '/images?url=' + key,
              };
              return (
                <BoardFileItem
                  file={data}
                  canClose
                  willRemoveFiles={willRemoveFiles}
                  setWillRemoveFiles={() =>
                    willRemoveFiles.includes(data.data)
                      ? setWillRemoveFiles(
                          willRemoveFiles.filter((key) => key !== data.data),
                        )
                      : setWillRemoveFiles(willRemoveFiles.concat(data.data))
                  }
                />
              );
            })}
            {files.map((file: any) => (
              <FileItem file={file} />
            ))}
            <label htmlFor="fileupload">
              <div className="w-full flex space-x-1 justify-center items-center pt-0.5 pb-2.5 cursor-pointer">
                <span className="text-2xl text-grey-3 mb-1">+</span>
                <div className="text-brand-1">
                  파일을 업로드해주세요 (다중 업로드 가능)
                </div>
              </div>
            </label>
          </div>
          <input
            type="file"
            id="fileupload"
            className="hidden"
            multiple
            onChange={(e) => {
              const newFiles = e.target.files
                ? files.concat(Array.from(e.target.files))
                : files;

              let size = 0;

              images.map((el: File | string | null | undefined) => {
                if (el instanceof File) {
                  size += el?.size || 0;
                }
              });

              newFiles.map((el: File | null | undefined) => {
                size += el?.size || 0;
              });

              if (size >= 20 * 1024 * 1024) {
                alert(
                  '한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요!',
                );
              } else {
                e.target.validity.valid && setFiles(newFiles);
              }
            }}
          />
        </div>
      </Section>
      <div className="text-center mt-6">
        <Button
          text="등록하기"
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
            height: 'h-11',
          }}
          style={{ width: '70%' }}
          disabled={buttonDisabled}
          onClick={() => {
            setLoading(true);
            updateBoard();
          }}
        />
      </div>
    </div>
  );
};
