import React, { FC } from 'react';
import { NewsletterTeacherItem } from '../../../components';
import { GET_NEWSLETTERS } from '../../../query';
import { List, useQuery } from '../../../reusable';
import { Newsletter } from '../../../types';

interface NewsletterMobilePageProps {}

export const NewsletterMobilePage: FC<NewsletterMobilePageProps> = ({}) => {
  let { data: newsletters }: any = useQuery(GET_NEWSLETTERS);
  return (
    <>
      <div className="w-full flex-col space-y-2 h-screen-9 overflow-y-auto scroll-box">
        <div className="whitespace-pre-line">
          <List>
            {newsletters?.newsletters?.map((newsletter: Newsletter) => (
              <NewsletterTeacherItem newsletter={newsletter} />
            ))}
          </List>
        </div>
        <div className="w-full h-32" />
      </div>
    </>
  );
};
