import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import {
  Blank,
  Button,
  Checkbox,
  LoadingScreen,
  Section,
  Select,
  TextArea,
  TextField,
  useMutation,
} from '../../../reusable';
import {
  Group,
  StudentActivity,
  StudentGroup,
  Summary,
  User,
} from '../../../types';
import {
  RecordCard,
  ErrorBlank,
  SummaryItem,
  SuperModal,
} from '../../../components';
import { UPDATE_RECORD_SUMMARY } from '../../../query';
import { forbiddenWords } from '../ForbiddenWords';
import { fetcher } from '../../../plugins';

const getRecordSummaryLength = (text: string) => {
  const hangulLength = text.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g)?.length || 0;
  const enterLength = text.match(/(\r)|(\n)/g)?.length || 0;
  const etcLength = text.length - hangulLength - enterLength;
  return hangulLength * 3 + enterLength * 2 + etcLength;
};

interface MatchParams {
  id: string;
}

interface RecordDetailPageProps extends RouteComponentProps<MatchParams> {
  studentGroups: StudentGroup[];
  selectedGroup: Group;
  refetch: () => void;
  me: User;
  subject: string;
}

const RecordDetailPage: React.FC<RecordDetailPageProps> = ({
  match,
  studentGroups,
  selectedGroup,
  refetch,
  me,
  subject,
}) => {
  const { id } = match.params;

  const selectedStudentGroup = studentGroups?.filter(
    (sg: StudentGroup) => sg.user?.id === Number(id),
  )[0];
  const selectedUser = selectedStudentGroup?.user;

  const { data: student, refetch: _refetch } = uQ(
    `/life_record/student/${id}?groupId=${selectedStudentGroup?.groupId}`,
    fetcher,
    {
      enabled: !!id && !!selectedStudentGroup?.groupId,
      keepPreviousData: true,
    },
  );

  const [subjectFilter, setSubjectFilter] = useState<string>('');

  const studentActivities = student?.studentActivities;

  const subjects = studentActivities
    ?.map((sa: StudentActivity) => sa.activity?.subject)
    .filter(function (item: string, index: number, self: string[]) {
      return !!item && self.indexOf(item) === index;
    });

  const [recordSummary, setRecordSummary] = useState('');
  const [selectedSAIds, setSelectedSAIds] = useState<number[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [selectedSubject, setSelectedSubject] = useState<string>('');
  const [modalOpen, setModalOpen] = useState(false);
  const [forbiddenText, setForbiddenText] = useState('');
  // const summaryText = useDebounce({ value: recordSummary, delay: 100 });

  const [updateRecordSummary] = useMutation(UPDATE_RECORD_SUMMARY, {
    variables: {
      id: selectedStudentGroup?.id,
      recordSummary,
      subject: selectedSubject,
    },
    onCompleted: () => {
      setLoading(false);
      setErrorText('');
      _refetch();
    },
    onError: (err) => {
      setLoading(false);
      setErrorText(err.message);
    },
  });

  useEffect(() => {
    if (!recordSummary) {
      const record = localStorage.getItem('recordSummary') || '';
      setRecordSummary(record);
    }
  }, [localStorage, recordSummary]);

  if (!student) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div className="col-span-4 grid grid-cols-4">
        {isLoading && <Blank />}
        {errorText && <ErrorBlank text={errorText} />}
        <div className="col-span-2 h-screen">
          <Section>
            <div className="flex items-center justify-between mr-7">
              <h1 className="text-xl font-semibold">
                {selectedGroup?.name} {selectedStudentGroup?.studentNumber}번{' '}
                {selectedUser?.name}
              </h1>
              <Checkbox
                className="w-6 h-6 -mr-0.5"
                checked={selectedSAIds.length === studentActivities?.length}
                onChange={() => {
                  if (selectedSAIds.length === studentActivities?.length) {
                    setSelectedSAIds([]);
                  } else {
                    setSelectedSAIds(
                      studentActivities?.map((el: StudentActivity) => el.id) ||
                        [],
                    );
                  }
                }}
              />
            </div>
            <div className="text-gray-500 text-base">
              희망진로 :{' '}
              <span className="text-gray-800">{selectedUser?.hopePath}</span> /
              희망학과 :{' '}
              <span className="text-gray-800">{selectedUser?.hopeMajor}</span>
            </div>
            <Select
              onChange={(e) => {
                setSubjectFilter(e.target.value);
                setSelectedSubject(e.target.value);
              }}
              value={subjectFilter}
            >
              <option selected hidden>
                과목
              </option>
              {subjects?.map((subject: string) => (
                <option value={subject} key={subject}>
                  {subject}
                </option>
              ))}
            </Select>
            <div className="h-0.5 bg-gray-100"></div>
            {studentActivities
              ?.slice()
              ?.filter((sa: StudentActivity) =>
                subjectFilter ? sa?.activity?.subject === subjectFilter : true,
              )
              ?.sort(
                (a: StudentActivity, b: StudentActivity) =>
                  new Date(a.activity?.createdAt || '').getTime() -
                  new Date(b.activity?.createdAt || '').getTime(),
              )
              ?.map((record: StudentActivity) => (
                <RecordCard
                  record={record}
                  refetch={() => _refetch()}
                  selectedSAIds={selectedSAIds}
                  setSelectedSAIds={(ids: number[]) => setSelectedSAIds(ids)}
                />
              ))}
          </Section>
        </div>

        <div className="col-span-2 h-screen overflow-h-scroll scroll-box">
          <Section tw={{ marginTop: 'mt-6' }}>
            <div className="w-full flex justify-between items-center">
              <div className="font-bold text-lg">활동기록 총정리</div>
              <div>
                {recordSummary.length}자 {getRecordSummaryLength(recordSummary)}
                Bytes
              </div>
            </div>
            <div className="border-gray-200 border-2 rounded-lg overflow-hidden">
              <TextArea
                tw={{ height: 'h-80' }}
                value={recordSummary}
                onChange={(e) => {
                  setRecordSummary(e.target.value);
                  localStorage.setItem('recordSummary', e.target.value);
                }}
              />
            </div>
            <TextField
              onChange={(e) => setSelectedSubject(e.target.value)}
              value={selectedSubject}
            />

            <div className="w-full flex items-center justify-end space-x-2">
              <Button
                text="불러오기"
                onClick={() => {
                  let recordText = '';
                  studentActivities
                    ?.slice()
                    ?.filter(
                      (sa: StudentActivity) =>
                        sa.id &&
                        selectedSAIds.includes(sa.id) &&
                        (!!sa.summary || !!sa.feedback),
                    )
                    ?.filter((sa: StudentActivity) =>
                      subjectFilter
                        ? sa?.activity?.subject === subjectFilter
                        : true,
                    )
                    ?.reverse()
                    ?.map((sa: StudentActivity) => {
                      if (sa.activity?.commonText) {
                        recordText += sa.activity.commonText + '\n';
                      }
                      if (sa.summary) {
                        recordText += sa.summary + '\n';
                      }
                      if (sa.feedback) {
                        recordText += sa.feedback + '\n';
                      }
                      recordText += '\n';
                    });
                  setRecordSummary(recordText);
                  localStorage.setItem('recordSummary', recordText);
                }}
              />
              <Button
                text="저장하기"
                onClick={() => {
                  if (!selectedSubject) {
                    alert('과목을 선택해주세요.');
                    return;
                  }

                  const match = recordSummary.match(
                    new RegExp(forbiddenWords.join('|'), 'g'),
                  );

                  if (match?.length) {
                    setModalOpen(true);
                    setForbiddenText(match.join(', '));
                  } else {
                    setLoading(true);
                    updateRecordSummary();
                    setForbiddenText('');
                  }
                }}
              />
            </div>
          </Section>
          <div className="w-full h-0 border-gray-100 border my-6" />
          <Section>
            {student?.studentGroups &&
              student.studentGroups[0]?.summaries
                ?.slice()
                .sort(
                  (a: Summary, b: Summary) =>
                    new Date(b?.createdAt || '').getTime() -
                    new Date(a?.createdAt || '').getTime(),
                )
                .map((summary: Summary) => (
                  <SummaryItem
                    summary={summary}
                    isUserOwn={summary.userId === me.id}
                    refetch={() => {
                      refetch();
                      _refetch();
                    }}
                  />
                ))}
          </Section>
        </div>
      </div>
      <SuperModal
        modalOpen={modalOpen}
        setModalClose={() => setModalOpen(false)}
        width="w-max"
      >
        <div className="px-12 py-6">
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            학생부 기재 유의어가 포함되어있습니다. <br />
            {forbiddenText} <br />
            <br />
            기재 내용을 다시 한번 확인해 주시기 바랍니다. 유의어를 포함하여
            저장하시겠습니까?
          </div>
          <Button
            tw={{ width: 'w-full' }}
            onClick={() => {
              setLoading(true);
              updateRecordSummary();
              setForbiddenText('');
              setModalOpen(false);
            }}
          >
            저장하기
          </Button>
        </div>
      </SuperModal>
    </>
  );
};

export default withRouter(RecordDetailPage);
