import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Badge,
  Blank,
  useQuery,
  Select,
  useQueryBasic,
} from '../../../reusable';
import { useQuery as uQ, useMutation } from 'react-query';
import { fetcher, api } from '../../../plugins';
import { GET_TIMETABLE_V2_BY_YEAR_SEMESTER_TEACHER } from '../../../query';
import { ErrorBlank } from '../../../components';
import { KlassInfo, Group } from '../../../types';

interface TimetableDetailV2PageProps {
  year: number;
  semester: number;
  setKlassInfo: (info: KlassInfo) => void;
}

export const TimetableDetailPageV2: FC<TimetableDetailV2PageProps> = ({
  year,
  semester,
  setKlassInfo,
}) => {
  const [groupId, setGroupId] = useState<number | undefined>(-1);
  const [teacherId, setTeacherId] = useState<number | undefined>(-1);
  const [teacherTableTime, setTeacherTableTime] = useState(false); // false : 교실보기, true : 과목보기

  const { loading, error, data, refetch } = useQuery(
    GET_TIMETABLE_V2_BY_YEAR_SEMESTER_TEACHER,
    {
      variables: { year, semester, groupId },
      errorPolicy: 'ignore',
      onError: (e) => {},
      skip:
        typeof year !== 'number' ||
        typeof semester !== 'number' ||
        typeof groupId !== 'number',
    },
  );

  const queryString = useQueryBasic({
    year: year,
    semester: semester,
  });
  //const { error, data } = uQ(`/attendance/students${queryString}`, fetcher);
  const {
    error: tterror,
    data: timetables,
    refetch: ttrefetch,
  } = uQ(`/timetable/allteacher${queryString}`, fetcher, {
    refetchOnWindowFocus: false,
  });

  const groups = data?.klassGroups?.slice().sort((a: any, b: any) => {
    const aData = a?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);
    const bData = b?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);

    if (!aData || !bData) {
      return 0;
    }

    if (aData[1] && bData[1]) {
      if (aData[1] === bData[1]) {
        return Number(aData[2]) - Number(bData[2]);
      } else {
        return Number(aData[1]) - Number(bData[1]);
      }
    } else {
      return 0;
    }
  });

  if (data && data?.timetablev2ByYearSemesterGroup?.groupId !== groupId) {
    setGroupId(data?.timetablev2ByYearSemesterGroup?.groupId);
    setTeacherId(data?.me?.id);
  }

  const teachers = timetables?.TimetableOfTeachers?.slice().sort(
    (a: any, b: any) => {
      const aData = a?.name;
      const bData = b?.name;

      if (!aData || !bData) {
        return 0;
      }

      if (aData < bData) {
        return -1;
      }
    },
  );

  const week = [
    'monday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'monday',
  ];
  const dayOfWeek = week[new Date().getDay()];

  const timetable = data?.timetablev2ByYearSemesterGroup;
  const timetableMove = data?.timetablev2teacher;

  const [selectedMyClass, setSelectedMyClass] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');

  let myKlassContent: any = null;
  let teacherContent: any = null;

  try {
    if (teacherId === 0) {
      teacherContent = null;
    } else if (teacherId && teacherId > 0) {
      let cc = teachers?.find((teacher: any) => teacher.id === teacherId);

      teacherContent = JSON.parse(cc?.content);
    } else {
      teacherContent = JSON.parse(timetableMove?.content);
    }
  } catch (err) {}

  try {
    myKlassContent = JSON.parse(timetable?.content);
  } catch (err) {}

  let content: any = null;
  let contentRow: number;

  if (selectedMyClass) {
    content = myKlassContent;
  } else {
    content = teacherContent;
  }

  contentRow = content?.length;

  //const day = new Date(2022, 9, 7).getDay();
  const day = new Date().getDay();

  // const history = useHistory();
  // const { pathname } = useLocation();

  // if (pathname === '/teacher/v2timetable/myclass' && !selectedMyClass) {
  //   setSelectedMyClass(true);
  // } else if (pathname === '/teacher/v2timetable' && selectedMyClass) {
  //   setSelectedMyClass(false);
  // }

  const timeTableClick = (
    klassTimeCode: string,
    klassTime: string,
    klassName: string,
    klassSubject: string,
    klassType: string,
  ) => {
    let tc = teachers?.find((teacher: any) => teacher.id === teacherId);

    setKlassInfo({
      timeCode: klassTimeCode,
      time: klassTime,
      name: klassName,
      myClass: selectedMyClass,
      subject: klassSubject,
      semester: semester,
      teacherName: tc?.name,
      type: klassType,
    });
    //console.log(selectedTime) ;
  };

  return (
    <div className="px-2 max-w-256">
      {loading && <Blank reversed />}
      {error && <ErrorBlank />}
      <div className="w-full flex items-center justify-center space-x-2 py-4">
        <Badge
          tw={{
            backgroundColor:
              selectedMyClass === false ? 'bg-black' : 'bg-white',
            color: selectedMyClass === false ? 'text-white' : 'text-black',
            paddingY: 'py-1.5',
          }}
          onClick={() => {
            //selectedMyClass && history.goBack();
            setSelectedMyClass(false);
            setKlassInfo({
              timeCode: dayOfWeek + '_0',
              time: '',
              name: '',
              myClass: false,
              subject: '',
              semester: semester,
              teacherName: '',
              type: '',
            });
            setSelectedTime('');
          }}
        >
          교사별
        </Badge>
        <Badge
          tw={{
            backgroundColor:
              selectedMyClass === false ? 'bg-white' : 'bg-black',
            color: selectedMyClass === false ? 'text-black' : 'text-white',
            paddingY: 'py-1.5',
          }}
          onClick={() => {
            //history.push(`/teacher/v2timetable/myclass`);
            setSelectedMyClass(true);
            setKlassInfo({
              timeCode: dayOfWeek + '_0',
              time: '',
              name: '',
              myClass: false,
              subject: '',
              semester: semester,
              teacherName: '',
              type: '',
            });
            setSelectedTime('');
          }}
        >
          학급별
        </Badge>
      </div>

      <table className="text-center w-full mx-auto">
        <thead>
          <tr>
            <td className="w-20">
              {selectedMyClass && (
                <Select
                  value={groupId}
                  onChange={(e) => setGroupId(Number(e.target.value))}
                  placeholder="반 선택"
                  tw={{ paddingX: 'text-center' }}
                >
                  <option value="0">선택</option>
                  {groups?.map((group: Group) => (
                    <option value={group.id}>
                      {group.name?.replace('학년 ', '-').replace('반', '')}
                    </option>
                  ))}
                </Select>
              )}

              {!selectedMyClass && (
                <Select
                  value={teacherId}
                  onChange={(e) => setTeacherId(Number(e.target.value))}
                  placeholder="선생님 선택"
                  tw={{ paddingX: 'text-center' }}
                >
                  <option value="0">선택</option>
                  {teachers?.map((teacher: Group) => (
                    <option value={teacher.id}>{teacher?.name}</option>
                  ))}
                </Select>
              )}
            </td>
            <td className="" />
            <td
              className={`min-w-max rounded-l-xl py-4 ${
                day === 1 ? 'bg-grey-1 bg-opacity-10' : 'bg-grey-9'
              }`}
            >
              월
            </td>
            <td
              className={`min-w-max ${
                day === 2 ? 'bg-brand-1 bg-opacity-20' : 'bg-grey-9'
              }`}
            >
              화
            </td>
            <td
              className={`min-w-max ${
                day === 3 ? 'bg-brand-1 bg-opacity-20' : 'bg-grey-9'
              }`}
            >
              수
            </td>
            <td
              className={`min-w-max ${
                day === 4 ? 'bg-brand-1 bg-opacity-20' : 'bg-grey-9'
              }`}
            >
              목
            </td>
            <td
              className={`min-w-max rounded-r-xl ${
                day === 5 ? 'bg-brand-1 bg-opacity-20' : 'bg-grey-9'
              }`}
            >
              금
            </td>
          </tr>
          <td className="pb-2"></td>
        </thead>
        <tbody>
          {selectedMyClass && (
            <>
              <tr>
                <td></td>
                <div className="px-0.5"></div>
                <td
                  className={`border border-white px-1 min-w-9.5 rounded-tl-xl rounded-bl-xl cursor-pointer ${
                    day === 1 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-2'
                  }
                  ${selectedTime === 'mon' ? 'text-red-500' : ''}
                  `}
                  onClick={() => {
                    timeTableClick(
                      'monday_0',
                      '월요일',
                      content[0].monday.class,
                      '조회',
                      '',
                    );
                    setSelectedTime('mon');
                  }}
                >
                  조회
                </td>
                <td
                  className={`border border-white px-1 min-w-9.5  cursor-pointer ${
                    day === 2 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-2'
                  } ${selectedTime === 'tue' ? 'text-red-500' : ''}`}
                  onClick={() => {
                    timeTableClick(
                      'tuesday_0',
                      '화요일',
                      content[0].tuesday.class,
                      '조회',
                      '',
                    );
                    setSelectedTime('tue');
                  }}
                >
                  조회
                </td>
                <td
                  className={`border border-white px-1 min-w-9.5  cursor-pointer ${
                    day === 3 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-2'
                  } ${selectedTime === 'wed' ? 'text-red-500' : ''}`}
                  onClick={() => {
                    timeTableClick(
                      'wednesday_0',
                      '수요일',
                      content[0].wednesday.class,
                      '조회',
                      '',
                    );
                    setSelectedTime('wed');
                  }}
                >
                  조회
                </td>
                <td
                  className={`border border-white  px-1  cursor-pointer ${
                    day === 4 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-2'
                  } 
                  ${selectedTime === 'thu' ? 'text-red-500' : ''}
                  `}
                  onClick={() => {
                    timeTableClick(
                      'thursday_0',
                      '목요일',
                      content[0].thursday.class,
                      '조회',
                      '',
                    );
                    setSelectedTime('thu');
                  }}
                >
                  조회
                </td>
                <td
                  className={`border border-white  px-1 min-w-9.5 rounded-tr-xl rounded-br-xl  cursor-pointer ${
                    day === 5 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-2'
                  } 
                  ${selectedTime === 'fri' ? 'text-red-500' : ''}`}
                  onClick={() => {
                    timeTableClick(
                      'friday_0',
                      '금요일',
                      content[0].friday.class,
                      '조회',
                      '',
                    );
                    setSelectedTime('fri');
                  }}
                >
                  조회
                </td>
              </tr>
              <tr>
                <td className="pb-2"></td>
              </tr>
            </>
          )}

          {content &&
            content?.map((classtime: any, i: number) => (
              <tr>
                <td
                  className={`py-2 min-w-9.5 px-1 min-h-10.5 bg-grey-9
                ${i === 0 ? 'rounded-t-xl' : ''} ${
                    i === contentRow - 1 ? 'rounded-b-xl' : ''
                  }`}
                >
                  {i + 1} {}
                </td>
                <div className="px-0.5"></div>
                <td
                  className={`text-xs md:text-base border border-grey-50 py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 1 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-0'
                  }
                  ${i === 0 ? 'rounded-tl-xl border-t-0 border-l-0' : ''} 
                  ${
                    selectedMyClass || classtime.monday.class
                      ? 'cursor-pointer'
                      : ''
                  } 
                  ${
                    i === contentRow - 1
                      ? 'rounded-bl-xl border-b-0 border-l-0'
                      : ''
                  }
                  ${selectedTime === 'mon' + i ? 'text-red-500' : ''}
                  `}
                  onClick={() => {
                    timeTableClick(
                      'monday_' + (i + 1),
                      '월요일 ' + (i + 1) + '교시',
                      classtime.monday.class,
                      classtime.monday.subject,
                      classtime.monday.type,
                    );
                    setSelectedTime('mon' + i);
                  }}
                >
                  {selectedMyClass
                    ? classtime.monday.type === 'move'
                      ? '분반'
                      : classtime.monday.subject
                    : teacherTableTime
                    ? classtime.monday.subject
                    : classtime.monday.class
                        .replace('학년', '-')
                        .replace('반', '')
                        .replace(' ', '')}
                </td>
                <td
                  className={`text-xs md:text-base border border-grey-50 py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 2 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-0'
                  } 
                  ${selectedTime === 'tue' + i ? 'text-red-500' : ''}
                  ${
                    selectedMyClass || classtime.tuesday.class
                      ? 'cursor-pointer'
                      : ''
                  } 
                  `}
                  onClick={() => {
                    timeTableClick(
                      'tuesday_' + (i + 1),
                      '화요일 ' + (i + 1) + '교시',
                      classtime.tuesday.class,
                      classtime.tuesday.subject,
                      classtime.tuesday.type,
                    );
                    setSelectedTime('tue' + i);
                  }}
                >
                  {selectedMyClass
                    ? classtime.tuesday.type === 'move'
                      ? '분반'
                      : classtime.tuesday.subject
                    : teacherTableTime
                    ? classtime.tuesday.subject
                    : classtime.tuesday.class
                        .replace('학년', '-')
                        .replace('반', '')
                        .replace(' ', '')}
                </td>
                <td
                  className={`text-xs md:text-base border border-grey-50 py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 3 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-0'
                  } ${selectedTime === 'wed' + i ? 'text-red-500' : ''}
                  ${
                    selectedMyClass || classtime.wednesday.class
                      ? 'cursor-pointer'
                      : ''
                  }`}
                  onClick={() => {
                    timeTableClick(
                      'wednesday_' + (i + 1),
                      '수요일 ' + (i + 1) + '교시',
                      classtime.wednesday.class,
                      classtime.wednesday.subject,
                      classtime.wednesday.type,
                    );
                    setSelectedTime('wed' + i);
                  }}
                >
                  {selectedMyClass
                    ? classtime.wednesday.type === 'move'
                      ? '분반'
                      : classtime.wednesday.subject
                    : teacherTableTime
                    ? classtime.wednesday.subject
                    : classtime.wednesday.class
                        .replace('학년', '-')
                        .replace('반', '')
                        .replace(' ', '')}
                </td>
                <td
                  className={`text-xs md:text-base border border-grey-50 py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 4 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-0'
                  } 
                  ${selectedTime === 'thu' + i ? 'text-red-500' : ''}
                  ${
                    selectedMyClass || classtime.thursday.class
                      ? 'cursor-pointer'
                      : ''
                  }
                  `}
                  onClick={() => {
                    timeTableClick(
                      'thursday_' + (i + 1),
                      '목요일 ' + (i + 1) + '교시',
                      classtime.thursday.class,
                      classtime.thursday.subject,
                      classtime.thursday.type,
                    );
                    setSelectedTime('thu' + i);
                  }}
                >
                  {selectedMyClass
                    ? classtime.thursday.type === 'move'
                      ? '분반'
                      : classtime.thursday.subject
                    : teacherTableTime
                    ? classtime.thursday.subject
                    : classtime.thursday.class
                        .replace('학년', '-')
                        .replace('반', '')
                        .replace(' ', '')}
                </td>
                <td
                  className={`text-xs md:text-base border border-grey-50 py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 5 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-0'
                  } 
                  ${i === 0 ? 'rounded-tr-xl border-t-0 border-r-0' : ''} ${
                    i === contentRow - 1
                      ? 'rounded-br-xl border-b-0 border-r-0'
                      : ''
                  } ${selectedTime === 'fri' + i ? 'text-red-500' : ''}
                  ${
                    selectedMyClass || classtime.friday.class
                      ? 'cursor-pointer'
                      : ''
                  }`}
                  onClick={() => {
                    timeTableClick(
                      'friday_' + (i + 1),
                      '금요일 ' + (i + 1) + '교시',
                      classtime.friday.class,
                      classtime.friday.subject,
                      classtime.friday.type,
                    );
                    setSelectedTime('fri' + i);
                  }}
                >
                  {selectedMyClass
                    ? classtime.friday.type === 'move'
                      ? '분반'
                      : classtime.friday.subject
                    : teacherTableTime
                    ? classtime.friday.subject
                    : classtime.friday.class
                        .replace('학년', '-')
                        .replace('반', '')
                        .replace(' ', '')}
                </td>
              </tr>
            ))}
          {!selectedMyClass && (!content || content.length === 0) && (
            <tr>
              <td colSpan={2}></td>
              <td colSpan={5}>선생님을 선택해주세요.</td>
            </tr>
          )}
          {selectedMyClass &&
            (!myKlassContent || myKlassContent.length === 0) && (
              <tr>
                <td colSpan={2}></td>
                <td colSpan={5}>반을 선택해주세요.</td>
              </tr>
            )}
        </tbody>
      </table>

      {!selectedMyClass && (
        <div className="w-full flex items-center justify-center space-x-2 py-4">
          <Badge
            tw={{
              backgroundColor:
                teacherTableTime === false ? 'bg-brand-1' : 'bg-white',
              color: teacherTableTime === false ? 'text-white' : 'text-black',
              paddingY: 'py-1.5',
            }}
            onClick={() => {
              setTeacherTableTime(false);
            }}
          >
            교실보기
          </Badge>
          <Badge
            tw={{
              backgroundColor:
                teacherTableTime === false ? 'bg-white' : 'bg-brand-1',
              color: teacherTableTime === false ? 'text-black' : 'text-white',
              paddingY: 'py-1.5',
            }}
            onClick={() => {
              setTeacherTableTime(true);
            }}
          >
            과목명보기
          </Badge>
        </div>
      )}
    </div>
  );
};
