import React from 'react';
import { Link } from 'react-router-dom';
import { Manager } from '../../types';

interface ManagerScheduleCardProps {
  manager: Manager;
  userRole: string;
}

export const ManagerScheduleCard: React.FC<ManagerScheduleCardProps> = ({
  manager,
  userRole,
}) => {
  let role = '선생님';

  if (userRole === 'PREHEAD') {
    role = '학년계';
  }
  if (userRole === 'HEAD') {
    role = '학년부장';
  }
  if (userRole === 'PREPRINCIPAL') {
    role = '교무계';
  }
  if (userRole === 'PRINCIPAL') {
    role = '교무부장';
  }
  if (userRole === 'VICEPRINCIPAL') {
    role = '교감';
  }
  if (userRole === 'HEADPRINCIPAL') {
    role = '교장';
  }

  let text = <div className="text-sm text-red-500">{role} 승인 전</div>;

  switch (manager.managerStatus) {
    case 'BEFORE_PARENT_CONFIRM':
      text = <div className="text-sm text-red-500">보호자 승인 전</div>;
      break;
    case 'BEFORE_TEACHER_APPROVAL':
      text = <div className="text-sm text-red-500">선생님 승인 전</div>;
      break;
    case 'BEFORE_PREHEAD_APPROVAL':
      text = <div className="text-sm text-red-500">학년계 승인 전</div>;
      break;
    case 'BEFORE_HEAD_APPROVAL':
      text = <div className="text-sm text-red-500">학년부장 승인 전</div>;
      break;
    case 'BEFORE_PREPRINCIPAL_APPROVAL':
      text = <div className="text-sm text-red-500">교무계 승인 전</div>;
      break;
    case 'BEFORE_PRINCIPAL_APPROVAL':
      text = <div className="text-sm text-red-500">교무부장 승인 전</div>;
      break;
    case 'BEFORE_VICEPRINCIPAL_APPROVAL':
      text = <div className="text-sm text-red-500">교감 승인 전</div>;
      break;
    case 'BEFORE_HEADPRINCIPAL_APPROVAL':
      text = <div className="text-sm text-red-500">교장 승인 전</div>;
      break;
    case 'PROCESSED':
      text = <div className="text-sm text-gray-600">처리 완료</div>;
      break;
    case 'RETURNED':
      text = <div className="text-sm text-brand-1">반려됨</div>;
      break;
    case 'DELETE_APPEAL':
      text = <div className="text-sm text-red-800">삭제 요청</div>;
      break;
  }
  return (
    <>
      <Link to={`/teacher/manager/${manager.id}`}>
        <div className="w-full p-4 relative flex items-center justify-between">
          <div>
            <h3 className="text-lg font-semibold">학교 개교기념일</h3>
            <div className="text-xs text-gray-500">
              2021.10.01(금) {manager.reportedAt}
            </div>
          </div>
          <div></div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
      </Link>
    </>
  );
};
