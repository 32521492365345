import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  BackButton,
  Blank,
  Button,
  Section,
  TextArea,
  TopNavbar,
  useMutation,
} from '../../../reusable';
import { SuperModal } from '../../../components';
import { DELETE_APPREAL_OUTING, DENY_OUTING } from '../../../query';
import {
  makeDateToString,
  makeStartEndToStringType2,
  makeTimeToString,
} from '../../../utils';
import { OutingUpdatePage } from './OutingUpdatePage';

interface MatchParams {
  id: string;
}
interface MatchParams {
  id: string;
}

interface OutingDetailPageProps extends RouteComponentProps<MatchParams> {
  outings: any;
  userRole: string;
  setOpen: (b: boolean) => void;
  setOutingId: (n: number) => void;
  setAgreeAll: (b: boolean) => void;
  refetch: () => void;
}

const OutingDetailPage: React.FC<OutingDetailPageProps> = ({
  match,
  userRole,
  outings,
  setOpen,
  setOutingId,
  setAgreeAll,
  refetch,
}) => {
  const { id } = match.params;

  const [isLoading, setLoading] = useState(false);
  const [deny, setDeny] = useState(false);
  const [notApprovedReason, setNotApprovedReason] = useState('');
  const [deleteReason, setDeleteReason] = useState('');
  const [deleteAppeal, setDeleteAppeal] = useState(false);
  const [errM, setErrM] = useState('');
  const [changeMode, setChangeMode] = useState(false);
  const outing: any = outings?.items?.filter(
    (el: any) => el.id === Number(id),
  )[0];

  useEffect(() => {
    setOutingId(Number(id));
  }, [id, setOutingId]);

  const [denyOuting] = useMutation(DENY_OUTING, {
    variables: { outingId: Number(id), notApprovedReason },
    onCompleted: () => {
      setDeny(false);
      setLoading(false);
      setErrM('');
      refetch();
    },
    onError: (err) => {
      setLoading(false);
      setErrM(err?.message);
    },
  });

  const [deleteAppealOuting] = useMutation(DELETE_APPREAL_OUTING, {
    variables: { outingId: Number(id), deleteReason },
    onCompleted: () => {
      setDeleteAppeal(false);
      setLoading(false);
      refetch();
    },
  });

  if (!outing) {
    return <div className="bg-white border rounded-lg p-5 h-screen-14"></div>;
  }

  const isConfirmed = outing?.outingStatus === 'PROCESSED';

  if (changeMode) {
    return (
      <OutingUpdatePage
        outingData={outing}
        refetch={() => refetch()}
        isConfirmed={isConfirmed}
        setChangeMode={(b: boolean) => setChangeMode(b)}
      />
    );
  }

  const updatedAt = new Date(outing?.updatedAt?.split('.')[0] || '');

  return (
    <>
      {/* Mobile V */}
      <div className="block md:hidden">
        <TopNavbar title="상세 보기" left={<BackButton />} />
      </div>
      <div className="bg-white border rounded-lg p-5 h-full bg-scroll block md:hidden">
        {isLoading && <Blank reversed />}
        <Section>
          {outing?.updateReason && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2">
              <div className="text-brand-1">{outing?.updateReason}</div>
              <div className="text-gray-500 text-sm">
                {makeDateToString(updatedAt)} {makeTimeToString(updatedAt)}에
                마지막으로 수정
              </div>
            </div>
          )}
          {outing?.outingStatus === 'RETURNED' && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2">
              <div className="text-brand-1 text-sm">
                {outing?.notApprovedReason}
              </div>
              <div className="text-red-500">반려 이유</div>
            </div>
          )}

          <div className="w-full text-center font-bold text-3xl min-w-max">
            {outing?.type ? outing.type + '증' : ''}
          </div>
          <div className="text-xl font-bold">
            [{outing?.type}] {outing?.student?.name} {outing?.studentGradeKlass}{' '}
            {outing?.studentNumber}번
          </div>

          <table className="w-full">
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                이름
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.student?.name}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                학번
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.studentGradeKlass} {outing?.studentNumber}번
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                유형
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.type}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                사유
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.reason}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                일시
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.startAt &&
                  outing?.endAt &&
                  makeStartEndToStringType2(outing.startAt, outing.endAt)}
              </td>
            </tr>
          </table>

          <div className="my-6">
            <div className="w-full flex flex-col items-end">
              {
                <div
                  className="bg-no-repeat bg-right bg-contain h-20"
                  style={{
                    backgroundImage: `url(${outing?.teacherSignature})`,
                  }}
                >
                  <div className="min-w-max font-bold mt-6 text-right mr-6">
                    담임: {outing?.teacher?.name} &nbsp;&nbsp;&nbsp;(인)
                  </div>
                </div>
              }
            </div>
            {userRole !== 'SECURITY' && (
              <>
                <Button
                  tw={{
                    width: 'w-full',
                    minWidth: 'min-w-max',
                    paddingY: 'py-3',
                    marginBottom: 'mb-2',
                    height: 'h-auto',

                    backgroundColor:
                      outing?.outingStatus === 'PROCESSED'
                        ? 'bg-gray-500'
                        : 'bg-brand-1',
                  }}
                  disabled={outing?.outingStatus === 'PROCESSED'}
                  text={
                    outing?.outingStatus === 'PROCESSED'
                      ? '승인 완료'
                      : '승인하기'
                  }
                  onClick={() => {
                    setOpen(true);
                    setAgreeAll(false);
                  }}
                />
                <Button
                  tw={{
                    width: 'w-full',
                    minWidth: 'min-w-max',
                    paddingY: 'py-3',
                    height: 'h-auto',
                    backgroundColor:
                      outing?.outingStatus === 'RETURNED'
                        ? 'bg-gray-500'
                        : 'bg-blue-500',
                  }}
                  disabled={outing?.outingStatus === 'RETURNED'}
                  text={
                    outing?.outingStatus === 'RETURNED' ? '반려됨' : '반려하기'
                  }
                  onClick={() => setDeny(true)}
                />
              </>
            )}
          </div>
        </Section>
        <SuperModal
          modalOpen={deny}
          setModalClose={() => setDeny(false)}
          width="w-max"
        >
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              이 학생의 확인증을 반려하시겠습니까?
            </div>
            <TextArea
              placeholder="반려 이유"
              onChange={(e) => setNotApprovedReason(e.target.value)}
              value={notApprovedReason}
            />
            <Button
              onClick={() => {
                setLoading(true);
                denyOuting();
              }}
            >
              반려하기
            </Button>
          </Section>
        </SuperModal>
      </div>

      {/* Desktop V */}
      <div className="bg-white border rounded-lg p-5 h-screen-14 hidden md:block">
        {isLoading && <Blank reversed />}
        <Section>
          {outing?.updateReason && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2">
              <div className="text-brand-1">{outing?.updateReason}</div>
              <div className="text-gray-500 text-sm">
                {makeDateToString(updatedAt)} {makeTimeToString(updatedAt)}에
                마지막으로 수정
              </div>
            </div>
          )}
          {outing?.outingStatus === 'RETURNED' && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2">
              <div className="text-brand-1 text-sm">
                {outing?.notApprovedReason}
              </div>
              <div className="text-red-500">반려 이유</div>
            </div>
          )}

          <div className="w-full text-center font-bold text-3xl min-w-max">
            {outing?.type ? outing.type + '증' : ''}
          </div>
          <div className="text-xl font-bold">
            [{outing?.type}] {outing?.student?.name} {outing?.studentGradeKlass}{' '}
            {outing?.studentNumber}번
          </div>

          <table>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                이름
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.student?.name}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                학번
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.studentGradeKlass} {outing?.studentNumber}번
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                유형
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.type}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                사유
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.reason}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                일시
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.startAt &&
                  outing?.endAt &&
                  makeStartEndToStringType2(outing.startAt, outing.endAt)}
              </td>
            </tr>
          </table>

          <div className="my-6">
            <div className="w-full flex items-end justify-end con">
              {
                <div
                  className="bg-no-repeat bg-right bg-contain h-20"
                  style={{
                    backgroundImage: `url(${outing?.teacherSignature})`,
                  }}
                >
                  <div className="min-w-max font-bold mt-6 text-right mr-6">
                    담임: {outing?.teacher?.name} &nbsp;&nbsp;&nbsp;(인)
                  </div>
                </div>
              }
            </div>
          </div>
          {errM && <div className="text-red-500">{errM}</div>}

          <div className="pt-4 flex items-center space-x-2">
            {userRole === 'TEACHER' && (
              <>
                <Button
                  tw={{
                    width: 'w-full',
                    minWidth: 'min-w-max',
                    backgroundColor: 'bg-red-500',
                  }}
                  text="삭제요청"
                  onClick={() => {
                    setDeleteAppeal(true);
                  }}
                />
                <Button
                  tw={{
                    width: 'w-full',
                    backgroundColor:
                      outing?.outingStatus === 'RETURNED'
                        ? 'bg-gray-500'
                        : 'bg-blue-500',
                  }}
                  disabled={outing?.outingStatus === 'RETURNED'}
                  text={
                    outing?.outingStatus === 'RETURNED' ? '반려됨' : '반려하기'
                  }
                  onClick={() => setDeny(true)}
                />
                <Button
                  tw={{
                    width: 'w-full',
                    backgroundColor: 'bg-yellow-500',
                  }}
                  text={isConfirmed ? '승인 후 수정' : '수정하기'}
                  onClick={() => setChangeMode(true)}
                />
                <Button
                  tw={{
                    width: 'w-full',
                    backgroundColor:
                      outing?.outingStatus === 'PROCESSED'
                        ? 'bg-gray-500'
                        : 'bg-brand-1',
                  }}
                  disabled={outing?.outingStatus === 'PROCESSED'}
                  text={
                    outing?.outingStatus === 'PROCESSED' ? '승인 완료' : '승인'
                  }
                  onClick={() => {
                    setOpen(true);
                    setAgreeAll(false);
                  }}
                />
              </>
            )}
          </div>
        </Section>
        <SuperModal
          modalOpen={deny}
          setModalClose={() => setDeny(false)}
          width="w-max"
        >
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              이 학생의 확인증을 반려하시겠습니까?
            </div>
            <TextArea
              placeholder="반려 이유"
              onChange={(e) => setNotApprovedReason(e.target.value)}
              value={notApprovedReason}
            />
            <Button
              onClick={() => {
                setLoading(true);
                denyOuting();
              }}
            >
              반려하기
            </Button>
          </Section>
        </SuperModal>
        <SuperModal
          modalOpen={deleteAppeal}
          setModalClose={() => setDeleteAppeal(false)}
          width="w-max"
        >
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              이 확인증을 삭제하도록 요청하시겠습니까?
            </div>
            <TextArea
              placeholder="삭제 이유"
              onChange={(e) => setDeleteReason(e.target.value)}
              value={deleteReason}
            />
            <Button
              tw={{ backgroundColor: 'bg-red-500' }}
              onClick={() => {
                setLoading(true);
                deleteAppealOuting();
              }}
            >
              삭제 요청하기
            </Button>
          </Section>
        </SuperModal>
      </div>
    </>
  );
};

export default withRouter(OutingDetailPage);
