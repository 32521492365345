import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import {
  addDays,
  addYears,
  eachDayOfInterval,
  format,
  isSameDay,
} from 'date-fns';
import { chain, concat, every, findIndex, flatten, get, last } from 'lodash';
import SignaturePad from 'signature_pad';
import {
  TopNavbar,
  BackButton,
  Section,
  TextField,
  TextArea,
  Button,
  PhoneNumberField,
  Checkbox,
  useMutation,
  BottomFixedView,
  Blank,
  CloseButton,
  useQuery,
  usePrevious,
} from '../../../reusable';
import {
  CREATE_FIELD_TRIP,
  GET_FIELDTRIP_ADD_PAGE,
  UPDATE_FIELD_TRIP,
} from '../../../query';
import { ErrorBlank, SelectValues, SuperModal } from '../../../components';
import { Fieldtrip } from '../../../types';
import {
  differenceWithSchedules,
  isWeekendDay,
  makeDateToString,
} from '../../../utils';
import { FieldtripDatePicker } from '../../../components/fieldtrip';

const relationshipType = ['부', '모'];
const forms = ['가족동반여행', '친·인척 방문', '답사∙견학 활동', '체험활동'];

enum RejectScheduleType {
  HOLIDAY = '공휴일',
  NOT_SELECTABLE = '지정불가',
}

interface MatchParams {
  type: string;
}

let sigPad: any = null;

interface FieldtripAddPageProps extends RouteComponentProps<MatchParams> {
  fieldtripData?: Fieldtrip;
  returnToDetail?: () => void;
}

const FieldtripAddPage: React.FC<FieldtripAddPageProps> = ({
  match,
  fieldtripData,
  returnToDetail,
}) => {
  let { type } = match?.params;

  if (fieldtripData) type = fieldtripData?.type?.toLowerCase() || 'suburbs';

  const [startAt, setStartAt] = useState<Date | null>(
    fieldtripData?.startAt ? new Date(fieldtripData.startAt) : new Date(),
  );
  const [endAt, setEndAt] = useState<Date | null>(
    fieldtripData?.endAt ? new Date(fieldtripData.endAt) : new Date(),
  );
  const [maxDate, setMaxDate] = useState<Date | null>(null);
  const [holidays, setHolidays] = useState<Date[]>([]);
  const [notSelectableDates, setNotSelectableDates] = useState<Date[]>([]);
  const [excludeDates, setExcludeDates] = useState<Date[]>([]);
  const currentDate = useMemo(() => new Date(), []);

  const { loading, error, data } = useQuery(GET_FIELDTRIP_ADD_PAGE, {
    variables: {
      startAt: startAt
        ? format(startAt, 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
      endAt: format(addYears(new Date(), 1), 'yyyy-MM-dd'),
      year: currentDate.getFullYear(),
      month: currentDate.getMonth(),
    },
  });

  const me = data?.me;

  const history = useHistory();
  const [success, setSuccess] = useState<any>();
  const [content, setContent] = useState(fieldtripData?.content || '');
  const [accommodation, setAccommodation] = useState(
    type === 'home' ? '자택' : fieldtripData?.accommodation || '',
  );
  const [agree, setAgree] = useState(false);
  const [destination, setDestination] = useState(
    type === 'home' ? '자택' : fieldtripData?.destination || '',
  );
  const [purpose, setPurpose] = useState(fieldtripData?.purpose || '');
  const [guideName, setGuideName] = useState(fieldtripData?.guideName || '');
  const [guidePhone, setGuidePhone] = useState(fieldtripData?.guidePhone || '');
  const [parentsName, setParentsName] = useState(me?.nokName || '');
  const [parentsPhone, setParentsPhone] = useState(me?.nokPhone);
  const [usedDays, setUsedDays] = useState(fieldtripData?.usedDays || 0);
  const prevUsedDays = usePrevious(usedDays);
  const [relationship, setRelationship] = useState(
    fieldtripData?.relationship || '',
  );
  const [suburbsModalopen, setSuburbsModalopen] = useState(false);
  const [homeModalopen, setHomeModalopen] = useState(false);
  const [openSignModal, setSignModal] = useState(false);
  const [sigPadData, setSigPadData] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [homePlan, setHomePlan] = useState<any>(
    fieldtripData && fieldtripData?.type === 'HOME'
      ? JSON.parse(fieldtripData?.content || '[]')
      : [],
  );

  const [form, setForm] = useState(forms[0]);
  const [count, setCount] = useState(0);

  const cannotSchedules = data?.rejectSchedulesByDate;

  useEffect(() => {
    if (document.querySelector('canvas')) {
      sigPad =
        //@ts-ignore
        new SignaturePad(document.querySelector('canvas'), {
          onEnd: () => {
            setSigPadData(sigPad.toDataURL());
          },
        });
    }
  }, []);

  const clearSignature = () => {
    sigPad && sigPad.clear();
    setSigPadData('');
    const canvas = document.querySelector('canvas');
    const ctx = canvas?.getContext('2d');
    ctx && ctx.clearRect(0, 0, canvas?.width || 0, canvas?.height || 0);
    ctx && ctx.beginPath();
  };

  const [createFieldtrip] = useMutation(CREATE_FIELD_TRIP, {
    variables: {
      data: JSON.stringify({
        content: type === 'home' ? JSON.stringify(homePlan) : content,
        accommodation,
        destination,
        guideName,
        guidePhone,
        parentsName,
        parentsPhone,
        startAt,
        endAt,
        relationship,
        form,
        purpose,
        usedDays,
        type: type?.toUpperCase(),
        reportedAt: makeDateToString(new Date()),
      }),
      signature: sigPadData,
    },
    onError: (error) => {
      setLoading(false);
      setSignModal(false);
      clearSignature();
      setErrorMessage(error.message);
    },
    onCompleted: (data) => {
      setLoading(false);
      setSignModal(false);
      alert('체험학습 신청서가 제출되었습니다.');
      clearSignature();
      setSuccess(data?.createFieldTrip?.id);
    },
  });

  const [updateFieldtrip] = useMutation(UPDATE_FIELD_TRIP, {
    variables: {
      data: JSON.stringify({
        fieldtripId: fieldtripData?.id,
        content: type === 'home' ? JSON.stringify(homePlan) : content,
        accommodation,
        destination,
        guideName,
        guidePhone,
        parentsName,
        parentsPhone,
        startAt,
        endAt,
        relationship,
        form,
        purpose,
        usedDays,
        type: type?.toUpperCase(),
      }),
      signature: sigPadData,
    },
    onError: (error) => {
      setLoading(false);
      setSignModal(false);
      clearSignature();
      setErrorMessage(error.message);
    },
    onCompleted: (data) => {
      setLoading(false);
      setSignModal(false);
      alert('체험학습 신청서를 수정하였습니다.');
      clearSignature();
      returnToDetail && returnToDetail();
    },
  });

  const _uniqDate = (date: Date, i: number, self: Date[]) => {
    return self.findIndex((d) => d.getTime() === date.getTime()) === i;
  };

  const _checkFillHomePlanContent = () => {
    const checkKeys = ['content1', 'subject1'];
    return every(homePlan, (plan) => {
      return every(checkKeys, (key) => {
        const value = get(plan, key, null);
        return !!value;
      });
    });
  };

  useEffect(() => {
    if (startAt && isWeekendDay(startAt)) {
      setStartAt(null);
      return;
    }

    if (endAt && isWeekendDay(endAt)) {
      setEndAt(null);
      return;
    }
    if (startAt && endAt) {
      const _usedDays = differenceWithSchedules(
        startAt,
        endAt,
        cannotSchedules,
      );
      setUsedDays(_usedDays);
    }
  }, [startAt, endAt]);

  useEffect(() => {
    if (!startAt || !me) {
      return;
    }
    let maxDay = 0;
    const checkDays = eachDayOfInterval({
      start: startAt,
      end: addDays(
        startAt,
        me.remainDaysOfFieldtrip - 1 < 0 ? 0 : me.remainDaysOfFieldtrip - 1,
      ),
    });
    while (checkDays.length) {
      const lastCheckDay = last(checkDays);
      const day = checkDays.shift();
      if (!day) {
        continue;
      }
      const findExcludeIndex = findIndex(notSelectableDates, (excludeDate) => {
        return isSameDay(day, excludeDate);
      });

      const findHolidayIndex = findIndex(holidays, (holiday) => {
        return isSameDay(day, holiday);
      });

      if (isWeekendDay(day) || findHolidayIndex !== -1) {
        checkDays.push(addDays(lastCheckDay as Date, 1));
      }
      if (findExcludeIndex !== -1) {
        setMaxDate(notSelectableDates[findExcludeIndex]);
        return;
      }
      maxDay++;
    }
    setMaxDate(addDays(startAt, maxDay - 1));
  }, [startAt, me, notSelectableDates]);

  useEffect(() => {
    if (!parentsName && me?.nokName) {
      setParentsName(me?.nokName);
    }
    if (me?.nokPhone) {
      setParentsPhone(me?.nokPhone);
    }
  }, [me]);

  useEffect(() => {
    const holidaySchedules = chain(cannotSchedules)
      .filter((schedule) => schedule.attendee === RejectScheduleType.HOLIDAY)
      .map((schedule) => {
        return eachDayOfInterval({
          start: new Date(schedule.start),
          end: new Date(schedule.end),
        });
      })
      .value();
    const excludeSchedules = chain(cannotSchedules)
      .filter(
        (schedule) => schedule.attendee === RejectScheduleType.NOT_SELECTABLE,
      )
      .map((schedule) => {
        return eachDayOfInterval({
          start: new Date(schedule.start),
          end: new Date(schedule.end),
        });
      })
      .value();
    setNotSelectableDates(flatten(excludeSchedules).filter(_uniqDate));
    setHolidays(flatten(holidaySchedules).filter(_uniqDate));
    setExcludeDates(
      concat(flatten(excludeSchedules), flatten(holidaySchedules)).filter(
        _uniqDate,
      ),
    );
  }, [cannotSchedules]);

  useEffect(() => {
    const _homePlan = (n: number) => {
      return new Array(n).fill(0).map(() => {
        return {};
      });
    };
    if (!prevUsedDays) {
      setHomePlan(_homePlan(usedDays));
      return;
    }

    if (prevUsedDays < usedDays) {
      const diffDays = usedDays - prevUsedDays;
      setHomePlan(concat(homePlan, _homePlan(diffDays)));
      return;
    }

    if (prevUsedDays > usedDays) {
      const diffDays = usedDays - prevUsedDays;
      const _homePlan = homePlan.slice(0, diffDays);
      setHomePlan(_homePlan);
      return;
    }
  }, [usedDays]);

  const name = type === 'home' ? '가정' : '교외 체험';

  const buttonDisabled =
    type === 'home'
      ? !startAt ||
        !endAt ||
        !destination ||
        !_checkFillHomePlanContent() ||
        !parentsName ||
        !parentsPhone ||
        !agree
      : !content ||
        !agree ||
        !destination ||
        !guideName ||
        !guidePhone ||
        !parentsName ||
        !parentsPhone ||
        !startAt ||
        !endAt ||
        !relationship;

  if (success) {
    return (
      <>
        <TopNavbar
          title="체험학습 신청서"
          left={
            <div className="h-15">
              <BackButton className="h-15" />
            </div>
          }
        />
        <div className="w-full h-screen-15 flex flex-col items-center justify-center text-center">
          <div className="text-gray-600 font-bold text-xl">
            체험학습 신청서 제출 완료
          </div>
          <div className="text-gray-400 mt-4">
            체험학습 신청서 제출이 완료되었습니다. <br />
            보호자, 담임, 학년부장, 교무부장의 서명 후<br />
            신청서 승인이 완료됩니다.
          </div>
          <BottomFixedView tw={{ bottom: 'bottom-16', paddingX: 'px-5' }}>
            <Button
              onClick={() => history.push(`/student/fieldtrip/${success}`)}
              tw={{ width: 'w-full' }}
            >
              신청서 확인하기
            </Button>
            <button
              className="bg-white border border-brand-1 text-brand-1 w-full rounded-lg mt-4 px-6 h-12 font-bold"
              onClick={() => history.push(`/student/fieldtrip`)}
            >
              목록으로 가기
            </button>
          </BottomFixedView>
        </div>
      </>
    );
  }

  return (
    <>
      {loading && <Blank />}
      {isLoading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar title="체험학습 신청서" left={<BackButton />} />
      <Section style={{ backgroundColor: '#F7F7F7' }}>
        <h1 className="text-xl font-semibold"> {name}학습 신청서</h1>
        <label className="mb-1 text-sm text-gray-800">*기간</label>
        <div className="space-y-3 pb-6">
          <div>
            <div className="flex flex-row items-center mb-4">
              <FieldtripDatePicker
                selectedDate={startAt}
                excludeDates={excludeDates}
                placeholderText="시작 날짜"
                onChange={(selectedDate) => {
                  if (!selectedDate) {
                    return;
                  }
                  if (endAt && selectedDate > endAt) {
                    setEndAt(selectedDate);
                  }
                  setStartAt(selectedDate);
                }}
              />
              <span>부터</span>
            </div>
            <div className="flex flex-row items-center mb-4">
              <FieldtripDatePicker
                disabled={!startAt}
                selectedDate={endAt}
                minDate={startAt}
                excludeDates={excludeDates}
                maxDate={maxDate}
                placeholderText={
                  !startAt ? '시작 날짜를 먼저 선택해주세요' : '종료 날짜'
                }
                onChange={(selectedDate) => {
                  if (!selectedDate) {
                    return;
                  }
                  if (startAt && selectedDate < startAt) {
                    setStartAt(selectedDate);
                  }
                  setEndAt(selectedDate);
                }}
              />
              <span>까지</span>
            </div>
          </div>
          <div className="  text-lg whitespace-pre-line mb-2">
            남은 일수&nbsp;
            <span className="text-brand-1 underline">
              {fieldtripData
                ? fieldtripData.currentRemainDays
                : me?.remainDaysOfFieldtrip}
              일 중 {usedDays}일을 신청
            </span>
            합니다.
          </div>
          <div className=" text-gray-600 text-xs whitespace-pre-line mb-2">
            ※<span className="font-bold">토,일, 개교기념일 등 학교 휴업일</span>
            은 체험학습 신청 일수에 넣지 않음. <br />※ 체험학습 신청은
            신청일보다 <span className="font-bold">3일 이전에 신청</span>해야함.
          </div>
          <div className="flex">
            <div className="font-bold text-gray-800 text-lg">
              *{name}학습 계획 작성
            </div>
          </div>
          {type === 'suburbs' && (
            <div className="w-full">
              <SelectValues
                selectValues={forms}
                label="*체험학습 형태"
                onChange={(f) => setForm(f)}
              />
            </div>
          )}

          <div className="w-full">
            <TextField
              label="*목적지"
              id="destination"
              placeholder="목적지를 입력해주세요."
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
              readOnly={type === 'home'}
            />
          </div>

          <div className="w-full">
            <TextField
              label="*목적"
              id="purpose"
              placeholder="목적을 입력해주세요."
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
            />
          </div>
          {type === 'suburbs' && (
            <>
              <div className="w-full">
                <TextField
                  label="*숙박장소(숙박일정이 있는 경우)"
                  id="accommodation"
                  placeholder="숙박장소를 입력해주세요."
                  value={accommodation}
                  onChange={(e) => setAccommodation(e.target.value)}
                />
              </div>
              <div className="w-full">
                <TextField
                  label="*인솔자명"
                  id="guideName"
                  placeholder="보호자 이름을 입력해주세요."
                  value={guideName}
                  onChange={(e) => setGuideName(e.target.value)}
                />
              </div>

              <div className="w-full">
                <SelectValues
                  label="*관계"
                  selectValues={relationshipType}
                  placeholder="선택"
                  value={relationship}
                  onChange={(g) => setRelationship(g)}
                />
                {/* <div className="text-red-600 text-sm whitespace-pre-line mb-2">
                  *인솔자가 직계가족이 아닌 경우 현장체험학습을 불허합니다.
                </div> */}
              </div>
              <div className="w-full">
                <TextField
                  label="*인솔자 연락처"
                  id="guidePhone"
                  placeholder="보호자 연락처를 입력해주세요."
                  value={guidePhone}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    setGuidePhone(e.target.value);
                  }}
                />
              </div>
              <div className="flex justify-between items-center">
                <div className="font-bold text-gray-800 text-lg">
                  *현장학습 계획 작성{' '}
                  <span className="font-normal text-red-600 text-sm">
                    (500자 이내) {count} / 500
                  </span>
                </div>
                <Button
                  text="예시보기"
                  onClick={() => setSuburbsModalopen(true)}
                  tw={{
                    width: 'w-24',
                    paddingY: 'py-2.5',
                    height: 'h-10',
                    paddingX: 'px-4',
                  }}
                />
              </div>
              <TextArea
                placeholder="예시를 보고 내용을 입력해주세요."
                rows={11}
                style={{ height: 'auto', borderWidth: '1px' }}
                value={content}
                onChange={(e) => {
                  const maxLength = 500;
                  if (e.target.value.length > maxLength) {
                    e.target.value = e.target.value.slice(0, maxLength);
                  }
                  setContent(e.target.value);
                  setCount(e.target.value.length);
                }}
              />
            </>
          )}
          {type === 'home' && (
            <>
              <div className="flex justify-between items-center">
                <div className="font-bold text-gray-800 text-lg">
                  *가정학습 계획 작성
                </div>
                <Button
                  text="예시보기"
                  tw={{
                    width: 'w-24',
                    paddingY: 'py-2.5',
                    height: 'h-10',
                    paddingX: 'px-4',
                  }}
                  onClick={() => setHomeModalopen(true)}
                />
              </div>
              {homePlan.map((plan: any, i: number) => (
                <div className=" bg-white shadow-sm p-4 border border-gray-100 rounded-md space-y-4">
                  <div className="font-bold text-gray-800 text-lg text-center pt-10 pb-4">
                    {i + 1}일차
                  </div>
                  <TextField
                    label="*1교시 교과명"
                    id={`home_subject_${i}_1`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject1'] || ''}
                    tw={{
                      borderColor: plan['subject1']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject1'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_1`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content1'] || ''}
                    tw={{
                      borderColor: plan['content1']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content1'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*2교시 교과명"
                    id={`home_subject_${i}_2`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject2'] || ''}
                    tw={{
                      borderColor: plan['subject2']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject2'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_2`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content2'] || ''}
                    tw={{
                      borderColor: plan['content2']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content2'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*3교시 교과명"
                    id={`home_subject_${i}_3`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject3'] || ''}
                    tw={{
                      borderColor: plan['subject3']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject3'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_3`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content3'] || ''}
                    tw={{
                      borderColor: plan['content3']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content3'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*4교시 교과명"
                    id={`home_subject_${i}_4`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject4'] || ''}
                    tw={{
                      borderColor: plan['subject4']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject4'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_4`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content4'] || ''}
                    tw={{
                      borderColor: plan['content4']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content4'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*5교시 교과명"
                    id={`home_subject_${i}_5`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject5'] || ''}
                    tw={{
                      borderColor: plan['subject5']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject5'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_5`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content5'] || ''}
                    tw={{
                      borderColor: plan['content5']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content5'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*6교시 교과명"
                    id={`home_subject_${i}_6`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject6'] || ''}
                    tw={{
                      borderColor: plan['subject6']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject6'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_6`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content6'] || ''}
                    tw={{
                      borderColor: plan['content6']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content6'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*7교시 교과명"
                    id={`home_subject_${i}_7`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject7'] || ''}
                    tw={{
                      borderColor: plan['subject7']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject7'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_7`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content7'] || ''}
                    tw={{
                      borderColor: plan['content7']
                        ? 'border-gray-300'
                        : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content7'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                </div>
              ))}
            </>
          )}
        </div>
        <div className="w-full">
          <TextField
            disabled={me?.nokName}
            label="보호자 이름"
            placeholder="보호자 이름을 입력해주세요."
            value={parentsName}
            onChange={(e) => setParentsName(e.target.value)}
            tw={{
              borderColor: parentsName ? 'border-gray-300' : 'border-red-700',
            }}
          />
        </div>
        <div className="w-full">
          <PhoneNumberField
            disabled={
              me?.nokPhone ||
              (me?.nokPhone === '010' && me?.nokPhone.length >= 10)
            }
            label="보호자 연락처"
            value={parentsPhone || '010'}
            onChange={(e) => setParentsPhone(e.target.value)}
            style={{
              borderColor: !parentsPhone ? 'rgba(185, 28, 28)' : '',
            }}
          />
          {/* <div className="text-red-600 text-sm whitespace-pre-line mb-2">
            보호자 동의를 위해 보호자의 연락처를 입력해주세요. 입력한 번호로
            서명 요청이 발송됩니다.
          </div> */}
          <div className=" text-gray-600 text-xs whitespace-pre-line mb-2 mt-2">
            * 보호자 정보 수정은 담임선생님의 학생카드 페이지에서 가능합니다.
          </div>
        </div>
        <div>
          <div className="flex items-center space-x-2 mb-1">
            <Checkbox
              id="agree"
              onChange={() => setAgree(!agree)}
              checked={agree}
            />
            <label htmlFor="agree">
              <span className="font-semibold text-lg cursor-pointer">
                아래 내용을 체크하셨습니까?
              </span>
            </label>
          </div>
          <div className="border border-gray-300 rounded-lg px-4 py-3 whitespace-pre-line">
            -신고자는 학생 본인입니다.
          </div>
        </div>
        <Button
          disabled={buttonDisabled}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={() => {
            setSignModal(true);
            const regExp = /^010(?:\d{4})\d{4}$/;
            if (parentsPhone && !regExp.test(parentsPhone.replace(/-/g, ''))) {
              alert('보호자 연락처를 확인해 주세요.');
              setSignModal(false);
              return;
            }
            if (guidePhone && !regExp.test(guidePhone)) {
              alert('인솔자 연락처를 확인해 주세요.');
              setSignModal(false);
              return;
            }
          }}
        >
          동의하고 서명하기
        </Button>

        {errorMessage && <div className="text-red-600">{errorMessage}</div>}
      </Section>
      <SuperModal
        modalOpen={suburbsModalopen}
        setModalClose={() => setSuburbsModalopen(false)}
      >
        <div className="text-center font-smibold text-brand-1 mt-5 text-lg">
          현장 학습 계획 예시
        </div>
        <div className="text-sm ml-6 mr-6 mb-6 mt-6 whitespace-pre-line">
          {`1.할머니 칠순맞이 가족과 국내 장거리 여행
            숙박, 식사 등 활동으로 친척과의 관계를 배울 수 있다.
            칠순, 고희에 대해 배울 수 있다.

            2.통영 케이블카 탑승
            케이블카 체험을 하고 한려해상국립공원의 아름다운 자연을 배울 수 있다.

            3.식사
            남해안과 경상도 지역의 식재료 및 음식을 알 수 있다.`}
        </div>

        <div className="w-full flex items-center justify-center my-2 mb-5">
          <button
            className="bg-gray-100 border border-gray-100 text-littleblack w-4/5 rounded-lg py-2 font-bold"
            onClick={() => setSuburbsModalopen(false)}
          >
            닫기
          </button>
        </div>
      </SuperModal>
      <SuperModal
        modalOpen={homeModalopen}
        setModalClose={() => setHomeModalopen(false)}
      >
        <div className="text-center font-smibold text-brand-1 mt-5 text-lg">
          가정 학습 계획 예시
        </div>
        <div className="text-sm ml-6 mr-6 mb-6 mt-6">
          작성 시 개인 시간표와 같게 작성해야 합니다.
          <br />
          1일 1장을 작성해야 합니다.
        </div>
        <div className=" bg-white shadow-xl p-4 border border-gray-100 rounded-md space-y-4 ml-7 mr-7 mt-4 mb-7">
          <div className="font-bold text-gray-800 text-lg text-center pt-10 pb-4">
            1일차
          </div>
          <TextField label="*1교시 교과명" id="reason" placeholder="국어" />
          <TextField
            label="*학습할 내용"
            id="reason"
            placeholder="3-1 훈민정음"
          />
        </div>

        <div className="w-full flex items-center justify-center my-2 mb-5">
          <button
            className="bg-gray-100 border border-gray-100 text-littleblack w-4/5 rounded-lg py-2 font-bold"
            onClick={() => setHomeModalopen(false)}
          >
            닫기
          </button>
        </div>
      </SuperModal>
      <div className={openSignModal ? '' : 'hidden'}>
        <Blank text="" />
        <BottomFixedView
          tw={{
            borderRadius: 'rounded-xl',
            backgroundColor: 'bg-white',
            zIndex: 'z-100',
            bottom: '-bottom-4',
          }}
        >
          <div
            className="absolute top-2 right-3"
            onClick={() => setSignModal(false)}
          >
            <CloseButton
              onClick={() => {
                setSignModal(false);
                clearSignature();
              }}
            />
          </div>
          <Section>
            <div>
              <div className="text-gray-700 text-xl font-bold">학생 서명란</div>
              <div className="text-gray-500">
                아래 네모칸에 서명을 해주세요.
              </div>
            </div>
            <canvas
              width={
                window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6
              }
              height={
                window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4
              }
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            <div className="flex items-center justify-between space-x-2">
              <Button
                tw={{
                  backgroundColor: 'bg-white',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  color: 'text-current',
                  width: 'w-full',
                }}
                onClick={() => clearSignature()}
              >
                다시하기
              </Button>
              <Button
                tw={{ width: 'w-full' }}
                onClick={() => {
                  if (!sigPadData) {
                    alert(
                      '서명이 없습니다. 아래 네모칸에 다시 서명을 해주세요.',
                    );
                    return;
                  }
                  if (parentsPhone === me?.phone) {
                    alert('학생 전화번호와 같은 번호로 신청할 수 없습니다.');
                  } else {
                    setLoading(true);
                    fieldtripData ? updateFieldtrip() : createFieldtrip();
                  }
                }}
              >
                서명 제출하기
              </Button>
            </div>
          </Section>
          {isLoading && <Blank />}
        </BottomFixedView>
      </div>
    </>
  );
};

export default withRouter(FieldtripAddPage);
