import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
//@ts-ignore
import Barcode from 'react-barcode';
//@ts-ignore
import preval from 'preval.macro';
import {
  Blank,
  Button,
  IconButton,
  tokenState,
  TopNavbar,
  useQuery,
  useResetRecoilState,
} from '../../../reusable';
import { ErrorBlank, StudentIDCard } from '../../../components';
import { GET_MYPAGE } from '../../../query';
import { version } from '../../../../package.json';
import { makeDateToString } from '../../../utils';
import { User } from '../../../types';
import { Bridge, api } from '../../../plugins';
import 'swiper/swiper.min.css';
import { checkNewVersion } from '../../../utils/status-util';

export const Mypage = () => {
  const history = useHistory();

  const resetToken = useResetRecoilState(tokenState);

  const { loading, error, data, refetch } = useQuery(GET_MYPAGE, {
    onError: ({ message }) => {
      if (message === 'Unauthorized') {
        history.push('/login');
      }
    },
  });

  const [me, setMe] = useState<User>();
  useEffect(() => {
    if (!me) {
      api
        .get('/users/me/childrens')
        .then((res) => setMe(res.data))
        .catch((err) => {
          if (err.message.includes('Unauthorized')) {
            history.replace('/login');
          } else {
            // alert(err.message);
          }
        });
    }
  }, []);

  const user = data?.me;
  const schoolName = user?.school?.name;

  const [isLoading, setLoading] = useState(false);

const parentLink = "https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%ED%8A%9C%ED%86%A0%EB%A6%AC%EC%96%BC%20%ED%95%99%EB%B6%80%EB%AA%A8%EC%9A%A9_2022.10.31.pdf";
const studentLink = "https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%ED%8A%9C%ED%86%A0%EB%A6%AC%EC%96%BC%20%ED%95%99%EC%83%9D%EC%9A%A9.pdf";

const link = me?.role === 'PARENT' ? parentLink : studentLink;

  if (error) {
    return <ErrorBlank />;
  }

  checkNewVersion();

  return (
    <div className="overflow-hidden">
      <TopNavbar
        title="MY"
        left={<div className="h-15 w-10"></div>}
        right={
          <div
            className="text-brand-1"
            onClick={() => {
              setLoading(true);
              window?.location?.reload();
            }}
          >
            새로고침
          </div>
        }
      />
      {loading && <Blank />}
      {isLoading && <Blank />}

      {me?.role === 'PARENT' ? (
        <Swiper
          slidesPerView={1.01}
          spaceBetween={10}
          className="mySwiper px-6"
        >
          {me?.children?.map((child: User) => (
            <SwiperSlide>
              <div className="card_shadow overflow-hidden w-full">
                <div>
                  <div
                    style={{
                      backgroundColor: '#FAFAFA',
                    }}
                    className="w-full flex flex-col items-center h-80"
                  >
                    <div
                      className="mt-12 mb-2 w-32 h-32 rounded-md bg-white bg-center bg-no-repeat bg-cover cursor-pointer z-10"
                      style={{ backgroundImage: `url(${child?.profile})` }}
                    />

                    <table>
                      <tbody>
                        <tr>
                          <td className="text-xs text-grey-4 pr-3 flex justify-between py-1">
                            <p>성</p>
                            <p>명</p>
                          </td>
                          <td className="font-bold text-black tracking-widest text-sm">
                            {child?.name}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-xs text-grey-4 pr-3 flex justify-between py-1">
                            <p>학</p>
                            <p>번</p>
                          </td>
                          <td className="font-bold text-black text-sm">
                            {child.studentGroups &&
                              `${child.studentGroups[0]?.group?.name} ${child.studentGroups[0]?.studentNumber}번`}
                          </td>
                        </tr>
                        {/* <tr>
                          <td className="text-xs text-grey-4 pr-3">생년월일</td>
                          <td className="text-black text-sm">
                            {child?.birthDate &&
                              makeDateToString(child.birthDate, '.')}
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>

                  <div className="px-6 h-30 flex flex-col justify-center">
                    <div>
                      <div className="flex space-x-2 items-center justify-center font-semibold">
                        {child?.school?.mark && (
                          <IconButton
                            className="w-8 h-8"
                            icon={
                              <img
                                src={
                                  process.env.REACT_APP_API_URL +
                                  '/images?url=' +
                                  child?.school?.mark
                                }
                                alt=""
                                loading="lazy"
                              />
                            }
                          />
                        )}
                        <p className="text-black">{child?.school?.name}</p>
                        {child?.school?.stamp && (
                          <IconButton
                            className="w-8 h-8"
                            icon={
                              <img
                                src={
                                  process.env.REACT_APP_API_URL +
                                  '/images?url=' +
                                  child?.school?.stamp
                                }
                                alt=""
                                loading="lazy"
                              />
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {child?.id === user?.id ? (
                  <Button
                    tw={{ backgroundColor: 'bg-gray-400', width: 'w-full' }}
                    disabled
                  >
                    현재 접속된 자녀
                  </Button>
                ) : (
                  <Button
                    tw={{
                      color: 'text-white',
                      backgroundColor: 'bg-brand-1',
                      width: 'w-full',
                    }}
                    onClick={() =>
                      api
                        .get(`/users/children/login/${child.uuid}`)
                        .then(({ data: { token } }: any) => {
                          localStorage.setItem('childToken', token);
                          refetch();
                        })
                        .catch(() => {
                          alert(
                            '해당 자녀로 로그인하던 중 문제가 발생했습니다. 잠시 후 다시 시도해주세요.',
                          );
                        })
                    }
                  >
                    자녀 변경
                  </Button>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <StudentIDCard me={user} refetch={() => refetch()} />
      )}

      {/* to here 바코드 자리 */}
      {/* <img src={sample_barcode} alt="" className="mx-auto" /> */}
      {user?.barcode && me?.role !== 'PARENT' && (
        <div className="w-full min-w-max flex justify-center">
          <Barcode
            value={user.barcode}
            options={{ format: 'CODE128', width: '100%' }}
          />
        </div>
      )}
      <div className="px-5 py-5 flex flex-col">
        <Link
          to="/student/info"
          className="-mx-5 px-5 py-4 font-semibold border-b"
        >
          내 정보
        </Link>
        {/* <Link to="/student/attend">
          <div className="flex justify-between items-center py-3 border-b-2 border-gray-6 cursor-pointer">
            <div className="text-gray-1 font-sfpro font-bold">출결 신고서</div>
            <RightArrow />
          </div>
        </Link> */}

        <a
          href={user?.school?.privacyPolicyLink}
          target="blank"
          className="-mx-5 px-5 py-4 font-semibold border-b cursor-pointer"
        >
          개인정보처리방침
        </a>

        <a
          href="https://www.notion.so/9fd766ba6b6945f9acbfc159989816a5"
          target="blank"
          className="-mx-5 px-5 py-4 font-semibold border-b cursor-pointer"
        >
          이용약관
        </a>
        <a
          href="https://superstudy.channel.io/lounge"
          target="blank"
          className="-mx-5 px-5 py-4 font-semibold border-b cursor-pointer"
        >
          문의하기
        </a>
        <a
          href={link}
          target="blank"
          className="-mx-5 px-5 py-4 font-semibold border-b cursor-pointer"
        >
          사용설명서
        </a>
        <div
          onClick={() => {
            resetToken();
            localStorage.removeItem('childToken');
            localStorage.removeItem('token');
            localStorage.removeItem('childError');
            Bridge.flareLane('setUserId', '');
            Bridge.flareLane('setTags', {});
            history.push('/');
          }}
          className="-mx-5 px-5 py-4 font-semibold border-b cursor-pointer"
        >
          로그아웃
        </div>

        <div className="my-5 w-full text-sm text-center text-gray-500">
          <div className="text-white">
            v{version} build at{' '}
            {preval`module.exports = new Date().toLocaleString().split("├")[0]`}
          </div>
          {schoolName} <br />
          Copyright 2022. SUPERSCHOOL all right reserved.
        </div>
      </div>
    </div>
  );
};
