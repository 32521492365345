import loadImage from 'blueimp-load-image';
import imageCompression from 'browser-image-compression';
import { toJpeg } from 'html-to-image';

export const _getImageMeta = (url: string) => {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.onerror = () => {
      reject(new Error(`Could not load image url ${url}`));
    };
    img.src = url;
  });
};

export const extractReactData = async (ref: any, fontEmbedCSS: string = '') => {
  if (!ref) {
    return null;
  }
  let imgData;
  try {
    imgData = await toJpeg(ref, { quality: 0.65, fontEmbedCSS });
    await _getImageMeta(imgData);
    return imgData;
  } catch (e) {
    console.log('extractReactData error  : ', e);
    console.log('extractReactData error imgData : ', imgData);
  }
};

export const _getBlobByCanvas = (canvas: any) => {
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob: any) => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
      }
      resolve(blob);
    }, 'image/jpeg');
  });
};

export const _getArrayBufferByFile = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.addEventListener('loadend', (e) =>
      resolve(e.target?.result as ArrayBuffer),
    );
    reader.addEventListener('error', reject);
    reader.readAsArrayBuffer(file);
  });
};

export interface extractImageDataReturnType {
  data: Uint8Array;
  width: number | undefined;
  height: number | undefined;
  orientation: loadImage.ExifTagValue;
}

export const extractImageData = async (
  filename: string,
): Promise<extractImageDataReturnType | null> => {
  if (!filename) {
    return null;
  }
  try {
    const result = await loadImage(filename, {
      meta: true,
      orientation: true,
      canvas: true,
    });
    const blob = await _getBlobByCanvas(result.image);
    const file = new File([blob], 'temp_file.jpeg', { type: blob.type });
    const compressedFile = await imageCompression(file, {
      initialQuality: 0.6,
    });
    const arrayBuffer = await _getArrayBufferByFile(compressedFile);
    const unit8Array = new Uint8Array(arrayBuffer);
    const orientation = result.exif?.get('Orientation') || 1;
    const isChangeWidthHeight =
      orientation === 5 ||
      orientation === 6 ||
      orientation === 7 ||
      orientation === 8;
    return {
      data: unit8Array,
      width: isChangeWidthHeight ? result.originalHeight : result.originalWidth,
      height: isChangeWidthHeight
        ? result.originalWidth
        : result.originalHeight,
      orientation,
    };
  } catch (e) {
    console.log('extractImageData error  : ', e);
  }
  return null;
};
