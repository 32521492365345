import React, { useEffect } from 'react';
import {
  HomePage,
  StudentMainPage,
  ActivityDetailPage,
  NoticeDetailPage,
  MyInfoPage,
  Login,
} from './pages/student';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import TeacherMainPage from './pages/teacher/TeacherMainPage';
import {
  ApolloProvider,
  AuthRoute,
  Blank,
  gql,
  useApolloClient,
  useAuth,
  useRecoilState,
} from './reusable';
import { meState } from './store';
import ResetPasswordPage from './pages/student/password/ResetPasswordPage';
import { AbsentApprovalPage } from './pages/student/absent/AbsentApprovalPage';
import { FindPasswordPage } from './pages/student/password/FindPasswordPage';
import { FindIdPage } from './pages/student/password/FindIdPage';
import { FieldtripApprovalPage } from './pages/student/fieldtrip/FieldtripApprovalPage';
import { FieldtripResultApprovalPage } from './pages/student/fieldtrip/FieldtripResultApprovalPage';
import FieldtripParentNoticePage from './pages/student/fieldtrip/FieldtripParentNoticePage';
import { StudentNewsletterApprovalPage } from './pages/student/newsletter/StudentNewsletterApprovalPage';
import { FirstLoginPage } from './pages/student/login/FirstLoginPage';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import { ParentSignupPage } from './pages/student/parent/ParentSignupPage';
import { AddChildrenPage } from './pages/student/parent/AddChildrenPage';
import { SetChildrenPage } from './pages/student/parent/SetChildrenPage';
import './index.css';
import { checkNewVersion } from './utils/status-util';

const MeQuery = gql`
  query Me {
    me {
      id
      name
      email
      role
      headNumber
      firstVisit
      canEditCanteen
      canEditTimetable
      school {
        name
      }
    }
  }
`;

function App() {
  const client = useApolloClient();
  const { authenticated } = useAuth();
  const [me, setMe] = useRecoilState(meState);
  const isProduction = process.env.REACT_APP_STATUS === 'production';

  useEffect(() => {
    if (authenticated && !me) {
      client
        .query({ query: MeQuery, fetchPolicy: 'network-only' })
        .then(({ data: { me } }) => {
          setMe(me);
          localStorage.setItem('schoolName', me?.school?.name);
        })
        .catch((error) => console.error(error));
    } else if (!authenticated && me) {
      setMe(undefined);
      client.clearStore();
    }
  }, [client, authenticated, me, setMe]);

  checkNewVersion();

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Blank />} //If not pass, nothing appears at the time of new version check.
    >
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Switch>
            <Route
              path="/fieldtrip/result/approve/:uuid"
              component={FieldtripResultApprovalPage}
            />
            <Route
              path="/fieldtrip/approve/:uuid"
              component={FieldtripApprovalPage}
            />
            <Route
              path="/fieldtrip/parent/notice/:uuid"
              component={FieldtripParentNoticePage}
            />
            <Route
              path="/studentnewsletter/approve/:uuid"
              component={StudentNewsletterApprovalPage}
            />
            <Route
              path="/absent/approve/:uuid"
              component={AbsentApprovalPage}
            />
            <Route path="/student/info" component={MyInfoPage} />
            {/* <Route path="/student/notice/:id" component={NoticeDetailPage} /> */}
            <Route
              path="/student/activity/:id"
              component={ActivityDetailPage}
            />
            <AuthRoute path="/student" component={StudentMainPage} />
            <AuthRoute path="/teacher" component={TeacherMainPage} />
            {/* <Route path="/signup/success" component={SignupSuccessPage} />
          <Route path="/signup/teacher" component={TeacherSignupPage} />
          <Route path="/signup/student" component={StudentSignupPage} /> */}
            <Route path="/reset-password/:id" component={ResetPasswordPage} />
            <Route path="/find-password" component={FindPasswordPage} />
            <Route path="/find-id" component={FindIdPage} />
            <AuthRoute path="/set-child" component={SetChildrenPage} />
            <AuthRoute path="/add-child/:uuid" component={AddChildrenPage} />
            <Route path="/parent-signup" component={ParentSignupPage} />
            <AuthRoute path="/first-login" component={FirstLoginPage} />
            <AuthRoute path="/login" guestOnly component={Login} />
            <Route path="/" component={HomePage} />
          </Switch>
        </BrowserRouter>
      </ApolloProvider>
    </CacheBuster>
  );
}

export default App;
