import {
  Blank,
  Button,
  Checkbox,
  Section,
  TextArea,
  TextField,
  useMutation,
} from '../../../reusable';
import React, { FC, useState } from 'react';
import { SuperModal, SelectValues } from '../../../components';
import { UPDATE_ABSENT_BY_TEACHER } from '../../../query';
import { Absent } from '../../../types';
import { makeDateToString, makeTimeToString } from '../../../utils';

interface AbsentUpdatePageProps {
  absentData: Absent;
  setChangeMode: (b: boolean) => void;
  refetch: () => void;
  isConfirmed: boolean;
}

const reportType = ['결석', '지각', '조퇴', '결과'];
const descriptionType = ['인정', '질병', '기타', '미인정'];

const reasonType: any = [
  '상고',
  '코로나 19 관련',
  '법정전염병',
  '생리',
  '학교장 출석인정',
  '병원진료',
  '가정에서의 안정',
  '보건실 방문',
  '기타',
  '개인사',
  '신고사유 없음',
];

const desType: any = {
  인정: {
    reasonType: ['상고', '코로나 19 관련', '법정전염병', '생리', '학교장 출석인정'],
    evidenceFileType: ['학부모 확인서', '기타'],
  },
  질병: {
    reasonType: ['병원진료', '가정에서의 안정', '보건실 방문'],
    evidenceFileType: ['진료확인서(진단서, 의사소견서 등)', '처방전(약봉투 등)','학부모 확인서', '담임교사 확인서','보건교사 확인서','기타'],
  },
  기타: {
    reasonType: ['기타'],
    evidenceFileType: ['학부모 확인서', '기타'],
  },
  미인정: {
    reasonType: ['개인사', '신고사유 없음', '기타'],
    evidenceFileType: ['학부모 확인서','증빙서류 없음', '기타'],
  },
};


const getMeridiem = (date: string | undefined) => {
  if (!date) return '오전';
  if (new Date(date).getHours() < 12) {
    return '오전';
  } else {
    return '오후';
  }
};

const getMeridiemHours = (date: string | undefined) => {
  if (!date) return 0;
  return new Date(date).getHours();
};

export const AbsentUpdatePage: FC<AbsentUpdatePageProps> = ({
  absentData,
  setChangeMode,
  refetch,
  isConfirmed,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [updateReason, setUpdateReason] = useState('');

  const [reason, setReason] = useState(
    absentData
      ? reasonType.includes(absentData?.reason)
        ? absentData?.reason
        : absentData?.description === '인정'
                ? '학교장 출석인정'
                : '기타'
      : '',
  );
  const [reasonText, setReasonText] = useState(absentData?.reason || '');
  const [report, setReport] = useState(absentData?.reportType || '');
  const [startAt, setStartAt] = useState(
    absentData?.startAt ? makeDateToString(new Date(absentData.startAt)) : '',
  );
  const [endAt, setEndAt] = useState(
    absentData?.endAt ? makeDateToString(new Date(absentData.endAt)) : '',
  );
  const [dateOff, setDateOff] = useState<boolean>(absentData?.startAt && absentData?.endAt ? 
    (makeTimeToString(absentData?.startAt) === '00:00' && makeTimeToString(absentData?.endAt) === '00:00') ? true : false : true,
  );
  const [description, setDescription] = useState(absentData?.description || '');
  const [evidenceType, setEvidenceType] = useState(
    desType[description]?.evidenceFileType.includes(absentData?.evidenceType)
      ? absentData?.evidenceType
      : '기타',
  );
  const [evidenceTypeText, setEvidenceTypeText] = useState(
    absentData?.evidenceType,
  );
  const [descriptionText, setDescriptionText] = useState(
    absentData?.description || '',
  );
  const [startMeridiem, setStartMeridiem] = useState(
    absentData ? getMeridiem(absentData.startAt) : '오전',
  );
  const [startHour, setStartHour] = useState(
    absentData ? getMeridiemHours(absentData.startAt) : 9,
  );
  const [startMinute, setStartMinute] = useState(
    absentData?.startAt ? new Date(absentData.startAt).getMinutes() : 0,
  );
  const [endMeridiem, setEndMeridiem] = useState(
    absentData ? getMeridiem(absentData.endAt) : '오후',
  );
  const [endHour, setEndHour] = useState(
    absentData ? getMeridiemHours(absentData.endAt) : 16,
  );
  const [endMinute, setEndMinute] = useState(
    absentData?.endAt ? new Date(absentData.endAt).getMinutes() : 40,
  );

  const makeStartAt = () => {
    let date = new Date();
    if (startAt) {
      date = new Date(startAt);
    }
    let hour = dateOff ? 0 : Number(startHour);
    date.setHours(hour, dateOff ? 0 : Number(startMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };
  const makeEndAt = () => {
    let date = new Date();
    if (report !== '결석') {
      startAt && (date = new Date(startAt));
    } else {
      endAt && (date = new Date(endAt));
    }
    let hour = dateOff ? 0 : Number(endHour);
    date.setHours(hour, dateOff ? 0 : Number(endMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };

  const [updateAbsent] = useMutation(UPDATE_ABSENT_BY_TEACHER, {
    variables: {
      data: JSON.stringify({
        id: absentData?.id,
        reportType: report,
        startAt: report !== '결석' ? makeStartAt() : startAt,
        endAt: report !== '결석' ? makeEndAt() : endAt,
        reason: (reason === '학교장 출석인정' || reason === '기타') ? reasonText : reason,
        description: description,
        evidenceType: evidenceType === '기타' ? evidenceTypeText : evidenceType,
        updateReason,
      }),
    },
    onCompleted: async () => {
      await refetch();
      await setLoading(false);
      await setChangeMode(false);
    },
  });

  return (
    <div className="bg-white border rounded-lg p-5">
      {isLoading && <Blank />}
      <div className="w-auto h-full">
        <Section>
          <div className="w-full flex items-center justify-between">
            <div className="text-2xl font-bold">
              [{absentData?.reportType}] {absentData?.student?.name}{' '}
              {absentData?.studentGradeKlass} {absentData?.studentNumber}번
            </div>
          </div>

          {[
            {
              label: '학번',
              title:
                absentData?.studentGradeKlass +
                ' ' +
                absentData?.studentNumber +
                '번',
            },
            { label: '학생이름', title: absentData?.student?.name },
            { label: '보호자이름', title: absentData?.student?.nokName },
            { label: '제출일', title: absentData?.reportedAt },
          ].map((item) => (
            <div>
              <div className="text-sm text-gray-500">{item.label}</div>
              <span className="font-semibold text-lg">{item.title}</span>
            </div>
          ))}
          <SelectValues
            label="*신고유형"
            placeholder="선택"
            selectValues={reportType}
            value={report}
            onChange={(group) => setReport(group)}
          />
          <SelectValues
            placeholder="선택"
            selectValues={descriptionType}
            value={description}
            onChange={(group) => {
              setDescription(group);
              if (!desType[group]?.reasonType?.includes(reason)) {
                setReason('');
              }
              if (!desType[group]?.evidenceFileType?.includes(evidenceType)) {
                setEvidenceType('');
              }
            }}
            borderColor={description ? 'border-gray-300' : 'border-red-700'}
          />
          {/* {description === '기타' && (
            <TextField
              tw={{ marginTop: 'mt-1' }}
              placeholder="신고 유형을 입력해주세요."
              value={descriptionText}
              onChange={(e) => setDescriptionText(e.target.value)}
            />
          )} */}
          <div>
            {report === '결석' && (
              <div className="w-full pb-6">
                <label className="mb-1 text-sm text-gray-800">*발생일</label>
                <div className="flex items-center mb-3">
                  <input
                    type="date"
                    value={startAt}
                    className="px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                    onChange={(e) => setStartAt(e.target.value)}
                  />
                  <span className="flex-shrink-0 ml-3">일 부터</span>
                </div>
                <div className="flex items-center">
                  <input
                    type="date"
                    value={endAt}
                    className="px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                    onChange={(e) => setEndAt(e.target.value)}
                  />
                  <span className="flex-shrink-0 ml-3">일 까지</span>
                </div>
              </div>
            )}
            {report !== '결석' && (
              <h1 className="space-y-3 pb-6">
                <div>
                  <label className="mb-1 text-sm text-gray-800">*발생일</label>
                  <input
                    type="date"
                    value={startAt}
                    defaultValue={absentData?.startAt}
                    className={`${
                      startAt ? 'border-gray-300' : 'border-red-700'
                    } px-4 w-full h-12 border rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max`}
                    onChange={(e) => setStartAt(e.target.value)}
                  />
                
                <div className="min-w-max flex space-x-2 items-center mt-2">
                  <label className="mb-1 text-sm text-gray-800">발생시간</label> 
                  <Checkbox
                    checked={dateOff}
                    label={dateOff ? 'off' : 'on'}
                    onChange={() => setDateOff(!dateOff)}
                  />
                </div>
                <div className="flex items-center space-x-2">
                <TextField type="text" 
                  min="0" 
                  max="24" 
                  className="px-4 w-14 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400"
                  value={dateOff ? '00' : startHour}
                  onChange={(e) =>{
                    if (/^\d*$/.test(e.target.value)) {
                      if (!isNaN(Number(e.target.value))) {
                        const _startHour = Number(e.target.value);
                        if (_startHour < 0 || _startHour > 24) {
                          return;
                        } 
                        if (_startHour > endHour) {
                          setEndHour(_startHour);
                        }
                        if (_startHour === endHour && startMinute > endMinute) {
                          setEndMinute(startMinute);
                        }
                        setStartHour(_startHour);
                      }
                    }
                  }}
                  disabled={dateOff}
                  />
                  <span className='text-sm'>시</span>
                  <TextField type="text" 
                    min="0" 
                    max="59" 
                    className="px-4 w-14 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400"
                    value={dateOff ? '00' : startMinute}
                    onChange={(e) =>{
                      if (/^\d*$/.test(e.target.value)) {
                        if (!isNaN(Number(e.target.value))) {
                          const _startMinute = Number(e.target.value);
                          if (_startMinute < 0 || _startMinute > 59) {
                            return;
                          } 
                          if (startHour === endHour && _startMinute > endMinute) {
                            setEndMinute(_startMinute);
                          }
                          setStartMinute(_startMinute);
                        }
                      }
                    }}
                    disabled={dateOff}
                  />
                  <span className='text-sm'>분 부터</span><br />
                  <TextField type="text" 
                  min="0" 
                  max="24" 
                  className="px-4 w-14 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400"
                  value={dateOff ? '00' : endHour}
                  onChange={(e) =>{
                    if (/^\d*$/.test(e.target.value)) {
                      if (!isNaN(Number(e.target.value))) {
                        const _endHour = Number(e.target.value);
                        if (_endHour < 0 || _endHour > 24) {
                          return;
                        }
                        setEndHour(_endHour);
                      }
                    }
                  }}
                  onBlur={(e) => {
                    const _endHour = Number(e.target.value);
                    if (startHour > _endHour) {
                      setStartHour(_endHour);
                    }
                    if (startHour === _endHour && startMinute > endMinute) {
                      setEndMinute(startMinute);
                    }
                  }}
                  disabled={dateOff}
                  />
                  <span className='text-sm'>시</span>
                  <TextField type="text" 
                    min="0" 
                    max="59" 
                    className="px-4 w-14 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400"
                    value={dateOff ? '00' : endMinute}
                    onChange={(e) =>{
                      if (/^\d*$/.test(e.target.value)) {
                        if (!isNaN(Number(e.target.value))) {
                          const _endMinute = Number(e.target.value);
                          if (_endMinute < 0 || _endMinute > 59) {
                            return;
                          } 
                          setEndMinute(_endMinute);
                        }
                      }
                    }}
                    onBlur={(e) => {
                      const _endMinute = Number(e.target.value);
                      if (startHour === endHour && startMinute > _endMinute) {
                        setStartMinute(_endMinute);
                      }
                    }}
                    disabled={dateOff}
                  />
                  <span className='text-sm'>분 까지</span>
                </div>
                </div>
              </h1>
            )}
          </div>
          <div>
            <SelectValues
              label="*신고사유 선택"
              placeholder="선택"
              selectValues={desType[description]?.reasonType || []}
              value={reason}
              onChange={(group) => setReason(group)}
              // onChange={(e) => setReason(e.target.value)}
            />
            {(reason === '학교장 출석인정' || reason === '기타') && (
              <TextField
                tw={{ marginTop: 'mt-1' }}
                placeholder="신고 사유를 입력해주세요."
                value={reasonText}
                onChange={(e) => setReasonText(e.target.value)}
              />
            )}
          </div>
          <div>
            <SelectValues
              label="*증빙서류 선택"
              selectValues={desType[description]?.evidenceFileType || []}
              value={evidenceType}
              onChange={(group) => setEvidenceType(group)}
            />
            {evidenceType === '기타' && (
              <TextField
                placeholder="서류 종류를 입력해주세요."
                value={evidenceTypeText}
                onChange={(e) => setEvidenceTypeText(e.target.value)}
                tw={{
                  borderColor: evidenceTypeText
                    ? 'border-gray-300'
                    : 'border-red-700',
                }}
              />
            )}
          </div>
          {absentData?.evidenceFiles &&
            absentData?.evidenceFiles.map((evidenceFile: string) => (
              <div className="w-full">
                <a href={evidenceFile} target="_blank" rel="noreferrer">
                  <div className={`relative pb-3/5 rounded bg-gray-50`}>
                    <img
                      className={`absolute w-full h-full rounded object-cover`}
                      src={
                        process.env.REACT_APP_API_URL +
                        '/images?url=' +
                        evidenceFile
                      }
                      loading="lazy"
                      alt=""
                    />
                  </div>
                </a>
              </div>
            ))
            }
        </Section>
      </div>

      {/* 버튼 */}
      <div className="flex items-center w-full space-x-2 mt-3">
        <Button
          tw={{ width: 'w-full' }}
          text="완료하기"
          onClick={() => {
            if (isConfirmed) {
              setModalOpen(true);
            } else {
              setLoading(true);
              updateAbsent();
            }
          }}
        />
        <Button
          tw={{ width: 'w-full', backgroundColor: 'bg-gray-500' }}
          text="취소하기"
          onClick={() => {
            setChangeMode(false);
          }}
        />
      </div>
      <SuperModal
        modalOpen={modalOpen}
        setModalClose={() => setModalOpen(false)}
        width="w-max"
      >
        <Section tw={{ marginTop: 'mt-7' }}>
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            이 출결신고서를 승인 후 수정하시는 이유를 적어주세요.
          </div>
          <TextArea
            placeholder="수정 이유"
            onChange={(e) => setUpdateReason(e.target.value)}
            value={updateReason}
          />
          <Button
            tw={{ backgroundColor: 'bg-red-500' }}
            onClick={() => {
              setLoading(true);
              updateAbsent();
            }}
          >
            승인 후 수정하기
          </Button>
        </Section>
      </SuperModal>
      <div className="w-full h-24" />
    </div>
  );
};
