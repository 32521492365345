import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import {
  BackButton,
  Button,
  LoadingScreen,
  Section,
  useMutation,
  useRecoilValue,
  TopNavbar,
} from '../../../reusable';
import { ErrorBlank, SuperModal } from '../../../components';
import { Chat, User } from '../../../types';
import { api, fetcher } from '../../../plugins';
import { SendMessage } from '../../../components/chat/SendMessage';
import { ReceiveMessage } from '../../../components/chat/ReceiveMessage';
import { meState } from '../../../store';
import svgUserIcon from '../../../assets/svg/user_icon.svg';
import useSocket from '../../../reusable/hooks/socket';
import { makeStudNum5 } from '../../../utils';

interface MatchParams {
  id: string;
}

export const ChatDetailPage: FC<MatchParams> = ({ id }) => {
  const history = useHistory();

  id = id.split('/')[0];

  const [data, setChatData] = useState<any>([]);
  const getChatData = useCallback(async () => {
    const res = await api.get(`/chat/${id}`).then(({ data }) => data);
    setChatData(res);
  }, [id]);
  
  useEffect(() => {
    getChatData();
  }, [getChatData]);

  const [me, setMe] = useState<User>();
  useEffect(() => {
    if (!me) {
      api
        .get('/users/me/detail')
        .then((data) => setMe(data.data))
        .catch((err) => {
          if (err.message.includes('Unauthorized')) {
            history.replace('/login');
          } else {
            alert(err.message);
          }
        });
    }
  }, [me]);

  const [chats, setChats] = useState<Chat[]>(data?.talk?.flat().reverse());
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [content, setContent] = useState('');
  //const [recvuser, setRecvuser] = useState(data ? data[0].receiverid : null);
  
  const [onlineList, setOnlineList] = useState<number[]>([]);
  const [socket, disconnect] = useSocket(`chat-${id}`);

  useEffect(() => {
    if (me?.id && id && socket) {
      console.log('in', me?.id, id, socket);
      socket.emit('in', { id: Number(me?.id) });
    }

    socket?.on('onlineList', (data: number[]) => {
      setOnlineList(data);
    });

    socket?.on('chat', (chatdata: Chat) => {
      getChatData();
    })
    // socket?.on('dm', onMessage);
    // console.log('socket on dm', socket?.hasListeners('dm'), socket);
    return () => {
      // socket?.off('dm', onMessage);
      // console.log('socket off dm', socket?.hasListeners('dm'));
      socket?.off('onlineList');
    };
  }, [id, me?.id, getChatData, socket]);

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, [id, disconnect]);

  useEffect(() => {
    if (data) {
      setChats(data?.talk?.flat().reverse());
    }
  }, [data]);

  // 채팅이 업데이트될 때마다 아래로 스크롤
  const chatListRef = useRef<HTMLImageElement | null>(null);
  useEffect(() => {
    chatListRef.current?.scrollTo(0, chatListRef.current.scrollHeight);
  }, [chats]);

  // 메시지 입력
  const OnSendMessage = async () => {
    await api
      .post(`/chat/${id}`, { content })
      .then((res) => {
        // _files.push(res.data);
        //history.push(`/student/chat/${id}`);
        setContent('');
        getChatData();
      })
      .catch((err) => {
        alert('메시지 전송에 실패했습니다.');
        console.log(err.message);
      });
  };

  // useEffect(() => {
  //   history.push(`/teacher/chat/${id}`);
  // }, []);

  if (isLoading) return <LoadingScreen />;
  //if (error) return <ErrorBlank />;
  if (!me) return <LoadingScreen />;

  return (
    <>
      <div className=" h-screen-4 relative bg-gray-50">
        <div className="block ">
          <TopNavbar title="채팅" left={<BackButton className="h-15" />} />
        </div>
        <div className="bg-gray-50 border rounded-lg h-18 p-2 m-3 overflow-hidden">
          <div className="float-left">
          <div className="relative">
            <img
              className="flex-2 rounded-full items-start flex-shrink-0 mr-1 w-12 h-12 mx-auto"
              src={data?.talkWith?.profile}
              alt=""
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = svgUserIcon;
              }}
            />
          </div>
          {/* <span className="absolute w-4 h-4 bg-green-400 rounded-full right-0 bottom-0 border-2 border-white"></span> */}
          </div>
          <div className="mt-1 float-left items-center w-max rounded-full px-1 py-0.5 m-1s mr-2 bg-white border-2 border-brand-1 text-brand-1 whitespace-nowrap text-sm font-bold">
            {data?.talkWith?.role}{' '}
          </div>
          <div className="mt-2 float-left text-sm">
            {data?.talkWith?.klass} {data?.talkWith?.name}
          </div>
        </div>

        <div className="bg-white border rounded-lg h-screen-22  m-3">
          <div className="h-full overflow-y-auto p-2" ref={chatListRef}>
            {chats?.map((c: Chat) =>
              c.senderid === me?.id ? (
                <SendMessage MessageData={c} />
              ) : (
                <ReceiveMessage
                  MessageData={c}
                  name={data?.talkWith?.name}
                  profile={data?.talkWith?.profile}
                  KlassNameNum={data?.talkWith?.klass}
                />
              ),
            )}
          </div>
        </div>

        <div className="flex-2 px-3">
          <div className="write bg-white shadow flex rounded-lg">
            <div className="flex-1">
              <textarea
                name="message"
                className="w-full block outline-none py-2 px-2 bg-transparent"
                placeholder="메시지 입력"
                onChange={(e) => setContent(e.target.value)}
                value={content}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    OnSendMessage();
                  }
                }}
              ></textarea>
            </div>
            <div className="flex p-2 content-center items-center">
              <button
                className="bg-blue-400 w-10 h-10 rounded-full inline-block"
                onClick={() => OnSendMessage()}
              >
                <span className="inline-block align-text-bottom">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    className="w-4 h-4 text-white"
                  >
                    <path d="M5 13l4 4L19 7"></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
