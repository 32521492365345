import React, { useState } from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  withRouter,
} from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import { Blank, useMutation } from '../../../reusable';
import { ErrorBlank, SubmitterItem, Tab } from '../../../components';
import { CREATE_OR_UPDATE_STUDENT_ACTIVITY_BY_TEACHER } from '../../../query';
import { StudentGroup, User } from '../../../types';
import ActivitySubmitDetailPage from './ActivitySubmitDetailPage';
import { fetcher } from '../../../plugins';

interface MatchParams {
  id: string;
}

interface ActivitySubmitPageProps extends RouteComponentProps<MatchParams> {
  groupId?: number;
}

const ActivitySubmitPage: React.FC<ActivitySubmitPageProps> = ({
  match,
  groupId,
}) => {
  const history = useHistory();
  let { id } = match.params;

  const selectedFilter = Number(localStorage.getItem('selectedFilter'));

  const [filter, setFilter] = useState(selectedFilter || 0);

  const { error, data, refetch } = uQ(
    `/activities/submit/${id}?groupId=${groupId}`,
    fetcher,
    {
      enabled: !!groupId && !!id && id !== 'undefined',
      keepPreviousData: true,
    },
  );

  const [isLoading, setLoading] = useState(false);

  const [createSA] = useMutation(CREATE_OR_UPDATE_STUDENT_ACTIVITY_BY_TEACHER, {
    errorPolicy: 'ignore',
    onCompleted: async (response) => {
      await setLoading(false);
      await refetch();
      await history.push(
        `/teacher/activity/submit/${id}/${response?.createOrUpdateStudentActivityByTeacher?.id}`,
      );
    },
  });

  if (error) {
    return <ErrorBlank />;
  }
  const klassGroups = data?.klassGroups?.slice().sort((a: any, b: any) => {
    const aData = a?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);
    const bData = b?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);

    if (!aData || !bData) {
      return 0;
    }

    if (aData[1] && bData[1]) {
      if (aData[1] === bData[1]) {
        return Number(aData[2]) - Number(bData[2]);
      } else {
        return Number(aData[1]) - Number(bData[1]);
      }
    } else {
      return 0;
    }
  });

  let studentGroups = data?.group?.studentGroups
    .slice()
    .sort((a: any, b: any) => {
      if (!klassGroups?.length) {
        return a.studentNumber - b.studentNumber;
      }
      const aNumber = klassGroups
        ?.filter((el: User) => el.id === a?.id)[0]
        ?.studentGroups?.filter(
          (sg: StudentGroup) => sg?.group?.type === 'KLASS',
        )[0]?.studentNumber;
      const bNumber = klassGroups
        ?.filter((el: User) => el.id === b?.id)[0]
        ?.studentGroups?.filter(
          (sg: StudentGroup) => sg?.group?.type === 'KLASS',
        )[0]?.studentNumber;

      return aNumber - bNumber;
    });

  if (filter === 1) {
    studentGroups = studentGroups?.filter(
      (sg: any) => sg.user?.studentActivities[0]?.isSubmitted === true,
    );
  } else if (filter === 2) {
    studentGroups = studentGroups?.filter(
      (sg: any) =>
        sg.user?.studentActivities.length === 0 ||
        sg.user?.studentActivities[0]?.isSubmitted === false,
    );
  }

  return (
    <div className="bg-white ml-0.5 grid grid-cols-7 h-screen">
      {/* {loading && <Blank reversed />} */}
      {isLoading && <Blank reversed />}
      <div className="col-span-4">
        <div className="w-full flex items-center justify-between p-4">
          <div className="space-x-2 flex items-center">
            <h3 className="text-lg">제출자 리스트</h3>
            <div className="text-gray-500 text-sm">
              총 {studentGroups?.length}명
            </div>
          </div>
          <div
            className="text-brand-1 cursor-pointer"
            onClick={() => refetch()}
          >
            새로고침
          </div>
        </div>
        <div className="h-0.5 bg-gray-100 "></div>
        <Tab
          filter={filter}
          setFilter={(n: number) => {
            localStorage.setItem('selectedFilter', n.toString());
            setFilter(n);
          }}
        />
        <div className="p-4 overflow-y-scroll h-screen-8">
          {studentGroups?.map((studentGroup: StudentGroup) => (
            <SubmitterItem
              students={data?.klassGroups}
              studentGroup={studentGroup}
              id={id}
              onClick={async () => {
                if (!studentGroup?.user?.studentActivities) {
                  await setLoading(true);
                  await createSA({
                    variables: {
                      data: JSON.stringify({
                        userId: studentGroup?.user?.id,
                        activityId: Number(id),
                        content: '',
                        isSubmitted: false,
                      }),
                    },
                  });
                } else {
                  await history.push(
                    `/teacher/activity/submit/${id}/${studentGroup?.user?.studentActivities[0]?.id}`,
                  );
                }
              }}
            />
          ))}
        </div>
      </div>
      <div className="col-span-3">
        <Switch>
          <Route
            path={`/teacher/activity/submit/${id}/:said`}
            component={() => (
              <ActivitySubmitDetailPage activityData={data?.activity} />
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(ActivitySubmitPage);
