import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Linkify from 'react-linkify';
import SignaturePad from 'signature_pad';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import {
  Blank,
  BottomFixedView,
  Button,
  CloseButton,
  Section,
  TopNavbar,
  useMutation,
  useQuery,
} from '../../../reusable';
import {
  APPROVE_STUDENT_NEWSLETTER,
  GET_STUDENTNEWSLETTER_BY_UUID,
} from '../../../query';
import {
  ErrorBlank,
  FileItem,
  SuperSurveyComponent,
} from '../../../components';
import { makeDateToString } from '../../../utils';

interface MatchParams {
  uuid: string;
}

let sigPad: any = null;

export const StudentNewsletterApprovalPage: FC<
  RouteComponentProps<MatchParams>
> = ({ match }) => {
  const { uuid } = match.params;
  const { loading, error, data, refetch } = useQuery(
    GET_STUDENTNEWSLETTER_BY_UUID,
    {
      variables: { uuid },
    },
  );

  const studentNewsletter = data?.studentNewsletterByUUID;
  const student = studentNewsletter?.student;
  const school = student?.school;
  const newsletter = studentNewsletter?.newsletter;

  const [openSign, setSign] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setSuccess] = useState(false);
  const [sigPadData, setSigPadData] = useState('');

  useEffect(() => {
    sigPad =
      //@ts-ignore
      new SignaturePad(document.querySelector('canvas'), {
        onEnd: () => {
          setSigPadData(sigPad.toDataURL());
        },
      });
  }, []);

  const clearSignature = () => {
    sigPad && sigPad.clear();
    setSigPadData('');
    const canvas = document.querySelector('canvas');
    const ctx = canvas?.getContext('2d');
    ctx && ctx.clearRect(0, 0, canvas?.width || 0, canvas?.height || 0);
    ctx && ctx.beginPath();
  };

  const [approveStudentNewsletter] = useMutation(APPROVE_STUDENT_NEWSLETTER, {
    variables: {
      uuid,
      signature: sigPadData,
    },
    onCompleted: () => {
      setLoading(false);
      setSign(false);
      refetch();
      setSuccess(true);
    },
    onError: (e) => {
      setLoading(false);
      setErrorMessage(e.message);
    },
  });

  let isSigned = false;
  if (studentNewsletter?.parentSignature) {
    isSigned = true;
  }

  const files: any[] = [];

  if (newsletter?.files) {
    try {
      newsletter?.files.map((f: any) => files.push(JSON.parse(f)));
    } catch (err) {
      console.error(err);
    }
  }

  let images: any[] = [];
  try {
    images = newsletter?.images
      ? newsletter?.images.map((el: any) => JSON.parse(el).url)
      : [];
  } catch (err) {
    console.error(err);
  }

  return (
    <>
      {loading && <Blank />}
      {isSuccess && (
        <Blank>
          정상적으로 제출되었습니다.
          <br /> 감사합니다!
        </Blank>
      )}
      {errorMessage && <ErrorBlank text={errorMessage} />}
      {error && (
        <ErrorBlank
          text={
            error.message?.includes('Could not find any entity')
              ? '해당하는 출결신고서를 찾을 수 없습니다.'
              : error.message
          }
        />
      )}
      <TopNavbar title={'가정통신문'} left={<div className="w-10 h-10" />} />
      <Section>
        {!isSigned ? (
          <div className="rounded-lg bg-light_orange whitespace-pre-line mb-4 p-4">
            {`해당 가정통신문의 설문 내용에 귀하의 자녀 ${
              student?.name || ''
            } 학생이 답변하였습니다.
      내용확인 후 서명을 부탁드립니다.

      서명 요청자 :
      ${school?.name || ''} ${studentNewsletter?.studentGradeKlass || ''} ${
              student?.name || ''
            } 학생

      서명 참여자 :
      ${student?.nokName || ''} 보호자님 (${student?.nokPhone || ''})
      `}
          </div>
        ) : (
          <>
            <div>
              <div className="rounded-lg bg-light_orange whitespace-pre-line mb-4 p-4">
                {`이 가정통신문의 답변 내용이 ${
                  student?.nokName || ''
                }님의 서명을 받았습니다.`}
              </div>
            </div>
            <div className="h-0.5 bg-gray-200" />
          </>
        )}
        <div>
          <h1 className="text-2xl font-semibold">{newsletter?.title}</h1>
          <div className="text-gray-500 text-sm">
            {newsletter?.createdAt &&
              makeDateToString(new Date(newsletter.createdAt), '.')}
          </div>
        </div>
        <div className="h-0.5 w-full bg-gray-100" />

        {images?.map((image: string, i: number) => (
          <>
            <div className="w-full">
              <a href={image} target="_blank" rel="noreferrer">
                <div className={`relative pb-3/5 rounded bg-gray-50`}>
                  <ExifOrientationImg
                    loading="lazy"
                    className={`absolute w-full h-full rounded object-cover`}
                    src={image}
                    alt=""
                  />
                </div>
              </a>
            </div>
          </>
        ))}
        {files?.length ? (
          <div className="bg-gray-50">
            {files?.map((f: any) => (
              <FileItem file={f} />
            ))}
          </div>
        ) : (
          <></>
        )}
        <div
          className={`text-grey-2 break-words whitespace-pre-line text-sm feedback_space`}
        >
          <Linkify>{newsletter?.content}</Linkify>
        </div>
      </Section>
      <SuperSurveyComponent
        surveyContent={newsletter?.surveyContent || ''}
        setContent={(c: any) => {}}
        content={JSON.parse(studentNewsletter?.content || '{}')}
      />
      <Section>
        {isSigned && (
          <div className="w-full flex flex-col items-end">
            <div className="min-w-max font-bold mt-4 text-right">
              보호자: {student?.nokName} (인)
            </div>
            <img
              src={studentNewsletter?.parentSignature}
              alt=""
              style={{ width: '100px', marginTop: '0.5rem' }}
            />
          </div>
        )}

        <div className="whitespace-pre-line pb-12">
          {/* {`*해당 가정통신문의 학생 답변 내용입니다.`} */}
        </div>
        {isSigned ? (
          <>
            <div className="text-gray-500 text-sm">승인 요청되었습니다.</div>
            <Button
              tw={{
                width: 'w-full',
                backgroundColor: 'bg-gray-500',
              }}
            >
              서명 완료
            </Button>
          </>
        ) : (
          <Button tw={{ width: 'w-full' }} onClick={() => setSign(true)}>
            내용 확인하고 서명하기
          </Button>
        )}
      </Section>
      <div className={openSign ? '' : 'hidden'}>
        <Blank text="" />
        <BottomFixedView
          tw={{
            borderRadius: 'rounded-xl',
            backgroundColor: 'bg-white',
            zIndex: 'z-100',
            bottom: '-bottom-4',
          }}
        >
          <div
            className="absolute top-2 right-3"
            onClick={() => setSign(false)}
          >
            <CloseButton
              onClick={() => {
                setSign(false);
                clearSignature();
              }}
            />
          </div>
          <Section>
            <div>
              <div className="text-gray-700 text-xl font-bold">
                보호자 서명란
              </div>
              <div className="text-gray-500">
                아래 네모칸에 서명을 해주세요.
              </div>
            </div>
            <canvas
              width={
                window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6
              }
              height={
                window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4
              }
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            <div className="flex items-center justify-between space-x-2">
              <Button
                tw={{
                  backgroundColor: 'bg-white',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  color: 'text-current',
                  width: 'w-full',
                }}
                onClick={() => clearSignature()}
              >
                다시하기
              </Button>
              <Button
                tw={{ width: 'w-full' }}
                onClick={() => {
                  setLoading(true);
                  approveStudentNewsletter();
                }}
                disabled={isLoading}
              >
                서명 제출하기
              </Button>
            </div>
          </Section>
        </BottomFixedView>
      </div>
    </>
  );
};
