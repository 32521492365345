import React, { useState, useEffect } from 'react';
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import {
  BackButton,
  Blank,
  Button,
  Checkbox,
  Divider,
  Section,
  useQuery,
  Label,
  TextArea,
  Select,
  TopNavbar,
  useQueryBasic,
  useRecoilValue,
  useSetRecoilState,
  newMsgCntState,
} from '../../../reusable';
import { ErrorBlank, LnbCalendarsItem, SelectMenus } from '../../../components';
import { ReactComponent as UploadIcon } from '../../../assets/svg/icon-upload.svg';
import { Group, StudentGroup } from '../../../types';
import { useQuery as uQ } from 'react-query';
import { api, fetcher } from '../../../plugins';
import { GET_TIMETABLE_PAGE } from '../../../query';
import { ReactComponent as Close } from '../../../assets/svg/Close_Icon.svg';
import { ChatRoomList } from '../../../components/chat/ChatRoomList';
import { makeStudNum5 } from '../../../utils';
import useSocket from '../../../reusable/hooks/socket';
import { meState } from '../../../store';
import { ChatDetailPage } from './ChatDetailPage';

interface ChatListPageProps {
  groupData?: Group;
  refetch: () => void;
}

interface UserDatas {
  id?: number;
  name?: string;
  role?: string;
  studNum5?: string;
}

const ChatListPage: React.FC<ChatListPageProps> = ({ groupData, refetch }) => {
  const history = useHistory();
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [, setStudentName] = useState('');
  const [_studentName, set_studentName] = useState('');
  const [selKlassId, setSelKlassId] = useState(-1);
  const [selectedGroup, setSelectedGroup] = useState<UserDatas[] | undefined>();
  const [selectedUserType, setSelectedUserType] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState<any[]>(
    groupData?.studentGroups
      ? groupData.studentGroups.map((sg: StudentGroup) => sg.user)
      : [],
  );
  const userIds = selectedUsers.map((el) => el.id);
  const [content, setContent] = useState(groupData ? groupData.name : '');
  const [isLoading, setLoading] = useState(false);
  const me = useRecoilValue(meState);

  const setNewMsgCnt = useSetRecoilState(newMsgCntState);

  useEffect(() => {
    api
      .get('/chatroom/newmsg')
      .then((res) => {
        setNewMsgCnt(res.data.newMsgCnt);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [refetch]);


  const pathname = useLocation().pathname;
  const ChatRoomId = pathname.replace('/teacher/chat', '').replace('/', '');

  const { data: groupdata } = useQuery(GET_TIMETABLE_PAGE);

  const Klass = groupdata?.klassGroups?.slice().sort((a: any, b: any) => {
    const aData = a?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);
    const bData = b?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);

    if (!aData || !bData) {
      return 0;
    }

    if (aData[1] && bData[1]) {
      if (aData[1] === bData[1]) {
        return Number(aData[2]) - Number(bData[2]);
      } else {
        return Number(aData[1]) - Number(bData[1]);
      }
    } else {
      return 0;
    }
  });

  const {
    error,
    data,
    refetch: refetchUser,
  } = selectedUserType <= 1
    ? uQ(`/groups?ids=${selKlassId}`, fetcher)
    : uQ(`/users/allteacher`, fetcher);

  useEffect(() => {
    let udata: UserDatas[] = [];

    if (data?.error) {
      alert(data?.error);
      return;
    }

    if (selectedUserType === 0) {
      if (data?.groupsByIds[0]) {
        const className = data?.groupsByIds[0]?.name;
        data?.groupsByIds[0]?.studentGroups.map(
          (student: StudentGroup, i: number) => {
            let aaa: UserDatas = {
              id: student?.user?.id,
              name: student?.user?.name,
              role: 'STUDENT',
              studNum5: makeStudNum5(
                (className ? className : '') +
                  (student?.studentNumber ? student?.studentNumber : ''),
              ),
            };
            udata.push(aaa);
          },
        );
        setSelectedGroup(udata);
      }
    } else if (selectedUserType === 1) {
      if (data?.groupsByIds[0]) {
        const className = data?.groupsByIds[0]?.name;
        data?.groupsByIds[0]?.studentGroups.map(
          (student: StudentGroup, i: number) => {
            if (student?.user?.parents && student?.user?.parents.length > 0) {
              let aaa: UserDatas = {
                id: student?.user?.parents[0]?.id,
                name: student?.user?.name + '보호자',
                role: 'PARENT',
                studNum5: makeStudNum5(
                  (className ? className : '') +
                    (student?.studentNumber ? student?.studentNumber : ''),
                ),
              };
              udata.push(aaa);
            }
          },
        );
        setSelectedGroup(udata);
      }
    } else if (selectedUserType === 2) {
      if (data) {
        data?.map((teachar: any, i: number) => {
          let aaa: UserDatas = {
            id: teachar.id,
            name: teachar.name,
            role: 'TEACHER',
            studNum5: teachar?.teacherGroups[0]?.group?.name
              ? teachar?.teacherGroups[0]?.group?.name
              : teachar.role === 'HEADPRINCIPAL'
              ? '교장선생님'
              : teachar.role === 'VICEPRINCIPAL'
              ? '교감선생님'
              : teachar.role === 'PRINCIPAL'
              ? '교무부장'
              : teachar.role === 'PREPRINCIPAL'
              ? '교무계'
              : teachar.role === 'HEAD'
              ? (teachar?.headNumber ? teachar?.headNumber : '') + '학년부장'
              : teachar.role === 'PREHEAD'
              ? (teachar?.headNumber ? teachar?.headNumber : '') + '학년계'
              : teachar.role === 'TEACHER'
              ? '선생님'
              : teachar.role === 'ADMIN'
              ? '관리자'
              : teachar.role === 'SECURITY'
              ? '보안관'
              : '',
          };
          if (me?.id !== teachar.id) {
            udata.push(aaa);
          }
        });
        setSelectedGroup(udata);
      }
    }
  }, [selectedUserType, data]);

  //const groupIDs: any[] = groups?.map((el: { id: any; }) => el.id) || [];

  // 메시지 전송
  const createChatRoom = () => {
    setLoading(true);

    let callCnt = userIds.length;

    userIds?.forEach((el) => {
      api
        .post(`/chatroom/create`, { content: content, attendeeId: el })
        .then((res) => {
          callCnt = callCnt - 1;

          if (!callCnt) {
            history.push(`/teacher/chat/${res.data.id}`);
            setSelectedMenu(false);
            setLoading(false);
          }
        })
        .catch((err) => {
          callCnt = callCnt - 1;

          alert('메시지 전송에 실패했습니다.');
          if (!callCnt) {
            setLoading(false);
          }
        });
    });
  };

  return (
    <>
      {/* Desktop V */}
      {isLoading && <Blank reversed />}
      {error && <ErrorBlank />}
      <div
        className={`col-span-3 h-screen ${
          !ChatRoomId || ChatRoomId === '' ? '' : 'hidden md:block'
        }`}
      >
        <div className="md:hidden">
          <div className="md:hidden block">
            <TopNavbar title="채팅" left={<BackButton />} />
          </div>
        </div>
        <div className="flex justify-between px-6 py-1 md:py-6">
          <h1 className="text-2xl font-semibold hidden md:block">채팅</h1>
        </div>
        <div className="flex px-6 pb-3">
          <Button
            tw={{
              backgroundColor:
                selectedMenu === false ? 'bg-brand-1' : 'bg-light_orange',
              color:
                selectedMenu === false ? 'text-light_orange' : 'text-brand-1',
              paddingY: 'py-1.5',
            }}
            onClick={() => {
              setSelectedMenu(false);
            }}
          >
            대화목록
          </Button>{' '}
          &nbsp;
          <Button
            tw={{
              backgroundColor:
                selectedMenu === false ? 'bg-light_orange' : 'bg-brand-1',
              color:
                selectedMenu === false ? 'text-brand-1' : 'text-light_orange',
              paddingY: 'py-1.5',
            }}
            onClick={() => {
              setSelectedMenu(true);
              setSelectedUsers([]);
              setSelectedUserType(-1);
              setSelKlassId(-1);

              setContent('');
              set_studentName('');
            }}
          >
            새 대화
          </Button>
        </div>
        <div className="h-screen-10 overflow-y-auto scroll-box">
          <div className="px-8">
            {/* Chat list */}
            {!selectedMenu && <ChatRoomList refetch={() => refetch()} />}

            {selectedMenu && (
              <Section>
                <div className="flex space-x-3 items-center">
                  <div className="min-w-max cursor-pointer mt-1">
                    <Select
                      value={selectedUserType}
                      tw={{
                        height: 'h-11',
                        fontSize: 'text-sm',
                      }}
                      onChange={(e) => {
                        setSelectedUserType(Number(e.target.value));
                        if (e.target.value === '2') {
                          setSelKlassId(-1);
                        }

                        refetchUser();
                      }}
                      placeholder="인원구분"
                    >
                      <option value={-1}>{'인원구분'}</option>
                      <option value={0}>{'학생'}</option>
                      <option value={1}>{'보호자'}</option>
                      <option value={2}>{'선생님'}</option>
                    </Select>
                  </div>
                  <div className="min-w-max cursor-pointer  mt-1">
                    <Select
                      value={selKlassId}
                      disabled={selectedUserType === 2}
                      tw={{
                        height: 'h-11',
                        fontSize: 'text-sm',
                      }}
                      onChange={(e) => setSelKlassId(Number(e.target.value))}
                      placeholder="반 선택"
                    >
                      <option value={-1}>{'반 선택'}</option>
                      {Klass?.map((group: Group) => (
                        <option value={group.id}>{group.name}</option>
                      ))}
                    </Select>
                  </div>
                  <div className="w-full cursor-pointer text-sm">
                    <div className="flex pt-3 pb-2 items-center space-x-2">
                      <form
                        className="items-center border-gray-200 rounded-md border flex justify-between h-11 px-2 w-full"
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <input
                          className="w-full"
                          disabled={selectedUserType !== 2 && selKlassId === -1}
                          placeholder="이름을 입력해 주세요."
                          value={_studentName}
                          onChange={(e) => {
                            set_studentName(e.target.value);
                            if (e.target.value === '') {
                              setStudentName('');
                            }
                          }}
                        />
                      </form>
                      <UploadIcon />
                    </div>
                  </div>
                </div>
              </Section>
            )}
            {selectedUserType !== 2 && selKlassId === -1 && selectedMenu && (
              <div className="text-center">인원구분과 반을 선택해주세요.</div>
            )}

            {(selectedUserType === 2 || selKlassId > -1) && selectedMenu && (
              <div className="w-full px-5 py-2">
                {selectedGroup && selectedGroup.length > 0 && (
                  <Checkbox
                    label="전체 선택"
                    checked={
                      !selectedGroup?.filter((el) => !userIds.includes(el.id))
                        .length
                    }
                    onChange={() =>
                      !selectedGroup?.filter((el) => !userIds.includes(el.id))
                        .length
                        ? setSelectedUsers(
                            selectedUsers.filter(
                              (el) =>
                                !selectedGroup
                                  ?.map((sg) => sg.id)
                                  .includes(el.id),
                            ),
                          )
                        : setSelectedUsers(
                            selectedUsers.concat(
                              selectedGroup
                                ?.filter(
                                  (el) =>
                                    !selectedUsers
                                      .map((u) => u.id)
                                      .includes(el.id),
                                )
                                .map((el) => el) || [],
                            ),
                          )
                    }
                  />
                )}
              </div>
            )}

            {(selectedUserType === 2 || selKlassId > -1) && selectedMenu && (
              <div className="w-full grid grid-flow-row grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 pr-4 pb-4 px-3">
                {selectedGroup
                  ?.slice()
                  ?.sort((a: any, b: any) => a.studNum5 - b.studNum5)
                  ?.filter(
                    (item: any) =>
                      _studentName === '' ||
                      item?.name.includes(_studentName) ||
                      item?.studNum5.includes(_studentName),
                  )
                  ?.map((student: UserDatas, i: number) => (
                    <div
                      className={`w-full border-2 py-1 md:py-3 px-3 md:m-2 rounded-lg cursor-pointer flex items-center justify-between ${
                        userIds.includes(student.id)
                          ? 'border-brand-1 bg-light_orange'
                          : ' border-grey-6'
                      }`}
                      key={student.id}
                      onClick={() => {
                        if (student) {
                          if (userIds.includes(student.id)) {
                            setSelectedUsers(
                              selectedUsers.filter((u) => u.id !== student.id),
                            );
                          } else {
                            setSelectedUsers(selectedUsers.concat(student));
                          }
                        }
                      }}
                    >
                      {
                        //selectedUserType !== 2 && (
                        <div className="font-bold">{student.studNum5}</div>
                      }
                      <div className="font-base whitespace-pre overflow-hidden">
                        {student.name}
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {selectedMenu && (
              <Section>
                <div className="">
                  <Label text="선택된 대화상대" />
                  <div className="flex flex-wrap mt-2">
                    {selectedUsers.map((el) => (
                      <div
                        className={`flex items-center space-x-2 w-max rounded-full px-2.5 py-1.5 m-1s mr-2 mt-2 bg-white border-2 whitespace-nowrap text-2sm font-bold cursor-pointer 
                          ${
                            el.role === 'TEACHER'
                              ? 'border-black text-black'
                              : el.role === 'PARENT'
                              ? 'border-brandblue-1 text-brandblue-1'
                              : 'border-brand-1 text-brand-1'
                          }

                        `}
                        onClick={() => {
                          setSelectedUsers(
                            selectedUsers.filter((u) => u.id !== el.id),
                          );
                        }}
                      >
                        <div className="whitespace-pre">{el.name}</div>
                        <Close />
                      </div>
                    ))}
                  </div>
                </div>
              </Section>
            )}
            {selectedMenu && <Divider />}

            {selectedMenu && (
              <Section>
                <TextArea
                  label="메시지"
                  placeholder="메시지 입력"
                  onChange={(e) => setContent(e.target.value)}
                  value={content}
                />
              </Section>
            )}
            {selectedMenu && (
              <div className="text-center my-6">
                <Button
                  text="전송"
                  tw={{
                    height: 'h-11',
                    backgroundColor:
                      !content || !selectedUsers.length
                        ? 'bg-gray-300'
                        : 'bg-brand-1',
                  }}
                  disabled={!content || !selectedUsers.length}
                  style={{ width: '70%' }}
                  onClick={() => {
                    setLoading(true);
                    createChatRoom();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {ChatRoomId && ChatRoomId !== '' && (
        <div
          className={`bg-gray-50 col-span-3 p-0 md:p-6 h-screen overflow-y-scroll  scroll-box`}
        >
          <ChatDetailPage id={ChatRoomId} />
          {/* <Switch>
          <Route
            path="/teacher/studentcard/:id"
            component={() => (
              <StudentCardDetailPage refetch={() => refetch()} />
            )}
          />
        </Switch> */}
        </div>
      )}

      {/* <div className="col-span-3 bg-white p-6 overflow-y-auto hidden md:block">
        <Switch>
          <Route
            path="/teacher/chat/:id"
            component={() => <ChatDetailPage chatRefetch={() => refetch()} />}
          />
        </Switch>
      </div> */}
    </>
  );
};

export default ChatListPage;
