import { useEffect, useMemo, useState } from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { useQuery as uQ } from 'react-query';
import {
  Blank,
  useQueryString,
  useQuery,
  TopNavbar,
  BackButton,
} from '../../../reusable';
import { Board, GetPaginatedBoards, Group, TeacherGroup } from '../../../types';
import BoardDetailPage from './BoardDetailPage';
import { BoardAddPage } from './BoardAddPage';
import { fetcher } from '../../../plugins';
import {
  BoardCard,
  SelectMenus,
  ErrorBlank,
  FrontPagination,
} from '../../../components';
import { BoardEditPage } from './BoardEditPage';
import { GET_TEACHER_BOARD_GROUPS } from '../../../query';

const categories = ['조종례', '알림', '오늘의 한마디', '자료', '기타'];

export const BoardsPage = () => {
  const { pathname, search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const { data } = useQuery(GET_TEACHER_BOARD_GROUPS, {
    fetchPolicy: 'cache-and-network',
  });

  const groups = data?.klassGroups?.slice().sort((a: any, b: any) => {
    const aData = a?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);
    const bData = b?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);

    if (!aData || !bData) {
      return 0;
    }

    if (aData[1] && bData[1]) {
      if (aData[1] === bData[1]) {
        return Number(aData[2]) - Number(bData[2]);
      } else {
        return Number(aData[1]) - Number(bData[1]);
      }
    } else {
      return 0;
    }
  });

  const homeKlass = groups?.filter((g: Group) =>
    g.teacherGroups?.some((tg: TeacherGroup) => tg.subject === '우리반'),
  )[0];

  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [selectedCategory, setSelectedCategory] = useState<any>();

  const [page, setPage] = useState<number>(Number(params.get('page') ?? '1'));
  const limit = Number(params.get('limit') ?? '10');

  useEffect(() => {
    setPage(Number(params.get('page') ?? '1'));
  }, [params]);

  useEffect(() => {
    if (!selectedGroup && groups?.length) {
      if (homeKlass) {
        setSelectedGroup(homeKlass);
      } else {
        setSelectedGroup(groups[0]);
      }
    }
  }, [groups]);

  const queryString = useQueryString({
    page: page,
    limit,
    defaultLimit: limit,
    groupId: selectedGroup?.id,
    category: selectedCategory,
  });

  const {
    data: boardData,
    error,
    refetch,
    isLoading,
  } = uQ<GetPaginatedBoards>(`/boards${queryString}`, fetcher, {
    enabled: !!selectedGroup,
  });

  const boards = selectedCategory
    ? boardData?.items.filter((el: Board) => el.category === selectedCategory)
    : boardData?.items;

  return (
    <>
      {isLoading && <Blank reversed />}
      {error && <ErrorBlank />}
      <div className="col-span-2 h-screen hidden md:block">
        <div className="flex justify-between px-6 pt-6 pb-3 place-items-center">
          <h1 className="text-2xl font-semibold">학급게시판</h1>

          <Link to="/teacher/board/add">
            <button className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-4 py-2 focus:outline-none">
              추가하기
            </button>
          </Link>
        </div>

        <div className="flex px-6 pt-3 pb-6 items-center space-x-2">
          <div className="cursor-pointer">
            <SelectMenus
              items={groups}
              value={selectedGroup}
              onChange={(value: any) => {
                setSelectedGroup(value);
              }}
            />
          </div>
          <div className="cursor-pointer">
            <SelectMenus
              items={categories}
              value={selectedCategory}
              allText="전체"
              onChange={(group: any) => setSelectedCategory(group)}
              allTextVisible
            />
          </div>
        </div>
        <div className="h-0.5 bg-gray-100 scroll-box"></div>
        <div className="h-screen-5 pb-20 overflow-y-auto scroll-box">
          {boards?.map((board: Board) => (
            <BoardCard board={board} page={page} limit={limit} />
          ))}

          {boardData && boardData?.total > limit && (
            <div className="grid place-items-center">
              <FrontPagination
                basePath="/teacher/board"
                total={boardData.total}
                limit={limit}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </div>
      </div>

      <div
        className={`bg-gray-50 col-span-4 p-0 scroll-box overflow-y-scroll h-screen-4 md:h-screen`}
      >
        <Switch>
          <Route
            path="/teacher/board/add"
            render={() => (
              <BoardAddPage
                refetch={() => refetch()}
                homeKlass={homeKlass}
                groupData={groups}
              />
            )}
          />
          <Route
            path="/teacher/board/:id/edit"
            render={() => <BoardEditPage refetch={() => refetch()} />}
          />
          <Route
            path="/teacher/board/:id"
            render={() => (
              <BoardDetailPage
                refetch={() => refetch()}
                page={page}
                limit={limit}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
};
