import { gql } from '@apollo/client';
import { Notice } from './types';

export const GET_ME = gql`
  query {
    me {
      id
      name
      nickname
      phone
      email
      userType
    }
  }
`;

export const GET_NEWSLETTER_ADD_PAGE = gql`
  query {
    me {
      id
      name
      nokName
      nokPhone
    }
  }
`;

export interface GetNoticesType {
  notices: Notice[];
}

export const GET_NOTICES = gql`
  query {
    notices {
      id
      title
      createdAt
      category
      user {
        name
      }
    }
    me {
      canEditNotice
    }
  }
`;

export const GET_KLASSGROUP_NAME = gql`
  query {
    me {
      klassGroup {
        group {
          id
          name
        }
      }
    }
  }
`;

export const GET_NOTICE = gql`
  query ($id: Int!) {
    notice(id: $id) {
      id
      title
      content
      images
      files
      createdAt
    }
    me {
      canEditNotice
    }
  }
`;

export const GET_NEWSLETTERS = gql`
  query {
    newsletters {
      id
      title
      type
      createdAt
      isPublished
      category
    }
    me {
      canEditNewsletter
    }
  }
`;

export const PUBLISH_NEWSLETTER = gql`
  mutation ($id: Int!) {
    publishNewsletter(id: $id) {
      id
    }
  }
`;

export const GET_NEWSLETTER = gql`
  query ($id: Int!) {
    me {
      nokPhone
      nokName
    }
    newsletter(id: $id) {
      id
      title
      content
      createdAt
      type
      images
      files
      surveyContent
      studentNewsletter {
        isSubmitted
        content
        parentSignature
      }
    }
  }
`;

export const GET_NEWSLETTER_BY_TEACHER = gql`
  query ($id: Int!) {
    newsletter(id: $id) {
      id
      title
      content
      createdAt
      type
      images
      files
      klasses
      surveyContent
      isPublished
    }
  }
`;

export const GET_NEWSLETTER_SUBMITTERS = gql`
  query ($id: Int!) {
    newsletter(id: $id) {
      id
      title
      content
      createdAt
      updatedAt
      surveyContent
      studentNewsletters {
        id
        isSubmitted
        studentGradeKlass
        studentNumber
        student {
          name
        }
      }
    }
  }
`;

export const GET_ACTIVITIES = gql`
  query {
    me {
      studentGroups {
        group {
          id
          name
          type
          groupActivities {
            activity {
              id
              title
              subject
              type
              endDate
              createdAt
              isRecord
              studentActivity {
                id
                isSubmitted
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ACTIVITIES_DETAIL = gql`
  query ($id: Int!) {
    activity(id: $id) {
      id
      title
      content
      type
      images
      files
      subject
      endDate
      createdAt
      groupActivities {
        group {
          id
          name
        }
      }
    }
  }
`;

export const GET_ACTIVITY_DETAIL_PAGE = gql`
  query ($id: Int!) {
    me {
      id
    }
    activity(id: $id) {
      id
      title
      content
      subject
      images
      files
      type
      endDate
      createdAt
      updatedAt
      isRecord
      studentActivity {
        id
        content
        images
        files
        isSubmitted
        updatedAt
        comments {
          id
          content
          updatedAt
          user {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_ACTIVITY_SUBMIT_DETAIL_PAGE = gql`
  query ($id: Int!) {
    me {
      id
    }
    studentActivity(id: $id) {
      id
      content
      images
      files
      isSubmitted
      updatedAt
      comments {
        id
        content
        updatedAt
        user {
          id
          name
        }
      }
    }
  }
`;

export const GET_STUDENT_NEWSLETTER = gql`
  query ($id: Int!) {
    me {
      id
    }
    studentNewsletter(id: $id) {
      id
      content
      images
      files
      isSubmitted
      updatedAt
    }
  }
`;

export const GET_TEACHER_BOARD_GROUPS = gql`
  query {
    klassGroups {
      id
      name
      teacherGroups {
        subject
      }
    }
  }
`;

// interface SignupInput {
//   email: string;
//   password: string;
//   name: string;
// }

export const STUDENT_SIGNUP = gql`
  mutation (
    $email: String!
    $password: String!
    $name: String!
    $groupId: Int!
    $studentNumber: Int!
    $schoolId: Int!
    $phone: String!
    $hopePath: String!
    $hopeMajor: String!
  ) {
    studentSignup(
      input: {
        email: $email
        password: $password
        name: $name
        phone: $phone
        hopePath: $hopePath
        hopeMajor: $hopeMajor
      }
      groupId: $groupId
      studentNumber: $studentNumber
      schoolId: $schoolId
    ) {
      token
    }
  }
`;

export const PARENT_SIGNUP = gql`
  mutation (
    $email: String!
    $password: String!
    $name: String!
    $phone: String!
    $schoolId: Int!
  ) {
    parentSignup(
      input: { email: $email, password: $password, name: $name, phone: $phone }
      schoolId: $schoolId
    ) {
      token
    }
  }
`;

export const SEND_PARENT_SIGNUP = gql`
  mutation {
    sendParentSignup {
      id
    }
  }
`;

export const SEND_PARENT_SIGNUP_WITH_STUDENT = gql`
  mutation ($studentId: Int!) {
    sendParentSignupWithStudentId(studentId: $studentId) {
      id
    }
  }
`;

export const TEACHER_SIGNUP = gql`
  mutation (
    $email: String!
    $password: String!
    $name: String!
    $schoolId: Int!
  ) {
    teacherSignup(
      input: { email: $email, password: $password, name: $name }
      schoolId: $schoolId
    ) {
      token
    }
  }
`;

export const LOGIN = gql`
  mutation ($password: String!, $email: String!) {
    login(password: $password, email: $email) {
      token
    }
  }
`;

export const CREATE_OR_UPDATE_STUDENT_ACTIVITY = gql`
  mutation CreateOrUpdateStudentActivity(
    $data: String!
    $images: Upload
    $files: Upload
  ) {
    createOrUpdateStudentActivity(data: $data, images: $images, files: $files) {
      id
      activity {
        id
      }
      user {
        id
      }
    }
  }
`;

export const CREATE_OR_UPDATE_STUDENT_NEWSLETTER = gql`
  mutation CreateOrUpdateStudentNewsletter($data: String!) {
    createOrUpdateStudentNewsletter(data: $data) {
      id
    }
  }
`;

export const CREATE_OR_UPDATE_STUDENT_ACTIVITY_BY_TEACHER = gql`
  mutation CreateOrUpdateStudentActivityByTeacher($data: String!) {
    createOrUpdateStudentActivityByTeacher(data: $data) {
      id
    }
  }
`;

// export const UPDATE_ACTIVITY = gql``;

export const GET_TEACHER_GROUPS = gql`
  query {
    me {
      teacherGroups {
        subject
        group {
          id
          name
          type
          groupActivities {
            activity {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_GROUP_ACTIVITIES = gql`
  query ($groupId: Int!) {
    groupActivitiesByGroupId(groupId: $groupId) {
      activity {
        id
        title
        type
        subject
        endDate
        createdAt
      }
    }
  }
`;

export const GET_SUBMITTERS = gql`
  query ($id: Int!, $groupId: Int!) {
    activity(id: $id) {
      id
      title
      type
      content
      subject
      endDate
      createdAt
      updatedAt
    }
    group(id: $groupId) {
      studentGroups {
        id
        studentNumber
        user {
          id
          name
          studentActivity(activityId: $id) {
            id
            isSubmitted
          }
        }
      }
    }
  }
`;

export const GET_GROUPS = gql`
  query {
    me {
      teacherGroups {
        subject
        group {
          id
          name
          type
          school {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_STUDENT_GROUPS = gql`
  query {
    me {
      teacherGroups {
        id
        group {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_FEEDBACK = gql`
  mutation ($id: Int!, $feedback: String!) {
    updateFeedback(id: $id, feedback: $feedback) {
      id
    }
  }
`;

export const FIND_ID_PAGE = gql`
  query {
      schools(first:200) {
        id
        name
        klassGroups {
          id
          name
        }
      }
    }
`;

export const GET_SIGNUP_GROUPS = gql`
  query {
    schools {
      id
      name
    }
    me {
      name
      email
      firstVisit
      nokName
      nokPhone
      birthDate
      school {
        name
        privacyPolicyLink
      }
      klassGroup {
        studentNumber
        group {
          id
          name
          year
        }
      }
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation ($said: Int!, $content: String!) {
    createComment(said: $said, content: $content) {
      id
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation ($id: Int!, $content: String!) {
    updateComment(id: $id, content: $content) {
      id
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation ($id: Int!) {
    deleteComment(id: $id)
  }
`;

export const CREATE_ACTIVITY = gql`
  mutation ($data: String!, $images: Upload, $files: Upload) {
    createActivity(data: $data, images: $images, files: $files) {
      id
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation ($id: Int!, $data: String!, $images: Upload, $files: Upload) {
    updateActivity(id: $id, data: $data, images: $images, files: $files) {
      id
    }
  }
`;

export const DELETE_ACTIVITY = gql`
  mutation ($id: Int!) {
    deleteActivity(id: $id)
  }
`;

export const DELETE_NOTICE = gql`
  mutation ($id: Int!) {
    deleteNotice(id: $id)
  }
`;

export const GET_SCHOOLS = gql`
  query {
    schools {
      id
      name
    }
  }
`;

export const UPDATE_ME = gql`
  mutation ($data: String!) {
    updateMe(data: $data) {
      id
    }
  }
`;

export const GET_GROUP_PAGE = gql`
  query {
      klassGroups {
        id
        name
        type
        studentGroups {
          id
        }
      }
      me {
        teacherGroups {
          group {
            id
            name
            type
            studentGroups {
              id
            }
          }
        }
      }
  }
`;

export const GET_GROUP_ADD_PAGE = gql`
  query ($ids: String!) {
    groupsByIds(ids: $ids) {
      id
      name
      type
      studentGroups {
        studentNumber
        user {
          id
          name
        }
      }
    }
  }
`;
export const GET_GROUP_DETAIL_PAGE = gql`
  query ($id: Int!) {
    group(id: $id) {
      id
      name
      type
      studentGroups {
        studentNumber
        user {
          id
          name
        }
      }
      groupActivities {
        id
      }
    }
  }
`;

export const GET_BOARD_DETAIL = gql`
  query ($id: Int!) {
    board(id: $id) {
      id
      title
      category
      content
      images
      files
      uuid
      createdAt
    }
  }
`;
export const CREATE_GROUP = gql`
  mutation ($name: String!, $data: String!) {
    createGroup(name: $name, data: $data) {
      id
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation ($id: Int!, $name: String!, $data: String!) {
    updateGroup(id: $id, name: $name, data: $data) {
      id
    }
  }
`;

export const GET_ACTIVITY_DOWNLOAD_PAGE = gql`
  query ($id: Int!, $groupId: Int!) {
    activity(id: $id) {
      id
      title
      type
      content
      subject
      endDate
      createdAt
    }
    group(id: $groupId) {
      name
      studentGroups {
        id
        studentNumber
        user {
          id
          name
          klassGroup {
            studentNumber
            group {
              id
              name
            }
          }
          studentActivity(activityId: $id) {
            id
            isSubmitted
            content
          }
        }
      }
    }
  }
`;

export const GET_NEWSLETTER_DOWNLOAD_PAGE = gql`
  query ($id: Int!) {
    newsletter(id: $id) {
      id
      title
      content
      createdAt
      type
      images
      files
      surveyContent
      studentNewsletters {
        id
        content
        isSubmitted
        updatedAt
        content
        studentGradeKlass
        studentNumber
        studentNewsletterStatus
        student {
          name
        }
      }
    }
  }
`;

export const SURVEY_FILES_UPLOAD = gql`
  mutation ($files: Upload) {
    surveyFilesUpload(files: $files)
  }
`;

export const GET_CANTEEN_BY_DATE = gql`
  query ($date: String!) {
    canteenByDate(date: $date) {
      i
      lunch
      dinner
      image
    }
  }
`;

export const GET_CANTEEN_BY_YEAR_MONTH = gql`
  query ($year: Int!, $month: Int!) {
    canteensByYearMonth(year: $year, month: $month) {
      id
      date
      lunch
      dinner
      image
      canteenComments {
        id
        content
        user {
          id
          name
          profile
          customProfile
        }
      }
    }
  }
`;

export const CREATE_CANTEEN = gql`
  mutation ($data: String!, $image: Upload) {
    createCanteen(data: $data, image: $image) {
      id
    }
  }
`;

export const UPDATE_CANTEEN = gql`
  mutation ($data: String!, $image: Upload) {
    updateCanteen(data: $data, image: $image) {
      id
    }
  }
`;

export const GET_COMMENT_BY_CANTEEN_ID = gql`
  query ($canteenId: Int!) {
    canteenCommentsByCanteenId(canteenId: $canteenId) {
      id
      content
      user {
        id
        name
        profile
        customProfile
      }
    }
  }
`;

export const CREATE_CANTEEN_COMMENT = gql`
  mutation ($canteenId: Int!, $content: String!) {
    createCanteenComment(canteenId: $canteenId, content: $content) {
      id
    }
  }
`;

export const UPDATE_CANTEEN_COMMENT = gql`
  mutation ($content: String!, $id: Int!) {
    updateCanteenComment(content: $content, id: $id) {
      id
    }
  }
`;

export const DELETE_CANTEEN_COMMENT = gql`
  mutation ($id: Int!) {
    deleteCanteenComment(id: $id)
  }
`;

export const GET_TIMETABLE_BY_YEAR_SEMESTER_GROUP = gql`
  query ($year: Int!, $semester: Int!, $groupId: Int!) {
    timetableByYearSemesterGroup(
      year: $year
      semester: $semester
      groupId: $groupId
    ) {
      id
      content
    }
    me {
      canEditTimetable
    }
  }
`;

export const GET_TIMETABLE_PAGE = gql`
  query {
    klassGroups {
      id
      name
    }
  }
`;

export const CREATE_TIMETABLE = gql`
  mutation ($data: String!) {
    createTimetable(data: $data) {
      id
    }
  }
`;

export const UPDATE_TIMETABLE = gql`
  mutation ($data: String!) {
    updateTimetable(data: $data) {
      id
    }
  }
`;

export const GET_STUDENT_CANTEEN_PAGE = gql`
  query ($year: Int!, $month: Int!) {
    me {
      studentGroups {
        group {
          id
          type
        }
      }
    }
    canteensByYearMonth(year: $year, month: $month) {
      id
      date
      lunch
      dinner
      image
    }
    schedulesByYearMonth(year: $year, month: $month) {
      title
      category
      start
      end
      isAllDay
      dueDateClass
    }
  }
`;

export const CREATE_ABSENT = gql`
  mutation ($data: String!, $images: Upload, $signature: String!) {
    createAbsent(data: $data, images: $images, signature: $signature) {
      id
    }
  }
`;

export const UPDATE_ABSENT = gql`
  mutation ($data: String!, $images: Upload) {
    updateAbsent(data: $data, images: $images) {
      id
    }
  }
`;

export const UPDATE_ABSENT_BY_TEACHER = gql`
  mutation ($data: String!) {
    updateAbsentByTeacher(data: $data) {
      id
    }
  }
`;

export const APPROVE_MULTIPLE_ABSENTS = gql`
  mutation ($data: String!) {
    approveAbsentsByTeacher(data: $data) {
      id
    }
  }
`;

export const GET_ABSENTS_BY_STUDENTS = gql`
  query {
    absentsByMe {
      id
      startAt
      endAt
      absentStatus
      reportedAt
      reportType
    }
  }
`;

export const GET_ABSENT_DETAIL = gql`
  query ($absentId: Int!) {
    me {
      id
      nokName
    }
    absentById(absentId: $absentId) {
      id
      reportType
      absentStatus
      reason
      startAt
      endAt
      description
      notApprovedReason
      deleteReason
      evidenceType
      evidenceFiles
      teacher {
        id
        name
      }
    }
  }
`;

export const GET_ABSENTS_BY_TEACHER = gql`
  query ($startDate: String!, $endDate: String!) {
    me {
      stamp
      role
      headNumber
    }
    absentsByTeacher(startDate: $startDate, endDate: $endDate) {
      id
      reportType
      absentStatus
      reason
      startAt
      endAt
      description
      evidenceType
      evidenceFiles
      reportedAt
      submitted
      niceSubmitted
      updatedAt
      updateReason
      uuid
      studentGradeKlass
      studentNumber
      studentSignature
      parentSignature
      parentComment
      teacherSignature
      preHeadSignature
      headSignature
      prePrincipalSignature
      principalSignature
      student {
        name
        nokName
        klassGroup {
          group {
            name
          }
        }
      }
    }
  }
`;

export const GET_ABSENT_DETAIL_BY_TEACHER = gql`
  query ($absentId: Int!) {
    absentByIdByTeacher(absentId: $absentId) {
      id
      reportType
      absentStatus
      reason
      startAt
      endAt
      description
      evidenceType
      evidenceFiles
      reportedAt
      uuid
      studentGradeKlass
      studentNumber
      studentSignature
      parentSignature
      teacherSignature
      preHeadSignature
      headSignature
      prePrincipalSignature
      principalSignature
      student {
        name
        nokName
      }
    }
  }
`;

export const GET_ABSENT_BY_UUID = gql`
  query ($uuid: String!) {
    absentByUUID(uuid: $uuid) {
      student {
        nokName
        name
        nokPhone
      }
      studentGradeKlass
      studentNumber
      reportType
      reason
      startAt
      endAt
      description
      evidenceType
      evidenceFiles
      reportedAt
      absentStatus
      parentSignature
      parentComment
      teacherSignature
      preHeadSignature
      headSignature
      prePrincipalSignature
      principalSignature
    }
  }
`;

export const GET_STUDENTNEWSLETTER_BY_UUID = gql`
  query ($uuid: String!) {
    studentNewsletterByUUID(uuid: $uuid) {
      student {
        nokName
        name
        nokPhone
        school {
          name
        }
      }
      newsletter {
        title
        content
        surveyContent
        images
        files
      }
      studentGradeKlass
      studentNumber
      content
      images
      files
      parentSignature
    }
  }
`;

export const REQUEST_SIGN_ABSENT_BY_PARENT = gql`
  mutation ($uuid: String!, $comment: String!, $signature: String!) {
    signAbsentByParent(uuid: $uuid, comment: $comment, signature: $signature) {
      id
    }
  }
`;

export const APPROVE_STUDENT_NEWSLETTER = gql`
  mutation ($uuid: String!, $signature: String!) {
    approveStudentNewsletter(uuid: $uuid, signature: $signature) {
      id
    }
  }
`;

export const APPROVE_ABSENT_BY_PARENT = gql`
  mutation ($uuid: String!) {
    approveAbsentByParent(uuid: $uuid) {
      id
    }
  }
`;

export const APPROVE_ABSENT = gql`
  mutation ($absentId: Int!, $signature: String!) {
    approveAbsentByTeacher(absentId: $absentId, signature: $signature) {
      id
    }
  }
`;

export const DENY_ABSENT = gql`
  mutation ($absentId: Int!, $notApprovedReason: String!) {
    denyAbsentByTeacher(
      absentId: $absentId
      notApprovedReason: $notApprovedReason
    ) {
      id
    }
  }
`;

export const DENY_FIELDTRIP = gql`
  mutation ($fieldtripId: Int!, $notApprovedReason: String!) {
    denyFieldtripByTeacher(
      fieldtripId: $fieldtripId
      notApprovedReason: $notApprovedReason
    ) {
      id
    }
  }
`;

export const DENY_OUTING = gql`
  mutation ($outingId: Int!, $notApprovedReason: String!) {
    denyOutingByTeacher(
      outingId: $outingId
      notApprovedReason: $notApprovedReason
    ) {
      id
    }
  }
`;

export const FIND_PASSWORD = gql`
  mutation ($email: String!, $name: String!, $phone: String!, $isMail: Boolean!) {
    findPassword(email: $email, name: $name, phone: $phone, isMail: $isMail) {
      id
      phone
    }
  }
`;

export const FIND_ID = gql`
  mutation ($userId: Int!) {
    findId(userId: $userId) {
      name
    }
  }
`;

export const GET_ABSENT_ADD_PAGE = gql`
  query Me {
    me {
      id
      name
      email
      role
      nokName
      klassGroup {
        id
        studentNumber
        group {
          id
          name
        }
      }
    }
  }
`;

export const RESET_NEW_PASSWORD = gql`
  mutation ($token: String!, $newPassword: String!) {
    resetNewPassword(token: $token, newPassword: $newPassword) {
      id
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation ($id: Int!) {
    deleteGroup(id: $id)
  }
`;

export const GET_OUTINGS_BY_TEACHER = gql`
  query ($startDate: String!, $endDate: String!) {
    me {
      role
      stamp
    }
    outingsByTeacher(startDate: $startDate, endDate: $endDate) {
      id
      type
      reason
      startAt
      endAt
      reportedAt
      updatedAt
      updateReason
      studentGradeKlass
      studentNumber
      teacherSignature
      outingStatus
      notApprovedReason
      student {
        name
        nokName
        profile
      }
      teacher {
        name
      }
    }
  }
`;

export const APPROVE_MULTIPLE_OUTINGS = gql`
  mutation ($data: String!) {
    approveOutingsByTeacher(data: $data) {
      id
    }
  }
`;

export const APPROVE_OUTING = gql`
  mutation ($outingId: Int!, $signature: String!) {
    approveOutingByTeacher(outingId: $outingId, signature: $signature) {
      id
    }
  }
`;

export const UPDATE_STAMP = gql`
  mutation ($image: Upload) {
    updateStamp(image: $image) {
      id
    }
  }
`;

export const UPDATE_OUTING_BY_TEACHER = gql`
  mutation ($data: String!, $outingId: Int!) {
    updateOutingByTeacher(data: $data, outingId: $outingId) {
      id
    }
  }
`;

export const UPDATE_OUTING = gql`
  mutation ($data: String!, $outingId: Int!) {
    updateOuting(data: $data, outingId: $outingId) {
      id
    }
  }
`;

export const CREATE_NOTICE = gql`
  mutation ($data: String!, $images: Upload, $files: Upload) {
    createNotice(data: $data, images: $images, files: $files) {
      id
    }
  }
`;


export const UPDATE_NOTICE = gql`
  mutation ($data: String!, $images: Upload, $files: Upload) {
    updateNotice(data: $data, images: $images, files: $files) {
      id
    }
  }
`;

export const CREATE_NEWSLETTER = gql`
  mutation ($data: String!, $images: Upload, $files: Upload) {
    createNewsletter(data: $data, images: $images, files: $files) {
      id
    }
  }
`;

export const UPDATE_NEWSLETTER = gql`
  mutation ($id: Int!, $data: String!, $images: Upload, $files: Upload) {
    updateNewsletter(id: $id, data: $data, images: $images, files: $files) {
      id
    }
  }
`;

export const CREATE_BOARD = gql`
  mutation ($data: String!, $images: Upload, $files: Upload) {
    createBoard(data: $data, images: $images, files: $files) {
      id
    }
  }
`;

export const UPDATE_BOARD = gql`
  mutation ($id: Int!, $data: String!, $images: Upload, $files: Upload) {
    updateBoard(id: $id, data: $data, images: $images, files: $files) {
      id
    }
  }
`;

export const DELETE_NEWSLETTER = gql`
  mutation ($id: Int!) {
    deleteNewsletter(id: $id) {
      id
    }
  }
`;

export const DELETE_APPREAL_ABSENT = gql`
  mutation ($absentId: Int!, $deleteReason: String!) {
    deleteAppealAbsentByTeacher(
      absentId: $absentId
      deleteReason: $deleteReason
    ) {
      id
    }
  }
`;

export const DELETE_APPREAL_ABSENTS = gql`
  mutation ($data: String!) {
    deleteAppealAbsentsByTeacher(data: $data) {
      id
    }
  }
`;

export const DELETE_APPREAL_OUTING = gql`
  mutation ($outingId: Int!, $deleteReason: String!) {
    deleteAppealOutingByTeacher(
      outingId: $outingId
      deleteReason: $deleteReason
    ) {
      id
    }
  }
`;

export const DELETE_ABSENT = gql`
  mutation ($absentId: Int!) {
    deleteAbsent(absentId: $absentId) {
      id
    }
  }
`;

export const DELETE_OUTING = gql`
  mutation ($outingId: Int!) {
    deleteOuting(outingId: $outingId) {
      id
    }
  }
`;

export const CREATE_FIELD_TRIP = gql`
  mutation ($data: String!, $signature: String!) {
    createFieldTrip(data: $data, signature: $signature) {
      id
    }
  }
`;

export const GET_FIELDTRIP_PAGE = gql`
  query Me {
    me {
      remainDaysOfFieldtrip
      school {
        fieldtripDays
        approvalLineF
      }
    }
    fieldtripsByStudent {
      id
      type
      startAt
      endAt
      fieldtripStatus
      fieldtripResultStatus
      resultFile
      resultText
    }
  }
`;

export const GET_FIELDTRIP_DETAIL = gql`
  query ($fieldtripId: Int!) {
    fieldtripById(fieldtripId: $fieldtripId) {
      student {
        nokName
        name
        nokPhone
        school {
          name
          approvalLineF
        }
      }
      teacher {
        id
        name
      }
      id
      parentSignature
      preHeadSignature
      headSignature
      teacherSignature
      prePrincipalSignature
      principalSignature
      viceprincipalSignature
      headprincipalSignature
      preHeadResultSignature
      headResultSignature
      teacherResultSignature
      prePrincipalResultSignature
      principalResultSignature
      viceprincipalResultSignature
      headprincipalResultSignature
      studentNumber
      accommodation
      studentGradeKlass
      type
      startAt
      endAt
      guideName
      guidePhone
      destination
      fieldtripStatus
      fieldtripResultStatus
      deleteReason
      notApprovedReason
      resultText
      resultFile
      resultFiles
      relationship
      content
      currentRemainDays
      usedDays
      purpose
      resultTitle
    }
  }
`;

export const GET_FIELDTRIP_ADD_PAGE = gql`
  query ($startAt: String!, $endAt: String!) {
    me {
      id
      nokName
      nokPhone
      phone
      remainDaysOfFieldtrip
    }
    rejectSchedulesByDate(startAt: $startAt, endAt: $endAt) {
      id
      start
      end
      isAllDay
      attendee
    }
  }
`;

export const GET_FIELDTRIP_BY_UUID = gql`
  query ($uuid: String!) {
    fieldtripByUUID(uuid: $uuid) {
      student {
        nokName
        name
        nokPhone
        school {
          name
          fieldtripDays
        }
      }
      teacher {
        id
        name
      }
      parentSignature
      teacherSignature
      studentNumber
      studentGradeKlass
      type
      startAt
      endAt
      guideName
      guidePhone
      destination
      relationship
      content
      currentRemainDays
      usedDays
      purpose
      form
    }
  }
`;

export const GET_FIELDTRIP_RESULT_BY_UUID = gql`
  query ($uuid: String!) {
    fieldtripByUUID(uuid: $uuid) {
      student {
        nokName
        name
        nokPhone
        school {
          name
        }
      }
      parentResultSignature
      studentGradeKlass
      type
      startAt
      endAt
      form
      purpose
      usedDays
      guideName
      guidePhone
      destination
      relationship
      resultText
      resultTitle
      resultFile
      content
    }
  }
`;

export const SIGN_FIELDTRIP_BY_PARENT = gql`
  mutation ($uuid: String!, $signature: String!) {
    signFieldtripByParent(uuid: $uuid, signature: $signature) {
      id
    }
  }
`;

export const SIGN_FIELDTRIP_RESULT_BY_PARENT = gql`
  mutation ($uuid: String!, $signature: String!) {
    signFieldtripResultByParent(uuid: $uuid, signature: $signature) {
      id
    }
  }
`;

export const GET_FIELDTRIPS_BY_TEACHER = gql`
  query ($startDate: String!, $endDate: String!) {
    me {
      stamp
      role
      headNumber
      school {
        fieldtripDays
      }
    }
    fieldtripsByTeacher(startDate: $startDate, endDate: $endDate) {
      id
      startAt
      endAt
      destination
      accommodation
      uuid
      studentGradeKlass
      studentNumber
      studentSignature
      parentSignature
      headSignature
      teacherSignature
      fieldtripStatus
      content
      type
      form
      purpose
      relationship
      guideName
      guidePhone
      currentRemainDays
      usedDays
      noticedAt
      reportedAt
      resultFile
      resultText
      preHeadSignature
      prePrincipalSignature
      principalSignature
      viceprincipalSignature
      headprincipalSignature
      notApprovedReason
      updatedAt
      teacher {
        name
      }
      student {
        name
        nokName
        nokPhone
        phone
        klassGroup {
          group {
            name
          }
        }
      }
    }
  }
`;

export const GET_FIELDTRIP_NOTICES_BY_TEACHER = gql`
  query ($startDate: String!, $endDate: String!) {
    me {
      stamp
      role
      headNumber
      school {
        fieldtripDays
      }
    }
    fieldtripNoticesByTeacher(startDate: $startDate, endDate: $endDate) {
      id
      startAt
      endAt
      destination
      accommodation
      uuid
      studentGradeKlass
      studentNumber
      studentSignature
      parentSignature
      headSignature
      teacherSignature
      fieldtripStatus
      content
      type
      form
      relationship
      guideName
      guidePhone
      currentRemainDays
      usedDays
      noticedAt
      reportedAt
      resultFile
      resultText
      preHeadSignature
      prePrincipalSignature
      principalSignature
      viceprincipalSignature
      headprincipalSignature
      teacher {
        name
      }
      student {
        name
        nokName
        nokPhone
        phone
        klassGroup {
          group {
            name
          }
        }
      }
    }
  }
`;

export const GET_FIELDTRIP_RESULTS_BY_TEACHER = gql`
  query ($startDate: String!, $endDate: String!) {
    me {
      stamp
      role
      headNumber
    }
    fieldtripResultsByTeacher(startDate: $startDate, endDate: $endDate) {
      id
      startAt
      endAt
      destination
      accommodation
      uuid
      studentGradeKlass
      studentNumber
      studentResultSignature
      parentResultSignature
      preHeadResultSignature
      headResultSignature
      teacherResultSignature
      prePrincipalResultSignature
      principalResultSignature
      content
      type
      form
      noticedAt
      reportedAt
      usedDays
      resultFile
      resultFiles
      resultText
      resultTitle
      fieldtripResultStatus
      notApprovedReason
      updatedAt
      student {
        name
        nokName
        klassGroup {
          group {
            name
          }
        }
      }
    }
  }
`;

export const APPROVE_FIELDTRIP = gql`
  mutation ($fieldtripId: Int!, $signature: String!) {
    approveFieldtripByTeacher(
      fieldtripId: $fieldtripId
      signature: $signature
    ) {
      id
    }
  }
`;

export const APPROVE_MULTIPLE_FIELDTRIPS = gql`
  mutation ($data: String!) {
    approveFieldtripsByTeacher(data: $data) {
      id
    }
  }
`;

export const GET_FIELDTRIP_NOTICE = gql`
  query ($fieldtripId: Int!) {
    me {
      id
      nokName
      nokPhone
      remainDaysOfFieldtrip
      school {
        name
        fieldtripDays
      }
    }
    fieldtripById(fieldtripId: $fieldtripId) {
      id
      startAt
      endAt
      teacherSignature
      studentGradeKlass
      studentNumber
      noticedAt
      destination
      currentRemainDays
      usedDays
      student {
        name
      }
      teacher {
        id
        name
      }
    }
  }
`;

export const UPDATE_FIELDTRIP_RESULT = gql`
  mutation ($data: String!, $signature: String!, $images: Upload) {
    updateFieldtripResult(data: $data, signature: $signature, images: $images) {
      id
    }
  }
`;

export const UPDATE_FIELD_TRIP_RESULT_BY_TEACHER = gql`
  mutation ($data: String!) {
    updateFieldtripResultBtTeacher(data: $data) {
      id
    }
  }
`;

export const APPROVE_MULTIPLE_FIELDTRIP_RESULTS = gql`
  mutation ($data: String!) {
    approveFieldtripResultsByTeacher(data: $data) {
      id
    }
  }
`;

export const APPROVE_FIELDTRIP_RESULT = gql`
  mutation ($fieldtripId: Int!, $signature: String!) {
    approveFieldtripResultByTeacher(
      fieldtripId: $fieldtripId
      signature: $signature
    ) {
      id
    }
  }
`;

export const GET_FIELDTRIP_REPORT_ADD_PAGE = gql`
  query ($fieldtripId: Int!) {
    me {
      nokName
      nokPhone
      school {
        fieldtripDays
      }
    }
    fieldtripById(fieldtripId: $fieldtripId) {
      id
      startAt
      endAt
      studentGradeKlass
      studentNumber
      content
      noticedAt
      destination
      currentRemainDays
      usedDays
      form
      resultTitle
      resultText
      student {
        name
      }
      teacher {
        id
        name
      }
    }
  }
`;

export const UPDATE_FIELD_TRIP = gql`
  mutation ($data: String!, $signature: String!) {
    updateFieldTrip(data: $data, signature: $signature) {
      id
    }
  }
`;

export const UPDATE_FIELD_TRIP_BY_TEACHER = gql`
  mutation ($data: String!) {
    updateFieldTripByTeacher(data: $data) {
      id
    }
  }
`;

export const DELETE_FIELD_TRIP = gql`
  mutation ($fieldtripId: Int!) {
    deleteFieldtrip(fieldtripId: $fieldtripId) {
      id
    }
  }
`;
export const CREATE_SCHEDULE = gql`
  mutation ($data: String!) {
    createSchedule(data: $data) {
      id
    }
  }
`;

export const DELETE_APPREAL_FIELDTRIP = gql`
  mutation ($fieldtripId: Int!, $deleteReason: String!) {
    deleteAppealFieldtripByTeacher(
      fieldtripId: $fieldtripId
      deleteReason: $deleteReason
    ) {
      id
    }
  }
`;
export const UPDATE_SCHEDULE = gql`
  mutation ($scheduleId: Int!, $data: String!) {
    updateSchedule(scheduleId: $scheduleId, data: $data) {
      id
    }
  }
`;

export const DENY_FIELDTRIP_RESULT = gql`
  mutation ($fieldtripId: Int!, $notApprovedReason: String!) {
    denyFieldtripResultByTeacher(
      fieldtripId: $fieldtripId
      notApprovedReason: $notApprovedReason
    ) {
      id
    }
  }
`;

export const DELETE_SCHEDULE = gql`
  mutation ($scheduleId: Int!) {
    deleteSchedule(scheduleId: $scheduleId) {
      id
    }
  }
`;

export const DELETE_APPREAL_FIELDTRIP_RESULT = gql`
  mutation ($fieldtripId: Int!, $deleteReason: String!) {
    deleteAppealFieldtripResultByTeacher(
      fieldtripId: $fieldtripId
      deleteReason: $deleteReason
    ) {
      id
    }
  }
`;

export const UPDATE_RECORD_SUMMARY = gql`
  mutation ($id: Int!, $recordSummary: String!, $subject: String!) {
    updateRecordSummary(
      id: $id
      recordSummary: $recordSummary
      subject: $subject
    ) {
      id
    }
  }
`;

export const UPDATE_EXIST_RECORD_SUMMARY = gql`
  mutation ($id: Int!, $recordSummary: String!) {
    updateExistRecordSummary(id: $id, recordSummary: $recordSummary) {
      id
    }
  }
`;

export const DELETE_RECORD_SUMMARY = gql`
  mutation ($id: Int!) {
    deleteRecordSummary(id: $id) {
      id
    }
  }
`;

export const GET_SCHEDULES = gql`
  query {
    schedules {
      id
      calendarId
      title
      category
      location
      isAllDay
      dueDateClass
      attendee
      start
      end
      isReadOnly
    }
  }
`;

export const SUBMIT_ABSENT_BY_TEACHER = gql`
  mutation ($absentId: Int!, $submitted: Boolean!) {
    submitAbsentByTeacher(absentId: $absentId, submitted: $submitted) {
      id
    }
  }
`;

export const SUBMIT_NICE_ABSENT_BY_TEACHER = gql`
  mutation ($absentId: Int!, $niceSubmitted: Boolean!) {
    submitNiceAbsentByTeacher(
      absentId: $absentId
      niceSubmitted: $niceSubmitted
    ) {
      id
    }
  }
`;

export const GET_ATTENDEE_INFOS = gql`
  query {
    myKlassGroup {
      group {
        name
        studentGroups {
          studentNumber
          user {
            name
            attendeeInfo
          }
        }
      }
    }
  }
`;

export const FIRST_LOGIN = gql`
  mutation ($data: String!) {
    firstLogin(data: $data) {
      id
    }
  }
`;

export const TEACHER_FIRST_LOGIN = gql`
  mutation ($data: String!) {
    teacherFirstLogin(data: $data) {
      id
    }
  }
`;

export const GET_TEACHER_INFO_UPDATE_PAGE = gql`
  query {
    me {
      name
      phone
    }
  }
`;

export const UPDATE_TEACHER = gql`
  mutation ($data: String!) {
    updateTeacher(data: $data) {
      id
    }
  }
`;

export const GET_MYPAGE = gql`
  query {
    me {
      id
      name
      email
      role
      profile
      customProfile
      customBackground
      customMessage
      birthDate
      barcode
      school {
        id
        name
        mark
        stamp
        privacyPolicyLink
      }
      klassGroup {
        id
        studentNumber
        group {
          id
          name
          type
          year
        }
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation ($profile: Upload!) {
    updateProfile(profile: $profile) {
      id
    }
  }
`;

export const UPDATE_CUSTOM_PROFILE = gql`
  mutation (
    $customProfile: Upload
    $customBackground: Upload
    $customMessage: String!
  ) {
    updateCustomProfile(
      customProfile: $customProfile
      customBackground: $customBackground
      customMessage: $customMessage
    ) {
      id
    }
  }
`;

export const GET_TIMETABLE_V2_BY_YEAR_SEMESTER_GROUP = gql`
  query ($year: Int!, $semester: Int!, $groupId: Int!, $studentId: Int!) {
    timetablev2ByYearSemesterGroup(
      year: $year
      semester: $semester
      groupId: $groupId
    ) {
      id
      content
    }
    me {
      canEditTimetable
    }
    timetablev2student(
      year: $year
      semester: $semester
      groupId: $groupId
      studentId: $studentId
    ) {
      id
      content
    }
  }
`;

export const GET_TIMETABLE_V2_BY_YEAR_SEMESTER_TEACHER = gql`
  query ($year: Int!, $semester: Int!, $groupId: Int!) {
    timetablev2ByYearSemesterGroup(
      year: $year
      semester: $semester
      groupId: $groupId
    ) {
      id
      content
      seatPosition
      groupId
    }
    me {
      id
      canEditTimetable
    }
    timetablev2teacher(year: $year, semester: $semester, groupId: $groupId) {
      id
      content
    }
    klassGroups {
      id
      name
    }
  }
`;

export const GET_KlASS_WITH_MYKLASSID = gql`
  query ($year: Int!, $semester: Int!, $groupId: Int!) {
    timetablev2ByYearSemesterGroup(
      year: $year
      semester: $semester
      groupId: $groupId
    ) {
      id
      groupId
    }
    klassGroups {
      id
      name
    }
  }
`;

export const GET_TIMETABLE_V2_MYKLASS = gql`
  query ($year: Int!, $semester: Int!, $groupId: Int!) {
    timetablev2ByYearSemesterGroup(
      year: $year
      semester: $semester
      groupId: $groupId
    ) {
      id
      content
      groupId
    }
    klassGroups {
      id
      name
    }
  }
`;

export const RESEND_RESULT_ALIMTALK = gql`
  mutation ($id: Int!) {
    resendResultAlimtalk(id: $id) {
      id
    }
  }
`;

export const RESEND_ALIMTALK = gql`
  mutation ($id: Int!) {
    resendAlimtalk(id: $id) {
      id
    }
  }
`;

export const GET_CHAT = gql`
  query ($id: Int!) {
    chat(id: $id) {
      id
      title
      content
      images
      files
      createdAt
    }
  }
`;

// me 추가 필요
export const DELETE_CHAT = gql`
  query ($id: Int!) {
    chat(id: $id) {
      id
      title
      content
      images
      files
      createdAt
    }
  }
`;
