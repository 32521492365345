import {
  BackButton,
  Blank,
  Button,
  Section,
  TextField,
  TopNavbar,
  useQuery,
  useMutation,
  TextArea,
} from '../../../reusable';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ErrorBlank, Td, Td2 } from '../../../components';
import {
  DELETE_FIELD_TRIP,
  GET_FIELDTRIP_DETAIL,
  RESEND_ALIMTALK,
} from '../../../query';
import { makeStartEndToString } from '../../../utils';
import FieldtripAddPage from './FieldtripAddPage';
import { api } from '../../../plugins';
import { User } from '../../../types';

interface MatchParams {
  id: string;
}

export const FieldtripDetailPage: React.FC<
  RouteComponentProps<MatchParams>
> = ({ match }) => {
  const history = useHistory();
  const { id } = match.params;

  const { loading, error, data } = useQuery(GET_FIELDTRIP_DETAIL, {
    variables: { fieldtripId: Number(id) },
  });

  const [mode, setMode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fieldtrip = data?.fieldtripById;
  const approvalLine = fieldtrip?.student?.school?.approvalLineF;
  const [me, setMe] = useState<User>();

  useEffect(() => {
    if (!me) {
      api
        .get('/users/me')
        .then((res) => setMe(res.data))
        .catch((err) => {
          if (err.message.includes('Unauthorized')) {
            history.replace('/login');
          } else {
            alert(err.message);
          }
        });
    }
  }, []);

  const isNotParent = me?.role !== 'PARENT';

  const [deleteFieldtrip] = useMutation(DELETE_FIELD_TRIP, {
    variables: { fieldtripId: Number(id) },
    onError: (e) => {
      setLoading(false);
      setErrorMessage(e.message);
    },
    onCompleted: () => {
      alert('삭제되었습니다');
      setLoading(false);
      history.push('/student/fieldtrip');
    },
  });

  const [resendAlimtalk] = useMutation(RESEND_ALIMTALK, {
    variables: { id: Number(id) },
    onError: (e) => {
      setLoading(false);
      setErrorMessage(e.message);
    },
    onCompleted: () => {
      alert('신청서 알림톡이 재전송되었습니다.');
      setLoading(false);
      history.push('/student/fieldtrip');
    },
  });

  let name = '가정';

  switch (fieldtrip?.type) {
    case 'HOME':
      name = '가정';
      break;
    case 'SUBURBS':
      name = '교외 체험';
      break;
  }

  if (mode) {
    return (
      <FieldtripAddPage
        fieldtripData={fieldtrip}
        returnToDetail={() => setMode(false)}
      />
    );
  }

  return (
    <>
      {loading && <Blank />}
      {error && <ErrorBlank />}
      {isLoading && <Blank />}
      <TopNavbar title="체험학습 신청서 상세" left={<BackButton />} />
      <Section>
        <h1 className="text-xl font-semibold"> {name}학습 신청서</h1>
        <div className=" w-full flex items-center justify-center">
          <table className="min-w-max">
            <tr>
              {approvalLine?.map((role: string) => {
                switch (role) {
                  case 'TEACHER':
                    return <Td2 style={{ height: '16px' }}>담임</Td2>;
                  case 'PREHEAD':
                    return <Td2 style={{ height: '16px' }}>학년계</Td2>;
                  case 'HEAD':
                    return <Td2 style={{ height: '16px' }}>학년부장</Td2>;
                  case 'PREPRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교무계</Td2>;
                  case 'PRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교무부장</Td2>;
                  case 'VICEPRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교감</Td2>;
                  case 'HEADPRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교장</Td2>;
                }
              })}
            </tr>
            <tr>
              {approvalLine?.map((role: string) => {
                switch (role) {
                  case 'TEACHER':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.teacherSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.teacherSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'PREHEAD':
                  return (
                    <Td2 style={{ height: '40px' }}>
                      {fieldtrip?.preHeadSignature && (
                        <img
                          style={{ width: '50px', margin: 'auto' }}
                          src={fieldtrip?.preHeadSignature}
                          crossOrigin="anonymous"
                          alt=""
                        />
                      )}
                    </Td2>
                  );
                  case 'HEAD':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.headSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.headSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'PREPRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.prePrincipalSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.prePrincipalSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'PRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.principalSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.principalSignature}
                            crossOrigin="anonymous"
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'VICEPRINCIPAL':
                      return (
                        <Td2 style={{ height: '40px' }}>
                          {fieldtrip?.viceprincipalSignature && (
                            <img
                              style={{ width: '50px', margin: 'auto' }}
                              src={fieldtrip?.viceprincipalSignature}
                              crossOrigin="anonymous"
                              alt=""
                            />
                          )}
                        </Td2>
                      );
                  case 'HEADPRINCIPAL':
                      return (
                        <Td2 style={{ height: '40px' }}>
                          {fieldtrip?.headprincipalSignature && (
                            <img
                              style={{ width: '50px', margin: 'auto' }}
                              src={fieldtrip?.headprincipalSignature}
                              crossOrigin="anonymous"
                              alt=""
                            />
                          )}
                        </Td2>
                      );
                }
              })}
            </tr>
          </table>
        </div>
        {fieldtrip?.fieldtripStatus === 'RETURNED' && (
          <div className="mt-3">
            <div className="text-red-500">반려 이유</div>
            <div className="rounded-lg w-full bg-light_orange px-5 py-3 whitespace-pre-line">
              {fieldtrip?.notApprovedReason}
            </div>
          </div>
        )}
        {fieldtrip?.fieldtripStatus === 'DELETE_APPEAL' && (
          <div className="mt-3">
            <div className="text-red-500">삭제 이유</div>
            <div className="rounded-lg w-full bg-light_orange px-5 py-3 whitespace-pre-line">
              {fieldtrip?.deleteReason}
            </div>
          </div>
        )}

        {/* <h1 className="text-xl font-semibold">신청기간</h1> */}
        <div className="w-full">
          {fieldtrip?.startAt &&
            fieldtrip?.endAt &&
            makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)}{' '}
          ({fieldtrip?.usedDays}일간)
        </div>
        <div className="whitespace-pre-line mb-2">
          남은 일수
          <span className="text-brand-1 underline">
            {fieldtrip?.currentRemainDays}일 중 {fieldtrip?.usedDays}일 신청
          </span>
        </div>

        {/* <h1 className="text-xl font-semibold">체험학습 종류</h1> */}
        <div className="w-full">
          <TextField
            label="체험학습 종류"
            value={fieldtrip?.type === 'HOME' ? '가정학습' : '교외 체험학습'}
            disabled
          />
        </div>

        {/* <h1 className="text-xl font-semibold">목적지</h1> */}
        <div className="w-full">
          <TextField label="목적지" value={fieldtrip?.destination} disabled />
        </div>
        <div className="w-full">
          <TextField label="목적" value={fieldtrip?.purpose} disabled />
        </div>
        {fieldtrip?.type === 'SUBURBS' && (
          <>
            {/* <h1 className="text-xl font-semibold"> */}
            {/* 숙박장소(숙박일정이 있는 경우)
              </h1> */}
            <div className="w-full">
              <TextField
                label="숙박장소"
                value={fieldtrip?.accommodation}
                disabled
              />
            </div>
            {/* <h1 className="text-xl font-semibold">인솔자명</h1> */}
            <div className="w-full">
              <TextField
                label="인솔자명"
                value={fieldtrip?.guideName}
                disabled
              />
            </div>
            {/* <h1 className="text-xl font-semibold">인솔자 관계</h1> */}
            <div className="w-full">
              <TextField
                label="인솔자 관계"
                value={fieldtrip?.relationship}
                disabled
              />
              <div className="text-red-600 text-sm whitespace-pre-line mb-2">
                *인솔자가 직계가족이 아닌 경우 현장체험학습을 불허합니다.
              </div>
            </div>
            {/* <h1 className="text-xl font-semibold">인솔자 연락처</h1> */}
            <div className="w-full">
              <TextField
                label="인솔자 연락처"
                value={fieldtrip?.guidePhone}
                disabled
              />
            </div>
            {/* <h1 className="text-xl font-semibold">현장학습 계획</h1> */}
            <div className="w-full">
              <TextArea
                style={{ borderWidth: '1px', height: 'max-content' }}
                value={fieldtrip?.content}
                disabled
              />
            </div>
          </>
        )}
        {fieldtrip?.type === 'HOME' && (
          <>
            {/* <h1 className="text-xl font-semibold">가정학습 계획</h1> */}
            <table className="mx-auto">
              {JSON.parse(fieldtrip?.content || '[]')?.map(
                (el: any, i: number) => (
                  <>
                    <tr>
                      <Td
                        colSpan={3}
                        style={{
                          textAlign: 'center',
                          backgroundColor: '#C4C4C4',
                        }}
                      >
                        {i + 1}일차
                      </Td>
                    </tr>
                    <tr>
                      <Td innerClassName="min-w-max">교시</Td>
                      <Td innerClassName="min-w-max">교과</Td>
                      <Td innerClassName="min-w-max">학습할 내용</Td>
                    </tr>
                    {new Array(7).fill('').map((dlldl: any, index: number) => (
                      <>
                        {el['subject' + (index + 1)] && (
                          <tr>
                            <Td>{index + 1}교시</Td>
                            <Td>{el['subject' + (index + 1)]}</Td>
                            <Td>{el['content' + (index + 1)]}</Td>
                          </tr>
                        )}
                      </>
                    ))}
                  </>
                ),
              )}
            </table>
          </>
        )}
        {isNotParent && (
          <>
            {(fieldtrip?.fieldtripStatus === 'BEFORE_PARENT_CONFIRM' ||
              fieldtrip?.fieldtripStatus === 'RETURNED' ||
              fieldtrip?.fieldtripStatus === 'BEFORE_TEACHER_APPROVAL') && (
              <Button onClick={() => setMode(true)}>수정하기</Button>
            )}
            {fieldtrip?.fieldtripStatus === 'BEFORE_PARENT_CONFIRM' && (
              <Button
                tw={{ backgroundColor: 'bg-blue-500' }}
                onClick={() => resendAlimtalk()}
              >
                알림톡 재전송하기
              </Button>
            )}

            <div className="text-red-500">{errorMessage}</div>
            {(fieldtrip?.fieldtripStatus === 'BEFORE_PARENT_CONFIRM' ||
              fieldtrip?.fieldtripStatus === 'DELETE_APPEAL' ||
              fieldtrip?.fieldtripStatus === 'BEFORE_TEACHER_APPROVAL') && (
              <Button
                onClick={() => {
                  setLoading(true);
                  deleteFieldtrip();
                }}
                tw={{
                  backgroundColor: 'bg-red-500',
                }}
              >
                삭제하기
              </Button>
            )}
          </>
        )}

        <div className="w-full h-32" />
      </Section>
    </>
  );
};
