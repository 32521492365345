import React, { useState } from 'react';
import {
  BackButton,
  Blank,
  Button,
  Section,
  TextArea,
  TextField,
  TopNavbar,
  useMutation,
} from '../../../reusable';
import { SuperModal, SelectValues } from '../../../components';
import { UPDATE_OUTING_BY_TEACHER } from '../../../query';
import { makeDateToString, makeTimeToString } from '../../../utils';

interface OutingUpdatePageProps {
  outingData: any;
  setChangeMode: (b: boolean) => void;
  refetch: () => void;
  isConfirmed: boolean;
}
const reportType = ['조퇴', '외출', '확인'];

const getMeridiem = (date: string | undefined) => {
  if (!date) return '오전';
  if (new Date(date).getHours() < 12) {
    return '오전';
  } else {
    return '오후';
  }
};

const getMeridiemHours = (date: string | undefined) => {
  if (!date) return 0;
  return new Date(date).getHours();
};

export const OutingUpdatePage: React.FC<OutingUpdatePageProps> = ({
  outingData,
  setChangeMode,
  refetch,
  isConfirmed,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [updateReason, setUpdateReason] = useState('');
  const [startAt, setStartAt] = useState(
    outingData?.startAt ? makeDateToString(new Date(outingData.startAt)) : '',
  );
  const [endAt, setEndAt] = useState(
    outingData?.endAt ? makeDateToString(new Date(outingData.endAt)) : '',
  );
  const [startMeridiem, setStartMeridiem] = useState(
    outingData ? getMeridiem(outingData.startAt) : '오전',
  );
  const [startHour, setStartHour] = useState(
    outingData ? getMeridiemHours(outingData.startAt) : 9,
  );
  const [startMinute, setStartMinute] = useState(
    outingData?.startAt ? new Date(outingData.startAt).getMinutes() : 0,
  );
  const [endMeridiem, setEndMeridiem] = useState(
    outingData ? getMeridiem(outingData.endAt) : '오후',
  );
  const [endHour, setEndHour] = useState(
    outingData ? getMeridiemHours(outingData.endAt) : 16,
  );
  const [endMinute, setEndMinute] = useState(
    outingData?.endAt ? new Date(outingData.endAt).getMinutes() : 40,
  );
  const [report, setReport] = useState(outingData?.type || '');
  const [reason, setReason] = useState(outingData?.reason || '');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);

  const makeStartAt = () => {
    let date = new Date();
    if (startAt) {
      date = new Date(startAt);
    }
    let hour = Number(startHour);
    date.setHours(hour, Number(startMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };
  const makeEndAt = () => {
    let date = new Date();
    if (startAt) {
      date = new Date(startAt);
    }
    if (report === '확인') {
      date = new Date(endAt);
    }
    let hour = Number(endHour);
    date.setHours(hour, Number(endMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };

  const [updateOuting] = useMutation(UPDATE_OUTING_BY_TEACHER, {
    variables: {
      data: JSON.stringify({
        type: report,
        startAt: makeStartAt(),
        endAt: makeEndAt(),
        reason,
        updateReason,
      }),
      outingId: outingData.id,
    },
    onError: (e) => {
      setLoading(false);
      setErrorMessage(e.message);
    },
    onCompleted: () => {
      setLoading(false);
      setChangeMode(false);
      refetch();
    },
  });

  return (
    <>
      {isLoading && <Blank />}
      <TopNavbar title="확인증 신청" left={<BackButton />} />
      <Section>
        <div className="w-full">
          <TextField
            label="학생 이름(본인)"
            id="studentName"
            value={outingData?.student?.name}
            tw={{ backgroundColor: 'bg-gray-100' }}
            readOnly
          />
        </div>
        <div className="w-full">
          <TextField
            label="학생 학년/반/번호"
            value={`${outingData?.studentGradeKlass} ${outingData?.studentNumber}번`}
            tw={{ backgroundColor: 'bg-gray-100' }}
            readOnly
          />
        </div>
        <div className="w-full">
          <SelectValues
            label="*유형"
            placeholder="유형을 선택해주세요."
            selectValues={reportType}
            value={report}
            onChange={(group: any) => {
              setReport(group);
              if (group === '외출') {
                setStartMeridiem('오후');
                setEndMeridiem('오후');
                setStartHour(12);
                setStartMinute(50);
                setEndHour(1);
                setEndMinute(50);
              }
            }}
          />
        </div>
        <div className="w-full pb-6">
          <label className="mb-1 text-sm text-gray-800">*기간</label>
          <div className="space-y-3 pb-6">
            <div>
              <input
                type="date"
                value={startAt}
                className="px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400"
                onChange={(e) => setStartAt(e.target.value)}
              />
            </div>
            <div className="flex items-center space-x-2">
              <select
                value={startHour}
                onChange={(e) => setStartHour(Number(e.target.value))}
                className="px-4 w-16 h-12 border
               border-gray-200 rounded-md sm:text-sm
               focus:ring-0 focus:border-brand-1 placeholder-gray-400
               disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
              >
                {new Array(24).fill(null).map((item: any, num: number) => (
                  <option value={num}>{num}</option>
                ))}
              </select>
              <span>시</span>
              <select
                className="px-4 w-16 h-12 border
        border-gray-200 rounded-md sm:text-sm
        focus:ring-0 focus:border-brand-1 placeholder-gray-400
        disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                onChange={(e) => setStartMinute(Number(e.target.value))}
                value={startMinute}
              >
                <option value={0}>0</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
              </select>
              <span>분 부터</span>
            </div>
            <div className="flex items-center space-x-2">
              {report === '확인' && (
                <div>
                  <input
                    type="date"
                    lang="ko-KR"
                    value={endAt}
                    className="px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400"
                    onChange={(e) => setEndAt(e.target.value)}
                  />
                </div>
              )}
              <select
                value={endHour}
                onChange={(e) => setEndHour(Number(e.target.value))}
                className="px-4 w-16 h-12 border
               border-gray-200 rounded-md sm:text-sm
               focus:ring-0 focus:border-brand-1 placeholder-gray-400
               disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
              >
                {new Array(24).fill(null).map((item: any, num: number) => (
                  <option value={num}>{num}</option>
                ))}
              </select>
              <span>시</span>
              <select
                className="px-4 w-16 h-12 border
        border-gray-200 rounded-md sm:text-sm
        focus:ring-0 focus:border-brand-1 placeholder-gray-400
        disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                onChange={(e) => setEndMinute(Number(e.target.value))}
                value={endMinute}
              >
                <option value={0}>0</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
              </select>
              <span>분 까지</span>
            </div>
          </div>
        </div>
        <div className="w-full">
          <TextField
            label="*사유"
            id="reason"
            placeholder="조퇴/외출/확인 사유를 자세하게 입력해주세요"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>

        {errorMessage && <div className="text-red-600">{errorMessage}</div>}
        <Button
          disabled={!reason || !report || !startAt}
          tw={{
            backgroundColor:
              !reason || !report || !startAt ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={() => {
            if (isConfirmed) {
              setModalOpen(true);
            } else {
              setLoading(true);
              updateOuting();
            }
          }}
        >
          제출하기
        </Button>
      </Section>
      <SuperModal
        modalOpen={modalOpen}
        setModalClose={() => setModalOpen(false)}
        width="w-max"
      >
        <Section tw={{ marginTop: 'mt-7' }}>
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            이 확인증을 승인 후 수정하시는 이유를 적어주세요.
          </div>
          <TextArea
            placeholder="수정 이유"
            onChange={(e) => setUpdateReason(e.target.value)}
            value={updateReason}
          />
          <Button
            tw={{ backgroundColor: 'bg-red-500' }}
            onClick={() => {
              setLoading(true);
              updateOuting();
            }}
          >
            승인 후 수정하기
          </Button>
        </Section>
      </SuperModal>
    </>
  );
};
